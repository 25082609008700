import React from 'react';
import PropTypes from 'prop-types';
import Chip from './Chip';
import { CHIP_DEFAULT_COLOR } from './constants';

function EventChip({
  label,       // typically event title or todo name
  lineThrough, // should be set to `true` for completed todo
  color,       // color of the event or todo (in HEX)
  startTime,   // start time if not all-day event
  startIcon,   // should not be null if todo
  leftRadius,  // `true` if the event starts here
  rightRadius, // `true` if the event ends here
  disabled,    // should be set to `true` while dragging for example
  ...rest
}) {
  return (
    <Chip
      label={label}
      labelLineThrough={lineThrough}
      color={color}
      startTime={startTime}
      startIcon={startIcon}
      leftRadius={leftRadius}
      rightRadius={rightRadius}
      disabled={disabled}
      {...rest}
    />
  );
}

EventChip.propTypes = {
  label: PropTypes.string.isRequired,
  lineThrough: PropTypes.bool,

  color: PropTypes.string,

  startTime: PropTypes.string,
  startIcon: PropTypes.node,

  leftRadius: PropTypes.bool,
  rightRadius: PropTypes.bool,

  disabled: PropTypes.bool
};

EventChip.defaultProps = {
  color: CHIP_DEFAULT_COLOR,
  lineThrough: false,
  leftRadius: true,
  rightRadius: true,
  disabled: false
};

export default EventChip;
