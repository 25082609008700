import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DialogContent from '../../ui/dialog/MultiStepContent';
import DialogActions from '../../ui/dialog/MultiStepActionsContainer';
import Button from '../../ui/button/Button';
import MakeFolderForm from './MakeFolderForm';
import FocusAfterNameInput from './FocusAfterNameInput';
import FolderPickerButton from './FolderPickerButton';
import MakeFolderFootnote from './MakeFolderFootnote';
import useProjectUrl from '../../project/hooks/useProjectURL';
import useCreateFolderMutation from '../hooks/useCreateFolderMutation';

function MakeFolder({
  projectId,
  parent,
  onSelectParent,
  focusInputAfter,
  onClose,
  actionsContainer
}) {
  const history = useHistory();
  const projectUrl = useProjectUrl(projectId);
  const [name, setName] = React.useState('');

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const isSubmitDisabled = () => {
    const trimmedName = name.trim();

    if (!trimmedName.length) {
      return true;
    }

    return false;
  };

  //
  // GraphQL mutation.
  //

  const [createFolder, { loading }] = useCreateFolderMutation({
    variables: {
      name,
      projectId,
      parentId: (parent.id !== 'root') ? parent.id : null
    },
    onCompleted: (data) => {
      onClose();
      redirectToNewFolderPage(data.createFolder.id);
    }
  });

  const redirectToNewFolderPage = (newFolderId) => {
    const rootUrl = `${projectUrl}/files`;
    const newFolderUrl = `${rootUrl}/directories/${newFolderId}`;
    history.push(newFolderUrl);
  };

  return (
    <DialogContent>
      <MakeFolderForm>
        <FocusAfterNameInput
          helperText={null}
          id='__make_folder_name_input'
          placeholder='Enter folder name'
          transitionDuration={focusInputAfter}
          value={name}
          onChange={handleChange}
        />
        <FolderPickerButton
          label={parent.name}
          onClick={onSelectParent}
        />
        <MakeFolderFootnote />
      </MakeFolderForm>

      <DialogActions container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          disabled={isSubmitDisabled() || loading}
          loading={loading}
          onClick={createFolder}
        >
          Create
        </Button>
      </DialogActions>
    </DialogContent>
  );
}

MakeFolder.propTypes = {
  projectId: PropTypes.string.isRequired,
  parent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  onSelectParent: PropTypes.func,
  focusInputAfter: PropTypes.number.isRequired,
  actionsContainer: PropTypes.object, // DOM node to mount dialog actions
  onClose: PropTypes.func.isRequired  // Close dialog
};

export default MakeFolder;
