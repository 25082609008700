import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useRouteMatch, Route } from 'react-router-dom';

import DailyScheduleWrapper from './DailyScheduleWrapper';
import WeeklyScheduleWrapper from './WeeklyScheduleWrapper';
import MonthlyEventsWrapper from './MonthlyEventsWrapper';

import ViewSelect from './ViewSelect';
import ViewNavigator from './ViewNavigator';
import Header from './ui/Header';
import ScheduleDrawer from './schedule-drawer/ScheduleDrawer';

function renderView(view) {
  switch(view) {
    case 'day':
      return <DailyScheduleWrapper />;
    case 'week':
      return <WeeklyScheduleWrapper />;
    case 'month':
      return <MonthlyEventsWrapper />;
    default:
      return null;
  }
}

function ViewSwitch({ rootUrl }) {
  const { view } = useParams();
  const { path, url } = useRouteMatch();

  return (
    <React.Fragment>
      <Header>
        <ViewNavigator activeView={view} rootUrl={rootUrl} />
        <ViewSelect activeView={view} rootUrl={rootUrl} />
      </Header>
      {renderView(view)}
      <Route path={`${path}/:scheduleType(task|event)?/:scheduleId?`} exact>
        <ScheduleDrawer rootUrl={url} />
      </Route>
    </React.Fragment>
  );
}

ViewSwitch.propTypes = {
  rootUrl: PropTypes.string.isRequired
};

export default ViewSwitch;
