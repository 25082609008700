import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '../../icons/20x20/ArrowForwardIos';
import ListItem from '../../ui/dialog/ListItem';
import ListItemStartIcon from '../../ui/dialog/ListItemStartIcon';
import ListItemText from '../../ui/dialog/ListItemText';
import ListItemEndIcon from '../../ui/dialog/ListItemEndIcon';

function OptionListItem({
  startIcon,
  primary,
  secondary,
  ...rest
}) {

  return (
    <ListItem {...rest}>
      <ListItemStartIcon icon={startIcon} />
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemEndIcon
        icon={<ArrowForwardIcon color='secondary' />}
      />
    </ListItem>
  );
}

OptionListItem.propTypes = {
  startIcon: PropTypes.node.isRequired,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired
};

export default OptionListItem;
