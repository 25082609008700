import PropTypes from 'prop-types';
import useSomeTodoListUpdatedSubscription
from './hooks/useSomeTodoListUpdatedSubscription';

function SomeTodoListUpdatedSubscriber({ projectId }) {
  useSomeTodoListUpdatedSubscription({ projectId });
  return null;
}

SomeTodoListUpdatedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default SomeTodoListUpdatedSubscriber;
