import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import UploadStatus, { StatusCode, STATUS_CODES }
from '../file/list/UploadStatus';

const CancelToken = axios.CancelToken;

function runUploadTask({
  path,
  presignedUrl,
  file,
  uploadId,
  onProgress,
  onStatusChange
}) {
  const cancelSource = CancelToken.source();

  const config = {
    headers: {
      'Content-Type': file.type
    },
    onUploadProgress: (event) => {
      const progress = (event.loaded / event.total) * 100;
      onProgress(progress);
    },
    cancelToken: cancelSource.token
  };

  axios
    .put(presignedUrl, file, config)
    .then((response) => {
      onStatusChange(uploadId, {
        statusCode: StatusCode.SUCCEEDED,
        path
      });
    })
    .catch((error) => {
      const isCanceled = axios.isCancel(error);
      onStatusChange(uploadId, {
        statusCode: isCanceled ? StatusCode.CANCELED : StatusCode.FAILED
      });
    });

  return cancelSource;
}

function UploadTask({
  path,
  presignedUrl,
  file,
  uploadId,
  statusCode,
  onStatusChange,
  ...rest
}) {
  const [progress, setProgress] = React.useState(0);
  const cancelSourceRef = React.useRef();

  const handleCancel = () => {
    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel();
    }
  };

  const handleRetry = () => {
    onStatusChange(uploadId, { statusCode: StatusCode.UPLOADING });
  };

  React.useEffect(() => {
    if (statusCode === StatusCode.UPLOADING) {
      const cancelSource = runUploadTask({
        path,
        presignedUrl,
        file,
        uploadId,
        onProgress: setProgress,
        onStatusChange
      });

      cancelSourceRef.current = cancelSource;

      return () => cancelSource.cancel();
    }
  }, [path, presignedUrl, file, uploadId, statusCode, onStatusChange]);

  return (
    <UploadStatus
      statusCode={statusCode}
      progress={progress}
      onCancel={handleCancel}
      onRetry={handleRetry}
      {...rest}
    />
  );
}

UploadTask.propTypes = {
  // The destination path of the uploaded file
  path: PropTypes.string.isRequired,

  // The presigned url to upload file
  presignedUrl: PropTypes.string.isRequired,

  // The file to be uploaded
  file: PropTypes.instanceOf(File).isRequired,

  // The id of the current upload
  uploadId: PropTypes.string.isRequired,

  // Upload status code
  statusCode: PropTypes.oneOf(STATUS_CODES).isRequired,

  // (newStatusCode, data) => {...}
  onStatusChange: PropTypes.func.isRequired
};

export default UploadTask;
