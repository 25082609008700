import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import DialogForm from '../../ui/dialog/DialogForm';
import TextField from '../../ui/input/TextField';
import UserAvatarUploader from '../avatar-uploader/UserAvatarUploader';
import useUpdateCurrentUserProfileMutation
from '../hooks/useUpdateCurrentUserProfileMutation';
import { gqlErrorDetails } from '../../oops/helpers';

function EditUserProfileForm({ user }) {
  const { closeDialog } = React.useContext(DialogContext);
  const { username, email } = user;
  const [formData, setFormData] = React.useState({ username });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  React.useEffect(() => {
    setFormData((prev) => ({ ...prev, username: username }));
  }, [username]);

  //
  // Graphql mutation.
  //

  const [updateProfile, { loading, error }] =
    useUpdateCurrentUserProfileMutation({
      variables: { changes: formData },
      onCompleted(data) {
        closeDialog();
      }
    });

  const errorDetails = gqlErrorDetails(error);
  const usernameError = errorDetails['username'];
  const usernameErrorProps = Boolean(usernameError) ? {
    error: true,
    helperText: `A valid username ${usernameError}.`
  } : {};

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Edit my profile
      </DialogTitle>

      <DialogContent>
        <DialogForm>
          <UserAvatarUploader user={user} />

          <TextField
            autoFocus
            type='text'
            name='username'
            id='edit_user_profile_form_input__username'
            label='Your name'
            fullWidth
            value={formData.username}
            onChange={handleChange}
            {...usernameErrorProps}
          />

          <TextField
            type='email'
            name='email'
            id='edit_user_profile_form_input__email'
            label='Your email address'
            fullWidth
            helperText='Sorry, this field cannot be modified.'
            defaultValue={email}
            InputProps={{
              readOnly: true
            }}
          />
        </DialogForm>
      </DialogContent>

      <DialogActions>
        {!loading && (
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={closeDialog}
          >
            Cancel
          </Button>
        )}

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          disabled={!(formData.username.trim().length >= 3)}
          onClick={() => {
            updateProfile().catch((err) => {});
          }}
        >
          Save changes
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

EditUserProfileForm.propTypes = {
  user: PropTypes.object.isRequired
};

export default EditUserProfileForm;
