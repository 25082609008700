import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../../ui/file/table/StyledUploadStatusCell';
import CellLoading from '../../ui/file/table/CellLoading';
import CellError from '../../ui/file/table/CellError';
import PresignedUrlQuery from './PresignedUrlQuery';
import UploadTask from './UploadTask';
import { STATUS_CODES } from '../../ui/file/table/UploadStatus';

function UploadStatusCell({
  projectId,
  file,
  statusCode,
  onStatusChange
}) {
  return (
    <Cell>
      <PresignedUrlQuery
        projectId={projectId}
        fileName={file.name}
        fileType={file.type}
        onLoading={() => <CellLoading />}
        onError={(error) => <CellError error={error} />}
      >
        {({ key, uploadUrl }) => (
          <UploadTask
            path={key}
            presignedUrl={uploadUrl}
            file={file}
            statusCode={statusCode}
            onStatusChange={onStatusChange}
          />
        )}
      </PresignedUrlQuery>
    </Cell>
  );
}

UploadStatusCell.propTypes = {
  // The id of the destination project.
  projectId: PropTypes.string.isRequired,

  // The file to be uploaded.
  file: PropTypes.instanceOf(File).isRequired,

  statusCode: PropTypes.oneOf(STATUS_CODES).isRequired,
  onStatusChange: PropTypes.func.isRequired
};

export default UploadStatusCell;
