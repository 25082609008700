import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

function Header({ children }) {
  const classes = useStyles();
  return (
    <DialogTitle
      disableTypography
      className={classes.root}
    >
      {children}
    </DialogTitle>
  );
}

export default Header;
