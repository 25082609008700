import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import RouterLink from '../ui/link/RouterLink';

export default function CreateAccountFormFooter() {
  return (
    <>
      <Divider flexItem style={{height: 1}} />

      <Typography variant='body2' color='textSecondary'>
        Already have an account?
        {' '}
        <RouterLink
          to='/sign-in'
          variant='subtitle2'
        >
          Sign in
        </RouterLink>
      </Typography>
    </>
  );
}
