import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '../ui/list/ListItem';
import IconAvatar from '../ui/avatar/IconAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      '& $avatar': {
        backgroundColor: theme.palette.primary.main
      },
      '& $icon': {
        filter: 'invert(100%)'
      }
    }
  },
  avatar: {},
  icon: {}
}));

function MobileNavItem(props) {
  const {icon, primary, to, ...rest} = props;

  const classes = useStyles();

  const renderLink = React.useMemo(() => (
    React.forwardRef((itemProps, ref) => (
      <NavLink to={to} ref={ref} {...itemProps} />
    ))
  ), [to]);

  return (
    <li>
      <ListItem
        disableRipple={true}
        disableTouchRipple={true}
        focusRipple={false}
        activeClassName='Mui-selected'
        button
        component={renderLink}
        className={classes.root}
        primary={primary}
        avatar={<IconAvatar icon={icon} classes={{
          avatar: classes.avatar,
          icon: classes.icon
        }} />}
        {...rest}
      />
    </li>
  );
}

MobileNavItem.propTypes = {
  icon: PropTypes.node.isRequired,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default MobileNavItem;
