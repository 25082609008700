import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import Autocomplete from './AutocompleteSubscriber';

function LazySubscriberSearch(props) {
  const {onMatchingChange, searchResults, loading, value, ...rest} = props;
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    const isNewInputValueNotEmpty = inputValue.trim().length > 0;
    setOpen(isNewInputValueNotEmpty);
    setActive(isNewInputValueNotEmpty);
  }, [inputValue, setOpen, setActive]);

  React.useEffect(() => {
    setActive(loading);
  }, [loading, setActive]);

  const handleMatchingChange = React.useCallback(debounce((newInputValue) => {
    const trimmed = newInputValue.trim();
    if (trimmed.length > 0) onMatchingChange(trimmed);
  }, 300), [onMatchingChange]);

  const filteredOptions = active ? [] : searchResults;
  const options = value ? [...value, ...filteredOptions] : filteredOptions;

  return (
    <Autocomplete
      {...rest}
      open={open}
      loading={active}
      value={value}
      options={options}
      filterOptions={(options) => filteredOptions}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
        handleMatchingChange(newInputValue)
      }}
    />
  );
}

LazySubscriberSearch.propTypes = {
  onMatchingChange: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired
};

export default LazySubscriberSearch;
