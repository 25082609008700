import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { parseISO, format, isToday, isYesterday } from 'date-fns';

function formatTimestamp(timestamp) {
  const date = parseISO(timestamp);
  const time = format(date, 'HH:mm a');

  let day;

  if (isToday(date)) {
    day = 'Today';
  } else if (isYesterday(date)) {
    day = 'Yesterday';
  } else {
    day = format(date, 'MMMM do, yyyy');
  }

  return { day, time };
}

function ActivityTimestamp({ timestamp }) {
  const { day, time } = formatTimestamp(timestamp);
  return (
    <Typography variant='caption' color='textSecondary'>
      {day} &bull; {time}
    </Typography>
  );
}

ActivityTimestamp.propTypes = {
  timestamp: PropTypes.string.isRequired
};

ActivityTimestamp.defaultProps = {
  // Fake timestamp
  timestamp: (new Date()).toISOString()
};

export default ActivityTimestamp;
