import React from 'react';
import PropTypes from 'prop-types';
import MenuButton from '../../ui/todo/sheet/MenuButton';
import PriorityChip, { PRIORITY_OPTIONS } from './PriorityChip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover $placeholderText': {
      display: 'block'
    },
    '&:focus $placeholderText': {
      display: 'block'
    },
  },
  placeholderText: {
    display: 'none'
  }
}));

function PriorityCell({ value, onChange }) {
  const classes = useStyles();

  const placeholder = (
    <Typography
      component='div'
      variant='body2'
      color='textSecondary'
      className={classes.placeholderText}
    >
      Set priority
    </Typography>
  );

  // Render menu item.
  const renderItem = (priority) => <PriorityChip priority={priority} />;

  // Render button label.
  const renderValue = (priority, isMenuOpen) => {
    if (priority === 'NONE' && !isMenuOpen) {
      return placeholder;
    }

    return <PriorityChip priority={priority} />;
  };

  return (
    <MenuButton
      className={classes.button}
      items={PRIORITY_OPTIONS}
      renderValue={renderValue}
      renderItem={renderItem}
      value={value}
      onChange={onChange}
    />
  );
}

PriorityCell.propTypes = {
  value: PropTypes.oneOf(PRIORITY_OPTIONS).isRequired,
  onChange: PropTypes.func
};

export default PriorityCell;
