import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import NotFound from '../oops/SectionNotFound';

const UPLOAD_QUERY = gql`
  query GetUpload($projectId: ID!, $id: ID!) {
    project(projectId: $projectId) {
      id
      upload(id: $id) {
        id
        name
        ext
        notes
        type
        size
        url
        previewUrl
        previewType
        downloadUrl

        path {
          id
          name
          ext
          type
        }

        creator {
          id
          username
          email
          avatar
        }

        updatedAt
      }
    }
  }
`;

function UploadQuery({
  projectId,
  id,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    UPLOAD_QUERY,
    {
      variables: { projectId, id },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  if (!data || !data.project.upload) {
    return <NotFound />;
  }

  return children(data.project.upload, otherQueryResults);
}

UploadQuery.propTypes = {
  // In which project to fetch the upload?
  projectId: PropTypes.string.isRequired,

  // Which upload to fetch?
  id: PropTypes.string.isRequired,

  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default UploadQuery;
