import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '../ui/button/Button';
import FormContainer from '../ui/misc/FullScreenFormContainer';
import Form from '../ui/misc/FullScreenForm';
import SignInFormInput from './SignInFormInput';
import SignInFormFooter from './SignInFormFooter';
import useSignInMutation from './hooks/useSignInMutation';
import Error from '../oops/Error';

function SignInForm() {
  const [formData, setFormData] = React.useState({
    email: '',
    password: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const [signIn, { loading, error }] = useSignInMutation({
    ...formData
  });

  return (
    <FormContainer>
      <Form onSubmit={(event) => {
        event.preventDefault();
        signIn().catch((err) => {});
      }}>
        <Typography variant='h4' component='h1'>
          Sign in.
        </Typography>

        {error && (
          <Error error={error} style={{alignSelf: 'stretch'}} />
        )}

        <SignInFormInput value={formData} onChange={handleChange} />

        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='large'
          fullWidth
          loading={loading}
        >
          Sign in
        </Button>
      </Form>

      {!loading && <SignInFormFooter /> }
    </FormContainer>
  );
}

export default SignInForm;
