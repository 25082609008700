import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import Form from '../ui/message/MessageForm';
import SubjectInput from '../ui/message/MessageSubjectInput';
import TextEditor from '../ui/message/MessageTextEditor';
import FormActions from '../ui/message/MessageFormActions';
import Button from '../ui/button/Button';
import useCreateMessageMutation from './useCreateMessageMutation';

// Helper hook to redirect user onCancel or onFinish
function useRedirectFn() {
  const location = useLocation();
  const history = useHistory();

  // This is typically the url of the message details page.
  let url = '/';
  if (location.state && location.state.from) {
    url = location.state.from;
  }

  return (newMessageId) => {
    if (!newMessageId || url === '/') {
      history.push(url);
    } else {
      history.push(`${url}/${newMessageId}`);
    }
  };
}

function CreateProjectMessage() {
  const { projectId } = useParams();
  const [subject, setSubject] = React.useState('');
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty()
  );

  const handleSubjectChange = (event) => {
    const newSubject = event.target.value;
    setSubject(newSubject);
  };

  const redirectFn = useRedirectFn();

  //
  // GraphQL mutation
  //

  const [createMessage, { loading }] = useCreateMessageMutation({
    variables: { projectId },
    onCompleted: ({ createProjectMessage: message }) => {
      redirectFn(message.id);
    }
  });

  const handlePostMessage = (event) => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(' ');
    const jsonText = JSON.stringify(convertToRaw(currentContent));

    createMessage({
      variables: { subject, plainText, jsonText }
    });
  };

  return (
    <Form>
      <SubjectInput
        value={subject}
        onChange={handleSubjectChange}
      />
      <TextEditor
        editorState={editorState}
        onChange={setEditorState}
      />
      <FormActions>
        <Button
          size='small'
          disabled={loading}
          onClick={(event) => redirectFn() }
        >
          Cancel
        </Button>
        <Button
          size='small'
          color='primary'
          disabled={loading}
          loading={loading}
          onClick={handlePostMessage}
        >
          Post this message
        </Button>
      </FormActions>
    </Form>
  );
}

export default CreateProjectMessage;
