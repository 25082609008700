import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const SOME_TODO_ITEM_MOVED_OUT_SUBSCRIPTION = gql`
  subscription OnSomeTodoItemMovedOut($todoListId: ID!) {
    someTodoItemMovedOut(todoListId: $todoListId) {
      todoItem { id }
      destination {
        todoList { id }
      }
    }
  }
`;

function SomeTodoItemMovedOutSubscriber({ todoListId }) {
  const todoListFragment = {
    fragment: gql`
      fragment TodoListTodoItemIds on TodoList {
        todoItems {
          id
        }
      }
    `,
    id: `TodoList:${todoListId}`
  };

  useSubscription(SOME_TODO_ITEM_MOVED_OUT_SUBSCRIPTION, {
    variables: { todoListId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) { return; }

      const {
        todoItem: movedItem,
        destination: { todoList: dstList }
      } = subscriptionData.data.someTodoItemMovedOut;

      const todoList = client.readFragment(todoListFragment);
      let todoItems = todoList.todoItems;

      // Defer update cache to mutation and moved into subscription.
      if (dstList.id === todoListId) { return; }

      client.writeFragment({
        ...todoListFragment,
        data: {
          ...todoList,
          todoItems: todoItems.filter(({ id }) => id !== movedItem.id)
        }
      });
    }
  });

  return null;
}

SomeTodoItemMovedOutSubscriber.propTypes = {
  todoListId: PropTypes.string.isRequired
};

export default SomeTodoItemMovedOutSubscriber;
