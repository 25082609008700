import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { TODO_ITEM_DETAILS} from '../fragments';

const CREATE_TODO_LIST_MUTATION = gql`
  mutation CreateTodoList($projectId: ID!, $name: String!) {
    createTodoList(projectId: $projectId, name: $name) {
      id
      name
      todoItems {
        ...TodoItemDetails
      }
    }
  }
  ${TODO_ITEM_DETAILS}
`;

export default function useCreateTodoListMutation(options) {
  return useMutation(CREATE_TODO_LIST_MUTATION, options);
}
