import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DialogTitle from '../../ui/dialog/MultiStepTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import ConfirmText from './DeleteFolderConfirmText';
import ConfirmCheckbox from './DeleteFolderConfirmCheckbox';
import useDeleteFolderMutation from '../hooks/useDeleteFolderMutation';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

// TODO: Inform user that he/she can only delete empty folders, or
// better yet, don't show this dialog when folder is not empty.
function DeleteFolderConfirmDialog({ id, name, parentUrl }) {
  const history = useHistory();
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  const { closeDialog } = React.useContext(DialogContext);
  const [yes, setYes] = React.useState(false);

  const handleYesChange = (event) => {
    setYes(event.target.checked);
  };

  const [deleteFolder, { loading }] = useDeleteFolderMutation({
    variables: { id },
    onCompleted: (data) => {
      closeDialog();
      history.push(parentUrl);
    },
    onError(error) {
      closeDialog();
      enqueueGraphQLErrorSnackbar(error);
    },
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Delete this folder?
      </DialogTitle>

      <DialogContent>
        <ConfirmText folderName={name} />
        <ConfirmCheckbox
          value={yes}
          onChange={handleYesChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='danger'
          disabled={!yes || loading}
          loading={loading}
          onClick={deleteFolder}
        >
          Yes, delete it
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

DeleteFolderConfirmDialog.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentUrl: PropTypes.string.isRequired
};

export default DeleteFolderConfirmDialog;
