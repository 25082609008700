import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const PROJECT_DIRECTORY_FILES_ADDED_SUBSCRIPTION = gql`
  subscription OnProjectDirectoryFilesAdded(
    $projectId: ID!,
    $directoryId: ID!
  ) {
    projectDirectoryFilesAdded(
      projectId: $projectId,
      directoryId: $directoryId
    )
  }
`;

function DirectoryFilesAddedSubscription({
  projectId,
  directoryId,
  refetchDirectoryFiles
}) {
  useSubscription(PROJECT_DIRECTORY_FILES_ADDED_SUBSCRIPTION, {
    variables: { projectId, directoryId },
    onSubscriptionData: () => refetchDirectoryFiles()
  });

  return null;
}

DirectoryFilesAddedSubscription.propTypes = {
  projectId: PropTypes.string.isRequired,
  directoryId: PropTypes.string.isRequired,
  refetchDirectoryFiles: PropTypes.func.isRequired
};

export default DirectoryFilesAddedSubscription;
