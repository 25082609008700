import { useCallback } from 'react';
import gql from 'graphql-tag';
import { isEqual } from 'date-fns';
import useMutation from '../../new-ui/graphql/useMutation';
import {parseDateInput} from '../../new-ui/datetime-picker/parseDateInput';

const UPDATE_EVENT_TIME_MUTATION = gql`
  mutation UpdateEventTime(
    $eventId: ID!,
    $startsAt: DateTime!,
    $endsAt: DateTime!
  ) {
    updateEvent(
      eventId: $eventId,
      changes: { startsAt: $startsAt, endsAt: $endsAt }
    ) {
      id
      startsAt
      endsAt
    }
  }
`;

export default function useUpdateEventTime() {
  const [mutate, mutationResults] = useMutation(
    UPDATE_EVENT_TIME_MUTATION,
    {
      loadingMessage: 'Saving...',
      successMessage: 'Event saved'
    }
  );

  const mutateFn = useCallback((event, { startsAt, endsAt }) => {
    const newStart = parseDateInput(startsAt);
    const newEnd = parseDateInput(endsAt);

    const oldStart = parseDateInput(event.startsAt);
    const oldEnd = parseDateInput(event.endsAt);

    if (isEqual(newStart, oldStart) && isEqual(newEnd, oldEnd)) {
      return;
    }

    mutate({
      variables: {
        eventId: event.id,
        startsAt: newStart,
        endsAt: newEnd
      },

      optimisticResponse: {
        updateEvent: {
          ...event,
          startsAt,
          endsAt
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
