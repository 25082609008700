import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ProjectHeaderProjectName from './ProjectHeaderProjectName';
import ProjectHeaderProjectTeam from './ProjectHeaderProjectTeam';
import ProjectHeaderProjectToolbar from './ProjectHeaderProjectToolbar';

const useStyles = makeStyles((theme) => ({
  projectDetails: {
    flex: '1 1 auto',
    width: 0,
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
}));

function ProjectHeaderProjectDetails({
  id,
  name,
  color,
  pinned,
  archived,
  team
}) {
  const classes = useStyles();

  return (
    <div className={classes.projectDetails}>
      <div className={classes.title}>
        <ProjectHeaderProjectName name={name} />
        <ProjectHeaderProjectToolbar
          id={id}
          color={color}
          pinned={pinned}
          archived={archived}
        />
      </div>
      <ProjectHeaderProjectTeam
        teamId={team.id}
        teamName={team.name}
      />
    </div>
  );
}

ProjectHeaderProjectDetails.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  pinned: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default ProjectHeaderProjectDetails;
