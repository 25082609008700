import React from 'react';
import Select from '../../new-ui/PrioritySelect';

export default function PrioritySelect(props) {
  return (
    <Select
      placeholderVariant='body2'
      placeholderColor='secondary'
      {...props}
    />
  );
}
