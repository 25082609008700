import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { useProjectContainer } from '../../../project/ProjectContainer';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid #EBEDF0',
    width: 600,
    marginTop: 160 /* 96 + 64 */,
    height: `calc(100% - 160px)`,
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: 152 /* 96 + 64 - 8 */,
      height: `calc(100% - 152px)`,
    }
  },
  paperFullscreen: {
    marginTop: 96,
    height: `calc(100% - 96px)`
  }
}));

export default function StyledScheduleDrawer(props) {
  const classes = useStyles();
  const { fullscreen } = useProjectContainer();

  return (
    <Drawer
      variant='persistent'
      elevation={0}
      anchor='right'
      classes={{paper: clsx(classes.paper, {
        [classes.paperFullscreen]: fullscreen
      })}}
      BackdropProps={{invisible: true}}
      {...props}
    />
  );
}
