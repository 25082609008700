import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import MonthGrid from './ui/MonthGrid';
import MonthGridHeader from './ui/MonthGridHeader';
import MonthGridBody from './ui/MonthGridBody';
import MonthGridRow from './ui/MonthGridRow';
import MonthGridDaysRow from './MonthGridDaysRow';
import MonthGridEventsRow from './MonthGridEventsRow';
import { getDaysOfWeek } from './helpers';
import EventsListProvider from './EventsListProvider';
import DragDropProvider from './MonthGridDragDropProvider';

import MonthGridLoading from './ui/MonthGridLoading';
import ProjectSchedulesQuery from './ProjectSchedulesQuery';
import { monthGrid } from './constants';
import getMonthGridForDate from './getMonthGridForDate';

function MonthlyEvents({ startOfMonth }) {
  const { projectId } = useParams();
  const gridRef = React.useRef(null);

  const daysOfWeek = getDaysOfWeek({
    startsWithSunday: monthGrid.startsWithSunday
  });

  const {
    grid,
    lowerBound,
    upperBound
  } = getMonthGridForDate(startOfMonth);

  const getDayAtCell = React.useCallback(({ rowIndex, colIndex }) => {
    return grid[rowIndex][colIndex];
  }, [grid]);

  return (
    <ProjectSchedulesQuery
      fetchPolicy='cache-and-network'
      projectId={projectId}
      start={lowerBound}
      end={upperBound}
      onLoading={() => (
        <MonthGridLoading
          startOfMonth={startOfMonth}
          daysOfWeek={daysOfWeek}
          daysGrid={grid}
        />
      )}
    >
      {(schedules) => (
        <EventsListProvider events={schedules}>
          <DragDropProvider
            numRows={grid.length}
            numCols={7}
            gridRef={gridRef}
            getDayAtCell={getDayAtCell}
          >
            <MonthGrid>
              <MonthGridHeader daysOfWeek={daysOfWeek} />
              <MonthGridBody ref={gridRef}>
                {grid.map((days, index) => (
                  <MonthGridRow key={index}>
                    <MonthGridDaysRow
                      projectId={projectId}
                      days={days}
                      startOfMonth={startOfMonth}
                    />
                    <MonthGridEventsRow
                      projectId={projectId}
                      days={days}
                    />
                  </MonthGridRow>
                ))}
              </MonthGridBody>
            </MonthGrid>
          </DragDropProvider>
        </EventsListProvider>
      )}
    </ProjectSchedulesQuery>
  );
}

MonthlyEvents.propTypes = {
  startOfMonth: PropTypes.instanceOf(Date).isRequired
};

export default MonthlyEvents;
