import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';

export default function RolesCounterSkeleton() {
  return (
    <React.Fragment>
      <div>
        <Skeleton variant='rect' width={64} height={64} />
        <Skeleton variant='text' />
      </div>
      <Divider flexItem orientation='vertical' />
      <div>
        <Skeleton variant='rect' width={64} height={64} />
        <Skeleton variant='text' />
      </div>
      <Divider flexItem orientation='vertical' />
      <div>
        <Skeleton variant='rect' width={64} height={64} />
        <Skeleton variant='text' />
      </div>
    </React.Fragment>
  );
}
