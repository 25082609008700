import React from 'react';
import PropTypes from 'prop-types';
import SubscribersInput from './SubscribersInput';
import useUpdateSubscribersMutation from './useUpdateSubscribersMutation';

const isESC = (keyCode) => keyCode === 27;

function UpdateSubscribers({
  projectId,
  eventId,
  subscribers,
  open,
  onClose
}) {
  const [value, setValue] = React.useState(subscribers);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const [updateSubscribers] = useUpdateSubscribersMutation({
    variables: { eventId }
  });

  const handleUpdateSubscribers = (e) => {
    const userIds = value.map(({ id }) => id);
    updateSubscribers({ variables: { userIds } });
    onClose(e);
  };

  const handleKeyDown = (e) => {
    const key = e.which || e.keyCode;

    if (isESC(key)) {
      e.preventDefault();
      handleUpdateSubscribers(e);
    }
  };

  React.useEffect(() => {
    setValue(subscribers);
  }, [subscribers]);

  return open ? (
    <SubscribersInput
      projectId={projectId}
      value={value}
      onChange={handleChange}
      onBlur={handleUpdateSubscribers}
      onKeyDown={handleKeyDown}
    />
  ) : null;
}

UpdateSubscribers.propTypes = {
  projectId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  subscribers: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UpdateSubscribers;
