import formatTimeOfDay
from '../../../new-ui/datetime-picker/formatTimeOfDay';
import { TIMELINE } from './constants';

const lowerBound = TIMELINE[0];
const upperBound = TIMELINE[TIMELINE.length - 1];

function _toMinutes({ hours, minutes }) {
  return hours * 60 + minutes;
}

function _toTime(totalMinutes) {
  const hours = ~~(totalMinutes / 60) % 24;
  const minutes = totalMinutes % 60;
  return formatTimeOfDay({ hours, minutes });
}

// Adds delta to the given time
export default function addTime(time, delta) {
  const totalMinutes = _toMinutes(time) + _toMinutes(delta);

  if (totalMinutes <= _toMinutes(lowerBound)) {
    return lowerBound;
  }

  if (totalMinutes >= _toMinutes(upperBound)) {
    return upperBound;
  }

  return _toTime(totalMinutes);
}
