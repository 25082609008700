import React from 'react';
import PropTypes from 'prop-types';
import List from './ui/CommentList';
import ListItem from '../../ui/comments/ListItem';
import useUpdateCommentMutation from './useUpdateCommentMutation';
import useDeleteCommentMutation from './useDeleteCommentMutation';
import { FILE_COMMENTS_QUERY } from './CommentsQuery';
import CommentCreatedSubscriber from './CommentCreatedSubscriber';
import CommentUpdatedSubscriber from './CommentUpdatedSubscriber';
import CommentDeletedSubscriber from './CommentDeletedSubscriber';
import { generateFileCommentHtmlId } from './helpers';

function CommentList({ projectId, fileId, comments, subscribeToMore }) {
  const [updateComment] = useUpdateCommentMutation({
    variables: { fileId }
  });

  const [deleteComment] = useDeleteCommentMutation({
    variables: { fileId },
    update: (cache, { data: { deleteFileComment }}) => {
      const deletedId = deleteFileComment.id;

      const data = cache.readQuery({
        query: FILE_COMMENTS_QUERY,
        variables: { projectId, fileId }
      });

      const comments = data.project.upload.comments.filter(({ id }) => (
        id !== deletedId
      ));

      cache.writeQuery({
        query: FILE_COMMENTS_QUERY,
        variables: { projectId, fileId },
        data: {
          project: {
            ...data.project,
            upload: {
              ...data.project.upload,
              comments: comments
            }
          }
        }
      });
    }
  });

  const handleUpdate = (commentId, data) => {
    updateComment({
      variables: { commentId, changes: data },
      optimisticResponse: {
        updateFileComment: {
          id: commentId,
          text: data.text,
          files: [],
          updatedAt: new Date().toISOString(),
          __typename: 'Comment'
        }
      }
    });
  };

  const handleDelete = (commentId) => {
    deleteComment({
      variables: { commentId },
      optimisticResponse: {
        deleteFileComment: {
          id: commentId,
          __typename: 'Comment'
        }
      }
    });
  };

  return (
    <List>
      {comments.map((comment) => (
        <ListItem
          key={comment.id}
          id={generateFileCommentHtmlId(fileId, comment.id)}
          comment={comment}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          readOnly={comment.autoGenerated}
          variant={comment.severity}
          projectId={projectId}
        />
      ))}
      <CommentCreatedSubscriber
        fileId={fileId}
        subscribeToCommentCreated={subscribeToMore}
      />
      <CommentUpdatedSubscriber fileId={fileId} />
      <CommentDeletedSubscriber
        fileId={fileId}
        subscribeToCommentDeleted={subscribeToMore}
      />
    </List>
  );
}

CommentList.propTypes = {
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,

  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string
    }).isRequired,
    updatedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  })).isRequired
};

export default CommentList;
