import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../../ui/comments/ListItem';
import List from '../../ui/message/CommentList';
import useUpdateCommentMutation from './useUpdateCommentMutation';
import useDeleteCommentMutation from './useDeleteCommentMutation';
import CommentCreatedSubscriber from './CommentCreatedSubscriber';
import CommentUpdatedSubscriber from './CommentUpdatedSubscriber';
import CommentDeletedSubscriber from './CommentDeletedSubscriber';
import { MESSAGE_COMMENTS_QUERY } from './CommentsQuery';
import { generateMessageCommentHtmlId } from './helpers';

function CommentList({ projectId, messageId, comments, subscribeToMore }) {
  const [updateComment] = useUpdateCommentMutation({
    variables: { messageId }
  });

  const [deleteComment] = useDeleteCommentMutation({
    variables: { messageId },
    update: (cache, { data: { deleteMessageComment }}) => {
      const deletedId = deleteMessageComment.id;

      const data = cache.readQuery({
        query: MESSAGE_COMMENTS_QUERY,
        variables: { projectId, messageId }
      });

      const comments = data.projectMessage.comments.filter(({ id }) => (
        id !== deletedId
      ));

      cache.writeQuery({
        query: MESSAGE_COMMENTS_QUERY,
        variables: { projectId, messageId },
        data: {
          projectMessage: {
            ...data.projectMessage,
            comments: comments
          }
        }
      });
    }
  });

  const handleUpdate = (commentId, data) => {
    updateComment({
      variables: { commentId, changes: data },
      optimisticResponse: {
        updateMessageComment: {
          id: commentId,
          text: data.text,
          files: [],
          updatedAt: new Date().toISOString(),
          __typename: 'Comment'
        }
      }
    });
  };

  const handleDelete = (commentId) => {
    deleteComment({
      variables: { commentId },
      optimisticResponse: {
        deleteMessageComment: {
          id: commentId,
          __typename: 'Comment'
        }
      }
    });
  };

  return (
    <List>
      {comments.map((comment) => (
        <ListItem
          key={comment.id}
          id={generateMessageCommentHtmlId(messageId, comment.id)}
          comment={comment}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          readOnly={comment.autoGenerated}
          variant={comment.severity}
          projectId={projectId}
        />
      ))}
      <CommentCreatedSubscriber
        messageId={messageId}
        subscribeToCommentCreated={subscribeToMore}
      />
      <CommentUpdatedSubscriber messageId={messageId} />
      <CommentDeletedSubscriber
        messageId={messageId}
        subscribeToCommentDeleted={subscribeToMore}
      />
    </List>
  );
}

CommentList.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,

  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string
    }).isRequired,
    updatedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  })).isRequired
};

export default CommentList;
