import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import Cell from './Cell';

function FileSizeCell({ size }) {
  return (
    <Cell align='right'>
      {size ? filesize(size, {round: 1, base: 10}) : '--'}
    </Cell>
  );
}

FileSizeCell.propTypes = {
  // File size in bytes or null if directory.
  size: PropTypes.number
};

export default FileSizeCell;
