export const CHIP_HEIGHT = 20;
export const CHIP_GAP = 2;

export const CHIP_DEFAULT_COLOR = '#039be5';

export const CHIP_COLOR_OPTIONS = [
  {name: 'default', hex: CHIP_DEFAULT_COLOR},
  {name: 'grape', hex: '#7B64C0'},
  {name: 'cherry', hex: '#E04C60'},
  {name: 'lime', hex: '#629824'},
  {name: 'orange', hex: '#E07A2E'},
  {name: 'pink', hex: '#D4539B'},
  {name: 'seafoam', hex: '#2088AF'},
  {name: 'teal', hex: '#31A38D'},
  {name: 'tomato', hex: '#EF6632'},
  {name: 'slate', hex: '#688694'},
  {name: 'skin', hex: '#A67B4F'},
  {name: 'blue-gray', hex: '#5F6673'},
  {name: 'blue-violet', hex: '#ac39ff'},
  {name: 'aluminum', hex: '#4B8096'},
  {name: 'green', hex: '#31A24C'},
  {name: 'red', hex: '#ff0053'}
];

export function isValidChipColor(color) {
  return CHIP_COLOR_OPTIONS.some(({ hex }) => hex === color);
}

export const chipHeightStyle = Object.freeze({
  // These two attributes make the height of the chip contatiner to always
  // be `CHIP_HEIGHT`
  fontSize: `${CHIP_HEIGHT/16}rem`,
  height: '1em',
});

// Helper function to get the position style of the chip container.
// - position: position of the chip within a cell
// - colIndex: which column?
// - colSpan: how many columns does this chip span?
export const getChipPosition = ({ position, colIndex, colSpan }) => ({
  top: `${position}em`,
  left: `${colIndex * 100/7}%`,
  width: `${colSpan * 100/7}%`
});

// See `RowEvents.js` for more details.
export const INTERSECTION_THRESHOLD = [
  0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
  0.7, 0.75, 0.8, 0.85, 0.90, 0.95, 1
];

// Returns `true` if chip at the given position is overflow, `false`
// otherwise. (TODO: more docs please...)
export const isOverflow = (position, intersectionEntry) => {
  if (intersectionEntry) {
    const { intersectionRatio, rootBounds } = intersectionEntry;
    const bottom = CHIP_HEIGHT * (position + 1);
    if (intersectionRatio < 1 && bottom >= rootBounds.height) {
      return true;
    }
  }

  return false;
}
