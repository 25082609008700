import React from 'react';
import PropTypes from 'prop-types';
import MuiContainer from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto'
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: 0
    }
  },
  paper: {
    borderRadius: 8,
    padding: theme.spacing(0.75, 2),
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3)
    }
  },
  sentinel: {
    width: '100%',
    height: 1
  }
}));

// This const determines how far from the bottom should the fetchMore
// be triggered.
const verticalMargin = 0;

function Container({ children, onScrollToBottom }) {
  const classes = useStyles();
  const rootRef = React.useRef();
  const bottomSentinelRef = React.useRef();

  React.useEffect(() => {
    const options = {
      root: rootRef.current,
      rootMargin: `${verticalMargin}px 0px`,
      threshold: 1.0
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && onScrollToBottom) {
          onScrollToBottom();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(bottomSentinelRef.current);

    return () => observer.disconnect();
  }, [onScrollToBottom]);

  return (
    <div ref={rootRef} className={classes.root}>
      <MuiContainer maxWidth='sm' className={classes.container}>
        <Paper className={classes.paper} elevation={0}>
          {children}
        </Paper>
        <div ref={bottomSentinelRef} className={classes.sentinel} />
      </MuiContainer>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  onScrollToBottom: PropTypes.func
};

export default Container;
