import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_CURRENT_USER_QUERY } from '../CurrentUserQuery';

const SIGNUP_MUTATION = gql`
  mutation SignUp(
    $username: String!,
    $email: String!,
    $password: String!
  ) {
    signup(username: $username, email: $email, password: $password) {
      token
      user {
        id
        username
        receivedInvitations {
          id
          workspace {
            name
          }
        }
      }
    }
  }
`;

export default function useSignUpMutation(options) {
  const { onFinished, ...otherOptions } = options;

  return useMutation(SIGNUP_MUTATION, {
    update(cache, { data }) {
      cache.writeQuery({
        query: GET_CURRENT_USER_QUERY,
        data: { me: data.signup.user }
      });
    },
    onCompleted(data) {
      localStorage.setItem('auth-token', data.signup.token);
      onFinished && onFinished(data.signup.user);
    },
    ...otherOptions
  });
}
