import React from 'react';
import PropTypes from 'prop-types';
import CurrentUserProfileQuery
from '../../../account/CurrentUserProfileQuery';
import CreateCommentForm from './CreateCommentForm';

function CreateComment({ todoId, projectId }) {
  return (
    <CurrentUserProfileQuery>
      {(currentUser) => (
        <CreateCommentForm
          user={currentUser}
          todoId={todoId}
          projectId={projectId}
        />
      )}
    </CurrentUserProfileQuery>
  );
}

CreateComment.propTypes = {
  todoId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default CreateComment;
