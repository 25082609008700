import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

// We explicitly don't want to get any data back from the mutation
// result, but from subscription instead.
const UPDATE_TEAM_MUTATION = gql`
  mutation UpdateTeam($teamId: ID!, $changes: TeamAttribute!) {
    updateTeam(teamId: $teamId, changes: $changes) {
      id
    }
  }
`;

export default function useUpdateTeamMutation(options) {
  return useMutation(UPDATE_TEAM_MUTATION, {...options});
}
