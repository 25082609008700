import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const TEAM_PROJECT_ARCHIVED_CHANGED_SUBSCRIPTION = gql`
  subscription OnTeamProjectArchivedChanged($teamId: ID!) {
    teamProjectArchivedChanged(teamId: $teamId) {
      id
      archived
    }
  }
`;


function ProjectArchivedChangedSubscriber(props) {
  const {
    teamId,
    subscribeToProjectArchivedChanged,
    refetchProjectSearchResults,
    archivedFilter
  } = props;

  React.useEffect(() => {
    const unsubscribe = subscribeToProjectArchivedChanged({
      document: TEAM_PROJECT_ARCHIVED_CHANGED_SUBSCRIPTION,
      variables: { teamId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        const project = subscriptionData.data.teamProjectArchivedChanged;
        if ((archivedFilter && !project.archived) ||
            (!archivedFilter && project.archived)) {
          return {
            team: {
              ...prev.team,
              projects: prev.team.projects.filter(({ id }) => (
                id !== project.id
              ))
            }
          };
        } else {
          refetchProjectSearchResults();
        }
      }
    });
    return () => unsubscribe();
  }, [
    teamId,
    subscribeToProjectArchivedChanged,
    refetchProjectSearchResults,
    archivedFilter
  ]);

  return null;
}

ProjectArchivedChangedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired,
  subscribeToProjectArchivedChanged: PropTypes.func.isRequired,
  refetchProjectSearchResults: PropTypes.func.isRequired,
  archivedFilter: PropTypes.bool.isRequired
};

export default ProjectArchivedChangedSubscriber;
