import React from 'react';
import PropTypes from 'prop-types';
import { projectOption } from './OptionList';
import EditProject from './EditProject';
import ChangeAvatar from './ChangeAvatar';
import ReassignOwner from './ReassignOwner';
import ArchiveConfirm from './ArchiveConfirm';
import DeleteConfirm from './DeleteConfirm';

function getDialogContentForOption(option, props) {
  const { project, ...rest } = props;
  const { id, name, description, color } = project;

  switch(option) {
    case projectOption.EDIT_PROJECT:
      return (
        <EditProject
          id={id}
          name={name}
          description={description}
          {...rest}
        />
      );

    case projectOption.CHANGE_AVATAR:
      return (
        <ChangeAvatar
          id={id}
          name={name}
          color={color}
          {...rest}
        />
      );

    case projectOption.REASSIGN_OWNER:
      return (
        <ReassignOwner
          id={id}
          name={name}
          {...rest}
        />
      );

    case projectOption.ARCHIVE_PROJECT:
      return (
        <ArchiveConfirm
          id={id}
          name={name}
          {...rest}
        />
      );

    case projectOption.DELETE_PROJECT:
      return (
        <DeleteConfirm
          id={id}
          name={name}
          {...rest}
        />
      );

    default:
      return null;
  }
};

function OptionDialogContent({ option, ...rest }) {
  return getDialogContentForOption(option, rest);
}

OptionDialogContent.propTypes = {
  option: PropTypes.oneOf(Object.values(projectOption))
};

export default OptionDialogContent;
