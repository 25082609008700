import PropTypes from 'prop-types';

export const TimelineItemShape = PropTypes.shape({
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
});

export const TimeSpecificScheduleShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  start: TimelineItemShape.isRequired,
  end: TimelineItemShape.isRequired
});
