import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import DialogActions from '../../ui/dialog/DialogActions';

function DialogActionsContainer({ container, children }) {
  return container ? ReactDOM.createPortal(
    <DialogActions>
      {children}
    </DialogActions>,
    container
  ) : null;
}

DialogActionsContainer.propTypes = {
  // The DOM node for `React.Portal`
  container: PropTypes.object,

  children: PropTypes.node
};

export default DialogActionsContainer;
