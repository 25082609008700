import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
import { useHistory } from 'react-router-dom';
import StyledViewNavigator from './ui/ViewNavigator';
import {
  useDateParam,
  getDateSlug,
  getPrevDateSlug,
  getNextDateSlug,
  getWeekSlug,
  getPrevWeekSlug,
  getNextWeekSlug,
  getMonthSlug,
  getPrevMonthSlug,
  getNextMonthSlug
} from './helpers';

import weekViewLabel from './weekly-schedule/utils/weekViewLabel';

function getTitle({ currentDate, activeView }) {
  if (!isValid(currentDate)) {
    return null;
  }

  switch(activeView) {
    case 'day':
      return format(currentDate, 'MMMM d, yyyy');
    case 'week':
      return weekViewLabel(currentDate);
    case 'month':
      return format(currentDate, 'MMMM yyyy');
    default:
      return null;
  }
}

function ViewNavigator({ activeView, rootUrl }) {
  const currentDate = useDateParam();
  const history = useHistory();

  const navigateTo = (slug) => {
    history.push(`${rootUrl}/${activeView}/${slug}`);
  };

  const handlePrev = (event) => {
    if (!isValid(currentDate)) {
      return;
    }

    let slug;

    switch(activeView) {
      case 'day':
        slug = getPrevDateSlug(currentDate);
        break;
      case 'week':
        slug = getPrevWeekSlug(currentDate);
        break;
      case 'month':
        slug = getPrevMonthSlug(currentDate);
        break;
      default:
        break;
    }

    if (slug) {
      navigateTo(slug);
    }
  };

  const handleNext = (event) => {
    if (!isValid(currentDate)) {
      return;
    }

    let slug;

    switch(activeView) {
      case 'day':
        slug = getNextDateSlug(currentDate);
        break;
      case 'week':
        slug = getNextWeekSlug(currentDate);
        break;
      case 'month':
        slug = getNextMonthSlug(currentDate);
        break;
      default:
        break;
    }

    if (slug) {
      navigateTo(slug);
    }
  };

  const handleCurr = (event) => {
    let slug;

    switch(activeView) {
      case 'day':
        slug = getDateSlug(new Date());
        break;
      case 'week':
        slug = getWeekSlug(new Date());
        break;
      case 'month':
        slug = getMonthSlug(new Date());
        break;
      default:
        break;
    }

    if (slug) {
      navigateTo(slug);
    }
  };

  return (
    <StyledViewNavigator
      title={getTitle({ currentDate, activeView })}
      onPrev={handlePrev}
      onCurr={handleCurr}
      onNext={handleNext}
    />
  );
}

ViewNavigator.propTypes = {
  activeView: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  rootUrl: PropTypes.string.isRequired
};

export default ViewNavigator;
