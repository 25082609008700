import React from 'react';
import MarginRow from './MarginRow';

function SectionHeaderContainer({ children }) {
  return (
    <tbody>
      <MarginRow />
      {children}
    </tbody>
  );
}

export default React.memo(SectionHeaderContainer);
