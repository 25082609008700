import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import Directory from './Directory';
import Upload from './Upload';

function ProjectFiles({ projectId }) {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={`${url}/directories/root`} />
      </Route>
      <Route path={`${path}/directories/:directoryId`} exact>
        <Directory rootUrl={url} projectId={projectId} />
      </Route>
      <Route path={`${path}/uploads/:uploadId`} exact>
        <Upload rootUrl={url} projectId={projectId} />
      </Route>
    </Switch>
  );
}

export default ProjectFiles;
