import React from 'react';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
    width: theme.spacing(60),
    padding: 0,
    boxShadow: theme.customShadow.menu
  },
  listRoot: {
    padding: theme.spacing(1.5),
    paddingRight: `${theme.spacing(1.5)}px !important`,
    backgroundColor: theme.palette.background.paper
  },
  listSubheader: {
    paddingTop: 0
  }
}))(({ classes, MenuListProps, ...rest }) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    classes={{
      paper: classes.paper
    }}
    MenuListProps={{
      ...MenuListProps,
      classes: {
        root: classes.listRoot,
        subheader: classes.listSubheader
      }
    }}
    {...rest}
  />
));
