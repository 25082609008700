import React from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import WorkspaceContext from '../../workspace/CurrentWorkspaceContext';
import { USER_PROFILE_FRAGMENT } from '../fragments';
import { MEMBERSHIP_DETAILS_FRAGMENT, MEMBERSHIP_WORKSPACE_FRAGMENT }
from '../../member/fragments';

const GET_CURRENT_ACCOUNT_QUERY = gql`
  query GetCurrentAccount($currentWorkspaceId: ID!) {
    me {
      ...UserProfile
      workspaceMemberships(workspaceId: $currentWorkspaceId) {
        ...MembershipDetails
        ...MembershipWorkspace
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${MEMBERSHIP_DETAILS_FRAGMENT}
  ${MEMBERSHIP_WORKSPACE_FRAGMENT}
`;

export default function useGetCurrentAccountLazyQuery(options) {
  const { currentWorkspaceId } = React.useContext(WorkspaceContext);
  return useLazyQuery(GET_CURRENT_ACCOUNT_QUERY, {
    variables: { currentWorkspaceId },
    ...options
  });
}
