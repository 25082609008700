import React from 'react';
import PropTypes from 'prop-types';
import { PROJECT_MEMBER_REMOVED_SUBSCRIPTION }
from '../hooks/useProjectMemberRemovedSubscription';

function MemberRemovedSubscriber({
  projectId,
  subscribeToMemberRemoved
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToMemberRemoved({
      document: PROJECT_MEMBER_REMOVED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return; }

        let removedMember = subscriptionData.data.projectMemberRemoved;

        const newMembers = prev.project.members.filter(({ id }) => (
          id !== removedMember.id
        ));

        return {
          project: {
            ...prev.project,
            members: newMembers
          }
        };
      }
    });

    return () => unsubscribe();
  }, [projectId, subscribeToMemberRemoved]);

  return null;
}

MemberRemovedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired,
  subscribeToMemberRemoved: PropTypes.func.isRequired
};

export default MemberRemovedSubscriber;
