import React from 'react';
import MembersCountItem from './MembersCountItem';
import PendingInvitesCounterSkeleton from './PendingInvitesCounterSkeleton';
import InvitationsInCurrentWorkspaceQuery
from './InvitationsInCurrentWorkspaceQuery';

function PendingInvitesCounter() {
  return (
    <InvitationsInCurrentWorkspaceQuery
      onLoading={() => <PendingInvitesCounterSkeleton /> }
    >
      {(invitations) => {
        const pendingInvitesCount = invitations.filter(({ state }) => {
          return state === 'pending';
        }).length;
        return (
          <MembersCountItem
            label='Pending invites'
            count={pendingInvitesCount}
          />
        );
      }}
    </InvitationsInCurrentWorkspaceQuery>
  );
}

export default PendingInvitesCounter;
