import React from 'react';
import ZoomOutIcon from '../../icons/18x18/MinusMagnifyingGlass';
import IconButton from './ToolbarIconButton';

export default function FileDetailsZoomOutButton(props) {
  return (
    <IconButton
      aria-label='Zoom out'
      color={props.disabled ? 'disabled' : 'secondary'}
      icon={<ZoomOutIcon />}
      {...props}
    />
  );
}
