import { withStyles } from '@material-ui/core/styles';
import TextField from '../input/TextField';

const ToolbarInput = withStyles((theme) => ({
  root: {
    '& .MuiInput-root': {
      padding: theme.spacing(0.5, 1),
      fontSize: '0.8125rem',
      width: 192
    },
    '& .MuiInputBase-input': {
      lineHeight: '1.1538461538em',
      height: '1.1538461538em',
      paddingTop: 1,
      paddingBottom: 2,
      '&::placeholder': {
        fontSize: '0.8125rem'
      }
    }
  },
}))(TextField);

export default ToolbarInput;
