import React from 'react';
import PropTypes from 'prop-types';
import AssigneeAutocomplete from './AssigneeAutocomplete';
import useActiveProjectMembersLazyQuery
from '../../project/hooks/useActiveProjectMembersLazyQuery';

function AssigneePicker({ projectId, value, onChange, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [
    loadActiveProjectMembers,
    { called, loading }
  ] = useActiveProjectMembersLazyQuery({
    projectId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const users = data.project.members.map(({ user }) => user );
      setOptions(users);
    }
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  // Fetch whenever open
  React.useEffect(() => {
    if (open) {
      loadActiveProjectMembers();
    }
  }, [open, loadActiveProjectMembers]);

  // TODO: do we really need to reset the options when this shit closes,
  // and also the user of `network-only` fetch policy above?
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <AssigneeAutocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      loading={called && loading}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
}

AssigneePicker.propTypes = {
  projectId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }),
  onChange: PropTypes.func
};

export default AssigneePicker;
