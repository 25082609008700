import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TrapFocus from '../ui/misc/TrapFocus';

const useStyles = makeStyles((theme) => ({
  projectContainer: {
    // https://stackoverflow.com/questions/67331031/
    // how-can-i-make-my-layout-adapt-to-the-material-ui-toolbar-height
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`
    },

    display: 'flex',
    flexDirection: 'column',
    outline: 0
  },

  fullscreen: {
    height: '100vh !important',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.modal
  }
}));

const ProjectContainerContext = React.createContext(null);
const isESC = (keyCode) => keyCode === 27;

function ProjectContainer({ children }) {
  const classes = useStyles();
  const [fullscreen, setFullscreen] = React.useState(false);

  const handleToggleFullscreen = React.useCallback(() => {
    setFullscreen((prev) => !prev);
  }, []);

  const handleKeyDown = (event) => {
    const key = event.which || event.keyCode;

    if (isESC(key) && fullscreen) {
      event.preventDefault();
      event.stopPropagation();
      setFullscreen(false);
    }
  };

  const ctx = {
    fullscreen,
    onToggleFullscreen: handleToggleFullscreen
  };

  return (
    <ProjectContainerContext.Provider value={ctx}>
      <TrapFocus trapped={fullscreen}>
        <div
          className={clsx(classes.projectContainer, {
            [classes.fullscreen]: fullscreen
          })}
          onKeyDown={handleKeyDown}
        >
          {children}
        </div>
      </TrapFocus>
    </ProjectContainerContext.Provider>
  );
}

ProjectContainer.propTypes = {
  children: PropTypes.node
};

export default ProjectContainer;

export function useProjectContainer() {
  return React.useContext(ProjectContainerContext);
}
