import React from 'react';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  paper: {
    width: theme.spacing(54),
    borderRadius: 8,
    boxShadow: theme.customShadow.menu,
    padding: theme.spacing(1.5)
  }
}))((props) => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
