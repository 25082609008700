import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import SectionSearchInput from '../ui/page/SectionSearchInput';

function MemberSearchCriteria({ criteria, onChange }) {

  const notifyOnChange = (newCriteria) => {
    onChange(newCriteria);
  };

  const handleMatchingChange = debounce((event) => {
    const newCriteria = {
      ...criteria,
      matching: event.target.value
    }
    notifyOnChange(newCriteria);
  }, 300);

  return (
    <SectionSearchInput
      placeholder='Search'
      defaultValue={criteria.matching}
      onChange={(event) => {
        event.persist();
        handleMatchingChange(event);
      }}
    />
  );
}

MemberSearchCriteria.propTypes = {
  criteria: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MemberSearchCriteria;
