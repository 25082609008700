import PdfIcon128 from '../icons/128/Pdf';
import DocIcon128 from '../icons/128/Doc';
import DocxIcon128 from '../icons/128/Docx';
import XlsIcon128 from '../icons/128/Xls';
import XlsxIcon128 from '../icons/128/Xlsx';
import PptIcon128 from '../icons/128/Ppt';
import PptxIcon128 from '../icons/128/Pptx';
import OdtIcon128 from '../icons/128/Odt';
import OdsIcon128 from '../icons/128/Ods';
import OdpIcon128 from '../icons/128/Odp';
import CsvIcon128 from '../icons/128/Csv';
import TxtIcon128 from '../icons/128/Txt';
import RtfIcon128 from '../icons/128/Rtf';
import FilePdfIcon from '../../icons/FilePdf';
import FileWordIcon from '../../icons/FileWord';
import FileExcelIcon from '../../icons/FileExcel';
import FilePowerpointIcon from '../../icons/FilePowerpoint';
import FileTextIcon from '../../icons/FileText';

import PdfIcon40 from '../icons/40/Pdf';
import DocIcon40 from '../icons/40/Doc';
import DocxIcon40 from '../icons/40/Docx';
import XlsIcon40 from '../icons/40/Xls';
import XlsxIcon40 from '../icons/40/Xlsx';
import PptIcon40 from '../icons/40/Ppt';
import PptxIcon40 from '../icons/40/Pptx';


export const DocumentMimeType = Object.freeze({
  'application/pdf': {
    description: 'PDF Document',
    Icon128: PdfIcon128,
    Icon24: FilePdfIcon,
    Icon40: PdfIcon40
  },
  'application/msword': {
    description: 'Word Document',
    Icon128: DocIcon128,
    Icon24: FileWordIcon,
    Icon40: DocIcon40
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    description: 'Word Document',
    Icon128: DocxIcon128,
    Icon24: FileWordIcon,
    Icon40: DocxIcon40
  },
  'application/vnd.ms-excel': {
    description: 'Microsoft Excel',
    Icon128: XlsIcon128,
    Icon24: FileExcelIcon,
    Icon40: XlsIcon40
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    description: 'Microsoft Excel',
    Icon128: XlsxIcon128,
    Icon24: FileExcelIcon,
    Icon40: XlsxIcon40
  },
  'application/vnd.ms-powerpoint': {
    description: 'Microsoft PowerPoint',
    Icon128: PptIcon128,
    Icon24: FilePowerpointIcon,
    Icon40: PptIcon40
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    description: 'Microsoft PowerPoint',
    Icon128: PptxIcon128,
    Icon24: FilePowerpointIcon,
    Icon40: PptxIcon40
  },
  'application/vnd.oasis.opendocument.text': {
    description: 'OpenDocument Text Document',
    Icon128: OdtIcon128,
    Icon24: FileWordIcon,
    Icon40: null
  },
  'application/vnd.oasis.opendocument.spreadsheet': {
    description: 'OpenDocument Spreadsheet Document',
    Icon128: OdsIcon128,
    Icon24: FileExcelIcon,
    Icon40: null
  },
  'application/vnd.oasis.opendocument.presentation': {
    description: 'OpenDocument Presentation Document',
    Icon128: OdpIcon128,
    Icon24: FilePowerpointIcon,
    Icon40: null
  },
  'text/csv': {
    description: 'Comma-Separated Values (CSV)',
    Icon128: CsvIcon128,
    Icon24: null,
    Icon40: null
  },
  'text/plain': {
    description: 'Plain Text Document',
    Icon128: TxtIcon128,
    Icon24: FileTextIcon,
    Icon40: null
  },
  'application/rtf': {
    description: 'Rich Text Document',
    Icon128: RtfIcon128,
    Icon24: FileTextIcon,
    Icon40: null
  }
});
