import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  li: {
    display: 'flex'
  },
  text: {
    fontWeight: 500
  }
}));

const Breadcrumb = React.forwardRef(
  function Breadcrumb({ primary, to, interactive, className }, ref) {
    const classes = useStyles();
    const isClickableLink = Boolean(to) && interactive;

    return (
      <li ref={ref} className={clsx(classes.li, className)}>
        {isClickableLink ? (
          <Link
            component={NavLink}
            variant='body2'
            noWrap
            to={to}
            className={classes.text}
          >
            {primary}
          </Link>
        ) : (
          <Typography
            noWrap
            variant='body2'
            color='textSecondary'
            className={classes.text}
          >
            {primary}
          </Typography>
        )}
      </li>
    );
  }
);

Breadcrumb.propTypes = {
  // If `interactive` is set to `true`, it means that this is a link
  // that user can click on to navigate to another page. In this
  // case `to` is required
  interactive: PropTypes.bool,

  to: PropTypes.string,
  primary: PropTypes.string.isRequired
};

Breadcrumb.defaultProps = {
  interactive: true
};

export default Breadcrumb;
