import React from 'react';
import TextField from '../../ui/input/TextField';

export default function NameInput(props) {
  return (
    <TextField
      autoFocus
      autoComplete='off'
      type='text'
      name='name'
      label='Folder name'
      placeholder='Enter folder name'
      helperText='Names should be at least 3 characters.'
      {...props}
    />
  );
}
