import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { gqlErrorDetails } from '../../oops/helpers';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const UPDATE_CURRENT_USER_PROFILE_MUTATION = gql`
  mutation UpdateCurrentUserProfile($changes: UserProfile!) {
    updateMyProfile(changes: $changes) {
      id
    }
  }
`;

export default function useUpdateCurrentUserProfileMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_CURRENT_USER_PROFILE_MUTATION, {
    onError(error) {
      const errorDetails = gqlErrorDetails(error);
      const usernameError = errorDetails['username'];
      if (!usernameError) enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
