import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: 700,
    lineHeight: 1.2
  }
}));

export default function SectionTitle(props) {
  const classes = useStyles();
  return (
    <Typography
      variant='h6'
      className={classes.sectionTitle}
      {...props}
    />
  );
}
