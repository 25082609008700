import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';
import { TODO_SHEET_THEME } from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    border: TODO_SHEET_THEME.cellBorder,
    backgroundColor: theme.palette.background.paper
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(0, 2),
    alignItems: 'center'
  }
}));

export default function CellSkeleton() {
  const classes = useStyles();
  return (
    <Cell className={classes.root}>
      <div className={classes.content}>
        <Skeleton width='72%' height={16} />
      </div>
    </Cell>
  );
}
