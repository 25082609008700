import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const INVITE_PEOPLE_TO_WORKSPACE_MUTATION = gql`
  mutation InvitePeopleToWorkspace($workspaceId: ID!, $emails: [String]) {
    inviteUsersToWorkspace(workspaceId: $workspaceId, emails: $emails)
  }
`;

export default function useInvitePeopleMutation(options) {
  const {
    enqueueSuccessSnackbar,
    enqueueGraphQLErrorSnackbar
  } = useCustomSnackbar();

  const { onCompleted: onFinished, ...rest } = options;

  return useMutation(INVITE_PEOPLE_TO_WORKSPACE_MUTATION, {
    onCompleted(data) {
      onFinished && onFinished(data);
      const numInvitationsSent = data.inviteUsersToWorkspace;
      const message = `${numInvitationsSent} invitations sent`;
      enqueueSuccessSnackbar(message);
    },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...rest
  });
}
