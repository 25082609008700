import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../ui/input/TextField';

function CreateWorkspaceFormInput({ value, onChange, error }) {
  const isNameError = Boolean(error['name']);
  const nameErrorProps = isNameError ? {
    error: true,
    helperText: `A valid workspace name ${error['name']}.`
  } : {};

  //
  // TODO: This is just a hack to focus the input field after transition
  // end. See `LinearStepper` and `LinearStep` for more details.
  //

  const inputRef = React.useRef(null);
  const timer = React.useRef(null);

  // 200 is the total time of the transition
  React.useEffect(() => {
    timer.current = setTimeout(() => {
      inputRef.current.focus();
    }, 250);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <TextField
      inputRef={inputRef}
      autoFocus
      type='text'
      name='name'
      id='name'
      autoComplete='off'
      placeholder='Your company name'
      fullWidth
      required
      value={value.name}
      onChange={onChange}
      {...nameErrorProps}
    />
  );
}

CreateWorkspaceFormInput.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func,
  error: PropTypes.object
};

export default CreateWorkspaceFormInput;
