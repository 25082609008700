import React from 'react';
import Section from '../ui/page/Section';
import SectionHeader from '../ui/page/SectionHeader';
import SectionTitle from '../ui/page/SectionTitle';
import SectionSearchNotFound from '../ui/page/SectionSearchNotFound';
import MemberSearchCriteria from './MemberSearchCriteria';
import MemberSearchLoading from './MemberSearchLoading';
import MembershipGrid from './MembershipGrid';
import SearchMemberQuery from './SearchMemberInCurrentWorkspaceQuery';


export default function SearchMemberInCurrentWorkspace() {
  const [criteria, setCriteria] = React.useState({
    matching: undefined
  });

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  const renderSearchResult = (memberships) => {
    if (memberships.length === 0) {
      const message = criteria.matching ?
                      `No results for "${criteria.matching}".`:
                      'No members found.';
      return <SectionSearchNotFound message={message} />;
    }
    return <MembershipGrid memberships={memberships} />;
  };

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Members</SectionTitle>
        <MemberSearchCriteria
          criteria={criteria}
          onChange={handleCriteriaChange}
        />
      </SectionHeader>
      <SearchMemberQuery
        criteria={criteria}
        onLoading={() => <MemberSearchLoading /> }
      >
        {renderSearchResult}
      </SearchMemberQuery>
    </Section>
  );
}
