import React from 'react';
import PropTypes from 'prop-types';
import StyledSectionName from '../../ui/todo/sheet/SectionName';
import useUpdateTodoListMutation from '../hooks/useUpdateTodoListMutation';

function SectionName({
  sectionId,
  sectionName,
  editMode,
  onEditModeChange
}) {
  const [updateTodoList] = useUpdateTodoListMutation();

  const handleNameChange = (newName) => {
    let newSectionName = newName && newName.trim();

    if (!newSectionName) {
      newSectionName = 'Untitled List';
    }

    if (newSectionName === sectionName) {
      return;
    }

    updateTodoList({
      variables: {
        todoListId: sectionId,
        changes: { name: newSectionName }
      },

      optimisticResponse: {
        __typename: 'Mutation',
        updateTodoList: {
          __typename: 'TodoList',
          id: sectionId,
          name: newSectionName
        }
      }
    });
  };

  return (
    <div style={{maxWidth: 300}}>
      <StyledSectionName
        value={sectionName}
        onChange={handleNameChange}
        editMode={editMode}
        onEditModeChange={onEditModeChange}
      />
    </div>
  );
}

SectionName.propTypes = {
  // The ID and name of the section (a.k.a TodoList)
  sectionId: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired
};

export default SectionName;
