import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import DocumentIconLink from './DocumentIconLink';
import PreviewContainer from './PreviewContainer';
import Spinner from '../../progress/Spinner';

function ImagePreview({
  src,
  type,
  alt,
  maxHeight,
  downloadUrl,
  zoom
}) {
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [naturalDim, setNaturalDim] = React.useState({
    width: 0,
    height: 0
  });

  const handleLoad = (event) => {
    setLoaded(true);

    setNaturalDim({
      width: event.target.naturalWidth,
      height: event.target.naturalHeight
    });
  };

  const handleError = () => {
    setError(true);
  };

  return (
    <PreviewContainer>
      {!error ? (
        <Image
          scale={zoom/100}
          alt={alt}
          src={src}
          naturalWidth={naturalDim.width}
          naturalHeight={naturalDim.height}
          onLoad={handleLoad}
          onError={handleError}
          maxHeight={maxHeight}
        />
      ) : (
        <DocumentIconLink type={type} href={downloadUrl} />
      )}
      {!loaded && !error && (
        <Spinner size={24} />
      )}
    </PreviewContainer>
  );
}

ImagePreview.propTypes = {
  // The `alt` and `src` attributes of the image to be previewed.
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,

  // The MIME type of the image, neccessary to have for fallback icon
  // when image cannot be previewed.
  type: PropTypes.string.isRequired,

  // The maximum height applied to the container.
  // If `maxHeight` is set to `none`, the height of the image container
  // will be the same as that of the image (after the image has been
  // scaled to fit the container width).
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['none'])
  ]).isRequired,

  downloadUrl: PropTypes.string,

  // Zoom value in percentage unit, e.g 80
  zoom: PropTypes.number
};

ImagePreview.defaultProps = {
  maxHeight: 'none',
  zoom: 100
};

export default ImagePreview;
