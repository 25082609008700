import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../ui/button/IconButton';
import MoreHorizIcon from '../../ui/icons/MoreHoriz';
import MembershipMenu from './MembershipMenu';
import { ROLE_LIST } from '../helpers';

function MembershipMenuButton({ membership, currentUserRole, ...rest }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        background={Boolean(anchorEl)}
        color={Boolean(anchorEl) ? 'accent' : 'secondary'}
        icon={ <MoreHorizIcon /> }
        onClick={ handleClick }
        {...rest}
      />
      <MembershipMenu
        membership={membership}
        currentUserRole={currentUserRole}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </React.Fragment>
  );
}

MembershipMenuButton.propTypes = {
  membership: PropTypes.object.isRequired,
  currentUserRole: PropTypes.oneOf(ROLE_LIST).isRequired
};

export default MembershipMenuButton;
