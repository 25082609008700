import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import DirectoryQuery from './DirectoryQuery';
import DirectoryLoading from './DirectoryLoading';
import DirectoryFiles from './DirectoryFiles';
import SpeedDial from './directory-options/DirectorySpeedDial';

function Directory({ rootUrl, projectId }) {
  const { directoryId } = useParams();

  const getParentUrl = (directoryPath) => {
    if (!directoryPath || directoryPath.length <= 1) {
      return rootUrl;
    }

    const secondToLast = directoryPath[directoryPath.length - 2];
    return `${rootUrl}/directories/${secondToLast.id}`;
  };

  return (
    <DirectoryQuery
      projectId={projectId}
      id={directoryId}
      onLoading={() => <DirectoryLoading /> }
      fetchPolicy='network-only'
    >
      {(directory) => (
        <React.Fragment>
          <DirectoryFiles
            rootUrl={rootUrl}
            projectId={projectId}
            directory={directory}
          />
          <SpeedDial
            parentUrl={getParentUrl(directory.path)}
            projectId={projectId}
            directory={directory}
          />
        </React.Fragment>
      )}
    </DirectoryQuery>
  );
}

Directory.propTypes = {
  // The url into the root directory of a project
  rootUrl: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default Directory;
