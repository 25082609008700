import React from 'react';
import Timeline from '../../ui/timeline/Timeline';
import DateItemSkeleton from '../../ui/timeline/DateItemSkeleton';
import ActivityItemSkeleton from '../../ui/timeline/ActivityItemSkeleton';

export default function ActivitiesTimelineSkeleton() {
  return (
    <Timeline breakpoint='sm' dividerWidth={72}>
      <DateItemSkeleton left />
      <ActivityItemSkeleton left />
      <ActivityItemSkeleton left />
      <ActivityItemSkeleton left />
      <DateItemSkeleton/>
      <ActivityItemSkeleton/>
      <ActivityItemSkeleton/>
      <ActivityItemSkeleton/>
    </Timeline>
  );
}
