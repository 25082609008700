import React from 'react';
import PropTypes from 'prop-types';
import UncheckedIcon from '../../icons/20x20/RadioButtonUnchecked';
import CheckedIcon from '../../icons/20x20/RadioButtonChecked';
import ListItem from '../../ui/dialog/ListItem';
import ListItemText from '../../ui/dialog/ListItemText';
import ListItemEndIcon from '../../ui/dialog/ListItemEndIcon';

function TeamPickerItem({ selected, value, onSelect, primary, secondary }) {
  const handleClick = () => {
    if (onSelect) { onSelect(value); }
  };

  return (
    <ListItem onClick={handleClick} selected={selected}>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemEndIcon
        icon={selected ? (
          <CheckedIcon color='accent' />
        ) : (
          <UncheckedIcon color='secondary' />
        )}
      />
    </ListItem>
  );
}

TeamPickerItem.propTypes = {
  // Current team's name
  primary: PropTypes.string.isRequired,

  // Current team's desc
  secondary: PropTypes.string,

  // Set `selected` to `true` if current team id matches the `value`
  selected: PropTypes.bool,

  // The team id
  value: PropTypes.string.isRequired,

  // (value) => {...}
  onSelect: PropTypes.func
};

export default TeamPickerItem;
