import { parseISO, format, isToday, isYesterday } from 'date-fns';

export function formatCreatedTime(timestamp) {
  const date = parseISO(timestamp);
  if (isToday(date)) {
    return format(date, "'Today' 'at' HH:mm a");
  } else if (isYesterday(date)) {
    return format(date, "'Yesterday' 'at' HH:mm a");
  } else {
    return format(date, "MMMM do, yyyy 'at' HH:mm a");
  }
}
