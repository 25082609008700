import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commentCountRoot: {
    marginRight: 0
  },
  commentCountCount: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: theme.spacing(3.5),
    minWidth: theme.spacing(3.5),
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function ListItemCommentCount({ count, ...rest }) {
  const classes = useStyles();

  if (!count) {
    return null;
  } else {
    return (
      <ListItemIcon className={classes.commentCountRoot}>
        <Typography
          component='span'
          variant='body2'
          className={classes.commentCountCount}
        >
          {count}
        </Typography>
      </ListItemIcon>
    );
  }
}

ListItemCommentCount.propTypes = {
  count: PropTypes.number
};

ListItemCommentCount.defaultProps = {
  count: 0
};

export default ListItemCommentCount;
