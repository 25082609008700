import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useProjectURL from '../project/hooks/useProjectURL';
import GenericActivityItem from './ui/GenericActivityItem';
import UploadPreview from './ui/UploadPreview';

function UploadFileItem({ activity }) {
  const { type, actor, details, upload, insertedAt } = activity;
  const [fileName] = details;

  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const uploadUrl = `${projectUrl}/files/uploads/${upload.id}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='uploaded a new file called'
      linkUrl={uploadUrl}
      linkText={fileName}
      insertedAt={insertedAt}
    >
      <UploadPreview
        src={upload.previewUrl}
        type={upload.previewType}
        downloadUrl={upload.downloadUrl}
        alt={upload.name}
      />
    </GenericActivityItem>
  );
}

UploadFileItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['UPLOAD_FILE']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    upload: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default UploadFileItem;
