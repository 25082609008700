import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  authorAvatar: {
    marginRight: theme.spacing(1),
    marginTop: 10,
    flex: '0 0 auto'
  }
}));

function AuthorAvatar({ author }) {
  const classes = useStyles();
  return (
    <UserAvatar
      user={author}
      size='small'
      className={classes.authorAvatar}
    />
  );
}

AuthorAvatar.propTypes = {
  author: PropTypes.object.isRequired
};

export default AuthorAvatar;
