function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: "",
      endCursor: "",
    },
  };
}

export function merge(existing, incoming, { args } ) {
  if (!existing) {
    existing = makeEmptyData();
  }

  if (!incoming) {
    return existing;
  }

  const incomingEdges = incoming.edges || [];

  if (incoming.pageInfo) {
    const { pageInfo } = incoming;
    const { startCursor, endCursor } = pageInfo;
    const firstEdge = incomingEdges[0];
    const lastEdge = incomingEdges[incomingEdges.length - 1];

    // In case we did not request the cursor field for edges in this
    // query, we can still infer cursors from pageInfo.

    if (firstEdge && startCursor) {
      firstEdge.cursor = startCursor;
    }

    if (lastEdge && endCursor) {
      lastEdge.cursor = endCursor;
    }

    // Cursors can also come from edges, so we default
    // pageInfo.{start,end}Cursor to {first,last}Edge.cursor.

    const firstCursor = firstEdge && firstEdge.cursor;

    if (firstCursor && !startCursor) {
      incoming = {
        ...incoming,
        pageInfo: {...pageInfo, startCursor: firstCursor}
      }
    }

    const lastCursor = lastEdge && lastEdge.cursor;

    if (lastCursor && !endCursor) {
      incoming = {
        ...incoming,
        pageInfo: {...pageInfo, endCursor: lastCursor}
      }
    }
  }

  let prefix = existing.edges;
  let suffix = [];

  if (args && args.after) {
    const index = prefix.findIndex(edge => edge.cursor === args.after);
    if (index >= 0) {
      prefix = prefix.slice(0, index + 1);
    }
  } else if (args && args.before) {
    const index = prefix.findIndex(edge => edge.cursor === args.before);
    suffix = index < 0 ? prefix : prefix.slice(index);
    prefix = [];
  } else if (incoming.edges) {
    // If we have neither args.after nor args.before, the incoming
    // edges cannot be spliced into the existing edges, so they must
    // replace the existing edges.
    prefix = [];
  }

  const edges = [
    ...prefix,
    ...incomingEdges,
    ...suffix,
  ];

  const pageInfo = {
    // The ordering of these two ...spreads may be surprising, but it
    // makes sense because we want to combine PageInfo properties with a
    // preference for existing values, *unless* the existing values are
    // overridden by the logic below, which is permitted only when the
    // incoming page falls at the beginning or end of the data.
    ...incoming.pageInfo,
    ...existing.pageInfo,
  };

  if (incoming.pageInfo) {
    const {
      hasPreviousPage,
      hasNextPage,
      startCursor,
      endCursor,
      ...extras
    } = incoming.pageInfo;

    // If incoming.pageInfo had any extra non-standard properties,
    // assume they should take precedence over any existing properties
    // of the same name, regardless of where this page falls with
    // respect to the existing data.
    Object.assign(pageInfo, extras);

    // Keep existing.pageInfo.has{Previous,Next}Page unless the
    // placement of the incoming edges means incoming.hasPreviousPage
    // or incoming.hasNextPage should become the new values for those
    // properties in existing.pageInfo. Note that these updates are
    // only permitted when the beginning or end of the incoming page
    // coincides with the beginning or end of the existing data, as
    // determined using prefix.length and suffix.length.
    if (!prefix.length) {
      if (void 0 !== hasPreviousPage) {
        pageInfo.hasPreviousPage = hasPreviousPage;
      }
      if (void 0 !== startCursor) {
        pageInfo.startCursor = startCursor;
      }
    }
    if (!suffix.length) {
      if (void 0 !== hasNextPage) {
        pageInfo.hasNextPage = hasNextPage;
      }
      if (void 0 !== endCursor) {
        pageInfo.endCursor = endCursor;
      }
    }
  }

  return {
    ...existing,
    ...incoming,
    edges,
    pageInfo,
  };
}
