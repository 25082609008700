import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
  grid: {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0
  }
}));

function Table({ onRowReorder, children }) {
  const classes = useStyles();

  const handleDragEnd = (result) => {
    if (onRowReorder) {
      onRowReorder(result);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <table role='grid' className={classes.grid}>
        {children}
      </table>
    </DragDropContext>
  );
}

Table.propTypes = {
  onRowReorder: PropTypes.func,
  children: PropTypes.node
};

export default Table;
