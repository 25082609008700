import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  details: {
    fontSize: '0.9375rem',

    // line-clamp
    display: '-webkit-box',
    '-webkit-line-clamp': 3 /* max number of lines */,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
}));

function ListItemDetails({ authorName, timestamp, details, ...rest }) {
  const classes = useStyles();
  return (
    <Typography
      component='span'
      variant='body2'
      color='textSecondary'
      className={classes.details}
      {...rest}
    >
      <Box component='span' color='text.primary'>
        {authorName} &bull; {timestamp}
      </Box>
      {` — ${details}`}
    </Typography>
  );
}

ListItemDetails.propTypes = {
  // The name of the message's author
  authorName: PropTypes.string.isRequired,

  // When was this message created? e.g., 'Oct 13'
  timestamp: PropTypes.string.isRequired,

  // The text contents of the message
  details: PropTypes.string.isRequired
};

export default ListItemDetails;
