import React from 'react';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';
import { isEvent, isTodo } from '../helpers';
import useUpdateEventTime from '../mutations/useUpdateEventTime';
import useUpdateTodoDueDate from '../mutations/useUpdateTodoDueDate';

function UpdateDateMutation({
  runMutation,
  schedule,
  delta
}) {
  const [updateEventTime] = useUpdateEventTime();
  const [updateTodoDueDate] = useUpdateTodoDueDate();

  React.useEffect(() => {
    if (!runMutation || !schedule || !delta) {
      return;
    }

    if (isEvent(schedule)) {
      const startsAt = addDays(schedule.startsAt, delta);
      const endsAt = addDays(schedule.endsAt, delta);
      updateEventTime(schedule, { startsAt, endsAt });
    } else if (isTodo(schedule)) {
      let dueDate = addDays(schedule.dueDate, delta);
      dueDate = format(dueDate, 'yyyy-MM-dd');
      updateTodoDueDate(schedule, dueDate);
    } else {
      console.log('NOT-HANDLED: ', schedule);
    }
  }, [updateEventTime, updateTodoDueDate, runMutation, schedule, delta]);

  return null;
}

UpdateDateMutation.propTypes = {
  runMutation: PropTypes.bool.isRequired,

  // The original (time specific) event object.
  schedule: PropTypes.object,

  // new date = old date + delta
  delta: PropTypes.number
};

export default UpdateDateMutation;
