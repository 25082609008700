import React from 'react';
import ListIcon from '../../../icons/16x16/List';
import ListPicker from '../../../calendar/form-dialog/ListPicker';
import FormField from './FormField';

export default function ListField(props) {
  return (
    <FormField icon={<ListIcon color='secondary' />} >
      <ListPicker
        clearable={false}
        {...props}
      />
    </FormField>
  );
}
