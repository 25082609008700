import React from 'react';
import PropTypes from 'prop-types';

const EventsListContext = React.createContext(null);

function init(initialEvents) {
  return { events: initialEvents };
}

function reducer(state, action) {
  switch(action.type) {
    case 'set':
      const prevEvents = state.events;
      const nextEvents = action.payload(prevEvents);
      return {...state, events: nextEvents};
    case 'reset':
      return init(action.payload);
    default:
      return state;
  }
}

// TODO: this shit should be gone once month grid fixed.
function EventsListProvider({ events, children }) {
  const [state, dispatch] = React.useReducer(reducer, events, init);

  // Similar to the version of setState that takes a callback
  // (prevState) => newState.
  const setEvents = React.useCallback((callback) => {
    dispatch({ type: 'set', payload: callback });
  }, [dispatch]);

  const ctx = {
    events: state.events,
    setEvents
  };

  React.useEffect(() => {
    dispatch({ type: 'reset', payload: events });
  }, [events]);

  return (
    <EventsListContext.Provider value={ctx}>
      {children}
    </EventsListContext.Provider>
  );
}

EventsListProvider.propTypes = {
  events: PropTypes.array.isRequired,
  children: PropTypes.node
};

export default EventsListProvider;

export function useEventsListContext() {
  return React.useContext(EventsListContext);
}
