import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from './DialogContent';
import DialogActionsContainer from './DialogActionsContainer';
import ReassignOwnerHelperText from './ReassignOwnerHelperText';
import OwnerPicker from '../owner/OwnerPicker';

function ReassignOwner({
  id,
  name,
  onClose,
  actionsContainer
}) {
  return (
    <DialogContent>
      <ReassignOwnerHelperText projectName={name} />
      <OwnerPicker projectId={id} />
      <DialogActionsContainer container={actionsContainer} />
    </DialogContent>
  )
}

ReassignOwner.propTypes = {
  // The project's name and ID
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  // Close dialog
  onClose: PropTypes.func,

  // The DOM node to render dialog actions.
  actionsContainer: PropTypes.object
};

export default ReassignOwner;
