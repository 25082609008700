import React from 'react';
import PropTypes from 'prop-types';
import OptionListItem from './OptionListItem';
import PencilIcon from '../../icons/20x20/PencilFill';
import PaletteIcon from '../../icons/20x20/PaletteFill';
import PersonIcon from '../../icons/20x20/PersonFill';
import ArchivedIcon from '../../icons/20x20/ArchiveFill';
import DeleteIcon from '../../icons/20x20/TrashFill';
import List from '../../ui/dialog/List';

// Available options for a project.
const projectOption = Object.freeze({
  'EDIT_PROJECT': 1,
  'CHANGE_AVATAR': 2,
  'REASSIGN_OWNER': 3,
  'ARCHIVE_PROJECT': 4,
  'DELETE_PROJECT': 5
});

const OPTIONS = [
  {
    startIcon: <PencilIcon />,
    primary: 'Edit this project',
    secondary: 'Edit the project name and description.',
    value: projectOption.EDIT_PROJECT
  },
  {
    startIcon: <PaletteIcon />,
    primary: 'Change project avatar',
    secondary: `Set a different color for this project. This will help you
and your team visually distinguish this project from another.`,
    value: projectOption.CHANGE_AVATAR
  },
  {
    startIcon: <PersonIcon />,
    primary: 'Reassign project owner',
    secondary: `The project owner can update, add new members to,
 or even delete this project completely.`,
    value: projectOption.REASSIGN_OWNER
  },
  {
    startIcon: <ArchivedIcon />,
    primary: 'Archive this project',
    secondary: `Archive if it is done, paused, or canceled. The project can
      be unarchived later.`,
    value: projectOption.ARCHIVE_PROJECT
  },
  {
    startIcon: <DeleteIcon />,
    primary: 'Delete this project',
    secondary: `Delete a project will permanently remove everything related
to it, including all of its tasks and files. This cannot be undone.`,
    value: projectOption.DELETE_PROJECT
  }
];

function OptionList({ onOptionClick }) {
  return (
    <List>
      {OPTIONS.map(({ value, startIcon, primary, secondary }) => (
        <OptionListItem
          key={value}
          startIcon={startIcon}
          primary={primary}
          secondary={secondary}
          onClick={() => {
            if (onOptionClick) {
              onOptionClick(value);
            }
          }}
        />
      ))}
    </List>
  );
}

OptionList.propTypes = {
  onOptionClick: PropTypes.func
};

export default OptionList;
export { projectOption }
