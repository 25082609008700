import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import { TODO_ITEM_DETAILS } from '../fragments';

const REORDER_TODO_ITEM_MUTATION = gql`
  mutation ReorderTodoItem(
    $todoItemId: ID!,
    $todoListId: ID!,
    $index: Int!,
    $filter: TodoFilter
  ) {
    reorderTodoItem(
      todoItemId: $todoItemId,
      todoListId: $todoListId,
      index: $index,
      filter: $filter
    ) {
      todoItem {
        id
      }
      source {
        index
        todoList { id }
      }
      destination {
        index
        todoList {id }
      }
    }
  }
`;

// TODO: move this shit out of this file.
function generateTodoItemsFragmentForTodoList(todoListId) {
  return {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };
}

export default function useReorderTodoItemMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(REORDER_TODO_ITEM_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },

    update: (client, { data: { reorderTodoItem }}) => {
      const { todoItem, source, destination } = reorderTodoItem;

      const todoItemId = todoItem.id;
      const srcIndex = source.index;
      const srcListId = source.todoList.id;
      const dstIndex = destination.index;
      const dstListId = destination.todoList.id;

      // The source list and the destination list are identical.
      if (srcListId === dstListId && srcIndex === dstIndex) {
        return;
      }

      const srcListFragment =
        generateTodoItemsFragmentForTodoList(srcListId);
      const srcList = client.readFragment(srcListFragment);
      let srcTodos = [...srcList.todoItems];

      let foundIndex;

      foundIndex = srcTodos.findIndex(({ id }) => id === todoItemId);

      // For some reason this item is not in the source list, it might
      // has been moved to the destination list.
      if (foundIndex === -1) { return; }

      // The target item has been moved (by subscriptionData) to the
      // desired destination index within the same list.
      if (srcListId === dstListId && foundIndex === dstIndex) {
        return;
      }

      // At this point, we would expect that the target item is STILL
      // in the source list at the `srcIndex`.
      // TODO: should report error!
      if (foundIndex !== srcIndex) { return; }

      if (srcListId === dstListId) {
        // Move the target item to the desired index within the same list.
        const [movedItem] = srcTodos.splice(srcIndex, 1);
        srcTodos.splice(dstIndex, 0, movedItem);

        client.writeFragment({
          ...srcListFragment,
          data: {
            ...srcList,
            todoItems: srcTodos
          }
        });
      } else {
        const dstListFragment =
          generateTodoItemsFragmentForTodoList(dstListId);
        const dstList = client.readFragment(dstListFragment);
        let dstTodos = [...dstList.todoItems];

        // Check to see if item is already at the desired location.
        foundIndex = dstTodos.findIndex(({ id }) => id === todoItemId);

        // For some reason (maybe subscriptionData comes first), the target
        // item is already at the desired index in the destination
        // list, but REMEMBER it is STILL in the source list.
        if (foundIndex === dstIndex) {
          client.writeFragment({
            ...srcListFragment,
            data: {
              ...srcList,
              todoItems: srcTodos.filter(({ id }) => id !== todoItemId)
            }
          });

          return;
        }

        // TODO: we expect (and it must be the case) that the foundIndex
        // value is -1, i.e, the target item is not in the destination
        // list yet.
        if (foundIndex !== -1) { return; }

        // Move the target item from the source list to the destination
        // list.
        const [movedTodo] = srcTodos.splice(srcIndex, 1);
        dstTodos.splice(dstIndex, 0, movedTodo);

        client.writeFragment({
          ...srcListFragment,
          data: {
            ...srcList,
            todoItems: srcTodos
          }
        });

        client.writeFragment({
          ...dstListFragment,
          data: {
            ...dstList,
            todoItems: dstTodos
          }
        });
      }
    },
    ...options
  });
}
