import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../ui/todo/sheet/Checkbox';
import useCompleteTodoItemMutation
from '../hooks/useCompleteTodoItemMutation';
import useReopenTodoItemMutation
from '../hooks/useReopenTodoItemMutation';
import { TODO_CRITERIA_SHAPE } from '../helpers';

function TodoCheckbox({ disabled, todo, todoListId, todoCriteria }) {
  const [completeTodoItem] = useCompleteTodoItemMutation({
    todoListId,
    todoCriteria,
    variables: {
      todoItemId: todo.id
    }
  });

  const [reopenTodoItem] = useReopenTodoItemMutation({
    todoListId,
    todoCriteria,
    variables: {
      todoItemId: todo.id
    }
  });

  const handleCheckedChange = (event) => {
    const newChecked = event.target.checked;

    if (newChecked) {
      completeTodoItem({
        optimisticResponse: {
          __typename: 'Mutation',
          completeTodoItem: {
            ...todo,
            completed: true
          }
        }
      });
    } else {
      reopenTodoItem({
        optimisticResponse: {
          __typename: 'Mutation',
          reopenTodoItem: {
            ...todo,
            completed: false
          }
        }
      });
    }
  };

  return (
    <Checkbox
      disabled={disabled}
      checked={todo.completed}
      onChange={handleCheckedChange}
      inputProps={{ 'aria-label': 'mark as complete' }}
    />
  );
}

TodoCheckbox.propTypes = {
  disabled: PropTypes.bool,
  todo: PropTypes.object.isRequired,
  todoListId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

TodoCheckbox.defaultProps = {
  disabled: false
};

export default TodoCheckbox;
