import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '../ui/svg-icons/DeleteOutline';
import CheckIcon from '../ui/svg-icons/Check';
import UndoIcon from '../ui/svg-icons/Undo';
import Menu from './ui/ChipContextMenu';
import ChipMenuItem from './ui/ChipMenuItem';
import ChipMenuDivider from './ui/ChipMenuDivider';
import ColorPicker from './ui/ChipMenuColorPicker';
import { CHIP_DEFAULT_COLOR, isValidChipColor } from './ui/constants';
import useMarkTodoCompleted from './mutations/useMarkTodoCompleted';
import useMarkTodoUncompleted from './mutations/useMarkTodoUncompleted';
import useUpdateTodoColor from './mutations/useUpdateTodoColor';
import useDeleteTodo from './mutations/useDeleteTodo';

function TodoContextMenu({ projectId, todo, ...rest }) {
  const { id, completed, color } = todo;

  const closeMenu = () => {
    if (rest.onClose()) {
      rest.onClose();
    }
  };

  //
  // GraphQL mutations
  //

  const [markCompleted] = useMarkTodoCompleted();
  const [markUncompleted] = useMarkTodoUncompleted();
  const [updateColor] = useUpdateTodoColor();
  const [deleteTodo] = useDeleteTodo(projectId);

  const handleMarkCompleted = (e) => {
    closeMenu();
    markCompleted(id);
  };

  const handleMarkUncompleted = (e) => {
    closeMenu();
    markUncompleted(id);
  };

  const handleDelete = (e) => {
    closeMenu();
    deleteTodo(todo);
  };

  const handleColorChange = (newColor) => {
    closeMenu();
    updateColor(id, newColor);
  };

  return (
    <Menu {...rest}>
      {completed ? (
        <ChipMenuItem
          primary='Mark uncompleted'
          startIcon={<UndoIcon />}
          onClick={handleMarkUncompleted}
        />
      ) : (
        <ChipMenuItem
          primary='Mark completed'
          startIcon={<CheckIcon />}
          onClick={handleMarkCompleted}
        />
      )}
      <ChipMenuItem
        primary='Delete'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
      />
      <ChipMenuDivider />
      <ColorPicker
        value={isValidChipColor(color) ? color : CHIP_DEFAULT_COLOR}
        onChange={handleColorChange}
      />
    </Menu>
  );
}

TodoContextMenu.propTypes = {
  projectId: PropTypes.string.isRequired,

  todo: PropTypes.shape({
    __typename: PropTypes.oneOf(["TodoItem"]).isRequired,
    id: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
    color: PropTypes.string,
    dueDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired
  }).isRequired
};

export default TodoContextMenu;
