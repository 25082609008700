import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../ui/input/TextField';
import PasswordField from '../ui/input/Pasword';

function CreateAccountFormInput({ value, onChange, error }) {
  const { username, email, password } = value;
  const {
    username: nameError,
    email: emailError,
    password: passwordError
  } = error;

  const isNameError = Boolean(nameError);
  const nameErrorProps = isNameError ? {
    error: true,
    helperText: `A valid username ${nameError}.`
  } : { };

  const isEmailError = Boolean(emailError);
  const emailErrorProps = isEmailError ? {
    error: true,
    helperText: `This email ${emailError}.`
  }: {};

  const isPasswordError = Boolean(passwordError);
  const passwordErrorProps = isPasswordError ? {
    error: true,
    helperText: `A valid password ${passwordError}.`
  } : { };

  return (
    <>
      <TextField
        autoFocus
        type='text'
        name='username'
        id='username'
        placeholder='Your name'
        autoComplete='username'
        fullWidth
        required
        value={username}
        onChange={onChange}
        {...nameErrorProps}
      />

      <TextField
        type='email'
        name='email'
        id='email'
        placeholder='Your email'
        autoComplete='email'
        required
        fullWidth
        value={email}
        onChange={onChange}
        {...emailErrorProps}
      />

      <PasswordField
        name='password'
        id='password'
        placeholder='Your password'
        required
        fullWidth
        value={password}
        onChange={onChange}
        {...passwordErrorProps}
      />
    </>
  );
}

CreateAccountFormInput.propTypes = {
  value: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func,
  error: PropTypes.object
};

export default CreateAccountFormInput;
