import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FolderDropdownButton from './FolderDropdownButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

function FolderPickerButton({ label, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='body1' className={classes.title}>
        In
      </Typography>
      <FolderDropdownButton label={label} onClick={onClick} />
    </div>
  );
}

FolderPickerButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default FolderPickerButton;
