import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../ui/button/Button';
import Error from '../oops/Error';
import useAcceptInvitation from './hooks/useAcceptInvitationMutation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  },
  invitation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  },
  highlight: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function GotInvitation({ username, invitation, onSkip }) {
  const classes = useStyles();

  const [acceptInvitation, { loading, error }] = useAcceptInvitation({
    invitationId: invitation.id
  });

  return (
    <div className={classes.root}>
      <div className={classes.invitation}>
        <Typography variant='h6'>
          You've got an invitation!
        </Typography>

        <DialogContentText>
          Hi, {username}! You've been invited to join the workspace
          {' '}
          <span className={classes.highlight}>
            {invitation.workspace.name}
          </span>
        </DialogContentText>

        { error && <Error error={error} /> }

        <Button
          variant='contained'
          color='success'
          size='large'
          fullWidth
          loading={loading}
          onClick={() => {
            acceptInvitation().catch((err) => {});
          }}
        >
          Accept invitation
        </Button>
      </div>

      {!loading && (
        <>
          <Divider flexItem style={{height: 1}} />

          <Button
            variant='contained'
            onClick={onSkip}
          >
            Skip to the next step
          </Button>
        </>
      )}
    </div>
  );
}

GotInvitation.propTypes = {
  username: PropTypes.string.isRequired,
  invitation: PropTypes.shape({
    id: PropTypes.string,
    workspace: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  onSkip: PropTypes.func
}
export default GotInvitation;
