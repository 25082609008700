import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';
import { TODO_SHEET_THEME } from './theme';

const useStyles = makeStyles((theme) => ({
  border: {
    border: TODO_SHEET_THEME.cellBorder,
    borderRight: 0
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'relative'
  }
}));

function SectionRowRightMostCell({ children, ...rest }) {
  const classes = useStyles();
  return (
    <Cell className={classes.border} {...rest}>
      <div className={classes.content}>{children}</div>
    </Cell>
  );
}

export default React.memo(SectionRowRightMostCell);
