import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SectionHeaderContainer
from '../../ui/todo/sheet/SectionHeaderContainer';
import SectionHeaderExpandRow
from '../../ui/todo/sheet/SectionHeaderExpandRow';
import SectionHeaderContent from './SectionHeaderContent';
import AddTodoFormRow from './AddTodoFormRow';
import { TODO_CRITERIA_SHAPE } from '../helpers';
import usePrependTodoItemMutation from '../hooks/usePrependTodoItemMutation';

function SectionHeader({
  sectionId,
  sectionName,
  todoCriteria,
  cols,
  expanded,
  onExpandedChange,
  rootUrl
}) {
  const [formOpen, setFormOpen] = React.useState(false);

  // since we memoize `SectionHeaderContent`.
  const handleFormOpen = React.useCallback(() => {
    if (!expanded && onExpandedChange) {
      onExpandedChange(true);
    }

    setFormOpen(true);
  }, [expanded, onExpandedChange]);

  const handleFormClose = () => {
    setFormOpen(false);
  };

  const [prependTodoItem] = usePrependTodoItemMutation({
    todoCriteria,
    variables: { todoListId: sectionId }
  });

  const handleSubmit = (variables, optimisticResponse) => {
    prependTodoItem({
      variables: variables,
      optimisticResponse: {
        __typename: 'Mutation',
        prependTodoItem: {
          __typename: 'TodoItem',
          ...optimisticResponse
        }
      }
    });
  };

  // TODO: this is pretty uggly and repetitive. The reason why we do it
  // here is because we want to submit the todo (save it to backend) and
  // then redirect when the `ViewTodoDetailsButton` button is clicked.
  // See `ViewTodoDetailsButton.js` for more details.

  const history = useHistory();

  const [prependTodoItemAndRedirect] = usePrependTodoItemMutation({
    todoCriteria,
    variables: { todoListId: sectionId },
    onCompleted: (data) => {
      const newTodoId = data.prependTodoItem.id;
      history.push({
        pathname: `${rootUrl}/${newTodoId}`,
        todoCriteria
      });
    }
  });

  const handleSubmitAndRedirect = React.useCallback(
    (variables, optimisticResponse) => {
      prependTodoItemAndRedirect({
        variables: variables,
        optimisticResponse: {
          __typename: 'Mutation',
          prependTodoItem: {
            __typename: 'TodoItem',
            ...optimisticResponse
          }
        }
      });
    }, [prependTodoItemAndRedirect]);

  return (
    <SectionHeaderContainer>
      <SectionHeaderExpandRow
        colSpan={cols.length + 2}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
      >
        <SectionHeaderContent
          sectionId={sectionId}
          sectionName={sectionName}
          onPrependRowButtonClick={handleFormOpen}
        />
      </SectionHeaderExpandRow>

      <AddTodoFormRow
        open={formOpen}
        onClose={handleFormClose}
        sectionId={sectionId}
        todoCriteria={todoCriteria}
        cols={cols}
        onSubmit={handleSubmit}
        onSubmitAndRedirect={handleSubmitAndRedirect}
      />
    </SectionHeaderContainer>
  );
}

SectionHeader.propTypes = {
  // id, name, and todo criteria for the todolist
  sectionId: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired,

  // sheet's columns
  cols: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    getCellProps: PropTypes.func.isRequired,
    renderCell: PropTypes.func.isRequired
  })).isRequired,

  // expanded section rows or not?
  expanded: PropTypes.bool,
  onExpandedChange: PropTypes.func,

  // TodoSheet root url
  rootUrl: PropTypes.string.isRequired
};

SectionHeader.defaultProps = {
  expanded: false
};

export default React.memo(SectionHeader);
