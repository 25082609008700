import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Section from './Section';
import SectionHeader from './SectionHeader';

export default function SectionSkeleton() {
  return (
    <Section>
      <SectionHeader>
        <Skeleton
          variant='rect'
          width='32%'
          height={20}
          style={{borderRadius: 8}}
        />
      </SectionHeader>
      <div>
        <Skeleton variant='rect' height={128} style={{borderRadius: 8}} />
      </div>
    </Section>
  );
}
