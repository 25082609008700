import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isValid } from 'date-fns';
import { viewOptions, getDateSlug, useDateParam } from './helpers';
import ViewMenu from './ui/ViewMenu';

function ViewSelect({ activeView, rootUrl }) {
  const date = useDateParam();
  const history = useHistory();

  const handleChange = (newActiveView) => {
    if (isValid(date)) {
      const dateSlug = getDateSlug(date);
      const to = `${rootUrl}/${newActiveView}/${dateSlug}`;
      history.push(to);
    }
  };

  return (
    <ViewMenu
      options={viewOptions}
      value={activeView}
      onChange={handleChange}
    />
  );
}

ViewSelect.propTypes = {
  activeView: PropTypes.oneOf(viewOptions.map(({ value }) => (
    value
  ))).isRequired,
  rootUrl: PropTypes.string.isRequired
};

export default ViewSelect;
