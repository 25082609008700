import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import CellContentWrapper from '../../ui/todo/sheet/CellContentWrapper';
import CellInput from '../../ui/todo/sheet/CellInput';
import { TODO_CRITERIA_SHAPE, matchesNewRowId } from '../helpers';
import TodoCheckbox from './TodoCheckbox';

import ViewTodoDetailsButton from './ViewTodoDetailsButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover $endAdornment': {
      opacity: 1
    }
  },
  endAdornment: {
    height: '100%',
    maxHeight: 'none',
    opacity: 0
  }
}));

function TaskNameCell({
  rootUrl,
  todo,
  onNameChange,
  sectionId,
  todoCriteria
}) {
  const classes = useStyles();

  const handleInputChange = (event) => {
    if (onNameChange) {
      onNameChange(event.target.value);
    }
  };

  const autoFocus = todo.autoFocus || false;

  return (
    <CellContentWrapper>
      <CellInput
        className={classes.root}
        autoFocus={autoFocus}
        placeholder='Write a task name'
        InputProps={{
          startAdornment: (
            <InputAdornment
              position='start'
            >
              <TodoCheckbox
                disabled={!todo.id || matchesNewRowId(todo.id)}
                todo={todo}
                todoListId={sectionId}
                todoCriteria={todoCriteria}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position='end'
              className={classes.endAdornment}
            >
              <ViewTodoDetailsButton
                rootUrl={rootUrl}
                todo={todo}
                todoCriteria={todoCriteria}
              />
            </InputAdornment>
          )
        }}
        value={todo.name}
        onChange={handleInputChange}
      />
    </CellContentWrapper>
  );
}

TaskNameCell.propTypes = {
  rootUrl: PropTypes.string.isRequired,
  todo: PropTypes.object.isRequired,

  // todo's name change.
  onNameChange: PropTypes.func,

  // todolist's ID and current todo criteria.
  sectionId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

TaskNameCell.defaultProps = {
  autoFocus: false
};

export default TaskNameCell;
