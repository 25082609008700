import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from '../workspace/CurrentWorkspaceContext';
import Error from '../oops/Error';
import { MEMBERSHIP_DETAILS_FRAGMENT, MEMBERSHIP_USER_FRAGMENT }
from './fragments';

const SEARCH_MEMBER_IN_CURRENT_WORKSPACE_QUERY = gql`
  query SearchMemberInCurrentWorkspace(
    $currentWorkspaceId: ID!,
    $filter: WorkspaceMembershipFilter!,
    $order: SortOrder!
  ) {
    workspace(workspaceId: $currentWorkspaceId) {
      id
      workspaceMemberships(filter: $filter, order: $order)
      @connection(key: "member_search_result") {
        ...MembershipDetails
        ...MembershipUser
      }
    }
  }
  ${MEMBERSHIP_DETAILS_FRAGMENT}
  ${MEMBERSHIP_USER_FRAGMENT}
`;

function SearchMemberInCurrentWorkspaceQuery(props) {
  const { criteria, order, onLoading, onError, children, ...rest } = props;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data } = useQuery(
    SEARCH_MEMBER_IN_CURRENT_WORKSPACE_QUERY,
    {
      variables: {
        currentWorkspaceId,
        filter: criteria,
        order
      },
      fetchPolicy: 'network-only',
      ...rest
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.workspace.workspaceMemberships);
}

SearchMemberInCurrentWorkspaceQuery.propTypes = {
  criteria: PropTypes.object,
  order: PropTypes.oneOf(['ASC', 'DESC']),
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

SearchMemberInCurrentWorkspaceQuery.defaultProps = {
  criteria: {},
  order: 'ASC'
};

export default SearchMemberInCurrentWorkspaceQuery;
