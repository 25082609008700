import React from 'react';
import PropTypes from 'prop-types';
import EventDetailsQuery from './EventDetailsQuery';
import EventDetailsWithData from './EventDetailsWithData';
import EventNotFound from './EventNotFound';
import EventQueryError from './EventQueryError';
import EventQueryLoading from './EventQueryLoading';

function EventDetails({ eventId, onClose }) {
  return (
    <EventDetailsQuery
      eventId={eventId}
      onLoading={() => <EventQueryLoading onCancel={onClose} />}
      onError={(error) => (
        <EventQueryError error={error} onClose={onClose} />
      )}
    >
      {(event) => event ? (
        <EventDetailsWithData
          event={event}
          onClose={onClose}
        />
      ) : <EventNotFound onClose={onClose} />}
    </EventDetailsQuery>
  );
}

EventDetails.propTypes = {
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default EventDetails;
