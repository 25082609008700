import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const SOME_EVENT_COMMENT_DELETED_SUBSCRIPTION = gql`
  subscription OnSomeEventCommentDeleted($eventId: ID!) {
    eventCommentDeleted(eventId: $eventId) {
      id
    }
  }
`;

function SomeCommentDeletedSubscriber({
  eventId,
  subscribeToSomeCommentDeleted
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToSomeCommentDeleted({
      document: SOME_EVENT_COMMENT_DELETED_SUBSCRIPTION,
      variables: { eventId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return prev; }

        const deletedId = subscriptionData.data.eventCommentDeleted.id;
        const comments = prev.event.comments.filter(({ id }) => (
          id !== deletedId
        ));

        return {
          event: {
            ...prev.event,
            comments: comments
          }
        };
      }
    });

    return () => unsubscribe();
  }, [eventId, subscribeToSomeCommentDeleted]);

  return null;
}

SomeCommentDeletedSubscriber.propTypes = {
  eventId: PropTypes.string.isRequired,
  subscribeToSomeCommentDeleted: PropTypes.func.isRequired
};

export default SomeCommentDeletedSubscriber;
