import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import UserCard from './UserCard';
import Menu from './StyledMembershipMenu';
import ChangeRolePopover from './ChangeRolePopover';
import ChangeRoleForm from './ChangeRoleForm';

function MembershipCard(props) {
  const { membership, onRoleChange, onSetAsOwner, onRemove } = props;
  const { id, role, owner, user } = membership;
  const cardRef = React.useRef(null);

  //
  // Popover manager.
  //

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [formOpen, setFormOpen] = React.useState(false);

  const handleMenuOpen = () => { setMenuOpen(true); };
  const handleMenuClose = () => { setMenuOpen(false); };

  const handleFormOpen = () => {
    handleMenuClose();
    setFormOpen(true);
  };

  const handleFormClose = () => {
    cardRef.current.focus();
    setFormOpen(false);
  };

  //
  // Data mamanager.
  //

  const handleSetAsOwner = (event) => {
    handleMenuClose();
    if (onSetAsOwner) { onSetAsOwner(membership); }
  };

  const handleRemove = (event) => {
    handleMenuClose();
    if (onRemove) { onRemove(id); }
  };

  const handleRoleChange = (newRole) => {
    handleFormClose();
    if (onRoleChange) { onRoleChange(id, newRole); }
  };

  const renderedRole = role ? role : (
    owner ? 'Project Owner' : '+ Add role'
  );

  return (
    <React.Fragment>
      <UserCard
        ref={cardRef}
        user={user}
        primary={user.username}
        secondary={renderedRole}
        highlight={menuOpen || formOpen}
        onClick={handleMenuOpen}
      />

      <Menu
        anchorEl={cardRef.current}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          primary={role ? 'Change role' : 'Add role'}
          onClick={handleFormOpen}
        />

        {!owner && (
          <MenuItem
            primary='Set as Project Owner'
            onClick={handleSetAsOwner}
          />
        )}

        <MenuItem
          primary='Remove from Project'
          onClick={handleRemove}
        />
      </Menu>

      <ChangeRolePopover
        open={formOpen}
        anchorEl={cardRef.current}
        onClose={handleFormClose}
      >
        <ChangeRoleForm
          currentRole={role}
          label={`What is ${user.username}'s role on this project?`}
          onSubmit={handleRoleChange}
          onCancel={handleFormClose}
        />
      </ChangeRolePopover>
    </React.Fragment>
  );
}

MembershipCard.propTypes = {
  membership: PropTypes.shape({
    id: PropTypes.string.isRequired,
    owner: PropTypes.bool.isRequired,
    role: PropTypes.string,
    user: PropTypes.object.isRequired
  }).isRequired,

  onRoleChange: PropTypes.func,
  onSetAsOwner: PropTypes.func,
  onRemove: PropTypes.func
};

export default MembershipCard;
