import React from 'react';
import PropTypes from 'prop-types';
import DataRow from './DataRow';
import DraggableDataRow from './DraggableDataRow';
import DataCell from './DataCell';

function SectionRow({
  draggable,
  index,
  row,
  cols,
  highlight,
  onBlurWithin,
  ...otherCellProps
}) {
  const [state, setState] = React.useState(row);

  React.useEffect(() => {
    setState(row);
  }, [row]);

  const handleFocusWithinChange = (isFocusWithin) => {
    if (!isFocusWithin) {
      if (onBlurWithin) {
        onBlurWithin(row, state);
      }
    }
  };

  const dataCells = cols.map(({width, name, renderCell, getCellProps}) => (
    <DataCell
      key={name}
      width={width}
      updateRow={setState}
      render={renderCell}
      {...getCellProps(state)}
      {...otherCellProps}
    />
  ));

  return draggable ? (
    <DraggableDataRow
      rowId={row.id}
      rowIndex={index}
      highlight={highlight}
      onFocusWithinChange={handleFocusWithinChange}
    >
      {dataCells}
    </DraggableDataRow>
  ) : (
    <DataRow
      highlight={highlight}
      onFocusWithinChange={handleFocusWithinChange}
    >
      {dataCells}
    </DataRow>
  );
}

SectionRow.propTypes = {
  draggable: PropTypes.bool,

  // This is required if `draggable` is set to `true`.
  index: PropTypes.number,

  // The data for each row
  row: PropTypes.object.isRequired,

  // See `Section.js` for more details.
  cols: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    getCellProps: PropTypes.func.isRequired,
    renderCell: PropTypes.func.isRequired
  })).isRequired,

  onBlurWithin: PropTypes.func,
  highlight: PropTypes.bool
};

SectionRow.defaultProps = {
  draggable: true
};

export default SectionRow;
