import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import useDeleteWorkspaceMutation
from '../../workspace/hooks/useDeleteWorkspaceMutation';

function DeleteCurrentWorkspaceConfirm({ workspace, client }) {
  const { closeDialog } = React.useContext(DialogContext);
  const { id, name } = workspace;
  const history = useHistory();

  const [deleteCurrentWorkspace, { loading }] = useDeleteWorkspaceMutation({
    workspaceId: id,
    onCompleted(data) {
      closeDialog();
      client.clearStore().then(() => {
        history.push('/');
      });
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Delete {name}?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          This action cannot be undone. Once this workspace is deleted,
          all of its teams, projects, tasks, and everything are gone forever
          as if they did not exist at all in the first place.
        </DialogContentText>

        <DialogContentText style={{marginBottom: 0}}>
          Are you sure you wish to do this?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {!loading && (
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={closeDialog}
          >
            Never mind
          </Button>
        )}

        <Button
          variant='contained'
          size='small'
          color='danger'
          loading={loading}
          onClick={() => {
            deleteCurrentWorkspace();
          }}
        >
          Yes, delete it
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

DeleteCurrentWorkspaceConfirm.propTypes = {
  workspace: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default withApollo(DeleteCurrentWorkspaceConfirm);
