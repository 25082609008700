import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  messageFormActions: {
    //backgroundColor: 'red',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(3, 2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(4, 7.5)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(4, 15)
    }
  }
}));

export default function MessageFormActions(props) {
  const classes = useStyles();
  return <div className={classes.messageFormActions} {...props} />;
}
