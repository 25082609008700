import React from 'react';
import PropTypes from 'prop-types';
import Grid from './ui/AllDayGrid';
import Chip from './ui/AllDayChip';
import UpdateDateMutation from './UpdateDateMutation';
import FormDialog from './AllDayFormDialog';

// Helpers.

function init(dragItem) {
  if (!dragItem) {
    return null;
  }

  return {
    ...dragItem,
    origColIndex: dragItem.chipProps.colIndex,
    origRowIndex: dragItem.chipProps.rowIndex,
    isNew: false,
    isDraggingStopped: false
  };
}

function createDragItem({ rowIndex, colIndex }) {
  return {
    schedule: null,
    chipProps: {
      rowIndex,
      colIndex,
      colSpan: 1,
      label: '(No title)'
    },
    origColIndex: colIndex,
    origRowIndex: rowIndex,
    isNew: true,
    isDraggingStopped: false
  }
}

function updatePosition(dragItem, { startPosition, endPosition }) {
  const colDelta = endPosition.colIndex - startPosition.colIndex;
  const rowDelta = endPosition.rowIndex - startPosition.rowIndex;

  return {
    ...dragItem,
    chipProps: {
      ...dragItem.chipProps,
      colIndex: dragItem.origColIndex + colDelta,
      rowIndex: dragItem.origRowIndex + rowDelta
    }
  };
}

function updateColSpan(dragItem, { startPosition, endPosition }) {
  const colDelta = endPosition.colIndex - startPosition.colIndex;
  let colIndex = startPosition.colIndex;
  let colSpan = 1;

  if (colDelta > 0) {
    colSpan += colDelta
  } else if (colDelta < 0) {
    colSpan -= colDelta;
    colIndex = endPosition.colIndex;
  }

  return {
    ...dragItem,
    chipProps: {
      ...dragItem.chipProps,
      colIndex,
      colSpan
    }
  };
}

function updateDragItem(dragItem, dragRange) {
  if (dragItem.isNew) {
    return updateColSpan(dragItem, dragRange);
  } else {
    return updatePosition(dragItem, dragRange);
  }
}

function reducer(state, action) {
  switch(action.type) {
    case 'START_DRAGGING':
      const { event, position } = action.payload;

      // User drags to create new schedule.
      if (event === 'DRAG') {
        return createDragItem(position);
      }

      // User clicks to create new schedule.
      if (event === 'CLICK') {
        return {
          ...createDragItem(position),
          isDraggingStopped: true
        };
      }

      throw new Error(`Unknown event: ${event}`);

    case 'UPDATE_DRAGGING':
      if (!state) {
        return state;
      }

      return updateDragItem(state, action.payload);

    case 'STOP_DRAGGING':
      if (!state) {
        return state;
      }

      return {...state, isDraggingStopped: true};

    case 'RESET_DRAG_ITEM':
      return init(action.payload);

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}

function AllDayGrid({
  projectId,
  days,
  numRows,
  numCols,
  dragItem,
  dragZIndex,
  onDragStart,
  onDragEnd,
  children,
  ...rest
}) {
  const [state, dispatch] = React.useReducer(reducer, dragItem, init);

  const handleDragStart = React.useCallback((e, position) => {
    const id = e.target.dataset.scheduleId;
    const type = e.target.dataset.scheduleType;

    if(id && type && onDragStart) {
      onDragStart({ id, __typename: type });
    } else {
      dispatch({
        type: 'START_DRAGGING',
        payload: { event: 'DRAG', position }
      });
    }
  }, [onDragStart]);

  const handleDragUpdate = React.useCallback((startPos, endPos) => {
    dispatch({
      type: 'UPDATE_DRAGGING',
      payload: { startPosition: startPos, endPosition: endPos }
    });
  }, []);

  const handleDragEnd = React.useCallback((startPos, endPos) => {
    if (onDragEnd) {
      onDragEnd();
    }

    dispatch({
      type: 'STOP_DRAGGING',
      payload: { startPosition: startPos, endPosition: endPos}
    });
  }, [onDragEnd]);

  // Clicks to create new schedule.
  const handleClick = React.useCallback((position) => {
    dispatch({
      type: 'START_DRAGGING',
      payload: { event: 'CLICK', position }
    });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: 'RESET_DRAG_ITEM',
      payload: dragItem
    });
  }, [dragItem]);

  const {
    origColIndex = 0,
    chipProps = null,
    schedule = null,
    isNew = false,
    isDraggingStopped = false
  } = state || {};

  return (
    <Grid
      numRows={numRows}
      numCols={numCols}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
    >
      {children}

      {chipProps && (
        <Chip
          {...chipProps}
          fullWidth
          focus
          zIndex={dragZIndex}
          numCols={numCols}
        />
      )}

      <UpdateDateMutation
        runMutation={isDraggingStopped && !isNew}
        schedule={schedule}
        delta={chipProps ? (chipProps.colIndex - origColIndex) : null}
      />

      {(chipProps && isNew && isDraggingStopped) && (
        <FormDialog
          projectId={projectId}
          startDate={days[chipProps.colIndex]}
          endDate={days[chipProps.colIndex + chipProps.colSpan - 1]}
          onClose={() => dispatch({
            type: 'RESET_DRAG_ITEM',
            payload: null
          })}
        />
      )}
    </Grid>
  );
}

AllDayGrid.propTypes = {
  projectId: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ).isRequired,

  numRows: PropTypes.number.isRequired,
  numCols: PropTypes.number.isRequired,

  dragItem: PropTypes.object,
  dragZIndex: PropTypes.number.isRequired,

  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,

  children: PropTypes.node
};

export default AllDayGrid;
