import React from 'react';
import DialogContent from '../../dialog/DialogContent';

export default function Content({ children, sentinelEndRef }) {
  return (
    <DialogContent>
      {children}
      <div ref={sentinelEndRef} />
    </DialogContent>
  );
}
