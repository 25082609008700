import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

const UNREAD_NOTIFICATION_COUNT_QUERY = gql`
  query GetUnreadNotificationCount($workspaceId: ID!) {
    unreadNotificationCount(workspaceId: $workspaceId)
  }
`;

function UnreadNotificationCountQuery({
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { workspaceId } = useParams();
  const { loading, error, data, ...otherQueryResults } = useQuery(
    UNREAD_NOTIFICATION_COUNT_QUERY,
    {
      variables: { workspaceId },
      ...otherQueryProps
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : null;
  return children(data.unreadNotificationCount, otherQueryResults);
}

UnreadNotificationCountQuery.propTypes = {
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default UnreadNotificationCountQuery;
export { UNREAD_NOTIFICATION_COUNT_QUERY };
