import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

function DroppableSectionBody({ sectionId, children }) {
  const hasNoDraggable = React.Children.count(children) === 0;

  return (
    <Droppable droppableId={sectionId}>
      {(provided, snapshot) => (
        <tbody
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {/* TODO: this is a hack to set min-height for tbody which is
              required for any droppable container, but it breaks the rule
              saying that droppable should not contain any non-draggable
              children. */}
          {hasNoDraggable && (
            <tr>
              <td style={{margin: 0, padding: 0}}>
                <div style={{width: '100%', height: 1}} />
              </td>
            </tr>
          )}

          {children}

          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
}

DroppableSectionBody.propTypes = {
  // The todo list ID used for droppableId.
  sectionId: PropTypes.string.isRequired,

  // List of draggable rows
  children: PropTypes.node
};

export default React.memo(DroppableSectionBody);
