import React from 'react';
import PropTypes from 'prop-types';
import CurrentWorkspaceMembershipQuery
from '../workspace/CurrentWorkspaceMembershipQuery';
import ActivateMembershipButton from './ActivateMembershipButton';
import MembershipMenuButton from './menu/MembershipMenuButton';
import { roleCmp, isAdmin, isMember, isGuest } from './helpers';

function MembershipAction({ membership, ...rest }) {

  const renderAction = ({ id, role }) => {
    if (isGuest(role) || isMember(role)) return null;
    if (isAdmin(role) && roleCmp(membership.role, role) >= 0) return null;
    if (id === membership.id) return null;

    return membership.active ? (
      <MembershipMenuButton
        membership={membership}
        currentUserRole={role}
        {...rest}
      />
    ) : (
      <ActivateMembershipButton membership={membership} {...rest} />
    );
  };

  return (
    <CurrentWorkspaceMembershipQuery>
      {(currentUser) => {
        const currentMembership = currentUser.workspaceMemberships[0];
        return renderAction(currentMembership);
      }}
    </CurrentWorkspaceMembershipQuery>
  );
}

MembershipAction.propTypes = {
  membership: PropTypes.object.isRequired
};

export default MembershipAction;
