import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridRowGroup: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default function GridRowGroup(props) {
  const classes = useStyles();
  return (
    <div
      role='rowgroup'
      className={classes.gridRowGroup}
      {...props}
    />
  );
}
