import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Input from './Input';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '100%',
    overflow: 'hidden',
    margin: 0,
    padding: 0
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  spaceholder: {
    padding: theme.spacing(0.75, 1), // same as input
    cursor: 'default',
    pointerEvents: 'none',
    userSelect: 'none',
    visibility: 'hidden',
    whiteSpace: 'pre'
  },
  'spaceholder-body1': {
    ...theme.typography.body1,
    lineHeight: '24px'
  },
  'spaceholder-body2': {
    ...theme.typography.body2,
    lineHeight: '24px'
  },
  'spaceholder-header': {
    ...theme.typography.h5,
    fontSize: '1.375rem'
  },
  startAdornmentSpaceholder: {
    marginLeft: theme.spacing(
      3 /* adornment size */ +
      1 /* adornment margin right */
    )
  },
  noHorizontalPaddingSpaceholder: {
    paddingLeft: 0,
    paddingRight: 0
  },
  clearIconSpaceholder: {
    paddingRight: theme.spacing(4)
  }
}));

function _getLongerString(str1, str2) {
  return (str1.length >= str2.length) ? str1 : str2;
}

const AutosizeInput = React.forwardRef(function AutosizeInput(props, ref) {
  const classes = useStyles();

  const {
    typography,
    horizontalPadding,
    dummyText,
    placeholder,
    hasClearIcon,
    startAdornment,
    inputProps = {},
    ...rest
  } = props;

  const shortestSpaceholder = placeholder ? placeholder : dummyText;
  const value = rest.value || inputProps.value || '';
  const spaceholder = _getLongerString(value, shortestSpaceholder);

  return (
    <div ref={ref} className={classes.root}>
      <Input
        typography={typography}
        placeholder={placeholder}
        horizontalPadding={horizontalPadding}
        hasClearIcon={hasClearIcon}
        startAdornment={startAdornment}
        className={classes.input}
        inputProps={inputProps}
        {...rest}
        multiline={false/* doesn't make sense to autosize textarea */}
      />
      <Typography
        component='div'
        aria-hidden={true}
        className={clsx(
          classes.spaceholder,
          classes[`spaceholder-${typography}`],
          {
            [classes.startAdornmentSpaceholder]: Boolean(startAdornment),
            [classes.noHorizontalPaddingSpaceholder]: !horizontalPadding,
            [classes.clearIconSpaceholder]: hasClearIcon
          }
        )}
      >
        {spaceholder}
      </Typography>
    </div>
  );
});

AutosizeInput.propTypes = {
  // We have to make sure that the typography of spaceholder text to be
  // consistent with the typography of the input
  typography: PropTypes.oneOf(['header', 'body1', 'body2']),

  // Without placeholder provided, this dummy text will determine the
  // minimum width of the input
  dummyText: PropTypes.string,

  // Since our input also takes `horizontalPadding` as one of its props,
  // and that prop will also determine the width of our spaceholder
  horizontalPadding: PropTypes.bool,

  // If the input has clear icon, we need to apply some right padding
  // to it as well as the spaceholder
  hasClearIcon: PropTypes.bool,

  // Other MUI props
  placeholder: PropTypes.string,
  startAdornment: PropTypes.node
};

AutosizeInput.defaultProps = {
  typography: 'body2',
  horizontalPadding: true,
  dummyText: 'placeholder',
  hasClearIcon: false
};

export default AutosizeInput;
