import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '../../icons/16x16/MagnifyingGlass';

export default withStyles((theme) => ({
  root: {
    // A trick to always position seaction search input on the top right
    // corner of the seaction itself.
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),

    backgroundColor: '#F0F2F5',
    borderRadius: 48,
    maxWidth: 192,
    padding: '4px 12px',
    fontSize: '0.9375rem',
  },
  input: {
    padding: '6px 0px',
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1.0
    },
    '&:focus': {
      '&::placeholder': {
        color: '#8A8D91'
      }
    }
  }
}))(React.forwardRef((props, ref) => (
  <InputBase
    type='search'
    ref={ref}
    startAdornment={
      <InputAdornment position='start'>
        <SearchIcon color='placeholder' />
      </InputAdornment>
    }
    {...props}
  />
)));
