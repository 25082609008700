import React from 'react';
import PropTypes from 'prop-types';
import CardGridItem from '../ui/card/CardGridItem';
import TeamCard from './TeamCard';

function TeamGridItem({ team }) {
  return (
    <CardGridItem>
      <TeamCard team={team} />
    </CardGridItem>
  );
}

TeamGridItem.propTypes = {
  team: PropTypes.object.isRequired
};

export default React.memo(TeamGridItem);
