import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    width: '100%',
    minHeight: '100vh',
    padding: theme.spacing(3, 2, 2),
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(3, 7.5, 7.5)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(3, 15, 7.5)
    },

    // placeholder
    '& .public-DraftEditorPlaceholder-inner': {
      ...theme.typography.body1,
      fontFamily: theme.typography.fontFamily,
      color: '#90949c',
      [theme.breakpoints.up('md')]: {
        fontSize: 'calc(0.75rem + 0.5vw)'
      }
    }
  }
}));

export default function MessageEditorContainer(props) {
  const classes = useStyles();
  return <div className={classes.editorContainer} {...props} />;
}
