import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 18,
    height: 18,
    fontSize: '0.625rem'
  }
}));

export default function ToolbarButtonUserAvatar({ className, ...rest }) {
  const classes = useStyles();
  return (
    <UserAvatar
      className={clsx(classes.avatar, className)}
      {...rest}
    />
  );
}
