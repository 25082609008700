import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../../oops/Error';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';

const EVENT_COMMENTS_QUERY = gql`
  query ListEventComments($eventId: ID!) {
    event(id: $eventId) {
      id
      comments {
        id
        text
        autoGenerated
        severity
        ...CommentAuthor
        ...CommentFiles
        updatedAt
      }
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

function CommentsQuery({
  eventId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    EVENT_COMMENTS_QUERY,
    {
      variables: { eventId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.event.comments, otherQueryResults);
}


CommentsQuery.propTypes = {
  eventId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default CommentsQuery;
export { EVENT_COMMENTS_QUERY };
