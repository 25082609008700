import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { INTERSECTION_THRESHOLD } from './constants';

const useStyles = makeStyles((theme) => ({
  eventsRow: {
    flex: '1 1 0%',
    marginTop: theme.spacing(3.5)
  },
  rowPresentation: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    fontSize: '1.25rem'
  }
}));

function MonthGridEventsRow({ height, onIntersect, children }) {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const targetRef = React.useRef(null);

  // Overflow observer
  React.useEffect(() => {
    const options = {
      root: rootRef.current,
      margin: '0px',
      threshold: INTERSECTION_THRESHOLD
    };

    const callback = (entries) => {
      // Only one entry!
      entries.forEach((entry) => {
        if (onIntersect) { onIntersect(entry); }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(targetRef.current);

    return () => observer.disconnect();
  }, [onIntersect]);

  return (
    <div ref={rootRef} className={classes.eventsRow}>
      <div
        ref={targetRef}
        role='presentation'
        className={classes.rowPresentation}
        style={{ height: `${height}em` }}
      >
        {children}
      </div>
    </div>
  );
}

MonthGridEventsRow.propTypes = {
  height: PropTypes.number.isRequired,
  onIntersect: PropTypes.func,
  children: PropTypes.node
};

export default MonthGridEventsRow;
