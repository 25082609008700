import React from 'react';
import EllipsisIcon from '../../icons/20x20/Ellipsis';
import IconButton from '../button/IconButton';

function CardHeaderAction({ icon, ...rest }) {
  return (
    <IconButton
      aria-label='More actions'
      aria-haspopup='true'
      icon={icon || <EllipsisIcon />}
      background={false}
      size='small'
      color='secondary'
      {...rest}
    />
  );
}

export default CardHeaderAction;
