import React from 'react';
import PageTitle from '../ui/page/PageTitle';
import PageHeader from '../ui/page/Header';
import MembersCounter from './MembersCounter';

function MembersHeader() {
  return (
    <PageHeader>
      <PageTitle>Manage members</PageTitle>
      <MembersCounter />
    </PageHeader>
  );
}

export default React.memo(MembersHeader);
