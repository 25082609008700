import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  skeletonsContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    '& > *:not(:last-child)': {
      marginRight: 8
    }
  }
}));

export default function TabsSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.skeletonsContainer}>
      <Skeleton width={32} height={16} />
      <Skeleton width={32} height={16} />
      <Skeleton width={32} height={16} />
    </div>
  );
}
