import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

const RouterLink = React.forwardRef(function RouterLink(props, ref) {
  const {to, children, ...rest} = props;

  const renderLink = React.useMemo(() => (
    React.forwardRef((itemProps, itemRef) => (
      <Link ref={itemRef} to={to} {...itemProps} />
    ))
  ), [to]);

  return (
    <MuiLink ref={ref} component={renderLink} {...rest}>
      {children}
    </MuiLink>
  );
});

RouterLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default RouterLink;
