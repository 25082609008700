import gql from 'graphql-tag';

export const PROJECT_ACTIVITY_DETAILS = gql`
  fragment ProjectActivityDetails on ProjectActivity {
    id
    type
    details
    actor {
      id
      username
      email
      avatar
    }
    todo { id }
    message { id }
    event {
      id
      startsAt
    }
    upload {
      id
      name
      previewUrl
      previewType
      downloadUrl
    }
    comment { id }
    insertedAt
  }
`;
