import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import BackgroundImage from './textfield_background.png';

const CssTextField = withStyles((theme) => ({
  root: {
    // Some margin top if you have label
    '& label + .MuiInput-formControl': {
      marginTop: 26
    },

    // For size `medium`
    '& .MuiInputBase-input': {
      padding: '6px 0px'
    },

    // For size `small`
    '& .MuiInputBase-inputMarginDense': {
      padding: '3px 0px'
    },

    // Input root
    '& .MuiInput-root': {
      padding: '8px 12px',
      backgroundColor: '#fff',
      background: `#fff url(${BackgroundImage}) repeat-x`,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      '&.Mui-focused': {
        boxShadow: `0 0 0 2px ${theme.palette.primary.shadow}`,
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-error': {
        borderColor: theme.palette.error.main
      },
      '&.Mui-error.Mui-focused': {
        boxShadow: 'none',
        borderColor: theme.palette.error.main
      }
    },

    // Remove `underline` for variant `standard`
    '& .MuiInput-underline': {
      '&:before': {
        content: 'none'
      },
      '&:after': {
        content: 'none'
      }
    }
  }
}))(TextField);

export default CssTextField;
