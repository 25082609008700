import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import TeamProjects from './projects/Projects';
import TeamMembers from './members/Members';
import TeamActivities from './activities/Activities';

function TeamSections() {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={`${url}/projects`} />
      </Route>
      <Route path={`${path}/projects`} >
        <TeamProjects />
      </Route>
      <Route path={`${path}/members`} exact>
        <TeamMembers />
      </Route>
      <Route path={`${path}/activities`} exact>
        <TeamActivities />
      </Route>
    </Switch>
  );
}

export default React.memo(TeamSections);
