import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  endIconContainer: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function ListItemEndIcon({ icon }) {
  const classes = useStyles();
  return (
    <div className={classes.endIconContainer}>
      {icon}
    </div>
  );
}

ListItemEndIcon.propTypes = {
  icon: PropTypes.node.isRequired
};

export default ListItemEndIcon;
