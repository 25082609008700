import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import useDeleteTodoListMutation from '../hooks/useDeleteTodoListMutation';

function DeleteTodoListConfirm({ todoList }) {
  const { id, name, incompleteCount, completedCount } = todoList;
  const { closeDialog } = React.useContext(DialogContext);

  const [deleteTodoList, { loading }] = useDeleteTodoListMutation({
    todoListId: id,
    onCompleted: (data) => {
      closeDialog();
    }
  });

  const highlightName = (
    <Box
      fontWeight='fontWeightMedium'
      component='span'
      color='text.primary'
    >
      {name}
    </Box>
  );

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Delete list {name}?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          This list {highlightName} includes {completedCount} completed
          tasks and {incompleteCount} incomplete tasks. Once this list is
          deleted, all of its tasks (both incomplete and completed) are
          gone forerver as if they did not exist at all in the first place.
        </DialogContentText>

        <DialogContentText style={{marginBottom: 0}}>
          Are you sure you wish to do this?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Never mind
        </Button>

        <Button
          variant='contained'
          size='small'
          color='danger'
          loading={loading}
          onClick={() => {
            deleteTodoList();
          }}
        >
          Yes, delete it
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

DeleteTodoListConfirm.propTypes = {
  todoList: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    incompleteCount: PropTypes.number.isRequired,
    completedCount: PropTypes.number.isRequired
  }).isRequired
};

export default DeleteTodoListConfirm;
