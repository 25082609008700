import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import LinearStepper from '../../ui/stepper/LinearStepper';
import LinearStep from '../../ui/stepper/LinearStep';
import DialogTitle from '../../ui/dialog/MultiStepTitleContainer';
import DialogContent from '../../ui/dialog/MultiStepContentContainer';
import DialogContext from '../../ui/dialog/DialogContext';
import OptionListTitle from './OptionListTitle';
import OptionList, { uploadOption } from './OptionList';
import OptionDialogTitle from './OptionDialogTitle';
import OptionDialogContent from './OptionDialogContent';

// 4 sec transition duration.
const TRANSITION_DURATION = '.2s';

function OptionDialog({ upload, defaultOption }) {
  const { closeDialog } = React.useContext(DialogContext);

  // Keep track of active step and option picked.

  const [activeStep, setActiveStep] = React.useState(defaultOption ? 1 : 0);
  const [option, setOption] = React.useState(defaultOption);

  React.useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  React.useEffect(() => {
    if (defaultOption) {
      setActiveStep(1);
    }
  }, [defaultOption]);

  // Make a transition when an option is clicked
  const handleOptionClick = (clickedOption) => {
    setOption(clickedOption);
    setActiveStep(1);
  };

  // Go back.
  // IMPORTANT NODE: we don't reset the option here.
  const handleGoback = () => {
    setActiveStep(0);
  };

  // Ref to dialog actions container.
  const [actionsContainer, setActionsContainer] = React.useState(null);
  const setActionsContainerRef = (elem) => {
    setActionsContainer(elem);
  };

  return (
    <React.Fragment>
      <DialogTitle>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <OptionListTitle
              uploadName={`${upload.name}${upload.ext}`}
              onClose={closeDialog}
            />
          </LinearStep>

          <LinearStep>
            <OptionDialogTitle
              option={option}
              onGoBack={handleGoback}
              onClose={closeDialog}
            />
          </LinearStep>
        </LinearStepper>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <OptionList onOptionClick={handleOptionClick} />
          </LinearStep>

          <LinearStep>
            <OptionDialogContent
              upload={upload}
              onClose={closeDialog}
              option={option}
              actionsContainer={actionsContainer}
            />
          </LinearStep>
        </LinearStepper>
      </DialogContent>

      {/* placeholder for dialog actions */}
      <div>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep />
          <LinearStep ref={setActionsContainerRef} />
        </LinearStepper>
      </div>
    </React.Fragment>
  );
}

OptionDialog.propTypes = {
  upload: PropTypes.object.isRequired,
  defaultOption: PropTypes.oneOf(Object.values(uploadOption))
};

OptionDialog.defaultProps = {
  defaultOption: null
};

export default OptionDialog;
