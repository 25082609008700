import React from 'react';
import PropTypes from 'prop-types';
import TodoDetails from '../../todo/details/TodoDetails';
import EventDetails from '../event-details/EventDetails';

function Schedule({ scheduleType, scheduleId, onClose }) {
  if (scheduleType === 'task') {
    return <TodoDetails todoId={scheduleId} onClose={onClose} />;
  } else if (scheduleType === 'event') {
    return <EventDetails eventId={scheduleId} onClose={onClose} />;
  }

  return null;
}

Schedule.propTypes = {
  scheduleType: PropTypes.oneOf(['task', 'event']).isRequired,
  scheduleId: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default Schedule;
