import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import HomeIcon from '../ui/icons/Home';
import TeamIcon from '../ui/icons/Groups';
import PeopleIcon from '../ui/icons/Person';

export default function useNavItems() {
  const { url } = useRouteMatch();
  return [
    {
      primary: 'Home',
      to: url,
      exact: true,
      icon: <HomeIcon />
    },
    {
      primary: 'Teams',
      to: `${url}/teams`,
      exact: true,
      icon: <TeamIcon />
    },
    {
      primary: 'People',
      to: `${url}/people`,
      exact: true,
      icon: <PeopleIcon />
    }
  ];
}
