import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../../oops/Error';

const EVENT_SUBSCRIBERS_QUERY = gql`
  query ListEventSubscribers($eventId: ID!) {
    event(id: $eventId) {
      id
      followers {
        id
        username
        email
        avatar
      }
    }
  }
`;

function SubscribersQuery({
  eventId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    EVENT_SUBSCRIBERS_QUERY,
    {
      variables: { eventId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.event.followers, otherQueryResults);
}


SubscribersQuery.propTypes = {
  eventId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default SubscribersQuery;
