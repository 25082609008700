import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  avatar: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    fontSize: '0.875rem',
    marginLeft: -4
  }
}))((props) => <AvatarGroup {...props} />);
