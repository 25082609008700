import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useMatchCurrentScheduleUrl
from '../daily-schedule/useMatchCurrentScheduleUrl';
import useScheduleUrl from '../daily-schedule/useScheduleUrl';
import withContextMenu from '../daily-schedule/withContextMenu';
import AllDayChip from './ui/AllDayChip';

function AllDayItem({ schedule, chipProps, ...rest }) {
  const isFocus = useMatchCurrentScheduleUrl(schedule);
  const scheduleUrl = useScheduleUrl(schedule);
  const history = useHistory();

  const redirectToScheduleDetails = (e) => {
    history.push(scheduleUrl);
  };

  return (
    <AllDayChip
      {...chipProps}
      {...rest}
      focus={isFocus}
      onClick={redirectToScheduleDetails}
    />
  );
}

AllDayItem.propTypes = {
  schedule: PropTypes.object.isRequired,
  chipProps: PropTypes.object.isRequired
};

export default withContextMenu(AllDayItem);
