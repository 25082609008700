import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../ui/progress/PageLoading';
import PageError from '../oops/PageError';

const GET_CURRENT_WORKSPACE_QUERY = gql`
  query GetCurrentWorkspace($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      id
    }
  }
`;

function CurrentWorkspaceQuery(props) {
  const { workspaceId, children, ...otherOptions } = props;
  const { loading, error, data } = useQuery(GET_CURRENT_WORKSPACE_QUERY, {
    variables: { workspaceId },
    ...otherOptions
  });

  if (loading) return <Loading />;
  if (error) return <PageError error={error} />;
  return children(data.workspace);
}

CurrentWorkspaceQuery.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default CurrentWorkspaceQuery;
