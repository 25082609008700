import React from 'react';
import PropTypes from 'prop-types';
import useMemberAddedSubscription from './hooks/useMemberAddedSubscription';
import {TEAM_DETAILS_AND_MEMBERS_QUERY} from './TeamDetailsAndMembersQuery';

function TeamMemberAddedSubscriber({ teamId }) {
  useMemberAddedSubscription({
    teamId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) return;

      const oldData = client.readQuery({
        query: TEAM_DETAILS_AND_MEMBERS_QUERY,
        variables: { teamId }
      });

      const oldMembers = oldData.team.members;
      let newMembers = subscriptionData.data.teamMemberAdded;

      newMembers = newMembers.filter(({ id }) => (
        !oldMembers.some((m) => m.id === id)
      ));

      if (newMembers.length === 0) return;

      const updatedMembers = [...newMembers, ...oldMembers];

      client.writeQuery({
        query: TEAM_DETAILS_AND_MEMBERS_QUERY,
        variables: { teamId },
        data: {
          team: {
            ...oldData.team,
            members: updatedMembers
          }
        }
      });
    }
  });

  return null;
}

TeamMemberAddedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamMemberAddedSubscriber);
