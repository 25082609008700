import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TimelineContext from './TimelineContext';
import Ruler from './Ruler';

const useStyles = makeStyles((theme) => ({
  timeline: {
    width: '100%',
    position: 'relative',
    '&::after': {
      content: '" "',
      display: 'block',
      height: 0,
      clear: 'both'
    }
  }
}));

function Timeline(props) {
  const {
    rulerWidth,
    dividerWidth,
    breakpoint,
    children,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(breakpoint));
  const ctx = { rulerWidth, dividerWidth, matches };

  return (
    <div className={clsx(classes.timeline, className)} {...rest}>
      <TimelineContext.Provider value={ctx}>
        <Ruler />
        {children}
      </TimelineContext.Provider>
    </div>
  );
}

Timeline.propTypes = {
  children: PropTypes.node,
  rulerWidth: PropTypes.number,
  dividerWidth: PropTypes.number,
  breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
};

Timeline.defaultProps = {
  rulerWidth: 4,
  dividerWidth: 64,
  breakpoint: 'sm'
};

export default Timeline;
