import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useDropzone} from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AttachmentLabel from './AttachmentLabel';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    cursor: 'pointer'
  },
  placeholder: {
    marginLeft: theme.spacing(5.5),
    color: theme.palette.text.placeholder,
    display: 'none'
  },
  showPlaceholder: {
    display: 'block'
  }
}));

function UploadDropzone({ onDrop, showPlaceholder }) {
  const classes = useStyles();

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    noDrag: true
  });

  return (
    <div className={classes.dropzone} {...getRootProps()}>
      <AttachmentLabel />
      <input {...getInputProps()} />
      <Typography
        variant='body2'
        className={clsx(classes.placeholder, {
          [classes.showPlaceholder]: showPlaceholder
        })}
      >
        Add attachments...
      </Typography>
    </div>
  );
}

UploadDropzone.propTypes = {
  showPlaceholder: PropTypes.bool,
  onDrop: PropTypes.func
};

UploadDropzone.defaultProps = {
  showPlaceholder: true
};

export default UploadDropzone;
