import React from 'react';
import IconButton from '../ui/button/IconButton';
import ArrowDropDownIcon from '../ui/icons/ArrowDropdown';
import SecondaryNavItem from './SecondaryNavItem';
import LoadingMenu from '../account/menu/LoadingMenu';
import AccountMenu from '../account/menu/AccountMenu';
import useGetCurrentAccountLazyQuery
from '../account/hooks/useGetCurrentAccountLazyQuery';

const otherMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  PaperProps: {
    style: {
      marginTop: 8,
      width: 320
    }
  }
};

export default function AccountMenuButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [getCurrentAccount, { called, loading, data }] =
    useGetCurrentAccountLazyQuery();

  const renderMenu = () => {
    if (called && loading) {
      return (
        <LoadingMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          {...otherMenuProps}
        />
      );
    }

    if (data) {
      return (
        <AccountMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          account={data.me}
          {...otherMenuProps}
        />
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <SecondaryNavItem tooltipTitle='Account and settings'>
        <IconButton
          color={Boolean(anchorEl) ? 'accent' : 'primary'}
          icon={ <ArrowDropDownIcon /> }
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            getCurrentAccount();
          }}
        />
      </SecondaryNavItem>
      {renderMenu()}
    </React.Fragment>
  );
}
