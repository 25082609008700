import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../ui/button/ButtonLink';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  dot: {
    display: 'inline-block',
    width: 4,
    height: 4,
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.divider,
    borderRadius: '50%',
    verticalAlign: 'middle'
  }
}));

function AvatarUploaderActions(props) {
  const classes = useStyles();
  const { onUpload, onRemove, loading, hasAvatar } = props;

  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          <Button
            variant='subtitle2'
            onClick={onUpload}
          >
            {hasAvatar ? 'Upload new photo' : 'Upload your photo'}
          </Button>
          {hasAvatar && (
            <>
              <span className={classes.dot}></span>
              <Button
                variant='subtitle2'
                onClick={onRemove}
              >
                Remove my photo
              </Button>
            </>
          )}
        </div>
      )}
      <Typography
        variant='caption'
        component='small'
        color='textSecondary'
      >
        Photos help your teammates recognize you in taskbook.
      </Typography>
    </div>
  );
}

AvatarUploaderActions.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasAvatar: PropTypes.bool.isRequired
};

AvatarUploaderActions.defaultProps = {
  loading: false
};

export default React.memo(AvatarUploaderActions);
