import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import TrapFocus from '../misc/TrapFocus';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto'
  },
  container: {
    outline: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      '&.MuiContainer-maxWidthSm': {
        maxWidth: 720
      }
    }
  },
  containerFullscreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: (theme.zIndex.drawer + theme.zIndex.modal) / 2,
    margin: 0,
    padding: 0
  }
}));

const isESC = (keyCode) => keyCode === 27;

const ContainerContext = React.createContext(null);

function FileDetailsContainer({ fullscreen, onExitFullscreen, children }) {
  const classes = useStyles();
  const sentinelEndRef = React.useRef();

  const ctx = React.useMemo(() => ({
    scrollToBottom: () => {
      sentinelEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }), []);

  const handleKeyDown = (event) => {
    const key = event.which || event.keyCode;

    if (isESC(key) && fullscreen && onExitFullscreen) {
      event.preventDefault();
      event.stopPropagation();
      onExitFullscreen();
    }
  };

  return (
    <ContainerContext.Provider value={ctx}>
      <div className={classes.root}>
        <TrapFocus trapped={fullscreen}>
          <Container
            maxWidth={fullscreen ? false : 'sm'}
            className={clsx(classes.container, {
              [classes.containerFullscreen]: fullscreen
            })}
            onKeyDown={handleKeyDown}
          >
            {children}
          </Container>
        </TrapFocus>
        <div ref={sentinelEndRef} />
      </div>
    </ContainerContext.Provider>
  );
}

FileDetailsContainer.propTypes = {
  fullscreen: PropTypes.bool,
  onExitFullscreen: PropTypes.func,
  children: PropTypes.node
};

FileDetailsContainer.defaultProps = {
  fullscreen: false
};

export default FileDetailsContainer;

export function useContainerContext() {
  return React.useContext(ContainerContext);
}
