import React from 'react';
import PropTypes from 'prop-types';

// See [1] for more details about error handling.
// [1] - https://developer.mozilla.org/en-US/docs/Web/Guide/
// Audio_and_video_delivery.
function Audio({ sources, fallback, ...rest }) {
  const [error, setError] = React.useState(false);

  const handleLastSourceError = (event) => {
    setError(true);
  };

  // Showing fallback content when no source could be decoded.
  if (error) { return fallback; }

  return (
    <audio controls preload='auto' {...rest}>
      {sources.map(({ src, type }, idx) => {
        const isLastSource = (idx + 1) === sources.length;

        return isLastSource ? (
          <source
            key={type}
            src={src}
            type={type}
            onError={handleLastSourceError}
          />
        ) : (
          <source key={type} src={src} type={type} />
        );
      })}

      {/* also fallback for browsers that don't support audio tag */}
      {fallback}
    </audio>
  );
}

Audio.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({
    // The source and MIME type of the audio
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })).isRequired,

  // Fallback node to render when either non of the sources could be decoded
  // in the current browser or the current browser does not support the
  // audio tag.
  fallback: PropTypes.node
};

Audio.defaultProps = {
  fallback: (
    <div>
      Sorry, your browser doesn't support embedded audios.
    </div>
  )
};

export default Audio;
