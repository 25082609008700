import React from 'react';
import PropTypes from 'prop-types';
import UploadProgress from './UploadProgress';
import UploadSucceeded from './UploadSucceeded';
import UploadFailed from './UploadFailed';

const StatusCode = Object.freeze({
  'UPLOADING': 1,
  'SUCCEEDED': 2,
  'FAILED': 3,
  'CANCELED': 4
});

const STATUS_CODES = Object.values(StatusCode);

function UploadStatus({ statusCode, progress, onCancel, onRetry }) {
  switch(statusCode) {
    case StatusCode.UPLOADING:
      return <UploadProgress progress={progress} onCancel={onCancel} />;
    case StatusCode.SUCCEEDED:
      return <UploadSucceeded />;
    case StatusCode.FAILED:
    case StatusCode.CANCELED:
      return <UploadFailed onRetry={onRetry} />;
    default:
      return null;
  }
}

UploadStatus.propTypes = {
  statusCode: PropTypes.oneOf(STATUS_CODES).isRequired,
  progress: PropTypes.number,
  onCancel: PropTypes.func,
  onRetry: PropTypes.func
};

export default UploadStatus;
export { StatusCode, STATUS_CODES };
