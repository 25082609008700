import gql from 'graphql-tag';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import useTeamsURL from '../../team/hooks/useTeamsURL';

const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      id
      team { id }
    }
  }
`;

export default function useDeleteProjectMutation(options) {
  const { onCompleted: onFinished, ...otherOptions } = options;
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  const teamsUrl = useTeamsURL();
  const { pathname } = useLocation();
  const history = useHistory();

  return useMutation(DELETE_PROJECT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },

    onCompleted: (data) => {
      if (onFinished) { onFinished(data); }

      // TODO: Is using this heuristic to determine if we're currently
      // on the project page OK?

      const { id, team } = data.deleteProject;

      const matches = matchPath(
        pathname,
        '/workspaces/:workspaceId/projects/:projectId'
      );

      if (matches && matches.params.projectId === id) {
        const teamUrl = `${teamsUrl}/${team.id}`;
        history.push(teamUrl);
      }
    },

    ...otherOptions
  });
}
