import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1010,
  }
}));

function BreadcrumbsBar({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        {children}
      </Container>
    </div>
  );
}

BreadcrumbsBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default BreadcrumbsBar;
