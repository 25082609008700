import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  content: {
    width: '100%',
    padding: theme.spacing(4, 0)
  }
}));

function Header({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <div className={classes.content}>
          {children}
        </div>
        <Divider />
      </Container>
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node
};

export default Header;
