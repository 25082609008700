import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import ProjectHeaderContainer from './ProjectHeaderContainer';
import ProjectHeaderProject from './ProjectHeaderProject';
import ProjectHeaderMembers from './ProjectHeaderMembers';

function ProjectHeader({ project }) {
  return (
    <ProjectHeaderContainer>
      <ProjectHeaderProject project={project} />
      <Hidden xsDown>
        <ProjectHeaderMembers projectId={project.id} />
      </Hidden>
    </ProjectHeaderContainer>
  );
}

ProjectHeader.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    pinned: PropTypes.bool.isRequired,
    archived: PropTypes.bool.isRequired,
    team: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired
};

export default ProjectHeader;
