import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  eventsCell: {
    flex: '1 1 0%',
    borderRight: '1px solid transparent',
    outline: 'none'
  }
}));

export default function MonthGridEventsCell({ children }) {
  const classes = useStyles();
  return (
    <div role='gridcell' className={classes.eventsCell}>
      <div role='presentation'>
        {children}
      </div>
    </div>
  );
}
