import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';

const FILE_COMMENTS_QUERY = gql`
  query ListFileComments($fileId: ID!, $projectId: ID!) {
    project(projectId: $projectId) {
      id
      upload(id: $fileId) {
        id
        comments {
          id
          text
          autoGenerated
          severity
          ...CommentAuthor
          ...CommentFiles
          updatedAt
        }
      }
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

function CommentsQuery({
  projectId,
  fileId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    FILE_COMMENTS_QUERY,
    {
      variables: { projectId, fileId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.project.upload.comments, otherQueryResults);
}

CommentsQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default CommentsQuery;
export { FILE_COMMENTS_QUERY };
