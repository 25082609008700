import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginTop: theme.spacing(1)
  }
}));

export default function CardFooter(props) {
  const classes = useStyles();
  return <div className={classes.cardFooter} {...props} />;
}
