import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  dailyScheduleHeader: {
    flex: '0 0 auto',
    backgroundColor: theme.palette.background.paper,
    minHeight: theme.spacing(5),

    // TODO: FIXME please!
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,

    display: 'flex',

    // Vertical bar
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.spacing(6.5),
      width: 1,
      backgroundColor: theme.palette.divider
    }
  },

  left: {
    flex: '0 0 auto',
    width: theme.spacing(6.5)
  },
  right: {
    flexGrow: 1,
    width: 0
  },
  allDay: {
    lineHeight: 1.5, // 18px height
  }
}));

export default function DailyScheduleHeader({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.dailyScheduleHeader}>
      <div className={classes.left}>
        <Typography
          component='div'
          variant='caption'
          color='textSecondary'
          className={classes.allDay}
        >
          all-day
        </Typography>
      </div>
      <div className={classes.right}>
        {children}
      </div>
    </div>
  );
}
