import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import DialogContent from './DialogContent';
import DialogActionsContainer from './DialogActionsContainer';
import ArchiveConfirmText from './ArchiveConfirmText';
import useArchiveProjectMutation from '../hooks/useArchiveProjectMutation';

function ArchiveConfirm({ id, name, onClose, actionsContainer }) {
  const [archiveProject, { loading }] = useArchiveProjectMutation({
    variables: { projectId: id },

    optimisticResponse: {
      __typename: 'Mutation',
      archiveProject: {
        __typename: 'Project',
        id: id,
        archived: true
      }
    },

    onCompleted: (data) => {
      onClose();
    }
  });

  return (
    <DialogContent>
      <ArchiveConfirmText projectName={name} />
      <DialogActionsContainer container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          onClick={() => archiveProject()}
        >
          Yes, archive it
        </Button>
      </DialogActionsContainer>
    </DialogContent>
  )
}

ArchiveConfirm.propTypes = {
  // The project's name and ID
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  // Close dialog
  onClose: PropTypes.func,

  // DOM node to mount dialog actions
  actionsContainer: PropTypes.object
};

export default ArchiveConfirm;
