import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '../menu/MenuItem';
import CollapsedButton from './CollapsedButton';

function CollapsedMenu({ menuItems }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <CollapsedButton onClick={handleOpen} />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {menuItems.map(({ primary, to }) => (
          <MenuItem
            key={primary}
            primary={`${primary}/`}
            component={NavLink}
            to={to}
          />
        ))}
      </Menu>
    </React.Fragment>
  );
}

CollapsedMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }))
};

export default CollapsedMenu;
