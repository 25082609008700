import React from 'react';
import PropTypes from 'prop-types';
import { PROJECT_MEMBER_ADDED_SUBSCRIPTION }
from '../hooks/useProjectMemberAddedSubscription';

function MemberAddedSubscriber({
  projectId,
  subscribeToMemberAdded,
  matcher
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToMemberAdded({
      document: PROJECT_MEMBER_ADDED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return; }

        let newMembers = subscriptionData.data.projectMemberAdded;
        newMembers = newMembers.filter((m) => matcher(m));

        if (newMembers.length === 0) { return; }

        const oldMembers = prev.project.members;

        newMembers = newMembers.filter(({ id }) => (
          !oldMembers.some((m) => m.id === id)
        ));

        if (newMembers.length === 0) { return; }

        const updatedMembers = [...oldMembers, ...newMembers];

        return {
          project: {
            ...prev.project,
            members: updatedMembers
          }
        };
      }
    });

    return () => unsubscribe();
  }, [projectId, subscribeToMemberAdded, matcher]);

  return null;
}

MemberAddedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired,
  subscribeToMemberAdded: PropTypes.func.isRequired,
  matcher: PropTypes.func
};

MemberAddedSubscriber.defaultProps = {
  matcher: () => true
};

export default MemberAddedSubscriber;
