import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '../../button/Button';

function ClickableListItem({ disabledHover, ...rest }) {
  return disabledHover ? (
    <ButtonBase component='div' {...rest} />
  ) : (
    <Button component='div' variant='text' {...rest} />
  );
}

ClickableListItem.propTypes = {
  disabledHover: PropTypes.bool
};

ClickableListItem.defaultProps = {
  disabledHover: false
};

export default ClickableListItem;
