import React from 'react';
import PriorityIcon from '../../../icons/16x16/Priority';
import PrioritySelect from '../../../new-ui/PrioritySelect';
import FormField from './FormField';

export default function PriorityField(props) {
  return (
    <FormField icon={<PriorityIcon color='secondary' />} >
      <PrioritySelect
        placeholderVariant='body2'
        placeholderColor='secondary'
        {...props}
      />
    </FormField>
  );
}
