import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles({
  root: {
    height: '100%',
    width: '100%'
  }
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    fontSize: '0.875rem',
    overflow: 'hidden',
    padding: 0,
    '&:hover:not($focused)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$focused': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white
    },
    '&:not(:hover):not($focused) $input': {
      '&::placeholder': {
        color: 'transparent'
      }
    }
  },
  input: {
    padding: theme.spacing(1.5),
    margin: 0,
    height: '100%',
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1.0,
      fontSize: '0.875rem'
    },
  },
  focused: {},
}));

export default React.forwardRef(({ InputProps, ...rest }, ref) => {
  const classes = useStyles();
  return (
    <StyledTextField
      ref={ref}
      InputProps={{
        classes,
        ...InputProps,
        disableUnderline: true
      }}
      {...rest}
    />
  );
});
