import { getHours, getMinutes } from 'date-fns';
import formatTimeOfDay from './formatTimeOfDay';

// Returns the time { hours, minutes, label } for the given date.
export default function getTime(date) {
  return formatTimeOfDay({
    hours: getHours(date),
    minutes: getMinutes(date)
  });
}
