import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '../../dialog/DialogContent';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(1, 0),
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function DialogContent(props) {
  const classes = useStyles();
  return (
    <MuiDialogContent
      className={classes.dialogContent}
      {...props}
    />
  );
}
