import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useFocusWithin from './useFocusWithin';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.background.paper,

    '& > :nth-child(2)': {
      borderLeft: 0
    },

    '& > :laft-child': {
      borderRight: 0
    },

    // It's important that we target every cell within this row
    // (not the row itself)
    '&:hover:not($focusWithin) > *': {
      backgroundColor: theme.palette.action.hover
    }
  },
  focusWithin: {
    // It's important that we target every cell within this row
    // (not the row itself)
    '& > *': {
      backgroundColor: '#E7F3FF'
    }
  }
}));

const FocusRow = React.forwardRef(
  function FocusRow(props, ref) {
    const classes = useStyles();

    const {
      highlight,
      onFocusWithinChange: handleFocusWithinChange,
      className,
      ...rest
    } = props;

    const [isFocusWithin, setFocusWithin] = React.useState(false);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => {
        setFocusWithin(isFocusWithin);
        if (handleFocusWithinChange) handleFocusWithinChange(isFocusWithin);
      }
    });

    return (
      <tr
        ref={ref}
        className={clsx(classes.row, {
          [classes.focusWithin]: isFocusWithin || highlight
        }, className)}
        {...focusWithinProps}
        {...rest}
      />
    );
  }
);

FocusRow.propTypes = {
  highlight: PropTypes.bool,
  onFocusWithinChange: PropTypes.func
};

export default FocusRow;
