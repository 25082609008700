import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import EventsCell from './ui/MonthGridEventsCell';
import EventChipContainer from './ui/EventChipContainer';
import EventChipPlaceholder from './ui/EventChipPlaceholder';
import OverflowMenu from './ui/MonthGridEventsCellOverflowMenu';
import EventChip from './EventChip';
import { getEventTitle, isTodo, isEvent, getDateSlug } from './helpers';
import useCalendarUrl from './useCalendarUrl';

function _getEventKey(event) {
  let prefix = '';

  if (isTodo(event)) {
    prefix = '__backend_todo_id';
  } else if (isEvent(event)) {
    prefix = '__backend_event_id';
  }

  return `${prefix}_${event.id}`;
}

function MonthGridEventsCell({ projectId, date, events }) {
  const overflowEvents = events.filter((e) => e.overflow);

  const calendarUrl = useCalendarUrl();
  const history = useHistory();

  const redirectToDayView = (mouseEvt) => {
    const slug = getDateSlug(date);
    const view = 'day';
    const toUrl = `${calendarUrl}/${view}/${slug}`;
    history.push(toUrl);
  };

  return (
    <EventsCell>
      {events.map(({ event, overflow, ...attrs }) => (
        <EventChipContainer
          key={_getEventKey(event)}
          invisible={attrs.invisible || overflow}
          {...attrs}
        >
          {attrs.invisible ? (
            <EventChipPlaceholder
              eventTitle={getEventTitle(event)}
              leftRadius={attrs.leftPadding}
              rightRadius={attrs.rightPadding}
            />
          ) : (
            <EventChip
              projectId={projectId}
              event={event}
              leftRadius={attrs.leftPadding}
              rightRadius={attrs.rightPadding}
            />
          )}
        </EventChipContainer>
      ))}

      <OverflowMenu
        overflowEvents={overflowEvents}
        onClick={redirectToDayView}
      />
    </EventsCell>
  );
}


MonthGridEventsCell.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,

  // List of events with presentation attributes within the day.
  events: PropTypes.arrayOf(PropTypes.shape({
    // The event itself
    event: PropTypes.object.isRequired,

    // Position of this event within the day
    position: PropTypes.number.isRequired,

    // Which day of the week?
    colIndex: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired,

    // has left padding if starts here
    leftPadding: PropTypes.bool.isRequired,

    // has right padding if ends here
    rightPadding: PropTypes.bool.isRequired,

    // Is this overflow the container
    overflow: PropTypes.bool.isRequired
  })).isRequired
};

export default MonthGridEventsCell;
