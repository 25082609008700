import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from './ParticipantListItem';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0
  }
}));

function ParticipantList({ users }) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      {users.map((user) => (
        <ListItem key={user.id} user={user} />
      ))}
    </List>
  );
}

ParticipantList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })).isRequired
};

export default ParticipantList;
