//
// Fake new comment IDs for optimistic responses.
//

var __newCommentId = 0;
const __newCommentIdPrefix = 'new_comment_';
const __newCommentIdPattern = new RegExp(`^${__newCommentIdPrefix}`);

export function generateNewCommentId() {
  __newCommentId++;
  return __newCommentIdPrefix + __newCommentId;
}

export function matchesNewCommentId(commentId) {
  return __newCommentIdPattern.test(commentId);
}

// Generate HTML id attribute for each comment item
export function generateTodoCommentHtmlId(todoId, commentId) {
  return `__todo_${todoId}_comment_${commentId}`;
}
