import React from 'react';
import IconButton from './SectionHeaderIconButton';
import DeleteIcon from '../../../icons/18x18/Trash';

export default function SectionHeaderDeleteButton(props) {
  return (
    <IconButton
      icon={<DeleteIcon />}
      tooltip='Delete this list'
      {...props}
    />
  );
}
