import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../ui/button/IconButton';
import { FILTER_PRIMARY } from '../ui/icons/Base';
import Tooltip from './ProjectHeaderToolbarTooltip';

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    width: 'auto',
    height: 28,
    minWidth: 28,
    borderRadius: 4,
    '&:hover $icon': {
      color: theme.palette.text.primary,
      filter: FILTER_PRIMARY
    }
  },
  icon: {}
}));

function ProjectHeaderToolbarButton({ icon, tooltip, ...rest }) {
  const classes = useStyles();

  const styledIcon = React.cloneElement(icon, {
    className: classes.icon
  });

  const button = (
    <IconButton
      color='secondary'
      background={false}
      square
      icon={styledIcon}
      className={classes.toolbarButton}
      {...rest}
    />
  );

  return Boolean(tooltip) ? (
    <Tooltip title={tooltip} arrow>
      {button}
    </Tooltip>
  ) : button;
}

ProjectHeaderToolbarButton.propTypes = {
  icon: PropTypes.node.isRequired,
  tooltip: PropTypes.string
};

export default ProjectHeaderToolbarButton;
