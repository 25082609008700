import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import FormatBoldIcon from '../../icons/20x20/FormatBold';
import FormatItalicIcon from '../../icons/20x20/FormatItalic';
import FormatUnderlinedIcon from '../../icons/20x20/FormatUnderline';
import ControlButton from './ControlButton';

const INLINE_STYLES = [
  {
    label: 'BOLD',
    icon: <FormatBoldIcon />,
    style: 'BOLD'
  },
  {
    label: 'ITALIC',
    icon: <FormatItalicIcon />,
    style: 'ITALIC'
  },
  {
    label: 'UNDERLINE',
    icon: <FormatUnderlinedIcon />,
    style: 'UNDERLINE'
  }
];

function InlineStyleControls({ editorState, onToggle }) {  
  const currentInlineStyle = editorState.getCurrentInlineStyle();

  return (
    <div>
      {INLINE_STYLES.map(({ label, icon, style }) => (
        <ControlButton
          key={label}
          icon={icon}
          active={currentInlineStyle.has(style)}
          onMouseDown={(event) => {
            event.preventDefault();
            onToggle(style);
          }}
        />
      ))}
    </div>
  );
}

InlineStyleControls.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onToggle: PropTypes.func.isRequired
};

export default InlineStyleControls;
