import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '../../ui/dialog/MultiStepTitle';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import UploadTable from './UploadTable';
import UploadFilesDropzone from './UploadFilesDropzone';
import UploadTableToolbar from './UploadTableToolbar';
import UploadFilesDestination from './UploadFilesDestination';
import { StatusCode } from '../../ui/file/table/UploadStatus';
import useRemoveUnsavedUploadsMutation
from '../hooks/useRemoveUnsavedUploadsMutation';
import useSaveUploadsMutation from '../hooks/useSaveUploadsMutation';
import useProjectUrl from '../../project/hooks/useProjectURL';
import CancelUploadsConfirmDialog from './CancelUploadsConfirmDialog';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    flexShrink: 0
  },
  dialogActions: {
    paddingBottom: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  actions: {
    flex: '0 0 auto',
    '& > *:not(:last-child)': {
      marginRight: 8
    }
  }
}));

function isRootFolder({ id }) {
  return id === 'root';
}

function appendAndRemoveDuplicates(state, newRows) {
  const trulyNewRows = newRows.filter((newRow) => {
    const found = state.find((row) => (row.id === newRow.id));
    return found ? false : true;
  });
  return [...state, ...trulyNewRows];
}

function UploadFilesDialog({ projectId, destinationFolder }) {
  const classes = useStyles();
  const { closeDialog } = React.useContext(DialogContext);
  const dropzoneRef = React.useRef();
  const [rows, setRows] = React.useState([]);
  const [destination, setDestination] = React.useState(destinationFolder);
  const [removeUnsavedUploads] = useRemoveUnsavedUploadsMutation();
  const isThisStillAliveRef = React.useRef(true);
  const projectUrl = useProjectUrl(projectId);
  const history = useHistory();

  const [
    saveUploadsAndRedirectToDestination,
    { loading: saving }
  ] = useSaveUploadsMutation({
    onCompleted: () => {
      const rootUrl = `${projectUrl}/files`;
      const destinationUrl = `${rootUrl}/directories/${destination.id}`;
      history.push(destinationUrl);
    }
  });

  const [
    openCancelUploadsConfirmDialog,
    setOpenCancelUploadsConfirmDialog
  ] = React.useState(false);

  const createNotesChangeHandler = React.useCallback((rowId) => {
    return (newNotes) => {
      setRows((prev) => prev.map((row) => {
        if (row.id !== rowId) { return row; }
        return { ...row, notes: newNotes };
      }));
    };
  }, []);

  const createToggleSelectedHandler = React.useCallback((rowId) => {
    return () => {
      setRows((prev) => prev.map((row) => {
        if (row.id !== rowId) { return row; }
        return { ...row, selected: !row.selected };
      }));
    }
  }, []);

  const createUploadStatusChangeHandler = React.useCallback((rowId) => {
    return (newStatusCode, data) => {
      if (isThisStillAliveRef.current) {
        setRows((prev) => prev.map((row) => {
          if (row.id !== rowId) {
            return row;
          }

          let newRow = {...row, statusCode: newStatusCode};

          if (newStatusCode === StatusCode.SUCCEEDED) {
            newRow = {...newRow, path: data.path};
          }

          return newRow;
        }));
      }
    }
  }, []);

  const handleDrop = React.useCallback((files) => {
    const newRows = files.map((file) => ({
      id: file.name,
      projectId,
      file,
      notes: '',
      onNotesChange: createNotesChangeHandler(file.name),
      selected: false,
      onToggleSelected: createToggleSelectedHandler(file.name),
      statusCode: StatusCode.UPLOADING,
      onStatusChange: createUploadStatusChangeHandler(file.name)
    }));

    setRows((prev) => appendAndRemoveDuplicates(prev, newRows));
  }, [
    projectId,
    createNotesChangeHandler,
    createToggleSelectedHandler,
    createUploadStatusChangeHandler
  ]);

  const handleSelectAllClick = React.useCallback((event) => {
    if (event.target.checked) {
      setRows((prev) => prev.map((row) => ({ ...row, selected: true })));
    } else {
      setRows((prev) => prev.map((row) => ({ ...row, selected: false })));
    }
  }, []);

  const handleRemoveSelected = React.useCallback(() => {
    const paths = rows.filter((row) => (
      row.selected && (row.statusCode === StatusCode.SUCCEEDED)
    )).map((row) => row.path);

    if (paths.length > 0) {
      console.log('Remove unsaved files: ', paths);
      removeUnsavedUploads({
        variables: { paths }
      });
    }

    setRows((prev) => prev.filter((row) => !row.selected));
  }, [rows, removeUnsavedUploads]);

  const handleOpenFilePrompt = React.useCallback(() => {
    dropzoneRef.current.open();
  }, []);

  const handleDestinationChange = (event, newDestination) => {
    setDestination(newDestination);
  };

  const handleCloseCancelUploadsConfirmDialog = () => {
    setOpenCancelUploadsConfirmDialog(false);
  };

  const haveAnyOngoingUploads = rows.some(({ statusCode }) => (
    statusCode === StatusCode.UPLOADING
  ));

  const saveSucceededUploads = () => {
    const succeededUploads = rows.filter((row) => (
      row.statusCode === StatusCode.SUCCEEDED
    )).map(({ file, notes, path }) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      notes: notes || null,
      key: path
    }));

    saveUploadsAndRedirectToDestination({
      variables: {
        projectId,
        directoryId: isRootFolder(destination) ? null : destination.id,
        uploads: succeededUploads
      }
    });
  };

  const cancelOngoingUploads = () => {
    setRows((prev) => prev.filter((row) => (
      row.statusCode !== StatusCode.UPLOADING
    )));
  };

  const handleRequestCloseDialog = () => {
    if (haveAnyOngoingUploads) {
      setOpenCancelUploadsConfirmDialog(true);
    } else {
      saveSucceededUploads();
      closeDialog();
    }
  };

  const handleCancelOngoingUploads = () => {
    cancelOngoingUploads();
    handleCloseCancelUploadsConfirmDialog();
    saveSucceededUploads();
    closeDialog();
  };

  const numFiles = rows.length;
  const numBytes = rows.reduce((acc, { file }) => acc + file.size, 0);
  const numSelected = rows.filter((row) => row.selected).length;

  const isSubmitDisabled = () => {
    return (numFiles === 0) || haveAnyOngoingUploads;
  };

  React.useEffect(() => {
    isThisStillAliveRef.current = true;
    return () => isThisStillAliveRef.current = false;
  }, []);

  return (
    <React.Fragment>
      <DialogTitle
        rootClassName={classes.dialogTitle}
        onClose={handleRequestCloseDialog}
      >
        Upload files
      </DialogTitle>

      <Divider />

      <UploadTableToolbar
        numFiles={numFiles}
        numBytes={numBytes}
        numSelected={numSelected}
        onRemoveSelected={handleRemoveSelected}
        onOpenFilePrompt={handleOpenFilePrompt}
      />

      <UploadFilesDropzone ref={dropzoneRef} onDrop={handleDrop}>
        <UploadTable
          numRows={numFiles}
          numSelected={numSelected}
          rows={rows}
          onSelectAllClick={handleSelectAllClick}
        />
      </UploadFilesDropzone>

      <DialogActions className={classes.dialogActions}>
        <UploadFilesDestination
          projectId={projectId}
          value={destination}
          onChange={handleDestinationChange}
        />

        <div className={classes.actions}>
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={handleRequestCloseDialog}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            size='small'
            color='primary'
            disabled={isSubmitDisabled() || saving}
            loading={saving}
            onClick={() => {
              saveSucceededUploads();
              closeDialog();
            }}
          >
            Save
          </Button>
        </div>
      </DialogActions>

      <CancelUploadsConfirmDialog
        open={openCancelUploadsConfirmDialog}
        onClose={handleCloseCancelUploadsConfirmDialog}
        onCancelUploads={handleCancelOngoingUploads}
      />
    </React.Fragment>
  );
}

UploadFilesDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  destinationFolder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default UploadFilesDialog;
