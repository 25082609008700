import React from 'react';
import FullscreenIcon from '../icons/20x20/Fullscreen';
import FullscreenExitIcon from '../icons/20x20/FullscreenExit';
import ToolbarButton from './ProjectHeaderToolbarButton';
import { useProjectContainer } from './ProjectContainer';

function ProjectHeaderToggleFullscreenButton() {
  const { fullscreen, onToggleFullscreen } = useProjectContainer();

  return (
    <ToolbarButton
      tooltip={fullscreen ? 'Exit fullscreen' : 'Go fullscreen'}
      icon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      onClick={onToggleFullscreen}
    />
  );
}

export default ProjectHeaderToggleFullscreenButton;
