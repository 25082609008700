import gql from 'graphql-tag';

export const CHIP_EVENT = gql`
  fragment ChipEvent on Event {
    id
    title
    color
    startsAt
    endsAt
    allDay
  }
`;

export const CHIP_TODO = gql`
  fragment ChipTodo on TodoItem {
    id
    name
    color
    dueDate
    completed
  }
`;

export const EVENT_DETAILS = gql`
  fragment EventDetails on Event {
    id
    title
    description
    allDay
    startsAt
    endsAt
    color
  }
`;

export const EVENT_PARTICIPANTS = gql`
  fragment EventParticipants on Event {
    participants {
      id
      username
      email
      avatar
    }
  }
`;

export const EVENT_REMINDERS = gql`
  fragment EventReminders on Event {
    reminders {
      id
      method
      offsetUnit
      offsetQuantity
      offsetMinutes
    }
  }
`;
