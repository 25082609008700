import React from 'react';
import PropTypes from 'prop-types';
import { TODO_CRITERIA_SHAPE } from '../helpers';
import TodoItemAppendedSubscriber from '../NewTodoItemAppendedSubscriber';
import TodoItemPrependedSubscriber from '../NewTodoItemPrependedSubscriber';
import TodoItemUpdatedSubscriber from '../SomeTodoItemUpdatedSubscriber';
import SomeTodoItemMovedIntoSubscriber
from '../SomeTodoItemMovedIntoSubscriber';
import SomeTodoItemMovedOutSubscriber
from '../SomeTodoItemMovedOutSubscriber';

function SectionSubscribers({ todoListId, todoCriteria }) {
  return (
    <React.Fragment>
      <TodoItemUpdatedSubscriber
        todoListId={todoListId}
        todoCriteria={todoCriteria}
      />

      <TodoItemPrependedSubscriber
        todoListId={todoListId}
        todoCriteria={todoCriteria}
      />

      <TodoItemAppendedSubscriber
        todoListId={todoListId}
        todoCriteria={todoCriteria}
      />

      <SomeTodoItemMovedIntoSubscriber
        todoListId={todoListId}
        todoCriteria={todoCriteria}
      />

      <SomeTodoItemMovedOutSubscriber todoListId={todoListId} />
    </React.Fragment>
  );
}

SectionSubscribers.propTypes = {
  todoListId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

export default React.memo(SectionSubscribers);
