import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import useDeactivateMembershipMutation
from '../hooks/useDeactivateMembershipMutation';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

function DeactivateOtherMembershipConfirm({ membershipId, username }) {
  const { closeDialog } = React.useContext(DialogContext);
  const { enqueueSuccessSnackbar } = useCustomSnackbar();

  const [
    deactivateMembership, { loading }
  ] = useDeactivateMembershipMutation({
    membershipId,
    onCompleted(data) {
      closeDialog();
      const message = `Deactivated ${username}'s membership`;
      enqueueSuccessSnackbar(message);
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Deactivate {username}?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Once this user is deactivated, he/she will no longer have
          access to this workspace.
        </DialogContentText>

        <DialogContentText style={{marginBottom: 0}}>
          Are you sure you wish to to this?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {!loading && (
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={closeDialog}
          >
            Never mind
          </Button>
        )}

        <Button
          variant='contained'
          color='primary'
          size='small'
          loading={loading}
          onClick={() => {
            deactivateMembership();
          }}
        >
          Yes, deactivate this user
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

DeactivateOtherMembershipConfirm.propTypes = {
  membershipId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default DeactivateOtherMembershipConfirm;
