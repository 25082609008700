import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from './UserOptionAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1)
  },
  username: {
    flex: '1 1 auto',
    width: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

function UserOption({ user }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UserAvatar className={classes.avatar} user={user} />
      <div className={classes.username}>
        {user.username}
      </div>
    </div>
  );
}

UserOption.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
};

export default UserOption;
