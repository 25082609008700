import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    margin: theme.spacing(0, 0, 1, 5.5)
  },
  formControlLabel: {
    ...theme.typography.body2
  },
  checkbox: {
    padding: theme.spacing(1)
  }
}));

export default function Checkbox({ label, ...rest }) {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        root: classes.formControlRoot,
        label: classes.formControlLabel
      }}
      control={
        <MuiCheckbox
          className={classes.checkbox}
          size='small'
          color='primary'
          {...rest}
        />
      }
      labelPlacement='start'
      label={label}
    />
  );
}
