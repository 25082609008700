import React from 'react';
import PropTypes from 'prop-types';
import List from '../ui/content/CommentListContainer';
import ListItem from '../../../ui/comments/ListItem';
import useUpdateCommentMutation from './useUpdateCommentMutation';
import useDeleteCommentMutation from './useDeleteCommentMutation';
import NewCommentAddedSubscriber from './NewCommentAddedSubscriber';
import SomeCommentUpdatedSubscriber from './SomeCommentUpdatedSubscriber';
import SomeCommentDeletedSubscriber from './SomeCommentDeletedSubscriber';
import { EVENT_COMMENTS_QUERY } from './CommentsQuery';
import { generateEventCommentHtmlId } from './helper';

function CommentList({ projectId, eventId, comments, subscribeToMore }) {
  const [updateComment] = useUpdateCommentMutation({
    variables: { eventId }
  });

  const [deleteComment] = useDeleteCommentMutation({
    variables: { eventId },
    update: (cache, { data: { deleteEventComment }}) => {
      const deletedId = deleteEventComment.id;

      const data = cache.readQuery({
        query: EVENT_COMMENTS_QUERY,
        variables: { eventId }
      });

      const comments = data.event.comments.filter(({ id }) => (
        id !== deletedId
      ));

      cache.writeQuery({
        query: EVENT_COMMENTS_QUERY,
        variables: { eventId },
        data: {
          event: {
            ...data.event,
            comments: comments
          }
        }
      });
    }
  });

  const handleUpdate = (commentId, data) => {
    updateComment({
      variables: { commentId, changes: data },
      optimisticResponse: {
        updateEventComment: {
          id: commentId,
          text: data.text,
          files: [],
          updatedAt: (new Date()).toISOString(),
          __typename: 'Comment'
        }
      }
    });
  };

  const handleDelete = (commentId) => {
    deleteComment({
      variables: { commentId },
      optimisticResponse: {
        deleteEventComment: {
          id: commentId,
          __typename: 'Comment'
        }
      }
    });
  };

  return (
    <List>
      {comments.map((comment) => (
        <ListItem
          key={comment.id}
          id={generateEventCommentHtmlId(eventId, comment.id)}
          comment={comment}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          readOnly={comment.autoGenerated}
          variant={comment.severity}
          projectId={projectId}
        />
      ))}
      <NewCommentAddedSubscriber
        eventId={eventId}
        subscribeToNewCommentAdded={subscribeToMore}
      />
      <SomeCommentUpdatedSubscriber eventId={eventId} />
      <SomeCommentDeletedSubscriber
        eventId={eventId}
        subscribeToSomeCommentDeleted={subscribeToMore}
      />
    </List>
  );
}

CommentList.propTypes = {
  projectId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,

  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string
    }).isRequired,
    updatedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  })).isRequired,

  subscribeToMore: PropTypes.func.isRequired
};

export default CommentList;
