import React from 'react';
import { useParams } from 'react-router-dom';
import Workspace from '../components/Workspace';
import DialogProvider from '../components/ui/dialog/DialogProvider';
import SnackbarProvider from '../components/snackbar/SnackbarProvider';

import CurrentWorkspaceQuery
from '../components/workspace/CurrentWorkspaceQuery';

import CurrentWorkspaceContext
from '../components/workspace/CurrentWorkspaceContext';

import AccessController from '../components/workspace/AccessController';

export default function MyWorkspace() {
  const { workspaceId } = useParams();

  React.useEffect(() => {
    document.title = 'Home';
  }, []);

  return (
    <CurrentWorkspaceQuery workspaceId={workspaceId}>
      {({ id }) => (
        <CurrentWorkspaceContext.Provider value={{
          currentWorkspaceId: id
        }}>
          <SnackbarProvider>
            <DialogProvider>
              <Workspace />
            </DialogProvider>
          </SnackbarProvider>
          <AccessController />
        </CurrentWorkspaceContext.Provider>
      )}
    </CurrentWorkspaceQuery>
  );
}
