import React from 'react';
import { cellRendererForColumn } from './helpers';
import Table from './Table';
import Header from './Header';
import Section from './SectionWithSubscribers';
import Footer from './Footer';
import SomeTodoListDeletedSubscriber from '../SomeTodoListDeletedSubscriber';
import NewTodoListCreatedSubscriber from '../NewTodoListCreatedSubscriber';
import SomeTodoListUpdatedSubscriber from '../SomeTodoListUpdatedSubscriber';

function SheetWithRawData({
  projectId,
  rootUrl,
  todoCriteria,
  rawColumns,
  rawSections,
  variables,
  subscribeToMore
}) {

  const cellPropsGetterForColumn = React.useMemo(() => ({
    'task_name': (todo) => ({
      todo: todo,
      rootUrl: rootUrl
    }),
    'assignee': (todo) => ({
      assignee: todo.assignee,
      projectId: projectId
    }),
    'priority': (todo) => ({priority: todo.priority}),
    'due_date': (todo) => ({dueDate: todo.dueDate }),
    'description': (todo) => ({description: todo.description})
  }), [projectId, rootUrl]);

  // EXTREMELY IMPORTANT OPTIMIZATION
  const sectionColumns = React.useMemo(() => {
    return rawColumns.filter(({ show }) => (
      show === true
    )).map((col) => ({
      ...col,
      getCellProps: cellPropsGetterForColumn[col.name],
      renderCell: cellRendererForColumn[col.name]
    }));
  }, [rawColumns, cellPropsGetterForColumn]);

  return (
    <Table todoCriteria={todoCriteria}>
      <Header
        projectId={projectId}
        rawColumns={rawColumns}
      />

      {rawSections.map(({ id, name, todoItems }) => (
        <Section
          key={id}
          id={id}
          name={name}
          todoCriteria={todoCriteria}
          cols={sectionColumns}
          rows={todoItems}
          rootUrl={rootUrl}
        />
      ))}

      <Footer
        colSpan={sectionColumns.length + 2}
        todosQueryVariables={variables}
      />

      <NewTodoListCreatedSubscriber
        projectId={projectId}
        subscribeToNewTodoListCreated={subscribeToMore}
      />

      <SomeTodoListDeletedSubscriber
        projectId={projectId}
        subscribeToSomeTodoListDeleted={subscribeToMore}
      />

      <SomeTodoListUpdatedSubscriber projectId={projectId} />
    </Table>
  );
}

export default SheetWithRawData;
