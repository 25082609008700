import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  PROJECT_DETAILS_FRAGMENT,
  PROJECT_PINNED_FRAGMENT,
  PROJECT_STATS_FRAGMENT,
  PROJECT_MEMBERS_FRAGMENT
} from '../../project/fragments';
import Error from '../../oops/Error';

const SEARCH_PROJECT_IN_TEAM_QUERY = gql`
  query SearchProjectInTeam(
    $teamId: ID!,
    $filter: ProjectFilter,
    $order: SortOrder,
    $limit: Int
  ) {
    team(teamId: $teamId) {
      id
      projects(filter: $filter, order: $order, limit: $limit)
      @connection(key: "project_search_results") {
        ...ProjectDetails
        ...ProjectPinned
        ...ProjectStats
        ...ProjectMembers
      }
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
  ${PROJECT_PINNED_FRAGMENT},
  ${PROJECT_STATS_FRAGMENT}
  ${PROJECT_MEMBERS_FRAGMENT}
`;

function SearchProjectInTeamQuery(props) {
  const {
    teamId,
    criteria,
    order,
    limit,
    onLoading,
    onError,
    children,
    ...otherQueryProps
  } = props;

  const { loading, error, data, ...otherQueryResults } = useQuery(
    SEARCH_PROJECT_IN_TEAM_QUERY,
    {
      variables: {
        teamId,
        filter: criteria,
        order: order,
        limit: limit
      },
      fetchPolicy: 'network-only',
      ...otherQueryProps
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.team.projects, otherQueryResults);
}

SearchProjectInTeamQuery.propTypes = {
  teamId: PropTypes.string.isRequired,
  criteria: PropTypes.object,
  order: PropTypes.oneOf(['ASC', 'DESC']),
  limit: PropTypes.number,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired,
};

SearchProjectInTeamQuery.defaultProps = {
  criteria: {},
  order: 'DESC'
};

export default SearchProjectInTeamQuery;
