import React from 'react';
import StyledTable from '../../ui/todo/sheet/Table';
import useReorderTodoItemMutation from '../hooks/useReorderTodoItemMutation';

export default function Table({ todoCriteria, ...rest }) {
  const criteria = {
    completionType: todoCriteria.completionType,
    assigneeId: todoCriteria.assignee ? todoCriteria.assignee.id : undefined,
    dueDateBetween: todoCriteria.dueDateBetween
  };

  const [reorderTodoItem] = useReorderTodoItemMutation();

  const handleRowReorder = (result) => {
    const { draggableId, source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }

    const todoItemId = draggableId;

    const srcIndex = source.index;
    const srcListId = source.droppableId;

    const dstIndex = destination.index;
    const dstListId = destination.droppableId;

    reorderTodoItem({
      variables: {
        todoItemId,
        todoListId: dstListId,
        index: dstIndex,
        filter: criteria
      },
      optimisticResponse: {
        __typename: 'Mutation',
        reorderTodoItem: {
          __typename: 'TodoReorderingResult',
          todoItem: {
            __typename: 'TodoItem',
            id: todoItemId
          },
          source: {
            __typename: 'TodoLocation',
            index: srcIndex,
            todoList: {
              __typename: 'TodoList',
              id: srcListId
            }
          },
          destination: {
            __typename: 'TodoLocation',
            index: dstIndex,
            todoList: {
              __typename: 'TodoList',
              id: dstListId
            }
          }
        }
      }
    });
  };

  return (
    <StyledTable
      onRowReorder={handleRowReorder}
      {...rest}
    />
  );
}
