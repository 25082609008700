import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 44, // Fix safari by
    marginTop: 1,
    padding: theme.spacing(1, 3, 1, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    zIndex: theme.zIndex.appBar - 1,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.01)',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));

export default function Toolbar(props) {
  const classes = useStyles();
  return <div className={classes.toolbar} {...props} />;
}
