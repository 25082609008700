import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import { MESSAGE_DETAILS, MESSAGE_AUTHOR, MESSAGE_RICH_TEXT }
from './fragments';

const PROJECT_MESSAGE_QUERY = gql`
  query GetProjectMessage($projectId: ID!, $messageId: ID!) {
    projectMessage(projectId: $projectId, messageId: $messageId) {
      ...MessageDetails
      ...MessageRichText
      ...MessageAuthor
    }
  }
  ${MESSAGE_DETAILS}
  ${MESSAGE_RICH_TEXT}
  ${MESSAGE_AUTHOR}
`;

function ProjectMessageQuery({
  projectId,
  messageId,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    PROJECT_MESSAGE_QUERY,
    {
      variables: { projectId, messageId },
      ...otherQueryOptions
    }
  );

  if (loading) { return onLoading ? onLoading() : null; }
  if (error) { return onError ? onError(error) : <Error error={error} />; }
  return children(data.projectMessage, otherQueryResults);
}

ProjectMessageQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ProjectMessageQuery;
