import React from 'react';
import PropTypes from 'prop-types';
import ActivityFeed from './ActivityFeed';
import updateQuery from './updateQuery';
import useActivitiesQuery from './useActivitiesQuery';

const LIMIT = 8;

function ProjectActivities({ projectId }) {
  const { loading, error, data, fetchMore } = useActivitiesQuery({
    variables: { projectId, first: LIMIT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const nodes = data ? data.project.activities.edges.map((edge) => (
    edge.node
  )) : [];

  const pageInfo = data ? data.project.activities.pageInfo : null;

  const handleLoadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor
        },
        updateQuery: updateQuery
      });
    }
  };

  return (
    <ActivityFeed
      loading={loading}
      error={error}
      entries={nodes}
      onLoadMore={handleLoadMore}
    />
  );
}

ProjectActivities.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectActivities;
