import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import StyledScheduleDrawer from './ui/StyledScheduleDrawer';
import Schedule from './Schedule';

function ScheduleDrawer({ rootUrl }) {
  const { scheduleType, scheduleId } = useParams();
  const history = useHistory();
  const isValidParams = Boolean(scheduleType) && Boolean(scheduleId);

  const handleClose = () => {
    history.push(rootUrl);
  };

  return (
    <StyledScheduleDrawer
      open={isValidParams}
      onClose={handleClose}
    >
      {isValidParams && (
        <Schedule
          scheduleType={scheduleType}
          scheduleId={scheduleId}
          onClose={handleClose}
        />
      )}
    </StyledScheduleDrawer>
  );
}

ScheduleDrawer.propTypes = {
  rootUrl: PropTypes.string.isRequired
};

export default ScheduleDrawer;
