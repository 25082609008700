import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Skeleton from '@material-ui/lab/Skeleton';

export default function ListItemSkeleton() {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton />}
        secondary={<Skeleton width='40%' />}
      />
    </ListItem>
  );
}
