import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import StyledMonthGridDaysRow from './ui/MonthGridDaysRow';
import FormDialog from './form-dialog/FormDialog';
import { useDialogContext } from './DialogProvider';
import useCalendarUrl from './useCalendarUrl';
import { getDateSlug } from './helpers';

function MonthGridDaysRow({ projectId, ...rest }) {
  const { openDialog } = useDialogContext();

  const calendarUrl = useCalendarUrl();
  const history = useHistory();

  const handleCellClick = (e, d) => {
    openDialog({
      dialogContent: (
        <FormDialog
          projectId={projectId}
          date={d}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  const handleDayClick = (e, d) => {
    e.preventDefault();
    e.stopPropagation();
    const slug = getDateSlug(d);
    const view = 'day';
    const toUrl = `${calendarUrl}/${view}/${slug}`;
    history.push(toUrl);
  };

  return (
    <StyledMonthGridDaysRow
      onCellClick={handleCellClick}
      onDayClick={handleDayClick}
      {...rest}
    />
  );
}

MonthGridDaysRow.propTypes = {
  // TODO: doesn't make a lot of sense for projectId to be here!
  projectId: PropTypes.string.isRequired
};

export default MonthGridDaysRow;
