import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.75, 2),
    minHeight: 'auto',
    borderRadius: 0,

    // To truncate long text
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&:active': {
      backgroundColor: '#EBEDF0'
    },
    '&:focus': {
      outline: 0
    },
    '&:focus:not(:active)': {
      backgroundColor: theme.palette.action.hover
    },

    '&$selected': {
      backgroundColor: 'rgba(45, 136, 255, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(45, 136, 255, 0.15)',
      },
      '&:active': {
        backgroundColor: 'rgba(45, 136, 255, 0.2)'
      },
      '&:focus': {
        backgroundColor: 'rgba(45, 136, 255, 0.2)'
      }
    }
  },

  body1: {
    ...theme.typography.body1,
    lineHeight: '24px'
  },
  body2: {
    ...theme.typography.body2,
    lineHeight: '24px'
  },

  selected: {}
}));

const MenuItem = React.forwardRef(function MenuItem(props, ref) {
  const { typography, ...rest } = props;
  const classes = useStyles();
  return (
    <MuiMenuItem
      ref={ref}
      classes={{
        root: clsx(classes.root, classes[typography]),
        selected: classes.selected
      }}
      {...rest}
    />
  );
});

MenuItem.propTypes = {
  typography: PropTypes.oneOf(['body1', 'body2'])
};

MenuItem.defaultProps = {
  typography: 'body1'
};

export default MenuItem;
