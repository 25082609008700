import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const ADD_MEMBERS_TO_PROJECT_MUTATION = gql`
  mutation AddMembersToProject($projectId: ID!, $userIds: [ID]) {
    addTeamMembersToProject(projectId: $projectId, userIds: $userIds) {
      id
    }
  }
`;

export default function useAddMembersMutation(options) {
  const { onCompleted: onFinished, ...rest } = options;
  const {
    enqueueSuccessSnackbar,
    enqueueGraphQLErrorSnackbar
  } = useCustomSnackbar();

  return useMutation(ADD_MEMBERS_TO_PROJECT_MUTATION, {
    onCompleted(data) {
      onFinished && onFinished();
      const count = data.addTeamMembersToProject.length;
      const message = (count > 1) ?
                      `${count} new members added!` :
                      `${count} new member added!`;
      enqueueSuccessSnackbar(message);
    },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error)
    },
    ...rest
  });
}
