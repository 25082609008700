import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../../oops/Error';

const TODO_FOLLOWERS_QUERY = gql`
  query ListTodoFollowers($todoId: ID!) {
    todoItem(todoItemId: $todoId) {
      id
      followers {
        id
        username
        email
        avatar
      }
    }
  }
`;

function FollowersQuery({
  todoId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    TODO_FOLLOWERS_QUERY,
    {
      variables: { todoId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.todoItem.followers, otherQueryResults);
}


FollowersQuery.propTypes = {
  todoId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default FollowersQuery;
