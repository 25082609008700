import React from 'react';
import PropTypes from 'prop-types';
import StyledAutocompleteOwner from './StyledAutocompleteOwner';
import OwnerDropdownButton from './OwnerDropdownButton';

function AutocompleteOwner({ ownership, options, onOwnershipChange }) {
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef();

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // restore focus back to button when modal is closed.
    buttonRef.current.focus();
    setOpen(false);
  };

  const handleOwnershipChange = (event, newOwnership) => {
    if (onOwnershipChange) {
      onOwnershipChange(newOwnership);
    }
  };

  return (
    <div style={{position: 'relative'}}>
      <OwnerDropdownButton
        fullWidth
        ref={buttonRef}
        invisible={open}
        owner={ownership.user}
        onClick={handleButtonClick}
      />

      {open && (
        <StyledAutocompleteOwner
          openOnFocus
          options={options}
          value={ownership}
          onChange={handleOwnershipChange}
          onClose={handleClose}
        />
      )}
    </div>
  );
}

const MembershipShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
});

AutocompleteOwner.propTypes = {
  ownership: MembershipShape,
  options: PropTypes.arrayOf(MembershipShape).isRequired,
  onOwnershipChange: PropTypes.func
};

export default AutocompleteOwner;
