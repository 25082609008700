import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const PRIORITY_OPTIONS = [
  'NONE',
  'HIGH',
  'MEDIUM',
  'LOW'
];

const useStyles = makeStyles((theme) => ({
  chip: {
    transition: 'none',
    backgroundColor: '#E4E6EB',
    cursor: 'inherit'
  },
  none: {
    display: 'inline-block',
    padding: theme.spacing(0, 1) // Same as small chip
  },
  low: {
    backgroundColor: '#68cc45',
    color: theme.palette.getContrastText('#68cc45')
  },
  medium: {
    backgroundColor: '#febe30',
    color: theme.palette.getContrastText('#febe30')
  },
  high: {
    backgroundColor: '#ff0053',
    color: theme.palette.getContrastText('#ff0053')
  }
}));

function cap(someString) {
  return someString.charAt(0).toUpperCase() + someString.slice(1);
}

function PriorityChip({ priority }) {
  const classes = useStyles();
  const lowercasePriority = priority.toLowerCase();

  return lowercasePriority === 'none' ? (
    <Typography
      component='span'
      variant='body1'
      color='textSecondary'
      className={classes.none}
    >
      —
    </Typography>
  ) : (
    <Chip
      size='small'
      label={cap(lowercasePriority)}
      className={clsx(classes.chip, classes[lowercasePriority])}
    />
  );
}

PriorityChip.propTypes = {
  priority: PropTypes.oneOf(PRIORITY_OPTIONS).isRequired
};

export default PriorityChip;
export { PRIORITY_OPTIONS };
