import React from 'react';
import CellContentWrapper from './CellContentWrapper';
import StyledDatePicker from './StyledDatePicker';

export default function DatePicker(props) {
  return (
    <CellContentWrapper>
      <StyledDatePicker
        {...props}
      />
    </CellContentWrapper>
  );
}
