import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 0,
    flexGrow: 1
  },
  avatar: {
    marginRight: theme.spacing(1),
    flex: '0 0 auto',
    width: 24,
    height: 24,
    fontSize: '0.75rem',
  },
  info: {
    width: 0,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > :first-child': {
      marginBottom: -3
    }
  }
}));

function AutocompleteUserOption({ user }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UserAvatar user={user} size='small' className={classes.avatar} />
      <div className={classes.info}>
        <Typography variant='subtitle2' color='textPrimary' noWrap>
          {user.username}
        </Typography>
        <Typography variant='caption' color='textSecondary' noWrap>
          {user.email}
        </Typography>
      </div>
    </div>
  );
}

AutocompleteUserOption.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
};

export default AutocompleteUserOption;
