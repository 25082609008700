import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listHeader: {
    //backgroundColor: 'yellow',
    padding: theme.spacing(0, 1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

function List({ title, action, children }) {
  const classes = useStyles();
  return (
    <Paper square elevation={0}>
      <div className={classes.listHeader}>
        <Typography variant='h6' component='h1' gutterBottom>
          {title}
        </Typography>
        {action}
      </div>

      {children}
    </Paper>
  );
}

List.propTypes = {
  title: PropTypes.node.isRequired,
  action: PropTypes.node
};

export default List;
