import { useSnackbar } from 'notistack';

// A workaround to pass down the `variant` from `enqueueSnackbar` to
// custom snackbar item.
// See https://github.com/iamhosseindhv/notistack/issues/218
export default function useCustomSnackbar() {
  const { enqueueSnackbar, ...rest} = useSnackbar();

  const customEnqueueSnackbar = (message, options) => {
    const { variant, ...others } = options;
    const hackedMessage = JSON.stringify({message, variant});
    return enqueueSnackbar(hackedMessage, others);
  };

  // Custom enqueue to show graphQL error.
  const enqueueGraphQLErrorSnackbar = (error, options) => {
    if (!error || !error.message) return;

    const isNetworkError = error.networkError &&
                           error.networkError.message &&
                           error.networkError.statusCode;

    const hasGraphQLErrors =
      error.graphQLErrors && error.graphQLErrors.length;

    let errorMessage;

    if (isNetworkError) {
      if (error.networkError.statusCode === 404) {
        errorMessage = '404: Not Found'
      } else {
        errorMessage = 'Network Error!'
        // TODO: Show network error status code and details
        // {error.networkError.statusCode}: {error.networkError.message}
      }
    } else if (hasGraphQLErrors) {
      // TODO: Only display first error in the array?
      errorMessage = error.graphQLErrors[0].message;
    } else {
      errorMessage = error.message;
    }

    if (errorMessage) {
      return customEnqueueSnackbar(errorMessage, {
        variant: 'error',
        autoHideDuration: 3000,
        ...options
      });
    }
  };

  // Custom enqueue to show success message
  const enqueueSuccessSnackbar = (message, options) => {
    if (!message) return;
    return customEnqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: 'success',
      ...options
    });
  };

  return {
    ...rest,
    enqueueSnackbar: customEnqueueSnackbar,
    enqueueGraphQLErrorSnackbar,
    enqueueSuccessSnackbar
  };
}
