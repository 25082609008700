import React from 'react';
import PropTypes from 'prop-types';
import LazyUserSearch from '../../autocomplete/LazyUserSearch';
import useSearchMembers from '../hooks/useLazySearchNewMembersForTeamQuery';

function AddMembersInput({ teamId, ...rest }) {
  const [criteria, setCriteria] = React.useState({matching: ''});
  const [searchResults, setSearchResults] = React.useState([]);

  const handleMatchingChange = (newMatching) => {
    setCriteria({matching: newMatching});
  };

  const [searchNewMembersForTeam, { loading }] = useSearchMembers({
    teamId: teamId,
    onCompleted(data) {
      setSearchResults(data.newMembersForTeam);
    }
  });

  React.useEffect(() => {
    if (criteria.matching) {
      searchNewMembersForTeam({
        variables: {filter: criteria}
      });
    }
  }, [criteria, searchNewMembersForTeam]);  

  return (
    <LazyUserSearch
      InputProps={{
        autoFocus: true,
        placeholder: 'Search by name or email',
        helperText: 'Search for active, existing workspace members.'
      }}
      onMatchingChange={handleMatchingChange}
      loading={loading}
      searchResults={searchResults}
      {...rest}
    />
  );
}

AddMembersInput.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default AddMembersInput;
