import React from 'react';
import SuccessIcon from '../../../icons/20x20/CheckCircleFill';
import UploadStatusIconButton from './UploadStatusIconButton';

export default function UploadSucceeded() {
  return (
    <UploadStatusIconButton
      disabled
      icon={<SuccessIcon color='positive' />}
    />
  );
}
