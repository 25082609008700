import React from 'react';
import PropTypes from 'prop-types';
import Section from '../../ui/todo/details/ActivitySection';
import ActivityLabel from '../../ui/todo/details/ActivityLabel';
import CommentsQuery from './comment/CommentsQuery';
import CommentList from './comment/CommentList';

function ActivitySection({ todoId, projectId }) {
  return (
    <Section>
      <ActivityLabel />
      <CommentsQuery
        todoId={todoId}
        fetchPolicy='network-only'
      >
        {(comments, otherQueryResults) => (
          <CommentList
            projectId={projectId}
            todoId={todoId}
            comments={comments}
            {...otherQueryResults}
          />
        )}
      </CommentsQuery>
    </Section>
  );
}


ActivitySection.propTypes = {
  todoId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ActivitySection;
