import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from './app-bar/AppBar';
import Home from './home/Home';
import Members from './member/Members';
import Teams from './team/Teams';
import Team from './team/Team';
import Project from './project/Project';
import SectionNotFound from './oops/SectionNotFound';
import TextEditor from './ui/text-editor/TextEditor';
import NotificationList from './notification/OldNotificationList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  toolbar: theme.mixins.toolbar
}));

export default function Workspace() {
  const classes = useStyles();
  const { path } = useRouteMatch();
  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.toolbar}></div>
      <main>
        <Switch>
          <Route path={path} exact>
            <Home />
          </Route>

          <Route path={`${path}/teams`} exact>
            <Teams />
          </Route>

          <Route path={`${path}/teams/:teamId`}>
            <Team />
          </Route>

          <Route path={`${path}/projects/:projectId`}>
            <Project />
          </Route>

          <Route path={`${path}/people`} exact>
            <Members />
          </Route>

          <Route path={`${path}/text-editor`} exact>
            <TextEditor />
          </Route>

          <Route path={`${path}/notifications`} exact>
            <NotificationList />
          </Route>

          <Route>
            <SectionNotFound />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
