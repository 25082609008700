import React from 'react';
import ToolbarIconButton from './ToolbarIconButton';
import CloseIcon from '../../../../icons/18x18/Close';

export default function ToolbarCloseButton(props) {
  return (
    <ToolbarIconButton
      icon={<CloseIcon />}
      {...props}
    />
  );
}
