import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import NotFound from '../oops/SectionNotFound';
import MessageDetailsContainer from '../ui/message/MessageDetailsContainer';
import Loading from '../ui/message/MessageDetailsLoading';
import ProjectMessageQuery from './ProjectMessageQuery';
import MessageDetailsHeader from '../ui/message/MessageDetailsHeader';
import MessageDetailsContent from '../ui/message/MessageDetailsContent';
import { formatCreatedTime } from './helpers';
import MessageDetailsMenu from './ProjectMessageDetailsMenu';
import MessageComments from './comments/MessageComments';
import CommentComposer from './comments/CommentComposer';
import FollowersBar from './followers/FollowersBar';

function ProjectMessageDetails({ rootUrl }) {
  const { projectId, messageId } = useParams();
  return (
    <ProjectMessageQuery
      fetchPolicy='network-only'
      projectId={projectId}
      messageId={messageId}
      onLoading={() => <Loading />}
    >
      {(message) => {
        if (!message) { return <NotFound />; }
        return (
          <MessageDetailsContainer>
            <MessageDetailsMenu
              messageBoardUrl={rootUrl}
              id={message.id}
              subject={message.subject}
            />
            <MessageDetailsHeader
              author={message.author}
              subject={message.subject}
              timestamp={formatCreatedTime(message.insertedAt)}
            />
            <MessageDetailsContent jsonText={message.jsonText} />
            <MessageComments
              projectId={projectId}
              messageId={messageId}
            />
            <CommentComposer
              projectId={projectId}
              messageId={messageId}
            />
            <FollowersBar
              projectId={projectId}
              messageId={messageId}
            />
          </MessageDetailsContainer>
        );
      }}
    </ProjectMessageQuery>
  );
}

ProjectMessageDetails.propTypes = {
  // The MessageBoard url
  rootUrl: PropTypes.string.isRequired
};

export default ProjectMessageDetails;
