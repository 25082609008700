import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../file/list/UploadListItem';
import ActionDelete from '../file/list/ActionDelete';
import { StatusCode, STATUS_CODES } from '../file/list/UploadStatus';
import UploadStatus from './UploadStatus';

function UploadListItem({
  item,
  onUploadStatusChange,
  onUploadDelete,
  projectId
}) {
  const { id, file, statusCode } = item;

  const handleDelete = React.useCallback(() => {
    if (statusCode === StatusCode.SUCCEEDED) {
      console.log('TODO: Handle remove upload from AWS S3!');
    }
    onUploadDelete(id);
  }, [id, statusCode, onUploadDelete]);

  return (
    <ListItem
      file={file}
      actions={[
        <UploadStatus
          file={file}
          uploadId={id}
          statusCode={statusCode}
          onStatusChange={onUploadStatusChange}
          projectId={projectId}
        />,
        <ActionDelete onClick={handleDelete} />
      ]}
    />
  );
}

UploadListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file: PropTypes.instanceOf(File).isRequired,
    statusCode: PropTypes.oneOf(STATUS_CODES).isRequired
  }).isRequired,

  onUploadStatusChange: PropTypes.func.isRequired,
  onUploadDelete: PropTypes.func.isRequired,

  projectId: PropTypes.string.isRequired
}

export default UploadListItem;
