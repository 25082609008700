import compareTime from '../../../new-ui/datetime-picker/compareTime';
import { timelineIntervalSize } from './constants';

// Given two overlapping time intervals [x1, y1] and [x2, y2] such that
// x1 >= x2. Let diff = x1 - x2. We have:
//
// - width([x2, y2]) = 0.95 * width[x1, y1] if diff >= delta:
//
//   x1 +-------------------------------------------------------+
//      + Foo event                                             +
//      + (x1 - y1)                                             +
//   x2 +    +--------------------------------------------------+
//      +    + Bar event                                        +
//      +    + (x2 - y2)                                        +
//   y1 +----+                                                  +
//      +    +                                                  +
//   y2 +    +--------------------------------------------------+
//
// - Otherwise, width([x2, y2]) = 0.5 * width[x1, y1]:
//
//   x1 +--------------------------+-----------------------------+
//      + Foo event                                              +
//   x2 + (x1 - y1)                +-----------------------------+
//      +                          + Bar event                   +
//      +                          + (x2 - y2)                   +
//      +                          +                             +
//   y1 +--------------------------+                             +
//      +                          +                             +
//   y2 +                          +-----------------------------+
//
// You could think of `delta` as the (CSS) height of an event's header.
// When `diff` >= `delta`, the entire Foo's header is visible no matter
// how wide Bar is. So it's safe to shrink Bar by just a small amount
// (0.05) (or even not to shrink it at all). But, when `diff` < `delta`,
// the Foo's header is either partially or completely hidden behind Bar,
// therefore we have to shrink Bar by a larger amount so that most of
// the Foo's header will be visible.
const delta = timelineIntervalSize.MINUTES * 4;

// Sort a list of time intervals ascending by start time (starts first
// comes first), and descending by end time (ends after comes first).
function _compareFn(i1, i2) {
  const startDiff = compareTime(i1.start, i2.start);

  if (startDiff === 0) {
    return compareTime(i2.end, i1.end);
  }

  return startDiff;
}

// Returns `true` if the two given time intervals are identical - having
// the exact same start and end.
function _isIdentical(i1, i2) {
  return compareTime(i1.start, i2.start) === 0 &&
         compareTime(i1.end, i2.end) === 0;
}

// Returns `true` if the two given (continuous) time intervals overlap.
// Returns `false`, otherwise.
//
// Two (continuous) time intervals are considered to NOT overlap if either
// they do not intersect at all or intersect at exactly one point. Otherwise,
// they are considered to overlap.
function _isOverlap(i1, i2) {
  return compareTime(i2.start, i1.end) < 0 &&
         compareTime(i1.start, i2.end) < 0;
}

// Convert the given time object { hours, minutes } to minutes
function _toMinutes({ hours, minutes }) {
  return hours * 60 + minutes;
}

// Returns the difference between the start of the two given time
// intervals.
function _startDiff(i1, i2) {
  return _toMinutes(i1.start) - _toMinutes(i2.start);
}

// Takes as input a list of time intervals, sorts them ascending by start
// time (starts first comes first) and descending by end time (ends later
// comes first), and adds two additional properties to each interval
// object: the zIndex and width. (TODO: explain what these are).
export default function sortTimeIntervals(intervals) {
  const sortedIntervals = intervals.sort(_compareFn);

  let index = 0;
  let results = [];

  function _findClosestOverlappingInterval(currIndex) {
    const currInterval = sortedIntervals[currIndex];

    for (let i = currIndex - 1; i >= 0; --i) {
      const interval = results[i]/* must be results array */;
      if (_isOverlap(interval, currInterval)) {
        return interval;
      }
    }

    return null;
  }

  function _getIdenticalSequenceLength(currIndex) {
    const currInterval = sortedIntervals[currIndex];
    let length = 1;

    for (let i = currIndex + 1; i < sortedIntervals.length; ++i) {
      if (_isIdentical(currInterval, sortedIntervals[i])) {
        length++;
      } else {
        break;
      }
    }
    return length;
  }

  while (index < sortedIntervals.length) {
    const found = _findClosestOverlappingInterval(index);
    const sequenceLength = _getIdenticalSequenceLength(index);

    let refZIndex;
    let refWidth;

    if (!found) {
      refZIndex = 0;
      refWidth = 1.0;
    } else {
      refZIndex = found.zIndex;
      refWidth = found.width;

      if (_startDiff(sortedIntervals[index], found) >= delta) {
        refWidth *= 0.95;
      } else {
        refWidth *= 0.5;
      }
    }

    for (let i = 0; i < sequenceLength; ++i) {
      let item = sortedIntervals[index + i];
      let zIndex = refZIndex + 1 + i;
      let width = refWidth * (sequenceLength - i) / sequenceLength;
      results.push({...item, zIndex, width });
    }

    index = index + sequenceLength;
  }

  return results;
}
