import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subscribersBar: {
    width: '100%',
    margin: '0px !important',
    padding: theme.spacing(2, 0, 0, 5.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default function SubscribersBar(props) {
  const classes = useStyles();
  return <div className={classes.subscribersBar} {...props} />;
}
