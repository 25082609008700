import React from 'react';
import Button from '../button/Button';

export default function MarkAsReadButton(props) {
  return (
    <Button
      size='small'
      variant='text'
      color='primary'
      {...props}
    >
      Mark all read
    </Button>
  );
}
