import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '../../ui/icons/ChevronLeft';
import ChevronRightIcon from '../../ui/icons/ChevronRight';
import IconButton from '../../ui/button/IconButton';
import Button from '../../ui/button/Button';

const useStyles = makeStyles((theme) => ({
  viewNavigator: {
    display: 'flex',
    alignItems: 'center'
  },
  navigatorButtonGroup: {
    marginRight: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  iconButton: {
    borderRadius: 4,
    minWidth: theme.spacing(4),
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  textButton: {
    borderRadius: 4,
    height: theme.spacing(4),
    fontSize: '0.875rem',
    lineHeight: '16px',
    padding: 0
  }
}));

function ViewNavigator({ title, onPrev, onCurr, onNext }) {
  const classes = useStyles();

  return (
    <div className={classes.viewNavigator}>
      <div className={classes.navigatorButtonGroup}>
        <IconButton
          square
          icon={ <ChevronLeftIcon />}
          className={classes.iconButton}
          onClick={onPrev}
        />
        <Button
          color='secondary'
          className={classes.textButton}
          onClick={onCurr}
        >
          Today
        </Button>
        <IconButton
          square
          icon={<ChevronRightIcon />}
          className={classes.iconButton}
          onClick={onNext}
        />
      </div>

      <Typography variant='h6' component='h1'>
        {title}
      </Typography>
    </div>
  );
}

ViewNavigator.propTypes = {
  title: PropTypes.string,
  onPrev: PropTypes.func,
  onCurr: PropTypes.func,
  onNext: PropTypes.func
};

export default ViewNavigator;
