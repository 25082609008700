import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    pointerEvents: 'none',
    opacity: 0.0,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    transition: 'opacity 0.1s cubic-bezier(0, 0, 1, 1)',
    '-webkit-tap-highlight-color': 'transparent',
    cursor: 'pointer'
  }
});

export default function Overlay({ className }) {
  const classes = useStyles();
  return <span className={clsx(classes.root, className)} />;
}
