import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from './Overlay';
import {
  FILTER_PRIMARY,
  FILTER_SECONDARY,
  FILTER_ACCENT,
  FILTER_NEGATIVE,
  FILTER_DISABLED
}
from '../icons/Base';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    borderRadius: '50%',
    height: 40,
    width: 40,

    '&:hover $overlay': {
      opacity: 1.0
    },

    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    }
  },

  square: {
    borderRadius: theme.shape.borderRadius,
    width: 'auto',
    minWidth: 40,

    '&$small': {
      minWidth: 36,
      width: 'auto'
    },
    '&$large': {
      minWidth: 48,
      width: 'auto'
    }
  },

  small: {
    height: 36,
    width: 36
  },

  large: {
    height: 48,
    width: 48
  },

  primary: {
    '& $icon': {
      filter: FILTER_PRIMARY
    }
  },

  secondary: {
    '& $icon': {
      filter: FILTER_SECONDARY
    },
    '&:hover $icon': {
      filter: FILTER_PRIMARY
    }
  },

  accent: {
    '& $icon': {
      filter: FILTER_ACCENT
    }
  },

  negative: {
    '& $icon': {
      filter: FILTER_NEGATIVE
    }
  },

  disabled: {
    '& $icon': {
      filter: FILTER_DISABLED
    }
  },

  background: {
    backgroundColor: theme.palette.button.secondary.main,
    '&$accent': {
      backgroundColor: theme.palette.button.primaryDeemphasized.main
    }
  },

  icon: {},
  overlay: {}
}));

const IconButton = React.forwardRef(function IconButton(props, ref) {
  const {
    icon,
    size,
    square,
    color,
    background,
    className,
    ...otherProps
  } = props;

  const classes = useStyles();

  return (
    <ButtonBase
      ref={ref}
      className={clsx(classes.root, classes[size], classes[color], {
        [classes.square]: square,
        [classes.background]: background
      }, className)}
      {...otherProps}
    >
      {React.cloneElement(icon, {
        className: clsx(icon.props.className, classes.icon)
      })}
      <Overlay className={classes.overlay} />
    </ButtonBase>
  );
});

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf([
    'primary', 'secondary', 'accent', 'negative', 'disabled'
  ]),
  square: PropTypes.bool,
  background: PropTypes.bool,
};

IconButton.defaultProps = {
  size: 'medium',
  color: 'primary',
  square: false,
  background: true,
};

export default IconButton;
