import React from 'react';
import PropTypes from 'prop-types';
import PageActions from '../ui/page/Actions';
import AddNewProject from './team-actions/AddNewProject';
import AddNewMember from './team-actions/AddNewMember';
import OtherActions from './team-actions/OtherActions';

function TeamActions({ teamId }) {
  return (
    <PageActions>
      <AddNewProject teamId={teamId} />
      <AddNewMember teamId={teamId} />
      <OtherActions teamId={teamId} />
    </PageActions>
  );
}

TeamActions.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamActions);
