import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: '1.125rem',
    fontWeight: 600,
    '&::placeholder': {
      fontSize: '1.125rem'
    }
  }
}));

export default function ProjectNameInput(props) {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      type='text'
      autoComplete='off'
      placeholder='Project name'
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.input
        }
      }}
      {...props}
    />
  );
}
