import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const DEACTIVATE_MEMBERSHIP_MUTATION = gql`
  mutation DeactivateMembership($membershipId: ID!) {
    deactivateMembership(membershipId: $membershipId) {
      id
    }
  }
`;

export default function useDeactivateMembershipMutation(options) {
  const { membershipId, ...rest } = options;
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(DEACTIVATE_MEMBERSHIP_MUTATION, {
    variables: { membershipId },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...rest
  });
}
