import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CHIP_DEFAULT_COLOR } from './constants';
import { TimelineItemShape } from './types';
import getTimeIntervalTopHeight from './getTimeIntervalTopHeight';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
    overflow: 'hidden',
    userSelect: 'none',
    pointerEvents: 'auto',

    // Fake border
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 1.0)',

    right: 6 /* TODO: WHY? */,
    width: 'calc(100% - 6px)',
    top: 0,
    height: 0
  },

  rootColor: ({ color }) => {
    try {
      return {
        backgroundColor: color,
        color: theme.palette.getContrastText(color)
      };
    } catch (e) {
      // If we failed to get the text color from the given color, we return
      // the default style instead.
      return {
        backgroundColor: CHIP_DEFAULT_COLOR,
        color: theme.palette.getContrastText(CHIP_DEFAULT_COLOR)
      };
    }
  },

  rootOneRow: {
    display: 'flex',
    alignItems: 'center',
    '& $title': {
      marginRight: theme.spacing(1),
      fontSize: '0.625rem'
    },
    '& $subtitle': {
      flex: '0 0 auto',
      fontSize: '0.625rem'
    }
  },

  rootTwoOrThreeRows: {
    display: 'flex',
    alignItems: 'center',
    '& $title': {
      marginRight: theme.spacing(1)
    },
    '& $subtitle': {
      flex: '0 0 auto'
    }
  },

  rootMoreThanThreeRows: {
    padding: theme.spacing(0.5, 1)
  },

  rootFocus: {
    boxShadow: theme.shadows[8]
  },

  rootDragging: {
    opacity: 0.6
  },

  title: {
    fontWeight: 600,
    fontSize: '0.75rem',
    pointerEvents: 'none'
  },

  subtitle: {
    fontSize: '0.75rem',
    pointerEvents: 'none'
  }
}));

function TimeSpecificScheduleChip({
  title,
  start,
  end,
  color,
  focus,
  focusZIndex,
  zIndex,
  width,
  isDragging,
  ...rest
}) {
  const classes = useStyles({ color });
  const { top, height, rowSpan } = getTimeIntervalTopHeight({start, end});

  const isOneRow = (rowSpan === 1);
  const isTwoOrThreeRows = (rowSpan === 2) || (rowSpan === 3);
  const isMoreThanThreeRows = (rowSpan > 3);

  return (
    <div
      className={clsx(classes.root, classes.rootColor, {
        [classes.rootOneRow]: isOneRow,
        [classes.rootTwoOrThreeRows]: isTwoOrThreeRows,
        [classes.rootMoreThanThreeRows]: isMoreThanThreeRows,
        [classes.rootFocus]: focus,
        [classes.rootDragging]: isDragging
      })}
      style={{
        top,
        height,
        zIndex: focus ? focusZIndex : zIndex,
        width: focus ? 'calc(100% - 6px)' : `calc(${width}% - 6px)`
      }}
      {...rest}
    >
      <Typography
        noWrap
        component='div'
        variant='body2'
        className={classes.title}
      >
        {title ? title : '(No title)'}
      </Typography>
      <Typography
        noWrap
        variant='body2'
        component='div'
        className={classes.subtitle}
      >
        {start.label} – {end.label}
      </Typography>
    </div>
  );
}

TimeSpecificScheduleChip.propTypes = {
  title: PropTypes.string,
  start: TimelineItemShape.isRequired,
  end: TimelineItemShape.isRequired,
  color: PropTypes.string,

  // If set to `true`, the chip will have box-shadow.
  focus: PropTypes.bool,

  // This is the z-index of the chip when focus is set to `true`.
  focusZIndex: PropTypes.number,

  // Normal z-index of the chip.
  zIndex: PropTypes.number,

  // The width (in percentage) of the chip
  width: PropTypes.number,

  // Set to `true` if this chip is being dragged around
  isDragging: PropTypes.bool
};

TimeSpecificScheduleChip.defaultProps = {
  focus: false,
  focusZIndex: 0,
  zIndex: 0,
  width: 100,
  isDragging: false
};

export default TimeSpecificScheduleChip;
