import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from '../../ui/button/Overlay';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover:not(.Mui-selected) $overlay': {
      opacity: 1.0
    },
    '&:active:not(.Mui-selected) $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },
    '&.Mui-focusVisible:not(.Mui-selected) $overlay': {
      opacity: 1.0
    }
  },
  startIcon: {
    color: theme.palette.text.secondary,
    fontSize: '1.25rem'
  },
  overlay: {}
}));

const ChipMenuItem = React.forwardRef(function ChipMenuItem(props, ref) {
  const classes = useStyles();
  const { startIcon, primary, ...rest } = props;
  return (
    <MenuItem ref={ref} className={classes.menuItem} {...rest}>
      {startIcon && (
        <ListItemIcon className={classes.startIcon}>
          {React.cloneElement(startIcon, { fontSize: 'inherit' })}
        </ListItemIcon>
      )}
      <ListItemText
        primary={primary}
        primaryTypographyProps={{variant: 'subtitle2'}}
      />
      <Overlay className={classes.overlay} />
    </MenuItem>
  );
});

ChipMenuItem.propTypes = {
  startIcon: PropTypes.node,
  primary: PropTypes.string.isRequired
};

export default ChipMenuItem;
