import React from 'react';
import PropTypes from 'prop-types';
import MenuItemDivider from '../../ui/menu/MenuItemDivider';
import StyledMenu from './StyledMenu';
import AddToFavoritesItem from './AddToFavoritesItem';
import RemoveFromFavoritesItem from './RemoveFromFavoritesItem';
import EditProjectItem from './EditProjectItem';
import ChangeColorItem from './ChangeColorItem';
import ReassignOwnerItem from './ReassignOwnerItem';
import AddMembersItem from './AddMembersItem';
import ArchiveProjectItem from './ArchiveProjectItem';
import DeleteProjectItem from './DeleteProjectItem';

function UnarchivedMenu({ pinned, projectId, ...rest }) {
  return (
    <StyledMenu {...rest}>
      {pinned ? (
        <RemoveFromFavoritesItem projectId={projectId} />
      ) : (
        <AddToFavoritesItem projectId={projectId} />
      )}
      <AddMembersItem projectId={projectId} />
      <MenuItemDivider />
      <EditProjectItem projectId={projectId} />
      <ChangeColorItem projectId={projectId} />
      <ReassignOwnerItem projectId={projectId} />
      <MenuItemDivider />
      <ArchiveProjectItem projectId={projectId} />
      <DeleteProjectItem projectId={projectId} />
    </StyledMenu>
  );
}

UnarchivedMenu.propTypes = {
  pinned: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired
};

export default UnarchivedMenu;
