import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import { TODO_ITEM_DETAILS } from '../fragments';
import { matchesTodoCriteria } from '../helpers';

const COMPLETE_TODO_ITEM_MUTATION = gql`
  mutation CompleteTodoItem($todoItemId: ID!) {
    completeTodoItem(todoItemId: $todoItemId) {
      ...TodoItemDetails
    }
  }
  ${TODO_ITEM_DETAILS}
`;

export default function useCompleteTodoItemMutation(options) {
  const { todoListId, todoCriteria, ...otherOptions } = options;
  const {
    enqueueSuccessSnackbar,
    enqueueGraphQLErrorSnackbar
  } = useCustomSnackbar();

  const fragmentObj = {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };

  return useMutation(COMPLETE_TODO_ITEM_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    update: (client, { data: { completeTodoItem: completedTodo }}) => {
      if (!matchesTodoCriteria(completedTodo, todoCriteria)) {
        const todoList = client.readFragment(fragmentObj);
        const todoItems = todoList.todoItems;

        client.writeFragment({
          ...fragmentObj,
          data: {
            ...todoList,
            todoItems: todoItems.filter(({ id }) => id !== completedTodo.id)
          }
        });
      }
    },
    onCompleted: (data) => {
      const completedTodo = data.completeTodoItem;
      const msg = `Marked ${completedTodo.name} complete`;
      enqueueSuccessSnackbar(msg);
    },
    ...otherOptions
  });
}
