import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//
// TODO:
//
// This is just a hacky way to make `SectionNameInput` auto-resizable,
// and more importantly to make `SectionNameButton` 'in sync'
// with `SectionNameInput`.
//

//
// Shared attibutes between `SectionNameInput` (me) and
// `SectionNameButton`.
//

const TYPOGRAPHY_STYLE = {
  fontSize: '1.0625rem',
  lineHeight: 1.45,
  fontWeight: 600,
};

const TYPOGRAPHY_PROPS = {
  component: 'span',
  variant: 'h6'
};

// top, right, bottom, left - in that order.
const INPUT_PADDINGS = [7, 11, 7, 8];

const INPUT_BORDER_WIDTH = 1;

//
// INPUT STYLES.
//

const INPUT_MIN_WIDTH = 192;

const INPUT_PADDING_STYLE = INPUT_PADDINGS.map((padding) => (
  `${padding}px`
)).join(' ');

const INPUT_BORDER_STYLE = `${INPUT_BORDER_WIDTH}px solid transparent`;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: INPUT_MIN_WIDTH
  },
  inputRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: INPUT_BORDER_STYLE,
    borderRadius: 4,
    ...TYPOGRAPHY_STYLE,

    '&$focused': {
      outline: 'none',
      borderColor: '#AAC9FF',
      boxShadow: `0 0 6px ${fade(theme.palette.primary.main, 0.5)}`
    }
  },
  inputInput: {
    padding: INPUT_PADDING_STYLE
  },
  focused: {},
  typography: {
    ...TYPOGRAPHY_STYLE,
    padding: INPUT_PADDING_STYLE,
    border: INPUT_BORDER_STYLE,
    cursor: 'default',
    pointerEvents: 'none',
    visibility: 'hidden',
    whiteSpace: 'pre'
  }
}));

function SectionNameInput(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography
        aria-hidden={true}
        className={classes.typography}
        {...TYPOGRAPHY_PROPS}
      >
        {!props.value ? 'x' : props.value}
      </Typography>

      <InputBase
        fullWidth
        type='text'
        placeholder='Section Name'
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
          focused: classes.focused
        }}
        {...props}
      />
    </div>
  );
}

export default SectionNameInput;
export {
  TYPOGRAPHY_PROPS,
  TYPOGRAPHY_STYLE,
  INPUT_BORDER_WIDTH,
  INPUT_PADDINGS
};
