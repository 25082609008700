import React from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MarkAllReadButton from './MarkAllReadButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 700
  }
}));

function UnreadListSubheader({ onMarkAllRead, ...rest }) {
  const classes = useStyles();
  return (
    <ListSubheader component='div' className={classes.root} {...rest}>
      <Typography variant='h6' className={classes.title}>
        New for you
      </Typography>
      <MarkAllReadButton onClick={onMarkAllRead} />
    </ListSubheader>
  );
}

UnreadListSubheader.propTypes = {
  onMarkAllRead: PropTypes.func
};

export default UnreadListSubheader;
