import { startOfDay, endOfDay, startOfMonth } from 'date-fns';
import { getDaysGridForMonth } from './helpers';
import { monthGrid } from './constants';

export default function getMonthGridForDate(date) {
  const beginOfMonth = startOfMonth(date);

  const grid = getDaysGridForMonth(beginOfMonth, {
    startsWithSunday: monthGrid.startsWithSunday,
    numRows: monthGrid.numRows
  });

  const start = grid[0][0];
  const end = grid[grid.length - 1][6];

  return {
    grid,
    lowerBound: startOfDay(start),
    upperBound: endOfDay(end)
  };
}
