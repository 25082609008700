import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../../ui/link/RouterLink';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    padding: theme.spacing(1, 1.5)
  },
  avatar: {
    margin: theme.spacing(0.5, 1.5, 0.5, 0)
  },
  content: {
    flex: '1 1 auto',
    alignSelf: 'center'
  },
  title: {
    fontSize: '0.9375rem',
    lineHeight: 1.333334
  },
  subtitle: {
    fontSize: '0.8125rem'
  }
}));

function EventCardLink({ to, avatar, title, subtitle }) {
  const classes = useStyles();

  return (
    <Paper
      underline='none'
      className={classes.paper}
      component={Link}
      to={to}
    >
      {avatar && (
        <div className={classes.avatar}>
          {avatar}
        </div>
      )}
      <div className={classes.content}>
        <Typography
          component='div'
          variant='subtitle2'
          color='textPrimary'
          className={classes.title}
        >
          {title}
        </Typography>

        <Typography
          component='div'
          variant='caption'
          color='textSecondary'
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      </div>
    </Paper>
  );
}

EventCardLink.propTypes = {
  to: PropTypes.string.isRequired,
  avatar: PropTypes.node,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired
};

export default EventCardLink;
