import React from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

export default function DialogContent(props) {
  // Fight the <body> scroll on mobile divices. See [1] and [2].
  // [1] - https://github.com/mui-org/material-ui/issues/5750
  // [2] - https://medium.com/react-camp/how-to-fight-the-body-scroll-2b00267b37ac

  const dialogContentRef = React.createRef(null);

  React.useEffect(() => {
    // Disable body scroll on mobile divices when this shit mounted.
    const current = dialogContentRef.current;
    disableBodyScroll(current);

    return () => {
      // Re-enable body scroll when this shit unmounted.
      enableBodyScroll(current);
    };
  }, [dialogContentRef]);

  return <MuiDialogContent ref={dialogContentRef} {...props} />;
}
