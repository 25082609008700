import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '../../ui/input/RadioButton';
import { ROLE_LIST, roleCmp } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontWeight: 500
  }
}));

function ChangeMembershipRoleInput({ currentUserRole,  value, onChange }) {
  const classes = useStyles();
  return (
    <FormControl component='fieldset' className={classes.root}>
      <RadioGroup value={value} onChange={onChange}>
        {ROLE_LIST.map((role) => (
          <FormControlLabel
            key={role}
            control={ <Radio /> }
            value={role}
            label={role}
            disabled={roleCmp(role, currentUserRole) >= 0 }
            classes={{ label: classes.label }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

ChangeMembershipRoleInput.propTypes = {
  currentUserRole: PropTypes.oneOf(ROLE_LIST).isRequired,
  value: PropTypes.oneOf(ROLE_LIST).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ChangeMembershipRoleInput;
