import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const SAVE_UPLOADS_MUTATION = gql`
  mutation SaveUploads(
    $projectId: ID!,
    $directoryId: ID,
    $uploads: [UploadInput]
  ) {
    saveUploads(
      projectId: $projectId,
      directoryId: $directoryId,
      uploads: $uploads
    )
  }
`;

export default function useSaveUploadsMutation(options) {
  return useMutation(SAVE_UPLOADS_MUTATION, options);
}
