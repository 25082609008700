import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';

const MARK_TODO_COMPLETED_MUTATION = gql`
  mutation MarkTodoCompleted($todoId: ID!) {
    completeTodoItem(todoItemId: $todoId) {
      id
      completed
    }
  }
`;

export default function useMarkTodoCompleted() {
  const [mutate, mutationResults] = useMutation(
    MARK_TODO_COMPLETED_MUTATION,
    {
      loadingMessage: 'Completing task...',
      successMessage: 'Task completed'
    }
  );

  const mutateFn = useCallback((todoId) => {
    mutate({
      variables: { todoId },
      optimisticResponse: {
        completeTodoItem: {
          __typename: 'TodoItem',
          id: todoId,
          completed: true
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
