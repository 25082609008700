import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ProjectGridItem from './ProjectGridItem';
import Empty from './Empty';

function ProjectGrid({ projects }) {
  const isEmpty = projects.length === 0;

  return isEmpty ? <Empty /> : (
    <Grid container spacing={2}>
      {projects.map((project) => (
        <ProjectGridItem key={project.id} project={project} />
      ))}
    </Grid>
  );
}

ProjectGrid.propTypes = {
  projects: PropTypes.array.isRequired
};

export default ProjectGrid;
