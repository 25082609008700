import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

const backgroundColor = '#F0F2F5';

const useStyles = makeStyles((theme) => ({
  // Base line
  inputRoot: {
    borderRadius: 4,
    backgroundColor: 'transparent',
    '&$underline': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    '&:not($underline)::before': {
      display: 'none'
    },
    '&$underline::before': {
      display: 'block',
      borderColor: theme.palette.divider
    },
    '&$inputError': {
      backgroundColor: 'rgb(253, 233, 236)',
      color: 'rgb(96, 16, 29)',
      '&::after': {
        borderBottomColor: '#FA383E'
      }
    }
  },
  inputInput: {
    // Only apply the height and padding to the (actual) input element
    // if it is NOT a textarea. (For textarea, see below).
    '&:not($inputMultiline)': {
      height: theme.spacing(3),
      padding: theme.spacing(0.75, 1)
    },

    // Some right padding if has clear icon
    '&$hasClearIcon': {
      paddingRight: theme.spacing(4)
    }
  },

  // Background variances
  'bg-always': {
    '&:not($inputDisabled):not($inputError)': {
      backgroundColor
    }
  },
  'bg-hover-only': {
    '&:hover:not($inputDisabled):not($inputError)': {
      backgroundColor
    }
  },
  'bg-hover-and-focus': {
    '&:hover:not($inputDisabled):not($inputError)': {
      backgroundColor
    },
    '&$inputFocused:not($inputError)': {
      backgroundColor
    }
  },
  'bg-never': {},

  // Typography variances
  'input-body2': {
    ...theme.typography.body2,
    lineHeight: '24px',
    '&::placeholder': {
      ...theme.typography.body2,
      lineHeight: '24px'
    }
  },
  'input-body1': {
    ...theme.typography.body1,
    lineHeight: '24px',
    '&::placeholder': {
      ...theme.typography.body1,
      lineHeight: '24px',
    }
  },
  'input-header': {
    '&:not($inputMultiline)': {
      height: theme.spacing(3.5)
    },
    ...theme.typography.h5,
    fontSize: '1.375rem',
    '&::placeholder': {
      ...theme.typography.h5,
      fontSize: '1.375rem'
    }
  },

  // Make the placeholder text looks like a secondary text
  secondaryPlaceholder: {
    '&::placeholder': {
      color: theme.palette.text.secondary
    }
  },

  // Get rid of the (default) underline when focused
  inputFocused: {
    '&::before': {
      display: 'none'
    }
  },

  // Re-style underline
  inputUnderline: {
    '&:hover:not($inputDisabled)': {
      '&:not($underline)::before': {
        display: 'none'
      },
      '&$underline::before': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },

  // as far as we're concerned disabled means readonly
  inputDisabled: {
    color: theme.palette.text.primary
  },

  // For textarea, padding is applied to the input root NOT the
  // textarea itself.
  multiline: {
    padding: theme.spacing(0.75, 1)
  },

  // Get rid of horizontal padding
  noHorizontalPadding: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important'
  },

  inputMultiline: {},
  inputError: {},
  underline: {},
  hasClearIcon: {}
}));

function Input(props) {
  const classes = useStyles();

  const {
    typography,
    background,
    underline,
    horizontalPadding,
    secondaryPlaceholder,
    startAdornment,
    hasClearIcon,
    inputProps,
    InputProps,
    ...rest
  } = props;

  return (
    <TextField
      type='text'
      inputProps={{
        ...inputProps,
        // We don't want anyone to re-style our text field
        // (e.g., MUI Autocomplete)
        className: ''
      }}
      InputProps={{
        ...InputProps,

        // We explicitly set the position to 'end' here so that
        // startAdornment will have left margin of 8 - which is what
        // we want!
        startAdornment: startAdornment &&  (
          <InputAdornment position='end'>
            {startAdornment}
          </InputAdornment>
        ),

        classes: {
          root: clsx(classes.inputRoot, classes[`bg-${background}`], {
            [classes.underline]: underline
          }),
          input: clsx(classes.inputInput, classes[`input-${typography}`], {
            [classes.secondaryPlaceholder]: secondaryPlaceholder,
            [classes.noHorizontalPadding]: !horizontalPadding,
            [classes.hasClearIcon]: hasClearIcon
          }),
          underline: classes.inputUnderline,
          focused: classes.inputFocused,
          multiline: clsx(classes.multiline, {
            [classes.noHorizontalPadding]: !horizontalPadding
          }),
          inputMultiline: classes.inputMultiline,
          disabled: classes.inputDisabled,
          error: classes.inputError
        },

        // We don't want anyone to re-style our text field
        // (e.g., MUI Autocomplete)
        className: ''
      }}
      {...rest}
    />
  );
}

Input.propTypes = {
  // Input typography
  typography: PropTypes.oneOf(['header', 'body1', 'body2']).isRequired,

  // When to show background
  background: PropTypes.oneOf([
    'hover-only',
    'hover-and-focus',
    'always',
    'never'
  ]).isRequired,

  // If set to `true`, the placeholder text will have the color of
  // the secondary text.
  secondaryPlaceholder: PropTypes.bool,

  // Our own underline style
  underline: PropTypes.bool,

  // If set to `false`, no horizontal paddings will be applied
  horizontalPadding: PropTypes.bool,

  // Other InputProps
  startAdornment: PropTypes.node,

  // Used by clearable autocomplete
  hasClearIcon: PropTypes.bool
};

Input.defaultProps = {
  typography: 'body2',
  background: 'hover-and-focus',
  secondaryPlaceholder: true,
  horizontalPadding: true,
  underline: false,
  hasClearIcon: false
};

export default Input;
