import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PlusIcon from '../../../icons/16x16/Plus';
import PaperclipIcon from '../../../icons/16x16/Paperclip';

const useStyles = makeStyles((theme) => ({
  label: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  labelIcon: {
    marginRight: theme.spacing(3)
  },
  labelText: {
    color: theme.palette.text.secondary
  },
  editIcon: {
    backgroundColor: theme.palette.button.secondary.main,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    fontSize: '0.875rem',
    width: 20,
    height: 20,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
  },
  clickable: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      display: 'inline-flex'
    }
  }
}));

function AttachmentLabel({ clickable }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.label, {
      [classes.clickable]: clickable
    })} >
      <PaperclipIcon color='secondary' className={classes.labelIcon} />
      <Typography
        variant='subtitle2'
        className={classes.labelText}
      >
        Attachments
      </Typography>
      <div className={classes.editIcon}>
        <PlusIcon color='primary' />
      </div>
    </div>
  );
}

AttachmentLabel.propTypes = {
  clickable: PropTypes.bool
};

AttachmentLabel.defaultProps = {
  clickable: true
};

export default AttachmentLabel;
