import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

function ReassignOwnerHelperText({ projectName }) {
  const name = (
    <Box
      component='span'
      color='text.primary'
      fontWeight={500}
    >
      {projectName}
    </Box>
  );

  return (
    <div>
      <DialogContentText>
        The owner of {name} has all the privileges of a typical project
        member, along with the following additional privileges:
      </DialogContentText>
      <Box
        component='ul'
        color='text.secondary'
      >
        <li>
          Transfer his ownership to another project member.
        </li>
        <li>
          Make any changes to the project (name, description, color...)
        </li>
        <li>
          Add new members to the project.
        </li>
        <li>
          Remove existing members from the project.
        </li>
        <li>
          Archive the project.
        </li>
        <li>
          Delete the project.
        </li>
      </Box>
    </div>
  );
}

ReassignOwnerHelperText.propTypes = {
  projectName: PropTypes.string.isRequired
};

export default ReassignOwnerHelperText;
