import React from 'react';
import PropTypes from 'prop-types';
import Composer from '../../../ui/comments/Composer';
import ComposerAvatar from '../../../ui/comments/ComposerAvatar';
import ComposerEditor from '../../../ui/comments/ComposerEditor';
import useCreateCommentMutation from './useCreateCommentMutation';
import { EVENT_COMMENTS_QUERY } from './CommentsQuery';
import { useContainerContext } from '../ContainerContext';
import { generateNewCommentId } from './helper';

function CreateCommentForm({ user, eventId, projectId }) {
  const { scrollToBottom } = useContainerContext();

  const [createComment] = useCreateCommentMutation({
    variables: { eventId },

    update: (cache, { data: { createEventComment: newComment }}) => {
      const data = cache.readQuery({
        query: EVENT_COMMENTS_QUERY,
        variables: { eventId }
      });

      let comments = [...data.event.comments];

      // We have to check for existance here because the comment may have
      // already been added by subscription.
      const found = comments.some(({ id }) => ( id === newComment.id ));
      if (!found) {
        comments = [...comments, newComment];
      }

      cache.writeQuery({
        query: EVENT_COMMENTS_QUERY,
        variables: { eventId },
        data: {
          event: {
            ...data.event,
            comments: comments
          }
        }
      });
    },

    onCompleted: (data) => {
      scrollToBottom();
    }
  });

  const handleSubmit = ({ text, files }) => {
    createComment({
      variables: { text, files },
      optimisticResponse: {
        createEventComment: {
          id: generateNewCommentId(),
          text: text,
          files: [],
          autoGenerated: false,
          severity: 'DEFAULT',
          author: user,
          updatedAt: (new Date()).toISOString(),
          __typename: 'Comment'
        }
      }
    });
  };

  return (
    <Composer>
      <ComposerAvatar user={user} />
      <ComposerEditor
        placeholder='Add a comment...'
        projectId={projectId}
        onSubmit={handleSubmit}
      />
    </Composer>
  )
}

CreateCommentForm.propTypes = {
  user: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default CreateCommentForm;
