import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Skeleton from './Skeleton';
import Error from '../oops/Error';

// TODO: this query is only specific to `CreateWorkspace`
// component?
const GET_CURRENT_USER_AND_HIS_INVITATIONS_QUERY = gql`
  query GetCurrentUserAndHisInvitationsQuery {
    me {
      id
      username
      receivedInvitations {
        id
        workspace {
          name
        }
      }
    }
  }
`;

function CreateWorkspaceQuery({ children }) {
  const { loading, error, data } = useQuery(
    GET_CURRENT_USER_AND_HIS_INVITATIONS_QUERY,
    {
      fetchPolicy: 'network-only'
    }
  );

  if (loading) return <Skeleton />;
  if (error) return <Error error={error} />;
  return children(data.me);
}

CreateWorkspaceQuery.propTypes = {
  children: PropTypes.func.isRequired
}

export default CreateWorkspaceQuery;
