import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const PIN_PROJECT_MUTATION = gql`
  mutation PinProject($projectId: ID!) {
    pinProject(projectId: $projectId) {
      id
      pinned
    }
  }
`;

// TODO: Wouldn't it be nice if we could optimistic response the full
// project details (see project card) so that we could update the
// list of user's favorite projects (if it's already in the cache).
export default function usePinProjectMutation(options) {
  const { projectId, onCompleted: onFinished, ...otherOptions} = options;
  const {
    enqueueSuccessSnackbar,
    enqueueGraphQLErrorSnackbar
  } = useCustomSnackbar();

  return useMutation(PIN_PROJECT_MUTATION,{
    variables: { projectId },

    optimisticResponse: {
      __typename: 'Mutation',
      pinProject: {
        __typename: 'Project',
        id: projectId,
        pinned: true
      }
    },

    onCompleted(data) {
      onFinished && onFinished(data);
      const msg = 'The project was added to your Favorites';
      enqueueSuccessSnackbar(msg);
    },

    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },

    ...otherOptions
  });
}
