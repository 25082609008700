import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../../account/UserAvatar';
import StyledAddUserButton from './StyledAddUserButton';

const useStyles = makeStyles((theme) => ({
  invisible: {
    opacity: 0,
    cursor: 'default',
    pointerEvents: 'none'
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: '0.75rem',
    marginRight: 8
  }
}));

const AddUserButton = React.forwardRef(
  function AddUserButton(props, ref) {
    const { user, invisible, placeholderText, ...rest } = props;
    const classes = useStyles();

    return (
      <StyledAddUserButton
        ref={ref}
        placeholderText={placeholderText}
        tabIndex={invisible ? -1 : 0}
        className={invisible ? classes.invisible : null}
        {...rest}
      >
        {user && (
          <>
            <UserAvatar className={classes.avatar} user={user} />
            <Typography variant='body2' color='textPrimary' noWrap>
              {user.username}
            </Typography>
          </>
        )}
      </StyledAddUserButton>
    );
  }
);

AddUserButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }),
  invisible: PropTypes.bool,
  placeholderText: PropTypes.string
};

AddUserButton.defaultProps = {
  invisible: false,
  placeholderText: 'Add user'
};

export default AddUserButton;
