import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const FOLLOW_MESSAGE_MUTATION = gql`
  mutation FollowMessage($messageId: ID!) {
    followMessage(messageId: $messageId) {
      id
    }
  }
`;

export default function useFollowMessageMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(FOLLOW_MESSAGE_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
