import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { makeStyles } from '@material-ui/core/styles';
import { TIMELINE, timelineIntervalSize } from './constants';
import convertMousePositionToGridRowIndex
from './convertMousePositionToGridRowIndex';
import convertRowIndexToTimeInterval
from './convertRowIndexToTimeInterval';

const useStyles = makeStyles((theme) => ({
  timeline: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: theme.spacing(6.5),
    right: 0,

    top: timelineIntervalSize.PIXELS/2,
    bottom: timelineIntervalSize.PIXELS/2
  },
  dragging: {
    cursor: 'move',

    // While user is dragging around, we have to turn off mouse-events
    // for all chips
    '& > *': {
      cursor: 'move !important',
      pointerEvents: 'none !important'
    }
  }
}));

function TimelineDragDropContext({
  onDragStart,
  onDragUpdate,
  onDragEnd,
  onClick,
  children
}) {
  const classes = useStyles();
  const ref = React.useRef(null);

  const [isDragging, setIsDragging] = React.useState(false);
  const [dragStart, setDragStart] = React.useState(null);

  const fromMousePositionToTimeInterval = React.useCallback((mouseEvt) => {
    const rect = ref.current.getBoundingClientRect();
    const options = { rect, numRows: TIMELINE.length - 1 };
    const rowIndex = convertMousePositionToGridRowIndex(mouseEvt, options);
    return convertRowIndexToTimeInterval(rowIndex, TIMELINE);
  }, []);

  // If user presses the mouse for more than two milisecond, we interpret
  // it as a dragging event, otherwise it is a click event.
  const debounceMouseDown = debounce((mouseEvt) => {
    const interval = fromMousePositionToTimeInterval(mouseEvt);

    setIsDragging(true);
    setDragStart(interval);

    if (onDragStart) {
      onDragStart(mouseEvt, interval);
    }
  }, 300);

  // User is dragging around.
  const handleMouseMove = (mouseEvt) => {
    if (!isDragging) { return; }

    const dragEnd = fromMousePositionToTimeInterval(mouseEvt);

    if (onDragUpdate) {
      onDragUpdate(mouseEvt, { dragStart, dragEnd });
    }
  };

  // User stops dragging
  const handleMouseUp = (mouseEvt) => {
    const dragEnd = fromMousePositionToTimeInterval(mouseEvt);

    if (!isDragging) {
      // If user releases the mouse before two miliseconds, we have to
      // cancel the registered mouse down event.
      debounceMouseDown.cancel();

      // Tell client that user has just clicked on some time-interval
      if (mouseEvt.target === ref.current && onClick) {
        onClick(mouseEvt, dragEnd);
      }

      return;
    }

    setIsDragging(false);
    setDragStart(null);

    if (onDragEnd) {
      onDragEnd(mouseEvt, { dragStart, dragEnd });
    }
  };

  const preventMouseDownOnContextMenu = React.useCallback((mouseEvt) => {
    mouseEvt.preventDefault();
    mouseEvt.stopPropagation();
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(classes.timeline, {
        [classes.dragging]: isDragging
      })}
      onMouseDown={(mouseEvt) => {
        mouseEvt.persist();
        debounceMouseDown(mouseEvt);
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onContextMenu={preventMouseDownOnContextMenu}
    >
      {children}
    </div>
  );
}

TimelineDragDropContext.propTypes = {
  onDragStart: PropTypes.func,
  onDragUpdate: PropTypes.func,
  onDragEnd: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default TimelineDragDropContext;
