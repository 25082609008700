import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import TrashIcon from '../../icons/20x20/Trash';
import DeleteTeam from '../dialogs/DeleteTeam';

const DeleteTeamMenuItem = React.forwardRef((props, ref) => {
  const { teamId, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <DeleteTeam teamId={teamId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Delete team'
      icon={ <TrashIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

DeleteTeamMenuItem.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default DeleteTeamMenuItem;
