import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../ui/input/TextField';
import Loading from './Loading';

export default withStyles((theme) => ({
  root: {},
  listbox: {},
  option: {},
  paper: {
    boxShadow: theme.customShadow.card
  },
  endAdornment: {},
  clearIndicator: {}
}))(({ InputProps, ...rest }) => {
  return (
    <Autocomplete
      multiple
      disableClearable
      forcePopupIcon={false}
      noOptionsText='No matches found'
      loadingText={ <Loading /> }
      renderInput={(params) => <TextField {...params} {...InputProps} />}
      {...rest}
    />
  );
});
