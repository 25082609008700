import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subscribers: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center'
  },
  subscribersLabel: {
    fontWeight: 500,
    flex: '0 0 auto',
    marginRight: theme.spacing(1)
  }
}));

export default function Subscribers({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.subscribers}>
      <Typography
        variant='body2'
        color='textSecondary'
        className={classes.subscribersLabel}
      >
        Subscribers
      </Typography>
      {children}
    </div>
  );
}
