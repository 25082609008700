import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

const UNREAD_NOTIFICATION_CREATED_SUBSCRIPTION = gql`
  subscription OnUnreadNotificationCreated($workspaceId: ID!) {
    newNotificationAdded(workspaceId: $workspaceId) {
      id
      unread
    }
  }
`;

const UNREAD_NOTIFICATION_DELETED_SUBSCRIPTION = gql`
  subscription OnUnreadNotificationDeleted($workspaceId: ID!) {
    someNotificationRemoved(workspaceId: $workspaceId) {
      id
      unread
    }
  }
`;


function UnreadNotificationCountChangedSubscriber({
  subscribeToUnreadNotificationCountChanged
}) {
  const { workspaceId } = useParams();

  React.useEffect(() => {
    const unsubscribe = subscribeToUnreadNotificationCountChanged({
      document: UNREAD_NOTIFICATION_CREATED_SUBSCRIPTION,
      variables: { workspaceId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;

        const notification = subscriptionData.data.newNotificationAdded;
        let newCount = prev.unreadNotificationCount;

        if (notification.unread) {
          newCount++;
        }

        return {
          unreadNotificationCount: newCount
        };
      }
    });

    return () => unsubscribe();
  }, [workspaceId, subscribeToUnreadNotificationCountChanged]);

  React.useEffect(() => {
    const unsubscribe = subscribeToUnreadNotificationCountChanged({
      document: UNREAD_NOTIFICATION_DELETED_SUBSCRIPTION,
      variables: { workspaceId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;

        const notification = subscriptionData.data.someNotificationRemoved;
        let newCount = prev.unreadNotificationCount;

        if (notification.unread) {
          newCount--;
        }

        return {
          unreadNotificationCount: newCount
        };
      }
    });

    return () => unsubscribe();
  }, [workspaceId, subscribeToUnreadNotificationCountChanged]);

  return null;
}

UnreadNotificationCountChangedSubscriber.propTypes = {
  subscribeToUnreadNotificationCountChanged: PropTypes.func.isRequired
};

export default UnreadNotificationCountChangedSubscriber;
