import React from 'react';
import PropTypes from 'prop-types';
import CardsGrid from '../ui/card/CardsGrid';
import TeamGridItem from './TeamGridItem';

function TeamsGrid({ teams }) {
  return (
    <CardsGrid>
      {teams.map((team) => <TeamGridItem key={team.id} team={team} />)}
    </CardsGrid>
  );
}

TeamsGrid.propTypes = {
  teams: PropTypes.array.isRequired
};

export default TeamsGrid;
