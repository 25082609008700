import React from 'react';
import PropTypes from 'prop-types';
import { isEvent } from '../helpers';
import useUpdateEventTime from '../mutations/useUpdateEventTime';
import adjustTimeOfDate from './adjustTimeOfDate';

function UpdateTimeMutation({runMutation, schedule, startTime, endTime}) {
  const [updateEventTime] = useUpdateEventTime();

  React.useEffect(() => {
    if (runMutation && schedule && startTime && endTime) {

      if (!isEvent(schedule)) {
        console.log('NOT-HANDLED');
        return;
      }

      const startsAt = adjustTimeOfDate(schedule.startsAt, startTime);
      const endsAt = adjustTimeOfDate(schedule.endsAt, endTime);
      updateEventTime(schedule, { startsAt, endsAt });
    }
  }, [updateEventTime, runMutation, schedule, startTime, endTime]);

  return null;
}

UpdateTimeMutation.propTypes = {
  runMutation: PropTypes.bool.isRequired,

  // The original (time specific) event object.
  schedule: PropTypes.object,

  // New start time
  startTime: PropTypes.shape({
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired
  }),

  // New end time
  endTime: PropTypes.shape({
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired
  })
};

export default UpdateTimeMutation;
