import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function FileDetailsToolbar(props) {
  const classes = useStyles();
  return <div className={classes.toolbar} {...props} />;
}
