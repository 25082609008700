import React from 'react';
import PropTypes from 'prop-types';
import { isBefore, isAfter } from 'date-fns';
import ClockIcon from '../../icons/16x16/Clock';
import DatePicker from '../../new-ui/DatePicker';
import FormField from './FormField';

function DateRangePicker({ startDate, endDate, onChange }) {
  const handleStartDateChange = React.useCallback((newStartDate) => {
    onChange((prevData) => {
      const newStart = newStartDate;
      let newEnd = prevData.endDate;

      if (isAfter(newStart, newEnd)) {
        newEnd = newStart;
      }

      return {
        ...prevData,
        startDate: newStart,
        endDate: newEnd
      };
    });
  }, [onChange]);

  const handleEndDateChange = React.useCallback((newEndDate) => {
    onChange((prevData) => {
      let newStart = prevData.startDate;
      const newEnd = newEndDate;

      if (isAfter(newStart, newEnd)) {
        newStart = newEnd
      }

      return {
        ...prevData,
        startDate: newStart,
        endDate: newEnd
      };
    });
  }, [onChange]);

  return (
    <FormField label={<ClockIcon />}>
      <DatePicker
        clearable={false}
        placeholder='Start date'
        value={startDate}
        onChange={handleStartDateChange}
      />
      —
      <DatePicker
        clearable={false}
        placeholder='End date'
        error={isBefore(endDate, startDate)}
        value={endDate}
        onChange={handleEndDateChange}
      />
    </FormField>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateRangePicker;
