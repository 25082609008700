import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { TODO_ITEM_DETAILS } from './fragments';
import { TODO_CRITERIA_SHAPE, matchesTodoCriteria } from './helpers';

import { TODO_DETAILS_QUERY } from './details/TodoDetailsQuery';

const SOME_TODO_ITEM_MOVED_INTO_SUBSCRIPTION = gql`
  subscription OnSomeTodoItemMovedInto($todoListId: ID!) {
    someTodoItemMovedInto(todoListId: $todoListId) {
      todoItem { ...TodoItemDetails }
      destination {
        index
        todoList { id name }
      }
    }
  }
  ${TODO_ITEM_DETAILS}
`;

function SomeTodoItemMovedIntoSubscriber({ todoListId, todoCriteria }) {
  const todoListFragment = {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };

  useSubscription(SOME_TODO_ITEM_MOVED_INTO_SUBSCRIPTION, {
    variables: { todoListId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) { return; }

      const {
        todoItem: movedItem,
        destination: {
          index: dstIndex,
          todoList: { id: dstListId, name: dstListName }
        }
      } = subscriptionData.data.someTodoItemMovedInto;

      if (dstListId !== todoListId) { return; }

      const todoList = client.readFragment(todoListFragment);
      let todoItems = [...todoList.todoItems];

      const foundIndex = todoItems.findIndex(({ id }) => (
        id === movedItem.id
      ));

      // There is nothing todo if moved item is already at the desired
      // destination.
      if (foundIndex === dstIndex) { return; }

      // There is nothing todo if moved item does not satisfy the
      // current todo criteria.
      if (!matchesTodoCriteria(movedItem, todoCriteria)) { return; }

      if (foundIndex === -1) {
        // Insert movedItem at the desired index.
        todoItems.splice(dstIndex, 0, movedItem);
      } else {
        // Move movedItem to the desired index.
        todoItems.splice(foundIndex, 1);
        todoItems.splice(dstIndex, 0, movedItem);
      }

      client.writeFragment({
        ...todoListFragment,
        data: {
          ...todoList,
          todoItems: todoItems
        }
      });

      //
      // Update the todo list for affected item
      //

      const affectedItem = client.readQuery({
        query: TODO_DETAILS_QUERY,
        variables: { todoId: movedItem.id }
      });

      if (!affectedItem) {
        return;
      }

      client.writeQuery({
        query: TODO_DETAILS_QUERY,
        variables: { todoId: movedItem.id },
        data: {
          todoItem: {
            ...affectedItem.todoItem,
            todoList: {
              ...affectedItem.todoItem.todoList,
              id: dstListId,
              name: dstListName
            }
          }
        }
      });
    }
  });

  return null;
}

SomeTodoItemMovedIntoSubscriber.propTypes = {
  todoListId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

export default SomeTodoItemMovedIntoSubscriber;
