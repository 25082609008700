import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const TEAM_PROJECT_CREATED_SUBSCRIPTION = gql`
  subscription OnTeamProjectCreated($teamId: ID!) {
    teamProjectCreated(teamId: $teamId) {
      id
    }
  }
`;

function ProjectCreatedSubscriber({ teamId, ...rest }) {
  const ret = useSubscription(TEAM_PROJECT_CREATED_SUBSCRIPTION, {
    variables: { teamId },
    ...rest
  });
  if (ret.error) console.log('Error');
  return null;
}

ProjectCreatedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default ProjectCreatedSubscriber;
