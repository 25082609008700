import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';
import CellContent from './CellContent';
import UserAvatar from '../../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    flex: '0 0 auto',
    width: 24,
    height: 24,
    fontSize: '0.75rem',
    fontWeight: 500,
    marginRight: 8
  },
  username: {
    flexGrow: 1
  }
}));

function FileCreatorCell({ creator }) {
  const classes = useStyles();
  return (
    <Cell>
      <CellContent>
        <UserAvatar user={creator} className={classes.avatar} />
        <Typography
          noWrap
          component='span'
          variant='body2'
          className={classes.username}
        >
          {creator.username}
        </Typography>
      </CellContent>
    </Cell>
  );
}

FileCreatorCell.propTypes = {
  creator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }).isRequired
};

export default FileCreatorCell;
