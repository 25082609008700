import React from 'react';
import PropTypes from 'prop-types';
import MonthGridContainer from './MonthGridContainer';
import MonthGrid from './MonthGrid';
import MonthGridHeader from './MonthGridHeader';
import MonthGridBody from './MonthGridBody';
import MonthGridRow from './MonthGridRow';
import MonthGridDaysRow from './MonthGridDaysRow';

function MonthGridLoading({ startOfMonth, daysOfWeek, daysGrid }) {
  return (
    <MonthGridContainer>
      <MonthGrid>
        <MonthGridHeader daysOfWeek={daysOfWeek} />
        <MonthGridBody>
          {daysGrid.map((days, index) => (
            <MonthGridRow key={index}>
              <MonthGridDaysRow
                days={days}
                startOfMonth={startOfMonth}
              />
            </MonthGridRow>
          ))}
        </MonthGridBody>
      </MonthGrid>
    </MonthGridContainer>
  );
}

MonthGridLoading.propTypes = {
  daysOfWeek: PropTypes.array.isRequired,
  daysGrid: PropTypes.array.isRequired
};

export default MonthGridLoading;
