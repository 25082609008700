import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../menu/StyledMenu';
import MoreHorizIcon from '../../icons/20x20/Ellipsis';
import IconButton from './ToolbarIconButton';

function FileMenu({ children, ...rest }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='More actions'
        aria-haspopup='true'
        icon={<MoreHorizIcon />}
        onClick={handleOpen}
      />

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disablePortal
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...rest}
      >
        {React.Children.map(children, (child) => React.cloneElement(child, {
          closeMenu: handleClose
        }))}
      </Menu>
    </div>
  );
}

FileMenu.propTypes = {
  children: PropTypes.node
};

export default FileMenu;
