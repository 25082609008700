import {
  format, subDays, addDays, startOfMonth, subMonths, addMonths, getDay,
  isSameMonth, isAfter, eachDayOfInterval, startOfDay, differenceInDays,
  isWithinInterval, isSameDay, addWeeks, subWeeks
} from 'date-fns';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { isOverflow } from './ui/constants';

// Calendar view options.
export const viewOptions = [
  {value: 'day', label: 'Day'},
  {value: 'week', label: 'Week'},
  {value: 'month', label: 'Month'}
];

// Given a date, returns a string of the form `yyyy/M/d`.
export function getDateSlug(date) {
  return format(date, 'yyyy/M/d');
}

// Given the current date (assuming valid), returns the slug for the
// previous date.
export function getPrevDateSlug(currentDate) {
  const prevDate = subDays(currentDate, 1);
  return getDateSlug(prevDate);
}

// Given the current date (assuming valid), returns the slug for the
// next date.
export function getNextDateSlug(currentDate) {
  const nextDate = addDays(currentDate, 1);
  return getDateSlug(nextDate);
}

export function getWeekSlug(currentDate) {
  return getDateSlug(currentDate);
}

export function getPrevWeekSlug(currentDate) {
  const date = subWeeks(currentDate, 1);
  return getDateSlug(date);
}

export function getNextWeekSlug(currentDate) {
  const date = addWeeks(currentDate, 1);
  return getDateSlug(date);
}

// Given the current date, returns the slug for the start date of the
// (current) month.
export function getMonthSlug(currentDate) {
  const beginOfMonth = startOfMonth(currentDate);
  return getDateSlug(beginOfMonth);
}

// Given the current date, returns the slug for the start date of the
// previous month
export function getPrevMonthSlug(currentDate) {
  const beginOfMonth = startOfMonth(currentDate);
  const beginOfPrevMonth = subMonths(beginOfMonth, 1);
  return getDateSlug(beginOfPrevMonth);
}

// Given the current date (assuming valid), returns the slug for the
// start date of the next month.
export function getNextMonthSlug(currentDate) {
  const beginOfMonth = startOfMonth(currentDate);
  const beginOfNextMonth = addMonths(beginOfMonth, 1);
  return getDateSlug(beginOfNextMonth);
}

// Kind of like the inverse of `getDateSlug` above.
// Converts date slug into a date object.
export function useDateParam() {
  const { year, month, day } = useParams();
  return new Date(`${year}/${month}/${day}`);
}

// Returns a list of all days within a week.
export function getDaysOfWeek(options) {
  const { startsWithSunday = false } = (options || {});
  const rest = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return startsWithSunday ? ['Sun', ...rest] : [...rest, 'Sun'];
}

// Given the start day of a month `startOfMonth`, returns a grid of size
// 6x7 (a 2D array of size 6, and each element of it is 1D array of size 7)
// of all days in the given month (potentially padded by extra days of
// previous/next month).
// Days within each row go from 'Sunday' to 'Saturday' if
// `options.startsWithSunday` is set to `true`, otherwise they go from
// 'Monday' to 'Sunday' (default).
export function getDaysGridForMonth(startOfMonth, options) {
  const {
    // Whether the first column of the grid is Monday (default) or
    // Sunday (if `startWithSunday` is set to `true`).
    startsWithSunday = false,

    // The number of rows of the grid. Available options are 'fit'
    // (minimum number of rows to fit all days of the given month), or
    // any positive numbers.
    numRows = 6
  } = (options || {});

  // The day of week, 0 represents Sunday
  let dayOfWeek = getDay(startOfMonth);

  // The day of week, 0 represents Monday
  if (!startsWithSunday) {
    dayOfWeek = (dayOfWeek + 6) % 7;
  }

  let start = subDays(startOfMonth, dayOfWeek);
  let grids = [];
  let i = 0;

  // Helper function for checking whether the given day `d` belongs to
  // the next month or not.
  const isNextMonth = (d) => (
    !isSameMonth(d, startOfMonth) &&
    isAfter(d, startOfMonth)
  );

  while(true) {
    if (numRows === 'fit') {
      if (isNextMonth(start)) { break; }
    } else {
      if (i === numRows) { break; }
    }

    const end = addDays(start, 6);
    grids.push(eachDayOfInterval({ start, end }));
    start = addDays(end, 1);
    i++;
  }

  return grids;
}

//
// Events helpers.
//

// Returns `true` if the given event is of type `TODO`. Returns
// `false` otherwise.
export function isTodo(event) {
  return event && event.__typename === 'TodoItem';
}

// Returns `true` if the given event is of type `EVENT`. Returns
// `false` otherwise.
export function isEvent(event) {
  return event && event.__typename === 'Event';
}

// Returns the title for the given event.
export function getEventTitle(event) {
  if (isTodo(event)) { return event.name; }
  if (isEvent(event)) { return event.title; }
  return null;
}

// Is the given day within the interval? (including start and end).
// TODO: explain why don't just use `isWithinInterval` directly.
export function isDayWithinInterval(day, { start, end }) {
  return isWithinInterval(startOfDay(day), {
    start: startOfDay(start),
    end: startOfDay(end)
  });
}

// Returns the distance between two given days: leftDay - rightDay
// TODO: explain why don't just use `differenceInDays` directly.
export function distanceBetweenTwoDays(leftDay, rightDay) {
  const left = startOfDay(leftDay);
  const right = startOfDay(rightDay);
  return differenceInDays(left, right);
}

// Sort (in-place) a list of events by their durations descending.
// Event which has longer duration will come first.
function _sortByDurationDesc(eventsList) {
  eventsList.sort((e1, e2) => {
    const d1 = distanceBetweenTwoDays(e1.endsAt, e1.startsAt);
    const d2 = distanceBetweenTwoDays(e2.endsAt, e2.startsAt);
    return d2 - d1;
  });
}

// Check if twop given events are of the same type and also having
// the exact same id.
function _isSameTypeAndId(e1, e2) {
  return (e1.id === e2.id) && (e1.__typename === e2.__typename);
}

// Group events into days.
// TODO: please given some explanation about the algorithm being used!
// (and also, i don't think this is the optimal way of doing it ^_^).
export function groupEventsIntoDays(eventsList, daysRange) {
  const daysWithEvents = daysRange.map((day) => {
    let dailyEvents = [];

    eventsList.forEach((e) => {
      if (isDayWithinInterval(day, {start: e.startsAt, end: e.endsAt})) {
        dailyEvents.push(e);
      }
    });

    return { day, events: dailyEvents };
  });

  return daysWithEvents.reduce((acc, dayWithEvents) => {
    const { day, events } = dayWithEvents;
    _sortByDurationDesc(events);

    let positionedEvents;

    if (!acc.length) {
      positionedEvents = events.map((e, i) => ({
        event: e,
        position: i
      }));
    } else {
      const anchor = acc[acc.length - 1].positionedEvents;
      let newEvents = [];
      let occupiedPos = [];

      positionedEvents = events.map((e) => {
        const found = anchor.find((x) => _isSameTypeAndId(x.event, e));

        if (found) {
          occupiedPos.push(found.position);
          return { event: e, position: found.position };
        } else {
          newEvents.push(e);
          return { event: e, position: -1 };
        }
      }).filter(({ position }) => position >= 0);

      let nextPos = -1;

      newEvents = newEvents.map((e) => {
        nextPos++;
        while(occupiedPos.includes(nextPos)) { nextPos++; }
        return { event: e, position: nextPos }
      });

      positionedEvents = [...positionedEvents, ...newEvents];
    }

    return [...acc, { day, positionedEvents }];
  }, []);
}


// Helper function to sort a list of (positioned) events (within a day)
// by their positions.
function _sortByPositionDesc(eventsList) {
  return eventsList.sort((e1, e2) => {
    return e2.position - e1.position;
  });
}

// Append 'default' presentation attributes to each (positioned) event
// within the given day. These 'default' presentation attributes are:
//
// - leftPadding: `true` iff the `startsAt` of the event is the same as
//   the (given) day.
//
// - rightPadding: `true` iff the event ends at someday within the same
//   row (where day is located).
//
// - colIndex: in which day of the week (0 - 6)?
//
// - overflow: `true` iff the event chip is overflowed the container.
export function dayEventsWithDefaultPresentationAttributes(
  day,          // which day
  events,       // list of (positioned) events within the day
  intersection, /* the intersection entry we'll get from the
                   InterSection Observer when our chips row intersect with
                   its container */
  { colIndex }  // which column (day of the week) is this day
) {
  let isPreviousOverflow = false;

  const dailyEvents = _sortByPositionDesc(events).map((e) => {
    const d1 = distanceBetweenTwoDays(e.event.endsAt, day) + 1;
    const d2 = 7 /* number of columns */ - colIndex;
    const startsHere = isSameDay(day, e.event.startsAt);
    let overflow = isOverflow(e.position, intersection);

    if (overflow) {
      isPreviousOverflow = true;
    } else if (isPreviousOverflow) {
      overflow = true;
      isPreviousOverflow = false;
    }

    return {
      ...e,
      overflow,
      colIndex,
      leftPadding: startsHere,
      rightPadding: (d1 <= d2)
    };
  });

  return { day, events: dailyEvents };
}

// Appends the `invisible` attribute to each event within a row.
// This method should only be called after the `overflow` attribute has
// been appended to each event.
// TODO: more explanation...
export function rowEventsWithVisibility(rowEvents) {
  return rowEvents.reduce((acc, { day, events }) => {
    const prev = (acc.length) ? acc[acc.length - 1].events : [];

    const curr = events.map((e) => {
      let invisible = e.overflow;

      if (!invisible && e.colIndex !== 0) {
        const found = prev.find((x) => _isSameTypeAndId(x.event, e.event));
        if (found && !found.overflow) {
          invisible = true;
        }
      }

      return {...e, invisible };
    });

    return [...acc, { day, events: curr }];
  }, []);
}

// Appends the `colSpan` attribute to each event within a row.
// This method should only be called after the `invisible` attribute
// has been appended to each event.
// TODO: more explanation...
export function rowEventsWithColSpan(rowEvents) {
  return rowEvents.map(({ day, events }) => {
    const eventsWithColSpan = events.map((e) => {
      let colSpan = 1;

      if (!e.invisible) {
        for (let i = e.colIndex + 1; i < rowEvents.length; ++i) {
          const found = rowEvents[i].events.find((x) => (
            _isSameTypeAndId(x.event, e.event)
          ));
          if (!found || found.overflow) { break; }
          colSpan++;
        }
      }

      const d = distanceBetweenTwoDays(e.event.endsAt, day) + 1;

      return {
        ...e,
        colSpan,
        rightPadding: e.rightPadding && (colSpan === d)
      };
    });

    return { day, events: eventsWithColSpan };
  });
}

//
// Fake new event IDs for optimistic responses.
//

const _newEventIdPrefix = '__new_event_';
const _newEventIdPattern = new RegExp(`^${_newEventIdPrefix}`);

export function generateNewEventId() {
  return _newEventIdPrefix.concat(uuidv4());
}

export function matchesNewEventId(eventId) {
  return _newEventIdPattern.test(eventId);
}

const _newTodoIdPrefix = '__new_todo_';
const _newTodoIdPattern = new RegExp(`^${_newTodoIdPrefix}`);

export function generateNewTodoId() {
  return _newTodoIdPrefix.concat(uuidv4());
}

export function matchesNewTodoId(todoId) {
  return _newTodoIdPattern.test(todoId);
}
