import React from 'react';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3, 3, 3)
  }
}))((props) => (
  <MuiDialogActions {...props} />
));

export default DialogActions;
