import getWindowPageOffset
from '../../../new-ui/helpers/getWindowPageOffset';

export default function convertMousePositionToGridRowIndex(
  mousePosition,
  options
) {
  const { rect, numRows } = options;
  const { top, height } = rect;
  const windowPageOffset = getWindowPageOffset();

  // Mouse (vertical) position relative to grid (in pixels).
  let relY = mousePosition.pageY - (windowPageOffset.y + top);

  if (relY < 0) {
    // mouse is on the top of the grid
    relY = 0;
  } else if (relY >= height) {
    // mouse is at the bottom of the grid
    relY = height - 1;
  }

  // Find row index

  const rowHeight = Math.ceil(height / numRows);
  let rowIndex = 0;
  let topBound = 0;

  while (rowIndex < numRows) {
    const bottomBound = topBound + rowHeight;
    if (relY >= topBound && relY < bottomBound) { break; }
    rowIndex++;
    topBound = bottomBound;
  }

  return rowIndex;
}
