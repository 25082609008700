import React from 'react';
import PropTypes from 'prop-types';
import Video from './Video';
import PreviewContainer from './PreviewContainer';
import DocumentIconLink from './DocumentIconLink';

// TODO:
// 1. Support multiple sources.
// 2. Provide custom controls.
// 3. More...
function VideoPreview({ src, type, downloadUrl, maxHeight }) {
  const sources = [{src: src, type: type }];

  return (
    <PreviewContainer>
      <Video
        maxHeight={maxHeight}
        sources={sources}
        fallback={<DocumentIconLink type={type} href={downloadUrl} />}
      />
    </PreviewContainer>
  );
}

VideoPreview.propTypes = {
  // The source and MIME type of the video to be previewed.
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  // The maximum height applied to the video element.
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['none'])
  ]).isRequired,

  downloadUrl: PropTypes.string
};

VideoPreview.defaultProps = {
  maxHeight: 'none'
};

export default VideoPreview;
