import React from 'react';
import PropTypes from 'prop-types';
import Card from '../ui/card/Card';
import CardHeader from '../ui/card/CardHeader';
import CardTitleLink from '../ui/card/CardTitleLink';
import CardContent from '../ui/card/CardContent';
import CardContentTypography from '../ui/card/CardContentTypography';
import CardFooter from '../ui/card/CardFooter';
import ProjectCardSubheader from './ProjectCardSubheader';
import ProjectCardAction from './ProjectCardAction';
import ProjectCardAvatarGroup from './ProjectCardAvatarGroup';
import ProjectCardAvatarGroupAction from './ProjectCardAvatarGroupAction';
import ProjectAvatar from './ProjectAvatar';
import ProjectUpdatedSubscriber from './ProjectUpdatedSubscriber';
import MemberAddedSubscriber from './ProjectCardMemberAddedSubscriber';
import MemberRemovedSubscriber from './ProjectCardMemberRemovedSubscriber';
import useProjectURL from './hooks/useProjectURL';

function ProjectCard({ project }) {
  const {
    id,
    name,
    description,
    color,
    memberCount,
    todoCompletedRatio,
    pinned,
    archived,
    members
  } = project;

  const projectURL = useProjectURL(id);

  return (
    <>
      <Card to={projectURL}>
        <CardHeader
          avatar={<ProjectAvatar name={name} color={color} />}
          action={(
            <ProjectCardAction
              projectId={id}
              pinned={pinned}
              archived={archived}
            />
          )}
          title={<CardTitleLink title={name} to={projectURL} />}
          subheader={(
            <ProjectCardSubheader
              memberCount={memberCount}
              todoCompletedRatio={todoCompletedRatio}
            />
          )}
        />
        <CardContent>
          <CardContentTypography>
            {description}
          </CardContentTypography>
        </CardContent>
        <CardFooter>
          <ProjectCardAvatarGroup members={members} />
          <ProjectCardAvatarGroupAction projectId={id} />
        </CardFooter>
      </Card>

      <ProjectUpdatedSubscriber projectId={id} />
      <MemberAddedSubscriber projectId={id} />
      <MemberRemovedSubscriber projectId={id} />
    </>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectCard;
