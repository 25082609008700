import React from 'react';
import PropTypes from 'prop-types';
import { startOfMonth, addMonths, subMonths, isValid } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MonthNavigator from './MonthNavigator';
import MonthGrid from './MonthGrid';

const StyledPopover = withStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 1, 1.5),
    width: theme.spacing(40),
    marginTop: theme.spacing(1)
  }
}))((props) => (
  <Popover
    disableAutoFocus
    disableEnforceFocus
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
));

function _startOfMonth(date) {
  if (!isValid(date)) {
    return startOfMonth(new Date());
  }
  return startOfMonth(date);
}

function MonthPopover({ selectedDate, onChange, ...popoverProps }) {
  const [beginOfMonth, setBeginOfMonth] = React.useState(
    _startOfMonth(selectedDate)
  );

  const handlePrev = () => {
    setBeginOfMonth((prev) => subMonths(prev, 1));
  };

  const handleNext = () => {
    setBeginOfMonth((prev) => addMonths(prev, 1));
  };

  // TODO: we don't need to change `beginOfMonth` if the `selectedDate`
  // and the current `beginOfMonth` are of the same month.
  // (we could use memo to avoid re-rendering unnecessarily)
  React.useEffect(() => {
    setBeginOfMonth(_startOfMonth(selectedDate));
  }, [selectedDate]);

  return (
    <StyledPopover {...popoverProps}>
      <MonthNavigator
        startOfMonth={beginOfMonth}
        onPrev={handlePrev}
        onNext={handleNext}
      />
      <MonthGrid
        selectedDate={selectedDate}
        onChange={onChange}
        startOfMonth={beginOfMonth}
      />
    </StyledPopover>
  );
}

// TODO: explain what these are...
MonthPopover.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired
};

export default MonthPopover;
