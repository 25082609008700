import React from 'react';
import PropTypes from 'prop-types';
import combineSchedulesWithChipProps
from '../daily-schedule/combineSchedulesWithChipProps';
import isSameTypeAndId from '../daily-schedule/isSameTypeAndId';
import filterSchedulesByDate from './utils/filterSchedulesByDate';
import TimeSpecificGrid from './TimeSpecificGrid';
import TimeSpecificColumn from './TimeSpecificColumn';

function TimeSpecificSchedule({ projectId, days, schedules }) {
  const [dragItem, setDragItem] = React.useState(null);

  const columns = Array.from(
    days,
    (date, index) => {
      const filteredSchedules = filterSchedulesByDate(schedules, date);
      return {
        items: combineSchedulesWithChipProps(filteredSchedules),
        date,
        colIndex: index
      };
    }
  );

  // User starts to drag existing schedule.
  const handleDragStart = React.useCallback((schedule) => {
    let foundItem;

    for (let i = 0; i < columns.length; ++i) {
      let found = columns[i].items.find((item) => (
        isSameTypeAndId(item.schedule, schedule)
      ));

      if (found) {
        foundItem = {
          schedule: found.schedule,
          chipProps: {
            title: found.chipProps.title,
            start: found.chipProps.start,
            end: found.chipProps.end,
            color: found.chipProps.color,
            colIndex: i
          }
        };

        break;
      }
    }

    if (foundItem) {
      setDragItem(foundItem);
    }
  }, [columns]);

  // User stops dragging.
  const handleDragEnd = React.useCallback(() => {
    setDragItem(null);
  }, []);

  const isDragging = (schedule) => {
    return dragItem && isSameTypeAndId(schedule, dragItem.schedule);
  };

  return (
    <TimeSpecificGrid
      projectId={projectId}
      days={days}
      dragItem={dragItem}
      dragZIndex={schedules.length + 1}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {columns.map(({ colIndex, date, items }) => (
        <TimeSpecificColumn
          key={colIndex}
          projectId={projectId}
          items={items.map((item) => ({
            ...item,
            chipProps: {
              ...item.chipProps,
              isDragging: isDragging(item.schedule)
            }
          }))}
        />
      ))}
    </TimeSpecificGrid>
  );
}

TimeSpecificSchedule.propTypes = {
  projectId: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
  schedules: PropTypes.array.isRequired
};

export default TimeSpecificSchedule;
