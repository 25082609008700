import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from './Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    flex: '0 0 auto'
  },
  avatar: {
    flex: '0 0 auto',
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: '2rem',
    marginRight: theme.spacing(2)
  },
  header: {
    flex: '1 1 auto'
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: '1.125rem',
    ...theme.customMixins.wordWrap
  },
  subtitle: {
    fontWeight: 500,
    lineHeight: 1.15,
    ...theme.customMixins.wordWrap
  },
  caption: {
    lineHeight: 1.3333333333,
    marginTop: 2
  }
}));

function UserCard(props) {
  const { avatar, title, subtitle, caption, action } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div className={classes.content}>
        {React.cloneElement(avatar, {
          className: classes.avatar
        })}
        <div className={classes.header}>
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
          {caption && (
            <Typography
              component='p'
              variant='caption'
              color='textSecondary'
              className={classes.caption}
            >
              {caption}
            </Typography>
          )}
        </div>
      </div>

      {action && <div className={classes.action}>{action}</div>}
    </Card>
  );
}

UserCard.propTypes = {
  avatar: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  caption: PropTypes.node,
  action: PropTypes.node
};

export default UserCard;
