import React from 'react';
import CardGridItem from '../ui/card/CardGridItem';
import UserCardSkeleton from '../ui/card/UserCardSkeleton';

export default function InvitationGridItemSkeleton() {
  return (
    <CardGridItem>
      <UserCardSkeleton />
    </CardGridItem>
  );
}
