import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  },
  fieldLabel: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    textAlign: 'right'
  },
  fieldIcon: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1.25),
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  fieldInputGroup: {
    width: 0,
    flexGrow: 1,
    minHeight: theme.spacing(4.5),

    // TODO: doesn't feel right to me
    display: 'flex',
    alignItems: 'center'
  }
}));

function FormField({ label, labelWidth, children }) {
  const classes = useStyles();
  const isTextualLabel = !React.isValidElement(label);
  return (
    <div className={classes.field}>
      {isTextualLabel ? (
        <Typography
          component='span'
          variant='body2'
          className={classes.fieldLabel}
          style={{width: labelWidth}}
        >
          {label}
        </Typography>
      ) : (
        <div className={classes.fieldIcon} style={{width: labelWidth}}>
          {React.cloneElement(label, {
            color: 'secondary'
          })}
        </div>
      )}
      <div className={classes.fieldInputGroup}>
        {children}
      </div>
    </div>
  );
}

FormField.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string /* for textual label */,
    PropTypes.node /* for icon label */
  ]).isRequired,
  labelWidth: PropTypes.number, /* a hack to right-align multiple labels */
  children: PropTypes.node
};

export default FormField;
