import React from 'react';
import PropTypes from 'prop-types';
import List from '../../ui/dialog/List';
import MyTeamListQuery from './MyTeamListQuery';
import TeamPickerItem from './TeamPickerItem';
import TeamPickerLoading from './TeamPickerLoading';

// Helper function to move chosen team to the front of the list.
function moveChoseTeamToFront(teamList, chosenTeamId) {
  const chosenTeamIndex = teamList.findIndex(({ id }) => (
    id === chosenTeamId
  ));

  if (chosenTeamIndex <= -1) {
    return teamList;
  }

  let copy = [...teamList];
  const [chosenTeam] = copy.splice(chosenTeamIndex, 1);
  copy.splice(0, 0, chosenTeam);
  return copy;
}

// TODO:
// 1. Handle no teams found.
// 2. Allow client to search through a long list of teams.
function TeamPicker({ value, onChange }) {
  return (
    <MyTeamListQuery onLoading={() => <TeamPickerLoading /> }>
      {(teams) => {
        const sortedTeams = moveChoseTeamToFront(teams, value);

        return (
          <List>
            {sortedTeams.map(({ id, name, description }) => (
              <TeamPickerItem
                key={id}
                primary={name}
                secondary={description}
                selected={id === value}
                value={id}
                onSelect={onChange}
              />
            ))}
          </List>
        )
      }}
    </MyTeamListQuery>
  );
}

TeamPicker.propTypes = {
  // Current chosen teamId
  value: PropTypes.string,

  // (newTeamId) => {...}
  onChange: PropTypes.func
}

export default TeamPicker;
