import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconAvatar from '../avatar/IconAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginRight: theme.spacing(2)
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  medium: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}));

function ListItemStartIcon({ icon, size }) {
  const classes = useStyles();
  return (
    <IconAvatar
      icon={icon}
      classes={{
        avatar: clsx(classes.root, classes[size])
      }}
    />
  );
}

ListItemStartIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

ListItemStartIcon.defaultProps = {
  size: 'large'
};

export default ListItemStartIcon;
