import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import useRemoveMemberMutation from '../hooks/useRemoveMemberMutation';

function MembershipCardAction({ membership }) {
  const { id } = membership;
  const [removeMember, { loading }] = useRemoveMemberMutation({
    membershipId: id
  });

  return (
    <Button
      size='small'
      style={{marginLeft: 8}}
      loading={loading}
      onClick={() => {
        removeMember();
      }}
    >
      Remove
    </Button>
  );
}

MembershipCardAction.propTypes = {
  membership: PropTypes.object.isRequired
};

export default MembershipCardAction;
