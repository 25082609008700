import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '../../../../icons/16x16/Person';
import ContentField from './ContentField';
import ParticipantList from './ParticipantList';

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: '28px'
  }
}));

function ParticipantsField({ users }) {
  const classes = useStyles();
  return (
    <ContentField icon={<PersonIcon />}>
      <Typography variant='body2' className={classes.title}>
        {users.length} participants
      </Typography>
      <ParticipantList users={users} />
    </ContentField>
  );
}

ParticipantsField.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })).isRequired
};

export default ParticipantsField;
