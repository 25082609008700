import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  timeSpecificColumn: {
    flex: '1 1 0%',
    backgroundColor: 'transparent',
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    pointerEvents: 'none'
  }
}));

function TimeSpecificColumn(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.timeSpecificColumn}
      {...props}
    />
  );
}

export default TimeSpecificColumn;
