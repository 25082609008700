import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    width: '100%',
    padding: theme.spacing(4, 2, 1),
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(7.5, 7.5, 1)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(7.5, 15, 1)
    }
  },
  input: {
    ...theme.typography.h4,
    fontWeight: 600,
    '&::placeholder': {
      ...theme.typography.h4,
      fontWeight: 600,
      color: '#90949c',
      opacity: 1.0
    }
  }
}));

function MessageSubjectInput(props) {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      <InputBase
        fullWidth
        multiline={true}
        autoFocus={true}
        autoComplete='off'
        type='text'
        placeholder='Type a subject...'
        classes={{input: classes.input}}
        {...props}
      />
    </div>
  );
}

export default MessageSubjectInput;
