import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiTooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '../../button/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    minWidth: theme.spacing(4),
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#282828',
    fontSize: '0.8125rem',
    fontWeight: theme.typography.fontWeightMedium
  },
  arrow: {
    '&:before': {
      backgroundColor: '#282828'
    }
  }
}))(MuiTooltip);

function SectionHeaderIconButton(props) {
  const classes = useStyles();
  const { className, tooltip, ...rest } = props;

  const button = (
    <IconButton
      color='secondary'
      square
      background={false}
      className={clsx(classes.root, className)}
      {...rest}
    />
  );

  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      {button}
    </Tooltip>
  ) : button;
}

SectionHeaderIconButton.propTypes = {
  tooltip: PropTypes.string
};

export default SectionHeaderIconButton;
