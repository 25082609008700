import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: 8,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'
  },
  link: {
    cursor: 'pointer'
  }
}));

function Card({ to, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const hasLink = Boolean(to);

  const handleClick = (event) => {
    if (!hasLink) { return; }
    history.push(to);
  };

  return (
    <MuiCard
      className={clsx(classes.root, {
        [classes.link]: hasLink
      }, className)}
      onClick={handleClick}
      {...rest}
    />
  );
}

Card.propTypes = {
  to: PropTypes.string
};

export default Card;
