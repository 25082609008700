import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const UPDATE_TODO_FOLLOWERS_MUTATION = gql`
  mutation UpdateTodoFollowers($todoId: ID!, $userIds: [ID]) {
    updateTodoFollowers(todoId: $todoId, userIds: $userIds) {
      id
    }
  }
`;

export default function useUpdateTodoFollowersMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_TODO_FOLLOWERS_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
