import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    margin: '0 auto',
    maxWidth: theme.spacing(136),
    width: '100%',
    padding: theme.spacing(4, 2, 2),
    position: 'relative',

    [theme.breakpoints.up(768)]: {
      marginTop: theme.spacing(3),
      width: '90%',
      borderRadius: '8px 8px 0px 0px',
      boxShadow: theme.shadows[1],
      padding: theme.spacing(6, 7.5, 7.5)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(6, 15, 7.5)
    }
  },
  divider: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar
  }
}));

const ContainerContext = React.createContext(null);

export default function MessageDetailsContainer({ children, className }) {
  const classes = useStyles();
  const sentinelEndRef = React.useRef();

  const ctx = React.useMemo(() => ({
    scrollToBottom: () => {
      sentinelEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }), []);

  return (
    <ContainerContext.Provider value={ctx}>
      <div className={classes.root}>
        <Divider className={classes.divider} />
        <div className={clsx(classes.container, className)}>
          {children}
        </div>
        <div ref={sentinelEndRef} />
      </div>
    </ContainerContext.Provider>
  );
}

export function useContainerContext() {
  return React.useContext(ContainerContext);
}
