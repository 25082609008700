import React from 'react';
import PropTypes from 'prop-types';
import FollowFileButton from './ui/FollowFileButton';
import useFollowFileMutation from './useFollowFileMutation';

function AddCurrentUser({ fileId }) {
  const [addCurrentUser] = useFollowFileMutation({
    variables: { fileId }
  });

  return <FollowFileButton onClick={addCurrentUser} />;
}

AddCurrentUser.propTypes = {
  fileId: PropTypes.string.isRequired
};

export default AddCurrentUser;
