import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import DeleteIcon from '../../ui/icons/Delete';
import IconAvatar from '../../ui/avatar/IconAvatar';
import DialogContext from '../../ui/dialog/DialogContext';
import MenuContext from '../../ui/menu/MenuContext';
import DeleteCurrentWorkspaceConfirm
from '../dialogs/DeleteCurrentWorkspaceConfirm';

const DeleteCurrentWorkspaceMenuItem = React.forwardRef((props, ref) => {
  const { workspace, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <DeleteCurrentWorkspaceConfirm workspace={workspace} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Delete this workspace'
      secondary={`Delete ${workspace.name}.`}
      avatar={ <IconAvatar icon={ <DeleteIcon /> } /> }
      onClick={handleClick}
      {...rest}
    />
  )
});

DeleteCurrentWorkspaceMenuItem.propTypes = {
  workspace: PropTypes.object.isRequired
};

export default DeleteCurrentWorkspaceMenuItem;
