import React from 'react';
import PropTypes from 'prop-types';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from './Checkbox';
import SortLabel from './SortLabel';
import HeadCell from './HeadCell';
import VisuallyHiddenSortLabel from './VisuallyHiddenSortLabel';

function convertOrder(backendOrder) {
  return backendOrder.toLowerCase();
}

function TableHead({
  checkbox,
  columns,
  onRequestSort,
  order,
  orderBy,
  numSelected,
  rowCount,
  onSelectAllClick
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {checkbox && (
          <HeadCell checkbox>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </HeadCell>
        )}
        {columns.map((col) => (
          <HeadCell
            key={col.id}
            align={col.numeric ? 'right' : 'left'}
            sortDirection={!col.disabledSort && convertOrder(order)}
            style={{ width: col.width }}
          >
            {col.disabledSort ? col.label : (
              <SortLabel
                active={orderBy === col.id}
                direction={orderBy === col.id ? convertOrder(order) : 'asc'}
                onClick={createSortHandler(col.id)}
              >
                {col.label}
                {orderBy === col.id ? (
                  <VisuallyHiddenSortLabel order={convertOrder(order)} />
                ) : null}
              </SortLabel>
            )}
          </HeadCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
}

TableHead.propTypes = {
  checkbox: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool.isrequired,
    width: PropTypes.number.isRequired,
    disabledSort: PropTypes.bool.isRequired
  })).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func
};

TableHead.defaultProps = {
  numSelected: 0,
  rowCount: 0
};

export default TableHead;
