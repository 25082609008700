import React from 'react';
import PropTypes from 'prop-types';
import FullscreenIcon from '../../icons/20x20/Fullscreen';
import FullscreenExitIcon from '../../icons/20x20/FullscreenExit';
import IconButton from './ToolbarIconButton';

function FileDetailsFullscreenToggleButton({ fullscreen, onChange }) {
  const handleClick = (event) => {
    if (onChange) { onChange(!fullscreen); }
  };

  return (
    <IconButton
      aria-label={fullscreen ? 'Go full screen' : 'Exit full screen'}
      icon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      onClick={handleClick}
    />
  );
}


FileDetailsFullscreenToggleButton.propTypes = {
  fullscreen: PropTypes.bool,
  onChange: PropTypes.func
};

FileDetailsFullscreenToggleButton.defaultProps = {
  fullscreen: false
};

export default FileDetailsFullscreenToggleButton;
