import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './select/MenuItem';
import Select from './select/StyledSelect';
import Placeholder from './select/Placeholder';
import Chip from './priority/Chip';
import { priorityOptions, isValidPriority } from './priority/options';

function PrioritySelect({
  value,
  onChange,
  placeholderText,
  placeholderVariant,
  placeholderColor
}) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(newValue);
    }
  };

  const renderValue = (someValue) => {
    if (isValidPriority(someValue)) {
      return <Chip variant={someValue} />;
    }

    return (
      <Placeholder
        text={placeholderText}
        color={placeholderColor}
      />
    );
  };

  return (
    <Select
      typography={placeholderVariant}
      value={value}
      onChange={handleChange}
      renderValue={renderValue}
    >
      {priorityOptions.map((option) => (
        <MenuItem key={option} value={option}>
          <Chip variant={option} />
        </MenuItem>
      ))}
    </Select>
  );
}

PrioritySelect.propTypes = {
  value: PropTypes.oneOf(priorityOptions),
  onChange: PropTypes.func,

  placeholderText: PropTypes.string,
  placeholderVariant: PropTypes.oneOf(['body1', 'body2']),
  placeholderColor: PropTypes.oneOf(['primary', 'secondary', 'placeholder'])
};

PrioritySelect.defaultProps = {
  value: 'NONE',
  placeholderText: 'Set priority',
  placeholderVariant: 'body1',
  placeholderColor: 'placeholder'
};

export default PrioritySelect;
