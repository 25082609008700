import React from 'react';
import PropTypes from 'prop-types';
import TodoDetailsQuery from './TodoDetailsQuery';
import TodoDetailsLoading from './TodoDetailsLoading';
import TodoQueryError from './TodoQueryError';
import TodoNotFound from './TodoNotFound';
import TodoDetailsWithData from './TodoDetailsWithData';

function TodoDetails({ todoId, onClose }) {
  return (
    <TodoDetailsQuery
      todoId={todoId}
      onLoading={() => <TodoDetailsLoading onClose={onClose} />}
      onError={(error) => (
        <TodoQueryError error={error} onClose={onClose} />
      )}
    >
      {(todo) => todo ? (
        <TodoDetailsWithData
          todo={todo}
          onClose={onClose}
        />
      ) : (
        <TodoNotFound onClose={onClose} />
      )}
    </TodoDetailsQuery>
  );
}

TodoDetails.propTypes = {
  todoId: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default TodoDetails;
