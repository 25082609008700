import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from './Spinner';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
}));

function PageLoading() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Spinner />
    </Backdrop>
  );
}

export default PageLoading;
