import React from 'react';
import PropTypes from 'prop-types';
import CardGridItem from '../../ui/card/CardGridItem';
import MembershipCard from './MembershipCard';

function MembershipGridItem({ membership }) {
  return (
    <CardGridItem>
      <MembershipCard membership={membership} />
    </CardGridItem>
  );
}

MembershipGridItem.propTypes = {
  membership: PropTypes.object.isRequired
};

export default React.memo(MembershipGridItem);
