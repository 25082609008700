import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function MarkCompleteSkeleton() {
  return (
    <Skeleton
      variant='rect'
      width='16%'
      height={28}
      style={{borderRadius: 6}}
    />
  );
}
