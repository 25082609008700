import React from 'react';
import PropTypes from 'prop-types';
import CardHeaderAction from '../ui/card/CardHeaderAction';
import TeamCardMenu from './team-card-menu/TeamCardMenu';

function TeamCardAction({ teamId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <CardHeaderAction
        onClick={handleClick}
        color={open ? 'accent' : 'secondary'}
        background={open}
      />
      <TeamCardMenu
        teamId={teamId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </React.Fragment>
  );
}

TeamCardAction.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamCardAction);
