import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 36,
    height: 36,
    color: theme.palette.button.secondary.contrastText,
    backgroundColor: theme.palette.button.secondary.main
  }
}));

function IconAvatar({ icon, classes }) {
  const internalClasses = useStyles();
  return (
    <Avatar className={clsx(internalClasses.root, classes.avatar)}>
      {React.cloneElement(icon, { className: classes.icon })}
    </Avatar>
  );
}

IconAvatar.propTypes = {
  icon: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    avatar: PropTypes.string,
    icon: PropTypes.string
  })
};

IconAvatar.defaultProps = {
  classes: {
    avatar: null,
    icon: null
  }
};

export default IconAvatar;
