import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import DialogActions from '../../ui/dialog/MultiStepActionsContainer';
import EditUploadtForm from './EditUploadForm';
import UploadNameInput from './UploadnameInput';
import UploadDescInput from './UploadDescInput';
import Divider from './EditUploadFormDivider';
import Preview from './EditUploadPreview';
import Metadata from './EditUploadMetadata';
import useUpdateUploadMutation from '../hooks/useUpdateUploadMutation';

function EditUpload({ upload, onClose, actionsContainer }) {
  const [formData, setFormData] = React.useState({
    name: upload.name,
    notes: upload.notes || ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Sync when external data changed
  React.useEffect(() => {
    setFormData({
      name: upload.name,
      notes: upload.notes || ''
    });
  }, [upload.name, upload.notes]);

  //
  // GraphQL mutation.
  //

  const [updateUpload, { loading }] = useUpdateUploadMutation({
    variables: {
      uploadId: upload.id,
      changes: formData
    },
    onCompleted: (data) => {
      onClose();
    }
  });

  // TODO: FIXME
  const isSubmitDisabled = () => {
    const trimmedNotes = formData.notes.trim();
    const trimmedName = formData.name.trim();
    const isSameName = trimmedName === upload.name;
    const isSameDesc = (!upload.notes && trimmedNotes.length === 0) ||
                       (trimmedNotes === upload.notes);

    if (isSameName && isSameDesc) {
      return true;
    }

    if (trimmedName.length < 3) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <EditUploadtForm>
        <Preview
          src={upload.previewUrl}
          type={upload.previewType}
          downloadUrl={upload.downloadUrl}
          alt={upload.name}
        />

        <UploadNameInput
          name='name'
          ext={upload.ext}
          value={formData.name}
          onChange={handleChange}
        />

        <Metadata type={upload.type} size={upload.size} />

        <Divider />

        <UploadDescInput
          name='notes'
          value={formData.notes}
          onChange={handleChange}
        />
      </EditUploadtForm>

      <DialogActions container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          disabled={loading}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          disabled={isSubmitDisabled()}
          onClick={() => {
            updateUpload();
          }}
        >
          Save changes
        </Button>
      </DialogActions>
    </div>
  );
}

EditUpload.propTypes = {
  upload: PropTypes.object.isRequired,

  // DOM node to mount dialog actions
  actionsContainer: PropTypes.object,

  // Close dialog
  onClose: PropTypes.func
};

export default EditUpload;
