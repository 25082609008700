import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from '../button/Overlay';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover:not(.Mui-selected) $overlay': {
      opacity: 1.0
    },
    '&:active:not(.Mui-selected) $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },
    '&.Mui-focusVisible:not(.Mui-selected) $overlay': {
      opacity: 1.0
    }
  },
  primary: {
    fontSize: '0.9375rem',
    lineHeight: 1.5
  },
  secondary: {
    fontSize: '0.8125rem'
  },
  overlay: {}
}));

const MenuItem = React.forwardRef(function MenuItem(props, ref) {
  const { primary, secondary, icon, avatar, className, ...rest } = props;
  const classes = useStyles();
  return (
    <MuiMenuItem
      ref={ref}
      alignItems={secondary ? 'flex-start' : 'center'}
      className={clsx(classes.root, className)}
      {...rest}
    >
      {icon && (<ListItemIcon>{icon}</ListItemIcon>)}
      {!icon && avatar && (<ListItemAvatar>{avatar}</ListItemAvatar>)}
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{variant: 'subtitle2'}}
        classes={{
          primary: classes.primary,
          secondary: classes.secondary
        }}
      />
      <Overlay className={classes.overlay} />
    </MuiMenuItem>
  );
});

MenuItem.propTypes = {
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node,
  icon: PropTypes.node,
  avatar: PropTypes.node
};

export default MenuItem;
