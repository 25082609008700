import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AVATAR_COLORS, AVATAR_DEFAULT_COLOR } from './colors';

const useStyles = makeStyles((theme) => ({
  small: {
    width: 36,
    height: 36,
    fontSize: '1.125rem'
  },
  medium: {
    width: 40,
    height: 40,
    fontSize: '1.25rem'
  },
  large: {
    width: 48,
    height: 48,
    fontSize: '1.5rem'
  },
  xlarge: {
    width: 60,
    height: 60,
    fontSize: '2rem'
  }
}));

const Avatar = React.forwardRef(function Avatar(props, ref) {
  const { size, color, className, style, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MuiAvatar
      ref={ref}
      className={clsx({
        [classes.small]: (size === 'small'),
        [classes.medium]: (size === 'medium'),
        [classes.large]: (size === 'large'),
        [classes.xlarge]: (size === 'xlarge')
      }, className)}
      style={{
        ...style,
        color: theme.palette.getContrastText(color),
        backgroundColor: color
      }}
      {...rest}
    />
  );
});

Avatar.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']).isRequired,
  color: PropTypes.oneOf(AVATAR_COLORS)
};

Avatar.defaultProps = {
  size: 'medium',
  color: AVATAR_DEFAULT_COLOR
};

export default Avatar;
