import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commentList: {
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
}));

function CommentList({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.commentList}>
      {children}
    </div>
  );
}

CommentList.propTypes = {
  children: PropTypes.node
};

export default CommentList;
