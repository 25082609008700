import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { PROJECT_MEMBERSHIP_DETAILS_FRAGMENT } from '../fragments';

const ACTIVE_PROJECT_MEMBERS_QUERY = gql`
  query GetActiveProjectMembers($projectId: ID!) {
    project(projectId: $projectId) {
      id
      members {
        ...ProjectMembershipDetails
      }
    }
  }
  ${PROJECT_MEMBERSHIP_DETAILS_FRAGMENT}
`;

export default function useLazySearchNewMembersForProjectQuery(options) {
  const { projectId, ...rest } = options;
  return useLazyQuery(ACTIVE_PROJECT_MEMBERS_QUERY, {
    variables: { projectId },
    ...rest
  });
}
