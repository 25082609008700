import React from 'react';
import StyledFooter from '../../ui/todo/sheet/StyledFooter';
import CreateNewSectionForm from '../../ui/todo/sheet/CreateNewSectionForm';
import useCreateTodoListMutation from '../hooks/useCreateTodoListMutation';
import { TODOS_IN_PROJECT_QUERY } from './TodosInProjectQuery';
import { generateNewSectionId } from '../helpers';

export default function Footer({
  colSpan,
  todosQueryVariables: variables
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpenForm = () => {
    setOpen(true);
  };

  const handleCloseForm = () => {
    setOpen(false);
  };

  //
  // Mutation.
  //

  const [createTodoList] = useCreateTodoListMutation();
  const defaultSectionName = 'Untitled List';

  const handleSubmit = (sectionName) => {
    createTodoList({
      variables: {
        projectId: variables.projectId,
        name: sectionName || defaultSectionName
      },

      optimisticResponse: {
        __typename: 'Mutation',
        createTodoList: {
          __typename: 'TodoList',
          id: generateNewSectionId(),
          name: sectionName || defaultSectionName,
          todoItems: []
        }
      },

      update: (client, { data: { createTodoList: newList }}) => {
        const oldData = client.readQuery({
          query: TODOS_IN_PROJECT_QUERY,
          variables: variables
        });

        const oldTodoLists = oldData.project.todoLists;

        const found = oldTodoLists.some(({ id }) => (id === newList.id));

        const newTodoLists = found ?
                             oldTodoLists :
                             [...oldTodoLists, newList];

        client.writeQuery({
          query: TODOS_IN_PROJECT_QUERY,
          variables: variables,
          data: {
            project: {
              ...oldData.project,
              todoLists: newTodoLists
            }
          }
        });
      }
    });
  };

  return (
    <React.Fragment>
      <CreateNewSectionForm
        colSpan={colSpan}
        open={open}
        onClose={handleCloseForm}
        onSubmit={handleSubmit}
      />
      <StyledFooter colSpan={colSpan} onClick={handleOpenForm} />
    </React.Fragment>
  );
}
