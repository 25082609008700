import React from 'react';
import StyledAutocomplete from './autocomplete/StyledAutocomplete';
import AutosizeInput from './AutosizeInput';
import Input from './Input';

const _makeRenderInputFn = (inputProps) => (params) => {
  const {
    // If either fullWidth is set to `true` or autosize is set to `false`
    // the Input component will be used. Otherwise, the AutosizeInput
    // will be used, and the fullWidth will be ignored.
    autosize = true,
    fullWidth = false,
    ...otherInputProps
  } = inputProps;

  const finalInputProps = {...otherInputProps, ...params};

  if (fullWidth || !autosize) {
    return <Input {...finalInputProps} fullWidth={fullWidth} />;
  }

  return <AutosizeInput {...finalInputProps} fullWidth={false} />;
};

export default function Autocomplete({
  disableClearable = false,
  inputProps = {},
  ...rest
}) {
  return (
    <StyledAutocomplete
      fullWidth={inputProps.fullWidth || false}
      typography={inputProps.typography || 'body2'}
      disableClearable={disableClearable}
      renderInput={_makeRenderInputFn({
        ...inputProps,
        hasClearIcon: !disableClearable && Boolean(rest.value)
      })}
      {...rest}
    />
  );
}
