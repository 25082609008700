import React from 'react';
import Button from '../ui/button/Button';
import PlusCircleIcon from '../icons/16x16/PlusCircleFill';
import DialogContext from '../ui/dialog/DialogContext';
import CreateTeam from './dialogs/CreateTeam';

function CreateTeamButton() {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: <CreateTeam />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    })
  };

  return (
    <Button
      size='small'
      color='primaryDeemphasized'
      startIcon={ <PlusCircleIcon /> }
      onClick={handleClick}
    >
      Create new team
    </Button>
  );
}

export default CreateTeamButton;
