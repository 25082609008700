import React from 'react';
import { useParams } from 'react-router-dom';
import StyledMenu from '../ui/notification/Menu';
import ListItemSkeleton from '../ui/notification/ListItemSkeleton';
import ListItemPlaceholder from '../ui/notification/ListItemPlaceholder';
import ListSubheader from '../ui/notification/UnreadListSubheader';
import useLazyNotificationsQuery from './useLazyNotificationsQuery';
import ListItem from './ListItem';
import useMarkAsReadMutation from './useMarkAsReadMutation';
import useMarkAllReadMutation from './useMarkAllReadMutation';
import { UNREAD_NOTIFICATION_COUNT_QUERY }
from './UnreadNotificationCountQuery';

function renderData(data, { onMarkAsRead, closeMenu }) {
  if (!data) { return null; }

  const notifications = data.me.notifications;

  if (!notifications.length) {
    return <ListItemPlaceholder />;
  }

  return notifications.map((notification) => (
    <ListItem
      key={notification.id}
      notification={notification}
      onMarkAsRead={onMarkAsRead}
      closeMenu={closeMenu}
    />
  ));
}

function Menu({ open, ...rest }) {
  const [
    listNotifications,
    { called, loading, data, updateQuery }
  ] = useLazyNotificationsQuery({
    filter: { unread: true },
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (open) {
      listNotifications();
    }
  }, [open, listNotifications]);

  //
  // Mark a specific notification as read.
  //

  const { workspaceId } = useParams();

  const [markAsRead] = useMarkAsReadMutation({
    update: (cache, { data: { markAsRead: notification }}) => {
      // Update list of unread notifications
      updateQuery((prevData) => ({
        me: {
          ...prevData.me,
          notifications: prevData.me.notifications.filter(({ id }) => (
            id !== notification.id
          ))
        }
      }));

      //
      // Update unread notification count.
      //

      const prevCountData = cache.readQuery({
        query: UNREAD_NOTIFICATION_COUNT_QUERY,
        variables: { workspaceId }
      });

      const newCount = prevCountData.unreadNotificationCount - 1;

      cache.writeQuery({
        query: UNREAD_NOTIFICATION_COUNT_QUERY,
        variables: { workspaceId },
        data: {
          unreadNotificationCount: newCount
        }
      });
    }
  });

  const handleMarkAsRead = (notificationId) => {
    markAsRead({
      variables: { notificationId },
      optimisticResponse: {
        markAsRead: {
          id: notificationId,
          __typename: 'Notification'
        }
      }
    });
  };

  //
  // Mark all notifications as read
  //

  const [markAllRead] = useMarkAllReadMutation({
    update: (cache, { data: { markAllRead: count }}) => {
      // Update list of unread notifications
      updateQuery((prevData) => ({
        me: {
          ...prevData.me,
          notifications: (count === prevData.me.notifications.length) ?
                         [] : prevData.me.notifications
        }
      }));

      //
      // Update unread notification count.
      //

      const prevCountData = cache.readQuery({
        query: UNREAD_NOTIFICATION_COUNT_QUERY,
        variables: { workspaceId }
      });

      const newCount = prevCountData.unreadNotificationCount - count;

      cache.writeQuery({
        query: UNREAD_NOTIFICATION_COUNT_QUERY,
        variables: { workspaceId },
        data: {
          unreadNotificationCount: newCount
        }
      });
    }
  });

  const handleMarkAllRead = (event) => {
    markAllRead({
      optimisticResponse: {
        markAllRead: data.me.notifications.length
      }
    });
  };

  return (
    <StyledMenu
      open={open}
      MenuListProps={{
        subheader: data && data.me.notifications.length > 0 && (
          <ListSubheader onMarkAllRead={handleMarkAllRead} />
        )
      }}
      {...rest}
    >
      {called && loading && <ListItemSkeleton />}
      {renderData(data, {
        onMarkAsRead: handleMarkAsRead,
        closeMenu: rest.onClose
      })}
    </StyledMenu>
  );
}

export default Menu;
