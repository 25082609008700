import React from 'react';
import PropTypes from 'prop-types';
import UnarchivedMenu from './UnarchivedMenu';
import ArchivedMenu from './ArchivedMenu';

function ProjectCardMenu({ pinned, archived, projectId, ...rest }) {
  return archived ? (
    <ArchivedMenu projectId={projectId} {...rest} />
  ) : (
    <UnarchivedMenu pinned={pinned} projectId={projectId} {...rest}/>
  );
}

ProjectCardMenu.propTypes = {
  pinned: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired
};

export default React.memo(ProjectCardMenu);
