import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '../../ui/svg-icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    margin: theme.spacing(1, 0, 0, 1),
    padding: 0,
    borderRadius: '50%',
    transition: 'all .2s ease-in-out'
  },
  color: ({ color }) => ({
    backgroundColor: color,
    color: theme.palette.getContrastText(color),
    '&:hover': {
      backgroundColor: color,
      transform: 'scale(1.2)',
      boxShadow: theme.shadows[1]
    },
    '&.Mui-focusVisible': {
      outline: 'none',
      backgroundColor: color,
      transform: 'scale(1.2)',
      boxShadow: theme.shadows[1]
    }
  }),
  checkIcon: {
    fontSize: '1rem'
  }
}));

// TODO: Until we find a way to integrate `ChipColorPicker` with material-ui
// Menu so that navigation won't break for keyboard users, we have to
// stick with this hack!
const ChipMenuColorPickerItem = React.forwardRef(
  function ChipMenuColorPickerItem(props, ref) {
    const { color, checked, onSelect, className, ...rest } = props;
    const classes = useStyles({ color });

    const handleSelect = (event) => {
      if (onSelect) {
        onSelect(color);
      }
    };

    return (
      <MenuItem
        ref={ref}
        className={clsx(classes.root, classes.color, className)}
        onClick={handleSelect}
        {...rest}
      >
        {checked && <CheckIcon className={classes.checkIcon} /> }
      </MenuItem>
    );
  }
);

ChipMenuColorPickerItem.propTypes = {
  // Which color (HEX CODE)
  color: PropTypes.string.isRequired,

  // Set checked to true if current color is equal to this color
  checked: PropTypes.bool,

  // (color) => {...}
  onSelect: PropTypes.func
};

ChipMenuColorPickerItem.defaultProps = {
  checked: false
};

export default ChipMenuColorPickerItem;
