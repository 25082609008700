import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    backgroundColor: theme.palette.primary.main,
    height: 3,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 1
  }
}));

export default function TabActiveUnderline({ className, ...rest }) {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)} {...rest}></div>;
}
