import React from 'react';
import PropTypes from 'prop-types';
import AvatarGroup from '../ui/card/CardAvatarGroup';
import UserAvatar from '../account/UserAvatar';

function ProjectHeaderAvatarGroup({ members }) {
  return (
    <AvatarGroup max={8}>
      {members.map(({ id, user }) => (
        <UserAvatar key={id} user={user} />
      ))}
    </AvatarGroup>
  );
}

ProjectHeaderAvatarGroup.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    // Membership ID
    id: PropTypes.string.isRequired,

    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string
    })
  })).isRequired
};

export default ProjectHeaderAvatarGroup;
