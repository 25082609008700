import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { timelineIntervalSize } from '../../daily-schedule/ui/constants';

const useStyles = makeStyles((theme) => ({
  timeSpecificGrid: {
    backgroundColor: 'transparent',
    display: 'flex',

    position: 'absolute',
    left: theme.spacing(6.5),
    right: 0,
    top: timelineIntervalSize.PIXELS/2,
    bottom: timelineIntervalSize.PIXELS/2
  },
  dragging: {
    cursor: 'move',

    // While user is dragging around, we have to turn off mouse-events
    // for children (including nested ones).
    '& *': {
      cursor: 'move !important',
      pointerEvents: 'none !important'
    }
  }
}));

const StyledTimeSpecificGrid = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { isDragging, ...rest } = props;

  return (
    <div
      ref={ref}
      className={clsx(classes.timeSpecificGrid, {
        [classes.dragging]: isDragging
      })}
      {...rest}
    />
  );
});

StyledTimeSpecificGrid.propTypes = {
  isDragging: PropTypes.bool
};

StyledTimeSpecificGrid.defaultProps = {
  isDragging: false
};

export default StyledTimeSpecificGrid;
