import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  subject: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: 1.1
  },
  divider: {
    width: theme.spacing(15),
    margin: `${theme.spacing(1)}px auto ${theme.spacing(0.5)}px`
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: theme.spacing(24)
  },
  bull: {
    margin: theme.spacing(0, 1)
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up(768)]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: '1rem'
    }
  }
}));

function MessageDetailsHeader({ author, subject, timestamp }) {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Typography
        variant='h5'
        component='h1'
        className={classes.subject}
      >
        {subject}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.subtitle}>
        <div className={classes.user}>
          <UserAvatar user={author} className={classes.avatar} />
          <Typography variant='body1' noWrap>
            {author.username}
          </Typography>
        </div>
        <span className={classes.bull}>&bull;</span>
        <Typography variant='body1' noWrap>
          {timestamp}
        </Typography>
      </div>
    </div>
  );
}

MessageDetailsHeader.propTypes = {
  author: PropTypes.object.isRequired,
  subject: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired
};

export default MessageDetailsHeader;
