import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commentList: {
    paddingLeft: theme.spacing(5.5),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  }
}));

export default function CommentList(props) {
  const classes = useStyles();
  return <div className={classes.commentList} {...props} />
}
