import React from 'react';
import MenuItem from '../../ui/menu/MenuItem';
import DoorEnterIcon from '../../ui/icons/DoorEnter';
import IconAvatar from '../../ui/avatar/IconAvatar';
import DialogContext from '../../ui/dialog/DialogContext';
import MenuContext from '../../ui/menu/MenuContext';
import SignInToOtherWorkspace from '../dialogs/SignInToOtherWorkspace';

const SignInToOtherWorkspaceMenuItem = React.forwardRef((props, ref) => {
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <SignInToOtherWorkspace />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Sign in to other workspace'
      avatar={ <IconAvatar icon={ <DoorEnterIcon /> } /> }
      onClick={ handleClick }
      {...props}
    />
  )
});

export default SignInToOtherWorkspaceMenuItem;
