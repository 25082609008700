import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext
from '../../workspace/CurrentWorkspaceContext';
import Error from '../../oops/Error';

// TODO: We cannot reuse `TeamsQuery` in '../../team/' since that would
// fetch all team details which is much more than what we want here.
// ( We only want id, name, descitpion ).
const MY_TEAM_LIST_QUERY = gql`
  query GetMyTeamList($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      id
      teams {
        id
        name
        description
      }
    }
  }
`;

function MyTeamListQuery({
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);

  const {
    loading,
    error,
    data,
    ...otherQueryResults
  } = useQuery(MY_TEAM_LIST_QUERY, {
    variables: {
      workspaceId: currentWorkspaceId
    },
    ...otherQueryProps
  });

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.workspace.teams, otherQueryResults);
}

MyTeamListQuery.propTypes = {
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default MyTeamListQuery;
