import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import filesize from 'filesize';
import { getDescriptionForMimeType } from '../../ui/file/mime/mime';

const useStyles = makeStyles((theme) => ({
  metadata: {
    fontWeight: 500,
    marginTop: -4
  }
}));

function EditUploadMetadata({ type, size }) {
  const classes = useStyles();
  const description = getDescriptionForMimeType(type);
  const humanReadableSize = filesize(size, {round: 1, base: 10});

  return (
    <Typography
      variant='body2'
      color='textSecondary'
      className={classes.metadata}
    >
      {description} - {humanReadableSize}
    </Typography>
  );
}

EditUploadMetadata.propTypes = {
  // Upload's MIME type
  type: PropTypes.string.isRequired,

  // Upload's content length
  size: PropTypes.number.isRequired
};

export default EditUploadMetadata;
