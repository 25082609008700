import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import WorkspaceContext from '../../workspace/CurrentWorkspaceContext';
import {
  PROJECT_DETAILS_FRAGMENT,
  PROJECT_PINNED_FRAGMENT,
  PROJECT_STATS_FRAGMENT,
  PROJECT_MEMBERS_FRAGMENT
} from '../fragments';

const SOME_PROJECT_PINNED_OR_UNPINNED_SUBSCRIPTION = gql`
  subscription OnSomeProjectPinnedOrUnpinned($workspaceId: ID!) {
    someProjectPinnedOrUnpinned(workspaceId: $workspaceId) {
      ...ProjectDetails
      ...ProjectPinned
      ...ProjectStats
      ...ProjectMembers
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
  ${PROJECT_PINNED_FRAGMENT},
  ${PROJECT_STATS_FRAGMENT}
  ${PROJECT_MEMBERS_FRAGMENT}
`;

function SomeProjectPinnedOrUnpinnedSubscriber({
  subscribeToSomeProjectPinnedOrUnpinned
}) {
  const { currentWorkspaceId } = React.useContext(WorkspaceContext);

  React.useEffect(() => {
    const unsubscribe = subscribeToSomeProjectPinnedOrUnpinned({
      document: SOME_PROJECT_PINNED_OR_UNPINNED_SUBSCRIPTION,
      variables: { workspaceId: currentWorkspaceId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return prev; }

        const project = subscriptionData.data.someProjectPinnedOrUnpinned;
        const pid = project.id;
        const isPinned = project.pinned;

        const oldFavorites = prev.myFavoriteProjects;
        let newFavorites;

        if (isPinned) {
          const found = oldFavorites.find(({ id }) => id === pid);
          if (found) { return prev; }
          newFavorites = [project, ...oldFavorites];
        } else {
          newFavorites = oldFavorites.filter(({ id }) => id !== pid );
        }

        return {
          ...prev,
          myFavoriteProjects: newFavorites
        };
      }
    });

    return () => unsubscribe();
  }, [currentWorkspaceId, subscribeToSomeProjectPinnedOrUnpinned]);

  return null;
}

SomeProjectPinnedOrUnpinnedSubscriber.propTypes = {
  subscribeToSomeProjectPinnedOrUnpinned: PropTypes.func.isRequired
};

export default SomeProjectPinnedOrUnpinnedSubscriber;
