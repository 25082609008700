import React from 'react';
import PropTypes from 'prop-types';
import MenuItemDivider from '../../ui/menu/MenuItemDivider';
import StyledMenu from './StyledMenu';
import RestoreProjectItem from './RestoreProjectItem';
import DeleteProjectItem from './DeleteProjectItem';

function ArchivedMenu({ projectId, ...rest }) {
  return (
    <StyledMenu {...rest}>
      <RestoreProjectItem projectId={projectId} />
      <MenuItemDivider />
      <DeleteProjectItem projectId={projectId} />
    </StyledMenu>
  );
}

ArchivedMenu.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ArchivedMenu;
