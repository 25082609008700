import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from '../workspace/CurrentWorkspaceContext';
import Error from '../oops/Error';
import { USER_PROFILE_FRAGMENT } from '../account/fragments';

const SEARCH_INVITATIONS_IN_CURRENT_WORKSPACE_QUERY = gql`
  query SearchInvitationsInCurrentWorkspace(
    $currentWorkspaceId: ID!,
    $filter: WorkspaceInvitationFilter!
  ) {
    workspace(workspaceId: $currentWorkspaceId) {
      id
      joiningInvitations(filter: $filter)
      @connection(key: "invitation_search_result") {
        id
        state
        receiverEmail
        sender {
          ...UserProfile
        }
        sentAt
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

function SearchInvitationsInCurrentWorkspaceQuery(props) {
  const { criteria, onLoading, onError, children, ...other } = props;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data } = useQuery(
    SEARCH_INVITATIONS_IN_CURRENT_WORKSPACE_QUERY,
    {
      variables: {
        currentWorkspaceId,
        filter: criteria
      },
      fetchPolicy: 'network-only',
      ...other
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.workspace.joiningInvitations);
}

SearchInvitationsInCurrentWorkspaceQuery.propTypes = {
  criteria: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

SearchInvitationsInCurrentWorkspaceQuery.defaultProps = {
  criteria: {}
};

export default SearchInvitationsInCurrentWorkspaceQuery;
export { SEARCH_INVITATIONS_IN_CURRENT_WORKSPACE_QUERY };
