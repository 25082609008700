import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import PersonRemoveIcon from '../../ui/icons/PersonRemove';
import IconAvatar from '../../ui/avatar/IconAvatar';
import DialogContext from '../../ui/dialog/DialogContext';
import MenuContext from '../../ui/menu/MenuContext';
import DeactivateSelfMembershipConfirm
from '../../member/dialogs/DeactivateSelfMembershipConfirm';

const RemoveMeFromCurrentWorkspaceMenuItem = React.forwardRef((props, ref) => {
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);
  const { membershipId, workspaceName, ...rest } = props;

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: (
        <DeactivateSelfMembershipConfirm
          membershipId={membershipId}
          workspaceName={workspaceName}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Remove me from this workspace'
      secondary={`Remove yourself from ${workspaceName}.`}
      avatar={ <IconAvatar icon={ <PersonRemoveIcon /> } /> }
      onClick={handleClick}
      {...rest}
    />
  )
});

RemoveMeFromCurrentWorkspaceMenuItem.propTypes = {
  membershipId: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default RemoveMeFromCurrentWorkspaceMenuItem;
