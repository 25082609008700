import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../ui/todo/details/AttachmentList';
import AttachmentListItem from './AttachmentListItem';
import AttachmentsAddedSubscriber from './AttachmentsAddedSubscriber';
import SomeAttachmentRemoved from './SomeAttachmentRemoved';
import MultiPreview from '../../../ui/file/preview/MultiPreview';

function AttachmentList({
  attachments,
  todoId,
  refetchAttachments,
  onSomeAttachmentRemoved
}) {
  return (
    <List>
      <MultiPreview files={attachments}>
        {attachments.map((attachment, index) => (
          <AttachmentListItem
            key={attachment.id}
            index={index}
            todoId={todoId}
            attachment={attachment}
          />
        ))}
      </MultiPreview>
      <AttachmentsAddedSubscriber
        todoId={todoId}
        refetchAttachments={refetchAttachments}
      />
      <SomeAttachmentRemoved
        todoId={todoId}
        onSomeAttachmentRemoved={onSomeAttachmentRemoved}
      />
    </List>
  );
}

AttachmentList.propTypes = {
  attachments: PropTypes.array.isRequired,
  todoId: PropTypes.string.isRequired,
  refetchAttachments: PropTypes.func.isRequired,
  onSomeAttachmentRemoved: PropTypes.func.isRequired
};

export default AttachmentList;
