import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from '../workspace/CurrentWorkspaceContext';
import Error from '../oops/Error';

const INVITATIONS_IN_CURRENT_WORKSPACE_QUERY = gql`
  query GetInvitationsInCurrentWorkspace($currentWorkspaceId: ID!) {
    workspace(workspaceId: $currentWorkspaceId) {
      id
      joiningInvitations {
        id
        state
      }
    }
  }
`;

function InvitationsInCurrentWorkspaceQuery(props) {
  const { onLoading, onError, children, ...other } = props;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data } = useQuery(
    INVITATIONS_IN_CURRENT_WORKSPACE_QUERY,
    {
      variables: { currentWorkspaceId },
      fetchPolicy: 'network-only',
      ...other
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.workspace.joiningInvitations);
}

InvitationsInCurrentWorkspaceQuery.propTypes = {
  children: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

export default InvitationsInCurrentWorkspaceQuery;
export { INVITATIONS_IN_CURRENT_WORKSPACE_QUERY };
