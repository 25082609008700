import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import NavTabs from './tabs/NavTabs';
import TabLink from './tabs/TabLink';
import TabsContainer from './tabs/TabsContainer';
import TabsSkeleton from './tabs/TabsSkeleton';

function ProjectTabs({ isLoading }) {
  const { url } = useRouteMatch();

  return (
    <TabsContainer>
      {!isLoading ? (
        <NavTabs>
          <TabLink to={`${url}/tasks`} primary='Tasks' />
          <TabLink to={`${url}/files`} primary='Files' />
          <TabLink to={`${url}/messages`} primary='Messages' />
          <TabLink to={`${url}/calendar`} primary='Calendar' />
          <TabLink to={`${url}/members`} primary='Members' exact />
          <TabLink to={`${url}/activities`} primary='Activities' exact />
        </NavTabs>
      ) : <TabsSkeleton />}
    </TabsContainer>
  );
}

ProjectTabs.propTypes = {
  isLoading: PropTypes.bool
};

ProjectTabs.defaultProps = {
  isLoading: false
};

export default ProjectTabs;
