import React from 'react';
import PersonIcon from '../../icons/16x16/Person';
import FormField from './FormField';
import UserAutocomplete from '../../new-ui/UserAutocompleteAsync';
import UserList from '../../new-ui/autocomplete/UserList';
import useActiveProjectMembersLazyQuery
from '../../project/hooks/useActiveProjectMembersLazyQuery';

function ParticipantsField({ projectId, value, onChange }) {
  const [inputValue, setInputValue] = React.useState('');

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newParticipant) => {
    const found = value.some(({ id }) => id === newParticipant.id);
    if (!found && onChange) {
      onChange([newParticipant, ...value]);
    }
    setInputValue('');
  };

  const handleRemoveParticipant = (user) => {
    const newParticipants = value.filter(({ id }) => id !== user.id);
    if (onChange) {
      onChange(newParticipants);
    }
  };

  // GraphQL

  const [
    loadActiveProjectMembers,
    { called, loading, data }
  ] = useActiveProjectMembersLazyQuery({
    projectId,
    // TODO: do we really need to set fetchPolicy here?
    fetchPolicy: 'network-only'
  });

  const getOptionsFromQueryResult = () => {
    if (loading || !data) {
      return [];
    }

    return data.project.members.map(({ user }) => user );
  };

  return (
    <FormField label={<PersonIcon />}>
      <div style={{width: '100%'}}>
        <UserAutocomplete
          fullWidth
          inputProps={{
            placeholder: 'Add participants'
          }}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          fetchUsers={loadActiveProjectMembers}
          loading={called && loading}
          options={getOptionsFromQueryResult()}
          value={null}
          onChange={handleChange}
        />
        <UserList
          users={value}
          onDelete={handleRemoveParticipant}
        />
      </div>
    </FormField>
  );
}

export default ParticipantsField;
