import React from 'react';
import PropTypes from 'prop-types';
import TimeAutocomplete from './datetime-picker/TimeAutocomplete';
import listTimesOfDay from './datetime-picker/listTimesOfDay';

function TimePicker({ value, onChange, step, start, clearable, ...rest }) {
  const options = React.useMemo(() => listTimesOfDay({
    step,
    start
  }), [step, start]);

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <TimeAutocomplete
      value={value}
      onChange={handleChange}
      options={options}
      disableClearable={!clearable}
      inputProps={{
        placeholder: 'Time',
        ...rest
      }}
    />
  );
}

TimePicker.propTypes = {
  value: PropTypes.shape({
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,

  // Gap between two time points ( 15 minutes by default )
  step: PropTypes.number,

  // A number between 0 and 24 (exclusive) indicating where to start
  // list the times.
  start: PropTypes.number,

  clearable: PropTypes.bool
};

TimePicker.defaultProps = {
  step: 15,
  start: 0,
  clearable: true
};

export default TimePicker;
