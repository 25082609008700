import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Error from '../../oops/Error';
import { USER_PROFILE_FRAGMENT } from '../../account/fragments';

const TEAM_ACTIVITIES_QUERY = gql`
  query GetTeamActivities($teamId: ID!) {
    team(teamId: $teamId) {
      id
      activities {
        id
        actor {
          ...UserProfile
        }
        what
        excerpt
        insertedAt
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

function TeamActivitiesQuery(props) {
  const {
    teamId,
    onLoading,
    onError,
    children,
    ...otherQueryProps
  } = props;

  const {
    loading,
    error,
    data,
    otherQueryResults
  } = useQuery(TEAM_ACTIVITIES_QUERY, {
    variables: { teamId },
    fetchPolicy: 'network-only',
    ...otherQueryProps
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.team.activities, otherQueryResults);
}

TeamActivitiesQuery.propTypes = {
  teamId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default TeamActivitiesQuery;
