import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const AppBarTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#373737',
    fontSize: '0.875rem',
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular
  },
  tooltipPlacementBottom: {
    margin: theme.spacing(1, 0)
  }
}))(React.forwardRef((props, ref) => {
  return (
    <Tooltip
      ref={ref}
      {...props}
      placement='bottom'
    />
  );
}));

export default AppBarTooltip;
