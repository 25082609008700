import React from 'react';
import PropTypes from 'prop-types';
import SecondaryNavItem from '../app-bar/SecondaryNavItem';
import IconButton from '../ui/button/IconButton';
import NotificationIcon from '../ui/icons/Notification';
import Badge from '../ui/notification/Badge';

function NotificationButton({ count, onClick, highlight }) {
  const color = highlight ? 'accent' : 'primary';
  return (
    <SecondaryNavItem tooltipTitle='Notifications'>
      <IconButton
        color={color}
        icon={(
          <Badge badgeContent={count}>
            <NotificationIcon color={color} />
          </Badge>
        )}
        onClick={onClick}
      />
    </SecondaryNavItem>
  );
}

NotificationButton.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
  highlight: PropTypes.bool
};

NotificationButton.defaultProps = {
  count: 0,
  highlight: false
};

export default NotificationButton;
