import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none'
  },
  fileDetailsFooter: {
    padding: theme.spacing(2)
  }
}));

function FileDetailsFooter({ hidden, children }) {
  const classes = useStyles();

  return (
    <div className={clsx(hidden && classes.hidden)}>
      <Divider />
      <div className={classes.fileDetailsFooter}>
        {children}
      </div>
    </div>
  );
}

FileDetailsFooter.propTypes = {
  children: PropTypes.node
};

export default FileDetailsFooter;
