import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import EmailsInput from '../../ui/input/EmailsInput';
import Button from '../../ui/button/Button';
import { isEmailValid } from '../../../utils/helpers';
import useInvitePeopleMutation from '../hooks/useInvitePeopleMutation';
import { SEARCH_INVITATIONS_IN_CURRENT_WORKSPACE_QUERY }
from '../SearchInvitationsInCurrentWorkspaceQuery';
import { INVITATIONS_IN_CURRENT_WORKSPACE_QUERY }
from '../InvitationsInCurrentWorkspaceQuery';

function InvitePeopleToWorkspace({ workspace }) {
  const { id, name } = workspace;
  const { closeDialog } = React.useContext(DialogContext);
  const [value, setValue] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCommaOrSpacePressed = (event, newInputValue) => {
    if (newInputValue && !value.includes(newInputValue)) {
      setValue([...value, newInputValue]);
    }
  };

  const someEmailsInvalid = value.some((email) => !isEmailValid(email));

  const [invitePeople, { loading }] = useInvitePeopleMutation({
    variables: {
      workspaceId: id,
      emails: value
    },
    onCompleted(data) {
      closeDialog();
    },
    refetchQueries: [
      {
        query: SEARCH_INVITATIONS_IN_CURRENT_WORKSPACE_QUERY,
        variables: {
          currentWorkspaceId: id,
          filter: { state: 'pending' }
        }
      },
      {
        query: INVITATIONS_IN_CURRENT_WORKSPACE_QUERY,
        variables: {
          currentWorkspaceId: id
        }
      }
    ]
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Invite people to {name}
      </DialogTitle>
      <DialogContent>
        <EmailsInput
          fullWidth
          value={value}
          onChange={handleChange}
          onCommaOrSpacePressed={handleCommaOrSpacePressed}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='primary'
          disabled={( value.length === 0) || someEmailsInvalid }
          loading={ loading }
          onClick={() => { invitePeople(); }}
        >
          Send
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

InvitePeopleToWorkspace.propTypes = {
  workspace: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default InvitePeopleToWorkspace;
