import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import PrivateRoute from './components/account/PrivateRoute';
import HomePage from './pages/Home';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import CreateWorkspacePage from './pages/CreateWorkspace';
import MyWorkspace from './pages/MyWorkspace';
import PageNotFound from './components/oops/PageNotFound';

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route path='/' exact>
              <HomePage />
            </Route>

            <Route path='/sign-in'>
              <SignInPage />
            </Route>

            <Route path='/sign-up'>
              <SignUpPage />
            </Route>

            <PrivateRoute path='/create-workspace'>
              <CreateWorkspacePage />
            </PrivateRoute>

            <PrivateRoute path='/workspaces/:workspaceId'>
              <MyWorkspace />
            </PrivateRoute>

            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
