// Reminder method

export const EVENT_REMINDER_METHOD_OPTIONS = [
  {value: 'EMAIL', label: 'Email'},
  {value: 'POPUP', label: 'Notification'}
];

export const EVENT_REMINDER_METHODS =
  EVENT_REMINDER_METHOD_OPTIONS.map(({ value }) => value );

// All-day event reminder unit options

export const DAY_EVENT_REMINDER_UNIT_OPTIONS = [
  {value: 'DAYS', label: 'days'},
  {value: 'WEEKS', label: 'weeks'}
];

export const DAY_EVENT_REMINDER_UNITS =
  DAY_EVENT_REMINDER_UNIT_OPTIONS.map(({ value }) => value );

// Time-specific event reminder unit options

export const TIME_EVENT_REMINDER_UNIT_OPTIONS = [
  {value: 'MINUTES', label: 'minutes'},
  {value: 'HOURS', label: 'hours'},
  ...DAY_EVENT_REMINDER_UNIT_OPTIONS
];

export const TIME_EVENT_REMINDER_UNITS =
  TIME_EVENT_REMINDER_UNIT_OPTIONS.map(({ value }) => value );

export const EVENT_REMINDER_UNITS = TIME_EVENT_REMINDER_UNITS;

export const MAX_NUM_REMINDERS = 5;
