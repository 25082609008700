import React from 'react';
import PropTypes from 'prop-types';
import { AlertTitle } from '@material-ui/lab';
import Alert from './Alert';

function Error({ error, ...otherProps }) {
  if (!error || !error.message) return null;

  const isNetworkError = error.networkError &&
                         error.networkError.message &&
                         error.networkError.statusCode;

  const hasGraphQLErrors = error.graphQLErrors &&
                           error.graphQLErrors.length;

  let errorMessage;

  if (isNetworkError) {
    if (error.networkError.statusCode === 404) {
      errorMessage = (
        <Alert {...otherProps} severity='error'>
          <code>404: Not Found</code>
        </Alert>
      );
    } else {
      errorMessage = (
        <Alert {...otherProps} severity='error'>
          <AlertTitle>Network Error!</AlertTitle>
          <code>
            {error.networkError.statusCode}: {error.networkError.message}
          </code>
        </Alert>
      );
    }
  } else if (hasGraphQLErrors) {
    errorMessage = (
      <>
        {error.graphQLErrors.map(({ message, details }, i) => (
          <Alert {...otherProps} severity='error' key={i}>
            <AlertTitle>{message}</AlertTitle>
            {details && (
              <ul>
                {Object.keys(details).map(key => (
                  <li key={key}>
                    {key}: {details[key]}
                  </li>
                ))}
              </ul>
            )}
          </Alert>
        ))}
      </>
    );
  } else {
    errorMessage = (
      <Alert {...otherProps} severity='error'>
        <AlertTitle>Sorry, something went wrong.</AlertTitle>
        {error.message}
      </Alert>
    );
  }

  return errorMessage;
}

Error.propTypes = {
  error: PropTypes.object
};

Error.defaultProps = {
  error: {}
};

export default Error;
