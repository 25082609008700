import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  form: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    margin: '0 auto',
    maxWidth: theme.spacing(136),
    width: '100%',

    [theme.breakpoints.up(768)]: {
      marginTop: theme.spacing(3),
      width: '90%',
      borderRadius: '8px 8px 0px 0px',
      boxShadow: theme.shadows[1]
    }
  }
}));

export default function MessageForm({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <form
        className={classes.form}
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {children}
      </form>
    </div>
  );
}
