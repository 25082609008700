import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const TOGGLE_TODO_FIELD_MUTATION = gql`
  mutation ToggleTodoField(
    $projectId: ID!,
    $field: String!,
    $show: Boolean!
  ) {
    updateTodoSheetColumns(
      projectId: $projectId,
      changes: [{col: $field, changes: {show: $show}}]
    ) {
      name
      show
      width
    }
  }
`;

export default function useToggleFieldMutation(options) {
  return useMutation(TOGGLE_TODO_FIELD_MUTATION, options);
}
