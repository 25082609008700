import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(1),
    height: theme.spacing(5),
    overflow: 'hidden'
  }
}))((props) => ( <CardContent {...props} />));
