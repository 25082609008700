import React from 'react';
import PropTypes from 'prop-types';
import useMemberRemovedSubscription
from './hooks/useMemberRemovedSubscription';
import {TEAM_DETAILS_AND_MEMBERS_QUERY} from './TeamDetailsAndMembersQuery';

function TeamMemberRemovedSubscriber({ teamId }) {
  useMemberRemovedSubscription({
    teamId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) return;

      const { teamMembership: removedMember } =
        subscriptionData.data.teamMemberRemoved;

      const oldData = client.readQuery({
        query: TEAM_DETAILS_AND_MEMBERS_QUERY,
        variables: { teamId }
      });

      const oldMembers = oldData.team.members;
      const updatedMembers = oldMembers.filter(({ id }) => (
        id !== removedMember.id
      ));

      client.writeQuery({
        query: TEAM_DETAILS_AND_MEMBERS_QUERY,
        variables: { teamId },
        data: {
          team: {
            ...oldData.team,
            members: updatedMembers
          }
        }
      });
    }
  });

  return null;
}

TeamMemberRemovedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamMemberRemovedSubscriber);
