import React from 'react';
import PropTypes from 'prop-types';
import Container from './ui/Container';
import StickyHeader from './ui/StickyHeader';
import AllDayRow from './ui/AllDayRow';
import Timeline from './ui/Timeline';
import TimeSpecificGrid from './ui/StyledTimeSpecificGrid';
import TimeSpecificColumn from './ui/TimeSpecificColumn';
import WeekDayRow from './WeekDayRow';

function NoDataView({ daysOfWeek }) {
  return (
    <Container>
      <StickyHeader>
        <WeekDayRow daysOfWeek={daysOfWeek} />
        <AllDayRow numCols={daysOfWeek.length} />
      </StickyHeader>

      <Timeline>
        <TimeSpecificGrid>
          {Array.from(
            daysOfWeek,
            (date, index) => <TimeSpecificColumn key={index} />
          )}
        </TimeSpecificGrid>
      </Timeline>
    </Container>
  );
}

NoDataView.propTypes = {
  daysOfWeek: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ).isRequired
};

export default NoDataView;
