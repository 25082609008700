export default function convertRowIndexToTimeInterval(rowIndex, timeline) {
  // No such interval when the given timeline is either empty or has only
  // one element in it!
  if (!timeline.length || timeline.length <= 1) {
    return null;
  }

  const numIntervals = timeline.length - 1;

  if (rowIndex < 0 || rowIndex >= numIntervals ) {
    return null;
  }

  return {
    start: timeline[rowIndex],
    end: timeline[rowIndex + 1]
  };
}
