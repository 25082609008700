import React from 'react';
import PropTypes from 'prop-types';
import DailyScheduleContainer from './ui/DailyScheduleContainer';
import DailyScheduleHeader from './ui/DailyScheduleHeader';
import DailyScheduleTimeline from './ui/DailyScheduleTimeline';
import DailyScheduleLoading from './ui/DailyScheduleLoading';
import AllDaySchedule from './AllDaySchedule';
import TimeSpecificSchedule from './TimeSpecificSchedule';
import ProjectDailyScheduleQuery from './ProjectDailyScheduleQuery';
import splitSchedules from './splitSchedules';

function ProjectDailySchedule({ projectId, date }) {
  return (
    <ProjectDailyScheduleQuery
      fetchPolicy='cache-and-network'
      projectId={projectId}
      date={date}
      onLoading={() => <DailyScheduleLoading />}
    >
      {(schedules, { subscribeToMore }) => {
        const { allDay, timeSpecific } = splitSchedules(schedules);

        return (
          <DailyScheduleContainer>
            <DailyScheduleHeader>
              <AllDaySchedule
                projectId={projectId}
                date={date}
                schedules={allDay}
              />
            </DailyScheduleHeader>
            <DailyScheduleTimeline>
              <TimeSpecificSchedule
                projectId={projectId}
                date={date}
                schedules={timeSpecific}
              />
            </DailyScheduleTimeline>
          </DailyScheduleContainer>
        );
      }}
    </ProjectDailyScheduleQuery>
  );
}

ProjectDailySchedule.propTypes = {
  // Which project?
  projectId: PropTypes.string.isRequired,

  // Which date?
  date: PropTypes.instanceOf(Date).isRequired
};

export default ProjectDailySchedule;
