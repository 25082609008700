import React from 'react';
import PropTypes from 'prop-types';

import Header from './ui/header/Header';
import Toolbar from './ui/header/Toolbar';
import CloseButton from './ui/header/ToolbarCloseButton';
import DeleteEventButton from './DeleteEventButton';
import EditEventDialog from './EditEventDialog';

import Content from './ui/content/Content';
import TitleField from './ui/content/TitleField';
import ParticipantsField from './ui/content/ParticipantsField';
import DescriptionField from './ui/content/DescriptionField';
import RemindersField from './ui/content/RemindersField';
import ProjectField from './ui/content/ProjectField';
import CommentsSection from './CommentsSection';

import Footer from './ui/footer/Footer';
import CreateComment from './comments/CreateComment';
import ContainerContext from './ContainerContext';

import SubscribersBar from './subscribers/SubscribersBar';

function EventDetailsWithData({ event, onClose }) {
  const sentinelEndRef = React.useRef();

  const ctx = React.useMemo(() => ({
    scrollToBottom: () => {
      sentinelEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }), []);

  return (
    <ContainerContext.Provider value={ctx}>
      <Header>
        <Toolbar>
          <EditEventDialog
            event={event}
            onOpen={onClose}
          />
          <DeleteEventButton
            event={event}
            beforeDeleteFn={onClose}
          />
          <CloseButton onClick={onClose} />
        </Toolbar>
      </Header>

      <Content sentinelEndRef={sentinelEndRef}>
        <TitleField
          title={event.title}
          isAllDay={event.allDay}
          startsAt={event.startsAt}
          endsAt={event.endsAt}
        />

        <ParticipantsField users={event.participants} />

        <DescriptionField description={event.description} />

        <RemindersField
          isAllDayEvent={event.allDay}
          reminders={event.reminders}
        />

        <ProjectField name={event.project.name} />

        <CommentsSection
          eventId={event.id}
          projectId={event.project.id}
        />
      </Content>

      <Footer>
        <CreateComment
          eventId={event.id}
          projectId={event.project.id}
        />

        <SubscribersBar
          eventId={event.id}
          projectId={event.project.id}
        />
      </Footer>
    </ContainerContext.Provider>
  );
}

EventDetailsWithData.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default EventDetailsWithData;
