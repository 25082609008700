import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProjectMessageBoard from './ProjectMessageBoard';
import ProjectMessageDetails from './ProjectMessageDetails';
import CreateProjectMessage from './CreateProjectMessage';
import EditProjectMessage from './EditProjectMessage';

export default function ProjectMessages() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <ProjectMessageBoard />
      </Route>
      <Route path={`${path}/new`} exact>
        <CreateProjectMessage />
      </Route>
      <Route path={`${path}/:messageId`} exact>
        <ProjectMessageDetails rootUrl={url} />
      </Route>
      <Route path={`${path}/:messageId/edit`} exact>
        <EditProjectMessage />
      </Route>
    </Switch>
  );
}
