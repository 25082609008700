import React from 'react';
import MenuItem from '../menu/MenuItem';
import OpenInNewIcon from '../../icons/20x20/OpenInNew';

export default React.forwardRef(function OpenInNewMenuItem(props, ref) {
  const { closeMenu, ...rest } = props;

  const handleClick = (event) => {
    closeMenu();
  };

  return (
    <MenuItem
      ref={ref}
      primary='Open in new tab'
      secondary='View in full-size.'
      icon={ <OpenInNewIcon /> }
      component='a'
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleClick}
      {...rest}
    />
  );
})
