import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  ol: {
    flexWrap: 'nowrap'
  },
  li: {
    '&:last-child': {
      flex: '1 1 auto',
      width: 0
    }
  },
  separator: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 4,
      marginRight: 4
    }
  }
}))((props) => <Breadcrumbs separator='›' {...props} />);
