import React from 'react';
import FullScreenCard from '../ui/card/FullScreenCard.js';
import LinearStepper from '../ui/stepper/LinearStepper';
import LinearStep from '../ui/stepper/LinearStep';
import CreateWorkspaceQuery from './CreateWorkspaceQuery';
import GotInvitation from './GotInvitation';
import CreateWorkspaceForm from './CreateWorkspaceForm';

function UserCreateWorkspace({ user }) {
  const [activeStep, setActiveStep] = React.useState(0);

  if (user.receivedInvitations.length === 0) {
    return <CreateWorkspaceForm username={user.username} />;
  }

  return (
    <LinearStepper activeStep={activeStep}>
      <LinearStep>
        {(activeStep === 0) && (
          <GotInvitation
            username={user.username}
            invitation={user.receivedInvitations[0]}
            onSkip={() => {
              setActiveStep(1);
            }}
          />
        )}
      </LinearStep>

      <LinearStep>
        {(activeStep === 1) && (
          <CreateWorkspaceForm username={user.username} />
        )}
      </LinearStep>
    </LinearStepper>
  );
}

function CreateWorkspace() {
  return (
    <FullScreenCard>
      <CreateWorkspaceQuery>
        {(currentUser) => (
          <UserCreateWorkspace user={currentUser} />
        )}
      </CreateWorkspaceQuery>
    </FullScreenCard>
  );
}

export default CreateWorkspace;
