import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProjectCardSkeleton from '../../project/ProjectCardSkeleton';

export default function TeamsGridSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ProjectCardSkeleton />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectCardSkeleton />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectCardSkeleton />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectCardSkeleton />
      </Grid>
    </Grid>
  );
}
