import React from 'react';
import ToolbarButton from './ToolbarButton';
import ClockIcon from '../../icons/12x12/Clock';

export default function FilterDueDateButton(props) {
  return (
    <ToolbarButton
      startIcon={<ClockIcon color='secondary' />}
      {...props}
    />
  );
}
