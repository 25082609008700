import React from 'react';
import IconButton from './SectionHeaderIconButton';
import EditIcon from '../../../icons/18x18/Pencil';

export default function SectionHeaderEditButton(props) {
  return (
    <IconButton
      icon={<EditIcon />}
      tooltip='Rename this list'
      {...props}
    />
  );
}
