import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridRow: {
    flex: '1 1 0%',
    display: 'flex'
  }
}));

export default function GridRow(props) {
  const classes = useStyles();
  return <div role='row' className={classes.gridRow} {...props} />;
}
