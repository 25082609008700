import React from 'react';
import TimelineContent from '@material-ui/lab/TimelineContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 0,
    width: 0 // OK since its flex-grow is set to 1
  }
}));

export default function ActivityContent(props) {
  const classes = useStyles();
  return <TimelineContent classes={classes} {...props} />;
}
