import React from 'react';
import PropTypes from 'prop-types';
import useWorkspaceURL from '../workspace/hooks/useCurrentWorkspaceURL';
import ListItem from '../ui/notification/ListItem';
import ListItemAvatar from '../ui/notification/ListItemAvatar';
import ListItemText from '../ui/notification/ListItemText';
import UserAvatar from '../account/UserAvatar';
import { generateTodoCommentHtmlId } from '../todo/details/comment/helper';
import { formatTime } from './helpers';

function getNotificationText(todoNotification)  {
  const taskName = todoNotification.todo.name;
  const projName = todoNotification.todo.todoList.project.name;
  const timestamp = formatTime(todoNotification.updatedAt);
  const actorName = todoNotification.actor.username;

  let primary = '';

  switch(todoNotification.type) {
    case 'ASSIGNMENT':
      primary = `Assigned you: ${taskName}`;
      break;
    case 'ASSIGNMENT_DONE':
      primary = `Completed: ${taskName}`;
      break;
    case 'ASSIGNMENT_UNDONE':
      primary = `Re-opened: ${taskName}`;
      break;
    case 'MESSAGE':
      primary = `Re: ${taskName}`;
      break;
    default:
      break;
  }

  return {
    primary,
    secondary: [actorName, projName, timestamp]
  };
}

function getNotificationUrl(todoNotification, workspaceUrl) {
  const projId = todoNotification.todo.todoList.project.id;
  const taskId = todoNotification.todo.id;
  const url = `${workspaceUrl}/projects/${projId}/tasks/${taskId}`;

  if (todoNotification.type === 'MESSAGE') {
    const commentId = todoNotification.comment.id
    const htmlId = generateTodoCommentHtmlId(taskId, commentId);
    return `${url}#${htmlId}`;
  } else {
    return url;
  }
}

const TodoNotificationItem = React.forwardRef((props, ref) => {
  const { notification, onMarkAsRead, ...rest } = props;
  const workspaceUrl = useWorkspaceURL();
  const {id, updatedAt, todoNotification } = notification;

  return (
    <ListItem
      ref={ref}
      id={id}
      url={getNotificationUrl(todoNotification, workspaceUrl)}
      onMarkAsRead={onMarkAsRead}
      {...rest}
    >
      <ListItemAvatar
        primary={<UserAvatar user={todoNotification.actor} />}
        secondary={todoNotification.type}
      />
      <ListItemText
        {...getNotificationText({
          ...todoNotification,
          updatedAt: updatedAt
        })}
      />
    </ListItem>
  );
})

TodoNotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onMarkAsRead: PropTypes.func.isRequired
};

export default TodoNotificationItem;
