import React from 'react';
import PropTypes from 'prop-types';
import DialogLoading from '../../ui/dialog/DialogLoading';
import TeamDetailsQuery from '../TeamDetailsQuery';
import EditTeamForm from './EditTeamForm';

function EditTeam({ teamId }) {
  return (
    <TeamDetailsQuery
      teamId={teamId}
      onLoading={() => <DialogLoading /> }
    >
      {(team) => <EditTeamForm team={team} />}
    </TeamDetailsQuery>
  );
}

EditTeam.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default EditTeam;
