import React from 'react';
import PropTypes from 'prop-types';
import MuiTableBody from '@material-ui/core/TableBody';
import EmptyRow from './EmptyRow';
import FileRow from './FileRow';

function TableBody({ checkbox, columns, rows }) {
  const colCount = columns.length;

  return (
    <MuiTableBody>
      <EmptyRow colSpan={checkbox ? colCount + 1 : colCount} />
      {rows.map((row) => (
        <FileRow
          key={row.id || row.name}
          checkbox={checkbox}
          columns={columns}
          row={row}
        />
      ))}
    </MuiTableBody>
  );
}

TableBody.propTypes = {
  checkbox: PropTypes.bool,

  // TODO: explicitly spell out the shape of each column and each row.
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

TableBody.defaultProps = {
  checkbox: false
};

export default TableBody;
