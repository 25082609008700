import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import { TODO_ITEM_DETAILS} from '../fragments';
import { matchesTodoCriteria } from '../helpers';

const APPEND_TODO_ITEM_MUTATION = gql`
  mutation AppendTodoItem(
    $todoListId: ID!,
    $name: String!,
    $description: String,
    $assigneeId: ID,
    $dueDate: Date,
    $priority: TodoPriority
  ) {
    appendTodoItem(
      todoListId: $todoListId,
      name: $name,
      description: $description,
      assigneeId: $assigneeId,
      dueDate: $dueDate,
      priority: $priority
    ) {
      ...TodoItemDetails
    }
  }
  ${TODO_ITEM_DETAILS}
`;

export default function useAppendTodoItemMutation(options) {
  const todoListId = options.variables.todoListId;
  const todoCriteria = options.todoCriteria;
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  const todoListFragment = {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };

  return useMutation(APPEND_TODO_ITEM_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },

    update: (client, { data: { appendTodoItem: newItem }}) => {
      const todoList = client.readFragment(todoListFragment);
      const todoItems = todoList.todoItems;
      const found = todoItems.some(({ id }) => (id === newItem.id));

      if (found && matchesTodoCriteria(newItem, todoCriteria)) {
        // Found and also matches current criteria.
        return;
      } else if (found) {
        // Found but does not match the current criteria.
        client.writeFragment({
          ...todoListFragment,
          data: {
            ...todoList,
            todoItems: todoItems.filter(({ id }) => id !== newItem.id)
          }
        });
      } else if (matchesTodoCriteria(newItem, todoCriteria)) {
        // Not found yet but matches current criteria.
        client.writeFragment({
          ...todoListFragment,
          data: {
            ...todoList,
            todoItems: [...todoItems, newItem]
          }
        });
      }
    },

    ...options
  });
}
