import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    transitionProperty: 'visibility, opacity, transform',
    transitionDuration: '.2s',
    transitionTimingFunction: 'cubic-bezier(0.08, 0.52, 0.52, 1)'
  }
}));

const WithHeightLinearStep = React.forwardRef(
  function WithHeightLinearStep(props, ref) {
    const classes = useStyles();

    const {
      height,
      activeStep,
      index,
      onHeightChange,
      transitionDuration,
      children,
      className,
      style,
      ...otherProps
    } = props;

    const active = (index === activeStep);

    // Notify ancestor about my height if i'm currently the active step.
    React.useEffect(() => {
      if (active) onHeightChange(height);
    }, [height, onHeightChange, active]);

    const visibleTransform = 'translateX(0%) translateZ(1px)';

    const hiddenTransform =
      `translateX(${(index - activeStep) * 100}%) translateZ(1px)`;

    let finalStyle = {
      ...style,
      transitionDuration: transitionDuration || '.2s',
      opacity: active ? 1.0 : 0.0,
      visibility: active ? 'visible' : 'hidden',
      transform: active ? visibleTransform : hiddenTransform
    };

    const a11Props = {
      'aria-hidden': active ? false : true
    };

    return (
      <div
        ref={ref}
        className={clsx(classes.root, className)}
        style={finalStyle}
        {...a11Props}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
);

WithHeightLinearStep.propTypes = {
  height: PropTypes.number,
  activeStep: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onHeightChange: PropTypes.func,
  transitionDuration: PropTypes.string,
  children: PropTypes.node
};

export default WithHeightLinearStep;
