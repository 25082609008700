import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center'
  }
}))

export default function ListEmpty() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='h6' color='textSecondary'>
        No messages
      </Typography>
      <Typography variant='body1' color='textSecondary'>
        You don't have any messages in this project yet.
      </Typography>
    </div>
  );
}
