import React from 'react';
import PropTypes from 'prop-types';
import UploadStatusIconButton from './UploadStatusIconButton';
import ErrorIcon from '../../../icons/20x20/ErrorFill';
import RefreshIcon from '../../../icons/18x18/Refresh';

function UploadFailed({ onRetry }) {
  return (
    <UploadStatusIconButton
      icon={<ErrorIcon color='negative' />}
      hoverIcon={<RefreshIcon />}
      tooltip='Retry'
      onClick={onRetry}
    />
  );
}

UploadFailed.propTypes = {
  onRetry: PropTypes.func
};

export default UploadFailed;
