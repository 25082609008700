import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { NOTIFICATION_DETAILS_FRAGMENT } from './fragments';

const NOTIFICATIONS_QUERY = gql`
  query ListNotifications($workspaceId: ID!, $filter: NotificationFilter) {
    me {
      id
      notifications(workspaceId: $workspaceId, filter: $filter) {
        ...NotificationDetails
      }
    }
  }
  ${NOTIFICATION_DETAILS_FRAGMENT}
`;

export default function useLazyNotificationsQuery(options) {
  const { workspaceId } = useParams();
  const { filter, ...rest } = options;
  return useLazyQuery(NOTIFICATIONS_QUERY, {
    variables: { workspaceId, filter },
    ...rest
  });
}
