import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../button/IconButton';

const useStyles = makeStyles((theme) => ({
  toolbarIconButton: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

export default function ToolbarIconButton({ icon, ...rest }) {
  const classes = useStyles();
  return (
    <IconButton
      background={false}
      color='secondary'
      icon={icon}
      className={classes.toolbarIconButton}
      {...rest}
    />
  );
}
