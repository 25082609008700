import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ToolbarButton from './ToolbarButton';
import PersonIcon from '../../icons/12x12/Person';
import UserAvatar from './ToolbarButtonUserAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 192
  },
  invisible: {
    opacity: 0,
    cursor: 'default',
    pointerEvents: 'none'
  },
  label: {
    fontSize: '0.8125rem',
    fontWeight: 600
  },
}));

const FilterAssigneeButton = React.forwardRef(
  function FilterAssigneeButton(props, ref) {
    const { user, invisible, ...rest } = props;
    const classes = useStyles();

    const startIcon = Boolean(user) ? (
      <UserAvatar user={user} style={{filter: 'none'}} />
    ) : (
      <PersonIcon color='secondary' />
    );

    const color = Boolean(user) ? 'primary' : 'secondary';

    return (
      <ToolbarButton
        ref={ref}
        tabIndex={invisible ? -1 : 0}
        startIcon={startIcon}
        color={color}
        className={clsx(classes.root, {[classes.invisible]: invisible})}
        {...rest}
      >
        <Typography
          variant='body2'
          color='inherit'
          noWrap
          className={classes.label}
        >
          {user ? user.username : 'All assignees'}
        </Typography>
      </ToolbarButton>
    );
  }
);

FilterAssigneeButton.propTypes = {
  user: PropTypes.object,
  invisible: PropTypes.bool
};

FilterAssigneeButton.defaultProps = {
  invisible: false
};

export default FilterAssigneeButton;
