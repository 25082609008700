import React from 'react';
import PropTypes from 'prop-types';
import StyledTabs from './StyledTabs';
import StyledTab from './StyledTab';

const tabOptions = [
  {value: 'event', label: 'Event'},
  {value: 'task', label: 'Task'}
];

const tabValues = tabOptions.map(({ value }) => value);

function tabId(tabValue) {
  return `calendar-create-new-${tabValue}-tab`;
}

function tabPanelId(tabValue) {
  return `calendar-create-new-${tabValue}-tabpanel`;
}

function tabA11yProps(tabValue) {
  return {
    id: tabId(tabValue),
    'aria-controls': tabPanelId(tabValue)
  };
}

function tabpanelA11yProps(tabValue) {
  return {
    id: tabPanelId(tabValue),
    'aria-labelledby': tabId(tabValue)
  };
}

function Tabs({ activeTab, onChange }) {
  return (
    <StyledTabs value={activeTab} onChange={onChange}>
      {tabOptions.map(({ value, label }) => (
        <StyledTab
          key={value}
          value={value}
          label={label}
          {...tabA11yProps(value)}
        />
      ))}
    </StyledTabs>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.oneOf(tabValues).isRequired,
  onChange: PropTypes.func
};

export default Tabs;
export { tabValues, tabpanelA11yProps };
