import React from 'react';
import ProjectHeaderContainer from './ProjectHeaderContainer';
import { ProjectHeaderProjectAvatarSkeleton }
from './ProjectHeaderProjectAvatar';
import { ProjectHeaderProjectNameSkeleton }
from './ProjectHeaderProjectName';
import { ProjectHeaderProjectTeamSkeleton }
from './ProjectHeaderProjectTeam';
import AvatarGroupSkeleton from './ProjectHeaderAvatarGroupSkeleton';

function ProjectHeaderSkeleton() {
  return (
    <ProjectHeaderContainer>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 auto'
      }}>
        <ProjectHeaderProjectAvatarSkeleton />
        <div style={{
          flex: '1 1 auto',
          width: 0,
          marginRight: 16
        }}>
          <ProjectHeaderProjectNameSkeleton />
          <ProjectHeaderProjectTeamSkeleton />
        </div>
      </div>
      <AvatarGroupSkeleton />
    </ProjectHeaderContainer>
  );
}

export default ProjectHeaderSkeleton;
