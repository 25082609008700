import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CurrentUserQuery from './CurrentUserQuery';
import CurrentUserContext from './CurrentUserContext';

function PrivateRoute({ children, ...rest }) {
  return (
    <CurrentUserQuery>
      {(currentUser) => {
        return (
          <Route
            {...rest}
            render={({ location }) =>
              currentUser ? (
                <CurrentUserContext.Provider value={{
                  currentUserId: currentUser.id
                }}>
                  {children}
                </CurrentUserContext.Provider>
              ) : (
                <Redirect
                  to={{
                    pathname: "/sign-in",
                    state: { from: location }
                  }}
                />
              )
            }
          />
        )
      }}
    </CurrentUserQuery>
  );
}

export default PrivateRoute;
