import gql from 'graphql-tag';

export const COMMENT_AUTHOR = gql`
  fragment CommentAuthor on Comment {
    author {
      id
      username
      email
      avatar
    }
  }
`;

export const COMMENT_FILES = gql`
  fragment CommentFiles on Comment {
    files {
      id
      key
      name
      ext
      type
      size
      url
      previewUrl
      previewType
      downloadUrl

      creator {
        id
        username
        email
        avatar
      }

      updatedAt
    }
  }
`;
