import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    lineHeight: 1.333333,
    fontWeight: 600,
    pointerEvents: 'none'
  },
  labelStrikeThrough: {
    textDecoration: 'line-through'
  }
}));

function AllDayChipLabel({ label, strikeThrough }) {
  const classes = useStyles();
  return (
    <Typography
      noWrap
      variant='caption'
      className={clsx(classes.label, {
        [classes.labelStrikeThrough]: strikeThrough
      })}
    >
      {label}
    </Typography>
  );
}

AllDayChipLabel.propTypes = {
  label: PropTypes.string.isRequired,
  strikeThrough: PropTypes.bool
};

AllDayChipLabel.defaultProps = {
  strikeThrough: false
};

export default AllDayChipLabel;
