import React from 'react';
import PropTypes from 'prop-types';
import CardGridItem from '../ui/card/CardGridItem';
import UserCard from '../ui/card/UserCard';
import UserAvatar from '../ui/avatar/UserAvatar';
import { fromEmailToAvatarColor } from '../ui/avatar/colors';
import { formatMMMDYYYY } from '../../utils/helpers';

function InvitationGridItem({ invitation }) {
  const { receiverEmail, sender, sentAt } = invitation;
  return (
    <CardGridItem>
      <UserCard
        avatar={(
          <UserAvatar
            color={fromEmailToAvatarColor(receiverEmail)}
            username={receiverEmail}
          />
        )}
        title={receiverEmail}
        subtitle={`invited by ${sender.username}`}
        caption={`Sent ${formatMMMDYYYY(sentAt)}`}
      />
    </CardGridItem>
  );
}

InvitationGridItem.propTypes = {
  invitation: PropTypes.object.isRequired
};

export default React.memo(InvitationGridItem);
