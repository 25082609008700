import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../ui/progress/PageLoading';
import Error from '../oops/Error';

const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    me {
      id
    }
  }
`;

function CurrentUserQuery({ children }) {
  const { loading, error, data } = useQuery(GET_CURRENT_USER_QUERY);
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  return children(data.me);
}

CurrentUserQuery.propTypes = {
  children: PropTypes.func.isRequired
}

export default CurrentUserQuery;
export { GET_CURRENT_USER_QUERY };
