import React from 'react';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Error from './Error';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  section: {
    maxWidth: 456,
    borderRadius: 12,
    padding: theme.spacing(3),
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)'
  }
}));

function SectionError({ error }) {
  const classes = useStyles();
  return (
    <Container maxWidth='md' className={classes.root}>
      <Paper className={classes.section}>
        <Error error={error} />
      </Paper>
    </Container>
  );
}

SectionError.propTypes = {
  error: PropTypes.object
};

export default SectionError;
