import React from 'react';
import PropTypes from 'prop-types';
import ProjectHeaderProjectQuery from './ProjectHeaderProjectQuery';
import ProjectHeaderSkeleton from './ProjectHeaderSkeleton';
import ProjectHeader from './ProjectHeader';
import ProjectTabs from './ProjectTabs';
import ArchivedWarning from './ArchivedWarning';

// TODO: Refactor this!
function ProjectHeaderWithData({ projectId }) {
  const handleLoading = () => (
    <div>
      <ProjectHeaderSkeleton />
      <ProjectTabs isLoading />
    </div>
  );

  return (
    <ProjectHeaderProjectQuery
      projectId={projectId}
      onLoading={handleLoading }
    >
      {(project) => (
        <div>
          {project.archived && (
            <ArchivedWarning projectId={project.id} />
          )}
          <ProjectHeader project={project} />
          <ProjectTabs />
        </div>
      )}
    </ProjectHeaderProjectQuery>
  );
}

ProjectHeaderWithData.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectHeaderWithData;
