import React from 'react';
import PropTypes from 'prop-types';
import DialogLoading from '../../ui/dialog/DialogLoading';
import ProjectDetailsQuery from '../ProjectDetailsQuery';
import OptionDialogWithData from './OptionDialogWithData';
import { projectOption } from './OptionList';

function OptionDialog({ projectId, defaultOption }) {
  return (
    <ProjectDetailsQuery
      projectId={projectId}
      onLoading={() => <DialogLoading /> }
    >
      {(project) => (
        <OptionDialogWithData
          project={project}
          defaultOption={defaultOption}
        />
      )}
    </ProjectDetailsQuery>
  );
}

OptionDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  defaultOption: PropTypes.oneOf(Object.values(projectOption))
};

export default OptionDialog;
