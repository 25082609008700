import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import ChevronLeftIcon from '../../ui/icons/ChevronLeft';
import ChevronRightIcon from '../../ui/icons/ChevronRight';
import IconButton from '../../ui/button/IconButton';

const useStyles = makeStyles((theme) => ({
  monthNavigator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 1.5),
    marginBottom: theme.spacing(1)
  },
  buttonGroup: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  iconButton: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  }
}));

function MonthNavigator({ startOfMonth, onPrev, onNext }) {
  const classes = useStyles();

  const handlePrev = (event) => {
    if (onPrev) {
      onPrev(event);
    }
  };

  const handleNext = (event) => {
    if (onNext) {
      onNext(event);
    }
  };

  return (
    <div className={classes.monthNavigator}>
      <Typography variant='subtitle2'>
        {format(startOfMonth, 'LLLL yyyy')}
      </Typography>
      <div className={classes.buttonGroup}>
        <IconButton
          background={false}
          color='secondary'
          icon={ <ChevronLeftIcon />}
          className={classes.iconButton}
          onClick={handlePrev}
        />
        <IconButton
          background={false}
          color='secondary'
          icon={<ChevronRightIcon />}
          className={classes.iconButton}
          onClick={handleNext}
        />
      </div>
    </div>
  );
}

MonthNavigator.propTypes = {
  // Start of the current month
  startOfMonth: PropTypes.instanceOf(Date).isRequired,

  onPrev: PropTypes.func,
  onNext: PropTypes.func
};

export default MonthNavigator;
