import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from './AppBarTooltip';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

function SecondaryNavItem({ tooltipTitle, children }) {
  const classes = useStyles();
  return (
    <Tooltip title={tooltipTitle}>
      <div className={classes.root}>{children}</div>
    </Tooltip>
  );
}

SecondaryNavItem.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default SecondaryNavItem;
