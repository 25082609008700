import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PageTitle from './PageTitle';

export default function PageTitleSkeleton() {
  return (
    <PageTitle>
      <Skeleton
        variant='rect'
        width='60%'
        height={24}
        style={{borderRadius:8}}
      />
    </PageTitle>
  );
}
