import React from 'react';
import TaskNameCell from './TaskNameCell';
import AssigneeCell from './AssigneeCell';
import PriorityCell from './PriorityCell';
import DueDateCell from './DueDateCell';
import DescriptionCell from './DescriptionCell';
import HeaderCellContent from './HeaderCellContent';
import { fromFieldNameToFieldLabel } from '../helpers';

// Helper function to get the bounds for a given column.
// This will determine the resizing boundary for the column.
export function getColumnBounds(colName) {
  switch(colName) {
    case 'task_name':
      return { minWidth: 200, maxWidth: 600 };

    case 'assignee':
      return { minWidth: 256, maxWidth: 384};

    case 'due_date':
      return { minWidth: 152, maxWidth: 320};

    case 'priority':
      return { minWidth: 128, maxWidth: 256};

    case 'description':
      return { minWidth: 300, maxWidth: 600 };

    default:
      return null;
  }
}

// Given a column how to render its header cell.
export function renderHeaderCell(col) {
  return (
    <HeaderCellContent
      name={fromFieldNameToFieldLabel(col.name)}
    />
  );
}

// Mapping from column name to cell renderer for that column.
export const cellRendererForColumn = {
  'task_name': (cellProps, updateTodo) => (
    <TaskNameCell
      {...cellProps}
      onNameChange={(newName) => {
        updateTodo((prev) => ({...prev, name: newName}));
      }}
    />
  ),

  'assignee': ({ projectId, assignee }, updateTodo) => (
    <AssigneeCell
      projectId={projectId}
      value={assignee}
      onChange={(newAssignee) => {
        updateTodo((prev) => ({...prev, assignee: newAssignee}));
      }}
    />
  ),

  'priority': ({ priority }, updateTodo) => (
    <PriorityCell
      value={priority}
      onChange={(newPriority) => {
        updateTodo((prev) => ({...prev, priority: newPriority}));
      }}
    />
  ),

  'due_date': (cellProps, updateTodo) => (
    <DueDateCell
      value={cellProps.dueDate}
      onChange={(newDate) => {
        updateTodo((prev) => ({...prev, dueDate: newDate}));
      }}
    />
  ),

  'description': (cellProps, updateTodo) => (
    <DescriptionCell
      value={cellProps.description}
      onChange={(newDesc) => {
        updateTodo((prev) => ({...prev, description: newDesc}))
      }}
    />
  )
};

//
// TODO: Is this too dangerous and unnecessary, since who on earth would
// create a todo list with hundred of todo items in it?
//
// Helper functions to used with `React.memo` to prevent section with
// large number of todo items from re-rendering unnecessary.
//

// See `SheetWithRawData.js` to learn about the structure of
// these columns. We don't need to compare `show`, `renderCell` and
// `getCellProps`, since these are always equal?(REALLY?).
export function areTwoSectionColsEqual(col1, col2) {
  return col1.name === col2.name &&
         col1.width === col2.width;
}

// Compares two todo items for the same row.
export function areTwoSectionRowsEqual(row1, row2) {
  const sameAssignee =
    (!row1.assignee && !row2.assignee) ||
    (row1.assignee && row2.assignee &&
     row1.assignee.id === row2.assignee.id);

  return row1.id === row2.id &&
         row1.name === row2.name &&
         row1.description === row2.description &&
         row1.dueDate === row2.dueDate &&  // two date strings
         row1.priority === row2.priority &&
         row1.completed === row2.completed &&
         row1.rank === row2.rank &&
         sameAssignee;
}

// Are two array equal?
function __areTwoArraysEqual(arr1, arr2, isEqual) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  let foundMismatch = false;

  for (let i = 0; i < arr1.length; ++i) {
    foundMismatch = !isEqual(arr1[i], arr2[i]);
    if (foundMismatch) {
      break;
    }
  }

  return !foundMismatch;
}

// Are two cols arrays equal?
export function areTwoColsArraysEqual(arr1, arr2) {
  return __areTwoArraysEqual(arr1, arr2, areTwoSectionColsEqual);
}

// Are two rows arrays equal?
export function areTwoRowsArraysEqual(arr1, arr2) {
  return __areTwoArraysEqual(arr1, arr2, areTwoSectionRowsEqual);
}
