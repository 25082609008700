import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../ui/avatar/Avatar';
import {AVATAR_COLORS, AVATAR_DEFAULT_COLOR} from '../ui/avatar/colors';

function getAvatarLetters(name) {
  const trimmed = name.trim();
  const trimmedLength = trimmed.length;

  if (trimmedLength === 0) {
    return null;
  } else if (trimmedLength === 1) {
    return trimmed[0].toUpperCase();
  } else {
    const seps = /[\s.,-:;]+/;
    const words = trimmed.split(seps).filter((w) => w.trim().length > 0);
    let ch1 = '';
    let ch2 = '';

    if (words.length < 2) {
      ch1 = trimmed[0];
      ch2 = trimmed[1];
    } else {
      ch1 = words[0][0];
      ch2 = words[1][0];
    }

    return ch1.toUpperCase() + ch2.toLowerCase();
  }
}

function ProjectAvatar({ name, color, ...rest }) {
  const avatarColor = (AVATAR_COLORS.includes(color) && color) ||
                      AVATAR_DEFAULT_COLOR;
  return (
    <Avatar
      variant='rounded'
      size='large'
      color={avatarColor}
      {...rest}
    >
      {getAvatarLetters(name)}
    </Avatar>
  );
}

ProjectAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default React.memo(ProjectAvatar);
