import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { TODO_ITEM_DETAILS } from './fragments';
import { matchesNewSectionId } from './helpers';

const NEW_TODO_LIST_CREATED_SUBSCRIPTION = gql`
  subscription OnNewTodoListCreated($projectId: ID!) {
    newTodoListCreated(projectId: $projectId) {
      id
      name
      todoItems {
        ...TodoItemDetails
      }
    }
  }
  ${TODO_ITEM_DETAILS}
`;

function NewTodoListCreatedSubscriber({
  projectId,
  subscribeToNewTodoListCreated  // subscribeToMore result
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToNewTodoListCreated({
      document: NEW_TODO_LIST_CREATED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;

        const newList = subscriptionData.data.newTodoListCreated;
        let newTodoLists = [...prev.project.todoLists];

        // TODO: explain why.
        // If we didn't remove the optimistic response result from
        // the mutation, we would end up with two todolists in the
        // cache: one is the optimistic response result and
        // the other from the database (probably subscription result).
        if (
          newTodoLists.length &&
          matchesNewSectionId(newTodoLists[newTodoLists.length - 1].id)
        ) {
          newTodoLists.pop();
        }

        //
        // Check for existance.
        //

        const found = newTodoLists.some(({ id }) => (id === newList.id));

        if (!found) {
          newTodoLists = [...newTodoLists, newList];
        }

        return {
          project: {
            ...prev.project,
            todoLists: newTodoLists
          }
        };
      }
    });

    return () => unsubscribe();
  }, [projectId, subscribeToNewTodoListCreated]);

  return null;
}

NewTodoListCreatedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired,
  subscribeToNewTodoListCreated: PropTypes.func.isRequired
};

export default NewTodoListCreatedSubscriber;
