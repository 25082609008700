import { ApolloLink } from 'apollo-link';
import { hasSubscription } from '@jumpn/utils-graphql';
import socketLink from './socketLink';
import httpLink from './httpLink';

// Create a link that 'splits' requests based on GraphQL operation type.
// Queries and mutations go through the HTTP link.
// Subscriptions go through WebSocket link.
const link = new ApolloLink.split(
  operation => hasSubscription(operation.query),
  socketLink,
  httpLink
);

export default link;
