import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2, 2, 2),
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(2, 7.5, 7.5)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(2, 15, 7.5)
    }
  }
}));

export default function MessageBoardContent(props) {
  const classes = useStyles();
  return <div className={classes.content} {...props} />;
}
