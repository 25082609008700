import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { EditorState } from 'draft-js';
import InlineStyleControls from '../text-editor/InlineStyleControls';
import BlockStyleControls from '../text-editor/BlockStyleControls';

const useStyles = makeStyles((theme) => ({
  editorToolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(0.5, 6.5)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(0.5, 14)
    },

    // sticky
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper
  }
}))

function MessageEditorToolbar({
  editorState,
  onToggleInlineStyle,
  onToggleBlockType
}) {
  const classes = useStyles();
  return (
    <div className={classes.editorToolbar}>
      <InlineStyleControls
        editorState={editorState}
        onToggle={onToggleInlineStyle}
      />
      <BlockStyleControls
        editorState={editorState}
        onToggle={onToggleBlockType}
      />
    </div>
  );
}

MessageEditorToolbar.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onToggleInlineStyle: PropTypes.func.isRequired,
  onToggleBlockType: PropTypes.func.isRequired
};

export default MessageEditorToolbar;
