import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../ui/avatar/UserAvatar';
import { fromEmailToAvatarColor } from '../ui/avatar/colors';
import useSubscription from './hooks/useUserProfileChangedSubscription';

function UserAvatar({ user, ...rest }) {
  const { id, username, email, avatar } = user;

  // Only compute the color if avatar is null
  let color;
  if (!avatar) color = fromEmailToAvatarColor(email);

  // Subscribe to user profile changed event.
  useSubscription({ userId: id });

  return (
    <Avatar
      username={username}
      color={color}
      src={avatar}
      alt={username}
      {...rest}
    />
  );
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.string
  }).isRequired
};

export default React.memo(UserAvatar);
