import React from 'react';
import Autocomplete from '../../new-ui/Autocomplete';

const getOptionSelected = (option, value) => option.id === value.id
const getOptionLabel = (list) => list.name;

export default function ListAutocomplete({ inputProps, ...rest }) {
  return (
    <Autocomplete
      inputProps={{
        placeholder: 'Pick a list',
        ...inputProps
      }}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      {...rest}
    />
  );
}
