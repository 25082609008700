import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../../account/UserAvatar';
import UserCard from '../../ui/card/UserCard';
import MembershipCardAction from './MembershipCardAction';

function MembershipCard({ membership }) {
  const { user } = membership;
  return (
    <UserCard
      avatar={ <UserAvatar user={user} /> }
      title={user.username}
      subtitle={user.email}
      action={ <MembershipCardAction membership={membership} /> }
    />
  );
}

MembershipCard.propTypes = {
  membership: PropTypes.object.isRequired
};

export default MembershipCard;
