import { isTodo } from '../helpers';
import { useLocation, useRouteMatch, matchPath } from 'react-router-dom';

// Returns `true` if the given `schedule` matches the current schedule
// URL (see `useScheduleUrl`).
export default function useMatchCurrentScheduleUrl(schedule) {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const match = matchPath(pathname, {
    path: `${path}/:scheduleType/:scheduleId`,
    isExact: true
  });

  if (!match || !match.params) {
    return false;
  }

  const { scheduleType, scheduleId } = match.params;

  if (isTodo(schedule) && scheduleType === 'task') {
    return (schedule.id === scheduleId);
  }

  return (scheduleType === 'event') &&
         (scheduleId === schedule.id);
}
