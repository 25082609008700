import React from 'react';
import PropTypes from 'prop-types';
import NameInput from './NameInput';

function FocusAfterNameInput({ transitionDuration, ...rest }) {
  const inputRef = React.useRef();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, transitionDuration);

    return () => clearTimeout(timeout);
  }, [transitionDuration]);

  return (
    <NameInput
      inputRef={inputRef}
      autoFocus={false}
      {...rest}
    />
  );
}

FocusAfterNameInput.propTypes = {
  // miliseconds
  transitionDuration: PropTypes.number.isRequired
};

export default FocusAfterNameInput;
