import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const MOVE_TODO_ITEM_TO_ANOTHER_LIST_MUTATION = gql`
  mutation MoveTodoItemToAnotherList(
    $todoItemId: ID!,
    $todoListId: ID!,
    $index: Int!,
    $filter: TodoFilter
  ) {
    reorderTodoItem(
      todoItemId: $todoItemId,
      todoListId: $todoListId,
      index: $index,
      filter: $filter
    ) {
      todoItem { id }
      destination {
        index
        todoList {id name}
      }
    }
  }
`;

export default function useChangeListMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(MOVE_TODO_ITEM_TO_ANOTHER_LIST_MUTATION, {
    onError: (error) => {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
