import React from 'react';
import PropTypes from 'prop-types';
import SearchProjectInTeamQuery from './SearchProjectInTeamQuery';
import ProjectSearchCriteria from './ProjectSearchCriteria';
import ProjectsGridSkeleton from './ProjectsGridSkeleton';
import ProjectsGrid from './ProjectsGrid';

function SearchProjectInTeam({ teamId, archived }) {
  const [criteria, setCriteria] = React.useState({
    matching: undefined,
    myProjectOnly: false,
    archived: archived
  });

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  const renderSearchResult = (projects, otherQueryResults) => {
    return <ProjectsGrid projects={projects} {...otherQueryResults} />;
  };

  return (
    <>
      <ProjectSearchCriteria
        criteria={criteria}
        onChange={handleCriteriaChange}
      />
      <SearchProjectInTeamQuery
        teamId={teamId}
        criteria={criteria}
        onLoading={() => <ProjectsGridSkeleton /> }
      >
        {renderSearchResult}
      </SearchProjectInTeamQuery>
    </>
  );
}

SearchProjectInTeam.propTypes = {
  teamId: PropTypes.string.isRequired,
  archived: PropTypes.bool
};

SearchProjectInTeam.defaultProps = {
  archived: false
};

export default SearchProjectInTeam;
