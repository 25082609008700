import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  preview: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default function FileDetailsPreview(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.preview}
      {...props}
    />
  );
}
