import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import FormatSizeIcon from '../../icons/20x20/FormatSize';
import FormatQuoteIcon from '../../icons/20x20/FormatQuote';
import FormatCodeIcon from '../../icons/20x20/FormatCode';
import FormatListNumberedIcon from '../../icons/20x20/FormatListNumbered';
import FormatListBulletedIcon from '../../icons/20x20/FormatListBulleted';
import ControlButton from './ControlButton';

const BLOCK_TYPES = [
  {
    icon: <FormatSizeIcon />,
    style: 'header-one'
  },
  {
    icon: <FormatQuoteIcon />,
    style: 'blockquote'
  },
  {
    icon: <FormatCodeIcon />,
    style: 'code-block'
  },
  {
    icon: <FormatListBulletedIcon />,
    style: 'unordered-list-item'
  },
  {
    icon: <FormatListNumberedIcon />,
    style: 'ordered-list-item'
  }
];

function BlockStyleControls({ editorState, onToggle }) {
  const selection = editorState.getSelection();

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div>
      {BLOCK_TYPES.map(({ icon, style }) => (
        <ControlButton
          key={style}
          active={style === blockType}
          icon={icon}
          onMouseDown={(event) => {
            event.preventDefault();
            onToggle(style)
          }}
        />
      ))}
    </div>
  );
}

BlockStyleControls.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onToggle: PropTypes.func.isRequired
};

export default BlockStyleControls;
