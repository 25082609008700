import React from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headCell: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: 0,
    height: 40,
    padding: theme.spacing(0, 2),
    left: 'auto',
    '&:not(:last-of-type)': {
      borderRight: '1px solid  #DADDE1'
    }
  },
  checkbox: {
    width: 48,
    paddingLeft: 6,
    paddingRight: 6
  }
}));

export default function HeadCell({ checkbox, ...rest }) {
  const classes = useStyles();
  return (
    <TableCell
      className={clsx(classes.headCell, {
        [classes.checkbox]: checkbox
      })}
      {...rest}
    />
  );
}
