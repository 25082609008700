import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { TEAM_DETAILS_FRAGMENT } from '../fragments';

const TEAM_UPDATED_SUBSCRIPTION = gql`
  subscription OnTeamUpdated($teamId: ID!) {
    teamUpdated(teamId: $teamId) {
      ...TeamDetails
    }
  }
  ${TEAM_DETAILS_FRAGMENT}
`;

export default function useTeamUpdatedSubscription(options) {
  const { teamId, ...otherOptions } = options;

  return useSubscription(TEAM_UPDATED_SUBSCRIPTION, {
    variables: { teamId },
    ...otherOptions
  });
}
