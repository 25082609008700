import React from 'react';
import { addDays, isSameDay, format } from 'date-fns';
import DragDropProvider from './ui/MonthGridDragDropProvider';
import { useEventsListContext } from './EventsListProvider';
import { distanceBetweenTwoDays, isEvent, isTodo } from './helpers';
import useUpdateEventTime from './mutations/useUpdateEventTime';
import useUpdateTodoDueDate from './mutations/useUpdateTodoDueDate';

export default function MonthGridDragDropProvider(props) {
  const { setEvents } = useEventsListContext();

  // The `srcEvent` was being dragged from the original cell (start) to
  // the destination cell (end).
  const handleChange = React.useCallback((srcEvent, { start, end }) => {
    const delta = distanceBetweenTwoDays(end, start);
    return {
      startsAt: addDays(srcEvent.startsAt, delta),
      endsAt: addDays(srcEvent.endsAt, delta)
    };
  }, []);

  //
  // GraphQL mutation.
  //

  const [updateEventTime] = useUpdateEventTime();
  const [updateTodoDueDate] = useUpdateTodoDueDate();

  const handleDragEnd = React.useCallback((event, { startsAt, endsAt }) => {
    if (isEvent(event)) {
      updateEventTime(event, { startsAt, endsAt });
    } else if (isTodo(event) && isSameDay(startsAt, endsAt)) {
      const newDueDate = format(startsAt, 'yyyy-MM-dd');
      updateTodoDueDate(event, newDueDate);
    }
  }, [updateEventTime, updateTodoDueDate]);

  return (
    <DragDropProvider
      dispatchEvents={setEvents}
      onChange={handleChange}
      onDragEnd={handleDragEnd}
      {...props}
    />
  );
}
