import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PageAvatarGroup from './PageAvatarGroup';

export default function PageAvatarGroupSkeleton() {
  return (
    <PageAvatarGroup>
      <Skeleton variant='circle' />
      <Skeleton variant='circle' />
      <Skeleton variant='circle' />
      <Skeleton variant='circle' />
    </PageAvatarGroup>
  );
}
