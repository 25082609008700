import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { CHIP_DEFAULT_COLOR } from './constants';

// Some style constants
const CHIP_HEIGHT = 18;
const HALF_CHIP_HEIGHT = CHIP_HEIGHT / 2;
const CHIP_CONTINUOUS_WIDTH = 6/* depends on CHIP_HEIGHT */;

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    flex: '1 1 auto',
    textAlign: 'left',
    justifyContent: 'flex-start',
    width: `calc(100% - ${CHIP_CONTINUOUS_WIDTH}px)`,
    height: CHIP_HEIGHT,
    padding: theme.spacing(0, 0.5),
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    borderRadius: theme.shape.borderRadius
  },

  chipColor: ({ color }) => {
    try {
      return {
        backgroundColor: color,
        color: theme.palette.getContrastText(color)
      };
    } catch (e) {
      // If we failed to get the text color from the given color, we return
      // the default style instead.
      return {
        backgroundColor: CHIP_DEFAULT_COLOR,
        color: theme.palette.getContrastText(CHIP_DEFAULT_COLOR)
      };
    }
  },

  leftContinuous: ({ color }) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: HALF_CHIP_HEIGHT,
    width: `calc(100% - ${HALF_CHIP_HEIGHT + CHIP_CONTINUOUS_WIDTH}px)`,
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: -HALF_CHIP_HEIGHT,
      bottom: 0,
      width: HALF_CHIP_HEIGHT,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderTopWidth: HALF_CHIP_HEIGHT,
      borderBottomWidth: HALF_CHIP_HEIGHT,
      borderRightWidth: CHIP_CONTINUOUS_WIDTH,
      borderRightColor: color
    }
  }),

  rightContinuous: ({ color }) => ({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: -HALF_CHIP_HEIGHT,
      bottom: 0,
      width: HALF_CHIP_HEIGHT,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderTopWidth: HALF_CHIP_HEIGHT,
      borderBottomWidth: HALF_CHIP_HEIGHT,
      borderLeftWidth: CHIP_CONTINUOUS_WIDTH,
      borderLeftColor: color
    }
  }),

  focus: {
    boxShadow: theme.shadows[8]
  },

  startIcon: {
    fontSize: '0.875rem',
    marginRight: theme.spacing(0.5)
  },

  label: {
    lineHeight: 1.333333,
    fontWeight: 600
  },
  labelStrikeThrough: {
    textDecoration: 'line-through'
  }
}));

function AllDayScheduleChip({
  label,
  startIcon,
  color,
  strikeThrough,
  leftContinuous,
  rightContinuous,
  focus,
  ...rest
}) {
  const classes = useStyles({ color: color || CHIP_DEFAULT_COLOR });

  return (
    <ButtonBase
      component='div'
      className={clsx(classes.chipRoot, classes.chipColor, {
        [classes.leftContinuous]: leftContinuous,
        [classes.rightContinuous]: rightContinuous,
        [classes.focus]: focus
      })}
      {...rest}
    >
      {startIcon && React.cloneElement(startIcon, {
        className: classes.startIcon
      })}
      <Typography
        noWrap
        variant='caption'
        className={clsx(classes.label, {
          [classes.labelStrikeThrough]: strikeThrough
        })}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
}

AllDayScheduleChip.propTypes = {
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.node,
  color: PropTypes.string,
  strikeThrough: PropTypes.bool,
  leftContinuous: PropTypes.bool,
  rightContinuous: PropTypes.bool,
  focus: PropTypes.bool
};

AllDayScheduleChip.defaultProps = {
  color: CHIP_DEFAULT_COLOR,
  strikeThrough: false,
  leftContinuous: false,
  rightContinuous: false,
  focus: false
};

export default AllDayScheduleChip;
