import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Header from '../../ui/todo/details/Header';
import HeaderToolbar from '../../ui/todo/details/HeaderToolbar';
import ToolbarButton from '../../ui/todo/details/HeaderToolbarButton';
import CloseIcon from '../../ui/icons/CloseMui';
import Content from '../../ui/todo/details/Content';
import Button from '../../ui/button/Button';

function TodoNotFound({ onClose }) {
  return (
    <React.Fragment>
      <Header>
        <div style={{flexGrow: 1}} />
        <HeaderToolbar>
          <ToolbarButton
            background={false}
            aria-label='close'
            icon={ <CloseIcon /> }
            onClick={onClose}
          />
        </HeaderToolbar>
      </Header>
      <Content>
        <Typography variant='h6' gutterBottom>
          Sorry, we can't seem to find the task you're looking for.
        </Typography>
        <DialogContentText style={{marginBottom: 40}}>
          This could be because the task you're looking for has been
          deleted or you don't have access to it. Or it could be because
          you clicked a broken link.
        </DialogContentText>
        <Button
          fullWidth
          size='small'
          color='primary'
          onClick={onClose}
        >
          Close
        </Button>
      </Content>
    </React.Fragment>
  );
}

TodoNotFound.propTypes = {
  onClose: PropTypes.func
};

export default TodoNotFound;
