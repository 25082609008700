import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../ui/todo/sheet/DatePicker';

function DueDateCell({ value, onChange }) {
  return (
    <DatePicker
      placeholder='Set due date'
      format='dd/MM/yyyy'
      value={value}
      onChange={onChange}
    />
  );
}

DueDateCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  onChange: PropTypes.func
};

export default DueDateCell;
