import React from 'react';
import Dialog from './StyledDialog';
import DialogContext from './DialogContext';

const DIALOG_ACTION = Object.freeze({
  'OPEN': 1,
  'CLOSE': 2
});

const INITIAL_STATE = Object.freeze({
  open: false,
  dialogProps: {},
  dialogContent: null
});

function dialogReducer(state, action) {
  const { type, payload } = action;
  switch(type) {
    case DIALOG_ACTION.OPEN:
      return { open: true, ...payload };
    case DIALOG_ACTION.CLOSE:
      return INITIAL_STATE;
    default:
      return state;
  }
}

function DialogProvider({ children }) {
  const [state, dispatch] = React.useReducer(dialogReducer, INITIAL_STATE);
  const { open, dialogProps, dialogContent } = state;

  // This optimization is REALLY IMPORTANT, without this all components
  // that consumes `DialogContext` will re-render every time
  // the `state` changes (e.g via `openDialog` by clicking some button)
  const ctx = React.useMemo(() => ({
    openDialog: function(data) {
      const payload = {
        dialogProps: data.dialogProps || {},
        dialogContent: data.dialogContent || null
      };
      dispatch({ type: DIALOG_ACTION.OPEN, payload });
    },

    closeDialog: function() {
      dispatch({type: DIALOG_ACTION.CLOSE});
    }
  }), [dispatch]);

  return (
    <DialogContext.Provider value={ctx}>
      {children}
      <Dialog
        {...dialogProps}
        open={open}
        onClose={ctx.closeDialog}
      >
        {dialogContent}
      </Dialog>
    </DialogContext.Provider>
  );
}

export default DialogProvider;
