import React from 'react';
import MyFavorites from '../project/favorites/MyFavorites';
import RecentProjects from '../project/most-recent/MyMostRecentProjects';

export default function Home() {
  return (
    <div>
      <MyFavorites />
      <RecentProjects />
    </div>
  );
}
