import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '../../ui/svg-icons/Clear';
import IconButton from '../../ui/button/IconButton';

const useStyles = makeStyles((theme) => ({
  reminderItem: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  deleteButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.text.secondary
  }
}));

function StyledReminderItem({ children, onDelete }) {
  const classes = useStyles();
  return (
    <div className={classes.reminderItem}>
      {children}
      {onDelete && (
        <IconButton
          background={false}
          className={classes.deleteButton}
          icon={<ClearIcon fontSize='small' color='inherit' /> }
          onClick={onDelete}
        />
      )}
    </div>
  );
}

StyledReminderItem.propTypes = {
  children: PropTypes.node,
  onDelete: PropTypes.func
};

export default StyledReminderItem;
