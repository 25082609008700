import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DialogTitle from '../ui/dialog/CenteredDialogTitle';
import DialogContent from '../ui/dialog/DialogContent';
import DialogActions from '../ui/dialog/DialogActions';
import DialogContext from '../ui/dialog/DialogContext';
import ConfirmText from '../ui/message/DeleteConfirmText';
import ConfirmCheckbox from '../ui/message/DeleteConfirmCheckbox';
import CancelButton from '../ui/message/DeleteConfirmCancelButton';
import DeleteButton from '../ui/message/DeleteConfirmDeleteButton';
import useDeleteMessageMutation from './useDeleteMessageMutation';

function DeleteConfirmDialog({ id, subject, messageBoardUrl }) {
  const { closeDialog } = React.useContext(DialogContext);
  const [yes, setYes] = React.useState(false);
  const history = useHistory();

  const handleYesChange = (event) => {
    setYes(event.target.checked);
  };

  const [deleteMessage, { loading }] = useDeleteMessageMutation({
    variables: { messageId: id },
    onCompleted: (data) => {
      closeDialog();
      history.push(messageBoardUrl);
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Delete message?
      </DialogTitle>

      <DialogContent>
        <ConfirmText subject={subject} />
        <ConfirmCheckbox value={yes} onChange={handleYesChange} />
      </DialogContent>

      <DialogActions>
        <CancelButton
          disabled={loading}
          onClick={closeDialog}
        />
        <DeleteButton
          loading={loading}
          disabled={!yes || loading}
          onClick={deleteMessage}
        />
      </DialogActions>
    </React.Fragment>
  );
}


DeleteConfirmDialog.propTypes = {
  id: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  messageBoardUrl: PropTypes.string.isRequired
};

export default DeleteConfirmDialog;
