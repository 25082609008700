import { TIMELINE, timelineIntervalSize } from './constants';
import findTimeIndex from './findTimeIndex';

const _intervalSize = timelineIntervalSize.PIXELS;

// Returns the top (in pixels, relative to the top of the timeline) and
// the height (in pixels) of the given time interval.
export default function getTimeIntervalTopHeight({ start, end }) {
  const startIndex = findTimeIndex(start, TIMELINE);
  const endIndex = findTimeIndex(end, TIMELINE);

  // TODO: what if startIndex < 0 and/or endIndex < 0
  const rowIndex = startIndex;
  const rowSpan = (endIndex - startIndex);

  return {
    top: _intervalSize * rowIndex,
    height: _intervalSize * rowSpan,
    rowSpan
  };
}
