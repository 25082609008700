import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import PersonPlusIcon from '../../icons/20x20/PersonPlus';
import AddMembers from '../dialogs/AddMembers';

const AddMembersItem = React.forwardRef((props, ref) => {
  const { projectId, ...other } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <AddMembers projectId={projectId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Add new members'
      icon={ <PersonPlusIcon /> }
      onClick={handleClick}
      {...other}
    />
  );
});

AddMembersItem.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default AddMembersItem;
