import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { USER_PROFILE_FRAGMENT } from '../fragments';

const USER_PROFILE_CHANGED_SUBSCRIPTION = gql`
  subscription OnUserProfileChanged($userId: ID!) {
    userProfileChange(userId: $userId) {
      ...UserProfile
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export default function useUserProfileChangedSubscription(options) {
  const { userId, ...rest } = options;
  return useSubscription(USER_PROFILE_CHANGED_SUBSCRIPTION, {
    variables: { userId },
    ...rest
  });
}
