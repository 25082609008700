import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import EventIcon from '../../../icons/16x16/Calendar';
import { FILTER_PRIMARY } from '../../../icons/Base';
import CellInput from './CellInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 8,
    boxShadow: theme.customShadow.menu,
    marginTop: 1
  },
  input: {
    '&:hover $eventButton': {
      display: 'flex'
    },
    '&.Mui-focused $eventButton': {
      display: 'flex'
    }
  },
  highlightInputWhenOpen: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '& $eventButton': {
      display: 'flex'
    }
  },
  eventButton: {
    display: 'none',
    padding: 8,
    '&:hover $eventIcon': {
      filter: FILTER_PRIMARY
    }
  },
  eventIcon: {}
}));

export default function StyledDatePicker(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleOpen = () => {
    // Transfer focus from (triggered) button to input before
    // open the modal so that restore focus will work as expected.
    inputRef.current.focus();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        format='MM/dd/yyyy'
        PopoverProps={{
          // Keep the focus within row when this shit is open.
          disablePortal: true,
          BackdropProps: {
            tabIndex: -1,
            invisible: true
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          classes: {
            paper: classes.paper
          }
        }}
        TextFieldComponent={CellInput}
        InputProps={{
          className: clsx(classes.input, {
            [classes.highlightInputWhenOpen]: open
          })
        }}
        inputProps={{
          // We have to make the input as the last focused item before
          // modal is open, so that restoreFocus will restore the focus
          // back to the input when it's closed rather than the
          // triggered button.
          ref: inputRef
        }}
        keyboardIcon={(
          <EventIcon
            color='secondary'
            className={classes.eventIcon}
          />
        )}
        KeyboardButtonProps={{
          className: classes.eventButton,

          // Place focus on this button whenever it's clicked
          // [1] - https://zellwk.com/blog/inconsistent-button-behavior/
          tabIndex: -1,
          component: 'div'
        }}
        onOpen={handleOpen}
        onClose={handleClose}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
