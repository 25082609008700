import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../button/Button';

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    fontSize: '0.8125rem',
    fontWeight: 600,
    padding: '5px 10px',
    flex: '0 0 auto'
  }
}));

const ToolbarButton = React.forwardRef(
  function ToolbarButton({className, ...rest}, ref) {
    const classes = useStyles();
    return (
      <Button
        ref={ref}
        variant='text'
        className={clsx(classes.toolbarButton, className)}
        {...rest}
      />
    );
  }
);

export default ToolbarButton;
