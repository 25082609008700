import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import PersonRemoveIcon from '../../ui/icons/PersonRemoveOutline';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import DeactivateOtherMembershipConfirm
from '../dialogs/DeactivateOtherMembershipConfirm';

const DeactivateMembershipMenuItem = React.forwardRef((props, ref) => {
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);
  const { membershipId, username, ...rest } = props;

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: (
        <DeactivateOtherMembershipConfirm
          membershipId={membershipId}
          username={username}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Deactivate membership'
      secondary='Remove this user from your workspace.'
      icon={ <PersonRemoveIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

DeactivateMembershipMenuItem.propTypes = {
  membershipId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default DeactivateMembershipMenuItem;
