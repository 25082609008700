import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../ui/menu/Menu';
import MenuItemDivider from '../../ui/menu/MenuItemDivider';
import EditTeamMenuItem from './EditTeamMenuItem';
import AddProjectMenuItem from './AddProjectMenuItem';
import AddMembersMenuItem from './AddMembersMenuItem';
import DeleteTeamMenuItem from './DeleteTeamMenuItem';

function TeamCardMenu({ teamId, ...other }) {
  // TODO: Is this shit ok?
  // Prevent the click event from propagating to the card parent.
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: 192
        }
      }}
      onClick={handleClick}
      {...other}
    >
      <EditTeamMenuItem teamId={teamId} />
      <MenuItemDivider />
      <AddProjectMenuItem teamId={teamId} />
      <AddMembersMenuItem teamId={teamId} />
      <MenuItemDivider />
      <DeleteTeamMenuItem teamId={teamId} />
    </Menu>
  );
}

TeamCardMenu.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default TeamCardMenu;
