import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';

// Only need to change height, say 20 to make it bigger
const height = 16;
const padding = 2;
const thumbSize = height - 2 * padding;
const width = 2 * (thumbSize + padding);

const Switch = withStyles((theme) => ({
  root: {
    width: width,
    height: height,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: padding,
    color: '#fff',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:hover + $track': {
      borderColor: '#8F949D'
    },
    '&$checked': {
      transform: `translateX(${thumbSize}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      }
    },
    '&.Mui-focusVisible': {
      outline: 0
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: `${thumbSize / 4}px solid #fff`,
    },
  },
  thumb: {
    width: thumbSize,
    height: thumbSize,
    boxShadow: 'none'
  },
  track: {
    border: '1px solid #CFD1D5',
    borderRadius: height / 2,
    opacity: 1,
    backgroundColor: '#EFF0F1'
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => (
  <MuiSwitch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

export default Switch;
