import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commentListContainer: {
    marginTop: theme.spacing(1),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  }
}));

export default function CommentListContainer(props) {
  const classes = useStyles();
  return <div className={classes.commentListContainer} {...props} />;
}
