import React from 'react';
import Section from '../../home/Section';
import SectionHeader from '../../home/SectionHeader';
import SectionBody from '../../home/SectionBody';
import MyMostRecentProjectsQuery from './MyMostRecentProjectsQuery';
import ProjectGrid from './ProjectGrid';
import ProjectGridSkeleton from './ProjectGridSkeleton';

function MyMostRecentProjects() {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <Section>
      <SectionHeader
        expanded={expanded}
        onToggleExpanded={() => setExpanded(!expanded)}
        title='Recent Projects'
      />

      {expanded && (
        <SectionBody>
          <MyMostRecentProjectsQuery
            onLoading={() => <ProjectGridSkeleton />}
            fetchPolicy='cache-and-network'
          >
            {(projects) => (
              <ProjectGrid projects={projects} />
            )}
          </MyMostRecentProjectsQuery>
        </SectionBody>
      )}
    </Section>
  );
}

export default MyMostRecentProjects;
