import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  allDayScheduleRoot: {
    minHeight: 16,
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: 2
    }
  }
}));

export default function AllDaySchedule(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.allDayScheduleRoot}
      {...props}
    />
  );
}
