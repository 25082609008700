import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionBody: {
    padding: theme.spacing(3, 1)
  }
}));

export default function SectionBody(props) {
  const classes = useStyles();
  return <div className={classes.sectionBody} {...props} />;
}
