import React from 'react';
import PropTypes from 'prop-types';
import SectionNameInput from './SectionNameInput';
import SectionNameButton from './SectionNameButton';

function SectionName({ value, onChange, editMode, onEditModeChange }) {
  //
  // Capture value as our internal state.
  //

  const [inputValue, setInputValue] = React.useState(value);

  const handleInputValueChange = (event) => {
    setInputValue(event.target.value)
  };

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  //
  // Turn editMode on.
  //

  const handleEditModeOn = () => {
    if (onEditModeChange) {
      onEditModeChange(true);
    }
  };

  const handleButtonClick = () => {
    handleEditModeOn();
  };

  const handleButtonKeyDown = (event) => {
    if (
      event.key === ' ' ||
      event.key === 'Enter' ||
      event.key === 'Spacebar' // for IE11 support
    ) {
      event.preventDefault();
      handleEditModeOn();
    }
  };

  //
  // Turn off editMode.
  //

  const handleEditModeOff = () => {
    if (onEditModeChange) {
      onEditModeChange(false);
    }

    if (onChange) {
      onChange(inputValue);
    }
  };

  const handleInputBlur = () => {
    handleEditModeOff();
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Escape' || event.key === 'Enter' ) {
      event.preventDefault();
      handleEditModeOff();
    }
  };

  return editMode ? (
    <SectionNameInput
      autoFocus
      value={inputValue}
      onChange={handleInputValueChange}
      onBlur={handleInputBlur}
      onKeyDown={handleInputKeyDown}
    />
  ) : (
    <SectionNameButton
      label={value}
      onClick={handleButtonClick}
      onKeyDown={handleButtonKeyDown}
    />
  );
}

SectionName.propTypes = {
  // The name of the section
  value: PropTypes.string.isRequired,

  // callback to execute when input got blur:
  // (newInputValue) => {...}
  onChange: PropTypes.func,

  // Show input instead of button
  editMode: PropTypes.bool,

  // (newEditMode) => {...}
  onEditModeChange: PropTypes.func,
};

SectionName.defaultProps = {
  editMode: false
};

export default SectionName;
