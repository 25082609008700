import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../ui/progress/PageLoading';
import Error from '../oops/Error';

const GET_MY_MOST_RECENT_WORKSPACE = gql`
  query GetMyMostRecentWorkspace {
    me {
      id
      workspaceMemberships(limit: 1) {
        id
        workspace {
          id
        }
      }
    }
  }
`;

function RecentWorkspaceQuery({ children }) {
  const {loading, error, data} = useQuery(GET_MY_MOST_RECENT_WORKSPACE);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const memberships = data.me.workspaceMemberships;
  const mostRecentWorkspace = memberships.length === 0 ? null :
                              memberships[0].workspace;
  return children(mostRecentWorkspace);
}

RecentWorkspaceQuery.propTypes = {
  children: PropTypes.func.isRequired
};

export default RecentWorkspaceQuery;
