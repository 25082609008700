import React from 'react';
import JoinOrLeaveButton from './JoinOrLeaveButton';
import BellIcon from '../../icons/18x18/BellFill';

export default function UnfollowMessageButton(props) {
  const tooltip = 'Stop getting notifications about activity on this message.';
  return (
    <JoinOrLeaveButton
      label='Unsubscribe'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
