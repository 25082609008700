import React from 'react';
import Dialog from '@material-ui/core/Dialog';

const DialogContext = React.createContext(null);
const dialogAction = Object.freeze({
  'OPEN': 1,
  'CLOSE': 2
});
const initialState = Object.freeze({
  open: false,
  dialogProps: {},
  dialogContent: null
});

function reducer(state, action) {
  switch(action.type) {
    case dialogAction.OPEN:
      return { open: true, ...action.payload };
    case dialogAction.CLOSE:
      return initialState;
    default:
      return state;
  }
}

// README: The reasons why we need a separate DialogProvider for our
// Calendar instead of using the global one are:
// 1. The global DialogProvider sucks (TODO)
// 2. Sometimes we need to use react-router-dom `useParams` to get the
//    current projectId for example - this won't  work with global
//    DialogProvider.
function DialogProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { open, dialogProps, dialogContent } = state;

  // This optimization is REALLY IMPORTANT, without this all components
  // that consumes `DialogContext` will re-render every time
  // the `state` changes (e.g via `openDialog` by clicking some button)
  const ctx = React.useMemo(() => ({
    openDialog: function(data) {
      const payload = {
        dialogProps: data.dialogProps || {},
        dialogContent: data.dialogContent || null
      };
      dispatch({ type: dialogAction.OPEN, payload });
    },

    closeDialog: function() {
      dispatch({type: dialogAction.CLOSE});
    }
  }), [dispatch]);

  return (
    <DialogContext.Provider value={ctx}>
      {children}
      <Dialog
        {...dialogProps}
        open={open}
        onClose={ctx.closeDialog}
      >
        {dialogContent}
      </Dialog>
    </DialogContext.Provider>
  );
}

export default DialogProvider;
export function useDialogContext() {
  return React.useContext(DialogContext);
}
