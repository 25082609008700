import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '../../icons/12x12/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function DropDownIcon({ className, ...rest }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.iconRoot, className)} {...rest}>
      <ArrowDropDownIcon color='secondary' />
    </div>
  );
}
