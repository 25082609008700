import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../../../ui/button/IconButton';

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    minWidth: theme.spacing(4)
  }
}))

function ToolbarIconButton({ icon, ...rest }) {
  const classes = useStyles();
  return (
    <IconButton
      square
      background={false}
      color='secondary'
      className={classes.toolbarButton}
      icon={icon}
      {...rest}
    />
  );
}

ToolbarIconButton.propTypes = {
  icon: PropTypes.node.isRequired
};

export default ToolbarIconButton;
