import React from 'react';
import PropTypes from 'prop-types';
import TodosInProjectQuery from './TodosInProjectQuery';
import { DEFAULT_COMPLETION_TYPE } from '../CompletionMenu';
import { DEFAULT_DUE_DATE_RANGE } from '../DueDateFilter';
import Toolbar from './Toolbar';
import SheetContainer from '../../ui/todo/sheet/SheetContainer';
import SheetSkeleton from '../../ui/todo/sheet/SheetSkeleton';
import SheetWithRawData from './SheetWithRawData';

// TODO:
//
// 1. Resizing and toggling columns on/off are UNACCEPTABLY SLOW
//    for sheets whose number of rows are quite large.
//
// 2. The current way used to update the cache data after subscription
//    and/or mutation are kind of copy-and-paste. REFACTOR THAT!
//
// 3. Prevent rows from re-rendering unnecessary when its index changed.
//
// 4. The way we currently fixed position the header cells are
//    EXTREMMELY TRICKY.
//
// 5. Use virtualizing or windowing techniques to optimize sheets with
//    large number of rows.
//
// 6. There are something not quite right with `SheetContainer`!
function TodoSheet({ projectId, rootUrl }) {
  const [criteria, setCriteria] = React.useState({
    completionType: DEFAULT_COMPLETION_TYPE,
    assignee: null,
    dueDateBetween: DEFAULT_DUE_DATE_RANGE
  });

  const searchCriteria = {
    completionType: criteria.completionType,
    assigneeId: criteria.assignee ? criteria.assignee.id : undefined,
    dueDateBetween: criteria.dueDateBetween
  };

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  return (
    <>
      <Toolbar
        projectId={projectId}
        criteria={criteria}
        onChange={handleCriteriaChange}
      />

      <SheetContainer>
        <TodosInProjectQuery
          projectId={projectId}
          criteria={searchCriteria}
          fetchPolicy='network-only'
          onLoading={() => <SheetSkeleton />}
        >
          {({ todoLists, todoSheetColumns }, otherQueryResults) => (
            <SheetWithRawData
              projectId={projectId}
              rootUrl={rootUrl}
              todoCriteria={criteria}
              rawColumns={todoSheetColumns}
              rawSections={todoLists}
              {...otherQueryResults}
            />
          )}
        </TodosInProjectQuery>
      </SheetContainer>
    </>
  );
}

TodoSheet.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default TodoSheet;
