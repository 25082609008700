import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const FOLLOW_TODO_MUTATION = gql`
  mutation FollowTodo($todoId: ID!) {
    followTodo(todoId: $todoId) {
      id
    }
  }
`;

export default function useFollowTodoMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(FOLLOW_TODO_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
