import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CommentsIcon from '../../../../icons/16x16/Bubbles';
import ContentField from './ContentField';

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: '28px'
  }
}));

function CommentsSection({ children }) {
  const classes = useStyles();
  return (
    <ContentField icon={<CommentsIcon />}>
      <Typography variant='body2' className={classes.title}>
        Comments
      </Typography>
      {children}
    </ContentField>
  );
}

CommentsSection.propTypes = {
  children: PropTypes.node
};

export default CommentsSection;
