import React from 'react';
import PropTypes from 'prop-types';
import StyledHeader from '../../ui/todo/sheet/StyledHeader';
import HeaderCell from './HeaderCell';
import { getColumnBounds, renderHeaderCell } from './helpers';

function Header({ projectId, rawColumns }) {

  const optimisticResponse = ({ name }, newWidth) => {
    return rawColumns.map((c) => {
      if (c.name !== name ) return c;
      return {...c, width: newWidth}
    });
  };

  const columns = rawColumns.filter(({ show }) => (
    show === true
  )).map((col) => ({
    ...col,
    ...getColumnBounds(col.name),
    renderHeaderCell,
  }));

  return (
    <StyledHeader>
      {columns.map((col, idx) => (
        <HeaderCell
          key={col.name}
          col={col}
          contentPaddingLeft={idx !== 0}
          projectId={projectId}
          optimisticResponseUponColumnResize={optimisticResponse}
        />
      ))}
    </StyledHeader>
  );
}

Header.propTypes = {
  projectId: PropTypes.string.isRequired,
  rawColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    show: PropTypes.bool
  })).isRequired
};

export default Header;
