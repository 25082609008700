import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from './CurrentWorkspaceContext';
import Error from '../oops/Error';

const CURRENT_WORKSPACE_NAME_QUERY = gql`
  query GetCurrentWorkspaceName($currentWorkspaceId: ID!) {
    workspace(workspaceId: $currentWorkspaceId) {
      id
      name
    }
  }
`;

function CurrentWorkspaceNameQuery(props) {
  const { children, onLoading, onError, ...rest } = props;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data } = useQuery(CURRENT_WORKSPACE_NAME_QUERY, {
    variables: { currentWorkspaceId },
    ...rest
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.workspace);
}

CurrentWorkspaceNameQuery.propTypes = {
  children: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

export default CurrentWorkspaceNameQuery;
