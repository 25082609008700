import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TimelineContext from './TimelineContext';

const useStyles = makeStyles((theme) => ({
  ruler: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: '#DADDE1'
  }
}));

function Ruler({ style, className, ...rest }) {
  const classes = useStyles();
  const {
    dividerWidth,
    rulerWidth,
    matches
  } = React.useContext(TimelineContext);

  return (
    <div
      className={clsx(classes.ruler, className)}
      style={{
        ...style,
        width: rulerWidth,
        left: matches ? '50%' : (dividerWidth - rulerWidth) / 2,
        transform: matches ? 'translateX(-50%)' : 'none'
      }}
      {...rest}
    />
  );
}

export default React.memo(Ruler);
