import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { format, isSameDay, isSameYear } from 'date-fns';
import ClockIcon from '../../../../icons/16x16/Clock';
import { parseDateInput, DateInputType }
from '../../../../new-ui/datetime-picker/parseDateInput';
import ContentField from './ContentField';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.375rem'
  },
  middot: {
    margin: theme.spacing(0, 1),
    fontWeight: 700
  }
}));

// Helpers

function _formatDate(date) {
  return isSameYear(date, new Date()) ?
         format(date, 'EEEE, MMMM d') :
         format(date, 'EEEE, MMMM d, yyyy');
}

function _formatEndDate(startDate, endDate) {
  if (isSameDay(startDate, endDate)) {
    return 'All day';
  }

  return _formatDate(endDate);
}

function _formatTime(startDate, endDate) {
  const start = format(startDate, 'HH:mm');
  const end = format(endDate, 'HH:mm');
  return `${start} – ${end}`;
}

// Main

function TitleField({ title, isAllDay, startsAt, endsAt, noWrap }) {
  const classes = useStyles();

  const startDate = parseDateInput(startsAt);
  const endDate = parseDateInput(endsAt);

  return (
    <ContentField icon={<ClockIcon /> }>
      <Typography
        component='h1'
        variant='h5'
        className={classes.title}
        noWrap={noWrap}
      >
        {title}
      </Typography>

      <Typography variant='body2'>
        {_formatDate(startDate)}
        <span className={classes.middot}>&middot;</span>
        {isAllDay ?
         _formatEndDate(startDate, endDate) :
         _formatTime(startDate, endDate)
        }
      </Typography>
    </ContentField>
  );
}

TitleField.propTypes = {
  // The title of the event
  title: PropTypes.string.isRequired,

  startsAt: DateInputType.isRequired,
  endsAt: DateInputType.isRequired,

  // Is this an all-day event?
  isAllDay: PropTypes.bool.isRequired,

  // If set to `true` and the title text is far too long to fit its
  // container, the title will be truncated.
  noWrap: PropTypes.bool
};

TitleField.defaultProps = {
  noWrap: false
};

export default TitleField;
