import { merge } from '../../apollo/relayStylePagination';

export default function updateQuery(prev, { fetchMoreResult, variables }) {
  const existing = prev.project.activities;
  const incoming = fetchMoreResult.project.activities;

  return {
    project: {
      ...prev.project,
      activities: merge(existing, incoming, {
        args: variables
      })
    }
  };
}
