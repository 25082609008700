import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  monthGridBody: {
    margin: 0,
    overflow: 'hidden',
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const MonthGridBody = React.forwardRef(function MonthGridBody(props, ref) {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      role='presentation'
      className={classes.monthGridBody}
      {...props}
    />
  );
});

export default MonthGridBody;
