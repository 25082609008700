import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '../icons/Visibility';
import VisibilityOff from '../icons/VisibilityOff';
import TextField from './TextField';

const Password = React.forwardRef(function Password(props, ref) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputAdornment = (
    <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge='end'
      >
        {
          showPassword ?
          <Visibility color='secondary' /> :
          <VisibilityOff color='secondary' />
        }
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      ref={ref}
      {...props}
      type={showPassword ? 'text' : 'password'}
      autoComplete='current-password'
      InputProps={{
        endAdornment: inputAdornment
      }}
    />
  );
});

export default Password;
