import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { matchesNewCommentId } from './helper';
import { useContainerContext } from '../ContainerContext';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';

const NEW_EVENT_COMMENT_ADDED_SUBSCRIPTION = gql`
  subscription OnNewEventCommentAdded($eventId: ID!) {
    eventCommentAdded(eventId: $eventId) {
      id
      text
      autoGenerated
      severity
      ...CommentAuthor
      ...CommentFiles
      updatedAt
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

function NewCommentAddedSubscriber({eventId, subscribeToNewCommentAdded}) {
  const { scrollToBottom } = useContainerContext();
  const timer = React.useRef();

  React.useEffect(() => {
    const unsubscribe = subscribeToNewCommentAdded({
      document: NEW_EVENT_COMMENT_ADDED_SUBSCRIPTION,
      variables: { eventId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return prev; }

        const newComment = subscriptionData.data.eventCommentAdded;
        let comments = [...prev.event.comments];

        // TODO: explain why.
        // If we didn't remove the optimistic response result from
        // the mutation, we would end up with two comments in the
        // cache: one from the optimistic response result and
        // the other from the database (probably subscription result).
        if (
          comments.length &&
          matchesNewCommentId(comments[comments.length - 1].id)
        ) {
          comments.pop();
        }

        // Check for existance
        const found = comments.some(({ id }) => ( id === newComment.id ));
        if (!found) {
          comments = [...comments, newComment];
        }

        // FIXME: this is a workaround solution in order to wait for the
        // cache to be updated first, and then scroll to bottom!
        timer.current = setTimeout(() => {
          scrollToBottom();
        }, 100);

        return {
          event: {
            ...prev.event,
            comments: comments
          }
        };
      }
    });

    return () => {
      unsubscribe();
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [eventId, subscribeToNewCommentAdded, scrollToBottom]);

  return null;
}

NewCommentAddedSubscriber.propTypes = {
  eventId: PropTypes.string.isRequired,
  subscribeToNewCommentAdded: PropTypes.func.isRequired
};

export default NewCommentAddedSubscriber;
