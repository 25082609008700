import { matchPath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

// TODO: This is an ugly way to directly retrieve the rootUrl
// (of the Calendar) instead of relying on the ancestor components to
// pass it down.
export default function useCalendarUrl() {
  const { url } = useRouteMatch();

  const match = matchPath(url, {
    path: '/workspaces/:workspaceId/projects/:projectId/calendar',
    exact: false
  });

  // Allow it to fail if no match!
  return match.url;
}
