import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    flex: '0 0 auto',
    backgroundColor: theme.palette.background.paper,

    // TODO: FIXME please!
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar
  }
}));

export default function StickyHeader(props) {
  const classes = useStyles();
  return <div className={classes.stickyHeader} {...props} />;
}
