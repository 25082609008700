import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../ui/progress/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingText: {
    marginLeft: theme.spacing(1)
  }
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Spinner size={16} />
      <Typography variant='body1' className={classes.loadingText}>
        Loading results...
      </Typography>
    </div>
  );
}
