import React from 'react';
import Divider from '@material-ui/core/Divider';
import MembersCountItem from './MembersCountItem';
import MembershipsInCurrentWorkspaceQuery
from './MembershipsInCurrentWorkspaceQuery';
import { isGuest, isMember, isAdmin } from './helpers';
import RolesCounterSkeleton from './RolesCounterSkeleton';

export default function RolesCounter() {
  return (
    <MembershipsInCurrentWorkspaceQuery
      onLoading={() => <RolesCounterSkeleton /> }
    >
      {(memberships) => {
        let guestCount = 0, memberCount = 0, adminCount = 0;
        let curr;

        for (let i = 0; i < memberships.length; ++i) {
          curr = memberships[i];
          if (curr.active) {
            if (isGuest(curr.role)) { guestCount++; continue; }
            if (isMember(curr.role)) { memberCount++; continue; }
            if (isAdmin(curr.role)) adminCount++;
          }
        }

        return (
          <React.Fragment>
            <MembersCountItem label='Admins' count={adminCount} />
            <Divider flexItem orientation='vertical' />
            <MembersCountItem label='Members' count={memberCount} />
            <Divider flexItem orientation='vertical' />
            <MembersCountItem label='Guests' count={guestCount} />
          </React.Fragment>
        );
      }}
    </MembershipsInCurrentWorkspaceQuery>
  );
}
