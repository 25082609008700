import React from 'react';
import Container from '../page/Container';

export default function EditorContainer({ children }) {
  return (
    <Container>
      {children}
    </Container>
  );
}
