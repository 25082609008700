import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { CHIP_DEFAULT_COLOR } from '../../daily-schedule/ui/constants';

// Some style constants
const CHIP_HEIGHT = 18;
const HALF_CHIP_HEIGHT = CHIP_HEIGHT / 2;
const OVERFLOW_WIDTH = 6/* depends on CHIP_HEIGHT */;

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    fontSize: '1.25rem',
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: 0,
    height: CHIP_HEIGHT,
    '&.Mui-disabled': {
      opacity: 0.6
    }
  },

  chipColor: ({ color }) => {
    try {
      return {
        backgroundColor: color,
        color: theme.palette.getContrastText(color)
      };
    } catch (e) {
      // If we failed to get the text color from the given color, we return
      // the default style instead.
      return {
        backgroundColor: CHIP_DEFAULT_COLOR,
        color: theme.palette.getContrastText(CHIP_DEFAULT_COLOR)
      };
    }
  },

  leftOverflow: ({ color }) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: HALF_CHIP_HEIGHT,
    width: `calc(100% - ${HALF_CHIP_HEIGHT + OVERFLOW_WIDTH}px)`,
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: -HALF_CHIP_HEIGHT,
      bottom: 0,
      width: HALF_CHIP_HEIGHT,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderTopWidth: HALF_CHIP_HEIGHT,
      borderBottomWidth: HALF_CHIP_HEIGHT,
      borderRightWidth: OVERFLOW_WIDTH,
      borderRightColor: color
    }
  }),

  rightOverflow: ({ color }) => ({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: -HALF_CHIP_HEIGHT,
      bottom: 0,
      width: HALF_CHIP_HEIGHT,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderTopWidth: HALF_CHIP_HEIGHT,
      borderBottomWidth: HALF_CHIP_HEIGHT,
      borderLeftWidth: OVERFLOW_WIDTH,
      borderLeftColor: color
    }
  }),

  focus: {
    boxShadow: theme.shadows[8]
  }
}));

function AllDayChipRoot({
  color,
  colIndex,
  colSpan,
  rowIndex,
  numCols,
  focus,
  fullWidth,
  zIndex,
  ...rest
}) {
  const classes = useStyles({ color: color || CHIP_DEFAULT_COLOR });
  const colWidth = 100 / numCols;

  let leftOverflow = false;
  let rightOverflow = false;

  // Left overflow
  if (colIndex < 0) {
    colSpan += colIndex;
    colIndex = 0;
    leftOverflow = true;
  }

  // Right overflow
  if ((colIndex + colSpan) > numCols) {
    colSpan = numCols - colIndex;
    rightOverflow = true;
  }

  const getWidth = () => {
    const width = colWidth * colSpan;
    let offset = (fullWidth && !rightOverflow) ? 0 : OVERFLOW_WIDTH;

    if (leftOverflow) {
      offset +=  HALF_CHIP_HEIGHT;
    }

    return `calc(${width}% - ${offset}px)`;
  }

  return (
    <ButtonBase
      component='div'
      className={clsx(classes.chipRoot, classes.chipColor, {
        [classes.leftOverflow]: leftOverflow,
        [classes.rightOverflow]: rightOverflow,
        [classes.focus]: focus
      })}
      style={{
        zIndex,
        top: `${rowIndex}em`,
        left: `${colIndex * colWidth}%`,
        width: getWidth()
      }}
      {...rest}
    />
  );
}

AllDayChipRoot.propTypes = {
  color: PropTypes.string,

  // The start column index of the chip. It must be < `numCols` (below),
  // and can be a negative number - in that case, the chip is left
  // overflow.
  colIndex: PropTypes.number.isRequired,

  // Number of columns this chip spans. It must be a positive integer
  // that satisfies `colSpan` + `colIndex` > 0.
  colSpan: PropTypes.number.isRequired,

  // The row index will determine the vertical position of the chip
  // within the grid.
  rowIndex: PropTypes.number.isRequired,

  // This prop serves two purposes. Firstly, it help (along with
  // `colIndex` and `colSpan` above) to determine whether the chip is
  // left-overflow or right-overflow or none. Secondly, it determines the
  // width of each column in the grid: `width` = 100 / `numCols`.
  numCols: PropTypes.number.isRequired,

  focus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  zIndex: PropTypes.number
};

AllDayChipRoot.defaultProps = {
  rowIndex: 0,
  focus: false,
  fullWidth: false,
  zIndex: 0
};

export default AllDayChipRoot;
