import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';
import { removeEntryFromCache } from '../schedulesCacheHelpers';

const DELETE_TODO_MUTATION = gql`
  mutation DeleteTodo($todoId: ID!) {
    deleteTodoItem(todoItemId: $todoId) {
      id
      dueDate
    }
  }
`;

export default function useDeleteTodo(projectId) {
  const [mutate, mutationResults] = useMutation(DELETE_TODO_MUTATION, {
    loadingMessage: 'Deleting...',
    successMessage: 'Task deleted',
    update: (cache, { data: { deleteTodoItem: entry }}) => {
      removeEntryFromCache(cache, { projectId, entry });
    }
  });

  const mutateFn = useCallback((todo) => {
    mutate({
      variables: { todoId: todo.id },
      optimisticResponse: {
        deleteTodoItem: {
          ...todo
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
