import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import {
  CHIP_TODO,
  EVENT_DETAILS,
  EVENT_PARTICIPANTS,
  EVENT_REMINDERS
} from './fragments';

const PROJECT_SCHEDULE_UPDATED_SUBSCRIPTION = gql`
  subscription OnProjectScheduleUpdated($projectId: ID!) {
    someEventUpdated(projectId: $projectId) {
      __typename

      ... on Event {
        ...EventDetails
        ...EventParticipants
        ...EventReminders
      }

      ... on TodoItem {
        ...ChipTodo
      }
    }
  }
  ${EVENT_DETAILS}
  ${EVENT_PARTICIPANTS}
  ${EVENT_REMINDERS}
  ${CHIP_TODO}
`;

function ScheduleUpdatedSubscription({ projectId }) {
  useSubscription(PROJECT_SCHEDULE_UPDATED_SUBSCRIPTION, {
    variables: { projectId }
  });

  return null;
}

ScheduleUpdatedSubscription.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ScheduleUpdatedSubscription;
