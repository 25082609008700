import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { PROJECT_DETAILS_FRAGMENT } from './fragments';
import Error from '../oops/Error';

const PROJECT_DETAILS_QUERY = gql`
  query GetProjectDetails($projectId: ID!) {
    project(projectId: $projectId) {
      ...ProjectDetails
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
`;

function ProjectDetailsQuery(props) {
  const {projectId, onLoading, onError, children, ...other} = props;
  const {loading, error, data, ...rest} = useQuery(PROJECT_DETAILS_QUERY, {
    variables: { projectId },
    ...other
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.project, rest);
}

ProjectDetailsQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ProjectDetailsQuery;
