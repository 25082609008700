import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const CHANGE_MEMBERSHIP_ROLE_MUTATION = gql`
  mutation ChangeMembershipRole(
    $membershipId: ID!,
    $newRole: WorkspaceRole!
  ) {
      updateMembershipRole(membershipId: $membershipId, newRole: $newRole) {
        id
      }
    }
`;

function useChangeMembershipRoleMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(CHANGE_MEMBERSHIP_ROLE_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}

export default useChangeMembershipRoleMutation;
