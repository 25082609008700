import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/IconButton';

const ControlButton = React.forwardRef(({icon, active, ...rest}, ref) => (
  <Button
    ref={ref}
    tabIndex={-1}
    square
    size='small'
    background={false}
    color={active ? 'accent' : 'secondary'}
    icon={icon}
    {...rest}
  />
));

ControlButton.propTypes = {
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool
};

ControlButton.defaultProps = {
  active: false
};

export default ControlButton;
