import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

// TODO: Similar to `todo/hooks/useUpdateTodoItemMutation.js` except that
// one is too specific to TodoSheet.
const UPDATE_TODO_MUTATION = gql`
  mutation UpdateTodo($todoId: ID!, $changes: UpdateTodoItemInput!) {
    updateTodoItem(todoItemId: $todoId, changes: $changes) {
      id
      name
      description

      assignee {
        id
        username
        email
        avatar
      }

      dueDate
      priority
      completed
    }
  }
`;

export default function useUpdateTodoMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_TODO_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
