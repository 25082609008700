import React from 'react';
import Grid from '@material-ui/core/Grid';
import MemberGridItemSkeleton from './MemberGridItemSkeleton';

export default function MembershipsGridSkeleton() {
  return (
    <Grid container spacing={2}>
      <MemberGridItemSkeleton />
    </Grid>
  );
}
