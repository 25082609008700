import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BaseIcon from './Base';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundPosition: '0px -600px'
  }
}));

export default function Zip({ className, ...rest }) {
  const classes = useStyles();
  const finalClassName = clsx(classes.root, className);
  return <BaseIcon className={finalClassName} {...rest} />;
}
