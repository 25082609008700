import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const REORDER_TODO_FIELD_MUTATION = gql`
  mutation ReorderTodoField(
    $projectId: ID!,
    $field: String!,
    $index: Int!
  ) {
    reorderTodoSheetColumns(
      projectId: $projectId,
      input: { col: $field, index: $index }
    ) {
      name
      show
      width
    }
  }
`;

export default function useReorderFieldMutation(options) {
  return useMutation(REORDER_TODO_FIELD_MUTATION, options);
}
