import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../ui/button/Button';
import ArrowDropdownIcon from '../../icons/12x12/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: 'left',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25, 0.25, 0.25, 1)
  },
  placeholder: {
    border: `1px dashed ${theme.palette.text.secondary}`
  },
  label: {
    fontWeight: 500
  },
  endIconContainer: {
    flex: '0 0 auto',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function TeamDropdownButton({ label, placeholder, ...rest }) {
  const classes = useStyles();

  return (
    <Button
      size='small'
      variant={placeholder ? 'text' : 'contained'}
      className={clsx(classes.button, {
        [classes.placeholder]: placeholder
      })}
      {...rest}
    >
      <Typography
        variant='body2'
        noWrap
        className={classes.label}
      >
        {label}
      </Typography>
      <div className={classes.endIconContainer}>
        <ArrowDropdownIcon />
      </div>
    </Button>
  );
}

TeamDropdownButton.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.bool
};

TeamDropdownButton.defaultProps = {
  label: "Let's pick a team",
  placeholder: false
};

export default TeamDropdownButton;
