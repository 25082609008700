import React from 'react';
import Loading from '../../ui/dialog/DialogLoading';
import CurrentWorkspaceNameQuery
from '../../workspace/CurrentWorkspaceNameQuery';
import InvitePeopleToWorkspace from './InvitePeopleToWorkspace';

export default function InvitePeopleToCurrentWorkspace() {
  return (
    <CurrentWorkspaceNameQuery onLoading={() => <Loading /> }>
      {(workspace) => <InvitePeopleToWorkspace  workspace={workspace} /> }
    </CurrentWorkspaceNameQuery>
  );
}
