import React from 'react';
import Input from './Input';

export default function NumberInput({ min, max, inputProps, ...rest }) {
  return (
    <div style={{width: 64}}>
      <Input
        fullWidth
        autoComplete='off'
        type='number'
        inputProps={{
          ...inputProps,
          min,
          max,
        }}
        {...rest}
      />
    </div>
  );
}
