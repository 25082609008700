import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Secondary from './Secondary';

const useStyles = makeStyles((theme) => ({
  notificationDetails: {
    flex: '1 1 auto',
    marginRight: theme.spacing(3)
  },
  notificationPrimary: {
    fontSize: '0.9375rem',
    lineHeight: 1.4
  }
}));

function ListItemText({ primary, secondary }) {
  const classes = useStyles();
  return (
    <div className={classes.notificationDetails}>
      <Typography
        variant='subtitle2'
        className={classes.notificationPrimary}
      >
        {primary}
      </Typography>
      <Secondary text={secondary} />
    </div>
  );
}

ListItemText.propTypes = {
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array
  ]).isRequired
};

export default ListItemText;
