import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../ui/ChipColorPicker';
import PaletteIcon from '../../icons/16x16/Palette';
import NotesIcon from '../../icons/16x16/Notes';
import PrimaryInput from './PrimaryInput';
import Checkbox from './Checkbox';
import FormField from './FormField';
import NotesInput from './NotesInput';
import ParticipantsField from './ParticipantsField';
import DateRangePicker from './DateRangePicker';
import DateTimePicker from './DateTimePicker';
import TimeReminderList from './TimeReminderList';
import DayReminderList from './DayReminderList';

function EventFormData({ projectId, data, onChange }) {
  const handleTitleChange = React.useCallback((event) => {
    const newTitle = event.target.value;
    onChange((prevData) => ({
      ...prevData,
      title: newTitle
    }));
  }, [onChange]);

  const handleAllDayChange = React.useCallback((event) => {
    const isAllDay = event.target.checked;
    onChange((prevData) => ({
      ...prevData,
      allDay: isAllDay
    }));
  }, [onChange]);

  const handleDescriptionChange = React.useCallback((event) => {
    const newDesc = event.target.value;
    onChange((prevData) => ({
      ...prevData,
      description: newDesc
    }));
  }, [onChange]);

  const handleColorChange = React.useCallback((event) => {
    const newColor = event.target.value;
    onChange((prevData) => ({
      ...prevData,
      color: newColor
    }));
  }, [onChange]);

  const handleParticipantsChange = React.useCallback((newParticipants) => {
    onChange((prevData) => ({
      ...prevData,
      participants: newParticipants
    }));
  }, [onChange]);

  return (
    <div>
      <PrimaryInput
        autoFocus
        placeholder='Name of the event'
        leftPadding={44}
        value={data.title}
        onChange={handleTitleChange}
      />

      <Checkbox
        label='All day?'
        checked={data.allDay}
        onChange={handleAllDayChange}
      />

      {data.allDay ? (
        <DateRangePicker
          startDate={data.startDate}
          endDate={data.endDate}
          onChange={onChange}
        />
      ) : (
        <DateTimePicker
          startDate={data.startDate}
          startTime={data.startTime}
          endTime={data.endTime}
          onChange={onChange}
        />
      )}

      <ParticipantsField
        projectId={projectId}
        value={data.participants}
        onChange={handleParticipantsChange}
      />

      {data.allDay ? (
        <DayReminderList
          dayReminders={data.dayReminders}
          onChange={onChange}
        />
      ) : (
        <TimeReminderList
          timeReminders={data.timeReminders}
          onChange={onChange}
        />
      )}

      <FormField label={<PaletteIcon />}>
        <ColorPicker
          value={data.color}
          onChange={handleColorChange}
        />
      </FormField>

      <FormField label={<NotesIcon />}>
        <NotesInput
          placeholder='Add description'
          value={data.description}
          onChange={handleDescriptionChange}
        />
      </FormField>
    </div>
  );
}

EventFormData.propTypes = {
  projectId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default EventFormData;
