import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import DeleteIcon from '../../icons/20x20/Trash';
import OptionDialog from '../options/OptionDialog';
import { projectOption } from '../options/OptionList';

const DeleteProjectItem = React.forwardRef((props, ref) => {
  const { projectId, ...other } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();

    openDialog({
      dialogContent: (
        <OptionDialog
          projectId={projectId}
          defaultOption={projectOption.DELETE_PROJECT}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Delete this project'
      icon={<DeleteIcon />}
      onClick={handleClick}
      {...other}
    />
  );
});

DeleteProjectItem.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default DeleteProjectItem;
