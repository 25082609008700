import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import Timestamp from './Timestamp';
import AuthorAvatar from './AuthorAvatar';
import Content from './Content';
import Caption from './Caption';
import ComposerEditor from './ComposerEditor';
import DiscardChanges from './DiscardChanges';
import StyledListItem from './StyledListItem';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  commentBody: {
    display: 'flex',
    alignItems: 'center',
    '&:hover $commentMenu': {
      opacity: 1
    }
  },
  commentMenu: {}
}));

function ListItem({
  readOnly,
  comment,
  onUpdate,
  onDelete,
  variant,
  projectId,
  ...rest
}) {
  const classes = useStyles();
  const [editable, setEditable] = React.useState(false);

  const commentId = comment.id
  const authorName = comment.author.username;
  const text = comment.text;
  const files = comment.files

  const handleEdit = (event) => {
    setEditable(true);
  };

  const handleDelete = (event) => {
    if (onDelete) {
      onDelete(commentId);
    }
  };

  const handleUpdate = (data) => {
    if (onUpdate) {
      onUpdate(commentId, data);
    }
    setEditable(false);
  };

  const handleDiscardChanges = (event) => {
    setEditable(false);
  };

  const grow = editable || (files && files.length);

  return (
    <StyledListItem {...rest}>
      <AuthorAvatar author={comment.author} />
      <div className={grow ? classes.grow : null}>
        <div className={classes.commentBody}>
          {editable && !readOnly ? (
            <ComposerEditor
              autoFocus
              label={authorName}
              text={text}
              files={files}
              onSubmit={handleUpdate}
              onDiscard={handleDiscardChanges}
              projectId={projectId}
            />
          ) : (
            <Content
              variant={variant}
              authorName={authorName}
              text={text}
              files={files}
            />
          )}
          {!editable && !readOnly && (
            <Menu
              className={classes.commentMenu}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          )}
        </div>
        <Caption>
          {!editable ? (
            <Timestamp value={comment.updatedAt} />
          ) : (
            <DiscardChanges onDiscard={handleDiscardChanges} />
          )}
        </Caption>
      </div>
    </StyledListItem>
  );
}

ListItem.propTypes = {
  readOnly: PropTypes.bool,

  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string
    }).isRequired,
    updatedAt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  }).isRequired,

  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,

  variant: PropTypes.oneOf([
    'DEFAULT',
    'SUCCESS',
    'WARNING',
    'INFO',
    'ERROR'
  ]),

  projectId: PropTypes.string.isRequired
};

ListItem.defaultProps = {
  readOnly: false,
  variant: 'DEFAULT'
};

export default ListItem;
