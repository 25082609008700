import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../ui/menu/Menu';
import MenuItemDivider from '../../ui/menu/MenuItemDivider';
import EditTeamMenuItem from '../team-card-menu/EditTeamMenuItem';
import DeleteTeamMenuItem from '../team-card-menu/DeleteTeamMenuItem';

function OtherActionsMenu({ teamId, ...other }) {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: 320
        }
      }}
      {...other}
    >
      <EditTeamMenuItem teamId={teamId} />
      <MenuItemDivider />
      <DeleteTeamMenuItem teamId={teamId} />
    </Menu>
  );
}

OtherActionsMenu.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default OtherActionsMenu;
