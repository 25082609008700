import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import { ROLE_LIST } from '../helpers';
import ChangeMembershipRoleInput from './ChangeMembershipRoleInput';
import useChangeMembershipRoleMutation
from '../hooks/useChangeMembershipRoleMutation';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

function ChangeMembershipRole({ membership, currentUserRole }) {
  const { id, role, username } = membership;
  const { closeDialog } = React.useContext(DialogContext);
  const [newRole, setNewRole] = React.useState(role);
  const { enqueueSuccessSnackbar } = useCustomSnackbar();

  const handleChange = (event) => {
    setNewRole(event.target.value);
  };

  const handleCompleted = () => {
    let message;

    switch(newRole) {
      case 'OWNER':
        message = `${username} is now an Owner`;
        break;

      case 'ADMIN':
        message = `${username} is now an Admin`;
        break;

      case 'MEMBER':
        message = `${username} is now a Member`;
        break;

      case 'GUEST':
        message = `${username} is now a Guest`;
        break;

      default:
        break;
    }

    enqueueSuccessSnackbar(message);
  };

  const [changeRole, { loading }] = useChangeMembershipRoleMutation({
    variables: {
      membershipId: id,
      newRole: newRole
    },
    onCompleted(data) {
      closeDialog();
      handleCompleted();
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Change {username}'s role
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose what role {username} will have in your workspace.
        </DialogContentText>

        <ChangeMembershipRoleInput
          currentUserRole={currentUserRole}
          value={newRole}
          onChange={handleChange}
        />
      </DialogContent>

      <DialogActions>
        {!loading && (
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={closeDialog}
          >
            Cancel
          </Button>
        )}

        <Button
          variant='contained'
          color='primary'
          size='small'
          disabled={ newRole === role }
          loading={loading}
          onClick={() => {
            changeRole();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

ChangeMembershipRole.propTypes = {
  membership: PropTypes.exact({
    id: PropTypes.string,
    role: PropTypes.oneOf(ROLE_LIST),
    username: PropTypes.string.isRequired
  }).isRequired,
  currentUserRole: PropTypes.oneOf(ROLE_LIST).isRequired
};

export default ChangeMembershipRole;
