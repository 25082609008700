import React from 'react';
import { isValid, startOfDay } from 'date-fns';
import NotFound from '../oops/SectionNotFound';
import DailySchedule from './DailySchedule';
import { useDateParam } from './helpers';

// TODO: handle the situation where the `date` (event though valid) returned
// by `useDateParam` does not match the date slug in url.
export default function DailyScheduleWrapper() {
  const date = useDateParam();

  if (isValid(date)) {
    return <DailySchedule startOfDay={startOfDay(date)} />;
  }

  return <NotFound />;
}
