import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from './Chip';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none'
  }
}));

function EventChipPlaceholder({ eventTitle, ...rest }) {
  const classes = useStyles();
  return (
    <Chip
      className={classes.placeholder}
      disabled
      label={eventTitle}
      {...rest}
    />
  );
}

EventChipPlaceholder.propTypes = {
  eventTitle: PropTypes.string.isRequired
};

export default EventChipPlaceholder;
