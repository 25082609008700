import React from 'react';
import PropTypes from 'prop-types';
import ProjectMembersQuery from '../ProjectMembersQuery';
import OwnerPickerSkeleton from './OwnerPickerSkeleton';
import OwnerPickerWithData from './OwnerPickerWithData';

function OwnerPicker({ projectId }) {
  return (
    <ProjectMembersQuery
      projectId={projectId}
      onLoading={() => <OwnerPickerSkeleton />}
    >
      {(memberships) => (
        <OwnerPickerWithData
          projectId={projectId}
          memberships={memberships}
        />
      )}
    </ProjectMembersQuery>
  );
}

OwnerPicker.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default OwnerPicker;
