import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import SheetContainerContext from './SheetContainerContext';
import ResizeHandle from './ResizeHandle';

const useStyles = makeStyles((theme) => ({
  isDragging: {
    '&$handle': {
      backgroundColor: '#AAC9FF'
    },
    '& $midLine': {
      backgroundColor: theme.palette.primary.main
    }
  },
  handle: {
    zIndex: theme.zIndex.tooltip,
    pointerEvents: 'auto',
    '&:hover': {
      backgroundColor: '#AAC9FF'
    }
  },
  midLine: {}
}));

function ColumnResizeHandle({ bounds, onStart, onDrag, onStop }) {
  const classes = useStyles();
  const { height } = React.useContext(SheetContainerContext);

  const [isDragging, setIsDragging] = React.useState(false);
  const [delta, setDelta] = React.useState({ x: 0, y: 0 });
  const [position, setPosition] = React.useState(null);

  const handleStart = () => {
    setPosition(null);
    setIsDragging(true);
    onStart && onStart();
  };

  const handleDrag = (e, { deltaX, deltaY }) => {
    setDelta(({ x, y }) => ({
      x: x + deltaX,
      y: y + deltaY
    }));
    onDrag && onDrag();
  };

  const handleStop = () => {
    if (onStop) onStop(delta.x);
    setPosition({ x: 0, y: 0 });
    setDelta({x: 0, y: 0});
    setIsDragging(false);
  };

  return (
    <Draggable
      axis='x'
      bounds={bounds}
      position={position}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
    >
      <ResizeHandle
        className={clsx(classes.handle, isDragging && classes.isDragging)}
        midLineClassName={classes.midLine}
        midLineStyles={{ height: isDragging ? height : '100%' }}
      />
    </Draggable>
  );
}

ColumnResizeHandle.propTypes = {
  bounds: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number
  }),
  onStart: PropTypes.func,
  onDrag: PropTypes.func,
  onStop: PropTypes.func
};

export default ColumnResizeHandle;
