import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import RouterLink from '../link/RouterLink';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary
  }
}));

function CardTitleLink({ title, to, className, ...rest }) {
  const classes = useStyles();

  // This is important to prevent click event from propagating to
  // the card parent.
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <RouterLink
      to={to}
      className={clsx(classes.link, className)}
      onClick={handleClick}
      {...rest}
    >
      {title}
    </RouterLink>
  );
}

CardTitleLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CardTitleLink;
