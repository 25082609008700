import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { PROJECT_MEMBERSHIP_DETAILS_FRAGMENT } from './fragments';
import useProjectMemberAddedSubscription
from './hooks/useProjectMemberAddedSubscription';

function ProjectCardMemberAddedSubscriber({ projectId }) {
  useProjectMemberAddedSubscription({
    projectId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) return;

      const fragmentObj = {
        id: `Project:${projectId}`,
        fragment: gql`fragment ProjectMembersWithCount on Project {
          memberCount
          members {
            ...ProjectMembershipDetails
          }
        },
          ${PROJECT_MEMBERSHIP_DETAILS_FRAGMENT}
        `,
        fragmentName: 'ProjectMembersWithCount'
      };

      const oldData = client.readFragment(fragmentObj);
      const oldMembers = oldData.members;

      const newMembers = subscriptionData.data.projectMemberAdded.filter(
        ({ id }) => !oldMembers.some((m) => m.id === id)
      );

      if (newMembers.length === 0) return;

      const updatedMembers = [...oldMembers, ...newMembers];

      client.writeFragment({
        ...fragmentObj,
        data: {
          ...oldData,
          memberCount: updatedMembers.length,
          members: updatedMembers
        }
      });
    }
  });

  return null;
}

ProjectCardMemberAddedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectCardMemberAddedSubscriber;
