import React from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import UserOption from './autocomplete/UserOption';
import UserAvatar from './autocomplete/UserOptionAvatar';
import Autocomplete from './Autocomplete';

const getOptionSelected = (option, value) => option.id === value.id
const getOptionLabel = ({ username }) => username;
const renderOption = (user) => <UserOption user={user} />;
const filterOptions = createFilterOptions({
  stringify: (user) => `${user.username} ${user.email}`,
});

export default function UserAutocomplete({
  inputProps = {},
  ...rest
}) {
  return (
    <Autocomplete
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      inputProps={{
        ...inputProps,
        startAdornment: !rest.open && rest.value && (
          <UserAvatar user={rest.value} />
        )
      }}
      {...rest}
    />
  );
}

export {
  getOptionSelected,
  getOptionLabel,
  renderOption,
  filterOptions
};
