import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  metadata: {
    flex: '0 0 auto'
  }
}));

export default function FileDetailsMetadata(props) {
  const classes = useStyles();
  return <div className={classes.metadata} {...props} />;
}
