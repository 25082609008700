import React from 'react';
import PropTypes from 'prop-types';
import DialogLoading from '../../ui/dialog/DialogLoading';
import TodoListNameAndCompletionCountQuery
from '../TodoListNameAndCompletionCountQuery';
import DeleteTodoListConfirm from './DeleteTodoListConfirm';

function DeleteTodoList({ todoListId }) {
  return (
    <TodoListNameAndCompletionCountQuery
      todoListId={todoListId}
      onLoading={() => <DialogLoading /> }
    >
      {(todoList) => <DeleteTodoListConfirm todoList={todoList} />}
    </TodoListNameAndCompletionCountQuery>
  );
}

DeleteTodoList.propTypes = {
  todoListId: PropTypes.string.isRequired
};

export default DeleteTodoList;
