import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../button/IconButton';
import CloseIcon from '../../icons/20x20/Close';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    lineHeight: 1.4
  },
  // TODO: explain why substracting by 32px?
  titleWidth: {
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

function DialogTitle({ children, onClose, noWrap, ...otherProps }) {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...otherProps}>
      <Typography
        component='h2'
        variant='h6'
        className={clsx(classes.title, {
          [classes.titleWidth]: Boolean(onClose)
        })}
        noWrap={noWrap}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          color='secondary'
          size='small'
          icon={ <CloseIcon /> }
          className={classes.closeButton}
          onClick={onClose}
        />
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  noWrap: PropTypes.bool
};

DialogTitle.defaultProps = {
  noWrap: false
};

export default DialogTitle;
