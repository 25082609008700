import React from 'react';
import PropTypes from 'prop-types';
import FilterAssigneeButton from '../ui/todo/FilterAssigneeButton';
import AutocompleteAssignee from '../ui/todo/AutocompleteAssignee';
import useActiveProjectMembersLazyQuery
from '../project/hooks/useActiveProjectMembersLazyQuery';

function AssigneeFilter({ projectId, value, onChange }) {
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef();

  const [
    loadActiveProjectMembers,
    { called, loading, data }
  ] = useActiveProjectMembersLazyQuery({
    projectId,
    fetchPolicy: 'network-only'
  });

  const handleOpen = (event) => {
    setOpen(true);
    loadActiveProjectMembers();
  };

  const handleClose = () => {
    // Restore the focus back to the button when autocomplete is closed.
    buttonRef.current.focus();
    setOpen(false);
  };

  const handleChange = (event, newAssignee) => {
    if (onChange) {
      onChange(newAssignee);
    }
  };

  const options = data ? data.project.members.map(({ user }) => user) : [];

  return (
    <div style={{position: 'relative'}}>
      {/* A hack to hide the button inplace when autocomplete is open */}
      <div
        style={{
          position: open ? 'absolute' : 'static',
          top: 0,
          left: 0
        }}
      >
        <FilterAssigneeButton
          ref={buttonRef}
          onClick={handleOpen}
          user={value}
          invisible={open}
        />
      </div>

      {open && (
        <AutocompleteAssignee
          loading={called && loading}
          options={options}
          value={value}
          onChange={handleChange}
          onClose={handleClose}
        />
      )}
    </div>
  );
}

AssigneeFilter.propTypes = {
  projectId: PropTypes.string.isRequired,
  value: PropTypes.object,  // user object
  onChange: PropTypes.func  // callback to fire when assignee changed.
};

// Does the given `todoItem` match the specified `assignee`?
export function matchesAssignee(todoItem, assignee) {
  if (!todoItem) {
    return false;
  }

  if (!assignee) {
    return true;
  } else if (!todoItem.assignee) {
    return false;
  } else {
    return todoItem.assignee.id === assignee.id;
  }
}

export default AssigneeFilter;
