import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // TODO: CANNOT use flex box here because of some weird bug
    // on Safari.
    position: 'absolute',
    top: '50%',
    left: theme.spacing(1),
    transform: 'translateY(-50%)',

    color: theme.palette.text.secondary,
    fontSize: '0.8125rem'
  }
}));

function HeaderCellContent({ name }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {name}
    </div>
  );
}

HeaderCellContent.propTypes = {
  name: PropTypes.string.isRequired
};

export default HeaderCellContent;
