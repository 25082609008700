import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import RouterLink from '../ui/link/RouterLink';

export default function CreateWorkspaceFormFooter() {
  return (
    <>
      <Divider flexItem style={{height: 1}} />
      <Typography variant='body2' color='textSecondary'>
        Already have a workspace?
        {' '}
        <RouterLink to='/' variant='subtitle2'>
          Go to my workspace
        </RouterLink>
      </Typography>
    </>
  );
}
