import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    position: 'relative',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    lineHeight: '16px',
    fontWeight: 500,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    userSelect: 'none'
  },
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    color: theme.palette.text.secondary
  },
  tertiary: {
    color: '#BCC0C4'
  },
  white: {
    color: theme.palette.common.white
  }
}));

function Label({ color, className, ...rest }) {
  const classes = useStyles();
  return (
    <Typography
      variant='caption'
      component='div'
      className={clsx(classes.label, classes[color], className)}
      {...rest}
    />
  );
}

Label.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'white'])
};

Label.defaultProps = {
  color: 'primary'
};

export default Label;
