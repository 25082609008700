import React from 'react';
import DialogTitle from '../../ui/dialog/MultiStepTitle';

export default function FormDialogTitle(props) {
  return (
    <DialogTitle {...props}>
      Create new project
    </DialogTitle>
  );
}
