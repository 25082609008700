import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const DELETE_MESSAGE_COMMENT_MUTATION = gql`
  mutation DeleteMessageComment($messageId: ID!, $commentId: ID!) {
    deleteMessageComment(messageId: $messageId, commentId: $commentId) {
      id
    }
  }
`;

export default function useDeleteCommentMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(DELETE_MESSAGE_COMMENT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
