import React from 'react';
import PropTypes from 'prop-types';
import CellContentWrapper from '../../ui/todo/sheet/CellContentWrapper';
import DescriptionInput from '../../ui/todo/sheet/DescriptionInput';

function DescriptionCell({ value, onChange }) {

  const handleInputChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <CellContentWrapper>
      <DescriptionInput
        multiline
        placeholder='Add a description'
        value={value || ''}
        onChange={handleInputChange}
      />
    </CellContentWrapper>
  );
}

DescriptionCell.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default DescriptionCell;
