import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import CellSkeleton from './CellSkeleton';

function RowSkeleton({ colSpan }) {
  return (
    <Row>
      {Array.from(new Array(colSpan), (elem, index) => (
        <CellSkeleton key={index} />
      ))}
    </Row>
  );
}

RowSkeleton.propTypes = {
  colSpan: PropTypes.number.isRequired
};

export default RowSkeleton;
