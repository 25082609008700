import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ResizeHandle, { HANDLE_WIDTH } from './ResizeHandle';
import Cell from './Cell';

//
// TODO: This shit is unnecessarily complicated!
//

const useStyles = makeStyles((theme) => ({
  cellInterior: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  content: {
    height: '100%',
    width: `calc(100% - ${Math.floor(HANDLE_WIDTH/2)}px)`,
    paddingLeft: 0
  },
  contentFullWidth: {
    width: '100%'
  },
  contentPaddingLeft: {
    paddingLeft: Math.ceil(HANDLE_WIDTH/2)
  },
  contentInterior: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2)
  },
  interiorPaddingLeft: {
    paddingLeft: theme.spacing(2) - Math.ceil(HANDLE_WIDTH/2)
  }
}));

function HeaderCellSkeleton(props) {
  const classes = useStyles();

  const {
    borderRight,
    contentFullWidth,
    contentPaddingLeft,
  } = props;

  return (
    <Cell
      isHeaderCell
      sticky
      disablePointerEvents
    >
      <div className={classes.cellInterior}>
        <div
          className={clsx(
            classes.content,
            contentFullWidth && classes.contentFullWidth,
            contentPaddingLeft && classes.contentPaddingLeft
          )}
        >
          <div className={clsx(classes.contentInterior, {
            [classes.interiorPaddingLeft]: contentPaddingLeft
          })}>
            <Skeleton width='24%' height={24} />
          </div>
        </div>
        {(borderRight) && <ResizeHandle />}
      </div>
    </Cell>
  );
}

HeaderCellSkeleton.propTypes = {
  borderRight: PropTypes.bool,
  contentFullWidth: PropTypes.bool,
  contentPaddingLeft: PropTypes.bool
};

HeaderCellSkeleton.defaultProps = {
  borderRight: true,
  contentFullWidth: false,
  contentPaddingLeft: true
};

export default HeaderCellSkeleton;
