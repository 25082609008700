import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '../ui/button/Button';
import FormContainer from '../ui/misc/FullScreenFormContainer';
import Form from '../ui/misc/FullScreenForm';
import CreateAccountFormInput from './CreateAccountFormInput';
import CreateAccountFormFooter from './CreateAccountFormFooter';
import useSignUpMutation from './hooks/useSignUpMutation';
import Error from '../oops/Error';
import { gqlErrorDetails } from '../oops/helpers';

function CreateAccountForm({ onFinished }) {
  const [formData, setFormData] = React.useState({
    username: '',
    email: '',
    password: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const [signUp, { loading, error }] = useSignUpMutation({
    variables: formData,
    onFinished
  });

  const errorDetails = gqlErrorDetails(error);
  const isInputError = errorDetails['username'] ||
                       errorDetails['email'] ||
                       errorDetails['password'];

  return (
    <FormContainer>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          signUp().catch((err) => {});
        }}
      >
        <Typography variant='h4' component='h1'>
          Create account.
        </Typography>

        {error && !isInputError && (
          <Error error={error} style={{
            alignSelf: 'stretch'
          }} />
        )}

        <CreateAccountFormInput
          value={formData}
          onChange={handleChange}
          error={errorDetails}
        />

        <Button
          type='submit'
          variant='contained'
          color='success'
          size='large'
          fullWidth
          loading={loading}
        >
          Next
        </Button>
      </Form>
      <CreateAccountFormFooter />
    </FormContainer>
  );
}

CreateAccountForm.propTypes = {
  onFinished: PropTypes.func
};

export default CreateAccountForm;
