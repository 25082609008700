import React from 'react';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '../../ui/svg-icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    }
  },
  icon: ({ color }) => ({
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    borderRadius: '50%',
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .2s ease-in-out;',
    'input:hover ~ &': {
      transform: 'scale(1.2)',
      boxShadow: theme.shadows[1]
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    }
  }),

  checked: ({ color }) => ({
    color: theme.palette.getContrastText(color),
    fontSize: '1rem'
  })
}));

const ColorRadio = React.forwardRef(
  function ColorRadio({ value, ...otherProps }, ref) {
    const classes = useStyles({ color: value });

    const icon = <span className={classes.icon} />;

    const checkedIcon = (
      <span className={classes.icon}>
        <CheckIcon className={classes.checked} />
      </span>
    );

    return (
      <Radio
        ref={ref}
        disableRipple
        color='default'
        icon={icon}
        checkedIcon={checkedIcon}
        className={classes.root}
        value={value}
        {...otherProps}
      />
    );
  }
);

export default React.memo(ColorRadio);
