import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

function DeleteProjectConfirmText({ projectName }) {
  const name = (
    <Box
      component='span'
      color='text.primary'
      fontWeight={500}
    >
      {projectName}
    </Box>
  );

  return (
    <DialogContentText>
      Are you sure you want to delete {name}? Everything related to this
      project, including tasks and files will be removed from Taskbook
      immediately. This cannot be undone.
    </DialogContentText>
  );
}

DeleteProjectConfirmText.propTypes = {
  projectName: PropTypes.string.isRequired
};

export default DeleteProjectConfirmText;
