import React from 'react';
import PropTypes from 'prop-types';
import CommentsQuery from './CommentsQuery';
import CommentList from './CommentList';

function MessageComments({ projectId, messageId }) {
  return (
    <CommentsQuery
      projectId={projectId}
      messageId={messageId}
      fetchPolicy='network-only'
    >
      {(comments, otherQueryResults) => (
        <CommentList
          projectId={projectId}
          messageId={messageId}
          comments={comments}
          {...otherQueryResults}
        />
      )}
    </CommentsQuery>
  );
}

MessageComments.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired
};

export default MessageComments;
