import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import WorkspaceContext from '../../workspace/CurrentWorkspaceContext';
import {
  PROJECT_DETAILS_FRAGMENT,
  PROJECT_PINNED_FRAGMENT,
  PROJECT_STATS_FRAGMENT,
  PROJECT_MEMBERS_FRAGMENT
} from '../fragments';
import Error from '../../oops/Error';

const GET_MY_FAVORITE_PROJECTS_QUERY = gql`
  query GetMyFavoriteProjects($workspaceId: ID!) {
    myFavoriteProjects(workspaceId: $workspaceId) {
      ...ProjectDetails
      ...ProjectPinned
      ...ProjectStats
      ...ProjectMembers
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
  ${PROJECT_PINNED_FRAGMENT},
  ${PROJECT_STATS_FRAGMENT}
  ${PROJECT_MEMBERS_FRAGMENT}
`;

function MyFavoritesQuery({
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { currentWorkspaceId } = React.useContext(WorkspaceContext);

  const { loading, error, data, ...otherQueryResults } = useQuery(
    GET_MY_FAVORITE_PROJECTS_QUERY,
    {
      variables: { workspaceId: currentWorkspaceId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.myFavoriteProjects, otherQueryResults);
}

MyFavoritesQuery.propTypes = {
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default MyFavoritesQuery;
export { GET_MY_FAVORITE_PROJECTS_QUERY };
