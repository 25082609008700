import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from './icons/Person';
import PersonAddIcon from './icons/PersonAdd';
import PersonRemoveIcon from './icons/PersonRemove';
import TaskCreatedIcon from './icons/TaskCreated';
import TaskCompletedIcon from './icons/TaskCompleted';
import TaskUncompletedIcon from './icons/TaskUncompleted';
import FileUploadIcon from './icons/FileUpload';
import PaperPlaneIcon from './icons/PaperPlane';
import TextBubbleIcon from './icons/TextBubble';
import CalendarIcon from './icons/Calendar';
import ProjectIcon from './icons/Project';
import ActionIcon from './ActionIcon';

function getActionIconBasedOnActionType(actionType) {
  switch(actionType) {
    case 'GRANT_ACCESS':
      return <PersonAddIcon />;
    case 'REVOKE_ACCESS':
      return <PersonRemoveIcon />;
    case 'ADD_TODO':
      return <TaskCreatedIcon />;
    case 'COMPLETE_TODO':
      return <TaskCompletedIcon />;
    case 'REOPEN_TODO':
      return <TaskUncompletedIcon />;
    case 'UPLOAD_FILE':
      return <FileUploadIcon />;
    case 'REASSIGN_OWNER':
      return <PersonIcon />;
    case 'ADD_MESSAGE':
      return <PaperPlaneIcon />;
    case 'COMMENT_MESSAGE':
    case 'COMMENT_TODO':
    case 'COMMENT_EVENT':
    case 'COMMENT_FILE':
      return <TextBubbleIcon />;
    case 'ADD_EVENT':
    case 'RESCHEDULE_EVENT':
      return <CalendarIcon />;
    case 'CREATE_PROJECT':
      return <ProjectIcon />;
    default:
      return null;
  }
}

function ActivityIcon({ variant }) {
  return <ActionIcon icon={getActionIconBasedOnActionType(variant)} />;
}

ActivityIcon.propTypes = {
  variant: PropTypes.oneOf([
    'GRANT_ACCESS',
    'REVOKE_ACCESS',
    'ADD_TODO',
    'COMPLETE_TODO',
    'REOPEN_TODO',
    'UPLOAD_FILE',
    'REASSIGN_OWNER',
    'ADD_MESSAGE',
    'COMMENT_MESSAGE',
    'COMMENT_TODO',
    'COMMENT_EVENT',
    'COMMENT_FILE',
    'ADD_EVENT',
    'RESCHEDULE_EVENT',
    'CREATE_PROJECT'
  ])
};

export default ActivityIcon;
