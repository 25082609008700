import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { isSameDay, format } from 'date-fns';
import Button from '../../../ui/button/Button';

const useStyles = makeStyles((theme) => ({
  cell: {
    flex: '1 1 0%',
    textAlign: 'center',
    padding: theme.spacing(0.5, 0)
  },
  button: {
    padding: `4px 8px`,
    lineHeight: 1.45,
    borderRadius: 16,
    fontSize: '0.875rem'
  }
}));

function WeekDayCell({ date, onDateClick }) {
  const classes = useStyles();
  const isToday = isSameDay(date, new Date());

  return (
    <div className={classes.cell}>
      <Button
        className={classes.button}
        size='small'
        variant={isToday ? 'contained' : 'text'}
        color={isToday ? 'primary' : 'secondary'}
        onClick={onDateClick}
      >
        {format(date, 'EEE d')}
      </Button>
    </div>
  );
}

WeekDayCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onDateClick: PropTypes.func
};

export default WeekDayCell;
