import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  commonTabStyles: {
    flex: '0 0 auto',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    fontSize: '0.9375rem',
    textTransform: 'none',
    transition: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:  theme.spacing(5),
    padding: theme.spacing(0, 1),
    position: 'relative',
    verticalAlign: 'middle'
  }
}));
