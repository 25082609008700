import React from 'react';
import PropTypes from 'prop-types';
import MuiTable from '@material-ui/core/Table';
import TableContainer from './TableContainer';

function Table({ width, children }) {
  return (
    <TableContainer>
      <MuiTable
        stickyHeader
        size='small'
        style={{
          tableLayout: 'fixed',
          width: width
        }}
      >
        {children}
      </MuiTable>
    </TableContainer>
  );
}

Table.propTypes = {
  children: PropTypes.node
};

export default Table;
