import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ProjectDailySchedule from './daily-schedule/ProjectDailySchedule';

function DailySchedule({ startOfDay }) {
  const { projectId } = useParams();
  return (
    <ProjectDailySchedule
      projectId={projectId}
      date={startOfDay}
    />
  );
}

DailySchedule.propTypes = {
  startOfDay: PropTypes.instanceOf(Date).isRequired
};

export default DailySchedule;
