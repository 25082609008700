import React from 'react';
import PropTypes from 'prop-types';
import FollowTodoButton from '../../../ui/todo/details/FollowTodoButton';
import useFollowTodoMutation from './useFollowTodoMutation';

function AddCurrentUser({ todoId }) {
  const [addCurrentUser] = useFollowTodoMutation({
    variables: { todoId }
  });

  return <FollowTodoButton onClick={addCurrentUser} />;
}

AddCurrentUser.propTypes = {
  todoId: PropTypes.string.isRequired
};

export default AddCurrentUser;
