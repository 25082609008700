import React from 'react';
import PropTypes from 'prop-types';
import GenericActivityItem from './ui/GenericActivityItem';
import ActivityTextDetails from './ui/ActivityTextDetails';

function ReassignOwnerItem({ activity }) {
  const { type, actor, details, insertedAt } = activity;
  const [ownerName] = details;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='re-assigned the owner of this project'
      insertedAt={insertedAt}
    >
      <ActivityTextDetails text={`${ownerName} is now the owner.`} />
    </GenericActivityItem>
  );
}

ReassignOwnerItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['REASSIGN_OWNER']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default ReassignOwnerItem;
