import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:not(:first-child):not(:last-child)': {
      marginBottom: theme.spacing(2)
    },
    '& > :first-child': {
      marginBottom: theme.spacing(4)
    }
  }
}));

function FullScreenForm({ children, className, ...otherProps }) {
  const classes = useStyles();
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
      className={clsx(classes.form, className)}
      {...otherProps}
    >
      {children}
    </form>
  );
}

FullScreenForm.propTypes = {
  children: PropTypes.node
};

export default FullScreenForm;
