import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const SOME_MESSAGE_COMMENT_DELETED_SUBSCRIPTION = gql`
  subscription OnSomeMessageCommentDeleted($messageId: ID!) {
    messageCommentDeleted(messageId: $messageId) {
      id
    }
  }
`;

function CommentDeletedSubscriber({ messageId, subscribeToCommentDeleted }) {
  React.useEffect(() => {
    const unsubscribe = subscribeToCommentDeleted({
      document: SOME_MESSAGE_COMMENT_DELETED_SUBSCRIPTION,
      variables: { messageId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return prev; }

        const deletedId = subscriptionData.data.messageCommentDeleted.id;
        const comments = prev.projectMessage.comments.filter(({ id }) => (
          id !== deletedId
        ));

        return {
          projectMessage: {
            ...prev.projectMessage,
            comments: comments
          }
        };
      }
    });

    return () => unsubscribe();
  }, [messageId, subscribeToCommentDeleted]);

  return null;
}

CommentDeletedSubscriber.propTypes = {
  messageId: PropTypes.string.isRequired,
  subscribeToCommentDeleted: PropTypes.func.isRequired
};

export default CommentDeletedSubscriber;
