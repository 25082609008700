import React from 'react';
import Input from '../../new-ui/Input';

export default function PrimaryInput({ leftPadding, ...rest }) {
  return (
    <div style={{
      marginBottom: 16,
      paddingLeft: leftPadding
    }}>
      <Input
        fullWidth
        underline
        typography='header'
        horizontalPadding={false}
        background='never'
        inputProps={{spellCheck: false, autoComplete: 'off'}}
        {...rest}
      />
    </div>
  );
}
