import React from 'react';
import PropTypes from 'prop-types';
import SheetToolbar from '../../ui/todo/sheet/Toolbar';
import CompletionMenu, { COMPLETION_TYPES } from '../CompletionMenu';
import AssigneeFilter from '../AssigneeFilter';
import DueDateFilter, { DUE_DATE_RANGES } from '../DueDateFilter';
import CustomizeFieldMenu from '../CustomizeFieldMenu';

function Toolbar({ projectId, criteria, onChange }) {
  const handleCompletionTypeChange = (newCompletionType) => {
    const newCriteria = {
      ...criteria,
      completionType: newCompletionType
    };

    onChange(newCriteria);
  };

  const handleAssigneeChange = (newAssignee) => {
    const newCriteria = {
      ...criteria,
      assignee: newAssignee
    };

    onChange(newCriteria);
  };

  const handleDueDateRangeChange = (newDueDateRange) => {
    const newCriteria = {
      ...criteria,
      dueDateBetween: newDueDateRange
    };

    onChange(newCriteria);
  };

  return (
    <SheetToolbar>
      <CompletionMenu
        value={criteria.completionType}
        onChange={handleCompletionTypeChange}
      />

      <AssigneeFilter
        projectId={projectId}
        value={criteria.assignee}
        onChange={handleAssigneeChange}
      />

      <DueDateFilter
        value={criteria.dueDateBetween}
        onChange={handleDueDateRangeChange}
      />

      <CustomizeFieldMenu projectId={projectId} />
    </SheetToolbar>
  );
}

Toolbar.propTypes = {
  projectId: PropTypes.string.isRequired,

  // Criteria for todos in a given project
  criteria: PropTypes.shape({
    completionType: PropTypes.oneOf(COMPLETION_TYPES).isRequired,
    assignee: PropTypes.object,
    dueDateBetween: PropTypes.oneOf(
      DUE_DATE_RANGES.map(({ value }) => value)
    ).isRequired
  }).isRequired,

  // callback to execute when criteria changed.
  onChange: PropTypes.func.isRequired
};

export default Toolbar;
