import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbsBar from '../ui/page/BreadcrumbsBar';
import Breadcrumbs from '../ui/page/Breadcrumbs';
import BreadcrumbsLink from '../ui/page/BreadcrumbsLink';
import BreadcrumbsCurrent from '../ui/page/BreadcrumbsCurrent';
import useTeamsURL from './hooks/useTeamsURL';

function TeamBreadcrumbs({ teamName }) {
  const teamsURL = useTeamsURL();
  return (
    <BreadcrumbsBar>
      <Breadcrumbs>
        <BreadcrumbsLink to={teamsURL} primary='Teams' />
        <BreadcrumbsCurrent>
          {teamName}
        </BreadcrumbsCurrent>
      </Breadcrumbs>
    </BreadcrumbsBar>
  );
}

TeamBreadcrumbs.propTypes = {
  teamName: PropTypes.string.isRequired
};

export default React.memo(TeamBreadcrumbs);
