import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const UPDATE_PROJECT_COLOR_MUTATION = gql`
  mutation UpdateProjectColor($projectId: ID!, $color: String!) {
    updateProject(projectId: $projectId, changes: {color: $color}) {
      id
      color
    }
  }
`;

export default function useUpdateProjectColorMutation(options) {
  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  return useMutation(UPDATE_PROJECT_COLOR_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
