import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';

const TODO_DETAILS_QUERY = gql`
  query GetTodoDetails($todoId: ID!) {
    todoItem(todoItemId: $todoId) {
      id
      name
      description

      assignee {
        id
        username
        email
        avatar
      }

      dueDate
      priority
      completed

      todoList {
        id
        name
        project { id }
      }
    }
  }
`;

function TodoDetailsQuery({
  todoId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    TODO_DETAILS_QUERY,
    {
      variables: { todoId },
      ...otherQueryProps
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.todoItem, otherQueryResults);
}

TodoDetailsQuery.propTypes = {
  todoId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default TodoDetailsQuery;
export { TODO_DETAILS_QUERY };
