import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../ui/input/TextField';
import ChangeRoleActions from './ChangeRoleActions';

function ChangeRoleForm({ label, currentRole, onSubmit, onCancel }) {
  const inputRef = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) { onSubmit(inputRef.current.value); }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    if (onCancel) { onCancel(event); }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        inputRef={inputRef}
        fullWidth
        autoFocus
        size='small'
        label={label}
        defaultValue={currentRole}
        helperText={(
          <ChangeRoleActions
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        )}
      />
    </form>
  );
}

ChangeRoleForm.propTypes = {
  label: PropTypes.string.isRequired,
  currentRole: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default ChangeRoleForm;
