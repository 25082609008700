import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const UPDATE_EVENT_SUBSCRIBERS_MUTATION = gql`
  mutation UpdateEventSubscribers($eventId: ID!, $userIds: [ID]) {
    updateEventFollowers(eventId: $eventId, userIds: $userIds) {
      id
    }
  }
`;

export default function useUpdateSubscribersMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(UPDATE_EVENT_SUBSCRIBERS_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
