import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import Section from '../../ui/page/Section';
import SectionHeader from '../../ui/page/SectionHeader';
import SectionTitle from '../../ui/page/SectionTitle';
import SectionSearchInput from '../../ui/page/SectionSearchInput';
import NavTabs from '../../ui/tabs/NavTabs';
import TabLink from '../../ui/tabs/TabLink';
import ActiveProjects from './ActiveProjects';
import ArchivedProjects from './ArchivedProjects';

function Projects() {
  const { path, url } = useRouteMatch();
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Projects</SectionTitle>
        <SectionSearchInput placeholder='Search' />
      </SectionHeader>

      <div style={{marginBottom: 16}}>
        <NavTabs>
          <TabLink to={`${url}/active`} primary='Active' exact />
          <TabLink to={`${url}/archived`} primary='Archived' exact />
        </NavTabs>
      </div>

      <Switch>
        <Route path={path} exact>
          <Redirect to={`${url}/active`} />
        </Route>
        <Route path={`${path}/active`} exact>
          <ActiveProjects />
        </Route>
        <Route path={`${path}/archived`} exact>
          <ArchivedProjects />
        </Route>
      </Switch>
    </Section>
  );
}

export default Projects;
