import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import NotificationIcon from '../../icons/16x16/Bell';
import Container from './RemindersFieldContainer';
import FormField from './FormField';
import AddNotificationButton from './AddNotificationButton';
import TimeReminderItem from './TimeReminderItem';
import { timeEventReminder } from './defaultReminders';
import { MAX_NUM_REMINDERS } from './constants';

function TimeReminderList({ timeReminders, onChange }) {
  const handleAddReminder = () => {
    const newReminder = {
      ...timeEventReminder,
      _id: uuidv4()
    };

    onChange((prevData) => ({
      ...prevData,
      timeReminders: [...prevData.timeReminders, newReminder]
    }));
  };

  return (
    <FormField label={<NotificationIcon />}>
      <Container>
        {timeReminders.map((reminder) => (
          <TimeReminderItem
            key={reminder._id}
            value={reminder}
            onChange={onChange}
          />
        ))}
        {(timeReminders.length < MAX_NUM_REMINDERS) && (
          <AddNotificationButton onClick={handleAddReminder} />
        )}
      </Container>
    </FormField>
  );
}

TimeReminderList.propTypes = {
  timeReminders: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimeReminderList;
