import React from 'react';
import PropTypes from 'prop-types';
import Composer from '../../../ui/comments/Composer';
import ComposerAvatar from '../../../ui/comments/ComposerAvatar';
import ComposerEditor from '../../../ui/comments/ComposerEditor';
import useCreateTodoCommentMutation from './useCreateCommentMutation';
import { TODO_COMMENTS_QUERY } from './CommentsQuery';
import { useContainerContext } from '../ContainerContext';
import { generateNewCommentId } from './helper';

function CreateCommentForm({ user, todoId, projectId }) {
  const { scrollToBottom } = useContainerContext();

  const [createComment] = useCreateTodoCommentMutation({
    variables: { todoId },

    update: (cache, { data: { createTodoComment: newComment }}) => {
      const data = cache.readQuery({
        query: TODO_COMMENTS_QUERY,
        variables: { todoId }
      });

      let comments = [...data.todoItem.comments];

      // We have to check for existance here because the comment may have
      // already been added by subscription.
      const found = comments.some(({ id }) => ( id === newComment.id ));
      if (!found) {
        comments = [...comments, newComment];
      }

      cache.writeQuery({
        query: TODO_COMMENTS_QUERY,
        variables: { todoId },
        data: {
          todoItem: {
            ...data.todoItem,
            comments: comments
          }
        }
      });
    },

    onCompleted: (data) => {
      scrollToBottom();
    }
  });

  const handleSubmit = ({ text, files }) => {
    createComment({
      variables: { text, files },
      optimisticResponse: {
        createTodoComment: {
          id: generateNewCommentId(),
          text: text,
          files: [],
          autoGenerated: false,
          severity: 'DEFAULT',
          author: user,
          updatedAt: new Date().toISOString(),
          __typename: 'Comment'
        }
      }
    });
  };

  return (
    <Composer>
      <ComposerAvatar user={user} />
      <ComposerEditor projectId={projectId} onSubmit={handleSubmit}/>
    </Composer>
  )
}

CreateCommentForm.propTypes = {
  user: PropTypes.object.isRequired,
  todoId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default CreateCommentForm;
