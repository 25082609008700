import React from 'react';
import PropTypes from 'prop-types';
import DialogContext from '../../ui/dialog/DialogContext';
import ToolbarEditButtton from './ui/header/ToolbarEditButton';
import FormDialog from './edit/FormDialog';

function EditEventDialog({ event, onOpen }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    if (onOpen) {
      onOpen();
    }

    openDialog({
      dialogContent: <FormDialog event={event} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return <ToolbarEditButtton onClick={handleClick} />;
}

EditEventDialog.propTypes = {
  event: PropTypes.object.isRequired,
  onOpen: PropTypes.func
};

export default EditEventDialog;
