import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';

const useStyles = makeStyles((theme) => ({
  timelineRoot: {
    padding: 0,
    margin: 0
  }
}));

export default function ActivityFeed(props) {
  const classes = useStyles();
  return <Timeline className={classes.timelineRoot} {...props} />;
}
