import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';

function UploadFileNameCell({ name }) {
  return (
    <Cell>
      {name}
    </Cell>
  );
}

UploadFileNameCell.propTypes = {
  name: PropTypes.string.isRequired
};

export default UploadFileNameCell;
