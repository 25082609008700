import React from 'react';
import PropTypes from 'prop-types';
import CardGridItem from '../../ui/card/CardGridItem';
import MembershipCard from './MembershipCard';

function MemberGridItem({ member, ...rest }) {
  return (
    <CardGridItem>
      <MembershipCard membership={member} {...rest} />
    </CardGridItem>
  );
}

MemberGridItem.propTypes = {
  member: PropTypes.object.isRequired
};

export default React.memo(MemberGridItem);
