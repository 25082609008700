import { useCallback } from 'react';
import useRemoveMemberMutation from '../hooks/useRemoveMemberMutation';
import useReassignOwnerMutation from '../hooks/useReassignOwnerMutation';
import useChangeRoleMutation from './useChangeRoleMutation';

export default function useMembershipManager({ updateQuery }) {
  //
  // INTERNAL
  //

  const [removeMember] = useRemoveMemberMutation({
    update: (cache, result) => {
      const goneId = result.data.removeUserFromProject.id;
      updateQuery((prev) => ({
        project: {
          ...prev.project,
          members: prev.project.members.filter(({ id }) => id !== goneId)
        }
      }));
    }
  });

  const [reassignOwner] = useReassignOwnerMutation({
    update: (cache, result) => {
      const newOwner = result.data.reassignProjectOwner;

      updateQuery((prev) => ({
        project: {
          ...prev.project,
          members: prev.project.members.map((m) => ({
            ...m,
            owner: (m.id === newOwner.id)
          }))
        }
      }));
    }
  });

  const [changeRole] = useChangeRoleMutation();

  //
  // API
  //

  const handleRoleChange = useCallback((membershipId, newRole) => {
    const role = newRole.trim();

    changeRole({
      variables: { membershipId, role },
      optimisticResponse: {
        changeProjectRole: {
          id: membershipId,
          role: role,
          __typename: 'ProjectMembership'
        }
      }
    });
  }, [changeRole]);

  const handleSetAsOwner = useCallback((membership) => {
    reassignOwner({
      variables: { membershipId: membership.id },
      optimisticResponse: {
        reassignProjectOwner: {
          id: membership.id,
          __typename: 'ProjectMembership'
        }
      }
    });
  }, [reassignOwner]);

  const handleRemove = useCallback((membershipId) => {
    removeMember({
      variables: { membershipId },
      optimisticResponse: {
        removeUserFromProject: {
          id: membershipId,
          __typename: 'ProjectMembership'
        }
      }
    });
  }, [removeMember]);

  return {
    handleRoleChange,
    handleSetAsOwner,
    handleRemove
  };
}
