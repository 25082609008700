import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const SOME_TODO_LIST_UPDATED_SUBSCRIPTION = gql`
  subscription OnSomeTodoListUpdated($projectId: ID!) {
    someTodoListUpdated(projectId: $projectId) {
      id
      name
      description
    }
  }
`;

export default function useSomeTodoListUpdatedSubscription(options) {
  const { projectId, ...otherOptions } = options;
  return useSubscription(SOME_TODO_LIST_UPDATED_SUBSCRIPTION, {
    variables: { projectId },
    ...otherOptions
  });
}
