import React from 'react';
import PropTypes from 'prop-types';
import SubscribersBar from '../ui/subscribers/SubscribersBar';
import Subscribers from '../ui/subscribers/Subscribers';
import SubscribersList from '../ui/subscribers/SubscribersList';
import AvatarGroup from '../ui/subscribers/SubscribersAvatarGroup';
import UpdateButton from '../ui/subscribers/UpdateSubscribersButton';
import MeButton from './MeButton';
import UpdateSubscribers from './UpdateSubscribers';

function SubscribersBarWithData({ projectId, eventId, subscribers }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    <SubscribersBar>
      <Subscribers>
        <SubscribersList>
          {!open && (
            <React.Fragment>
              <AvatarGroup subscribers={subscribers} />
              <UpdateButton onClick={handleOpen} />
            </React.Fragment>
          )}

          <UpdateSubscribers
            projectId={projectId}
            eventId={eventId}
            subscribers={subscribers}
            open={open}
            onClose={handleClose}
          />
        </SubscribersList>
      </Subscribers>

      {!open && (
        <MeButton
          eventId={eventId}
          subscribers={subscribers}
        />
      )}
    </SubscribersBar>
  );
}

SubscribersBarWithData.propTypes = {
  projectId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  subscribers: PropTypes.array.isRequired
};

export default SubscribersBarWithData;
