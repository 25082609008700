import React from 'react';
import PropTypes from 'prop-types';
import CellContentWrapper from './CellContentWrapper';
import AddUserButton from './AddUserButton';
import AutocompleteUser from './AutocompleteUser';

function UserPicker(props) {
  const {
    value,
    onChange,
    options,
    loading,
    fetchUsers,
    placeholderText
  } = props;

  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef();

  const handleButtonClick = () => {
    setOpen(true);

    if (fetchUsers) {
      fetchUsers();
    }
  };

  const handleUserChange = (event, newUser) => {
    if (onChange) {
      onChange(newUser);
    }
  };

  const handleClose = () => {
    // restore focus back to button when modal is closed.
    buttonRef.current.focus();
    setOpen(false);
  };

  return (
    <CellContentWrapper>
      <AddUserButton
        placeholderText={placeholderText}
        ref={buttonRef}
        onClick={handleButtonClick}
        user={value}
        invisible={open}
      />

      {open && (
        <AutocompleteUser
          loading={loading}
          options={options}
          value={value}
          onChange={handleUserChange}
          onClose={handleClose}
        />
      )}
    </CellContentWrapper>
  );
}

// TODO: This should not be living here.
const UserShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.string
});

UserPicker.propTypes = {
  options: PropTypes.arrayOf(UserShape).isRequired,
  value: UserShape,
  onChange: PropTypes.func,

  // Typically this function is the result of `useLazyQuery`
  fetchUsers: PropTypes.func,
  loading: PropTypes.bool,

  placeholderText: PropTypes.string
};

UserPicker.defaultProps = {
  loading: false,
  fetchUsers: () => {}
};

export default UserPicker;
