import React from 'react';
import JoinOrLeaveButton from '../../../ui/message/JoinOrLeaveButton';
import BellIcon from '../../../icons/18x18/BellFill';

export default function UnfollowFileButton(props) {
  const tooltip = 'Stop getting notifications about activity on this file.';
  return (
    <JoinOrLeaveButton
      label='Unsubscribe'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
