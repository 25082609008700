import React from 'react';
import MuiBadge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    filter: 'none !important'
  }
}))((props) => (
  <MuiBadge
    color='secondary'
    max={9}
    {...props}
  />
));
