import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../ui/button/IconButton';
import CloseIcon from '../../icons/20x20/Close';
import ArrowBackIcon from '../../icons/20x20/ArrowBack';
import InfoIcon from '../../icons/20x20/InfoCircleFill';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },

  // Padding applied to the root if no `onGoBack` was provided.
  leftPadding: {
    paddingLeft: theme.spacing(3)
  },

  // Padding applied to the root if neither `onClose` nor `onInfo`
  // were provided
  rightPadding: {
    paddingRight: theme.spacing(3)
  },

  title: {
    flexGrow: 1,
    fontWeight: 700,
    lineHeight: 1.4
  },

  centeredTitle: {
    textAlign: 'center'
  },

  leftToolbar: {
    alignSelf: 'flex-start',
    flex: '0 0 auto',
    marginRight: theme.spacing(1)
  },

  rightToolbar: {
    alignSelf: 'flex-start',
    flex: '0 0 auto',
    marginLeft: theme.spacing(1),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));

function MultiStepTitle({
  children,
  onGoBack,
  onInfo,
  onClose,
  noWrap,
  centered,
  rootClassName
}) {
  const classes = useStyles();

  const hasGoBack = Boolean(onGoBack);
  const hasCloseOrInfo = Boolean(onClose) || Boolean(onInfo);

  return (
    <div
      className={clsx(classes.root, {
        [classes.leftPadding]: !hasGoBack,
        [classes.rightPadding]: !hasCloseOrInfo
      }, rootClassName)}
    >
      {hasGoBack && (
        <div className={classes.leftToolbar}>
          <IconButton
            aria-label='Go back'
            color='secondary'
            size='small'
            icon={ <ArrowBackIcon /> }
            onClick={onGoBack}
          />
        </div>
      )}

      <Typography
        component='h2'
        variant='h6'
        className={clsx(classes.title, {
          [classes.centeredTitle]: centered
        })}
        noWrap={noWrap}
      >
        {children}
      </Typography>

      {hasCloseOrInfo && (
        <div className={classes.rightToolbar}>
          {onInfo && (
            <IconButton
              aria-label='Click for more informations'
              color='secondary'
              size='small'
              icon={ <InfoIcon /> }
              onClick={onInfo}
            />
          )}

          {onClose && (
            <IconButton
              aria-label='close'
              color='secondary'
              size='small'
              icon={ <CloseIcon /> }
              onClick={onClose}
            />
          )}
        </div>
      )}
    </div>
  );
}

MultiStepTitle.propTypes = {
  children: PropTypes.node,

  // Callback fired when go-back button is clicked.
  onGoBack: PropTypes.func,

  // Callback fired when close button is clicked
  onClose: PropTypes.func,

  // Callback fired when info button is clicked
  onInfo: PropTypes.func,

  // Whether to wrap the title text of it's far too long.
  noWrap: PropTypes.bool,

  // Whether to center the title text or not.
  centered: PropTypes.bool,

  // Additional clasName applied to root element
  rootClassName: PropTypes.string
};

MultiStepTitle.defaultProps = {
  noWrap: true,
  centered: true
};

export default MultiStepTitle;
