import React from 'react';
import PropTypes from 'prop-types';
import StyledCommentsSection from './ui/content/CommentsSection';
import CommentsQuery from './comments/CommentsQuery';
import CommentList from './comments/CommentList';

function CommentsSection({ eventId, projectId }) {
  return (
    <StyledCommentsSection>
      <CommentsQuery
        eventId={eventId}
        fetchPolicy='network-only'
      >
        {(comments, otherQueryResults) => (
          <CommentList
            projectId={projectId}
            eventId={eventId}
            comments={comments}
            {...otherQueryResults}
          />
        )}
      </CommentsQuery>
    </StyledCommentsSection>
  );
}

CommentsSection.propTypes = {
  eventId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default CommentsSection;
