import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5)
  },
  placeholder: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: '1.125rem',
    color: theme.palette.text.secondary,
    fontWeight: 500
  }
}));

const ListItemPlaceholder = React.forwardRef(
  function ListItemPlaceholder({ placeholder, ...rest }, ref) {
    const classes = useStyles();
    return (
      <ListItem ref={ref} className={classes.root} {...rest}>
        <Typography variant='body1' className={classes.placeholder}>
          {placeholder}
        </Typography>
      </ListItem>
    );
  }
);

ListItemPlaceholder.propTypes = {
  placeholder: PropTypes.string
};

ListItemPlaceholder.defaultProps = {
  placeholder: 'Nothing new for you.'
};

export default ListItemPlaceholder;
