import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const CREATE_FOLDER_MUTATION = gql`
  mutation CreateFolder($name: String!, $projectId: ID!, $parentId: ID) {
    createFolder(name: $name, projectId: $projectId, parentId: $parentId) {
      id
    }
  }
`;

export default function useCreateFolderMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(CREATE_FOLDER_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
