import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NewMessageButton from './NewMessageButton';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(2, 7.5)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(2, 15)
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 700
  }
}));

function MessageBoardHeader() {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const newMessageUrl = `${url}/new`;

  const renderLink = React.useMemo(() => {
    return React.forwardRef(function Link(linkProps, ref) {
      return (
        <RouterLink
          role={undefined}
          to={{pathname: newMessageUrl, state: { from: url }}}
          ref={ref}
          {...linkProps}
        />
      )
    });
  }, [url, newMessageUrl]);

  return (
    <div className={classes.header}>
      <Typography variant='h6' component='h1' className={classes.title}>
        Message Board
      </Typography>
      <NewMessageButton component={renderLink} />
    </div>
  );
}

export default MessageBoardHeader;
