import React from 'react';
import ZoomInIcon from '../../icons/18x18/PlusMagnifyingGlass';
import IconButton from './ToolbarIconButton';

export default function FileDetailsZoomInButton(props) {  
  return (
    <IconButton
      aria-label='Zoom in'
      color={props.disabled ? 'disabled' : 'secondary'}
      icon={<ZoomInIcon />}
      {...props}
    />
  );
}
