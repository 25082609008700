import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PageSubtitle from './PageSubtitle';

export default function PageSubtitleSkeleton() {
  return (
    <PageSubtitle>
      <Skeleton variant='rect' height={64}  style={{borderRadius: 8}} />
    </PageSubtitle>
  );
}
