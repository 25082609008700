import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(1)
  }
}));

export default function Checkbox(props) {
  const classes = useStyles();
  return (
    <MuiCheckbox
      size='small'
      className={classes.checkbox}
      {...props}
    />
  );
}
