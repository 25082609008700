import React from 'react';
import MenuItem from '../../menu/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    borderRadius: 0,
    padding: theme.spacing(1),
    '&.Mui-focusVisible': {
      outline: 0
    }
  }
}));

export default React.forwardRef(function StyledMenuItem(props, ref) {
  const classes = useStyles();
  return <MenuItem ref={ref} className={classes.menuItem} {...props} />;
});
