import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const REMOVE_UNSAVED_UPLOADS_MUTATION = gql`
  mutation RemoveUnsavedUploads($paths: [String]) {
    removeUnsavedUploads(paths: $paths)
  }
`;

export default function useRemoveUnsavedUploadsMutation(options) {
  return useMutation(REMOVE_UNSAVED_UPLOADS_MUTATION, options);
}
