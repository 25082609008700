import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';

const LIST_FOLDERS_IN_PROJECT_QUERY = gql`
  query ListFoldersInProject($projectId: ID!) {
    project(projectId: $projectId) {
      id
      folders {
        id
        name
      }
    }
  }
`;

function ListFoldersInProjectQuery({
  projectId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    LIST_FOLDERS_IN_PROJECT_QUERY,
    {
      variables: { projectId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.project.folders, otherQueryResults);
}

ListFoldersInProjectQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ListFoldersInProjectQuery;

export function useListFoldersInProjectLazyQuery(options) {
  return useLazyQuery(LIST_FOLDERS_IN_PROJECT_QUERY, options);
}
