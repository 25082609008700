import formatTimeOfDay from './formatTimeOfDay';

export default function addTime(t1, t2) {
  const t1Minutes = t1.hours * 60 + t1.minutes;
  const t2Minutes = t2.hours * 60 + t2.minutes;
  const totalMinutes = t1Minutes + t2Minutes;

  const hours = ~~(totalMinutes / 60) % 24;
  const minutes = totalMinutes % 60;

  return formatTimeOfDay({ hours, minutes });
}
