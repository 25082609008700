import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isSameDay, format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from '../../ui/button/Overlay';
import Label from './Label';

const useStyles = makeStyles((theme) => ({
  day: {
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover $overlay': { opacity: 1.0 },
    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  },
  selected: {
    backgroundColor: '#E7F3FF',
    color: theme.palette.primary.main,
    '&:active $overlay': {
      backgroundColor: 'rgba(25, 110, 255, 0.15)'
    }
  },
  today: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.15)'
    },
    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
  },
  overlay: { }
}));

function Day({ date, onSelect, selected, secondary, ...rest }) {
  const classes = useStyles();

  const handleClick = (event) => {
    if (onSelect) {
      onSelect(date);
    }
  };

  return (
    <Label
      color={secondary ? 'tertiary' : 'primary'}
      className={clsx(classes.day, {
        [classes.selected]: selected,
        [classes.today]: isSameDay(date, new Date())
      })}
      onClick={handleClick}
      {...rest}
    >
      {format(date, 'd')}
      <Overlay className={classes.overlay} />
    </Label>
  );
}

Day.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  secondary: PropTypes.bool
};

Day.defaultProps = {
  selected: false,
  secondary: false
};

export default Day;
