import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { PROJECT_MEMBERSHIP_DETAILS_FRAGMENT } from '../fragments';
import Error from '../../oops/Error';

const SEARCH_MEMBER_IN_PROJECT_QUERY = gql`
  query SearchMemberInProject($projectId: ID!, $filter: ProjectMemberFilter) {
    project(projectId: $projectId) {
      id
      members(filter: $filter) @connection(key: "member_search_results") {
        ...ProjectMembershipDetails
      }
    }
  }
  ${PROJECT_MEMBERSHIP_DETAILS_FRAGMENT}
`;

function SearchMemberInProjectQuery({
  projectId,
  criteria,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    SEARCH_MEMBER_IN_PROJECT_QUERY,
    {
      variables: {
        projectId,
        filter: criteria
      },
      fetchPolicy: 'network-only',
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.project.members, otherQueryResults);
}

SearchMemberInProjectQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  criteria: PropTypes.object,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

SearchMemberInProjectQuery.defaultProps = {
  criteria: {}
};

export default SearchMemberInProjectQuery;
export { SEARCH_MEMBER_IN_PROJECT_QUERY }
