import { hashCode } from '../../../utils/helpers';

export const AVATAR_COLORS = [
  // Dark
  '#C32D0E', '#9C8AB4','#F3425F', '#9360F7', '#B0377B',
  '#815830', '#0087BF', '#24917D', '#407887', '#5F6673',

  // Light
  '#FF66BF', '#FFBA00', '#F5888D', '#B2B7BB', '#A2B01F',
  '#72ACAE', '#2ABBA7', '#39AFD5', '#FB724B', '#A3CEDF'
]

export const AVATAR_DEFAULT_COLOR = '#B2B7BB';

export function getRandomAvatarColor() {
  return AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)];
}

export function fromEmailToAvatarColor(email) {
  const hash = hashCode(email);
  const index = hash % AVATAR_COLORS.length;
  return AVATAR_COLORS[Math.abs(index)];
}
