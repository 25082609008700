import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  projectName: {
    fontWeight: 700,
    lineHeight: 1.2,
    marginRight: theme.spacing(1)
  }
}));

function ProjectHeaderProjectName({ name }) {
  const classes = useStyles();

  return (
    <Typography noWrap variant='h6' className={classes.projectName}>
      {name}
    </Typography>
  );
}

function ProjectHeaderProjectNameSkeleton() {
  return (
    <div style={{flexGrow: 1}}>
      <Skeleton width='40%' height={20} />
    </div>
  );
}

ProjectHeaderProjectName.propTypes = {
  name: PropTypes.string.isRequired
};

export default ProjectHeaderProjectName;
export { ProjectHeaderProjectNameSkeleton };
