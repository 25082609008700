import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Menu from '../../ui/menu/Menu';
import ChangeMembershipRoleMenuItem from './ChangeMembershipRoleMenuItem';
import DeactivateMembershipMenuItem from './DeactivateMembershipMenuItem';
import { ROLE_LIST } from '../helpers';

function MembershipMenu({ membership, currentUserRole, ...rest }) {
  const { id, role, user } = membership;
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: 320
        }
      }}
      {...rest}
    >
      <ChangeMembershipRoleMenuItem
        membership={{ id, role, username: user.username }}
        currentUserRole={currentUserRole}
      />
      <Divider component='li' style={{ margin: 8}} />
      <DeactivateMembershipMenuItem
        membershipId={id}
        username={user.username}
      />
    </Menu>
  );
}

MembershipMenu.propTypes = {
  membership: PropTypes.object.isRequired,
  currentUserRole: PropTypes.oneOf(ROLE_LIST).isRequired
};

export default MembershipMenu;
