import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { TEAM_MEMBERSHIP_DETAILS_FRAGMENT } from '../fragments';
import Error from '../../oops/Error';

const SEARCH_MEMBER_IN_TEAM_QUERY = gql`
  query SearchMemberInTeam($teamId: ID!, $filter: TeamMemberFilter) {
    team(teamId: $teamId) {
      id
      members(filter: $filter) @connection(key: "member_search_results") {
        ...TeamMembershipDetails
      }
    }
  }
  ${TEAM_MEMBERSHIP_DETAILS_FRAGMENT}
`;

function SearchMemberInTeamQuery(props) {
  const { teamId, criteria, onLoading, onError, children, ...rest } = props;
  const { loading, error, data, ...otherResults } = useQuery(
    SEARCH_MEMBER_IN_TEAM_QUERY,
    {
      variables: {
        teamId,
        filter: criteria
      },
      fetchPolicy: 'network-only',
      ...rest
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />
  return children(data.team.members, otherResults);
}

SearchMemberInTeamQuery.propTypes = {
  teamId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  children: PropTypes.func.isRequired,
  criteria: PropTypes.object,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

SearchMemberInTeamQuery.defaultProps = {
  criteria: {}
};

export default SearchMemberInTeamQuery;
export { SEARCH_MEMBER_IN_TEAM_QUERY };
