import React from 'react';
import Autocomplete from '../Autocomplete';

// These functions require the shape of the time object to be of the
// form: `{ hours, minutes, label }`.
const renderOption = ({ label }) => label;
const getOptionLabel = ({ label }) => label;
const getOptionSelected = (option, value) => (
  option.hours === value.hours &&
  option.minutes === value.minutes
);

export default function TimeAutocomplete(props) {
  return (
    <Autocomplete
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      {...props}
    />
  );
}
