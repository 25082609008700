import React from 'react';
import PropTypes from 'prop-types';
import FileMenu from '../ui/file/FileMenu';
import Divider from '../ui/menu/MenuItemDivider';
import EditFileMenuItem from '../ui/file/EditFileMenuItem';
import OpenInNewMenuItem from '../ui/file/OpenInNewMenuItem';
import DeleteFileMenuItem from '../ui/file/DeleteFileMenuItem';
import DialogContext from '../ui/dialog/DialogContext';
import OptionDialog from './upload-options/OptionDialog';
import { uploadOption } from './upload-options/OptionList';

function UploadMenu({ upload }) {
  const { previewUrl } = upload;
  const { openDialog } = React.useContext(DialogContext);

  const openOptionDialog = (option) => {
    openDialog({
      dialogContent: (
        <OptionDialog
          upload={upload}
          defaultOption={option}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  const openEditOptionDialog = () => {
    openOptionDialog(uploadOption.EDIT_UPLOAD)
  };

  const openDeleteOptionDialog = () => {
    openOptionDialog(uploadOption.DELETE_UPLOAD);
  };

  return (
    <FileMenu>
      <EditFileMenuItem onClick={openEditOptionDialog} />
      <OpenInNewMenuItem href={previewUrl} />
      <Divider />
      <DeleteFileMenuItem onClick={openDeleteOptionDialog} />
    </FileMenu>
  );
}

UploadMenu.propTypes = {
  upload: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ext: PropTypes.string.isRequired,
    notes: PropTypes.string,
    previewUrl: PropTypes.string.isRequired,
    parentUrl: PropTypes.string.isRequired
  }).isRequired
};

export default UploadMenu;
