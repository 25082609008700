import React from 'react';
import PropTypes from 'prop-types';
import AvatarGroup from '../ui/card/CardAvatarGroup';
import UserAvatar from '../account/UserAvatar';

function ProjectCardAvatarGroup({ members }) {
  return (
    <AvatarGroup max={8}>
      {members.map(({ id, user }) => (
        <UserAvatar key={id} user={user} />
      ))}
    </AvatarGroup>
  );
}

ProjectCardAvatarGroup.propTypes = {
  members: PropTypes.array.isRequired
};

export default React.memo(ProjectCardAvatarGroup);
