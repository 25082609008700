import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const MESSAGE_FOLLOWERS_CHANGED_SUBSCRIPTION = gql`
  subscription OnMessageFollowersChanged($messageId: ID!) {
    messageFollowersChanged(messageId: $messageId) {
      id
      followers {
        id
        username
        email
        avatar
      }
    }
  }
`;

function FollowersChangedSubscriber({
  messageId,
  subscribeToFollowersChanged
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToFollowersChanged({
      document: MESSAGE_FOLLOWERS_CHANGED_SUBSCRIPTION,
      variables: { messageId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newFollowers =
          subscriptionData.data.messageFollowersChanged.followers;

        return {
          projectMessage: {
            ...prev.projectMessage,
            followers: newFollowers
          }
        };
      }
    });

    return () => unsubscribe();
  }, [messageId, subscribeToFollowersChanged]);

  return null;
}


FollowersChangedSubscriber.propTypes = {
  messageId: PropTypes.string.isRequired,
  subscribeToFollowersChanged: PropTypes.func.isRequired
};

export default FollowersChangedSubscriber;
