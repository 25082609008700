import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    margin: '0 auto',
    maxWidth: theme.spacing(136),

    [theme.breakpoints.up(768)]: {
      marginTop: theme.spacing(3),
      width: '90%',
      borderRadius: '8px 8px 0px 0px',
      boxShadow: theme.shadows[1]
    },
  },
  divider: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar
  }
}));

export default function MessageBoard({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Divider className={classes.divider} />
      <div className={classes.root}>
        {children}
      </div>
    </div>
  );
}
