import React from 'react';
import PropTypes from 'prop-types';
import { TODO_CRITERIA_SHAPE } from '../helpers';
import { areTwoColsArraysEqual, areTwoRowsArraysEqual } from './helpers';
import SectionHeader from './SectionHeader';
import SectionBody from './SectionBody';
import SectionFooter from './SectionFooter';

function Section({ id, name, todoCriteria, cols, rows, rootUrl }) {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandedChange = React.useCallback((newExpanded) => {
    setExpanded(newExpanded);
  }, []);

  return (
    <React.Fragment>
      <SectionHeader
        sectionId={id}
        sectionName={name}
        todoCriteria={todoCriteria}
        cols={cols}
        expanded={expanded}
        onExpandedChange={handleExpandedChange}
        rootUrl={rootUrl}
      />

      {expanded && (
        <>
          <SectionBody
            sectionId={id}
            todoCriteria={todoCriteria}
            rows={rows}
            cols={cols}
          />

          <SectionFooter
            cols={cols}
            sectionId={id}
            todoCriteria={todoCriteria}
            rootUrl={rootUrl}
          />
        </>
      )}
    </React.Fragment>
  );
}

Section.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

// This is a VERY IMPORTANT optimization to prevent any section with
// large number of todo items from re-rendering unnecessary, but
// it could be worse for sections whose number of todo items
// are small.
function areSectionPropsEqual(prevSection, nextSection) {
  return prevSection.id === nextSection.id &&
         prevSection.name === nextSection.name &&
         prevSection.todoCriteria === nextSection.todoCriteria &&
         areTwoColsArraysEqual(prevSection.cols, nextSection.cols) &&
         areTwoRowsArraysEqual(prevSection.rows, nextSection.rows);
};

export default React.memo(Section, areSectionPropsEqual);
