import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: theme.spacing(5),
    fontSize: '1.25rem'/* chip's height + 2px gap */
  },
  dragging: {
    cursor: 'move',

    // While user is dragging around, we have to turn off mouse-events
    // for children (including nested ones).
    '& *': {
      cursor: 'move !important',
      pointerEvents: 'none !important'
    }
  }
}));

const StyledAllDayGrid = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { numRows, isDragging, ...rest } = props;

  return (
    <div
      ref={ref}
      className={clsx(classes.root, {
        [classes.dragging]: isDragging
      })}
      style={{height: `${numRows}em`}}
      {...rest}
    />
  );
});

StyledAllDayGrid.propTypes = {
  numRows: PropTypes.number.isRequired,
  isDragging: PropTypes.bool,
};

StyledAllDayGrid.defaultProps = {
  isDragging: false
};

export default StyledAllDayGrid;
