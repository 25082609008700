import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    fontWeight: 700,
    fontSize: '1.75rem',
    lineHeight: 1.1875
  }
}))((props) => (
  <Typography variant='h4' component='h1' gutterBottom {...props} />
));
