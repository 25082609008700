import React from 'react';
import PropTypes from 'prop-types';
import GenericActivityItem from './ui/GenericActivityItem';
import GrantAccessDetails from './ui/GrantAccessDetails';

function GrantAccessItem({ activity  }) {
  const { type, actor, details, insertedAt } = activity;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='changed who can access this project'
      insertedAt={insertedAt}
    >
      <GrantAccessDetails names={details} />
    </GenericActivityItem>
  );
}

GrantAccessItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['GRANT_ACCESS']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default GrantAccessItem;
