import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';
import {
  TODO_ITEM_DETAILS,
  PROJECT_SHEET_COLUMNS
} from '../fragments';

const TODOS_IN_PROJECT_QUERY = gql`
  query GetTodosInProject($projectId: ID!, $filter: TodoFilter) {
    project(projectId: $projectId) {
      id
      todoLists {
        id
        name
        todoItems(filter: $filter) @connection(key: "todoItems") {
          ...TodoItemDetails
        }
      }
      ...ProjectSheetColumns
    }
  }
  ${TODO_ITEM_DETAILS}
  ${PROJECT_SHEET_COLUMNS}
`;

function TodosInProjectQuery(props) {
  const {
    projectId,
    criteria,
    onLoading,
    onError,
    children,
    ...otherQueryProps
  } = props;

  const {
    loading,
    error,
    data,
    ...otherQueryResults
  } = useQuery(TODOS_IN_PROJECT_QUERY, {
    variables: {
      projectId,
      filter: criteria
    },
    ...otherQueryProps
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.project, otherQueryResults);
}

TodosInProjectQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  criteria: PropTypes.object,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

TodosInProjectQuery.defaultProps = {
  criteria: {}
};

export default TodosInProjectQuery;
export { TODOS_IN_PROJECT_QUERY };
