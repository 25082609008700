import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(0.75, 0)
  },
  avatar: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    fontWeight: 500
  }
}));

function ParticipantListItem({ user, ...rest }) {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem} {...rest}>
      <UserAvatar user={user} className={classes.avatar} />
      <ListItemText
        primaryTypographyProps={{variant: 'body2', noWrap: true}}
        primary={user.username}
      />
    </ListItem>
  );
}

ParticipantListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }).isRequired
};

export default ParticipantListItem;
