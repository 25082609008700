import React from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import PrevIcon from '../../../icons/20x20/ArrowBackThin';
import { FILTER_PRIMARY } from '../../icons/Base';

const useStyles = makeStyles((theme) => ({
  prevFab: {
    position: 'absolute',
    top: '50%',
    left: theme.spacing(2),
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& $prevIcon': {
        filter: FILTER_PRIMARY
      }
    }
  },
  prevIcon: {}
}));

export default function PrevFab(props) {
  const classes = useStyles();
  return (
    <Fab
      size='small'
      aria-label='previous'
      className={classes.prevFab}
      {...props}
    >
      <PrevIcon color='secondary' className={classes.prevIcon} />
    </Fab>
  );
}
