import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import { getDescriptionForMimeType } from '../mime/mime';

function FileKindCell({ type }) {
  return (
    <Cell>
      {getDescriptionForMimeType(type)}
    </Cell>
  );
}

FileKindCell.propTypes = {
  // The file's MIME type
  type: PropTypes.string.isRequired
};

export default FileKindCell;
