import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TabCollapseButton from './TabCollapseButton';
import TabCollapseMenu from './TabCollapseMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflow: 'hidden',
    marginRight: theme.spacing(3),
    height: theme.spacing(7) // VERY IMPORTANT, see useCommonTabStyles
  },
  list: {
    display: 'flex'
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0
  }
}));

function NavTabs({ children }) {
  const classes = useStyles();
  const childrenCount = React.Children.count(children);
  const rootRef = React.useMemo(() => React.createRef(), []);

  const childrenRef = React.useMemo(() => {
    let refs = [];
    for (let i = 0; i < childrenCount; ++i) refs.push(React.createRef());
    return refs;
  }, [childrenCount]);

  const [observer, setObserver] = React.useState(null);
  const [invisible, setInvisible] = React.useState(
    React.Children.toArray(children).reduce((acc, curr, index) => (
      {...acc, [index]: true}
    ), {})
  );

  const callback = React.useCallback((entries, ob) => {
    entries.forEach((entry) => {
      const index = entry.target.dataset.index;
      if (entry.isIntersecting) {
        setInvisible((prev) => ({
          ...prev,
          [index]: false
        }));
      } else {
        setInvisible((prev) => ({
          ...prev,
          [index]: true
        }));
      }
    });
  }, []);

  React.useEffect(() => {
    const options = {
      root: rootRef.current,
      margin: '0px',
      threshold: 1.0
    };
    setObserver(new IntersectionObserver(callback, options));
  }, [rootRef, callback]);

  React.useEffect(() => {
    if (observer) {
      childrenRef.forEach((ref) => {
        observer.observe(ref.current);
      });
    }

    return () => {
      if (observer) {
        // Stops watching all of its target elements for
        // visibility changes.
        observer.disconnect();
      }
    };
  }, [observer, childrenRef]);

  const getMenuItems = () => {
    let items = [];
    React.Children.forEach(children, (child, index) => {
      if (invisible[index]) items.push(child.props);
    });
    return items;
  };

  const menuItems = getMenuItems();

  return (
    <div className={classes.root}>
      <nav ref={rootRef}>
        <div className={classes.list}>
          {React.Children.map(children, (child, index) => {
            if (invisible[index]) return null;
            return child;
          })}
          {menuItems.length > 0 && (
            <TabCollapseMenu menuItems={menuItems} />
          )}
        </div>
        <div
          className={clsx(classes.list, classes.hidden)}
          aria-hidden={true}
        >
          <TabCollapseButton invisible />
          {React.Children.map(children, (child, index) => (
            React.cloneElement(child, {
              ref: childrenRef[index],
              index: index,
              invisible: true
            })
          ))}
        </div>
      </nav>
    </div>
  );
}

export default NavTabs;
