import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';
import { TODO_SHEET_THEME } from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    border: TODO_SHEET_THEME.cellBorder
  }
}));

function DataCell({ width, render, updateRow, ...cellProps }) {
  const classes = useStyles();

  return (
    <Cell className={classes.root} style={{width: width}}>
      {render(cellProps, updateRow)}
    </Cell>
  );
}

DataCell.propTypes = {
  // How to render this cell using this `cellProps`. This function should
  // return a valid React component based on the given `data`.
  render: PropTypes.func.isRequired,

  // See Section.js for more details.
  updateRow: PropTypes.func
};

export default React.memo(DataCell);
