import React from 'react';
import PropTypes from 'prop-types';
import CommentsQuery from './CommentsQuery';
import CommentList from './CommentList';

function FileComments({ projectId, fileId }) {
  return (
    <CommentsQuery
      projectId={projectId}
      fileId={fileId}
      fetchPolicy='network-only'
    >
      {(comments, { subscribeToMore }) => (
        <CommentList
          projectId={projectId}
          fileId={fileId}
          comments={comments}
          subscribeToMore={subscribeToMore}
        />
      )}
    </CommentsQuery>
  );
}

FileComments.propTypes = {
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired
};

export default FileComments;
