import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Overlay from '../../button/Overlay';
import { FILTER_PRIMARY, FILTER_DISABLED } from '../../../icons/Base';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    width: theme.spacing(22),
    backgroundColor: '#282828',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium
  },
  arrow: {
    '&:before': {
      backgroundColor: '#282828'
    }
  }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    marginLeft: theme.spacing(1),

    fontFamily: 'inherit',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    textTransform: 'none',
    transition: 'none',
    minWidth: theme.spacing(8),
    borderRadius: theme.shape.borderRadius,

    fontSize: '0.9375rem',
    lineHeight: 1.6,
    padding: theme.spacing(0.75, 1.25),

    '&:hover': {
      color: theme.palette.text.primary,
      '& $overlay': {
        opacity: 1.0
      },
      '& $icon': {
        filter: FILTER_PRIMARY
      }
    },

    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },

    '&.Mui-disabled': {
      backgroundColor: '#E4E6EB',
      color: theme.palette.text.disabled,
      '& $icon': {
        filter: FILTER_DISABLED
      }
    }
  },

  label: {
    width: '100%',
    display: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  overlay: {}
}));

function JoinLeaveButton({ tooltip, icon, label, ...rest }) {
  const classes = useStyles();
  return (
    <StyledTooltip
      arrow
      placement='top'
      title={tooltip}
      enterDelay={500}
    >
      <ButtonBase className={classes.root} {...rest} >
        <span className={classes.label}>
          {React.cloneElement(icon, {
            className: classes.icon,
            color: 'secondary'
          })}
          {label}
        </span>
        <Overlay className={classes.overlay} />
      </ButtonBase>
    </StyledTooltip>
  );
}

JoinLeaveButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  tooltip: PropTypes.string
};

export default JoinLeaveButton;
