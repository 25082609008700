import React from 'react';
import PropTypes from 'prop-types';
import DragHandleCell from './DragHandleCell';
import DataRowRightMostCell from './DataRowRightMostCell';
import FocusRow from './FocusRow';

function DataRow({ innerRef, dragHandleCellProps, children, ...rest}) {
  return (
    <FocusRow ref={innerRef} {...rest}>
      <DragHandleCell {...dragHandleCellProps} />
      {children}
      <DataRowRightMostCell />
    </FocusRow>
  );
}

DataRow.propTypes = {
  // innerRef provided by Draggable if DataRow is draggable.
  innerRef: PropTypes.func,

  // Props applied to DragHandleCell (provided by Draggable) if
  // DataRow is draggable.
  dragHandleCellProps: PropTypes.object,

  children: PropTypes.node
};

export default DataRow;
