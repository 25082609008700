import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '../../../icons/16x16/Check';
import Overlay from '../../button/Overlay';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& $overlay': {
        opacity: 1.0
      }      
    },
    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  checked: {    
    color: 'hsl(214, 89%, 52%)',
    backgroundColor: '#E7F3FF',
    border: '1px solid #E7F3FF'
  },
  overlay: {}
}));

function Icon({ checked, classes }) {
  return (
    <span className={clsx(classes.icon, {[classes.checked]: checked})}>
      <CheckIcon
        style={{marginRight: 4}}
        color={checked ? 'accent' : 'secondary'}
      />
      {checked ? 'Completed' : 'Mark complete'}
      <Overlay className={classes.overlay}/>
    </span>
  );
}

function MarkCompleteCheckbox(props) {
  const classes = useStyles();
  return (
    <Checkbox    
      color='default'
      className={classes.checkbox}
      icon={ <Icon classes={classes} /> }
      checkedIcon={<Icon checked classes={classes} />}
      {...props}
    />
  );
}

export default MarkCompleteCheckbox;
