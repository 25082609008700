import React from 'react';
import Section from '../ui/page/Section';
import SectionHeader from '../ui/page/SectionHeader';
import SectionTitle from '../ui/page/SectionTitle';
import TeamsGridSkeleton from './TeamsGridSkeleton';
import TeamsGrid from './TeamsGrid';
import NoTeamsFound from './NoTeamsFound';
import TeamsQuery from './TeamsQuery';
import CreateTeamButton from './CreateTeamButton';

export default function Teams() {
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Teams</SectionTitle>
        <CreateTeamButton />
      </SectionHeader>
      <TeamsQuery onLoading={() => <TeamsGridSkeleton /> }>
        {(teams) => {
          if (teams.length === 0) return <NoTeamsFound />;
          return <TeamsGrid teams={teams} />;
        }}
      </TeamsQuery>
    </Section>
  );
}
