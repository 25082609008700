import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/MultiStepTitle';

function OptionListTitle({ uploadName, ...rest }) {
  return (
    <DialogTitle noWrap {...rest}>
      {uploadName}
    </DialogTitle>
  );
}

OptionListTitle.propTypes = {
  uploadName: PropTypes.string.isRequired
};

export default OptionListTitle;
