import React from 'react';
import IconButton from '../button/IconButton';

export default function ToolbarIconButon(props) {
  return (
    <IconButton
      background={false}
      size='small'
      color='secondary'
      {...props}
    />
  );
}
