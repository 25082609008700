import React from 'react';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DropzoneRoot from './UploadFilesDropzoneRoot';

const useStyles = makeStyles((theme) => ({
  dropzoneIndicatorContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.modal + 1,
    display: 'none'
  },
  showDropzoneIndicator: {
    display: 'block'
  },
  dropzoneIndicator: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `4px dashed ${theme.palette.divider}`
  }
}));

const UploadFilesDropzone = React.forwardRef(
  function UploadFilesDropzone({ onDrop, children }, ref) {
    const classes = useStyles();

    return (
      <Dropzone
        ref={ref}
        noClick
        noKeyboard
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <DropzoneRoot {...getRootProps()}>
            <div className={clsx(classes.dropzoneIndicatorContainer, {
              [classes.showDropzoneIndicator]: isDragActive
            })}>
              <div className={classes.dropzoneIndicator}>
                <Typography>
                  Drop the files you want to upload here.
                </Typography>
              </div>
            </div>
            <input {...getInputProps()} />
            {children}
          </DropzoneRoot>
        )}
      </Dropzone>
    );
  }
);

export default UploadFilesDropzone;
