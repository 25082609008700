import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// A hack to make clamp multiple lines of text
// TODO: This is not a cross-browser solution!
const useStyles = makeStyles((theme) => ({
  clampText: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  }
}));

export default function CardContentTypography(props) {
  const classes = useStyles();
  return (
    <Typography
      variant='body2'
      component='p'
      color='textSecondary'
      className={classes.clampText}
      {...props}
    />
  );
}
