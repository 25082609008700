import React from 'react';
import PropTypes from 'prop-types';
import FollowersBar from './ui/FollowersBar';
import Followers from './ui/Followers';
import FollowersList from './ui/FollowersList';
import AvatarGroup from './ui/FollowersAvatarGroup';
import UpdateButton from './ui/UpdateFollowersButton';
import FollowOrUnfollowButton from './FollowOrUnfollowButton';
import UpdateFollowers from './UpdateFollowers';

function FollowersBarWithData({ projectId, fileId, followers }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    <FollowersBar>
      <Followers>
        <FollowersList>
          {!open && (
            <React.Fragment>
              <AvatarGroup followers={followers} />
              <UpdateButton onClick={handleOpen} />
            </React.Fragment>
          )}
          <UpdateFollowers
            projectId={projectId}
            fileId={fileId}
            followers={followers}
            open={open}
            onClose={handleClose}
          />
        </FollowersList>
      </Followers>
      {!open && (
        <FollowOrUnfollowButton
          fileId={fileId}
          followers={followers}
        />
      )}
    </FollowersBar>
  );
}

FollowersBarWithData.propTypes = {
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  followers: PropTypes.array.isRequired
};

export default FollowersBarWithData;
