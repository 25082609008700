import React from 'react';
import PropTypes from 'prop-types';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import DocumentLoading from './PdfDocumentLoading';
import PdfPage from './PdfPage';
import PdfDocumentContainer from './PdfDocumentContainer';

function PdfPreview({ src, zoom, maxHeight }) {
  const [visibilities, setVisibilities] = React.useState([]);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setVisibilities(Array.from(new Array(numPages), () => false));
  };

  const handleIntersect = React.useCallback((intersects) => {
    setVisibilities((prev) => prev.map((val, idx) => {
      if (intersects.hasOwnProperty(idx)) { return intersects[idx]; }
      return val;
    }));
  }, []);

  return (
    <PdfDocumentContainer
      maxHeight={maxHeight}
      onIntersect={handleIntersect}
    >
      <Document
        file={src}
        loading={ <DocumentLoading /> }
        onLoadSuccess={handleDocumentLoadSuccess}
      >
        {visibilities.map((visible, index) => (
          <PdfPage
            key={`page_${index}`}
            pageIndex={index}
            visible={visible}
            zoom={zoom}
          />
        ))}
      </Document>
    </PdfDocumentContainer>
  );
}

PdfPreview.propTypes = {
  src: PropTypes.string.isRequired,
  zoom: PropTypes.number
};

PdfPreview.defaultProps = {
  zoom: 100
};

export default PdfPreview;
