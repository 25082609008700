import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from './DialogTitle';
import { projectOption } from './OptionList';

function getDialogTitleForOption(option) {
  switch(option) {
    case projectOption.EDIT_PROJECT:
      return 'Project details';
    case projectOption.CHANGE_AVATAR:
      return 'Change project avatar';
    case projectOption.REASSIGN_OWNER:
      return 'Reassign project owner';
    case projectOption.ARCHIVE_PROJECT:
      return 'Archive project';
    case projectOption.DELETE_PROJECT:
      return 'Delete project';
    default:
      return null;
  }
}

function OptionDialogTitle({ option, ...rest }) {
  const title = getDialogTitleForOption(option);
  return (
    <DialogTitle noWrap {...rest}>
      {title}
    </DialogTitle>
  );
}

OptionDialogTitle.propTypes = {
  option: PropTypes.oneOf(Object.values(projectOption))
};

export default OptionDialogTitle;
