import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dailyScheduleContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',

    padding: theme.spacing(0, 2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3, 2)
    },
  }
}));

export default function DailyScheduleContainer(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.dailyScheduleContainer}
      {...props}
    />
  );
}
