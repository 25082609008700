import React from 'react';
import PropTypes from 'prop-types';

function LinearStepper(props) {
  const {
    activeStep,
    transitionDuration,
    children,
    style,
    ...otherProps
  } = props;

  const [height, setHeight] = React.useState(0);

  const handleHeightChange = React.useCallback((height) => {
    setHeight(height);
  }, []);

  return (
    <div
      style={{
        ...style,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: height,
        transitionProperty: 'height',
        transitionDuration: transitionDuration,
        transitionTimingFunction: 'cubic-bezier(0.08, 0.52, 0.52, 1)',
      }}
      {...otherProps}
    >
      {React.Children.map(children, (child, index) => (
        React.cloneElement(child, {
          index,
          transitionDuration,
          activeStep,
          onHeightChange: handleHeightChange
        })
      ))}
    </div>
  );
}

LinearStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  transitionDuration: PropTypes.string,
  children: PropTypes.node
};

LinearStepper.defaultProps = {
  activeStep: 0,
  transitionDuration: '0.2s'
};

export default LinearStepper;
