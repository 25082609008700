import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import { COMMENT_FILES } from './fragments';

const UPDATE_FILE_COMMENT_MUTATION = gql`
  mutation UpdateFileComment(
    $fileId: ID!,
    $commentId: ID!,
    $changes: UpdateCommentInput!
  ) {
    updateFileComment(
      fileId: $fileId,
      commentId: $commentId,
      changes: $changes
    ) {
      id
      text
      ...CommentFiles
      updatedAt
    }
  }
  ${COMMENT_FILES}
`;

export default function useUpdateCommentMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_FILE_COMMENT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
