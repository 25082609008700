import React from 'react';
import ListItemAction from './ListItemAction';
import DeleteIcon from '../../../icons/18x18/Trash';

export default function ActionDelete(props) {
  return (
    <ListItemAction
      icon={<DeleteIcon />}
      {...props}
    />
  );
}
