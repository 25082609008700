import React from 'react';
import Button from '../../ui/button/Button';

export default function SubmitButton(props) {
  return (
    <Button
      variant='contained'
      size='small'
      color='primary'
      {...props}
    />
  );
}
