import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '../ui/file/Toolbar';
import FileDetails from '../ui/file/FileDetails';
import FileDetailsHeader from '../ui/file/FileDetailsHeader';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 8
    }
  },
  header: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  headerAvatar: {
    flex: '0 0 auto',
    marginRight: 12
  },
  headerText: {
    flex: 1
  },
  title: {
    marginBottom: 6
  },
  media: {
    height: 192
  },
  content: {
    padding: theme.spacing(3, 2)
  }
}));

// TODO: This shit should be in the ui folder.
export default function UploadLoading() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <div className={classes.breadcrumbs}>
          <Skeleton width={48} />
          <Skeleton width={48} />
          <Skeleton width={48} />
        </div>
      </Toolbar>

      <FileDetails>
        <FileDetailsHeader>
          <div className={classes.header}>
            <Skeleton
              variant='circle'
              width={40}
              height={40}
              className={classes.headerAvatar}
            />
            <div className={classes.headerText}>
              <Skeleton height={12} width='80%' className={classes.title} />
              <Skeleton height={12} width='40%' />
            </div>
          </div>
        </FileDetailsHeader>

        <div>
          <Skeleton variant='rect' className={classes.media} />
        </div>

        <div className={classes.content}>
          <Skeleton height={10} style={{ marginBottom: 6 }} />
          <Skeleton height={10} width="80%" />
        </div>
      </FileDetails>
    </React.Fragment>
  );
}
