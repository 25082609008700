import {
  getHours,
  getMinutes,
  subMinutes,
  addMinutes,
  addHours,
  startOfDay,
  endOfDay,
  isBefore
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import compareTime from '../../new-ui/datetime-picker/compareTime';
import formatTimeOfDay from '../../new-ui/datetime-picker/formatTimeOfDay';
import {parseDateInput} from '../../new-ui/datetime-picker/parseDateInput';
import { CHIP_DEFAULT_COLOR } from '../ui/constants';
import { dayEventReminder, timeEventReminder } from './defaultReminders';
import getReminderQuantityRange from './getReminderQuantityRange';

// Helpers

function roundDownMinutesToNearestMultipleOf(date, factor) {
  const originalMinutes = getMinutes(date);
  const expectedMinutes = Math.floor(originalMinutes / factor) * factor;
  const diff = originalMinutes - expectedMinutes;
  return subMinutes(date, diff);
}

function getTime(date) {
  return formatTimeOfDay({
    hours: getHours(date),
    minutes: getMinutes(date)
  });
}

function addTime(date, { hours, minutes }) {
  const d = startOfDay(date);
  return addMinutes(addHours(d, hours), minutes);
}

// TODO: FIXME please!!!
function getEventDefaultDatesAndTimes(options) {
  let startDate;
  let endDate;

  if (options.startDate && options.endDate) {
    startDate = startOfDay(options.startDate);
    endDate = startOfDay(options.endDate);
  } else if (options.startDate) {
    startDate = startOfDay(options.startDate);
    endDate = startDate;
  } else if (options.endDate) {
    endDate = startOfDay(options.endDate);
    startDate = endDate;
  } else {
    startDate = startOfDay(new Date());
    endDate = startDate;
  }

  const startTime = options.startTime ||
                    formatTimeOfDay({ hours: 8, minutes: 30});

  const endTime = options.endTime ||
                  formatTimeOfDay({ hours: 11, minutes: 0 });

  return {
    startDate,
    startTime,
    endDate,
    endTime
  };
}

function isReminderInvalid({ offsetQuantity, offsetUnit }) {
  const { min, max } = getReminderQuantityRange(offsetUnit);
  return isNaN(offsetQuantity) ||
         offsetQuantity < min ||
         offsetQuantity > max;
}

// Exports

export function initFormData(options) {
  const opts = options || {};
  const { allDay = true } = opts;

  return {
    title: '',
    description: '',
    allDay,
    color: CHIP_DEFAULT_COLOR,
    participants: [],
    dayReminders: [dayEventReminder],
    timeReminders: [timeEventReminder],
    ...getEventDefaultDatesAndTimes(opts)
  };
}

export function convertEventDataToFormData(rawEvent) {
  const isAllDay = rawEvent.allDay;

  let startsAt = parseDateInput(rawEvent.startsAt);
  let endsAt = parseDateInput(rawEvent.endsAt);

  // See DateRangePicker (also TimePicker) (15 minutes gap)
  startsAt = roundDownMinutesToNearestMultipleOf(startsAt, 15);
  endsAt = roundDownMinutesToNearestMultipleOf(endsAt, 15);

  const reminders = rawEvent.reminders.map(({ __typename, ...rest }) => ({
    ...rest,
    _id: uuidv4()
  }));

  return {
    title: rawEvent.title,
    description: rawEvent.description || '',
    allDay: isAllDay,
    color: rawEvent.color || CHIP_DEFAULT_COLOR,
    participants: rawEvent.participants,

    startDate: startOfDay(startsAt),
    startTime: getTime(startsAt),

    endDate: startOfDay(endsAt),
    endTime: getTime(endsAt),

    dayReminders: isAllDay ? reminders : [dayEventReminder],
    timeReminders: !isAllDay ? reminders : [timeEventReminder]
  };
}

export function convertFormDataToEventData(formData) {
  const isAllDay = formData.allDay;

  let startsAt;
  let endsAt;
  let reminders;

  if (isAllDay) {
    startsAt = startOfDay(formData.startDate);
    endsAt = endOfDay(formData.endDate);
    reminders = formData.dayReminders;
  } else {
    startsAt = addTime(formData.startDate, formData.startTime);
    endsAt = addTime(formData.startDate, formData.endTime);
    reminders = formData.timeReminders;
  }

  reminders = reminders.map(({ _id, offsetQuantity, ...rest }) => ({
    ...rest,
    offsetQuantity: +(offsetQuantity)
  }));

  return {
    title: formData.title,
    description: formData.description,
    allDay: formData.allDay,
    color: formData.color,
    startsAt,
    endsAt,
    reminders,
    participantIds: formData.participants.map(({ id }) => id )
  }
}

export function convertFormDataToOptimisticResponse(formData) {
  const data = convertFormDataToEventData(formData);
  delete data.participantIds;

  let reminders;

  if (formData.allDay) {
    reminders = formData.dayReminders;
  } else {
    reminders = formData.timeReminders;
  }

  return {
    ...data,
    participants: formData.participants,
    reminders: reminders.map(({ id, _id, ...rest }) => {
      let reminder = {
        ...rest,
        offsetQuantity: +(rest.offsetQuantity),
        __typename: 'EventReminder'
      };

      if (id) {
        return {
          id,
          ...reminder
        };
      }

      return {
        id: _id,
        ...reminder
      };
    })
  };
}

export function isFormDataInvalid(formData) {
  const {
    allDay,
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    dayReminders,
    timeReminders
  } = formData;

  if (!title || title.trim().length === 0) {
    return true;
  }

  if (allDay && isBefore(endDate, startDate)) {
    return true;
  }

  if (!allDay && compareTime(startTime, endTime) >= 0) {
    return true;
  }

  if (allDay) {
    return dayReminders.some(isReminderInvalid);
  }

  return timeReminders.some(isReminderInvalid);
}
