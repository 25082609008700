import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function TeamCardSubheader({projectCount, memberCount}) {
  const projectCountText = (projectCount > 1) ?
                           `${projectCount} projects` :
                           `${projectCount} project`;

  const memberCountText = (memberCount > 1) ?
                          `${memberCount} members` :
                          `${memberCount} member`;

  return (
    <Box>
      {projectCountText} &bull; {memberCountText}
    </Box>
  );
}

TeamCardSubheader.propTypes = {
  projectCount: PropTypes.number.isRequired,
  memberCount: PropTypes.number.isRequired
};

export default TeamCardSubheader;
