import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import ListItem from '../file/list/ListItem';
import {
  getIcon40ForMimeType,
  getDescriptionForMimeType
} from '../file/mime/mime';
import ActionDelete from './AttachmentActionDelete';
import ActionDownload from './AttachmentActionDownload';
import { useMultiPreview } from '../file/preview/MultiPreview';

function AttachmentListItem({ attachment, index, onDelete }) {
  const { id, type, name, ext, size, downloadUrl } = attachment;
  const Icon = getIcon40ForMimeType(type);
  const nameWithExt = `${name}${ext}`;
  const description = getDescriptionForMimeType(type);
  const humanReadableSize = filesize(size, {round: 1, base: 10});

  const { previewCurr } = useMultiPreview();

  const handleClick = (event) => {
    previewCurr(index);
  };

  const handleDelete = (event) => {
    if (onDelete) onDelete(id);
  };

  return (
    <ListItem
      disabledHover
      avatar={<Icon />}
      primary={nameWithExt}
      secondary={`${description} - ${humanReadableSize}`}
      actions={[
        <ActionDownload downloadUrl={downloadUrl} />,
        onDelete && <ActionDelete onClick={handleDelete} />
      ]}
      onClick={handleClick}
    />
  );
}

AttachmentListItem.propTypes = {
  index: PropTypes.number.isRequired,
  attachment: PropTypes.object.isRequired,
  onDelete: PropTypes.func
};

export default AttachmentListItem;
