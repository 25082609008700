import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from '../workspace/CurrentWorkspaceContext';
import Error from '../oops/Error';
import { TEAM_DETAILS_FRAGMENT, TEAM_STATS_FRAGMENT, TEAM_MEMBERS_FRAGMENT }
from './fragments';

const TEAMS_QUERY = gql`
  query GetTeams($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      id
      teams {
        ...TeamDetails
        ...TeamStats
        ...TeamMembers
      }
    }
  }
  ${TEAM_DETAILS_FRAGMENT}
  ${TEAM_STATS_FRAGMENT}
  ${TEAM_MEMBERS_FRAGMENT}
`;

function TeamsQuery({ onLoading, onError, children, ...rest }) {
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data } = useQuery(TEAMS_QUERY, {
    variables: {
      workspaceId: currentWorkspaceId
    },
    fetchPolicy: 'network-only',
    ...rest
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.workspace.teams);
}

TeamsQuery.propTypes = {
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default TeamsQuery;
export { TEAMS_QUERY };
