import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const SOME_TODO_LIST_DELETED_SUBSCRIPTION = gql`
  subscription OnSomeTodoListDeleted($projectId: ID!) {
    someTodoListDeleted(projectId: $projectId) {
      id
    }
  }
`;

function SomeTodoListDeletedSubscriber({
  projectId,
  subscribeToSomeTodoListDeleted  // subscribeToMore result
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToSomeTodoListDeleted({
      document: SOME_TODO_LIST_DELETED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;

        const deletedId = subscriptionData.data.someTodoListDeleted.id;

        const newTodoLists = prev.project.todoLists.filter(({ id }) => (
          id !== deletedId
        ));

        return {
          project: {
            ...prev.project,
            todoLists: newTodoLists
          }
        };
      }
    });

    return () => unsubscribe();
  }, [projectId, subscribeToSomeTodoListDeleted]);

  return null;
}

SomeTodoListDeletedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired,
  subscribeToSomeTodoListDeleted: PropTypes.func.isRequired
};

export default SomeTodoListDeletedSubscriber;
