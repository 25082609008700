import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getIcon128ForMimeType } from '../mime/mime';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    display: 'inline-flex',
    borderRadius: 4
  }
}));

function DocumentIconLink({ type, ...rest }) {
  const classes = useStyles();
  const IconComponent = getIcon128ForMimeType(type);
  return (
    <a className={classes.link} {...rest}>
      <IconComponent />
    </a>
  );
}

DocumentIconLink.propTypes = {
  // The MIME type of the document.
  type: PropTypes.string.isRequired
};

export default DocumentIconLink;
