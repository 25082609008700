import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

function DeleteConfirmText({ subject }) {
  const name = (
    <Box
      component='span'
      color='text.primary'
      fontWeight={500}
    >
      {subject}
    </Box>
  );

  return (
    <DialogContentText>
      Are you sure you want to delete the message {name}? This cannot be
      undone.
    </DialogContentText>
  );
}

DeleteConfirmText.propTypes = {
  subject: PropTypes.string.isRequired
};

export default DeleteConfirmText;
