import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '../../../icons/18x18/MultiplyCircleFill';
import UploadStatusIconButton from './UploadStatusIconButton';
import CircularProgress from './CircularProgress';

function UploadProgress({ progress, onCancel, ...rest }) {
  return (
    <UploadStatusIconButton
      icon={ <CircularProgress value={progress} />}
      hoverIcon={<CancelIcon />}
      tooltip='Cancel upload'
      onClick={onCancel}
      {...rest}
    />
  );
}

UploadProgress.propTypes = {
  // A number between 0 and 100 indicating upload progress.
  progress: PropTypes.number.isRequired,

  onCancel: PropTypes.func
};

export default UploadProgress;
