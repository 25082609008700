import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from './NotFound';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  section: {
    borderRadius: 12,
    padding: theme.spacing(3),
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)'
  }
}));

export default function SectionNotFound() {
  const classes = useStyles();
  return (
    <Container maxWidth='sm' className={classes.root}>
      <Paper className={classes.section}>
        <NotFound />
      </Paper>
    </Container>
  );
}
