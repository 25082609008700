import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { makeStyles, withStyles, darken } from '@material-ui/core/styles';
import ExpandMoreIcon from '../../icons/12x12/ArrowDropDown';
import MenuItem from '../menu/MenuItem';

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 8,
    boxShadow: theme.customShadow.menu
  },
  list: {
    padding: theme.spacing(1, 0)
  }
}))((props) => (
  <Menu
    disablePortal
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    flex: '0 0 auto',
  },
  buttonRoot: {
    padding: theme.spacing(0.75, 1.5),
    backgroundColor: '#F0F2F5',
    '&:hover': {
      backgroundColor: darken('#F0F2F5', 0.04)
    }
  },
  buttonLabel: {
    fontSize: '0.875rem',
    lineHeight: '24px'
  },
  buttonEndIcon: {
    marginLeft: theme.spacing(1),
    marginRight: -2
  },
  menuItem: {
    borderRadius: 0,
    padding: theme.spacing(1, 1.5),
    '& .MuiListItemText-primary': {
      fontSize: `0.875rem !important`,
      lineHeight: '20px'
    },
    '&.Mui-focusVisible': {
      outline: 0
    }
  },
  showFilter: {
    display: 'block'
  },
}));

function DropdownMenu({ hidden, options, value, onChange }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSelected = (option) => option.value === value.value;

  const onItemSelected = (event, newOption) => {
    handleClose();
    if (onChange) {
      onChange(event, newOption);
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.showFilter]: !hidden
      })}
    >
      <Button
        endIcon={(<ExpandMoreIcon color='secondary' />)}
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonLabel,
          endIcon: classes.buttonEndIcon
        }}
        onClick={handleOpen}
      >
        {value.label}
      </Button>

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            className={classes.menuItem}
            primary={option.label}
            selected={isSelected(option)}
            onClick={(event) => onItemSelected(event, option)}
          />
        ))}
      </StyledMenu>
    </div>
  );
}

const OptionShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  label: PropTypes.string.isRequired
});

DropdownMenu.propTypes = {
  hidden: PropTypes.bool,        // Toggle the menu on/off.
  options: PropTypes.arrayOf(OptionShape).isRequired,
  value: OptionShape.isRequired, // Current selected option
  onChange: PropTypes.func       // (event, newOption) => {...}
};

DropdownMenu.defaultProps = {
  hidden: true
};

export default DropdownMenu;
