import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ColorPicker from '../ui/color-picker/ColorPicker';
import {AVATAR_COLORS} from '../ui/avatar/colors';
import ProjectAvatar from './ProjectAvatar';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: `${theme.palette.text.secondary} !important`,
    marginBottom: theme.spacing(1),
    '&.Mui-focused': {
      color: theme.palette.text.primary
    }
  },
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  previewAvatar: {
    marginRight: theme.spacing(2),
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  helperText: {
    marginLeft: theme.spacing(9) + 4
  }
}));

function ProjectColorPicker({ inputName, projectName, value, onChange }) {
  const classes = useStyles();

  return (
    <div>
      <FormControl component='fieldset'>
        <FormLabel component='legend' className={classes.label}>
          Avatar
        </FormLabel>
        <div className={classes.previewContainer}>
          <ProjectAvatar
            color={value}
            name={projectName}
            className={classes.previewAvatar}
          />
          <ColorPicker
            colors={AVATAR_COLORS}
            name={inputName}
            value={value}
            onChange={onChange}
          />
        </div>
      </FormControl>

      <Typography
        variant='caption'
        component='small'
        color='textSecondary'
        className={classes.helperText}
      >
        Colors help you visually distinguish one project from another.
      </Typography>
    </div>
  );
}

ProjectColorPicker.propTypes = {
  // The name used to reference the value of the control.
  // This `name` will be passed down to Mui RadioGroup.
  inputName: PropTypes.string,

  projectName: PropTypes.string.isRequired,

  // The project color.
  value: PropTypes.oneOf(AVATAR_COLORS),

  // Callback to execute when color changes. You can get the current
  // color by using `{ name, value } = event.target`, in which,
  // name is the `inputName` you passed in and `value` is the current
  // color.
  // Prototype: (event) => {...}
  onChange: PropTypes.func
};

export default ProjectColorPicker;
