import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commentList: {
    marginTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
}));

export default function CommentList(props) {
  const classes = useStyles();
  return <div className={classes.commentList} {...props} />
}
