import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import DialogTitle from './DialogTitle';
import DialogContent from './DialogContent';
import PrevFab from './PrevFab';
import NextFab from './NextFab';
import Preview from './Preview';
import DialogActions from './DialogActions';

const MultiPreviewContext = React.createContext(null);

// A few zoom constants
const ZOOM_MIN = 20;
const ZOOM_MAX = 100;
const ZOOM_GAP = 10;
const ZOOM_DEF = 60;

const multiPreviewAction = Object.freeze({
  'PREVIEW_NEXT': 1,   // preview the next file
  'PREVIEW_PREV': 2,   // preview the previous file
  'PREVIEW_CURR': 3,   // preview the file at a specific index
  'ZOOM_IN': 4,
  'ZOOM_OUT': 5,
  'CLOSE_DIALOG': 6,
  'REFRESH': 7
});

function init(files) {
  return {
    open: false,
    curr: 0,
    files: files,
    zoom: ZOOM_DEF
  };
}

function reducer(state, action) {
  const { type, payload } = action;
  const { curr, files, zoom } = state;
  const count = files.length;

  switch(type) {
    case multiPreviewAction.PREVIEW_NEXT:
      if (count && curr < count - 1) {
        return {...state, open: true, curr: curr + 1};
      } else {
        return state;
      }
    case multiPreviewAction.PREVIEW_PREV:
      if (count && curr > 0) {
        return {...state, open: true, curr: curr - 1};
      } else {
        return state;
      }
    case multiPreviewAction.PREVIEW_CURR:
      const { index } = payload;
      if (index < 0 || index >= count) {
        return state;
      } else {
        return {...state, open: true, curr: index};
      }
    case multiPreviewAction.ZOOM_OUT:
      if (zoom > ZOOM_MIN) {
        return {...state, zoom: zoom - ZOOM_GAP};
      } else {
        return state;
      }
    case multiPreviewAction.ZOOM_IN:
      if (zoom < ZOOM_MAX) {
        return {...state, zoom: zoom + ZOOM_GAP};
      } else {
        return state;
      }
    case multiPreviewAction.CLOSE_DIALOG:
      return {...state, open: false };
    case multiPreviewAction.REFRESH:
      return init(payload.files);
    default:
      return state;
  }
}

function MultiPreview({ files, children }) {
  const [state, dispatch] = React.useReducer(reducer, files, init);
  const { curr, open } = state;
  const count = state.files.length;
  const currFile = state.files[curr];

  React.useEffect(() => {
    dispatch({
      type: multiPreviewAction.REFRESH,
      payload: { files }
    });
  }, [files, dispatch]);

  const ctx = React.useMemo(() => ({
    // Preview next file
    previewNext: () => {
      dispatch({ type: multiPreviewAction.PREVIEW_NEXT });
    },

    // Preview previous file
    previewPrev: () => {
      dispatch({ type: multiPreviewAction.PREVIEW_PREV });
    },

    // Preview the file at this specific index.
    previewCurr: (index) => {
      dispatch({
        type: multiPreviewAction.PREVIEW_CURR,
        payload: { index }
      });
    },

    // Zoom out
    zoomOut: () => {
      dispatch({ type: multiPreviewAction.ZOOM_OUT });
    },

    // Zoom in
    zoomIn: () => {
      dispatch({ type: multiPreviewAction.ZOOM_IN });
    },

    // Close the dialog
    closeDialog: () => {
      dispatch({ type: multiPreviewAction.CLOSE_DIALOG });
    }
  }), [dispatch]);

  const haveNext = count && curr < count - 1;
  const havePrev = count && curr > 0;

  return (
    <MultiPreviewContext.Provider value={ctx}>
      {children}
      {currFile && (
        <Dialog open={open} onClose={ctx.closeDialog}>
          <DialogTitle
            name={`${currFile.name}${currFile.ext}`}
            creator={currFile.creator}
            updatedAt={currFile.updatedAt}
            downloadUrl={currFile.downloadUrl}
            onClose={ctx.closeDialog}
          />
          <DialogContent>
            <Preview
              src={currFile.previewUrl}
              type={currFile.previewType}
              downloadUrl={currFile.downloadUrl}
              alt={currFile.name}
              zoom={state.zoom}
            />
          </DialogContent>

          <DialogActions
            value={state.zoom}
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            onZoomOut={ctx.zoomOut}
            onZoomIn={ctx.zoomIn}
          />

          {havePrev && <PrevFab onClick={ctx.previewPrev} />}
          {haveNext && <NextFab onClick={ctx.previewNext} />}
        </Dialog>
      )}
    </MultiPreviewContext.Provider>
  );
}

MultiPreview.propTypes = {
  files: PropTypes.array.isRequired
};

export default MultiPreview;

export function useMultiPreview() {
  return React.useContext(MultiPreviewContext);
}
