import React from 'react';
import Table from './Table';
import HeaderSkeleton from './HeaderSkeleton';
import SectionSkeleton from './SectionSkeleton';

export default function SheetSkeleton() {
  return (
    <Table>
      <HeaderSkeleton />
      <SectionSkeleton />
    </Table>
  );
}
