import React from 'react';
import PropTypes from 'prop-types';
import GenericActivityItem from './ui/GenericActivityItem';
import EventDetails from './ui/EventDetails';
import useEventUrl from './useEventUrl';

function AddEventItem({ activity }) {
  const { type, actor, details, event, insertedAt } = activity;
  const eventUrl = useEventUrl(event);
  const [title, allDay, startDate, endDate] = details;
  const isAllDay = allDay === 'true';

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='added a new event:'
      insertedAt={insertedAt}
    >
      <EventDetails
        title={title}
        startDate={startDate}
        endDate={endDate}
        isAllDay={isAllDay}
        url={eventUrl}
      />
    </GenericActivityItem>
  );
}

AddEventItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['ADD_EVENT']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      startsAt: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default AddEventItem;
