import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const UPDATE_TODO_LIST_MUTATION = gql`
  mutation UpdateTodoList($todoListId: ID!, $changes: UpdateTodoListInput!) {
    updateTodoList(todoListId: $todoListId, changes: $changes) {
      id
      name
    }
  }
`;

export default function useUpdateTodoListMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(UPDATE_TODO_LIST_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
