import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatarUploaderDropZone from './UserAvatarUploaderDropZone';
import AvatarUploaderActions from './AvatarUploaderActions';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  avatarUploaderTitle: {
    fontWeight: 600,
    fontSize: '0.9375rem'
  },
  avatarUploaderContent: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const UPLOAD_MY_AVATAR_MUTATION = gql`
  mutation UpdateMyAvatar($avatar: Upload!) {
    updateMyAvatar(avatar: $avatar) {
      id
    }
  }
`;

const REMOVE_MY_AVATAR_MUTATION = gql`
  mutation RemoveMyAvatar {
    removeMyAvatar {
      id
    }
  }
`;

function UserAvatarUploader({ user }) {
  const classes = useStyles();
  const dropzoneRef = React.useRef(null);
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  // upload mutation
  const [updateMyAvatar, {loading: updateLoading}] = useMutation(
    UPLOAD_MY_AVATAR_MUTATION,
    {
      onError(error) {
        enqueueGraphQLErrorSnackbar(error);
      }
    }
  );

  // remove mutation.
  const [removeMyAvatar, {loading: removeLoading}] = useMutation(
    REMOVE_MY_AVATAR_MUTATION,
    {
      onError(error) {
        enqueueGraphQLErrorSnackbar(error);
      }
    }
  );

  const isLoading = updateLoading || removeLoading;

  const onDrop = ([avatar]) => {
    updateMyAvatar({variables: { avatar }});
  };

  const onUpload = React.useCallback(() => {
    dropzoneRef.current.open();
  }, [dropzoneRef]);

  const onRemove = React.useCallback(() => {
    removeMyAvatar();
  }, [removeMyAvatar]);

  const hasAvatar = () => {
    return user.avatar !== null;
  };

  return (
    <div className={classes.root}>
      <Typography
        variant='body1'
        color='textPrimary'
        className={classes.avatarUploaderTitle}
        gutterBottom
      >
        Your photo
      </Typography>

      <div className={classes.avatarUploaderContent}>
        <UserAvatarUploaderDropZone
          ref={dropzoneRef}
          user={user}
          onDrop={onDrop}
          loading={isLoading}
        />

        <AvatarUploaderActions
          onUpload={onUpload}
          onRemove={onRemove}
          loading={isLoading}
          hasAvatar={hasAvatar()}
        />
      </div>
    </div>
  );
}

export default UserAvatarUploader;
