import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  uploadListActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1.5)
    }
  }
}));

export default function UploadListActions(props) {
  const classes = useStyles();
  return <div className={classes.uploadListActions} {...props} />;
}
