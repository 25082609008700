import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import Row from './Row';
import RowCell from './Cell';

function FileRow({ checkbox, columns, row }) {
  const { selected, onToggleSelected } = row;

  return (
    <Row selected={selected}>
      {checkbox && (
        <RowCell checkbox>
          <Checkbox
            checked={selected}
            onChange={onToggleSelected}
          />
        </RowCell>
      )}
      {columns.map(({ id, Cell, getCellProps }) => (
        <Cell key={id} {...getCellProps(row)} />
      ))}
    </Row>
  );
}

FileRow.propTypes = {
  checkbox: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    Cell: PropTypes.elementType.isRequired,
    getCellProps: PropTypes.func.isRequired
  })).isRequired,
  row: PropTypes.object.isRequired
};

FileRow.defaultProps = {
  checkbox: false
};

export default React.memo(FileRow);
