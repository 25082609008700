import React from 'react';
import JoinOrLeaveButton from '../../../ui/message/JoinOrLeaveButton';
import BellIcon from '../../../icons/18x18/Bell';

export default function FollowFileButton(props) {
  const tooltip = 'Get notifications about activity on this file.';
  return (
    <JoinOrLeaveButton
      label='Subscribe'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
