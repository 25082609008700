import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../ui/link/RouterLink';
import ActivityItem from './ActivityItem';
import ActivityIcon from './ActivityIcon';
import ActivityContent from './ActivityContent';
import ActivityTitle from './ActivityTitle';
import ActivityTimestamp from './ActivityTimestamp';
import Bold from './Bold';

function GenericActivityItem({
  type,
  who,
  what,
  linkUrl,
  linkText,
  insertedAt,
  children
}) {
  return (
    <ActivityItem>
      <ActivityIcon variant={type} />
      <ActivityContent>
        <ActivityTitle>
          <Bold>{who.username}</Bold>{' '}{what}{' '}
          {linkText && linkUrl && (
            <Bold>
              <Link underline='always' to={linkUrl}>
                {linkText}
              </Link>
            </Bold>
          )}
        </ActivityTitle>
        <ActivityTimestamp timestamp={insertedAt} />
        {children}
      </ActivityContent>
    </ActivityItem>
  );
}

GenericActivityItem.propTypes = {
  type: PropTypes.string,
  who: PropTypes.object.isRequired,
  what: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  insertedAt: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default GenericActivityItem;
