import { useRef} from 'react';

// TODO: This should NOT be living here
export default function useFocusWithin(props) {
  const state = useRef({
    isFocusWithin: false
  }).current;

  if (props.isDisabled) {
    return {focusWithinProps: {}};
  }

  const onFocus = (e) => {
    if (!state.isFocusWithin) {
      if (props.onFocusWithin) {
        props.onFocusWithin(e);
      }

      if (props.onFocusWithinChange) {
        props.onFocusWithinChange(true);
      }

      state.isFocusWithin = true;
    }
  };

  const onBlur = (e) => {
    //debugger;

    if (!state.isFocusWithin) {
      return;
    }

    // We don't want to trigger onBlurWithin and then immediately
    // onFocusWithin again when moving focus inside the element.
    // Only trigger if the currentTarget doesn't include the relatedTarget
    // (where focus is moving).
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }

    if (props.onBlurWithin) {
      props.onBlurWithin(e);
    }

    if (props.onFocusWithinChange) {
      props.onFocusWithinChange(false);
    }

    state.isFocusWithin = false;
  };

  return {
    focusWithinProps: {
      onFocus: onFocus,
      onBlur: onBlur
    }
  };
}
