import React from 'react';
import { useParams } from 'react-router-dom';
import SearchProjectInTeam from './SearchProjectInTeam';

function ArchivedProjects() {
  const { teamId } = useParams();
  return <SearchProjectInTeam teamId={teamId} archived />;
}

export default ArchivedProjects;
