import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import MessageBoard from '../ui/message/MessageBoard';
import MessageBoardHeader from '../ui/message/MessageBoardHeader';
import MessageBoardContent from '../ui/message/MessageBoardContent';
import ListSkeleton from '../ui/message/ListSkeleton';
import ProjectMessagesQuery from './ProjectMessagesQuery';
import MessageList from './MessageList';

export default function ProjectMessageBoard() {
  const { projectId } = useParams();
  const { url } = useRouteMatch();

  return (
    <MessageBoard>
      <MessageBoardHeader />
      <MessageBoardContent>
        <ProjectMessagesQuery
          fetchPolicy='network-only'
          projectId={projectId}
          onLoading={() =>  <ListSkeleton />}
        >
          {(messages) => (
            <MessageList
              rootUrl={url}
              messages={messages}
            />
          )}
        </ProjectMessagesQuery>
      </MessageBoardContent>
    </MessageBoard>
  );
}
