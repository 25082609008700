import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '../icons/16x16/InfoCircle';
import DialogContext from '../ui/dialog/DialogContext';
import ToolbarButton from './ProjectHeaderToolbarButton';
import OptionDialog from './options/OptionDialog';
import { projectOption } from './options/OptionList';

function ProjectHeaderInfoButton({ projectId }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: (
        <OptionDialog
          projectId={projectId}
          defaultOption={projectOption.EDIT_PROJECT}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <ToolbarButton
      icon={<InfoIcon />}
      onClick={handleClick}
    />
  );
}

ProjectHeaderInfoButton.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectHeaderInfoButton;
