import React from 'react';
import PropTypes from 'prop-types';
import MonthGridContainer from './MonthGridContainer';
import useRect from '../../new-ui/hooks/useRect';
import {
  convertMousePositionToGridCoordinates,
  snapshotId,
  matchesSnapshot,
  isSameLocation
} from './helpers';

// Are the two given events e1, e2 of the same (graphql) type?
// This is just a hack to avoid collision between a todo and an event
// which happend to have the exact same id (from backend).
function _isSameType(e1, e2) {
  return e1.__typename === e2.__typename;
}

const MonthGridDragDropContext = React.createContext(null);

function MonthGridDragDropProvider({
  numRows,
  numCols,
  gridRef,
  getDayAtCell,
  dispatchEvents,
  onChange,
  onDragEnd,
  children,
  ...rest
}) {
  // The bounding client rect of the days grid.
  const rect = useRect(gridRef);

  // Keep track of the source {event, cell, day}.
  const [source, setSource] = React.useState(null);

  // Helper function to convert the mouse position into grid
  // coordinates { rowIndex, colIndex }.
  const getMousePos = React.useCallback((event) => {
    const mousePos = { pageX: event.pageX, pageY: event.pageY };
    const options = { rect, numRows, numCols };
    return convertMousePositionToGridCoordinates(mousePos, options);
  }, [rect, numRows, numCols]);

  // Helper function to compute new schedule { startsAt, endsAt } for
  // the source event as cursor moving from one cell to the next.
  const getNewSchedule = React.useCallback((destination) => {
    const dstDay = getDayAtCell(destination);

    if (!dstDay) {
      return null;
    }

    return onChange(source.event, { start: source.day, end: dstDay });
  }, [source, getDayAtCell, onChange]);

  // While dragging
  const handleMouseMove = React.useCallback((mouseEvent) => {
    if (!source) { return; }

    const destination = getMousePos(mouseEvent);
    const newSchedule = getNewSchedule(destination);
    if (!newSchedule) { return; }

    const srcEvent = source.event;
    const sameLocation = isSameLocation(destination, source.cell);

    dispatchEvents((prev) => {
      let foundSnapshot = false;

      const next = prev.map((e) => {
        if (matchesSnapshot(e.id)) {
          foundSnapshot = true;
          return { ...e, ...newSchedule };
        }

        if ((e.id !== srcEvent.id) || !_isSameType(e, srcEvent)) {
          return e;
        }

        return { ...e, isDragging: true };
      });

      if (sameLocation) {
        return next.filter((e) => !matchesSnapshot(e.id));
      }

      if (foundSnapshot) { return next; }

      return [
        ...next,
        {
          ...srcEvent,
          id: snapshotId,
          isDragging: true,
          ...newSchedule
        }
      ];
    });
  }, [getMousePos, getNewSchedule, dispatchEvents, source]);

  // dragging stopped
  const handleMouseUp = React.useCallback((mouseEvent) => {
    if (!source) { return; }

    const destination = getMousePos(mouseEvent);
    const newSchedule = getNewSchedule(destination);
    if (!newSchedule) { return; }

    const srcEvent = source.event;
    const sameLocation = isSameLocation(destination, source.cell);

    setSource(null);

    // TODO: do we really need this with optimistic response?
    dispatchEvents((prev) => {
      return prev.filter((e) => !matchesSnapshot(e.id)).map((e) => {
        if ((e.id === srcEvent.id) && _isSameType(e, srcEvent)) {
          delete e.isDragging;
          return { ...e, ...newSchedule };
        }
        return e;
      });
    });

    if (onDragEnd && !sameLocation) {
      onDragEnd(srcEvent, newSchedule);
    }
  }, [getMousePos, getNewSchedule, onDragEnd, dispatchEvents, source]);

  const ctx = React.useMemo(() => ({
    onMouseDown: (mouseEvent, data) => {
      const cell = getMousePos(mouseEvent);
      setSource({
        cell,
        day: getDayAtCell(cell),
        event: data.event
      });
    }
  }), [getMousePos, getDayAtCell]);

  return (
    <MonthGridContainer
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{cursor: source ? 'move' : 'default'}}
      {...rest}
    >
      <MonthGridDragDropContext.Provider value={ctx}>
        {children}
      </MonthGridDragDropContext.Provider>
    </MonthGridContainer>
  );
}

MonthGridDragDropProvider.propTypes = {
  // The grid attributes
  numRows: PropTypes.number.isRequired,
  numCols: PropTypes.number.isRequired,
  gridRef: PropTypes.object,

  // Which day at the cell { rowIndex, colIndex }?
  getDayAtCell: PropTypes.func.isRequired,

  // When an event is dragged, its snapshot is created and added to the
  // (original) events list. The schedule of this snapshot event
  // (its startsAt and endsAt) will be changing as cursor moving from
  // one cell to the next. The snapshot will be removed when drag is
  // finished. Therefore this prop is here to help updaing the state
  // of the (parent) events list.
  dispatchEvents: PropTypes.func.isRequired,

  // The `srcEvent` was being dragged from the original cell (start) to
  // the destination cell (end).
  // (srcEvent, { start, end }) => {...}
  onChange: PropTypes.func.isRequired,

  // Re-schedule the sourceEvent (when drag is finished).
  // (sourceEvent, newSchedule) => {...}
  onDragEnd: PropTypes.func
};

export default MonthGridDragDropProvider;

export function useDragDropContext() {
  return React.useContext(MonthGridDragDropContext);
}
