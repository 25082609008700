import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import convertRowIndexToTimeInterval
from '../../daily-schedule/ui/convertRowIndexToTimeInterval';

import { TIMELINE } from '../../daily-schedule/ui/constants';
import StyledTimeSpecificGrid from './StyledTimeSpecificGrid';

import convertMousePositionToGridCoordinates
from './convertMousePositionToGridCoordinates';

// https://www.w3schools.com/jsref/event_button.asp
// TODO: Internet Explorer 8 and earlier has different return value: 1
function _isLeftMousePressed(mouseEvent) {
  return mouseEvent && mouseEvent.button === 0;
}

function TimeSpecificGrid({
  numCols,
  onDragStart,
  onDragUpdate,
  onDragEnd,
  onClick,
  children
}) {
  const ref = React.useRef(null);

  const [isDragging, setIsDragging] = React.useState(false);
  const [dragStart, setDragStart] = React.useState(null);

  const getColIndexAndTimeInterval = React.useCallback((e) => {
    const rect = ref.current.getBoundingClientRect();

    const options = {
      rect,
      numCols,
      numRows: TIMELINE.length - 1
    };

    const {
      colIndex,
      rowIndex
    } = convertMousePositionToGridCoordinates(e, options);

    return {
      colIndex,
      time: convertRowIndexToTimeInterval(rowIndex, TIMELINE)
    }
  }, [numCols]);

  // If user presses the mouse for more than two milisecond, we interpret
  // it as a dragging event, otherwise it is a click event.
  const debounceMouseDown = debounce((e) => {
    if (!_isLeftMousePressed(e)) {
      return;
    }

    const result = getColIndexAndTimeInterval(e);

    setIsDragging(true);
    setDragStart(result);

    if (onDragStart) {
      onDragStart(e, result);
    }
  }, 300);

  // User is dragging around.
  const handleMouseMove = (e) => {
    if (!isDragging) { return; }

    const dragEnd = getColIndexAndTimeInterval(e);

    if (onDragUpdate) {
      onDragUpdate(dragStart, dragEnd);
    }
  };

  // User stops dragging
  const handleMouseUp = (e) => {
    const dragEnd = getColIndexAndTimeInterval(e);

    if (!isDragging) {
      // If user releases the mouse before two miliseconds, we have to
      // cancel the registered mouse down event.
      debounceMouseDown.cancel();

      // Tell client that user has just clicked on some time-interval
      if (onClick && _isLeftMousePressed(e) && e.target === ref.current) {
        onClick(dragEnd);
      }

      return;
    }

    setIsDragging(false);
    setDragStart(null);

    if (onDragEnd) {
      onDragEnd(dragStart, dragEnd);
    }
  };

  return (
    <StyledTimeSpecificGrid
      ref={ref}
      isDragging={isDragging}
      onMouseDown={(e) => {
        e.persist();
        debounceMouseDown(e);
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {children}
    </StyledTimeSpecificGrid>
  );
}

TimeSpecificGrid.propTypes = {
  numCols: PropTypes.number,
  onDragStart: PropTypes.func,
  onDragUpdate: PropTypes.func,
  onDragEnd: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node
};

TimeSpecificGrid.defaultProps = {
  numCols: 7
};

export default TimeSpecificGrid;
