import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '../../icons/Error';

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

// TODO: show `error`
export default function CellError({ error }) {
  const classes = useStyles();
  return (
    <div className={classes.errorContainer}>
      <ErrorIcon color='negative' />
    </div>
  );
}
