import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Row from './Row';
import Cell from './LargeCell';
import Button from '../../button/Button';
import UploadIcon from '../../../icons/20x20/UploadFill';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  }
}));

function NoFilesRow({ colSpan, onUpload }) {
  const classes = useStyles();

  return (
    <Row>
      <Cell colSpan={colSpan}>
        <div className={classes.root}>
          <Typography
            variant='body1'
            color='textSecondary'
            className={classes.title}
          >
            No files
          </Typography>

          <Typography
            variant='body1'
            color='textSecondary'
            className={classes.subtitle}
          >
            You don't have any files in this folder.
          </Typography>

          <Button
            startIcon={<UploadIcon />}
            onClick={onUpload}
          >
            Upload
          </Button>
        </div>
      </Cell>
    </Row>
  );
}

NoFilesRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  onUpload: PropTypes.func
};

export default NoFilesRow;
