import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../ui/button/IconButton';
import ArrowRightIcon from '../ui/icons/ArrowRight';
import { FILTER_PRIMARY } from '../ui/icons/Base';

const useStyles = makeStyles((theme) => ({
  expand: {
    minWidth: 32,
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover $icon': {
      filter: FILTER_PRIMARY
    }
  },
  expandOpen: {
    transform: 'rotate(90deg)',
  },
  icon: {}
}));

function ExpandSectionButton({ expanded, ...rest }) {
  const classes = useStyles();

  return (
    <IconButton
      color='secondary'
      square
      background={false}
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded
      })}
      icon={ <ArrowRightIcon className={classes.icon} /> }
      {...rest}
    />
  );
}

ExpandSectionButton.propTypes = {
  expanded: PropTypes.bool
};

ExpandSectionButton.defaultProps = {
  expanded: false
};

export default React.memo(ExpandSectionButton);
