import React from 'react';
import List from './List';
import ListItemSkeleton from './ListItemSkeleton';

export default function ListSkeleton() {
  return (
    <List>
      <ListItemSkeleton />
    </List>
  );
}
