import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ActivityItem from './ActivityItem';

export default function ActivityItemSkeleton(props) {
  return (
    <ActivityItem
      avatar={ <Skeleton variant='circle' width={40} height={40} />}
      primary={ <Skeleton height={12} style={{marginTop: 8}}/> }
      secondary={ <Skeleton width='50%' height={12}/> }
      divider={ <Skeleton width='40%' height={12} /> }
      {...props}
    />
  );
}
