import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import UserListItem from './UserListItem';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    marginTop: theme.spacing(1),
    '&:focus': {
      outline: 0
    }
  }
}));

function UserList({ users, onDelete }) {
  const classes = useStyles();
  const ref = React.useRef(null);

  const handleDeleteUser = (user) => {
    if (onDelete) {
      onDelete(user);

      // a (dirty) hack to move the focus to the parent when
      // one of its children is removed.
      // TODO: the focus must be moved to the previous focus element!
      ref.current.focus();
    }
  };

  return (
    <List ref={ref} className={classes.list}>
      {users.map((user) => (
        <UserListItem
          key={user.id}
          user={user}
          onDelete={handleDeleteUser}
        />
      ))}
    </List>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })).isRequired,

  onDelete: PropTypes.func
};

export default UserList;
