import React from 'react';
import MuiSpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

export default function SpeedDial(props) {
  const classes = useStyles();
  return (
    <MuiSpeedDial
      ariaLabel='Folder options'
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      {...props}
    />
  );
}
