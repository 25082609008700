import React from 'react';
import PropTypes from 'prop-types';
import Audio from './Audio';
import PreviewContainer from './PreviewContainer';
import DocumentIconLink from './DocumentIconLink';

// TODO:
// 1. Support multiple sources.
// 2. Provide custom controls.
// 3. More...
function AudioPreview({ src, type, downloadUrl }) {
  const sources = [{src: src, type: type }];

  return (
    <PreviewContainer>
      <Audio
        sources={sources}
        fallback={<DocumentIconLink type={type} href={downloadUrl} />}
      />
    </PreviewContainer>
  );
}

AudioPreview.propTypes = {
  // The source and MIME type of the audio to be previewed.
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  downloadUrl: PropTypes.string
};

export default AudioPreview;
