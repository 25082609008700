import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TODO_SHEET_THEME } from './theme';

// Must be an odd number since we need one extra pixel to cover the
// cell border.
const HANDLE_WIDTH = 9;

const useStyles = makeStyles((theme) => ({
  handle: {
    position: 'absolute',
    top: 0,
    right: -Math.ceil(HANDLE_WIDTH/2),
    bottom: 0,
    height: '100%',
    width: HANDLE_WIDTH,
    backgroundColor: theme.palette.background.paper,
    cursor: 'col-resize'
  },
  midLine: {
    position: 'absolute',
    width: 1,
    top: 0,
    left: Math.floor(HANDLE_WIDTH/2),
    height: '100%',
    backgroundColor: TODO_SHEET_THEME.borderColor,
  },
}));

function ResizeHandle(props) {
  const classes = useStyles();
  const {
    className,
    midLineClassName,
    midLineStyles,
    ...rest
  } = props;

  return (
    <div className={clsx(classes.handle, className)} {...rest}>
      <div
        className={clsx(classes.midLine, midLineClassName)}
        style={midLineStyles}
      />
    </div>
  );
}

ResizeHandle.propTypes = {
  midLineClassName: PropTypes.string,
  midLineStyles: PropTypes.object
};

export default ResizeHandle;
export { HANDLE_WIDTH };
