import React from 'react';
import PropTypes from 'prop-types';
import UnfollowButton from '../../../ui/todo/details/UnfollowTodoButton';
import useUnfollowTodoMutation from './useUnfollowTodoMutation';

function RemoveCurrentUser({ todoId }) {
  const [removeCurrentUser] = useUnfollowTodoMutation({
    variables: { todoId }
  });

  return <UnfollowButton onClick={removeCurrentUser} />;
}

RemoveCurrentUser.propTypes = {
  todoId: PropTypes.string.isRequired
};

export default RemoveCurrentUser;
