import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  startIcon: {
    fontSize: '0.875rem',
    marginRight: theme.spacing(0.5),
    pointerEvents: 'none'
  }
}));

function AllDayChipStartIcon({ icon }) {
  const classes = useStyles();
  return React.cloneElement(icon, { className: classes.startIcon });
}

AllDayChipStartIcon.propTypes = {
  icon: PropTypes.node.isRequired
};

export default AllDayChipStartIcon;
