import React from 'react';
import SecondaryNavItem from './SecondaryNavItem';
import IconButton from '../ui/button/IconButton';
import AddIcon from '../ui/icons/Add';

import Menu from '../ui/menu/StyledMenu';
import MenuItem from '../ui/menu/MenuItem';
import IconAvatar from '../ui/avatar/IconAvatar';
import GroupsIcon from '../ui/icons/Groups';
import ProjectIcon from '../ui/icons/Project';
import TaskIcon from '../ui/icons/Task';
import PersonAddIcon from '../ui/icons/PersonAdd';

import DialogContext from '../ui/dialog/DialogContext';
import CreateTeamDialog from '../team/dialogs/CreateTeam';
import CreateProjectDialog from '../project/new/CreateProjectDialog';
import InvitePeopleDialog
from '../member/dialogs/InvitePeopleToCurrentWorkspace';

const otherMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  PaperProps: {
    style: {
      marginTop: 8,
      width: 320
    }
  }
};

export default function AddNewMenuButton() {
  const { openDialog } = React.useContext(DialogContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <SecondaryNavItem tooltipTitle='Add new'>
        <IconButton
          icon={ <AddIcon /> }
          onClick={handleOpen}
          color={open ? 'accent' : 'primary'}
        />
      </SecondaryNavItem>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...otherMenuProps}
      >
        <MenuItem
          avatar={ <IconAvatar icon={ <GroupsIcon /> } /> }
          primary='Create new team'
          secondary='Create new team for your workspace.'
          onClick={() => {
            handleClose();
            openDialog({
              dialogContent: <CreateTeamDialog />,
              dialogProps: {
                maxWidth: 'sm',
                fullWidth: true
              }
            });
          }}
        />
        <MenuItem
          avatar={ <IconAvatar icon={ <ProjectIcon /> } /> }
          primary='Add new project'
          secondary='Add new project to an existing team.'
          onClick={() => {
            handleClose();
            openDialog({
              dialogContent: <CreateProjectDialog />,
              dialogProps: {
                maxWidth: 'sm',
                fullWidth: true
              }
            });
          }}
        />
        <MenuItem
          avatar={ <IconAvatar icon={ <TaskIcon /> } /> }
          primary='Add new task'
          secondary='Add new task to an existing project.'
        />
        <MenuItem
          avatar={ <IconAvatar icon={ <PersonAddIcon /> } /> }
          primary='Invite more people'
          secondary='Invite people to join your workspace.'
          onClick={() => {
            handleClose();
            openDialog({
              dialogContent: <InvitePeopleDialog />,
              dialogProps: {
                maxWidth: 'sm',
                fullWidth: true
              }
            });
          }}
        />
      </Menu>
    </React.Fragment>
  );
}
