import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../button/IconButton';

function ListItemAction({ icon, ...rest }) {
  return (
    <IconButton
      background={false}
      color='secondary'
      size='small'
      icon={icon}
      {...rest}
    />
  );
}

ListItemAction.propTypes = {
  icon: PropTypes.node.isRequired
};

export default ListItemAction;
