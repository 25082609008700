import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4)
    }
  },
  topMargin: {
    marginTop: theme.spacing(3)
  }
}));

function DialogForm({marginTop, children, ...otherProps}) {
  const classes = useStyles();
  return (
    <form
      className={clsx(classes.form, {
        [classes.topMargin]: marginTop
      })}
      onSubmit={(event) => {
        event.preventDefault();
      }}
      {...otherProps}
    >
      {children}
    </form>
  );
}

DialogForm.propTypes = {
  children: PropTypes.node,
  marginTop: PropTypes.bool
};

DialogForm.defaultProps = {
  marginTop: false
};

export default DialogForm;
