import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

export default function ChipMenuDivider(props) {
  const classes = useStyles();
  return (
    <Divider
      component='li'
      className={classes.divider}
      {...props}
    />
  );
}
