import React from 'react';
import PropTypes from 'prop-types';
import EventsRow from './ui/MonthGridEventsRow';
import EventsCell from './MonthGridEventsCell';
import { useEventsListContext } from './EventsListProvider';
import {
  groupEventsIntoDays,
  dayEventsWithDefaultPresentationAttributes,
  rowEventsWithVisibility,
  rowEventsWithColSpan
} from './helpers';


// TODO: this is kind of a MESS!
function eventsWithAttributes(rowEvents, intersection) {
  let tmp = rowEvents.map(({ day, positionedEvents }, index) => {
    return dayEventsWithDefaultPresentationAttributes(
      day,
      positionedEvents,
      intersection,
      { colIndex: index }
    );
  });

  tmp = rowEventsWithVisibility(tmp);
  tmp = rowEventsWithColSpan(tmp);
  return tmp;
}

function MonthGridEventsRow({ projectId, days }) {
  const [intersection, setIntersection] = React.useState(null);
  const { events } = useEventsListContext();

  // Transform events for rendering
  const daysWithEvents = groupEventsIntoDays(events, days);
  const eventsList = eventsWithAttributes(daysWithEvents, intersection);

  const maxNumberOfEventsADay = daysWithEvents.reduce((
    acc,
    { positionedEvents }
  ) => {
    const len = positionedEvents.length;
    return (len > acc) ? len : acc;
  }, 0);

  const handleIntersect = React.useCallback((intersectionEntry) => {
    setIntersection(intersectionEntry);
  }, []);

  return (
    <EventsRow
      height={maxNumberOfEventsADay}
      onIntersect={handleIntersect}
    >
      {eventsList.map(({ day, events }) => (
        <EventsCell
          key={day}
          projectId={projectId}
          date={day}
          events={events}
        />
      ))}
    </EventsRow>
  );
}

MonthGridEventsRow.propTypes = {
  projectId: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired
};

export default MonthGridEventsRow;
