import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';

const MESSAGE_FOLLOWERS_QUERY = gql`
  query ListMessageFollowers($messageId: ID!, $projectId: ID!) {
    projectMessage(messageId: $messageId, projectId: $projectId) {
      id
      followers {
        id
        username
        email
        avatar
      }
    }
  }
`;

function FollowersQuery({
  projectId,
  messageId,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    MESSAGE_FOLLOWERS_QUERY,
    {
      variables: { messageId, projectId },
      ...otherQueryOptions
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.projectMessage.followers, otherQueryResults);
}


FollowersQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default FollowersQuery;
