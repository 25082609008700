import React from 'react';
import NotificationPage from '../ui/notification/Page';
import List from '../ui/notification/List';
import ListItem from '../ui/notification/ListItem';
import ListItemAvatar from '../ui/notification/ListItemAvatar';
import ListItemText from '../ui/notification/ListItemText';
import UserAvatar from '../account/UserAvatar';
import MarkAsReadButton from '../ui/notification/MarkAllReadButton';

const notifications = [
  {
    id: '1',
    type: 'ASSIGNMENT',
    actor: {
      id: '1',
      username: 'Ls Huyen Trang',
      email: 'lshuyentrang@example.com',
      avatar: null
    },
    primary: 'Assigned you: some task name',
    secondary: [
      'Ls Huyen Trang',
      'Some project name',
      'Yesterday'
    ],
    url: '/foo'
  },

  {
    id: '2',
    type: 'MESSAGE',
    actor: {
      id: '2',
      username: 'Ngoc Linh',
      email: 'ngoclinh@example.com',
      avatar: null
    },
    primary: 'Re: some task name',
    secondary: [
      'Ls Huyen Trang',
      'Some project name',
      'Just now'
    ],
    url: '/bar'
  },

  {
    id: '3',
    type: 'ASSIGNMENT_DONE',
    actor: {
      id: '1',
      username: 'Ls Huyen Trang',
      email: 'lshuyentrang@example.com',
      avatar: null
    },
    primary: 'Completed: some task name',
    secondary: [
      'Ls Huyen Trang',
      'Some project name',
      'Yesterday'
    ],
    url: '/baz'
  },

  {
    id: '4',
    type: 'ASSIGNMENT_UNDONE',
    actor: {
      id: '1',
      username: 'Ls Huyen Trang',
      email: 'lshuyentrang@example.com',
      avatar: null
    },
    primary: 'Re-opened: some task name',
    secondary: [
      'Ls Huyen Trang',
      'Cursus sit amet, dictum sit amet justo donec enim diam, vulputate ut pharetra sit amet, aliquam id diam maecenas. Eget felis eget nunc lobortis mattis aliquam faucibus purus in massa!',
      'Yesterday'
    ],
    url: '/quz'
  },
]

export default function NotificationList() {
  const handleMarkAsRead = (notificationId) => {
    console.log(`Handle mark notificstion ${notificationId} as read`);
  };

  return (
    <NotificationPage>
      <List
        title='New for you'
        action={<MarkAsReadButton />}
      >
        {notifications.map((notification) => (
          <ListItem
            key={notification.id}
            id={notification.id}
            url={notification.url}
            onMarkAsRead={handleMarkAsRead}
          >
            <ListItemAvatar
              primary={<UserAvatar user={notification.actor} />}
              secondary={notification.type}
            />
            <ListItemText
              primary={notification.primary}
              secondary={notification.secondary}
            />
          </ListItem>
        ))}
      </List>

      <List title='Previous notifications'>
        {notifications.map((notification) => (
          <ListItem
            key={notification.id}
            id={notification.id}
            url={notification.url}
          >
            <ListItemAvatar
              primary={<UserAvatar user={notification.actor} />}
              secondary={notification.type}
            />
            <ListItemText
              primary={notification.primary}
              secondary={notification.secondary}
            />
          </ListItem>
        ))}
      </List>
    </NotificationPage>
  );
}
