import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import UserCard from './UserCard';

export default function UserCardSkeleton() {
  return (
    <UserCard
      avatar={ <Skeleton variant='circle' />}
      title={<Skeleton />}
      subtitle={ <Skeleton width='60%' /> }      
    />
  );
}
