import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',

    // Column so that skeleton will take up the entire width when image
    // is loading
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function PreviewContainer(props) {
  const classes = useStyles();
  return <div className={classes.container} {...props} />;
}
