import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Separator from './Separator';
import CollapsedMenu from './CollapsedMenu';

const useStyles = makeStyles((theme) => ({
  ol: {
    width: '100%',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  collapsedLastItem: {
    minWidth: 0
  }
}));

function Breadcrumbs({ collapsed, children, className, ...rest }) {
  const classes = useStyles();
  const childrenCount = React.Children.count(children);

  const getCollapsedItems = () => {
    let items = [];

    React.Children.forEach(children, (child, index) => {
      const neitherFirstNorLast = (index !== 0) &&
                                  (index + 1 !== childrenCount);
      if (neitherFirstNorLast) { items.push(child) };
    });

    return items;
  };

  const augmentLastChild = (lastChild) => {
    return collapsed ?  React.cloneElement(lastChild, {
      className: classes.collapsedLastItem
    }) : lastChild;
  };

  const renderFirstChild = () => {
    return React.Children.map(children, (child, index) => {
      const isFirst = (index === 0);

      if (isFirst && childrenCount >= 2) {
        return (
          <React.Fragment>
            {child}
            <Separator />
          </React.Fragment>
        );
      } else if (isFirst) {
        return augmentLastChild(child);
      } else {
        return null;
      }
    });
  };

  const renderMidChildren = () => {
    const midChildren = getCollapsedItems();

    if (midChildren.length === 0) { return null; }

    if (collapsed) {
      return (
        <React.Fragment>
          <CollapsedMenu
            menuItems={midChildren.map((child) => child.props)}
          />
          <Separator />
        </React.Fragment>
      );
    } else {
      return midChildren.map((child, index) => (
        <React.Fragment key={index}>
          {child}
          <Separator />
        </React.Fragment>
      ));
    }
  };

  const renderLastChild = () => {
    if (childrenCount < 2) { return null; }

    return React.Children.map(children, (child, index) => {
      const isLast = (index + 1 === childrenCount);

      if (isLast) {
        return augmentLastChild(child)
      }

      return null;
    });
  };

  return (
    <ol className={clsx(classes.ol, className)} {...rest}>
      {renderFirstChild()}
      {renderMidChildren()}
      {renderLastChild()}
    </ol>
  );
}

Breadcrumbs.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.node
};

Breadcrumbs.defaultProps = {
  collapsed: false
};

export default Breadcrumbs;
