import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Input from './Input';
import DropDownIcon from './DropDownIcon';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 4,
    width: theme.spacing(24),
    boxShadow: theme.shadows[4],
    marginTop: 8
  },
  list: {
    padding: theme.spacing(1, 0)
  }
}));

function StyledSelect({ typography, background, ...rest }) {
  const classes = useStyles();
  return (
    <Select
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        classes: {
          paper: classes.paper,
          list: classes.list
        },
      }}
      input={(
        <Input
          typography={typography}
          background={background}
        />
      )}
      IconComponent={DropDownIcon}
      {...rest}
    />
  );
}

StyledSelect.propTypes = {
  // Input typography
  typography: PropTypes.oneOf(['body1', 'body2']),

  // When to show background
  background: PropTypes.oneOf(['hover', 'always']).isRequired,
};

StyledSelect.defaultProps = {
  typography: 'body1',
  background: 'hover'
};

export default StyledSelect;
