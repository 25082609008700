import React from 'react';
import Button from '../button/Button';
import AddIcon from '../../icons/16x16/PlusCircleFill';

export default function NewMessageButton(props) {
  return (
    <Button
      size='small'
      color='primaryDeemphasized'
      startIcon={<AddIcon />}
      {...props}
    >
      New message
    </Button>
  );
}
