import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  composerAvatar: {
    marginRight: theme.spacing(1),
    flex: '0 0 auto'
  }
}))

function ComposerAvatar({ user }) {
  const classes = useStyles();
  return (
    <UserAvatar
      size='small'
      user={user}
      className={classes.composerAvatar}
    />
  );
}

ComposerAvatar.propTypes = {
  user: PropTypes.object.isRequired
};

export default ComposerAvatar;
