import { useMutation as _useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

export default function useMutation(mutation, options) {
  const {
    loadingMessage = 'Loading...',
    successMessage = 'Saved',
    ...otherOptions
  } = options;

  const {
    enqueueSnackbar,
    enqueueSuccessSnackbar,
    enqueueGraphQLErrorSnackbar,
    closeSnackbar
  } = useCustomSnackbar();

  // TODO: Should we only be closing the loading snackbar?
  // (in functions onError & onCompleted below)
  const [mutateFn, results] = _useMutation(mutation, {
    onError: (error) => {
      closeSnackbar();
      enqueueGraphQLErrorSnackbar(error);
    },
    onCompleted: (data) => {
      closeSnackbar();
      enqueueSuccessSnackbar(successMessage);
    },
    ...otherOptions
  });

  const customMutateFn = (args) => {
    // Show the loading snackbar right before the mutation takes place.
    // We set `persist` to `true` because we don't know how long this
    // mutation is going to take.
    enqueueSnackbar(loadingMessage, {
      persist: true
    });

    return mutateFn(args);
  };

  return [customMutateFn, results];
}
