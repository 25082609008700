import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../progress/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: theme.spacing(12),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function DialogLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Spinner size={24} />
    </div>
  );
}
