import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  }
}));

export default function EditUploadFormDivider() {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
}
