import AssignmentIcon from '../svg-icons/Assignment';
import DoneIcon from '../svg-icons/Done';
import WarningIcon from '../svg-icons/PriorityHigh';
import MessageIcon from '../svg-icons/Message';
import ScheduleIcon from '../svg-icons/Schedule';
import BellIcon from '../svg-icons/Notification';

const secondaryIconVariant = Object.freeze({
  'ASSIGNMENT': {
    SvgIcon: AssignmentIcon,
    cssStyle: {backgroundColor: '#39AFD5', color: '#fff'}
  },
  'ASSIGNMENT_DONE': {
    SvgIcon: DoneIcon,
    cssStyle: {backgroundColor: '#31A24C', color: '#fff'}
  },
  'ASSIGNMENT_UNDONE': {
    SvgIcon: WarningIcon,
    cssStyle: {backgroundColor: 'hsl(40, 89%, 52%)', color: '#fff'}
  },
  'MESSAGE': {
    SvgIcon: MessageIcon,
    cssStyle: {backgroundColor: '#1877F2', color: '#fff'}
  },
  'SCHEDULE': {
    SvgIcon: ScheduleIcon,
    cssStyle: {backgroundColor: '#FA383E', color: '#fff'}
  },
  'ALERT': {
    SvgIcon: BellIcon,
    cssStyle: {backgroundColor: 'red', color: '#fff'}
  }
});

export function getSecondaryIcon(variant) {
  return secondaryIconVariant[variant];
}

export const VARIANTS = Object.keys(secondaryIconVariant);
