import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuButton from './ViewMenuButton';
import MenuItem from './ViewMenuItem';

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 4,
    boxShadow: theme.customShadow.menu,
    width: theme.spacing(16)
  }
}))((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

function ViewMenu({ options, value, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (newValue) => {
    handleClose();
    if (onChange) {
      onChange(newValue);
    }
  };

  const getValueLabel = () => {
    const found = options.find((option) => option.value === value);
    if (found) {
      return found.label;
    }
    return null;
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <MenuButton
        active={open}
        label={getValueLabel()}
        onClick={handleOpen}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={(option.value === value)}
            primary={option.label}
            onClick={(event) => onSelect(option.value)}
          />
        ))}
      </StyledMenu>
    </React.Fragment>
  );
}

ViewMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,

  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default ViewMenu;
