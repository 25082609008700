import React from 'react';
import UnreadNotificationCountQuery from './UnreadNotificationCountQuery';
import NotificationMenuWithData from './NotificationMenuWithData';

function NotificationMenu() {
  return (
    <UnreadNotificationCountQuery>
      {(count, otherQueryResults) => (
        <NotificationMenuWithData
          count={count}
          {...otherQueryResults}
        />
      )}
    </UnreadNotificationCountQuery>
  );
}

export default NotificationMenu;
