import React from 'react';
import ToolbarIconButton from './ToolbarIconButton';
import EditIcon from '../../../../icons/18x18/Pencil';

export default function ToolbarEditButton(props) {
  return (
    <ToolbarIconButton
      icon={<EditIcon />}
      {...props}
    />
  );
}
