import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import WarningIcon from '../icons/20x20/WarningFillYellow';
import Restore from './ArchivedWarningRestoreButton';
import Trash from './ArchivedWarningTrashButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    verticalAlign: 'baseline',
    fontSize: 'inherit',
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function ArchivedWarning({ projectId }) {
  const classes = useStyles();

  const restore = (
    <Restore
      className={classes.button}
      projectId={projectId}
    />
  );

  const trash = (
    <Trash
      className={classes.button}
      projectId={projectId}
    />
  );

  return (
    <Alert
      severity='warning'
      className={classes.root}
      icon={ <WarningIcon color='warning' /> }
    >
      This project has been archived. You can {restore} or {trash}.
    </Alert>
  );
}

ArchivedWarning.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ArchivedWarning;
