import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CHIP_DEFAULT_COLOR } from '../../daily-schedule/ui/constants';
import { TimelineItemShape } from '../../daily-schedule/ui/types';
import getTimeIntervalTopHeight
from '../../daily-schedule/ui/getTimeIntervalTopHeight';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
    overflow: 'hidden',
    userSelect: 'none',

    // dragging
    boxShadow: theme.shadows[8],

    // The left and width will be passed via props
    right: 0,
    top: 0,
    height: 0
  },

  rootColor: ({ color }) => {
    try {
      return {
        backgroundColor: color,
        color: theme.palette.getContrastText(color)
      };
    } catch (e) {
      // If we failed to get the text color from the given color, we return
      // the default style instead.
      return {
        backgroundColor: CHIP_DEFAULT_COLOR,
        color: theme.palette.getContrastText(CHIP_DEFAULT_COLOR)
      };
    }
  },

  rootOneRow: {
    display: 'flex',
    alignItems: 'center',
    '& $title': {
      marginRight: theme.spacing(1),
      fontSize: '0.625rem'
    },
    '& $subtitle': {
      flex: '0 0 auto',
      fontSize: '0.625rem'
    }
  },

  rootTwoOrThreeRows: {
    display: 'flex',
    alignItems: 'center',
    '& $title': {
      marginRight: theme.spacing(1)
    },
    '& $subtitle': {
      flex: '0 0 auto'
    }
  },

  rootMoreThanThreeRows: {
    padding: theme.spacing(0.5, 1)
  },

  title: {
    fontWeight: 600,
    fontSize: '0.75rem',
    pointerEvents: 'none'
  },

  subtitle: {
    fontSize: '0.75rem',
    pointerEvents: 'none'
  }
}));

function TimeSpecificSnapshot({
  title,
  start,
  end,
  color,
  colIndex,
  width,
  zIndex,
  ...rest
}) {
  const classes = useStyles({ color });

  const {
    top,
    height,
    rowSpan
  } = getTimeIntervalTopHeight({ start, end });

  const isOneRow = (rowSpan === 1);
  const isTwoOrThreeRows = (rowSpan === 2) || (rowSpan === 3);
  const isMoreThanThreeRows = (rowSpan > 3);

  return (
    <div
      className={clsx(classes.root, classes.rootColor, {
        [classes.rootOneRow]: isOneRow,
        [classes.rootTwoOrThreeRows]: isTwoOrThreeRows,
        [classes.rootMoreThanThreeRows]: isMoreThanThreeRows
      })}
      style={{
        top,
        height,
        zIndex: zIndex,
        width: `${width}%`,
        left: `${width * colIndex}%`
      }}
      {...rest}
    >
      <Typography
        noWrap
        component='div'
        variant='body2'
        className={classes.title}
      >
        {title ? title : '(No title)'}
      </Typography>
      <Typography
        noWrap
        variant='body2'
        component='div'
        className={classes.subtitle}
      >
        {start.label} – {end.label}
      </Typography>
    </div>
  );
}

TimeSpecificSnapshot.propTypes = {
  // Typical chip props
  title: PropTypes.string,
  start: TimelineItemShape.isRequired,
  end: TimelineItemShape.isRequired,
  color: PropTypes.string,

  // Which column?
  colIndex: PropTypes.number.isRequired,

  // The width in precentage = 100 / numCols
  width: PropTypes.number.isRequired,

  // Typically 1 + number of existing schedules on the grid.
  zIndex: PropTypes.number
};

TimeSpecificSnapshot.defaultProps = {
  zIndex: 0
};

export default TimeSpecificSnapshot;
