import React from 'react';
import PropTypes from 'prop-types';
import { TODO_SHEET_THEME } from './theme';
import Cell from './Cell';

function MarginRow({ colSpan }) {
  const gap = TODO_SHEET_THEME.gapBetweenSections;

  return (!isNaN(gap) && gap > 0) ? (
    <tr>
      <Cell colSpan={colSpan} style={{height: gap}} />
    </tr>
  ) : null;
}

MarginRow.propTypes = {
  colSpan: PropTypes.number
};

MarginRow.defaultProps = {
  colSpan: 2
};

export default React.memo(MarginRow);
