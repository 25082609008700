import { eachDayOfInterval } from 'date-fns';
import startOfWeek from './startOfWeek';
import endOfWeek from './endOfWeek';

export default function listDaysOfWeek(date) {
  return eachDayOfInterval({
    start: startOfWeek(date),
    end: endOfWeek(date)
  });
}
