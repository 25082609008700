import React from 'react';
import PropTypes from 'prop-types';
import StyledDialogTitle from './StyledDialogTitle';
import Tabs, { tabValues } from './Tabs';
import TabPanel from './TabPanel';
import Divider from '@material-ui/core/Divider';
import CreateEventFormDialog from './CreateEventFormDialog';
import CreateTodoFormDialog from './CreateTodoFormDialog';
import { useDialogContext } from '../DialogProvider';

function FormDialog({ projectId, date }) {
  const { closeDialog } = useDialogContext();
  const [activeTab, setActiveTab] = React.useState('event');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <React.Fragment>
      <StyledDialogTitle title='Create new' onClose={closeDialog}>
        <Tabs activeTab={activeTab} onChange={handleTabChange} />
      </StyledDialogTitle>
      <Divider />
      {tabValues.map((value) => (
        <TabPanel key={value} value={value} activeTab={activeTab}>
          {(value === 'event') ? (
            <CreateEventFormDialog
              projectId={projectId}
              onClose={closeDialog}
              defaultStartDate={date}
              defaultAllDay={true}
            />
          ) : (
            <CreateTodoFormDialog
              projectId={projectId}
              defaultDueDate={date}
              onClose={closeDialog}
            />
          )}
        </TabPanel>
      ))}
    </React.Fragment>
  );
}

FormDialog.propTypes = {
  // Which project does this about-to-be-created event/todo belong to?
  projectId: PropTypes.string.isRequired,

  // Starting date (will not be null if this dialog is open when user
  // clicks on one of month grid cells)
  date: PropTypes.instanceOf(Date)
};

export default FormDialog;
