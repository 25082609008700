import React from 'react';
import PropTypes from 'prop-types';
import CardHeaderAction from '../ui/card/CardHeaderAction';
import PinIcon from '../icons/20x20/PinFill';
import EllipsisIcon from '../icons/20x20/Ellipsis';
import ProjectCardMenu from './menu/ProjectCardMenu';

function ProjectCardAction({ projectId, pinned, archived }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const icon = pinned ?
               <PinIcon style={{filter: 'none'}} /> :
               <EllipsisIcon />;

  return (
    <>
      <CardHeaderAction
        color='secondary'
        icon={icon}
        onClick={handleClick}
      />

      <ProjectCardMenu
        projectId={projectId}
        pinned={pinned}
        archived={archived}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      />
    </>
  );
}

ProjectCardAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  pinned: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired
};

export default React.memo(ProjectCardAction);
