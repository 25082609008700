import React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, fade } from '@material-ui/core/styles';
import Cell from './Cell';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: '0 !important'
  },
  // Without this, Safari won't make `inputRoot` the same height as cell.
  safariInputContainer: {
    width: '100%',
    height: '100%'
  },
  inputRoot: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1, 2),
    fontSize: '0.875rem'
  },
  focused: {
    outline: 'none',
    borderColor: '#AAC9FF',
    boxShadow: `0 0 6px ${fade(theme.palette.primary.main, 0.5)}`
  }
}));

function UploadFileNotesCell({ notes, onChange }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(notes);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const notifyOnChange = () => {
    if ((notes !== value) && onChange) {
      onChange(value);
    }
  };

  return (
    <Cell className={classes.cell}>
      <div className={classes.safariInputContainer}>
        <InputBase
          multiline
          placeholder='Add notes...'
          classes={{
            root: classes.inputRoot,
            focused: classes.focused
          }}
          value={value}
          onChange={handleChange}
          onBlur={notifyOnChange}
        />
      </div>
    </Cell>
  );
}

UploadFileNotesCell.propTypes = {
  notes: PropTypes.string,
  onChange: PropTypes.func
};

UploadFileNotesCell.defaultProps = {
  notes: ''
};

export default UploadFileNotesCell;
