import React from 'react';
import PropTypes from 'prop-types';
import TimeSpecificScheduleItem from './TimeSpecificScheduleItem';
import getScheduleKey from './getScheduleKey';
import isSameTypeAndId from './isSameTypeAndId';
import combineSchedulesWithChipProps from './combineSchedulesWithChipProps';
import Timeline from './Timeline';

function TimeSpecificSchedule({ projectId, date, schedules }) {
  const schedulesWithChipProps = combineSchedulesWithChipProps(schedules);
  const [dragItem, setDragItem] = React.useState(null);

  // User starts to drag existing schedule.
  const handleDragStart = React.useCallback((schedule) => {
    const found = schedulesWithChipProps.find((item) => (
      isSameTypeAndId(item.schedule, schedule)
    ));

    if (found) {
      setDragItem(found);
    }
  }, [schedulesWithChipProps]);

  // User stops dragging.
  const handleDragEnd = React.useCallback(() => {
    setDragItem(null);
  }, []);

  const isDragging = (schedule) => {
    return dragItem && isSameTypeAndId(schedule, dragItem.schedule);
  };

  return (
    <Timeline
      projectId={projectId}
      date={date}
      dragZIndex={schedulesWithChipProps.length}
      dragItem={dragItem}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {schedulesWithChipProps.map(({ schedule, chipProps }) => (
        <TimeSpecificScheduleItem
          key={getScheduleKey(schedule)}
          projectId={projectId}
          schedule={schedule}
          chipProps={{
            ...chipProps,
            isDragging: isDragging(schedule)
          }}
        />
      ))}
    </Timeline>
  );
}

TimeSpecificSchedule.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  schedules: PropTypes.array.isRequired
};

export default TimeSpecificSchedule;
