import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import AttachFileIcon from '../../icons/16x16/Paperclip';
import IconButton from './ToolbarIconButton';

function AttachFileButton({ onAttach }) {
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: onAttach,
    noDrag: true
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton
        aria-label='attach files'
        icon={ <AttachFileIcon /> }
      />
    </div>
  );
}

AttachFileButton.propTypes = {
  onAttach: PropTypes.func
};

AttachFileButton.defaultProps = {
  onAttach: (selectedFiles) => {
    console.log(selectedFiles);
  }
};

export default AttachFileButton;
