import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TimelineLabel from './TimelineLabel';
import { TIMELINE } from './constants';

const useStyles = makeStyles((theme) => ({
  dailyScheduleTimeline: {
    position: 'relative',
    flex: '1 1 auto',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.spacing(6.5),
      width: 1,
      backgroundColor: theme.palette.divider
    }
  }
}));

function DailyScheduleTimeline({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.dailyScheduleTimeline}>
      {TIMELINE.map(({ label }, index) => (
        <TimelineLabel
          key={label}
          label={label}
          hidden={(index % 4) !== 0}
        />
      ))}
      {children}
    </div>
  );
}


DailyScheduleTimeline.propTypes = {
  children: PropTypes.node
};

export default DailyScheduleTimeline;
