import React from 'react';
import NotificationButton from './NotificationButton';
import Menu from './Menu';
import UnreadNotificationCountChangedSubscriber
from './UnreadNotificationCountChangedSubscriber';

function NotificationMenuWithData({ count, subscribeToMore }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <NotificationButton
        count={count}
        highlight={open}
        onClick={handleOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />

      <UnreadNotificationCountChangedSubscriber
        subscribeToUnreadNotificationCountChanged={subscribeToMore}
      />
    </React.Fragment>
  );
}

export default NotificationMenuWithData;
