import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

function VisuallyHiddenSortLabel({ order }) {
  const classes = useStyles();
  return (
    <span className={classes.visuallyHidden}>
      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
    </span>
  );
}

VisuallyHiddenSortLabel.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired
};

export default VisuallyHiddenSortLabel;
