import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '../../icons/16x16/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import { TODO_CRITERIA_SHAPE, matchesNewRowId } from '../helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '100%',
    borderRadius: 0,
    color: theme.palette.text.secondary
  }
}));

function ViewTodoDetailsButton({ rootUrl, todo, todoCriteria }) {
  const classes = useStyles();
  const history = useHistory();
  const isUnsavedTodo = matchesNewRowId(todo.id);

  // See `SectionFooter.js` and `AddTodoFormRow.js` for more details
  const handleClick = (event) => {
    if (isUnsavedTodo) {
      const { onSaveAndRedirect, ...task } = todo;
      if (onSaveAndRedirect) { onSaveAndRedirect(task); }
    } else {
      history.push({
        pathname: `${rootUrl}/${todo.id}`,
        todoCriteria
      });
    }
  };

  return (
    <Button
      component='div'
      endIcon={<ArrowForwardIcon color='secondary' />}
      className={classes.button}
      onClick={handleClick}
    >
      Details
    </Button>
  );
}

ViewTodoDetailsButton.propTypes = {
  rootUrl: PropTypes.string.isRequired,
  todo: PropTypes.object.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

export default ViewTodoDetailsButton;
