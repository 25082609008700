import React from 'react';
import PropTypes from 'prop-types';
import PageNavigation from '../ui/page/Navigation';
import TeamActions from './TeamActions';
import TeamTabs from './TeamTabs';

function TeamNavigation({ teamId }) {
  return (
    <PageNavigation>
      <TeamTabs />
      <TeamActions teamId={teamId} />
    </PageNavigation>
  );
}

TeamNavigation.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamNavigation);
