import React from 'react';
import DialogTitle from '../../ui/dialog/MultiStepTitle';

export default function PickTeamDialogTitle(props) {
  return (
    <DialogTitle noWrap {...props}>
      Let's pick a team
    </DialogTitle>
  );
}
