import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiChip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    transition: 'none',
    backgroundColor: '#E4E6EB',
    cursor: 'inherit',
    verticalAlign: 'baseline',

    // make text bold & 14px font size
    ...theme.typography.subtitle2
  },

  none: {
    display: 'inline-block',
    padding: theme.spacing(0, 1) // Same as small chip
  },

  // Variances
  low: {
    backgroundColor: '#68cc45',
    color: theme.palette.getContrastText('#68cc45')
  },
  medium: {
    backgroundColor: '#febe30',
    color: theme.palette.getContrastText('#febe30')
  },
  high: {
    backgroundColor: '#ff0053',
    color: theme.palette.getContrastText('#ff0053')
  }
}));

const priorityLabel = Object.freeze({
  'none': '—',
  'low': 'Low',
  'medium': 'Medium',
  'high': 'High'
});

function Chip({ variant }) {
  const classes = useStyles();
  const lowercaseVariant = variant.toLowerCase();
  const label = priorityLabel[lowercaseVariant];

  return (lowercaseVariant === 'none') ? (
    <Typography
      component='span'
      variant='body1'
      color='textSecondary'
      className={classes.none}
    >
      {label}
    </Typography>
  ) : (
    <MuiChip
      size='small'
      label={label}
      className={clsx(classes.chip, classes[lowercaseVariant])}
    />
  );
}

const variantType = PropTypes.oneOf([
  'none', 'low', 'medium', 'high',
  'NONE', 'LOW', 'MEDIUM', 'HIGH'
]);

Chip.propTypes = {
  variant: variantType.isRequired
};

export default Chip;
export { variantType };
