import React from 'react';
import PropTypes from 'prop-types';
import SubscribeMeButton from '../ui/subscribers/SubscribeMeButton';
import useSubscribeMeMutation from './useSubscribeMeMutation';

function SubscribeMe({ eventId }) {
  const [subscribeMe] = useSubscribeMeMutation({
    variables: { eventId }
  });

  return <SubscribeMeButton onClick={subscribeMe} />;
}

SubscribeMe.propTypes = {
  eventId: PropTypes.string.isRequired
};

export default SubscribeMe;
