import React from 'react';
import PropTypes from 'prop-types';
import HeaderSkeleton from '../../ui/todo/details/HeaderSkeleton';
import Content from '../../ui/todo/details/Content';
import TodoDetailsSkeleton from '../../ui/todo/details/TodoDetailsSkeleton';

function TodoDetailsLoading({ onClose }) {
  return (
    <React.Fragment>
      <HeaderSkeleton onClose={onClose} />
      <Content>
        <TodoDetailsSkeleton />
      </Content>
    </React.Fragment>
  );
}

TodoDetailsLoading.propTypes = {
  onClose: PropTypes.func
};

export default TodoDetailsLoading;
