import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../ui/button/ButtonLink';

const useStyles = makeStyles((theme) => ({
  button: {
    verticalAlign: 'baseline',
    fontSize: 'inherit',
    fontWeight: 500
  }
}));

function ChangeRoleActions({ onSubmit, onCancel }) {
  const classes = useStyles();
  return (
    <span>
      Press Enter to{' '}
      <Button className={classes.button} onClick={onSubmit}>
        save
      </Button>{' '}
      or ESC to{' '}
      <Button className={classes.button} onClick={onCancel}>
        discard changes
      </Button>
      .
    </span>
  );
}

ChangeRoleActions.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default ChangeRoleActions;
