import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    flexGrow: 1,
    marginRight: theme.spacing(1.5)
  },
  primaryText: {
    fontSize: '1.0625rem',
    fontWeight: 500,
    lineHeight: 1.1765
  },
  secondaryText: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.2666666667
  }
}));

function ListItemText({ primary, secondary }) {
  const classes = useStyles();

  return (
    <div className={classes.listItemText}>
      <Typography
        variant='body1'
        color='textPrimary'
        className={classes.primaryText}
      >
        {primary}
      </Typography>

      {secondary && (
        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.secondaryText}
        >
          {secondary}
        </Typography>
      )}
    </div>
  );
}

ListItemText.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string
};

export default ListItemText;
