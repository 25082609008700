import React from 'react';
import PropTypes from 'prop-types';
import subTime from '../../new-ui/datetime-picker/subTime';
import compareTime from '../../new-ui/datetime-picker/compareTime';
import addTime from './ui/addTime';
import TimelineDragDropContext from './ui/TimelineDragDropContext';
import TimeSpecificScheduleChip from './ui/TimeSpecificScheduleChip';
import UpdateTimeMutation from './UpdateTimeMutation';
import FormDialog from './FormDialog';

// Helpers.

function _init(dragItem) {
  if (!dragItem) {
    return null;
  }

  return {
    ...dragItem,
    originalStart: dragItem.chipProps.start,
    originalEnd: dragItem.chipProps.end,
    isNew: false,
    isDraggingStopped: false
  };
}

function _createDragItem({ start, end }) {
  return {
    schedule: null,
    chipProps: {
      start,
      end
    },
    originalStart: start,
    originalEnd: end,
    isNew: true,
    isDraggingStopped: false
  };
}

function _moveItemToInterval(dragItem, { dragStart, dragEnd }) {
  const { originalStart, originalEnd } = dragItem;
  const delta = subTime(dragEnd.start, dragStart.start);

  return {
    ...dragItem,
    chipProps: {
      ...dragItem.chipProps,
      start: addTime(originalStart, delta),
      end: addTime(originalEnd, delta)
    }
  };
}

function _dragItemToFitInterval(dragItem, { dragStart, dragEnd }) {
  let startTime = dragStart.start;
  let endTime = dragStart.end;

  if (compareTime(dragEnd.end, endTime) > 0) {
    endTime = dragEnd.end;
  } else {
    startTime = dragEnd.start;
  }

  return {
    ...dragItem,
    chipProps: {
      ...dragItem.chipProps,
      start: startTime,
      end: endTime
    }
  };
}

function _updateDragItem(dragItem, dragRange) {
  if (dragItem.isNew) {
    return _dragItemToFitInterval(dragItem, dragRange);
  } else {
    return _moveItemToInterval(dragItem, dragRange);
  }
}

function _reducer(state, action) {
  switch(action.type) {
    case 'START_DRAGGING':
      const { event, timeInterval } = action.payload;

      // User drags to create new schedule.
      if (event === 'DRAG') {
        return _createDragItem(timeInterval);
      }

      // User clicks to create new schedule.
      if (event === 'CLICK') {
        return {
          ..._createDragItem({
            start: timeInterval.start,
            end: addTime(timeInterval.start, {hours: 1, minutes: 0})
          }),
          isDraggingStopped: true
        };
      }

      throw new Error(`Unknown event: ${event}`);

    case 'UPDATE_DRAGGING':
      if (!state) {
        return state;
      }

      return _updateDragItem(state, action.payload);

    case 'STOP_DRAGGING':
      if (!state) {
        return state;
      }

      return {...state, isDraggingStopped: true};

    case 'RESET_DRAG_ITEM':
      return _init(action.payload);

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}

function Timeline({
  projectId,
  date,
  dragItem,
  dragZIndex,
  onDragStart,
  onDragEnd,
  children
}) {
  const [state, dispatch] = React.useReducer(_reducer, dragItem, _init);

  const handleDragStart = React.useCallback((mouseEvt, timeInterval) => {
    const id = mouseEvt.target.dataset.scheduleId;
    const type = mouseEvt.target.dataset.scheduleType;

    if (id && type && onDragStart) {
      // Finds and sets the `dragItem`.
      onDragStart({ id, __typename: type });
    } else {
      // Drags to create new schedule.
      dispatch({
        type: 'START_DRAGGING',
        payload: { event: 'DRAG', timeInterval }
      });
    }
  }, [onDragStart]);

  const handleDragUpdate = React.useCallback((mouseEvt, dragRange) => {
    dispatch({
      type: 'UPDATE_DRAGGING',
      payload: dragRange
    });
  }, []);

  const handleDragEnd = React.useCallback((mouseEvt, dragRange) => {
    if (onDragEnd) {
      onDragEnd();
    }

    dispatch({
      type: 'STOP_DRAGGING',
      payload: dragRange
    });
  }, [onDragEnd]);

  // Clicks to create new schedule.
  const handleClick = React.useCallback((mouseEvt, timeInterval) => {
    dispatch({
      type: 'START_DRAGGING',
      payload: { event: 'CLICK', timeInterval }
    });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: 'RESET_DRAG_ITEM',
      payload: dragItem
    });
  }, [dragItem]);

  const {
    chipProps = null,
    schedule = null,
    isNew = false,
    isDraggingStopped = false
  } = state || {};

  return (
    <TimelineDragDropContext
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
    >
      {children}

      {chipProps && (
        <TimeSpecificScheduleChip
          title={chipProps.title}
          start={chipProps.start}
          end={chipProps.end}
          color={chipProps.color}
          focusZIndex={dragZIndex}
          width={100}
          focus
        />
      )}

      <UpdateTimeMutation
        runMutation={isDraggingStopped && !isNew}
        schedule={schedule}
        startTime={chipProps ? chipProps.start : null}
        endTime={chipProps ? chipProps.end : null}
      />

      {(chipProps && isNew && isDraggingStopped) && (
        <FormDialog
          projectId={projectId}
          date={date}
          time={{start: chipProps.start, end: chipProps.end}}
          onClose={() => dispatch({
            type: 'RESET_DRAG_ITEM',
            payload: null
          })}
        />
      )}
    </TimelineDragDropContext>
  );
}

Timeline.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,

  // The existing item that is currently being dragged around.
  // Clients have to set it or remove it using the following
  // `onDragStart` and `onDragEnd`, respectively.
  dragItem: PropTypes.shape({
    // This is the original schedule object and it won't be affected
    // at all by dragging. So why do we include it here? because
    // we want to make it easier to handle when the dragging stops.
    // (we have the original schedule right away at our disposal).
    schedule: PropTypes.object.isRequired,

    // This shit will be changing while dragging around.
    chipProps: PropTypes.object.isRequired
  }),

  // The zIndex applied to the dragging chip
  // (typically numSchedules + 1)
  dragZIndex: PropTypes.number.isRequired,

  // ({ id, __typename }) => {...}
  onDragStart: PropTypes.func,

  // () => {...}
  onDragEnd: PropTypes.func
};

export default Timeline;
