import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ProjectGridItem from './ProjectGridItem';
import NoFavorites from './NoFavorites';
import SomeProjectPinnedOrUnpinnedSubscriber
from './SomeProjectPinnedOrUnpinnedSubscriber';

function ProjectGrid({ projects, subscribeToMore }) {
  const isEmpty = projects.length === 0;

  return (
    <React.Fragment>
      {isEmpty ? <NoFavorites /> : (
        <Grid container spacing={2}>
          {projects.map((project) => (
            <ProjectGridItem key={project.id} project={project} />
          ))}
        </Grid>
      )}
      <SomeProjectPinnedOrUnpinnedSubscriber
        subscribeToSomeProjectPinnedOrUnpinned={subscribeToMore}
      />
    </React.Fragment>
  );
}

ProjectGrid.propTypes = {
  projects: PropTypes.array.isRequired,
  subscribeToMore: PropTypes.func.isRequired
};

export default ProjectGrid;
