import React from 'react';
import CurrentMemberAccessController from './CurrentMemberAccessController';
import CurrentMembershipQuery from './CurrentWorkspaceMembershipQuery';

function AccessController() {
  return (
    <CurrentMembershipQuery>
      {(currentUser) => {
        const currentMembership = currentUser.workspaceMemberships[0];
        if (currentMembership.role === 'OWNER') return null;
        return (
          <CurrentMemberAccessController currentMember={{
            id: currentUser.id,
            currentMembership
          }}/>
        );
      }}
    </CurrentMembershipQuery>
  );
}

export default AccessController;
