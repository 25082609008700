import React from 'react';
import Button from '../ui/button/Button';
import PersonAddIcon from '../ui/icons/PersonAdd';
import PageActions from '../ui/page/Actions';
import DialogContext from '../ui/dialog/DialogContext';
import InvitePeopleToCurrentWorkspace
from './dialogs/InvitePeopleToCurrentWorkspace';

function MembersActions() {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: <InvitePeopleToCurrentWorkspace />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <PageActions>
      <Button
        startIcon={ <PersonAddIcon /> }
        size='small'
        color='primaryDeemphasized'
        onClick={handleClick}
      >
        Invite people
      </Button>
    </PageActions>
  );
}

export default React.memo(MembersActions);
