import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    minHeight: 0,
    marginTop: theme.spacing(1.5)
  },
  indicator: {
    display: 'none'
  }
}))(Tabs);
