import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../button/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 1.5),
    borderRadius: 8,
    textAlign: 'left',
  },
  selected: {
    backgroundColor: '#E7F3FF',
    '& $buttonOverlay': {
      opacity: '0 !important'
    }
  },
  buttonOverlay: {}
}));

function ListItem({ button, selected, ...rest }) {
  const classes = useStyles();

  const finalClassName = clsx(classes.root, {
    [classes.selected]: selected
  });

  return button ? (
    <Button
      component='div'
      variant='text'
      className={finalClassName}
      overlayClassName={classes.buttonOverlay}
      {...rest}
    />
  ) : (
    <div className={finalClassName} {...rest} />
  );
}

ListItem.propTypes = {
  // Whether or not to use button for this item.
  button: PropTypes.bool,

  // Indicate whether the item is selected or not.
  selected: PropTypes.bool
};

ListItem.defaultProps = {
  button: true,
  selected: false
};

export default ListItem;
