import React from 'react';
import PropTypes from 'prop-types';
import SectionSearchNotFound from '../../ui/page/SectionSearchNotFound';

function NoMatchesFound({ criteria }) {
  const { matching } = criteria;
  const message = matching ?
                  `No results for: ${matching}.` :
                  'No members found.';
  return <SectionSearchNotFound message={message} />;
}

NoMatchesFound.propTypes = {
  criteria: PropTypes.shape({
    matching: PropTypes.string
  }).isRequired
};

export default NoMatchesFound;
