import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: '#EBEDF0'
  },
  note: {
    ...theme.customMixins.wordWrap
  }
}));

export default function MakeFolderFootnote() {
  const classes = useStyles();
  return (
    <div>
      <Divider className={classes.divider} />
      <Typography
        variant='caption'
        color='textSecondary'
        className={classes.note}
      >
        When you create a folder, Taskbook will make you the owner
        of that folder. Only you and workspace admins can later decide
        to rename or delete it, but all active project members will
        have access to its content, be able to upload files to or create
        subfolders within it.
      </Typography>
    </div>
  );
}
