import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function DeleteProjectConfirmCheckbox(props) {
  return (
    <DialogContentText style={{marginBottom: 0}}>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            size='small'
            name='yesChecked'
            {...props}
          />
        }
        label='Yes, permanently delete this project.'
      />
    </DialogContentText>
  );
}
