import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const MEMBERSHIP_CHANGED_SUBSCRIPTION = gql`
  subscription OnMembershipChanged($membershipId: ID!) {
    membershipChanged(membershipId: $membershipId) {
      id
      role
    }
  }
`;

export default function useMembershipChangedSubscription(options) {
  const { membershipId, ...rest } = options;
  return useSubscription(MEMBERSHIP_CHANGED_SUBSCRIPTION, {
    variables: { membershipId },
    ...rest
  });
}
