import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import PlusIcon from '../../icons/16x16/PlusCircleFill';
import DialogContext from '../../ui/dialog/DialogContext';
import CreateProjectDialog from '../../project/new/CreateProjectDialog';

function AddNewProject({ teamId }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: <CreateProjectDialog teamId={teamId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <Button
      startIcon={ <PlusIcon /> }
      size='small'
      color='primaryDeemphasized'
      onClick={handleClick}
    >
      Add new project
    </Button>
  );
}

AddNewProject.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default AddNewProject;
