import React from 'react';
import List from '../../ui/dialog/List';
import ListItem from '../../ui/dialog/ListItem';
import ListItemText from '../../ui/dialog/ListItemText';
import InfoDialogContentTitle from './InfoDialogContentTitle';

const helperTexts = [
  {
    primary: 'The owner of the project',
    secondary: `When you create a project, Taskbook will make you the owner
    of that project.`
  },
  {
    primary: 'Project members',
    secondary: `Only team members who are also project members have
    access to the project.`
  },
  {
    primary: 'Workspace admins',
    secondary: `The owner and admins of the workspace have access to any
project in any given team.`
  }
];

export default function InfoDialogContent() {
  return (
    <div>
      <InfoDialogContentTitle />
      <List>
        {helperTexts.map(({ primary, secondary }) => (
          <ListItem key={primary} button={false}>
            <ListItemText primary={primary} secondary={secondary} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
