import React from 'react';
import PropTypes from 'prop-types';
import UserPicker from '../../ui/todo/sheet/UserPicker';
import useActiveProjectMembersLazyQuery
from '../../project/hooks/useActiveProjectMembersLazyQuery';

function AssigneeCell({ projectId, value, onChange }) {
  const [loadActiveProjectMembers, { called, loading, data }] =
    useActiveProjectMembersLazyQuery({
      projectId,
      fetchPolicy: 'network-only'
    });

  const options = data ? data.project.members.map(({ user }) => user ) : [];

  return (
    <UserPicker
      placeholderText='Assign this task'
      fetchUsers={loadActiveProjectMembers}
      loading={called && loading}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

AssigneeCell.propTypes = {
  projectId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }),
  onChange: PropTypes.func
};

export default AssigneeCell;
