import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Section from '../../ui/page/Section';
import SectionHeader from '../../ui/page/SectionHeader';
import SectionTitle from '../../ui/page/SectionTitle';
import SearchMemberInProjectQuery from './SearchMemberInProjectQuery';
import MemberSearchCriteria from './MemberSearchCriteria';
import MemberGridSkeleton from './MemberGridSkeleton';
import MemberGrid from './MemberGrid';
import MemberAddedSubscriber from './MemberAddedSubscriber';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto'
  }
}));

function Members({ projectId }) {
  const classes = useStyles();
  const [criteria, setCriteria] = React.useState({
    matching: undefined
  });

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  // helper function to determine whether new member should be added
  // to the cache.
  const matches = ({ user }) => {
    if (!criteria.matching) { return true; }
    const { username, email } = user;
    const pattern = new RegExp(criteria.matching, 'i');
    return pattern.test(username) || pattern.test(email);
  };

  return (
    <div className={classes.root}>
      <Section>
        <SectionHeader>
          <SectionTitle>Members</SectionTitle>
          <MemberSearchCriteria
            criteria={criteria}
            onChange={handleCriteriaChange}
          />
        </SectionHeader>
        <SearchMemberInProjectQuery
          projectId={projectId}
          criteria={criteria}
          onLoading={() => <MemberGridSkeleton /> }
        >
          {(members, otherQueryResults) => (
            <>
              <MemberGrid
                members={members}
                {...otherQueryResults}
              />

              <MemberAddedSubscriber
                projectId={projectId}
                subscribeToMemberAdded={otherQueryResults.subscribeToMore}
                matcher={matches}
              />
            </>
          )}
        </SearchMemberInProjectQuery>
      </Section>
    </div>
  );
}

Members.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default Members;
