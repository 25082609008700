import { createMuiTheme } from '@material-ui/core/styles';

//
// COLORS
//

const PRIMARY_MAIN_COLOR = '#1877F2';
const PRIMARY_CONTRAST_TEXT_COLOR = '#fff';
const PRIMARY_SHADOW_COLOR = '#E7F3FF';

const SECONDARY_MAIN_COLOR = '#f02849';
const SECONDARY_CONTRAST_TEXT_COLOR = '#fff';

const TEXT_PRIMARY_COLOR = '#050505';

const BUTTON_SECONDARY_MAIN_COLOR = '#E4E6EB';
const BUTTON_SECONDARY_CONTRAST_TEXT_COLOR = TEXT_PRIMARY_COLOR;
const BUTTON_OVERLAY_HOVER_COLOR = 'rgba(0, 0, 0, 0.05)';
const BUTTON_OVERLAY_PRESS_COLOR = 'rgba(0, 0, 0, 0.10)';

//
// SHADOWS
//

const CARD_SHADOW = `
0 2px 4px rgba(0, 0, 0, .1),
0 8px 16px rgba(0, 0, 0, .1)
`;

const MENU_SHADOW = `
0 12px 28px 0 rgba(0, 0, 0, 0.2),
0 2px 4px 0 rgba(0, 0, 0,0.1),
inset 0 0 0 1px rgba(255, 255, 255, 0.5)
`;

//
// CUSTOM THEME
//

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN_COLOR,
      contrastText: PRIMARY_CONTRAST_TEXT_COLOR,
      shadow: PRIMARY_SHADOW_COLOR
    },
    secondary: {
      main: SECONDARY_MAIN_COLOR,
      contrastText: SECONDARY_CONTRAST_TEXT_COLOR
    },
    background: {
      paper: '#fff',
      default: '#f0f2f5'
    },
    text: {
      primary: TEXT_PRIMARY_COLOR,
      secondary: '#65676B',
      disabled: '#BCC0C4',
      hint: '#BCC0C4',
      sectionHeader: '#4B4C4F',
      placeholder: '#8A8D91',
    },
    button: {
      primary: {
        main: PRIMARY_MAIN_COLOR,
        contrastText: PRIMARY_CONTRAST_TEXT_COLOR,
      },
      primaryDeemphasized: {
        main: PRIMARY_SHADOW_COLOR,
        contrastText: 'hsl(214, 89%, 52%)'
      },
      secondary: {
        main: BUTTON_SECONDARY_MAIN_COLOR,
        contrastText: BUTTON_SECONDARY_CONTRAST_TEXT_COLOR
      },
      danger: {
        main: SECONDARY_MAIN_COLOR,
        contrastText: SECONDARY_CONTRAST_TEXT_COLOR
      },
      success: {
        main: '#008C00',
        contrastText: '#fff'
      },
      overlay: {
        hover: BUTTON_OVERLAY_HOVER_COLOR,
        press: BUTTON_OVERLAY_PRESS_COLOR,
        primaryDeemphasizedPress: 'rgba(25, 110, 255, 0.15)'
      },
    },
    divider: '#DADDE1',
    negative: {
      text: 'hsl(350, 87%, 55%)',
      background: 'hsl(350, 87%, 55%, 20%)'
    },
    positive: {
      text: '#31A24C',
      background: '#DEEFE1'
    },
    error: {
      main: SECONDARY_MAIN_COLOR
    }
  },
  shape: {
    borderRadius: 6
  },
  customShadow: {
    card: CARD_SHADOW,
    menu: MENU_SHADOW
  },
  customMixins: {
    // https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking
    // -out-of-container
    wordWrap: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      hyphens: 'auto'
    }
  },
  typography: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  timeline: {
    rulerWidth: 4,
    dividerWidth: 64,
    breakpoint: 'sm'
  },

  // OVERRIDE GLOBAL PROPS.
  props: {
    MuiButtonBase: {
      // No more ripple, on the whole application
      disableRipple: true,
      disableTouchRipple: true
    },
    MuiButton: {
      // No more elevation, on the whole application
      disableElevation: true
    }
  },

  // OVERRIDE GLOBAL STYLES.
  overrides: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: '#90949c',
          opacity: 1.0,
          fontSize: '1.0625rem'
        },
        '&:focus': {
          '&::placeholder': {
            color: '#bec3c9'
          }
        }
      },
      inputMarginDense: {
        '&::placeholder': {
          fontSize: '0.9375rem'
        }
      }
    },

    MuiButtonBase: {
      root: {
        // Global focus visible for button.
        '&.Mui-focusVisible': {
          outline: `2px solid ${PRIMARY_MAIN_COLOR}`
        }
      }
    },

    MuiButton: {
      root: {
        textTransform: 'none',
        transition: 'none'
      }
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(244, 244, 244, 0.8)'
      }
    },

    MuiList: {
      padding: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },

    MuiListItem: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: 'rgba(45, 136, 255, 0.1)'
        },
        '&$focusVisible': {
          backgroundColor: 'transparent'
        }
      },
      gutters: {
        paddingLeft: 8,
        paddingRight: 8
      },
      button: {
        transition: 'none',
        borderRadius: 8,
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },

    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0
      },
      multiline: {
        marginTop: 0,
        marginBottom: 0
      }
    },

    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: 12
      },
      alignItemsFlexStart: {
        marginTop: 4
      }
    },

    MuiListItemAvatar: {
      root: {
        minWidth: 'auto',
        marginRight: 12
      },
      alignItemsFlexStart: {
        marginTop: 4
      }
    },

    MuiMenuItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 'auto',
        whiteSpace: 'normal'
      }
    },

    MuiLink: {
      root: {
        color: '#216FDB'
      }
    }
  }
});


export default theme;
