import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RouterLink from '../../ui/link/RouterLink';
import ListItem from '../../ui/list/ListItem';
import OpenInNewIcon from '../../ui/icons/OpenInNew';
import CurrentWorkspaceContext from '../../workspace/CurrentWorkspaceContext';

const useStyles = makeStyles((theme) => ({
  link: {
    padding: theme.spacing(1, 1.5)
  }
}));

const UserWorkspaceMembershipListItem = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { workspaceMembership } = props;
  const { workspace } = workspaceMembership;
  const { id, name } = workspace;

  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const isCurrentWorkspace = (id === currentWorkspaceId);

  return (
    <li>
      <ListItem
        ref={ref}
        component={ RouterLink }
        button
        className={classes.link}
        primary={name}
        to={`/workspaces/${id}`}
        target='_blank'
        endIcon={!isCurrentWorkspace && <OpenInNewIcon color='secondary' /> }
        disabled={isCurrentWorkspace}
      />
    </li>
  );
});

UserWorkspaceMembershipListItem.propTypes = {
  workspaceMembership: PropTypes.object.isRequired
};

export default UserWorkspaceMembershipListItem;
