import React from 'react';
import IconButton from './SectionHeaderIconButton';
import AddIcon from '../../../icons/18x18/Plus';

export default function SectionHeaderAddRowButton(props) {
  return (
    <IconButton
      tooltip='Add a task to this list'
      icon={<AddIcon />}
      {...props}
    />
  );
}
