import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { FILTER_ACCENT } from '../ui/icons/Base';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(14)
    }
  },
  link: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    width: '100%',
    height:  theme.spacing(7),
    position: 'relative',
    '&.selected': {
      color: theme.palette.primary.main,
      '& $icon': {
        filter: FILTER_ACCENT
      }
    },
    '&.selected ~ $activeUnderline': {
      backgroundColor: theme.palette.primary.main
    },
    '&:hover:not(.selected)': {
      '& $overlay': {
        opacity: 1.0
      }
    },
    '&:active:not(.selected)': {
      '& $overlay': {
        opacity: 1.0,
        backgroundColor: theme.palette.button.overlay.press
      }
    },
  },
  activeUnderline: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 3,
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderRadius: 1
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    pointerEvents: 'none',
    backgroundColor: theme.palette.button.overlay.hover,
    opacity: 0.0,
    transition: 'opacity 0.1s cubic-bezier(0, 0, 1, 1)'
  },
  icon: {}
}));

function DesktopNavItem(props) {
  const classes = useStyles();
  const { to, icon, primary, ...otherProps } = props;

  return (
    <li className={classes.root}>
      <Link
        underline='none'
        activeClassName='selected'
        variant='subtitle2'
        className={classes.link}
        component={ NavLink }
        to={to}
        {...otherProps}
      >
        {React.cloneElement(icon, {
          className: classes.icon,
          color: 'secondary'
        })}
        {primary}
        <span className={classes.overlay}></span>
      </Link>
      <div className={classes.activeUnderline}></div>
    </li>
  );
}

DesktopNavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  primary: PropTypes.string.isRequired
};

export default DesktopNavItem;
