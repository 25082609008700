import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '../ui/button/IconButton';
import CloseIcon from '../icons/16x16/Close';
import ErrorIcon from '../icons/20x20/Error';
import SuccessIcon from '../icons/20x20/CheckCircle';

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
    backgroundColor: '#1C1C1C',
    color: theme.palette.common.white,
    borderRadius: 6,
    width: '100%'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
  },
  message: {
    fontSize: '0.9375rem'
  },
  actionButtons: {
    marginLeft: 'auto !important',
  },
  closeButton: {
    filter: 'invert(100%)'
  }
}));

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  // Hacky way to pass down the `variant` from `enqueueSnackbar`
  // to this component!
  const { id: snackId, options } = props;
  const { message, variant } = options;

  const handleDismiss = () => {
    closeSnackbar(snackId);
  };

  // Icons.

  let icon = null;

  if (variant === 'success') {
    icon = <SuccessIcon color='positive' />;
  } else if (variant === 'error') {
    icon = <ErrorIcon color='negative' />;
  } else {
    // TODO: handle other variant.
  }

  return (
    <Card ref={ref} className={classes.card}>
      <CardActions classes={{ root: classes.actionRoot }}>
        {icon}

        <Typography variant='body2' className={classes.message}>
          {message}
        </Typography>

        <div className={classes.actionButtons}>
          <IconButton
            className={classes.closeButton}
            onClick={handleDismiss}
            icon={ <CloseIcon /> }
            size='small'
            color='secondary'
            background={false}
          >
          </IconButton>
        </div>
      </CardActions>
    </Card>
  );
});

export default SnackMessage;
