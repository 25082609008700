import { v4 as uuidv4 } from 'uuid';

// The day before at 9:00 (all-day event)
export const dayEventReminder = {
  _id: uuidv4(),
  method: 'POPUP',
  offsetUnit: 'DAYS',
  offsetQuantity: 1,
  offsetMinutes: 9 * 60
};

// 30 minutes before (time-specific event)
export const timeEventReminder = {
  _id: uuidv4(),
  method: 'POPUP',
  offsetUnit: 'MINUTES',
  offsetQuantity: 30,
  offsetMinutes: 0
}
