import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  monthGridRow: {
    position: 'relative',
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.divider}`,
    flex: '1 1 0%',
    display: 'flex'
  }
}));

export default function MonthGridRow(props) {
  const classes = useStyles();
  return <div role='row' className={classes.monthGridRow} {...props} />;
}
