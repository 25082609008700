import React from 'react';
import Autocomplete, { createFilterOptions }
from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../../input/TextField';
import Chip from '../../chip/Chip';
import UserAvatar from '../../../account/UserAvatar';
import Loading from './AutocompleteFollowerLoading';
import Option from './AutocompleteFollowerOption';

function renderOption(user) {
  return <Option user={user} />;
}

function renderTags(value, getTagProps) {
  return value.map((user, index) => (
    <Chip
      color='primary'
      avatar={<UserAvatar user={user} /> }
      label={user.username}
      {...getTagProps({ index })}
    />
  ));
}

function getOptionSelected(option, value) {
  return option.id === value.id;
}

function getOptionLabel(user) {
  return user.username
}

const filterOptions = createFilterOptions({
  stringify: (user) => `${user.username} ${user.email}`,
});

export default withStyles((theme) => ({
  paper: {
    boxShadow: theme.customShadow.card
  },
  listbox: {
    padding: 0
  },
  option: {
    padding: theme.spacing(1),
    [`&[aria-selected="true"]`]: {
      backgroundColor: '#E7F3FF'
    }
  }
}))(({ InputProps, ...rest }) => {
  return (
    <Autocomplete
      multiple
      disableClearable
      forcePopupIcon={false}
      noOptionsText='No matches found'
      loadingText={ <Loading /> }
      renderInput={(params) => <TextField {...params} {...InputProps} />}
      renderOption={renderOption}
      renderTags={renderTags}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      {...rest}
    />
  );
});
