import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const RESIZE_TODO_SHEET_COLUMN_MUTATION = gql`
  mutation ResizeTodoSheetColumn(
    $projectId: ID!,
    $col: String!,
    $width: Int!
  ) {
    updateTodoSheetColumns(
      projectId: $projectId,
      changes: [{col: $col, changes: {width: $width}}]
    ) {
      name
      width
      show
    }
  }
`;

export default function useResizeColumnMutation(options) {
  return useMutation(RESIZE_TODO_SHEET_COLUMN_MUTATION, options);
}
