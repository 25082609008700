import React from 'react';
import PersonIcon from '../../../icons/16x16/Person';
import AssigneePicker from '../../../calendar/form-dialog/AssigneePicker';
import FormField from './FormField';

export default function AssigneeField(props) {
  return (
    <FormField icon={<PersonIcon color='secondary' />} >
      <AssigneePicker {...props} />
    </FormField>
  );
}
