import React from 'react';
import PropTypes from 'prop-types';
import CurrentUserQuery from '../../../account/CurrentUserProfileQuery';
import CreateCommentForm from './CreateCommentForm';

function CreateComment({ eventId, projectId }) {
  return (
    <CurrentUserQuery>
      {(currentUser) => (
        <CreateCommentForm
          user={currentUser}
          eventId={eventId}
          projectId={projectId}
        />
      )}
    </CurrentUserQuery>
  );
}

CreateComment.propTypes = {
  eventId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default CreateComment;
