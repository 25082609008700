import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MembershipGridItem from './MembershipGridItem';

function MembershipGrid({ memberships }) {
  return (
    <Grid container spacing={2}>
      {memberships.map((membership) => (
        <MembershipGridItem key={membership.id} membership={membership} />
      ))}
    </Grid>
  );
}

MembershipGrid.propTypes = {
  memberships: PropTypes.array.isRequired
};

export default MembershipGrid;
