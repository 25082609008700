import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 3, 0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.appBar - 1
  }
}));

export default function TabsContainer(props) {
  const classes = useStyles();
  return <div className={classes.tabsContainer} {...props} />;
}
