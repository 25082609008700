import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import formatTimeOfDay from '../../new-ui/datetime-picker/formatTimeOfDay';
import TimePicker from '../../new-ui/TimePicker';
import StyledReminderItem from './StyledReminderItem';
import ReminderMethodSelect from './ReminderMethodSelect';
import ReminderQuantityInput from './ReminderQuantityInput';
import ReminderUnitSelect from './ReminderUnitSelect';
import getReminderQuantityRange from './getReminderQuantityRange';
import {
  EVENT_REMINDER_METHODS,
  DAY_EVENT_REMINDER_UNIT_OPTIONS,
  DAY_EVENT_REMINDER_UNITS
} from './constants';

function updateRemindersFn(key, propertyName, propertyValue) {
  return (prevData) => ({
    ...prevData,
    dayReminders: prevData.dayReminders.map((reminder) => {
      if (reminder._id !== key) {
        return reminder;
      }

      return {
        ...reminder,
        [propertyName]: propertyValue
      };
    })
  });
}

function minutesToTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return formatTimeOfDay({ hours, minutes });
}

function timeToMinutes({ hours, minutes }) {
  return hours * 60 + minutes;
}

function DayReminderItem({ value, onChange }) {
  const { _id, method, offsetUnit, offsetQuantity, offsetMinutes } = value;
  const { min, max } = getReminderQuantityRange(offsetUnit);

  const handleMethodChange = React.useCallback((event) => {
    const newMethod = event.target.value;
    onChange(updateRemindersFn(_id, 'method', newMethod));
  }, [_id, onChange]);

  const handleUnitChange = React.useCallback((event) => {
    const newUnit = event.target.value;
    onChange(updateRemindersFn(_id, 'offsetUnit', newUnit));
  }, [_id, onChange]);

  const handleQuantityChange = React.useCallback((event) => {
    const newQuantity = event.target.value;
    onChange(updateRemindersFn(_id, 'offsetQuantity', newQuantity));
  }, [_id, onChange]);

  const handleTimeChange = React.useCallback((newTime) => {
    const newMinutes = timeToMinutes(newTime);
    onChange(updateRemindersFn(_id, 'offsetMinutes', newMinutes));
  }, [_id, onChange]);

  const handleDelete = React.useCallback((event) => {
    onChange((prevData) => ({
      ...prevData,
      dayReminders: prevData.dayReminders.filter((r) => r._id !== _id )
    }));
  }, [_id, onChange]);

  const isQuantityInvalid = isNaN(offsetQuantity) ||
                            offsetQuantity < min ||
                            offsetQuantity > max;

  return (
    <StyledReminderItem onDelete={handleDelete}>
      <ReminderMethodSelect
        value={method}
        onChange={handleMethodChange}
      />
      <ReminderQuantityInput
        min={min}
        max={max}
        error={isQuantityInvalid}
        value={offsetQuantity}
        onChange={handleQuantityChange}
      />
      <ReminderUnitSelect
        options={DAY_EVENT_REMINDER_UNIT_OPTIONS}
        value={offsetUnit}
        onChange={handleUnitChange}
      />
      <Typography variant='body2' color='textSecondary'>
        before at
      </Typography>

      <TimePicker
        clearable={false}
        background='always'
        placeholder='00:00'
        value={minutesToTime(offsetMinutes)}
        onChange={handleTimeChange}
      />
    </StyledReminderItem>
  );
}

DayReminderItem.propTypes = {
  value: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    method: PropTypes.oneOf(EVENT_REMINDER_METHODS).isRequired,
    offsetUnit: PropTypes.oneOf(DAY_EVENT_REMINDER_UNITS).isRequired,
    offsetQuantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    offsetMinutes: PropTypes.number.isRequired
  }).isRequired,

  onChange: PropTypes.func.isRequired
};

export default DayReminderItem;
