import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    fontWeight: 600,
    fontSize: '0.9375rem'
  }
}))((props) => <Typography variant='body1' noWrap {...props} /> );
