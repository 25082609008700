import React from 'react';
import PropTypes from 'prop-types';
import AvatarGroupAction from '../ui/card/CardAvatarGroupAction';
import DialogContext from '../ui/dialog/DialogContext';
import AddMembers from './dialogs/AddMembers';

function ProjectCardAvatarGroupAction({ projectId }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = (event) => {
    event.stopPropagation();

    openDialog({
      dialogContent: <AddMembers projectId={projectId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return <AvatarGroupAction onClick={handleClick} />;
}

ProjectCardAvatarGroupAction.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default React.memo(ProjectCardAvatarGroupAction);
