import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function Bold({ children }) {
  return (
    <Box component='span' fontWeight={600}>
      {children}
    </Box>
  );
}

Bold.propTypes = {
  children: PropTypes.node.isRequired
};

export default Bold;
