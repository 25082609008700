import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function ActivityTextDetails({ text }) {
  return text ? (
    <Typography variant='body2' color='textSecondary'>
      {text}
    </Typography>
  ) : null;
}

ActivityTextDetails.propTypes = {
  text: PropTypes.string
};

export default ActivityTextDetails;
