import React from 'react';
import Menu from '../../ui/menu/Menu';

export default function StyledMenu(props) {
  // TODO: Is this shit ok?
  // Prevent the click event from propagating to the card parent.
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: 320
        }
      }}
      onClick={handleClick}
      {...props}
    />
  );
}
