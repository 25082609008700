import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { generateTodoCommentHtmlId } from '../todo/details/comment/helper';
import useProjectURL from '../project/hooks/useProjectURL';
import GenericActivityItem from './ui/GenericActivityItem';
import ActivityTextDetails from './ui/ActivityTextDetails';

function CommentTodoItem({ activity }) {
  const { type, actor, details, todo, comment, insertedAt } = activity;
  const [todoName, commentText] = details;

  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const todoUrl = `${projectUrl}/tasks/${todo.id}`;
  const commentId = generateTodoCommentHtmlId(todo.id, comment.id);
  const commentUrl = `${todoUrl}#${commentId}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='commented on'
      linkUrl={commentUrl}
      linkText={todoName}
      insertedAt={insertedAt}
    >
      <ActivityTextDetails text={commentText} />
    </GenericActivityItem>
  );
}

CommentTodoItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['COMMENT_TODO']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    todo: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    comment: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default CommentTodoItem;
