import React from 'react';
import PropTypes from 'prop-types';
import UnfollowFileButton from './ui/UnfollowFileButton';
import useUnfollowFileMutation from './useUnfollowFileMutation';

function RemoveCurrentUser({ fileId }) {
  const [removeCurrentUser] = useUnfollowFileMutation({
    variables: { fileId }
  });

  return <UnfollowFileButton onClick={removeCurrentUser} />;
}

RemoveCurrentUser.propTypes = {
  fileId: PropTypes.string.isRequired
};

export default RemoveCurrentUser;
