import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TODO_SHEET_THEME } from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    textAlign: 'left',
    height: TODO_SHEET_THEME.cellHeight
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 1
  },
  disablePointerEvents: {
    pointerEvents: 'none'
  }
}));

function Cell(props) {
  const {
    isHeaderCell,
    sticky,
    disablePointerEvents,
    className,
    ...rest
  } = props;

  const classes = useStyles();

  const finalClassName = clsx(
    classes.root,
    sticky && classes.sticky,
    disablePointerEvents && classes.disablePointerEvents,
    className
  );

  return isHeaderCell ? (
    <th className={finalClassName} {...rest} />
  ) : (
    <td className={finalClassName} {...rest} />
  );
}

Cell.propTypes = {
  isHeaderCell: PropTypes.bool,
  sticky: PropTypes.bool,
  disablePointerEvents: PropTypes.bool
};

Cell.defaultProps = {
  isHeaderCell: false,
  sticky: false,
  disablePointerEvents: false
};

export default Cell;
