import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { COMMENT_FILES } from './fragments';

const SOME_MESSAGE_COMMENT_UPDATED_SUBSCRIPTION = gql`
  subscription OnSomeMessageCommentUpdated($messageId: ID!) {
    messageCommentUpdated(messageId: $messageId) {
      id
      text
      updatedAt
      ...CommentFiles
    }
  }
  ${COMMENT_FILES}
`;

function CommentUpdatedSubscriber({ messageId }) {
  useSubscription(SOME_MESSAGE_COMMENT_UPDATED_SUBSCRIPTION, {
    variables: { messageId }
  });
  return null;
}

CommentUpdatedSubscriber.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default CommentUpdatedSubscriber;
