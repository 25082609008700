import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SecondaryIcon from './SecondaryIcon';
import { VARIANTS } from './secondaryIconVariant';
import renderSecondarySvgIcon from './renderSecondarySvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'flex-start',
    flex: '0 0 auto',
    marginRight: theme.spacing(1.5),
    position: 'relative'
  },

  // TODO: this is just a workaround since we don't yet have a logo
  // for our app.
  taskbookAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

// TODO: Fix this once we have a logo for our app!
function ListItemAvatar({ primary, secondary }) {
  const classes = useStyles();
  return primary ? (
    <div className={classes.root}>
      {primary}
      <SecondaryIcon variant={secondary} />
    </div>
  ) : (
    <div className={clsx(classes.root, classes.taskbookAvatar)}>
      {renderSecondarySvgIcon(secondary)}
    </div>
  );
}

ListItemAvatar.propTypes = {
  // Typically UserAvatar (or AppLogo)
  primary: PropTypes.node,

  secondary: PropTypes.oneOf(VARIANTS)
};

export default ListItemAvatar;
