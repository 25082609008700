import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../snackbar/useCustomSnackbar';

const CREATE_PROJECT_MESSAGE_MUTATION = gql`
  mutation CreateProjectMessage(
    $projectId: ID!,
    $subject: String!,
    $plainText: String!,
    $jsonText: String!
  ) {
    createProjectMessage(
      projectId: $projectId,
      subject: $subject,
      plainText: $plainText,
      jsonText: $jsonText
    ) {
      id
    }
  }
`;

export default function useCreateMessageMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(CREATE_PROJECT_MESSAGE_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
