import React from 'react';
import PropTypes from 'prop-types';
import AvatarGroupContainer from './ProjectHeaderAvatarGroupContainer';
import AvatarGroup from './ProjectHeaderAvatarGroup';
import AvatarGroupSkeleton from './ProjectHeaderAvatarGroupSkeleton';
import AddPeopleButton from './ProjectHeaderAddPeopleButton';
import ProjectMembersQuery from './ProjectMembersQuery';
import MemberAddedSubscriber from './members/MemberAddedSubscriber';
import MemberRemovedSubscriber from './members/MemberRemovedSubscriber';

function ProjectHeaderMembers({ projectId }) {
  return (
    <ProjectMembersQuery
      projectId={projectId}
      onLoading={() => <AvatarGroupSkeleton /> }
    >
      {(members, { subscribeToMore }) => (
        <AvatarGroupContainer>
          <AvatarGroup members={members} />
          <AddPeopleButton projectId={projectId} />

          {/* TODO: Refactor these into a separate component.
              Members added/removed subscription. */}

          <MemberAddedSubscriber
            projectId={projectId}
            subscribeToMemberAdded={subscribeToMore}
          />

          <MemberRemovedSubscriber
            projectId={projectId}
            subscribeToMemberRemoved={subscribeToMore}
          />
        </AvatarGroupContainer>
      )}
    </ProjectMembersQuery>
  );
}

ProjectHeaderMembers.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectHeaderMembers;
