import React from 'react';
import ToolbarIconButton from './ToolbarIconButton';
import DeleteIcon from '../../../../icons/18x18/Trash';

export default function ToolbarDeleteButton(props) {
  return (
    <ToolbarIconButton
      icon={<DeleteIcon />}
      {...props}
    />
  );
}
