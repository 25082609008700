import React from 'react';
import Radio from '@material-ui/core/Radio';
import { makeStyles, darken } from '@material-ui/core/styles';
import CheckIcon from '../icons/CheckSvg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    }
  },
  icon: ({ color }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: 4,
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.1s cubic-bezier(0, 0, 1, 1)',
    'input:hover ~ &': {
      backgroundColor: darken(color, 0.15),
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    }
  }),

  checked: ({ color }) => ({
    color: theme.palette.getContrastText(color)
  })
}));

function ColorRadio({ value, ...otherProps }) {
  const classes = useStyles({ color: value });

  const icon = <span className={classes.icon} />;

  const checkedIcon = (
    <span className={classes.icon}>
      <CheckIcon className={classes.checked} />
    </span>
  );

  return (
    <Radio
      disableRipple
      color='default'
      icon={icon}
      checkedIcon={checkedIcon}
      className={classes.root}
      value={value}
      {...otherProps}
    />
  );
}

export default React.memo(ColorRadio);
