import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  rightMargin: {
    marginRight: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  }
}));

export default function OwnerPickerSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton
        variant='circle'
        width={40}
        height={40}
        className={classes.rightMargin}
      />
      <div className={classes.grow}>
        <Typography variant='body1'>
          <Skeleton width='100%' height={18} />
        </Typography>

        <Typography variant='caption'>
          <Skeleton width='40%' height={12} />
        </Typography>
      </div>
    </div>
  )
}
