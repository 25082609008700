import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TabOverlay from './TabOverlay';
import TabActiveUnderline from './TabActiveUnderline';
import useCommonTabStyles from './useCommonTabStyles';

const useStyles = makeStyles((theme) => ({
  link: {
    '&.selected': {
      color: theme.palette.primary.main,
      '& $underline': {
        display: 'block'
      }
    },

    '&:hover:not(.selected)': {
      '& $overlay': {
        opacity: 1.0
      }
    },

    '&:active:not(.selected)': {
      '& $overlay': {
        opacity: 1.0,
        backgroundColor: theme.palette.button.overlay.press
      }
    },
  },
  underline: {},
  overlay: {}
}));

const TabLink = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const common = useCommonTabStyles();
  const { to, primary, invisible, index, ...rest } = props;

  return invisible ? (
    <div
      ref={ref}
      className={common.commonTabStyles}
      data-index={index}
      aria-hidden={true}
    >
      <span>{primary}</span>
    </div>
  ) : (
    <Link
      ref={ref}
      className={clsx(common.commonTabStyles, classes.link)}
      component={ NavLink }
      activeClassName='selected'
      underline='none'
      variant='body1'
      to={to}
      {...rest}
    >
      <span>{primary}</span>
      <TabOverlay className={classes.overlay} />
      <TabActiveUnderline className={classes.underline} />
    </Link>
  );
});

TabLink.propTypes = {
  to: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  invisible: PropTypes.bool,
  index: PropTypes.number
};

TabLink.defaultProps = {
  invisible: false
};

export default React.memo(TabLink);
