import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import {getDescriptionForMimeType, getIcon40ForMimeType} from '../mime/mime';
import ListItem from './ListItem';

function UploadListItem({ file, ...rest }) {
  const { name, size, type } = file;
  const description = getDescriptionForMimeType(type);
  const humanReadableSize = filesize(size, {round: 1, base: 10});
  const Avatar = getIcon40ForMimeType(type);

  return (
    <ListItem
      avatar={<Avatar />}
      primary={name}
      secondary={`${description} - ${humanReadableSize}`}
      {...rest}
    />
  );
}

UploadListItem.propTypes = {
  file: PropTypes.instanceOf(File).isRequired
};

export default UploadListItem;
