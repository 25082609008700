import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const PROJECT_MEMBER_REMOVED_SUBSCRIPTION = gql`
  subscription OnProjectMemberRemoved($projectId: ID!) {
    projectMemberRemoved(projectId: $projectId) {
      id
    }
  }
`;

export default function useMemberRemovedSubscription(options) {
  const { projectId, ...otherOptions } = options;
  const ret = useSubscription(PROJECT_MEMBER_REMOVED_SUBSCRIPTION, {
    variables: { projectId },
    ...otherOptions
  });
  if (ret.error) console.log('Error');
  return ret;
}

export { PROJECT_MEMBER_REMOVED_SUBSCRIPTION };
