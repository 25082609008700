import React from 'react';
import MuiSpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/core/styles';

const BACKGROUND_COLOR = '#282828';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: BACKGROUND_COLOR,
    fontSize: '0.8125rem',
    fontWeight: theme.typography.fontWeightMedium
  },
  arrow: {
    '&:before': {
      backgroundColor: BACKGROUND_COLOR
    }
  },
  fab: {
    backgroundColor: BACKGROUND_COLOR,
    '&:hover': {
      backgroundColor: BACKGROUND_COLOR
    }
  }
}));

export default function SpeedDialAction(props) {
  const classes = useStyles();
  return (
    <MuiSpeedDialAction
      arrow
      classes={{fab: classes.fab}}
      TooltipClasses={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
      {...props}
    />
  );
}
