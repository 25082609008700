import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import RolesCounter from './RolesCounter';
import PendingInvitesCounter from './PendingInvitesCounter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(4)
  },
  white: {
    backgroundColor: theme.palette.background.paper
  }
}));

function MembersCounter() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider flexItem orientation='vertical' className={classes.white} />
      <RolesCounter />
      <Divider flexItem orientation='vertical' />
      <PendingInvitesCounter />
      <Divider flexItem orientation='vertical' className={classes.white} />
    </div>
  );
}

export default MembersCounter;
