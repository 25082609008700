import React from 'react';
import Button from '../button/Button';

export default function DeleteConfirmDeleteButton(props) {
  return (
    <Button
      variant='contained'
      size='small'
      color='danger'
      {...props}
    >
      Yes, delete it
    </Button>
  );
}
