import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import NotificationIcon from '../../../../icons/16x16/Bell';
import ContentField from './ContentField';

function minutesToTime(totalMinutes) {
  const hours = ~~(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const h = (hours < 10) ? `0${hours}` : `${hours}`;
  const m = (minutes < 10) ? `0${minutes}` : `${minutes}`;

  return `${h}:${m}`;
}

function formatAllDayEventReminder(reminder) {
  const { method, offsetUnit, offsetQuantity, offsetMinutes } = reminder;
  const time = minutesToTime(offsetMinutes);

  function withTimeAndMethod(prefix) {
    const withTime = prefix + ` at ${time}`;

    if (method === 'EMAIL') {
      return withTime + ', as email';
    }

    return withTime;
  }

  if (offsetUnit === 'DAYS' && offsetQuantity === 0) {
    return withTimeAndMethod('On the same day');
  }

  if (offsetUnit === 'DAYS' && offsetQuantity === 1) {
    return withTimeAndMethod('The day before');
  }

  if (offsetUnit === 'DAYS' && offsetQuantity > 1) {
    return withTimeAndMethod(`${offsetQuantity} days before`);
  }

  if (offsetUnit === 'WEEKS' && offsetQuantity === 1) {
    return withTimeAndMethod('1 week before');
  }

  if (offsetUnit === 'WEEKS' && offsetQuantity > 1) {
    return withTimeAndMethod(`${offsetQuantity} weeks before`);
  }

  throw new Error('Invalid reminder!');
}

function formatTimeEventReminder(reminder) {
  const { method, offsetUnit, offsetQuantity } = reminder;

  if (offsetQuantity === 0) {
    return 'When event starts';
  }

  let unit = offsetUnit.toLowerCase();

  if (offsetQuantity === 1) {
    unit = unit.slice(0, -1);
  }

  let msg = `${offsetQuantity} ${unit} before`;

  if (method === 'EMAIL') {
    return `${msg}, as email`;
  }

  return msg;
}

function formatReminder(reminder, isAllDayEvent) {
  return isAllDayEvent ?
         formatAllDayEventReminder(reminder) :
         formatTimeEventReminder(reminder);
}

function RemindersField({ isAllDayEvent, reminders }) {
  if (reminders.length === 0) {
    return null;
  }

  return (
    <ContentField icon={<NotificationIcon />}>
      {reminders.map((reminder) => (
        <Typography
          key={reminder.id}
          variant='body2'
          style={{lineHeight: '28px'}}
        >
          {formatReminder(reminder, isAllDayEvent)}
        </Typography>
      ))}
    </ContentField>
  );
}

// TODO: take me out of here please!
const OFFSET_METHODS = ['POPUP', 'EMAIL'];
const OFFSET_UNITS = ['MINUTES', 'HOURS', 'DAYS', 'WEEKS'];

RemindersField.propTypes = {
  isAllDayEvent: PropTypes.bool.isRequired,
  reminders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    method: PropTypes.oneOf(OFFSET_METHODS).isRequired,
    offsetUnit: PropTypes.oneOf(OFFSET_UNITS).isRequired,
    offsetQuantity: PropTypes.number.isRequired,
    offsetMinutes: PropTypes.number.isRequired
  })).isRequired
};

export default RemindersField;
