import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { timelineIntervalSize } from './constants';

const useStyles = makeStyles((theme) => ({
  timelineItem: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
    userSelect: 'none',
    cursor: 'default'
  },
  timelineItemLabel: {
    marginRight: theme.spacing(1),

    // This property depends on the length of the label string.
    width: theme.spacing(4.5),

    textAlign: 'right',
    lineHeight: `${timelineIntervalSize.PIXELS}px`
  },
  timelineItemDivider: {
    flexGrow: 1
  },
  hidden: {
    visibility: 'hidden'
  }
}));

function TimelineLabel({ label, hidden }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.timelineItem, {
      [classes.hidden]: hidden
    })}>
      <Typography
        variant='caption'
        color='textSecondary'
        className={classes.timelineItemLabel}
      >
        {label}
      </Typography>
      <Divider className={classes.timelineItemDivider} />
    </div>
  );
}

TimelineLabel.propTypes = {
  // E.g., '00:00'
  label: PropTypes.string.isRequired,

  // If set to `true`, this label will be hidden
  hidden: PropTypes.bool
};

TimelineLabel.defaultProps = {
  hidden: false
};

export default TimelineLabel;
