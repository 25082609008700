import React from 'react';
import PropTypes from 'prop-types';
import Error from '../../oops/Error';
import Header from '../../ui/todo/details/Header';
import HeaderToolbar from '../../ui/todo/details/HeaderToolbar';
import ToolbarButton from '../../ui/todo/details/HeaderToolbarButton';
import CloseIcon from '../../ui/icons/CloseMui';
import Content from '../../ui/todo/details/Content';
import TodoNotFound from './TodoNotFound';

function TodoQueryError({ error, onClose }) {
  const isProduction = process.env.NODE_ENV === 'production';

  return isProduction ? (
    <TodoNotFound onClose={onClose} />
  ) : (
    <React.Fragment>
      <Header>
        <div style={{flexGrow: 1}} />
        <HeaderToolbar>
          <ToolbarButton
            background={false}
            aria-label='close'
            icon={ <CloseIcon /> }
            onClick={onClose}
          />
        </HeaderToolbar>
      </Header>
      <Content>
        <Error error={error} />
      </Content>
    </React.Fragment>
  );
}

TodoQueryError.propTypes = {
  error: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default TodoQueryError;
