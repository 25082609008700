import React from 'react';
import PropTypes from 'prop-types';
import { startOfDay, endOfDay } from 'date-fns';
import ProjectSchedulesQuery from '../ProjectSchedulesQuery';

function ProjectWeeklyScheduleQuery({
  projectId,
  startOfWeek,
  endOfWeek,
  ...rest
}) {
  const start = startOfDay(startOfWeek);
  const end = endOfDay(endOfWeek);

  return (
    <ProjectSchedulesQuery
      projectId={projectId}
      start={start}
      end={end}
      {...rest}
    />
  );
}

ProjectWeeklyScheduleQuery.propTypes = {
  // Which project?
  projectId: PropTypes.string.isRequired,

  // Start and end days of the week
  startOfWeek: PropTypes.instanceOf(Date).isRequired,
  endOfWeek: PropTypes.instanceOf(Date).isRequired
};

export default ProjectWeeklyScheduleQuery;
