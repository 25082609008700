import React from 'react';
import PropTypes from 'prop-types';

import Container from './ui/Container';
import StickyHeader from './ui/StickyHeader';
import AllDayRow from './ui/AllDayRow';
import Timeline from './ui/Timeline';

import ProjectWeeklyScheduleQuery from './ProjectWeeklyScheduleQuery';
import NoDataView from './NoDataView';
import listDaysOfWeek from './utils/listDaysOfWeek';
import splitSchedules from './utils/splitSchedules';
import WeekDayRow from './WeekDayRow';
import TimeSpecificSchedule from './TimeSpecificSchedule';
import AllDaySchedule from './AllDaySchedule';

function ProjectWeeklySchedule({ projectId, date }) {
  const daysOfWeek = listDaysOfWeek(date);
  const startDate = daysOfWeek[0];
  const endDate = daysOfWeek[daysOfWeek.length - 1];

  return (
    <ProjectWeeklyScheduleQuery
      fetchPolicy='cache-and-network'
      projectId={projectId}
      startOfWeek={startDate}
      endOfWeek={endDate}
      onLoading={() => <NoDataView daysOfWeek={daysOfWeek} />}
    >
      {(schedules) => {
        const { allDay, timeSpecific } = splitSchedules(schedules);

        return (
          <Container>
            <StickyHeader>
              <WeekDayRow daysOfWeek={daysOfWeek} />
              <AllDayRow numCols={daysOfWeek.length}>
                <AllDaySchedule
                  projectId={projectId}
                  days={daysOfWeek}
                  schedules={allDay}
                />
              </AllDayRow>
            </StickyHeader>

            <Timeline>
              <TimeSpecificSchedule
                projectId={projectId}
                days={daysOfWeek}
                schedules={timeSpecific}
              />
            </Timeline>
          </Container>
        );
      }}
    </ProjectWeeklyScheduleQuery>
  );
}

ProjectWeeklySchedule.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

export default ProjectWeeklySchedule;
