import React from 'react';
import PropTypes from 'prop-types';
import SectionHeaderContainer from './SectionHeaderContainer';
import SectionHeaderExpandRow from './SectionHeaderExpandRow';
import SectionNameInput from './SectionNameInput';

function CreateNewSectionForm({ colSpan, open, onClose, onSubmit }) {
  const [inputValue, setInputValue] = React.useState('');

  const handleInputValueChange = (event) => {
    setInputValue(event.target.value)
  };

  const handleClose = () => {
    if (onSubmit) {
      onSubmit(inputValue);
    }

    if (onClose) {
      onClose();
    }
  };

  const handleInputBlur = () => {
    handleClose();
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Escape' || event.key === 'Enter' ) {
      event.preventDefault();
      handleClose();
    }
  };

  React.useEffect(() => {
    setInputValue('');
  }, [open]);

  return open && (
    <SectionHeaderContainer>
      <SectionHeaderExpandRow colSpan={colSpan} disableExpand>
        <div style={{maxWidth: 300}}>
          <SectionNameInput
            autoFocus
            value={inputValue}
            onChange={handleInputValueChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
          />
        </div>
      </SectionHeaderExpandRow>
    </SectionHeaderContainer>
  );
}

CreateNewSectionForm.propTypes = {
  colSpan: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

CreateNewSectionForm.defaultProps = {
  colSpan: 2,
  open: false
};

export default CreateNewSectionForm;
