import React from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../ui/page/Section';
import SectionHeader from '../../ui/page/SectionHeader';
import SectionTitle from '../../ui/page/SectionTitle';
import SearchMemberInTeamQuery from './SearchMemberInTeamQuery';
import MemberSearchCriteria from './MemberSearchCriteria';
import MembershipsGridSkeleton from './MembershipsGridSkeleton';
import MembershipsGrid from './MembershipsGrid';

function Members() {
  const { teamId } = useParams();
  const [criteria, setCriteria] = React.useState({
    matching: undefined
  });

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Members</SectionTitle>
        <MemberSearchCriteria
          criteria={criteria}
          onChange={handleCriteriaChange}
        />
      </SectionHeader>
      <SearchMemberInTeamQuery
        teamId={teamId}
        criteria={criteria}
        onLoading={() => <MembershipsGridSkeleton /> }
      >
        {(memberships, { variables, subscribeToMore }) => (
          <MembershipsGrid
            memberships={memberships}
            variables={variables}
            subscribeToMemberAddedOrRemoved={subscribeToMore}
          />
        )}
      </SearchMemberInTeamQuery>
    </Section>
  );
}

export default Members;
