import React from 'react';
import PageNavigation from '../ui/page/Navigation';
import Tabs from '../ui/tabs/Tabs';
import Tab from '../ui/tabs/Tab';
import TabPanel, { a11yProps } from './TabPanel';
import MembersHeader from './MembersHeader';
import MembersActions from './MembersActions';
import SearchMembers from './SearchMemberInCurrentWorkspace';
import SearchPendingInvites from './SearchPendingInvitesIncurrentWorkspace';

function Members() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <MembersHeader />
      <PageNavigation>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Members' {...a11yProps(0)} />
          <Tab label='Pending invites' {...a11yProps(1)} />
        </Tabs>
        <MembersActions />
      </PageNavigation>
      <TabPanel value={value} index={0}>
        <SearchMembers />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SearchPendingInvites />
      </TabPanel>
    </div>
  );
}

export default Members;
