import React from 'react';
import PropTypes from 'prop-types';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withStyles } from '@material-ui/core/styles';
import UserAvatar from '../../../../account/UserAvatar';

const StyledAvatarGroup =  withStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.875rem',
    fontWeight: 500,
    marginRight: theme.spacing(0.5)
  }
}))((props) => <AvatarGroup {...props} />);

function SubscribersAvatarGroup({ subscribers }) {
  return (
    <StyledAvatarGroup max={8}>
      {subscribers.map((user) => (
        <UserAvatar key={user.id} user={user} />
      ))}
    </StyledAvatarGroup>
  );
}


SubscribersAvatarGroup.propTypes = {
  subscribers: PropTypes.array.isRequired
};

export default SubscribersAvatarGroup;
