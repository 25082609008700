import React from 'react';
import MenuItem from '../../ui/menu/MenuItem';
import UserAvatar from '../UserAvatar';
import EditUserProfile from '../dialogs/EditUserProfile';
import DialogContext from '../../ui/dialog/DialogContext';
import MenuContext from '../../ui/menu/MenuContext';

const UserProfileMenuItem = React.forwardRef((props, ref) => {
  const { user, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <EditUserProfile />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      avatar={ <UserAvatar user={user} size='large' /> }
      primary={user.username}
      secondary='Edit your profile.'
      alignItems='center'
      onClick={handleClick}
      {...rest}
    />
  );
});

export default UserProfileMenuItem;
