import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/button/ButtonLink';
import useRestoreProjectMutation from './hooks/useRestoreProjectMuation';

function ArchivedWarningRestoreButton({ projectId, ...rest }) {
  const [restoreProject] = useRestoreProjectMutation({
    variables: { projectId },
    optimisticResponse: {
      __typename: 'Mutation',
      unarchiveProject: {
        __typename: 'Project',
        id: projectId,
        archived: false
      }
    }
  });

  const handleClick = () => {
    restoreProject();
  };

  return (
    <Button onClick={handleClick} {...rest}>
      restore it
    </Button>
  );
}

ArchivedWarningRestoreButton.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ArchivedWarningRestoreButton;
