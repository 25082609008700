import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '../../../../icons/16x16/TodoList';
import ContentField from './ContentField';

function ProjectField({ name }) {
  return (
    <ContentField icon={<WorkIcon />}>
      <Typography
        variant='body2'
        noWrap
        style={{lineHeight: '28px'}}
      >
        {name}
      </Typography>
    </ContentField>
  );
}

ProjectField.propTypes = {
  name: PropTypes.string.isRequired
};

export default ProjectField;
