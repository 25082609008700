import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const DELETE_WORKSPACE_MUTATION = gql`
  mutation DeleteWorkspace($workspaceId: ID!) {
    deleteWorkspace(workspaceId: $workspaceId) {
      id
    }
  }
`;

export default function useDeleteWorkspaceMutation(options) {
  const { workspaceId, ...rest } = options;
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(DELETE_WORKSPACE_MUTATION, {
    variables: { workspaceId },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...rest
  });
}
