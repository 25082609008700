import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { generateFileCommentHtmlId } from '../file/comments/helpers';
import useProjectURL from '../project/hooks/useProjectURL';
import GenericActivityItem from './ui/GenericActivityItem';
import ActivityTextDetails from './ui/ActivityTextDetails';

function CommentFileItem({ activity }) {
  const { type, actor, details, upload, comment, insertedAt } = activity;
  const [fileName, commentText] = details;

  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const fileUrl = `${projectUrl}/files/uploads/${upload.id}`;
  const commentId = generateFileCommentHtmlId(upload.id, comment.id);
  const commentUrl = `${fileUrl}#${commentId}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='commented on'
      linkUrl={commentUrl}
      linkText={fileName}
      insertedAt={insertedAt}
    >
      <ActivityTextDetails text={commentText} />
    </GenericActivityItem>
  );
}

CommentFileItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['COMMENT_FILE']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    upload: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    comment: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default CommentFileItem;
