import React from 'react';
import PropTypes from 'prop-types';
import SectionRow from '../../ui/todo/sheet/SectionRow';
import { TODO_CRITERIA_SHAPE, generateNewRowId, formatDateForBackend }
from '../helpers';

function AddTodoFormRow({
  sectionId,
  todoCriteria,
  cols,
  open,
  onClose,
  onSubmit,
  onSubmitAndRedirect
}) {
  const handleSubmit = React.useCallback((prevState, state) => {
    if (!onSubmit) { return; }

    // Validate task name.
    if (!state.name || state.name.trim().length < 3) {
      if (onClose) { onClose(); }
      return;
    }

    // Get rid of irrelevant stuffs from state.
    const { autoFocus, onSaveAndRedirect, ...optimisticResponse} = state;

    // variables for graphql mutation.
    const variables = {
      name: optimisticResponse.name,
      description: optimisticResponse.description,
      assigneeId: optimisticResponse.assignee ?
                  optimisticResponse.assignee.id :
                  null,
      dueDate: formatDateForBackend(optimisticResponse.dueDate),
      priority: optimisticResponse.priority
    };

    onSubmit(variables, optimisticResponse);

    if (onClose) { onClose(); }
  }, [onSubmit, onClose]);

  // TODO: this is ugly and repetitive.
  const handleSubmitAndRedirect = React.useCallback((state) => {
    if (!onSubmitAndRedirect) { return; }

    // Validate task name.
    if (!state.name || state.name.trim().length < 3) {
      if (onClose) { onClose(); }
      return;
    }

    // Get rid of irrelevant stuffs from state.
    const { autoFocus, onSaveAndRedirect, ...optimisticResponse} = state;

    // variables for graphql mutation.
    const variables = {
      name: optimisticResponse.name,
      description: optimisticResponse.description,
      assigneeId: optimisticResponse.assignee ?
                  optimisticResponse.assignee.id :
                  null,
      dueDate: formatDateForBackend(optimisticResponse.dueDate),
      priority: optimisticResponse.priority
    };

    onSubmitAndRedirect(variables, optimisticResponse);

    if (onClose) { onClose(); }
  }, [onSubmitAndRedirect, onClose]);


  // TODO: refine this to reflect dueDate criteria, etc...
  // Default new row based on the current criteria
  const defaultRow = React.useMemo(() => ({
    id: generateNewRowId(),
    name: '',
    description: '',
    assignee: todoCriteria.assignee,
    dueDate: null,
    priority: 'NONE',
    completed: false,
    rank: '',
    autoFocus: true,
    onSaveAndRedirect: handleSubmitAndRedirect
  }), [todoCriteria.assignee, handleSubmitAndRedirect]);

  return open ? (
    <SectionRow
      draggable={false}
      row={defaultRow}
      cols={cols}
      onBlurWithin={handleSubmit}
      sectionId={sectionId}
      todoCriteria={todoCriteria}
    />
  ) : null;
}

AddTodoFormRow.propTypes = {
  // id and todo criteria for the todolist.
  sectionId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired,

  // sheet's columns
  cols: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    getCellProps: PropTypes.func.isRequired,
    renderCell: PropTypes.func.isRequired
  })).isRequired,

  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitAndRedirect: PropTypes.func
};

AddTodoFormRow.defaultProps = {
  open: false
};

export default AddTodoFormRow;
