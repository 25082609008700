import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editorToolbar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,

    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bottom: {
    position: 'static',
    padding: theme.spacing(0, 0.5, 0.5, 0.5)
  }
}));

function EditorToolbar({ bottom, ...rest }) {
  const classes = useStyles();
  const finalClassName = clsx(classes.editorToolbar, {
    [classes.bottom]: bottom
  });
  return <div className={finalClassName} {...rest} />;
}

EditorToolbar.propTypes = {
  bottom: PropTypes.bool
};

EditorToolbar.defaultProps = {
  bottom: 0
};

export default EditorToolbar;
