import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '../icons/16x16/Star';
import StarFillIcon from '../icons/16x16/StarFillTransparent';
import { FILTER_PRIMARY } from '../icons/Base';
import { AVATAR_COLORS, AVATAR_DEFAULT_COLOR } from '../ui/avatar/colors';
import Tooltip from './ProjectHeaderToolbarTooltip';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.25, 0.75),
    borderRadius: 4,
    '&:hover': {
      '& $icon': {
        filter: FILTER_PRIMARY
      },
      '& $fillIcon': {
        filter: 'brightness(5%) invert(1)'
      }
    },
  },
  fillIcon: {
    // TODO: A hack to make filled icon look right on hover.
    filter: 'brightness(0) invert(1)',
    transition: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  icon: {}
}));

// Yellow as default color for star icon.
const defaultStarIconColor = '#febe30';

// Helper function to get the color for star icon.
function getStarIconColor(projectColor) {
  if (!projectColor) { return defaultStarIconColor;  }

  return (
    AVATAR_COLORS.includes(projectColor) &&
    projectColor !== AVATAR_DEFAULT_COLOR && // dont use gray for star
    projectColor
  ) || defaultStarIconColor;
}

function FavoriteCheckbox({ starColor, checked, onChange }) {
  const classes= useStyles();
  const color = getStarIconColor(starColor);

  const handleCheckedChange = (event) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  const tooltip = checked ? 'Remove from Favorites' : 'Add to Favorites';

  return (
    <Tooltip title={tooltip} arrow>
      <Checkbox
        color='default'
        className={classes.checkbox}
        icon={<StarIcon color='secondary' className={classes.icon} />}
        checkedIcon={(
          <span style={{display: 'flex', backgroundColor: color}}>
            <StarFillIcon className={classes.fillIcon} />
          </span>
        )}
        checked={checked}
        onChange={handleCheckedChange}
      />
    </Tooltip>
  );
}

FavoriteCheckbox.propTypes = {
  starColor: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

FavoriteCheckbox.defaultProps = {
  starColor: defaultStarIconColor,
  checked: false
};

export default FavoriteCheckbox;
