import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../button/IconButton';

function ZoomButton({ icon, disabled, ...rest }) {
  return (
    <IconButton
      background={false}
      size='small'
      color={disabled ? 'disabled' : 'secondary'}
      icon={icon}
      disabled={disabled}
      {...rest}
    />
  );
}

ZoomButton.propTypes = {
  icon: PropTypes.node.isRequired
};

export default ZoomButton;
