import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../ui/button/IconButton';
import PersonAddIcon from '../../icons/16x16/PersonPlusFill';
import DialogContext from '../../ui/dialog/DialogContext';
import AddMembers from '../dialogs/AddMembers';

function AddNewMember({ teamId }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: <AddMembers teamId={teamId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <IconButton
      icon={ <PersonAddIcon /> }
      size='small'
      square
      onClick={handleClick}
    />
  );
}

AddNewMember.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default AddNewMember;
