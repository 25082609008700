import React from 'react';
import FullScreenCard from '../ui/card/FullScreenCard.js';
import LinearStepper from '../ui/stepper/LinearStepper';
import LinearStep from '../ui/stepper/LinearStep';
import CreateAccountForm from './CreateAccountForm';
import GotInvitation from '../workspace/GotInvitation';
import CreateWorkspaceForm from '../workspace/CreateWorkspaceForm';

function SignUpForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [account, setAccount] = React.useState(null);

  React.useEffect(() => {
    if (account !== null) {
      if (account.receivedInvitations.length > 0) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    }
  }, [account]);

  return (
    <FullScreenCard>
      <LinearStepper activeStep={activeStep}>
        <LinearStep>
          <CreateAccountForm onFinished={(user) => {
            setAccount(user);
          }} />
        </LinearStep>

        <LinearStep>
          {(activeStep === 1) && (
            <GotInvitation
              username={account.username}
              invitation={account.receivedInvitations[0]}
              onSkip={() => {
                setActiveStep(2);
              }}
            />
          )}
        </LinearStep>

        <LinearStep>
          {(activeStep === 2) && (
            <CreateWorkspaceForm username={account.username} />
          )}
        </LinearStep>
      </LinearStepper>
    </FullScreenCard>
  );
}

export default SignUpForm;
