import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { generateMessageCommentHtmlId } from '../message/comments/helpers';
import useProjectURL from '../project/hooks/useProjectURL';
import GenericActivityItem from './ui/GenericActivityItem';
import ActivityTextDetails from './ui/ActivityTextDetails';

function CommentMessageItem({ activity }) {
  const { type, actor, details, message, comment, insertedAt } = activity;
  const [msgSubject, commentText] = details;

  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const messageUrl = `${projectUrl}/messages/${message.id}`;
  const commentId = generateMessageCommentHtmlId(message.id, comment.id);
  const commentUrl = `${messageUrl}#${commentId}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='commented on'
      linkUrl={commentUrl}
      linkText={msgSubject}
      insertedAt={insertedAt}
    >
      <ActivityTextDetails text={commentText} />
    </GenericActivityItem>
  );
}

CommentMessageItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['COMMENT_MESSAGE']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    message: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    comment: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default CommentMessageItem;
