import React from 'react';
import PropTypes from 'prop-types';
import DialogContext from '../ui/dialog/DialogContext';
import StyledNoFilesRow from '../ui/file/table/NoFilesRow';
import UploadFilesDialog from './directory-options/UploadFilesDialog';

function NoFilesRow({ colSpan, projectId, currentFolder }) {
  const { openDialog } = React.useContext(DialogContext);

  const openUploadDialog = () => {
    openDialog({
      dialogContent: (
        <UploadFilesDialog
          projectId={projectId}
          destinationFolder={currentFolder}
        />
      ),
      dialogProps: {
        fullScreen: true,
        disableEscapeKeyDown: true
      }
    });
  };

  return (
    <StyledNoFilesRow
      colSpan={colSpan}
      onUpload={openUploadDialog}
    />
  );
}

NoFilesRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  currentFolder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default NoFilesRow;
