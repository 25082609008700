import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import ProjectIcon from '../../icons/20x20/TodoList';
import DialogContext from '../../ui/dialog/DialogContext';
import CreateProjectDialog from '../../project/new/CreateProjectDialog';

const AddProjectMenuItem = React.forwardRef((props, ref) => {
  const { teamId, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <CreateProjectDialog teamId={teamId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Add project'
      icon={ <ProjectIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

AddProjectMenuItem.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default AddProjectMenuItem;
