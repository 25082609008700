import React from 'react';
import PropTypes from 'prop-types';
import { DocumentType, convertMimeTypeToDocumentType } from '../mime/mime';
import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';
import AudioPreview from './AudioPreview';
import PdfPreview from './PdfPreview';
import DocumentIconLink from './DocumentIconLink';
import PreviewContainer from './PreviewContainer';

function Preview({ src, type, alt, downloadUrl, maxHeight, zoom }) {
  const docType = convertMimeTypeToDocumentType(type);

  switch(docType) {
    case DocumentType.IMAGE:
      return (
        <ImagePreview
          src={src}
          type={type}
          alt={alt}
          downloadUrl={downloadUrl}
          maxHeight={maxHeight}
          zoom={zoom}
        />
      );
    case DocumentType.VIDEO:
      return (
        <VideoPreview
          src={src}
          type={type}
          downloadUrl={downloadUrl}
          maxHeight={maxHeight}
        />
      );
    case DocumentType.AUDIO:
      return (
        <AudioPreview
          src={src}
          type={type}
          downloadUrl={downloadUrl}
        />
      );
    case DocumentType.PDF:
      return (
        <PdfPreview
          maxHeight={maxHeight}
          src={src}
          zoom={zoom}
        />
      );
    default:
      return (
        <PreviewContainer>
          <DocumentIconLink type={type} href={downloadUrl} />
        </PreviewContainer>
      );
  }
}


Preview.propTypes = {
  // The source and MIME type of the document to be previewed.
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  // Optional download url when document cannot be previewed.
  downloadUrl: PropTypes.string,

  // This is required if the document to be previewed is of type image.
  alt: PropTypes.string,

  // The maximum height applied to the container if the document to
  // be preview is either image or video.
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['none'])
  ]),

  // Zoom value in percentage unit, e.g 80
  zoom: PropTypes.number
};

Preview.defaultProps = {
  maxHeight: 'none',
  zoom: 100
};

export default React.memo(Preview);
