import React from 'react';
import Section from '../ui/page/Section';
import SectionHeader from '../ui/page/SectionHeader';
import SectionTitle from '../ui/page/SectionTitle';
import SectionSearchNotFound from '../ui/page/SectionSearchNotFound';
import PendingInvitesSearchCriteria from './PendingInvitesSearchCriteria';
import SearchInvitationsInCurrentWorkspaceQuery
from './SearchInvitationsInCurrentWorkspaceQuery';
import InvitationsSearchLoading from './InvitationsSearchLoading';
import InvitationGrid from './InvitationGrid';

export default function SearchPendingInvitesInCurrentWorkspace() {
  const [criteria, setCriteria] = React.useState({
    matching: undefined,
    state: 'pending'
  });

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  const renderSearchResult = (invitations) => {
    if (invitations.length === 0) {
      const message = criteria.matching ?
                      `No results for "${criteria.matching}".` :
                      'No pending invites found.';
      return <SectionSearchNotFound message={message} />;
    }
    return <InvitationGrid invitations={invitations} />
  };

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Pending invites</SectionTitle>
        <PendingInvitesSearchCriteria
          criteria={criteria}
          onChange={handleCriteriaChange}
        />
      </SectionHeader>
      <SearchInvitationsInCurrentWorkspaceQuery
        criteria={criteria}
        onLoading={() => <InvitationsSearchLoading /> }
      >
        {renderSearchResult}
      </SearchInvitationsInCurrentWorkspaceQuery>
    </Section>
  );
}
