import React from 'react';
import PropTypes from 'prop-types';
import { uploadOption } from './OptionList';
import EditUpload from './EditUpload';
import DeleteConfirm from './DeleteConfirm';

function getDialogContentForOption(option, props) {
  switch(option) {
    case uploadOption.EDIT_UPLOAD:
      return (
        <EditUpload {...props} />
      );

    case uploadOption.DELETE_UPLOAD:
      return (
        <DeleteConfirm {...props} />
      );

    default:
      return null;
  }
};

function OptionDialogContent({ option, ...rest }) {
  return getDialogContentForOption(option, rest);
}

OptionDialogContent.propTypes = {
  option: PropTypes.oneOf(Object.values(uploadOption))
};

export default OptionDialogContent;
