import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    inset: '4px 0px',
    borderRadius: 6,
    cursor: 'pointer',
    pointerEvents: 'none',
    backgroundColor: theme.palette.button.overlay.hover,
    opacity: 0.0,
    transition: 'opacity 0.1s cubic-bezier(0, 0, 1, 1)'
  }
}));

export default function TabOverlay({ className, ...rest }) {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)} {...rest}></div>;
}
