import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  background: {
    color: '#E4E6EB'
  },
  foreground: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0
  }
}));

function CircularProgress({ value, size, thickness, className }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <MuiCircularProgress
        variant='determinate'
        value={100}
        className={classes.background}
        size={size}
        thickness={thickness}
      />
      <MuiCircularProgress
        variant='determinate'
        value={value}
        className={classes.foreground}
        size={size}
        thickness={thickness}
      />
    </div>
  );
}


CircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number,
  thickness: PropTypes.number
};

CircularProgress.defaultProps = {
  size: 20,
  thickness: 6
};

export default CircularProgress;
