import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InvitationGridItem from './InvitationGridItem';

function InvitationGrid({ invitations }) {
  return (
    <Grid container spacing={2}>
      {invitations.map((invitation) => (
        <InvitationGridItem key={invitation.id} invitation={invitation} />
      ))}
    </Grid>
  );
}

InvitationGrid.propTypes = {
  invitations: PropTypes.array.isRequired
};

export default InvitationGrid;
