import React from 'react';
import PropTypes from 'prop-types';
import FollowersBarWithData from './FollowersBarWithData';
import FollowersQuery from './FollowersQuery';
import FollowersChangedSubscriber from './FollowersChangedSubscriber';

function FollowersBar({ projectId, messageId }) {
  return (
    <FollowersQuery
      projectId={projectId}
      messageId={messageId}
      fetchPolicy='network-only'
    >
      {(followers, { subscribeToMore }) => (
        <React.Fragment>
          <FollowersBarWithData
            projectId={projectId}
            messageId={messageId}
            followers={followers}
          />
          <FollowersChangedSubscriber
            messageId={messageId}
            subscribeToFollowersChanged={subscribeToMore}
          />
        </React.Fragment>
      )}
    </FollowersQuery>
  );
}

FollowersBar.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired
};

export default FollowersBar;
