import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const FOLLOW_EVENT_MUTATION = gql`
  mutation FollowEvent($eventId: ID!) {
    followEvent(eventId: $eventId) {
      id
    }
  }
`;

export default function useSubscribeMeMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(FOLLOW_EVENT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
