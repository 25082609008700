import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '../ui/file/Toolbar';
import Breadcrumbs from '../ui/file/Breadcrumbs';
import FileTable from './FileTable';
import SearchCriteria, { DEFAULT_SEARCH_CRITERIA}
from '../ui/file/SearchCriteria';

function DirectoryFiles({ rootUrl, projectId, directory }) {
  const isRootDirectory = directory.id === 'root';
  const [criteria, setCriteria] = React.useState(DEFAULT_SEARCH_CRITERIA);

  const handleCriteriaChange = (newCriteria) => {
    setCriteria(newCriteria);
  };

  const handleClearFilters = () => {
    setCriteria(DEFAULT_SEARCH_CRITERIA);
  };

  return (
    <React.Fragment>
      <Toolbar>
        {isRootDirectory ? (
          <div style={{flexGrow: 1}} />
        ) : (
          <Breadcrumbs
            rootUrl={rootUrl}
            path={directory.path}
          />
        )}
        <SearchCriteria
          criteria={criteria}
          onChange={handleCriteriaChange}
        />
      </Toolbar>

      <FileTable
        rootUrl={rootUrl}
        projectId={projectId}
        directory={directory}
        criteria={criteria}
        onClearFilters={handleClearFilters}
      />
    </React.Fragment>
  );
}

DirectoryFiles.propTypes = {
  rootUrl: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  directory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.array
  }).isRequired
};

export default DirectoryFiles;
