import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';
import { CHIP_TODO } from '../fragments';
import { writeEntryToCache } from '../schedulesCacheHelpers';

const CREATE_TODO_MUTATION = gql`
  mutation CreateTodo(
    $todoListId: ID!,
    $name: String!,
    $description: String,
    $dueDate: Date,
    $priority: TodoPriority,
    $assigneeId: ID
  ) {
    appendTodoItem(
      todoListId: $todoListId,
      name: $name,
      description: $description,
      dueDate: $dueDate,
      priority: $priority,
      assigneeId: $assigneeId
    ) {
      ...ChipTodo
    }
  }
  ${CHIP_TODO}
`;

export default function useCreateTodo(projectId) {
  const [mutate, mutationResults] = useMutation(CREATE_TODO_MUTATION, {
    loadingMessage: 'Creating task...',
    successMessage: 'Task created',
    variables: { projectId },
    update: (cache, { data: { appendTodoItem: entry }}) => {
      writeEntryToCache(cache, { projectId, entry });
    }
  });

  const mutateFn = useCallback((data, optimisticResponse) => {
    mutate({
      variables: { ...data },
      optimisticResponse: {
        appendTodoItem: { ...optimisticResponse }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
