import React from 'react';
import PropTypes from 'prop-types';
import ActionDelete from '../../../ui/file/list/ActionDelete';
import { TODO_ATTACHMENTS_QUERY } from './AttachmentsQuery';
import useDeleteAttachmentMutation from './useDeleteAttachmentMutation';

function ListItemActionDelete({ todoId, attachmentId, ...rest }) {
  const [deleteAttachment] = useDeleteAttachmentMutation({
    variables: { attachmentId },

    optimisticResponse: {
      deleteTodoAttachment: {
        id: attachmentId,
        __typename: 'File'
      }
    },

    update: (cache, { data: { deleteTodoAttachment }}) => {
      const oldData = cache.readQuery({
        query: TODO_ATTACHMENTS_QUERY,
        variables: { todoId }
      });

      const newAttachments = oldData.todoItem.attachments.filter((a) => (
        a.id !== deleteTodoAttachment.id
      ));

      cache.writeQuery({
        query: TODO_ATTACHMENTS_QUERY,
        variables: { todoId },
        data: {
          todoItem: {
            ...oldData.todoItem,
            attachments: newAttachments
          }
        }
      });
    }
  });

  return (
    <ActionDelete
      onClick={deleteAttachment}
      {...rest}
    />
  );
}


ListItemActionDelete.propTypes = {
  todoId: PropTypes.string.isRequired,
  attachmentId: PropTypes.string.isRequired
};

export default ListItemActionDelete;
