import React from 'react';
import MuiSkeleton from '@material-ui/lab/Skeleton';

export default function Skeleton() {
  return (
    <div style={{
      padding: 24
    }}>
      <MuiSkeleton variant='text' style={{ marginBottom: 24}} />
      <MuiSkeleton variant='rect' width='100%' height={118} />
    </div>
  );
}
