import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import { MEMBERSHIP_DETAILS_FRAGMENT, MEMBERSHIP_WORKSPACE_FRAGMENT }
from '../member/fragments';

const CURRENT_USER_WORKSPACE_MEMBERSHIPS_QUERY = gql`
  query GetCurrentUserWorkspaceMemberships {
    me {
      id
      workspaceMemberships {
        ...MembershipDetails
        ...MembershipWorkspace
      }
    }
  }
  ${MEMBERSHIP_DETAILS_FRAGMENT}
  ${MEMBERSHIP_WORKSPACE_FRAGMENT}
`;

function CurrentUserWorkspaceMembershipsQuery(props) {
  const { onLoading, onError, children, ...rest } = props;
  const { loading, error, data } = useQuery(
    CURRENT_USER_WORKSPACE_MEMBERSHIPS_QUERY,
    { ...rest }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError() : <Error error={error} />;
  return children(data.me.workspaceMemberships);
}

CurrentUserWorkspaceMembershipsQuery.propTypes = {
  children: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

export default CurrentUserWorkspaceMembershipsQuery;
