import React from 'react';
import UserAutocomplete from '../../new-ui/UserAutocomplete';

export default function AssigneeAutocomplete({ inputProps, ...rest }) {
  return (
    <UserAutocomplete
      inputProps={{
        placeholder: 'Assign this task',
        ...inputProps
      }}
      {...rest}
    />
  );
}
