import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ProjectAvatar from './ProjectAvatar';

const useStyles = makeStyles((theme) => ({
  projectAvatar: {
    marginRight: theme.spacing(2),
    flex: '0 0 auto'
  }
}));

function ProjectHeaderProjectAvatar({ name, color }) {
  const classes = useStyles();

  return (
    <ProjectAvatar
      size='medium'
      className={classes.projectAvatar}
      name={name}
      color={color}
    />
  );
}

function ProjectHeaderProjectAvatarSkeleton() {
  const classes = useStyles();

  return (
    <Skeleton
      variant='rect'
      width={40}
      height={40}
      className={classes.projectAvatar}
    />
  );
}

export default ProjectHeaderProjectAvatar;
export { ProjectHeaderProjectAvatarSkeleton };
