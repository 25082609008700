import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const RESTORE_PROJECT_MUTATION = gql`
  mutation RestoreProject($projectId: ID!) {
    unarchiveProject(projectId: $projectId) {
      id
      archived
    }
  }
`;

export default function useRestoreProjectMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(RESTORE_PROJECT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
