import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../snackbar/useCustomSnackbar';

const UPDATE_PROJECT_MESSAGE_MUTATION = gql`
  mutation UpdateProjectMessage(
    $messageId: ID!,
    $changes: UpdateMessageInput!
  ) {
    updateProjectMessage(messageId: $messageId, changes: $changes) {
      id
    }
  }
`;

export default function useUpdateMessageMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_PROJECT_MESSAGE_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
