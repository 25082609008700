import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { TEAM_MEMBERSHIP_DETAILS_FRAGMENT } from '../fragments';

const TEAM_MEMBER_ADDED_SUBSCRIPTION = gql`
  subscription OnTeamMemberAdded($teamId: ID!) {
    teamMemberAdded(teamId: $teamId) {
      ...TeamMembershipDetails
    }
  }
  ${TEAM_MEMBERSHIP_DETAILS_FRAGMENT}
`;

export default function useMemberAddedSubscription(options) {
  const { teamId, ...otherOptions } = options;
  return useSubscription(TEAM_MEMBER_ADDED_SUBSCRIPTION, {
    variables: { teamId },
    ...otherOptions
  });
}

export { TEAM_MEMBER_ADDED_SUBSCRIPTION };
