import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '../../ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
  iconCircle: {
    border: '1px dashed currentColor',
    borderRadius: '50%',
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    transform: 'scale(.8)'
  }
}));

function NoOwnerAvatar({ iconClassName }) {
  const classes = useStyles();

  return (
    <div className={classes.iconCircle}>
      <PersonAddIcon
        color='secondary'
        className={clsx(classes.icon, iconClassName)}
      />
    </div>
  );
}

NoOwnerAvatar.propTypes = {
  // So that, say, we can filter the icon to our desired color upon hover.
  iconClassName: PropTypes.string
};

export default NoOwnerAvatar;
