import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { matchesNewCommentId } from './helpers';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';
import { useContainerContext } from '../../ui/file/FileDetailsContainer';

const FILE_COMMENT_CREATED_SUBSCRIPTION = gql`
  subscription OnFileCommentCreated($fileId: ID!) {
    fileCommentAdded(fileId: $fileId) {
      id
      text
      autoGenerated
      severity
      ...CommentAuthor
      ...CommentFiles
      updatedAt
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

function CommentCreatedSubscriber({ fileId, subscribeToCommentCreated }) {
  const { scrollToBottom } = useContainerContext();
  const timer = React.useRef();

  React.useEffect(() => {
    const unsubscribe = subscribeToCommentCreated({
      document: FILE_COMMENT_CREATED_SUBSCRIPTION,
      variables: { fileId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return prev; }

        const newComment = subscriptionData.data.fileCommentAdded;
        let comments = [...prev.project.upload.comments];

        // TODO: explain why.
        // If we didn't remove the optimistic response result from
        // the mutation, we would end up with two comments in the
        // cache: one from the optimistic response result and
        // the other from the database (probably subscription result).
        if (
          comments.length &&
          matchesNewCommentId(comments[comments.length - 1].id)
        ) {
          comments.pop();
        }

        // Check for existance
        const found = comments.some(({ id }) => ( id === newComment.id ));
        if (!found) {
          comments = [...comments, newComment];
        }

        // FIXME: this is a workaround solution in order to wait for the
        // cache to be updated first, and then scroll to bottom!
        timer.current = setTimeout(() => {
          scrollToBottom();
        }, 100);

        return {
          project: {
            ...prev.project,
            upload: {
              ...prev.project.upload,
              comments: comments
            }
          }
        };
      }
    });

    return () => {
      unsubscribe();
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [fileId, subscribeToCommentCreated, scrollToBottom]);

  return null;
}

CommentCreatedSubscriber.propTypes = {
  fileId: PropTypes.string.isRequired,
  subscribeToCommentCreated: PropTypes.func.isRequired
};

export default CommentCreatedSubscriber;
