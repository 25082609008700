import React from 'react';
import clsx from 'clsx';
import ArrowDropdownIcon from '../../ui/icons/ArrowDropdown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1)
  },
  icon: {
    transform: 'scale(.6)'
  }
}));

export default function DropDownIcon({ className }) {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      <ArrowDropdownIcon
        color='secondary'
        className={clsx(classes.icon, className)}
      />
    </div>
  );
}
