import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { COMMENT_FILES } from './fragments';

const SOME_FILE_COMMENT_UPDATED_SUBSCRIPTION = gql`
  subscription OnSomeFileCommentUpdated($fileId: ID!) {
    fileCommentUpdated(fileId: $fileId) {
      id
      text
      updatedAt
      ...CommentFiles
    }
  }
  ${COMMENT_FILES}
`;

function CommentUpdatedSubscriber({ fileId }) {
  useSubscription(SOME_FILE_COMMENT_UPDATED_SUBSCRIPTION, {
    variables: { fileId }
  });
  return null;
}

CommentUpdatedSubscriber.propTypes = {
  fileId: PropTypes.string.isRequired
};

export default CommentUpdatedSubscriber;
