import React from 'react';
import { parseISO } from 'date-fns';
import ClockIcon from '../../../icons/16x16/Calendar';
import DatePicker from '../../../new-ui/DatePicker';
import FormField from './FormField';

function _parseDate(date) {
  if (date instanceof Date) {
    return date;
  }
  return parseISO(date);
}

export default function DueDateField({ value, onSave, ...rest }) {
  return (
    <FormField icon={<ClockIcon color='secondary' />} >
      <DatePicker
        clearable
        placeholder='Set due date'
        value={_parseDate(value)}
        onBlurAndClose={onSave}
        secondaryPlaceholder
        {...rest}
      />
    </FormField>
  );
}
