import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import MessageDetailsContainer from './MessageDetailsContainer';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: theme.spacing(24),
    padding: theme.spacing(4, 2, 2),
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(7.5),
      borderRadius: theme.spacing(1)
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(7.5, 15)
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}))

export default function MessageDetailsLoading() {
  const classes = useStyles();
  return (
    <MessageDetailsContainer className={classes.container}>
      <Skeleton height={32} width='60%' className={classes.title} />
      <Skeleton height={16} />
      <Skeleton height={16} />
      <Skeleton height={16} />
    </MessageDetailsContainer>
  );
}
