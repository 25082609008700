import React from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  }
}));

function CustomizeFieldMenu({ onReorderField, children }) {
  const classes = useStyles();

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    // We don't have to check for equality of `droppableId` since we
    // have exactly one Droppable.
    if (sourceIndex === destinationIndex) return;

    // This is OK since we use the name (which is unique for each column)
    // to be its draggableId.
    if (onReorderField) {
      onReorderField(draggableId, destinationIndex);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='customize_task_field_menu'>
        {(provided) => (
          <MenuList
            variant='menu'
            className={classes.menu}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </MenuList>
        )}
      </Droppable>
    </DragDropContext>
  );
}

CustomizeFieldMenu.propTypes = {
  // Must be a list of `CustomizeFieldMenuItem`
  children: PropTypes.node,

  // Callback to execute once a field is reordered. The prototype of
  // this function is the following:
  //
  //   (fieldName, newIndex) => {...}
  onReorderField: PropTypes.func
};

export default CustomizeFieldMenu;
