import React from 'react';
import PropTypes from 'prop-types';
import UnsubscribeMeButton from '../ui/subscribers/UnsubscribeMeButton';
import useUnsubscribeMeMutation from './useUnsubscribeMeMutation';

function UnsubscribeMe({ eventId }) {
  const [unsubscribeMe] = useUnsubscribeMeMutation({
    variables: { eventId }
  });

  return <UnsubscribeMeButton onClick={unsubscribeMe} />;
}

UnsubscribeMe.propTypes = {
  eventId: PropTypes.string.isRequired
};

export default UnsubscribeMe;
