import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '../../icons/20x20/ChevronRight';

const useStyles = makeStyles((theme) => ({
  separator: {
    display: 'flex',
    margin: theme.spacing(0, 0.5),
    userSelect: 'none'
  }
}));

export default function Separator() {
  const classes = useStyles();
  return (
    <li aria-hidden={true} className={classes.separator}>
      <ChevronRightIcon color='disabled' />
    </li>
  );
}
