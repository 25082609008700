import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import Form from '../ui/message/MessageForm';
import SubjectInput from '../ui/message/MessageSubjectInput';
import TextEditor from '../ui/message/MessageTextEditor';
import FormActions from '../ui/message/MessageFormActions';
import Button from '../ui/button/Button';
import useUpdateMessageMutation from './useUpdateMessageMutation';

// TODO: What is the conversion failed?
// TODO: this is a duplicate from `MessageDetailsContent`.
function fromJsonTextToEditorState(jsonText) {
  if (!jsonText) {
    return EditorState.createEmpty();
  } else {
    const contentState = convertFromRaw(JSON.parse(jsonText));
    return EditorState.createWithContent(contentState);
  }
}

// Helper hook to redirect user onCancel or onFinish
function useRedirectFn() {
  const location = useLocation();
  const history = useHistory();

  // This is typically the url of the message details page.
  let url = '/';
  if (location.state && location.state.from) {
    url = location.state.from;
  }

  return () => history.push(url);
}

function EditMessageForm({ message }) {
  const [subject, setSubject] = React.useState(message.subject);
  const [editorState, setEditorState] = React.useState(
    fromJsonTextToEditorState(message.jsonText)
  );

  const handleSubjectChange = (event) => {
    const newSubject = event.target.value;
    setSubject(newSubject);
  };

  const redirectFn = useRedirectFn();

  //
  // GraphQL mutation.
  //

  const [updateMessage, { loading }] = useUpdateMessageMutation({
    variables: { messageId: message.id },
    onCompleted: (data) => {
      redirectFn();
    }
  });

  const handleUpdateMessage = (event) => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(' ');
    const jsonText = JSON.stringify(convertToRaw(currentContent));

    updateMessage({
      variables: {
        changes: { subject, plainText, jsonText }
      }
    });
  };

  return (
    <Form>
      <SubjectInput
        value={subject}
        onChange={handleSubjectChange}
      />
      <TextEditor
        editorState={editorState}
        onChange={setEditorState}
      />
      <FormActions>
        <Button
          size='small'
          disabled={loading}
          onClick={redirectFn}
        >
          Discard changes
        </Button>
        <Button
          size='small'
          color='primary'
          loading={loading}
          onClick={handleUpdateMessage}
        >
          Save changes
        </Button>
      </FormActions>
    </Form>
  );
}

EditMessageForm.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    jsonText: PropTypes.string
  }).isRequired
};

export default EditMessageForm;
