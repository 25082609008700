import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from './CurrentWorkspaceContext';
import { MEMBERSHIP_DETAILS_FRAGMENT, MEMBERSHIP_WORKSPACE_FRAGMENT }
from '../member/fragments';

const CURRENT_WORKSPACE_MEMBERSHIP_QUERY = gql`
  query GetCurrentWorkspaceMembership($currentWorkspaceId: ID!) {
    me {
      id
      workspaceMemberships(workspaceId: $currentWorkspaceId) {
        ...MembershipDetails
        ...MembershipWorkspace
      }
    }
  }
  ${MEMBERSHIP_DETAILS_FRAGMENT}
  ${MEMBERSHIP_WORKSPACE_FRAGMENT}
`;

function CurrentWorkspaceMembershipQuery(props) {
  const { children, onLoading, onError, ...rest } = props;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data, ...otherResults } = useQuery(
    CURRENT_WORKSPACE_MEMBERSHIP_QUERY,
    {
      variables: { currentWorkspaceId },
      ...rest
    }
  );

  if (loading) return onLoading ?  onLoading() : null;
  if (error) return onError ? onError() : null;
  return children(data.me, otherResults);
}

CurrentWorkspaceMembershipQuery.propTypes = {
  children: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

export default CurrentWorkspaceMembershipQuery;
