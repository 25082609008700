import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

function DeleteFolderConfirmText({ folderName }) {
  const name = (
    <Box
      component='span'
      color='text.primary'
      fontWeight={500}
    >
      {folderName}
    </Box>
  );

  return (
    <DialogContentText>
      Are you sure you want to delete the folder {name}? This cannot be
      undone.
    </DialogContentText>
  );
}

DeleteFolderConfirmText.propTypes = {
  folderName: PropTypes.string.isRequired
};

export default DeleteFolderConfirmText;
