import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import NameField from '../../ui/todo/details/NameField';
import DescField from '../../ui/todo/details/DescField';
import DueDateField from '../../ui/todo/details/DueDateField';
import AssigneeField from '../../ui/todo/details/AssigneeField';
import PriorityField from '../../ui/todo/details/PriorityField';
import ListField from './ListField';
import { diffTodoItemStates } from '../../todo/helpers';
import useUpdateTodoMutation from './hooks/useUpdateTodoMutation';

function TodoSection({ todo }) {
  // We maintain the inner state.
  const [state, setState] = React.useState(todo);

  // Sync inner state when external data changed.
  React.useEffect(() => {
    setState(todo);
  }, [todo]);


  //
  // Update state
  //

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setState((prev) => ({
      ...prev,
      name: newName
    }));
  };

  const handleDescChange = (event) => {
    const newDesc = event.target.value;
    setState((prev) => ({
      ...prev,
      description: newDesc
    }));
  };

  //
  // Save to backend.
  //

  const [updateTodo] = useUpdateTodoMutation({
    variables: { todoId: todo.id }
  });

  const handleUpdateTodo = (optRes) => {
    const optimisticResponse = optRes || state;
    const diff = diffTodoItemStates(todo, optimisticResponse);

    if (isEmpty(diff)) {
      return;
    }

    updateTodo({
      variables: {
        changes: diff
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTodoItem: {
          ...optimisticResponse
        }
      }
    });
  };

  const handleAssigneeChange = (newAssignee) => {
    const optimisticResponse = {...state, assignee: newAssignee};
    handleUpdateTodo(optimisticResponse);
  };

  const handleDueDateChange = (newDueDate) => {
    setState((prev) => ({
      ...prev,
      dueDate: newDueDate
    }));
  };

  const handlePriorityChange = (newPriority) => {
    const optimisticResponse = {...state, priority: newPriority};
    handleUpdateTodo(optimisticResponse);
  };

  return (
    <section style={{marginBottom: 16}}>
      <NameField
        value={state.name}
        onChange={handleNameChange}
        onBlur={() => handleUpdateTodo()}
      />

      <DueDateField
        value={state.dueDate}
        onChange={handleDueDateChange}
        onSave={() => handleUpdateTodo()}
      />

      <AssigneeField
        projectId={state.todoList.project.id}
        value={state.assignee}
        onChange={handleAssigneeChange}
      />

      <PriorityField
        value={state.priority}
        onChange={handlePriorityChange}
      />

      <DescField
        value={state.description || ''}
        onChange={handleDescChange}
        onBlur={() => handleUpdateTodo()}
      />

      <ListField
        todoId={todo.id}
        list={todo.todoList}
      />
    </section>
  );
}

TodoSection.propTypes = {
  todo: PropTypes.object.isRequired
};

export default TodoSection;
