import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../UserAvatar';
import Spinner from '../../ui/progress/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.spacing(2),
    position: 'relative',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    },
    '&$loading': {
      cursor: 'default'
    }
  },
  loading: {
    '& $spinner': {
      display: 'inline-block'
    },
    '& $avatar': {
      opacity: 0.2
    }
  },
  spinner: {
    cursor: 'default',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'none'
  },
  avatar: {}
}));

const FILE_EXT_WHITE_LIST = ['image/png', 'image/jpg', 'image/jpeg'];

const UserAvatarUploaderDropZone =
  React.forwardRef(function UserAvatarUploaderDropZone(props, ref) {
    const { onDrop, loading, user } = props;
    const classes = useStyles();

    return (
      <Dropzone
        ref={ref}
        accept={FILE_EXT_WHITE_LIST}
        onDrop={onDrop}
        disabled={loading}
      >
        {({getRootProps, getInputProps}) => (
          <div
            className={clsx(classes.root, {
              [classes.loading]: loading
            })}
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
            />
            <UserAvatar
              size='xlarge'
              className={classes.avatar}
              user={user}
            />
            <Spinner size={24} className={classes.spinner} />
          </div>
        )}
      </Dropzone>
    );
  });

UserAvatarUploaderDropZone.propTypes = {
  user: PropTypes.object.isRequired,
  onDrop: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

UserAvatarUploaderDropZone.defaultProps = {
  loading: false
};

export default UserAvatarUploaderDropZone;
