import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../form-dialog/StyledDialogTitle';
import Tabs, { tabValues } from '../form-dialog/Tabs';
import TabPanel from '../form-dialog/TabPanel';
import EventFormDialog from './EventFormDialog';
import TodoFormDialog from './TodoFormDialog';

function FormDialog({ projectId, date, time, onClose }) {
  const [open, setOpen] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState('event');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      onMouseDown={(event) => {
        // Stop mousedown event from propagating to Timeline Grid
        event.stopPropagation();
      }}
      onContextMenu={(event) => {
        // And also contextmenu event
        event.stopPropagation();
      }}
    >
      <DialogTitle title='Create new' onClose={handleClose}>
        <Tabs activeTab={activeTab} onChange={handleTabChange} />
      </DialogTitle>
      <Divider />
      {tabValues.map((value) => (
        <TabPanel key={value} value={value} activeTab={activeTab}>
          {(value === 'event') ? (
            <EventFormDialog
              projectId={projectId}
              date={date}
              time={time}
              onClose={handleClose}
            />
          ) : (
            <TodoFormDialog
              projectId={projectId}
              date={date}
              onClose={handleClose}
            />
          )}
        </TabPanel>
      ))}
    </Dialog>
  );
}

const TimeShape = PropTypes.shape({
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired
});

FormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  time: PropTypes.shape({
    start: TimeShape.isRequired,
    end: TimeShape.isRequired
  }).isRequired,

  onClose: PropTypes.func
};

export default FormDialog;
