import React from 'react';
import MenuContext from './MenuContext';
import StyledMenu from './StyledMenu';

export default function Menu(props) {
  const ctx = { closeMenu: props.onClose };

  return (
    <MenuContext.Provider value={ctx}>
      <StyledMenu {...props} />
    </MenuContext.Provider>
  );
}
