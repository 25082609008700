import React from 'react';
import PropTypes from 'prop-types';
import { isSameMonth, isSameDay, isValid } from 'date-fns';
import Label from './Label';
import Day from './Day';
import GridCell from './GridCell';
import GridRow from './GridRow';
import GridRowGroup from './GridRowGroup';
import getDaysGridForMonth from './getDaysGridForMonth';

function _isSameDay(date, dateToCompare) {
  if (!isValid(dateToCompare)) {
    return false;
  }
  return isSameDay(date, dateToCompare);
}

function MonthGrid({
  selectedDate,
  onChange,
  startOfMonth,
  startsWithSunday
}) {
  const daysOfWeek = React.useMemo(() => {
    const rest = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return startsWithSunday ? ['Su', ...rest] : [...rest, 'Su'];
  }, [startsWithSunday]);

  const daysGrid = React.useMemo(() => getDaysGridForMonth(startOfMonth, {
    startsWithSunday,
    numRows: 6 /* change to 'fit' is desired */
  }), [startOfMonth, startsWithSunday]);

  return (
    <div role='grid'>
      <GridRow>
        {daysOfWeek.map((d) => (
          <GridCell key={d} header>
            <Label color='secondary'>{d}</Label>
          </GridCell>
        ))}
      </GridRow>
      <GridRowGroup>
        {daysGrid.map((daysRow, index) => (
          <GridRow key={index}>
            {daysRow.map((date) => (
              <GridCell key={date}>
                <Day
                  onSelect={onChange}
                  selected={_isSameDay(date, selectedDate)}
                  date={date}
                  secondary={!isSameMonth(date, startOfMonth)}
                />
              </GridCell>
            ))}
          </GridRow>
        ))}
      </GridRowGroup>
    </div>
  );
}

MonthGrid.propTypes = {
  startOfMonth: PropTypes.instanceOf(Date).isRequired,
  startsWithSunday: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired
};

MonthGrid.defaultProps = {
  startsWithSunday: false
};

export default MonthGrid;
