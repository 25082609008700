import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import AccessDeniedDialog from './dialogs/WorkspaceAccessDeniedDialog';
import useWorkspaceDeletedSubscription
from './hooks/useWorkspaceDeletedSubscription';
import useMembershipDeactivatedSubscription
from '../member/hooks/useMembershipDeactivatedSubscription';
import useMembershipReactivatedSubscription
from '../member/hooks/useMembershipReactivatedSubscription';

function CurrentMemberAccessController({ currentMember, client })  {
  const { id: currentUserId, currentMembership } = currentMember;
  const { id: currentMembershipId, workspace } = currentMembership;

  const [deniedAccess, setDeniedAccess] = React.useState(false);
  const history = useHistory();

  const handleClose = () => {
    client.clearStore().then(() => {
      history.push('/');
    });
  };

  //
  // Document title.
  //

  const previousDocumentTitle = React.useMemo(() => {
    return document.title;
  }, []);

  React.useEffect(() => {
    if (deniedAccess) document.title = 'Access denied';
    else document.title = previousDocumentTitle;
  }, [deniedAccess, previousDocumentTitle]);

  //
  // Subscriptions.
  //

  // Subscribe to workspace deleted event.
  useWorkspaceDeletedSubscription({
    workspaceId: workspace.id,
    onSubscriptionData(data) {
      setDeniedAccess(true);
    }
  });

  // Subscribe to workspace membership deactivated event.
  useMembershipDeactivatedSubscription({
    membershipId: currentMembershipId,
    onSubscriptionData({ subscriptionData }) {
      if (!subscriptionData) return;
      const { deactivatedBy } = subscriptionData.data.membershipDeactivated;
      if (deactivatedBy.id !== currentUserId) setDeniedAccess(true);
    }
  });

  // Subscribe to workspace membership reactivated event.
  useMembershipReactivatedSubscription({
    membershipId: currentMembershipId,
    onSubscriptionData({ subscriptionData }) {
      if (!subscriptionData) return;
      const { active } = subscriptionData.data.membershipReactivated;
      if (active) setDeniedAccess(false);
    }
  });

  return <AccessDeniedDialog open={deniedAccess} onClose={handleClose} />;
}

CurrentMemberAccessController.propTypes = {
  currentMember: PropTypes.object.isRequired
};

export default withApollo(CurrentMemberAccessController);
