import React from 'react';
import HeaderCell from './HeaderCell';
import { TODO_SHEET_THEME } from './theme';

export default function StyledHeader({ children }) {
  return (
    <thead>
      <tr>
        <HeaderCell
          contentFullWidth
          contentPaddingLeft={false}
          resizable={false}
          borderRight={false}
          style={{width: TODO_SHEET_THEME.dragHandleCellWidth}}
        />

        {children}

        <HeaderCell
          contentFullWidth
          resizable={false}
          borderRight={false}
        />
      </tr>
    </thead>
  );
}
