import React from 'react';
import PropTypes from 'prop-types';
import CurrentUserQuery from '../../account/CurrentUserProfileQuery';
import AddCurrentUser from './AddCurrentUser';
import RemoveCurrentUser from './RemoveCurrentUser';

function FollowOrUnfollowButton({ fileId, followers }) {
  return (
    <CurrentUserQuery>
      {(user) => {
        const found = followers.some(({ id }) => id === user.id);
        return found ? (
          <RemoveCurrentUser fileId={fileId} />
        ) : (
          <AddCurrentUser fileId={fileId} />
        );
      }}
    </CurrentUserQuery>
  );
}

FollowOrUnfollowButton.propTypes = {
  fileId: PropTypes.string.isRequired,
  followers: PropTypes.array.isRequired
};

export default FollowOrUnfollowButton;
