import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/todo/ToolbarButton';
import FilterIcon from '../icons/12x12/Filter';
import CustomizeFieldPopover from '../ui/todo/CustomizeFieldPopover';
import Menu from '../ui/todo/CustomizeFieldMenu';
import CustomizeFieldMenuItem from '../ui/todo/CustomizeFieldMenuItem';
import useToggleFieldMutation from './hooks/useToggleFieldMutation';
import useReorderFieldMutation from './hooks/useReorderFieldMutation';
import useLazyTodoFieldsQuery, { TODO_FIELDS_QUERY}
from './hooks/useLazyTodoFieldsQuery';
import { fromFieldNameToFieldLabel, reorderFields } from './helpers';

function CustomizeFieldMenu({ projectId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [
    fetchTodoFields,
    { called, loading, data }
  ] = useLazyTodoFieldsQuery({ projectId });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    fetchTodoFields();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const fields = data ? data.project.todoSheetColumns : [];

  const fieldsWithLabels = fields.map((field) => ({
    ...field,
    label: fromFieldNameToFieldLabel(field.name)
  }));

  //
  // TODO: These shit shouldn't be here!
  // Mutation.
  //

  const [toggleField] = useToggleFieldMutation();
  const [reorderField] = useReorderFieldMutation();

  const handleReorderField = (fieldName, newIndex) => {
    reorderField({
      variables: { projectId, field: fieldName, index: newIndex },
      optimisticResponse: {
        __typename: 'Mutation',
        reorderTodoSheetColumns: reorderFields(fields, {
          name: fieldName,
          index: newIndex
        })
      },
      update: (client, { data: { reorderTodoSheetColumns }}) => {
        const oldData = client.readQuery({
          query: TODO_FIELDS_QUERY,
          variables: { projectId }
        });

        client.writeQuery({
          query: TODO_FIELDS_QUERY,
          variables: { projectId },
          data: {
            project: {
              ...oldData.project,
              todoSheetColumns: reorderTodoSheetColumns
            }
          }
        });
      }
    });
  };

  const handleToggleField = (fieldName, show) => {
    toggleField({
      variables: { projectId, field: fieldName, show },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTodoSheetColumns: fields.map((field) => {
          if (field.name !== fieldName) return field;
          return {...field, show: show }
        })
      },
      update: (client, { data: { updateTodoSheetColumns }}) => {
        const oldData = client.readQuery({
          query: TODO_FIELDS_QUERY,
          variables: { projectId }
        });

        client.writeQuery({
          query: TODO_FIELDS_QUERY,
          variables: { projectId },
          data: {
            project: {
              ...oldData.project,
              todoSheetColumns: updateTodoSheetColumns
            }
          }
        });
      }
    });
  };

  return (
    <div>
      <Button
        color={open ? 'primaryDeemphasized' : 'secondary'}
        startIcon={ <FilterIcon color='secondary' /> }
        onClick={handleOpen}
      >
        Fields
      </Button>

      <CustomizeFieldPopover
        loading={called && loading}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Menu onReorderField={handleReorderField}>
          {fieldsWithLabels.map(({ name, label, show }, index) => {
            // We don't want to customize task_name column.
            if (name === 'task_name') return null;

            return (
              <CustomizeFieldMenuItem
                key={name}
                index={index}
                name={name}
                label={label}
                show={show}
                onChange={handleToggleField}
              />
            );
          })}
        </Menu>
      </CustomizeFieldPopover>
    </div>
  );
}

CustomizeFieldMenu.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default CustomizeFieldMenu;
