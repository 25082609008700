import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { PROJECT_ACTIVITY_DETAILS } from './fragments';

const PROJECT_ACTIVITIES_QUERY = gql`
  query ProjectActivities($projectId: ID!, $first: Int!, $after: String) {
    project(projectId: $projectId) {
      id
      activities(first: $first, after: $after)
      @connection(key: "activities") {
        edges {
          node { ...ProjectActivityDetails }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
  ${PROJECT_ACTIVITY_DETAILS}
`;

export default function useActivitiesQuery(options) {
  return useQuery(PROJECT_ACTIVITIES_QUERY, options);
}
