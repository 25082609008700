import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

function ArchiveConfirmText({ projectName }) {
  const name = (
    <Box
      component='span'
      color='text.primary'
      fontWeight={500}
    >
      {projectName}
    </Box>
  );

  return (
    <div>
      <DialogContentText>
        Archive if this project is paused, done, or canceled. It will be
        kept safely for your records, but no longer shown in team's
        active project list or search results. You can unarchive it at
        any time later.
      </DialogContentText>

      <DialogContentText style={{marginBottom: 0}}>
        Are you sure you want to archive {name}?
      </DialogContentText>
    </div>
  );
}

ArchiveConfirmText.propTypes = {
  projectName: PropTypes.string.isRequired
};

export default ArchiveConfirmText;
