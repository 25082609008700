import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: '1.125rem',
    fontWeight: 600,
    '&::placeholder': {
      fontSize: '1.125rem'
    }
  },
  ext: {
    color: theme.palette.text.secondary,
    fontSize: '1.125rem',
    fontWeight: 600
  }
}));

const RETURN_KEYCODE = 13;

function preventDefaultWhenReturnKeyPressed(event) {
  const key = event.which || event.keyCode;
  if (key === RETURN_KEYCODE) {
    event.preventDefault();
  }
}

export default function UploadNameInput({ ext, ...rest }) {
  const classes = useStyles();
  return (
    <TextField
      autoFocus
      fullWidth
      multiline
      rowsMax={4}
      type='text'
      autoComplete='off'
      placeholder='File name'
      InputProps={{
        endAdornment: (
          <InputAdornment
            disableTypography
            position='end'
            className={classes.ext}
          >
            {ext}
          </InputAdornment>
        ),
        disableUnderline: true,
        classes: {
          input: classes.input
        }
      }}
      onKeyDown={preventDefaultWhenReturnKeyPressed}
      {...rest}
    />
  );
}
