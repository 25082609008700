import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

export default function MakeFolderForm(props) {
  const classes = useStyles();

  return (
    <form
      className={classes.form}
      onSubmit={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
