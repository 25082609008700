import React from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../ui/page/Section';
import SectionHeader from '../../ui/page/SectionHeader';
import SectionTitle from '../../ui/page/SectionTitle';
import TeamActivitiesQuery from './TeamActivitiesQuery';
import ActivitiesTimelineSkeleton from './ActivitiesTimelineSkeleton';
import NoActivitiesFound from './NoActivitiesFound';
import ActivitiesTimeline from './ActivitiesTimeline';

function Activities() {
  const { teamId } = useParams();

  const renderQueryResults = (activities, otherQueryResults) => {
    if (activities.length === 0) return <NoActivitiesFound />;
    return <ActivitiesTimeline activities={activities} />;
  };

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Activities</SectionTitle>
      </SectionHeader>
      <TeamActivitiesQuery
        teamId={teamId}
        onLoading={() => <ActivitiesTimelineSkeleton /> }
      >
        {renderQueryResults}
      </TeamActivitiesQuery>
    </Section>
  );
}

export default Activities;
