import React from 'react';
import PropTypes from 'prop-types';
import SubscribersQuery from './SubscribersQuery';
import SubscribersBarWithData from './SubscribersBarWithData';
import SubscribersChangedSubscription
from './SubscribersChangedSubscription';

function SubscribersBar({ projectId, eventId }) {
  return (
    <SubscribersQuery
      eventId={eventId}
      fetchPolicy='network-only'
    >
      {(subscribers, { subscribeToMore }) => (
        <React.Fragment>
          <SubscribersBarWithData
            projectId={projectId}
            eventId={eventId}
            subscribers={subscribers}
          />

          <SubscribersChangedSubscription
            eventId={eventId}
            onSubscribersChanged={subscribeToMore}
          />
        </React.Fragment>
      )}
    </SubscribersQuery>
  );
}

SubscribersBar.propTypes = {
  projectId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired
};

export default SubscribersBar;
