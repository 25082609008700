import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteCheckbox from './ProjectHeaderFavoriteCheckbox';
import InfoButton from './ProjectHeaderInfoButton';
import ToggleFullscreenButton from './ProjectHeaderToggleFullscreenButton';
import MoreActionMemu from './ProjectHeaderMoreActionMenu';

const useStyles = makeStyles((theme) => ({
  projectToolbar: {
    flex: '0 0 auto',
    display: 'flex'
  },
  divider: {
    margin: theme.spacing(0, 1)
  }
}));

function ProjectHeaderProjectToolbar({ id, color, pinned, archived }) {
  const classes = useStyles();

  return (
    <div className={classes.projectToolbar}>
      <MoreActionMemu
        projectId={id}
        pinned={pinned}
        archived={archived}
      />

      <InfoButton projectId={id} />

      <FavoriteCheckbox
        projectId={id}
        color={color}
        checked={pinned}
      />

      <Divider
        flexItem
        orientation='vertical'
        className={classes.divider}
      />

      <ToggleFullscreenButton />
    </div>
  );
}

ProjectHeaderProjectToolbar.propTypes = {
  // The project's ID.
  id: PropTypes.string.isRequired,

  // The project's color
  color: PropTypes.string,

  // Was the project pinned or not?
  pinned: PropTypes.bool.isRequired,

  // Was the project archived or not?
  archived: PropTypes.bool.isRequired
};

export default ProjectHeaderProjectToolbar;
