import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../button/IconButton';

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    minWidth: theme.spacing(4)
  }
}))

function HeaderToolbarButton(props) {
  const classes = useStyles();
  return (
    <IconButton
      square
      background={false}
      color='secondary'
      className={classes.toolbarButton}
      {...props}
    />
  );
}

export default HeaderToolbarButton;
