import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import DialogContext from '../../ui/dialog/DialogContext';
import LinearStepper from '../../ui/stepper/LinearStepper';
import LinearStep from '../../ui/stepper/LinearStep';
import DialogTitle from '../../ui/dialog/MultiStepTitleContainer';
import DialogContent from '../../ui/dialog/MultiStepContentContainer';
import FormDialogTitle from './FormDialogTitle';
import FormDialogContent from './FormDialogContent';
import {
  secondStepDialog,
  SecondStepDialogTitle,
  SecondStepDialogContent
} from './second_step_dialog';

const TRANSITION_DURATION = '.2s';

function CreateProjectDialog({ teamId }) {
  const { closeDialog } = React.useContext(DialogContext);

  // If no `teamId` was provided, user will be presented with a
  // list of available teams to pick first, which means `PICK_TEAM`
  // dialog will be rendered initially.
  const [activeStep, setActiveStep] = React.useState(teamId ? 0 : 1);

  // Open `PICK_TEAM` dialog if no `teamId` was provided.
  const [secondDialog, setSecondDialog] =
    React.useState(teamId ? null : secondStepDialog.PICK_TEAM);

  // Keep track of the ID of the chosen team.
  const [chosenTeamId, setChosenTeamId] = React.useState(teamId);

  const [firstStepActionsContainer, setFirstStepActionsContainer] =
    React.useState(null);

  // Make a transition to `PICK_TEAM` dialog when pick team button clicked.
  const handlePickTeam = () => {
    setSecondDialog(secondStepDialog.PICK_TEAM);
    setActiveStep(1);
  };

  // Make a trasition to `READ_INFO` dialog when info button clicked.
  const handleInfoClick = () => {
    setSecondDialog(secondStepDialog.READ_INFO);
    setActiveStep(1);
  };

  // Go back to first step.
  // IMPORTANT NODE: we don't reset the secondDialog here.
  const handleGoback = () => {
    setActiveStep(0);
  };

  // Another team was chosen!
  const handleChosenTeamIdChange = (newTeamId) => {
    setChosenTeamId(newTeamId);
    setActiveStep(0);
  };

  // DOM node to mount first step dialog actions into.
  const setFirstStepActionsContainerRef = (elem) => {
    setFirstStepActionsContainer(elem);
  };

  // No divider for `READ_INFO` dialog.
  const hasDivider = (activeStep === 0) ||
                     (secondDialog !== secondStepDialog.READ_INFO);

  return (
    <React.Fragment>
      <DialogTitle>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <FormDialogTitle
              onClose={closeDialog}
              onInfo={handleInfoClick}
            />
          </LinearStep>

          <LinearStep>
            <SecondStepDialogTitle
              dialog={secondDialog}
              onClose={closeDialog}
              onGoBack={handleGoback}
            />
          </LinearStep>
        </LinearStepper>
      </DialogTitle>

      {hasDivider && <Divider />}

      <DialogContent>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <FormDialogContent
              teamId={chosenTeamId}
              onPickTeam={handlePickTeam}
              actionsContainer={firstStepActionsContainer}
              onClose={closeDialog}
            />
          </LinearStep>

          <LinearStep>
            <SecondStepDialogContent
              dialog={secondDialog}
              teamId={chosenTeamId}
              onTeamIdChange={handleChosenTeamIdChange}
            />
          </LinearStep>
        </LinearStepper>
      </DialogContent>

      {/* placeholder for dialog actions */}
      <div>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep ref={setFirstStepActionsContainerRef} />
          <LinearStep />
        </LinearStepper>
      </div>
    </React.Fragment>
  );
}

CreateProjectDialog.propTypes = {
  teamId: PropTypes.string
};

export default CreateProjectDialog;
