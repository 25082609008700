import React from 'react';
import PropTypes from 'prop-types';
import useCreateEvent from '../mutations/useCreateEvent';
import { generateNewEventId } from '../helpers';
import { writeEntryToCache } from '../schedulesCacheHelpers';
import EventFormDialog from './EventFormDialog';

function CreateEventFormDialog({ projectId, onClose, ...rest }) {
  const [createEvent] = useCreateEvent({
    variables: { projectId },
    update: (cache, { data: { createEvent: newEvent }}) => {
      writeEntryToCache(cache, { projectId, entry: newEvent });
    }
  });

  const handleSubmit = (data, optimisticResponse) => {
    onClose();

    createEvent({
      variables: { attrs: data },
      optimisticResponse: {
        createEvent: {
          __typename: 'Event',
          id: generateNewEventId(),
          ...optimisticResponse
        }
      }
    });
  };

  return (
    <EventFormDialog
      projectId={projectId}
      onSubmit={handleSubmit}
      onClose={onClose}
      {...rest}
    />
  );
}

CreateEventFormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CreateEventFormDialog;
