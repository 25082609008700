import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../ui/button/Button';
import Toolbar from '../../ui/file/Toolbar';

const useStyles = makeStyles((theme) => ({
  toolbarText: {
    flexGrow: 1
  },
  toolbarTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.3
  },
  toolbarButtonContainer: {
    flex: '0 0 auto',
    '& > *:not(:last-child)': {
      marginRight: 8
    }
  },
  toolbarButton: {
    fontSize: 14,
    padding: '4px 12px',
    borderRadius: 4
  }
}));

function UploadTableToolbar({
  numFiles,
  numBytes: rawNumBytes,
  numSelected,
  onRemoveSelected,
  onOpenFilePrompt
}) {
  const classes = useStyles();
  const numBytes = filesize(rawNumBytes, {round: 1, base: 10});
  const subtitle = numFiles ? `(${numFiles} total, ${numBytes})` : '(0)';

  return (
    <Toolbar>
      <div className={classes.toolbarText}>
        <Typography variant='h6' className={classes.toolbarTitle}>
          Files
          {' '}
          <Box component='span' color='text.secondary'>
            {subtitle}
          </Box>
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          All files in this table will be uploaded.
        </Typography>
      </div>
      <div className={classes.toolbarButtonContainer}>
        <Button
          size='small'
          className={classes.toolbarButton}
          disabled={!numSelected}
          onClick={onRemoveSelected}
        >
          Remove
        </Button>
        <Button
          size='small'
          className={classes.toolbarButton}
          onClick={onOpenFilePrompt}
        >
          Add files
        </Button>
      </div>
    </Toolbar>
  );
}

UploadTableToolbar.propTypes = {
  numFiles: PropTypes.number.isRequired,
  numBytes: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRemoveSelected: PropTypes.func,
  onOpenFilePrompt: PropTypes.func
};

UploadTableToolbar.defaultProps = {
  numFiles: 0,
  numBytes: 0,
  numSelected: 0
};

export default UploadTableToolbar;
