import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconSprites from './icons.png';

const ICON_COUNT = 40;
const ICON_SIZE = 128;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    display: 'inline-block',
    cursor: 'inherit',
    userSelect: 'none',
    pointerEvents: 'none',
    backgroundImage: `url(${IconSprites})`,
    backgroundRepeat: 'no-repeat',
    width: ICON_SIZE,
    height: ICON_SIZE,
    backgroundSize: `${ICON_SIZE}px ${ICON_SIZE * ICON_COUNT}px`
  }
}));

export default function Base({ className, ...rest }) {
  const classes = useStyles();
  const finalClassName = clsx(classes.root, className);
  return <i className={finalClassName} {...rest} />;
}
