import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import NotFound from '../oops/SectionNotFound';

const UPDATE_MESSAGE_QUERY = gql`
  query GetProjectMessageForUpdating($projectId: ID!, $messageId: ID!) {
    projectMessage(projectId: $projectId, messageId: $messageId) {
      id
      subject
      jsonText
    }
  }
`;

function UpdateMessageQuery({
  projectId,
  messageId,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    UPDATE_MESSAGE_QUERY,
    {
      variables: { projectId, messageId },
      ...otherQueryOptions
    }
  );

  if (loading) { return onLoading ? onLoading() : null; }
  if (error) { return onError ? onError(error) : <Error error={error} />; }
  if (!data || !data.projectMessage) { return <NotFound />; }
  return children(data.projectMessage, otherQueryResults);
}

UpdateMessageQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default UpdateMessageQuery;
