import AviIcon128 from '../icons/128/Avi';
import MovIcon128 from '../icons/128/Mov';
import Mp4Icon128 from '../icons/128/Mp4';
import WmvIcon128 from '../icons/128/Wmv';
import MkvIcon128 from '../icons/128/Mkv';
import MpgIcon128 from '../icons/128/Mpg';
import OggVideoIcon128 from '../icons/128/OggVideo';
import FileMovieIcon from '../../icons/FileMovie';

export const VideoMimeType = Object.freeze({
  'video/mpeg': {
    description: 'MPEG Video',
    Icon128: MpgIcon128,
    Icon24: FileMovieIcon
  },
  'video/mp4': {
    description: 'MPEG-4 Video',
    Icon128: Mp4Icon128,
    Icon24: FileMovieIcon
  },
  'video/ogg': {
    description: 'HTML5 Video (Ogg)',
    Icon128: OggVideoIcon128,
    Icon24: FileMovieIcon
  },
  'video/webm': {
    description: 'HTML5 Video (WebM)',
    Icon128: null,
    Icon24: FileMovieIcon
  },
  'video/quicktime': {
    description: 'QuickTime Video',
    Icon128: MovIcon128,
    Icon24: FileMovieIcon
  },
  'video/x-msvideo': {
    description: 'AVI Video',
    Icon128: AviIcon128,
    Icon24: FileMovieIcon
  },
  'video/x-matroska': {
    description: 'MKV Video',
    Icon128: MkvIcon128,
    Icon24: FileMovieIcon
  },
  'video/x-ms-wmv': {
    description: 'Windows Media',
    Icon128: WmvIcon128,
    Icon24: FileMovieIcon
  }
});
