import listTimesOfDay from '../../../new-ui/datetime-picker/listTimesOfDay';

// The size (in pixels and in minutes) of each timeline interval (i.e., the
// distance between two consecutive timeline points).
export const timelineIntervalSize = Object.freeze({
  // If you change this value, you have to also go to
  // `./TimeSpecificScheduleChip` to change the font-size and
  // line-height there. And also a bunch of other places
  // ( that i don't remember!!! )
  PIXELS: 10,


  MINUTES: 15
});

// The timeline
export const TIMELINE = listTimesOfDay({
  step: timelineIntervalSize.MINUTES
});

export const CHIP_DEFAULT_COLOR = '#039be5';
