import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { COMMENT_FILES } from './fragments';

const SOME_TODO_COMMENT_UPDATED_SUBSCRIPTION = gql`
  subscription OnSomeTodoCommentUpdated($todoId: ID!) {
    todoCommentUpdated(todoId: $todoId) {
      id
      text
      updatedAt
      ...CommentFiles
    }
  }
  ${COMMENT_FILES}
`;

function SomeCommentUpdatedSubscriber({ todoId }) {
  useSubscription(SOME_TODO_COMMENT_UPDATED_SUBSCRIPTION, {
    variables: { todoId }
  });
  return null;
}

SomeCommentUpdatedSubscriber.propTypes = {
  todoId: PropTypes.string.isRequired
};

export default SomeCommentUpdatedSubscriber;
