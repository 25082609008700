import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '../../ui/button/Button';
import Header from './ui/header/Header';
import Toolbar from './ui/header/Toolbar';
import CloseButton from './ui/header/ToolbarCloseButton';
import Content from './ui/content/Content';

function EventNotFound({ onClose }) {
  return (
    <>
      <Header>
        <Toolbar>
          <CloseButton onClick={onClose} />
        </Toolbar>
      </Header>
      <Content>
        <Typography variant='h6' gutterBottom>
          Sorry, we can't seem to find the event you're looking for.
        </Typography>
        <DialogContentText style={{marginBottom: 40}}>
          This could be because the event you're looking for has been
          deleted or you don't have access to it. Or it could be because
          you clicked a broken link.
        </DialogContentText>
        <Button
          fullWidth
          size='small'
          color='primary'
          onClick={onClose}
        >
          Close
        </Button>
      </Content>
    </>
  );
}

EventNotFound.propTypes = {
  onClose: PropTypes.func
};

export default EventNotFound;
