import React from 'react';
import PropTypes from 'prop-types';
import List from '../../ui/dialog/List';
import FolderPickerItem from './FolderPickerItem';
import FolderPickerLoading from './FolderPickerLoading';
import ListFoldersInProjectQuery from '../ListFoldersInProjectQuery';

function appendRootFolderToQueryResult(queryResult) {
  const rootFolder = {id: 'root', name: 'Current project'};
  return [...queryResult, rootFolder];
}

function moveSelectedFolderToFront(folderList, selectedFolderId) {
  const selectedFolderIndex = folderList.findIndex(({ id }) => (
    id === selectedFolderId
  ));

  if (selectedFolderIndex <= -1 || folderList.length <= 1) {
    return folderList;
  }

  let copy = [...folderList];
  const [selectedFolder] = copy.splice(selectedFolderIndex, 1);
  copy.splice(0, 0, selectedFolder);
  return copy;
}

function FolderPicker({ projectId, value, onChange }) {

  return (
    <ListFoldersInProjectQuery
      fetchPolicy='network-only'
      projectId={projectId}
      onLoading={() =>  <FolderPickerLoading /> }
    >
      {(folders) => {
        const augmentedFolders = appendRootFolderToQueryResult(folders)
        const sortedFolders =
          moveSelectedFolderToFront(augmentedFolders, value.id);

        return (
          <List>
            {sortedFolders.map((folder) => (
              <FolderPickerItem
                key={folder.id}
                primary={folder.name}
                selected={folder.id === value.id}
                onClick={(event) => {
                  if (onChange) { onChange(event, folder); }
                }}
              />
            ))}
          </List>
        );
      }}
    </ListFoldersInProjectQuery>
  );
}

FolderPicker.propTypes = {
  projectId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func
};

export default FolderPicker;
