import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const FILE_FOLLOWERS_CHANGED_SUBSCRIPTION = gql`
  subscription OnFileFollowersChanged($fileId: ID!) {
    fileFollowersChanged(fileId: $fileId) {
      id
      followers {
        id
        username
        email
        avatar
      }
    }
  }
`;

function FollowersChangedSubscriber({
  fileId,
  subscribeToFollowersChanged
}) {
  React.useEffect(() => {
    const unsubscribe = subscribeToFollowersChanged({
      document: FILE_FOLLOWERS_CHANGED_SUBSCRIPTION,
      variables: { fileId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newFollowers =
          subscriptionData.data.fileFollowersChanged.followers;

        return {
          project: {
            ...prev.project,
            upload: {
              ...prev.project.upload,
              followers: newFollowers
            }
          }
        };
      }
    });

    return () => unsubscribe();
  }, [fileId, subscribeToFollowersChanged]);

  return null;
}


FollowersChangedSubscriber.propTypes = {
  fileId: PropTypes.string.isRequired,
  subscribeToFollowersChanged: PropTypes.func.isRequired
};

export default FollowersChangedSubscriber;
