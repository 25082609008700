import React from 'react';
import ListItem from '../../../ui/file/list/UploadListItem';
import ActionDelete from '../../../ui/file/list/ActionDelete';
import { StatusCode } from '../../../ui/file/list/UploadStatus';
import UploadStatus from './UploadStatus';

function UploadListItem({ item, onUploadStatusChange, onUploadDelete }) {
  const { id, projectId, file, statusCode } = item;

  const handleDelete = React.useCallback(() => {
    if (statusCode === StatusCode.SUCCEEDED) {
      // TODO: Handle remove upload from AWS S3!
    }
    onUploadDelete(id);
  }, [id, statusCode, onUploadDelete]);

  return (
    <ListItem
      file={file}
      actions={[
        <UploadStatus
          projectId={projectId}
          file={file}
          uploadId={id}
          statusCode={statusCode}
          onStatusChange={onUploadStatusChange}
        />,
        <ActionDelete onClick={handleDelete} />
      ]}
    />
  );
}

export default UploadListItem;
