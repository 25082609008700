import React from 'react';
import { useHistory } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import MenuItem from '../../ui/menu/MenuItem';
import ExitIcon from '../../ui/icons/DoorExit';
import IconAvatar from '../../ui/avatar/IconAvatar';

const LogoutMenuItem = React.forwardRef((props, ref) => {
  const { client, ...rest } = props;
  const history = useHistory();

  const handleClick = () => {
    localStorage.removeItem('auth-token');
    client.clearStore().then(() => {
      history.push('/sign-in');
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Log out'
      avatar={ <IconAvatar icon={ <ExitIcon /> } /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

export default withApollo(LogoutMenuItem);
