import React from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import NextIcon from '../../../icons/20x20/ArrowForwardThin';
import { FILTER_PRIMARY } from '../../icons/Base';

const useStyles = makeStyles((theme) => ({
  nextFab: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& $nextIcon': {
        filter: FILTER_PRIMARY
      }
    }
  },
  nextIcon: {}
}));

export default function NextFab(props) {
  const classes = useStyles();
  return (
    <Fab
      size='small'
      aria-label='previous'
      className={classes.nextFab}
      {...props}
    >
      <NextIcon color='secondary' className={classes.nextIcon} />
    </Fab>
  );
}
