import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';
import { removeEntryFromCache } from '../schedulesCacheHelpers';

const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId) {
      id
      startsAt
      endsAt
    }
  }
`;

export default function useDeleteEvent(projectId) {
  const [mutate, mutationResults] = useMutation(DELETE_EVENT_MUTATION, {
    loadingMessage: 'Deleting...',
    successMessage: 'Event deleted',
    update: (cache, { data: { deleteEvent: entry }}) => {
      removeEntryFromCache(cache, { projectId, entry });
    }
  });

  const mutateFn = useCallback((event) => {
    mutate({
      variables: { eventId: event.id },
      optimisticResponse: {
        deleteEvent: {
          ...event
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
