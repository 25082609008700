import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import CurrentWorkspaceContext from '../../workspace/CurrentWorkspaceContext';

const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeam(
    $workspaceId: ID!,
    $name: String!,
    $description: String
  ) {
    createTeam(
      workspaceId: $workspaceId,
      name: $name,
      description: $description
    ) {
      id
    }
  }
`;

export default function useCreateTeamMutation(options) {
  const { variables, onCompleted: onFinished, ...rest } = options;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const history = useHistory();

  const redirectToTeamPage = (teamId) => {
    history.push(`/workspaces/${currentWorkspaceId}/teams/${teamId}`);
  };

  return useMutation(CREATE_TEAM_MUTATION, {
    variables: {
      ...variables,
      workspaceId: currentWorkspaceId
    },
    onCompleted(data) {
      onFinished && onFinished(data);
      redirectToTeamPage(data.createTeam.id);
    },
    ...rest
  });
}
