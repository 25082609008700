import React from 'react';
import PropTypes from 'prop-types';
import LazyUserSearch from '../../autocomplete/LazyUserSearch';
import useSearchMembers
from '../hooks/useLazySearchNewMembersForProjectQuery';

function AddMembersInput({ projectId, ...rest }) {
  const [criteria, setCriteria] = React.useState({matching: ''});
  const [searchResults, setSearchResults] = React.useState([]);

  const handleMatchingChange = (newMatching) => {
    setCriteria({matching: newMatching});
  };

  const [searchNewMembersForProject, { loading }] = useSearchMembers({
    projectId: projectId,
    onCompleted(data) {
      setSearchResults(data.newMembersForProject);
    }
  });

  React.useEffect(() => {
    if (criteria.matching) {
      searchNewMembersForProject({
        variables: {filter: criteria}
      });
    }
  }, [criteria, searchNewMembersForProject]);

  return (
    <LazyUserSearch
      InputProps={{
        autoFocus: true,
        placeholder: 'Search by name or email',
        helperText: 'Search for active, existing team members.'
      }}
      onMatchingChange={handleMatchingChange}
      loading={loading}
      searchResults={searchResults}
      {...rest}
    />
  );
}

AddMembersInput.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default AddMembersInput;
