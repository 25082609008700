import React from 'react';
import PropTypes from 'prop-types';
import { isAfter } from 'date-fns';
import ClockIcon from '../../icons/16x16/Clock';
import compareTime from '../../new-ui/datetime-picker/compareTime';
import formatTimeOfDay from '../../new-ui/datetime-picker/formatTimeOfDay';
import addTime from '../../new-ui/datetime-picker/addTime';
import DatePicker from '../../new-ui/DatePicker';
import TimePicker from '../../new-ui/TimePicker';
import FormField from './FormField';

function subTime(t1, t2) {
  const t1Minutes = t1.hours * 60 + t1.minutes;
  const t2Minutes = t2.hours * 60 + t2.minutes;
  const deltaMinutes = t1Minutes - t2Minutes;

  if (deltaMinutes <= 0) {
    return formatTimeOfDay({ hours: 0, minutes: 0 });
  }

  const hours = ~~(deltaMinutes / 60);
  const minutes = deltaMinutes % 60;

  return formatTimeOfDay({ hours, minutes });
}

function DateTimePicker({ startDate, startTime, endTime, onChange }) {
  const handleDateChange = React.useCallback((newDate) => {
    onChange((prevData) => {
      const newStartDate = newDate;
      let newEndDate = prevData.endDate;

      if (isAfter(newStartDate, newEndDate)) {
        newEndDate = newStartDate;
      }

      return {
        ...prevData,
        startDate: newStartDate,
        endDate: newEndDate
      };
    });
  }, [onChange]);

  const handleStartTimeChange = React.useCallback((newStartTime) => {
    onChange((prevData) => {
      const newStart = newStartTime;
      let newEnd = prevData.endTime;

      if (compareTime(newStart, newEnd) >= 0) {
        newEnd = addTime(newStart, { hours: 1, minutes: 0});
      }

      return {
        ...prevData,
        startTime: newStart,
        endTime: newEnd
      };
    });
  }, [onChange]);

  const handleEndTimeChange = React.useCallback((newEndTime) => {
    onChange((prevData) => {
      let newStart = prevData.startTime;
      const newEnd = newEndTime;

      if (compareTime(newStart, newEnd) >= 0) {
        newStart = subTime(newEnd, { hours: 1, minutes: 0});
      }

      return {
        ...prevData,
        startTime: newStart,
        endTime: newEnd
      };
    });
  }, [onChange]);

  return (
    <FormField label={<ClockIcon />}>
      <DatePicker
        clearable={false}
        placeholder='Start date'
        value={startDate}
        onChange={handleDateChange}
      />
      <TimePicker
        clearable={false}
        placeholder='00:00'
        value={startTime}
        onChange={handleStartTimeChange}
      />
      —
      <TimePicker
        clearable={false}
        placeholder='00:00'
        error={compareTime(startTime, endTime) >= 0}
        value={endTime}
        onChange={handleEndTimeChange}
      />
    </FormField>
  );
}

DateTimePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateTimePicker;
