import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    '&::placeholder': {
      fontSize: '1rem'
    }
  }
}));

export default function ProjectDescInput(props) {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      multiline
      rows={8}
      type='text'
      placeholder="Add some notes to this file"
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.input
        }
      }}
      {...props}
    />
  );
}
