import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';
import { EVENT_DETAILS, EVENT_PARTICIPANTS, EVENT_REMINDERS }
from '../fragments';

const EVENT_DETAILS_QUERY = gql`
  query GetEventDetails($eventId: ID!) {
    event(id: $eventId) {
      ...EventDetails
      ...EventParticipants
      ...EventReminders

      project {
        id
        name
      }
    }
  }
  ${EVENT_DETAILS}
  ${EVENT_PARTICIPANTS}
  ${EVENT_REMINDERS}
`;

function EventDetailsQuery({
  eventId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    EVENT_DETAILS_QUERY,
    {
      variables: { eventId },
      ...otherQueryProps
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.event, otherQueryResults);
}

EventDetailsQuery.propTypes = {
  eventId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default EventDetailsQuery;
