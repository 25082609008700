import React from 'react';
import MuiChip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '../../icons/12x12/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
    fontSize: '0.875rem',
    borderRadius: 6,
    backgroundColor: theme.palette.button.secondary.main,
    color: theme.palette.button.secondary.contrastText
  },
  colorPrimary: {
    backgroundColor: '#E7F3FF',
    color: 'hsl(214, 89%, 52%)',
  },
  colorSecondary: {
    backgroundColor: 'hsl(350, 87%, 55%, 20%)',
    color: 'hsl(350, 87%, 55%)'
  },
  deleteIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 0.5, 0, 0)
  }
}));

export default function Chip(props) {
  const classes = useStyles();
  const iconColor = (props.color === 'primary') ? 'accent' :
                    (props.color === 'secondary') ? 'negative' :
                    'secondary';
  return (
    <MuiChip
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
        deleteIcon: classes.deleteIcon
      }}
      deleteIcon={(
        <span>
          <ClearIcon color={iconColor} />
        </span>
      )}
      {...props}
    />
  );
}
