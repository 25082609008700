import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import TodoForm from './TodoForm';
import CancelButton from './CancelButton';
import SubmitButton from './SubmitButton';
import {
  initFormData,
  convertFormDataToTodoData,
  convertFormDataToOptimisticResponse
} from './todoFormHelpers';

function TodoFormDialog({
  projectId,
  defaultDueDate,
  onSubmit,
  onClose
}) {
  const [formData, setFormData] = React.useState(initFormData({
    defaultDueDate
  }));

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  }

  const handleSubmit = () => {
    handleClose();
    if (onSubmit) {
      const data = convertFormDataToTodoData(formData);
      const optimisticResponse =
        convertFormDataToOptimisticResponse(formData);
      onSubmit(data, optimisticResponse);
    }
  };

  const isFormDataInvalid = !formData.name.trim().length ||
                            !formData.list;

  return (
    <React.Fragment>
      <DialogContent>
        <TodoForm
          projectId={projectId}
          data={formData}
          onChange={setFormData}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose}>
          Cancel
        </CancelButton>
        <SubmitButton
          disabled={isFormDataInvalid}
          onClick={handleSubmit}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </React.Fragment>
  );
}


TodoFormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  defaultDueDate: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default TodoFormDialog;
