import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative'
  }
}));

function CellContentWrapper({ className, ...rest }) {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)} {...rest} />;
}

export default CellContentWrapper;
