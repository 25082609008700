import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexShrink: 0,
    marginTop: 1,
    minHeight: 40,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)'
  }
}));

export default function Toolbar({ className, ...rest }) {
  const classes = useStyles();
  return (
    <MuiToolbar
      className={clsx(classes.toolbar, className)}
      {...rest}
    />
  );
}
