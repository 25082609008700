import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TimelineContext from './TimelineContext';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
  },
  floatRight: { float: 'right' },
  floatLeft: {
    float: 'left',
    textAlign: 'right',
    '& > :first-child': { order: 1 },
    '& > :nth-child(2)': { order: 0 }
  }
}));

function TimelineItem({ left, children, className, style, ...rest }) {
  const classes = useStyles();
  const { matches, dividerWidth } = React.useContext(TimelineContext);
  return (
    <div
      className={clsx(classes.item, className, {
        [classes.floatRight]: matches && !left,
        [classes.floatLeft]: matches && left
      })}
      style={{
        ...style,
        width: matches ? `calc(50% + ${dividerWidth/2}px)` : '100%'
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

TimelineItem.propTypes = {
  left: PropTypes.bool,
  children: PropTypes.node
};

TimelineItem.defaultProps = {
  left: false
};

export default React.memo(TimelineItem);
