import React from 'react';
import Button from '../button/Button';

export default function DeleteConfirmCancelButton(props) {
  return (
    <Button
      variant='contained'
      size='small'
      color='secondary'
      {...props}
    >
      Cancel
    </Button>
  );
}
