import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '../../icons/18x18/Download';
import IconButton from './ToolbarIconButton';

function DownloadFileIconButton({ downloadUrl, ...rest }) {
  return (
    <IconButton
      aria-label='Download file'
      icon={<DownloadIcon />}
      component='a'
      href={downloadUrl}
    />
  );
}

DownloadFileIconButton.propTypes = {
  downloadUrl: PropTypes.string.isRequired
};

export default DownloadFileIconButton;
