import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_PROFILE_FRAGMENT } from '../../account/fragments';

const SEARCH_NEW_MEMBERS_FOR_TEAM_QUERY = gql`
  query SearchNewMembersForTeam($teamId: ID!, $filter: UserSearchCriteria) {
    newMembersForTeam(teamId: $teamId, filter: $filter) {
      ...UserProfile
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export default function useLazySearchNewMembersForTeamQuery(options) {
  const { teamId, ...rest } = options;
  return useLazyQuery(SEARCH_NEW_MEMBERS_FOR_TEAM_QUERY, {
    fetchPolicy: 'network-only',
    variables: { teamId },
    ...rest
  });
}
