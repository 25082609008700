import React from 'react';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '../../../icons/18x18/Download';
import CloseIcon from '../../../icons/18x18/Close';
import Action from './DialogTitleAction';
import UserAvatar from '../../../account/UserAvatar';
import Typography from '@material-ui/core/Typography';
import { parseISO, format, isToday, isYesterday } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    padding: theme.spacing(1)
  },
  metadata: {
    flex: '1 1 auto',
    display: 'flex'
  },
  metadataAvatar: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1)
  },
  metadataContent: {
    flex: '1 1 auto',
    width: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  metadataTitle: {
    lineHeight: 1.2
  },
  metadataSubtitle: {
    lineHeight: 1.2
  },
  actions: {
    //backgroundColor: 'blue',
    flex: '0 0 auto',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));

function formatUpdatedAt(timestampString) {
  const timestamp = parseISO(timestampString)
  if (isToday(timestamp)) {
    return format(timestamp, "'Today' 'at' HH:mm a");
  } else if (isYesterday(timestamp)) {
    return format(timestamp, "'Yesterday' 'at' HH:mm a");
  } else {
    return format(timestamp, "MMMM do, yyyy 'at' HH:mm a");
  }
}

function DialogTitle({name, creator, updatedAt, downloadUrl, onClose }) {
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
    >
      <div className={classes.metadata}>
        <UserAvatar
          size='small'
          className={classes.metadataAvatar}
          user={creator}
        />
        <div className={classes.metadataContent}>
          <Typography
            variant='subtitle2'
            color='textPrimary'
            noWrap
            className={classes.metadataTitle}
          >
            {creator.username}
          </Typography>
          <Typography
            variant='caption'
            color='textSecondary'
            component='p'
            noWrap
            className={classes.metadataSubtitle}
          >
            {`${formatUpdatedAt(updatedAt)} - ${name}`}
          </Typography>
        </div>
      </div>
      <div className={classes.actions}>
        <Action
          icon={<DownloadIcon />}
          aria-label='Download file'
          component='a'
          href={downloadUrl}
        />
        <Action
          icon={<CloseIcon />}
          onClick={onClose}
        />
      </div>
    </MuiDialogTitle>
  );
}

DialogTitle.propTypes = {
  name: PropTypes.string.isRequired,
  creator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }),
  updatedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  downloadUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DialogTitle;
