import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import EditIcon from '../../ui/icons/PencilOutline';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import ChangeMembershipRole from '../dialogs/ChangeMembershipRole';
import { ROLE_LIST } from '../helpers';

const ChangeMembershipRoleMenuItem = React.forwardRef((props, ref) => {
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);
  const { membership, currentUserRole, ...rest } = props;

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: (
        <ChangeMembershipRole
          membership={membership}
          currentUserRole={currentUserRole}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: false
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Change membership role'
      secondary='Change what role this user will have in your workspace.'
      icon={ <EditIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

ChangeMembershipRoleMenuItem.propTypes = {
  membership: PropTypes.exact({
    id: PropTypes.string,
    role: PropTypes.oneOf(ROLE_LIST),
    username: PropTypes.string.isRequired
  }).isRequired,
  currentUserRole: PropTypes.oneOf(ROLE_LIST).isRequired
};

export default ChangeMembershipRoleMenuItem;
