import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const ACCEPT_WORKSPACE_INVITATION_MUTATION = gql`
  mutation AcceptWorkspaceInvitation($invitationId: ID!) {
    acceptWorkspaceInvitation(invitationId: $invitationId) {
      id
      workspace {
        id
      }
    }
  }
`;

export default function useAcceptWorkspaceInvitationMutation(options) {
  const { invitationId, ...otherOptions } = options;
  const history = useHistory();

  return useMutation(ACCEPT_WORKSPACE_INVITATION_MUTATION, {
    variables: { invitationId },
    onCompleted(data) {
      const workspaceId = data.acceptWorkspaceInvitation.workspace.id;
      history.push(`/workspaces/${workspaceId}`);
    },
    ...otherOptions
  });
}
