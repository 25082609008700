import React from 'react';
import DailyScheduleContainer from './DailyScheduleContainer';
import DailyScheduleHeader from './DailyScheduleHeader';
import DailyScheduleTimeline from './DailyScheduleTimeline';

export default function DailyScheduleLoading() {
  return (
    <DailyScheduleContainer>
      <DailyScheduleHeader />
      <DailyScheduleTimeline />
    </DailyScheduleContainer>
  );
}
