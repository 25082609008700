import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../../oops/Error';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';

const TODO_COMMENTS_QUERY = gql`
  query ListTodoComments($todoId: ID!) {
    todoItem(todoItemId: $todoId) {
      id
      comments {
        id
        text
        autoGenerated
        severity
        ...CommentAuthor
        ...CommentFiles
        updatedAt
      }
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

function CommentsQuery({
  todoId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    TODO_COMMENTS_QUERY,
    {
      variables: { todoId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.todoItem.comments, otherQueryResults);
}


CommentsQuery.propTypes = {
  todoId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default CommentsQuery;
export { TODO_COMMENTS_QUERY };
