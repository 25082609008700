import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import AvatarGroup from '../ui/card/CardAvatarGroup';

export default function ProjectHeaderAvatarGroupSkeleton() {
  return (
    <AvatarGroup >
      <Skeleton variant='circle' width={36} height={36} />
      <Skeleton variant='circle' width={36} height={36} />
      <Skeleton variant='circle' width={36} height={36} />
    </AvatarGroup>
  );
}
