import React from 'react';
import PropTypes from 'prop-types';
import CellContentWrapper from './CellContentWrapper';
import Menu from './CellMenu';
import MenuItem from './StyledMenuItem';
import Button from './StyledMenuButton';

function MenuButton(props) {
  const { value, renderValue, onChange, items, renderItem, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemSelected = (selectedItem) => {
    setAnchorEl(null);

    if (onChange) {
      onChange(selectedItem);
    }
  };

  return (
    <CellContentWrapper>
      <Button
        menuOpen={Boolean(anchorEl)}
        onClick={handleClick}
        {...rest}
      >
        {renderValue(value, Boolean(anchorEl))}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item}
            primary={renderItem(item)}
            selected={item ===value }
            onClick={(event) => onItemSelected(item)}
          />
        ))}
      </Menu>
    </CellContentWrapper>
  );
}

MenuButton.propTypes = {
  // List of string options
  items: PropTypes.arrayOf(PropTypes.string).isRequired,

  // How to render a specific item
  renderItem: PropTypes.func,

  // Current selected item (must be one of `items` above)
  value: PropTypes.string.isRequired,

  // How to render this value
  renderValue: PropTypes.func,

  // Signature: (value) => {...}
  onChange: PropTypes.func
};

MenuButton.defaultProps = {
  renderItem: (item) => item,
  renderValue: (val, isMenuOpen) => val
};

export default MenuButton;
