import PropTypes from 'prop-types';
import { TODO_CRITERIA_SHAPE } from './helpers';
import useSomeTodoItemUpdatedSubscription
from './hooks/useSomeTodoItemUpdatedSubscription';

function SomeTodoItemUpdatedSubscriber({ todoListId, todoCriteria }) {
  useSomeTodoItemUpdatedSubscription({
    todoCriteria,
    todoListId
  });

  return null;
}

SomeTodoItemUpdatedSubscriber.propTypes = {
  todoListId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

export default SomeTodoItemUpdatedSubscriber;
