import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Loading from './AutocompleteLoading';
import ClearIcon from '../../icons/12x12/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  paper: {
    borderRadius: 4,
    width: theme.spacing(24),
    boxShadow: theme.shadows[4],
    marginTop: 0
  },
  paperGutterTop: {
    marginTop: theme.spacing(1)
  },
  option: {
    minHeight: 'auto',
    [`&[aria-selected="true"]`]: {
      backgroundColor: '#E7F3FF'
    }
  },
  body1: {
    padding: theme.spacing(1, 2),
    ...theme.typography.body1,
    lineHeight: '24px'
  },
  body2: {
    padding: theme.spacing(0.75, 2),
    ...theme.typography.body2,
    lineHeight: '24px'
  },
  noWrap: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  endAdornment: {
    top: 'calc(50% - 12px)',
    right: 4
  },
  clearIndicator: {
    marginRight: 0,
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

function StyledAutocomplete(props) {
  const classes = useStyles();
  const { typography, gutterTop, noWrap, fullWidth, ...rest } = props;
  return (
    <Autocomplete
      forcePopupIcon={false}
      noOptionsText='No matches found'
      loadingText={<Loading />}
      closeIcon={ <ClearIcon color='secondary' /> }
      classes={{
        root: clsx(classes.root, {
          [classes.fullWidth]: fullWidth
        }),
        paper: clsx(classes.paper, {
          [classes.paperGutterTop]: gutterTop
        }),
        option: clsx(classes.option, classes[typography], {
          [classes.noWrap]: noWrap
        }),
        noOptions: classes[typography],
        loading: classes[typography],
        endAdornment: classes.endAdornment,
        clearIndicator: classes.clearIndicator
      }}
      {...rest}
    />
  );
}

StyledAutocomplete.propTypes = {
  // typography variant for textual option
  typography: PropTypes.oneOf(['body1', 'body2']).isRequired,

  // If set to `true` (default), textual option will be ellipsised
  noWrap: PropTypes.bool,

  // if set to `true`, 8px margin top will be applied to the paper
  gutterTop: PropTypes.bool,

  // If set to `true`, the autocomplete will take up the full width of
  // its container
  fullWidth: PropTypes.bool
};

StyledAutocomplete.defaultProps = {
  typography: 'body2',
  gutterTop: true,
  noWrap: true,
  fullWidth: false
};

export default StyledAutocomplete;
