import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import DialogActions from '../../ui/dialog/MultiStepActionsContainer';
import CreateProjectForm from './CreateProjectForm';
import Divider from './CreateProjectFormDivider';
import ProjectNameInput from './ProjectNameInput';
import TeamPickerButton from './TeamPickerButton';
import ProjectDescInput from './ProjectDescInput';
import useCreateProjectMutation from '../hooks/useCreateProjectMutation';
import { getRandomAvatarColor } from '../../ui/avatar/colors';

function FormDialogContent({
  teamId,
  onPickTeam,
  actionsContainer,
  onClose
}) {
  const [formData, setFormData] = React.useState({
    name: '',
    description: ''
  });

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const isFormDataValid = () => {
    if (!teamId) { return false; }
    if (formData.name.trim().length < 3) { return false; }
    return true;
  };

  // Mutation.

  const [createProject, { loading }] = useCreateProjectMutation({
    variables: {
      teamId,
      ...formData,
      color: getRandomAvatarColor()
    },
    onCompleted: (data) => {
      if (onClose) { onClose(); }
    }
  });

  return (
    <div>
      <CreateProjectForm>
        {/* TODO: Focus this shit after the CSS transition */}
        <ProjectNameInput
          autoFocus
          name='name'
          value={formData.name}
          onChange={handleFormDataChange}
        />

        <TeamPickerButton
          teamId={teamId}
          onClick={onPickTeam}
        />

        <Divider />

        <ProjectDescInput
          name='description'
          value={formData.description}
          onChange={handleFormDataChange}
        />
      </CreateProjectForm>

      <DialogActions container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          disabled={!isFormDataValid()}
          onClick={() => {
            createProject();
          }}
        >
          Create project
        </Button>
      </DialogActions>
    </div>
  );
}

FormDialogContent.propTypes = {
  // In which team does this project belong to.
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),

  // Callback fired when pick team button click
  onPickTeam: PropTypes.func,

  // DOM node to mount dialog actions
  actionsContainer: PropTypes.object,

  // Close dialog
  onClose: PropTypes.func
}

export default FormDialogContent;
