import React from 'react';
import PropTypes from 'prop-types';
import ChipRoot from './AllDayChipRoot';
import ChipLabel from './AllDayChipLabel';
import StartIcon from './AllDayChipStartIcon';

function AllDayChip({ startIcon, label, labelStrikeThrough, ...rest }) {
  return (
    <ChipRoot {...rest}>
      {startIcon && <StartIcon icon={startIcon} /> }
      <ChipLabel label={label} strikeThrough={labelStrikeThrough} />
    </ChipRoot>
  );
}

AllDayChip.propTypes = {
  startIcon: PropTypes.node,
  label: PropTypes.string.isRequired,
  labelStrikeThrough: PropTypes.bool
};

AllDayChip.defaultProps = {
  labelStrikeThrough: false
};

export default AllDayChip;
