import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';

const FILE_FOLLOWERS_QUERY = gql`
  query ListFileFollowers($fileId: ID!, $projectId: ID!) {
    project(projectId: $projectId) {
      id
      upload(id: $fileId) {
        id
        followers {
          id
          username
          email
          avatar
        }
      }
    }
  }
`;

function FollowersQuery({
  projectId,
  fileId,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    FILE_FOLLOWERS_QUERY,
    {
      variables: { fileId, projectId },
      ...otherQueryOptions
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.project.upload.followers, otherQueryResults);
}


FollowersQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default FollowersQuery;
