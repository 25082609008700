import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '../../icons/12x12/ArrowDropDown';
import { FILTER_ACCENT } from '../../icons/Base';
import TabOverlay from './TabOverlay';
import TabActiveUnderline from './TabActiveUnderline';
import useCommonTabStyles from './useCommonTabStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'inherit',  // IMPORTANT

    '&.selected': {
      color: theme.palette.primary.main,
      '& $icon': {
        filter: FILTER_ACCENT
      },
      '& $underline': {
        display: 'block'
      }
    },

    '&:hover:not(.selected)': {
      '& $overlay': {
        opacity: 1.0
      }
    },

    '&:active:not(.selected)': {
      '& $overlay': {
        opacity: 1.0,
        backgroundColor: theme.palette.button.overlay.press
      }
    },
  },
  label: {
    width: '100%',
    display: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit'
  },
  underline: {},
  overlay: {},
  icon: {
    marginLeft: theme.spacing(0.5)
  }
}));

const TabCollapseButton = React.forwardRef((props, ref) => {
  const { invisible, selected, ...rest } = props;
  const classes = useStyles();
  const common = useCommonTabStyles();

  return invisible ? (
    <div ref={ref} className={common.commonTabStyles}>
      <span className={classes.label}>
        More
        <ArrowDropDownIcon color='secondary'/>
      </span>
    </div>
  ) : (
    <ButtonBase
      ref={ref}
      className={clsx(common.commonTabStyles, classes.button, {
        selected: selected
      })}
      {...rest}
    >
      <span className={classes.label}>
        More
        <ArrowDropDownIcon color='secondary' className={classes.icon} />
      </span>
      <TabOverlay className={classes.overlay} />
      <TabActiveUnderline className={classes.underline} />
    </ButtonBase>
  );
});

TabCollapseButton.propTypes = {
  invisible: PropTypes.bool,
  selected: PropTypes.bool
};

TabCollapseButton.defaultProps = {
  invisible: false,
  selected: false
};

export default React.memo(TabCollapseButton);
