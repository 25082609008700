import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PageNavigation from './Navigation';

function ButtonSkeleton({ style }) {
  return (
    <Skeleton
      variant='rect'
      width={64}
      height={32}
      style={{...style, borderRadius: 6}}
    />
  );
}

export default function NavigationSkeleton() {
  const buttonsContainerStyles = {
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex'
  };

  return (
    <PageNavigation>
      <div style={buttonsContainerStyles}>
        <ButtonSkeleton style={{marginRight: 8}} />
        <ButtonSkeleton />
      </div>
      <div style={buttonsContainerStyles}>
        <ButtonSkeleton />
      </div>
    </PageNavigation>
  );
}
