import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cellContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));

function CellContent({ justifyContent, ...rest }) {
  const classes = useStyles();
  return (
    <div
      className={classes.cellContent}
      style={{ justifyContent: justifyContent }}
      {...rest}
    />
  );
}

CellContent.propTypes = {
  // CSS justifyContent value
  justifyContent: PropTypes.string
};

CellContent.defaultProps = {
  justifyContent: 'normal'
};

export default CellContent;
