import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    fontWeight: 700,
    fontSize: '1.25rem'
  }
}))((props) => <Typography variant='h6' {...props} /> );
