import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ProjectWeeklySchedule from './weekly-schedule/ProjectWeeklySchedule';

function WeeklySchedule({ date }) {
  const { projectId } = useParams();

  return (
    <ProjectWeeklySchedule
      projectId={projectId}
      date={date}
    />
  );
}

WeeklySchedule.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
};

export default WeeklySchedule;
