import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../../oops/Error';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';

const MESSAGE_COMMENTS_QUERY = gql`
  query ListMessageComments($projectId: ID!, $messageId: ID!) {
    projectMessage(projectId: $projectId, messageId: $messageId) {
      id
      comments {
        id
        text
        autoGenerated
        severity
        ...CommentAuthor
        ...CommentFiles
        updatedAt
      }
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

function CommentsQuery({
  projectId,
  messageId,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    MESSAGE_COMMENTS_QUERY,
    {
      variables: { projectId, messageId },
      ...otherQueryOptions
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.projectMessage.comments, otherQueryResults);
}


CommentsQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default CommentsQuery;
export { MESSAGE_COMMENTS_QUERY };
