import React from 'react';
import JoinLeaveButton from './JoinLeaveButton';
import BellIcon from '../../../icons/18x18/Bell';

export default function FollowTodoButton(props) {
  const tooltip = 'Get notifications about activity on this task.';
  return (
    <JoinLeaveButton
      label='Join task'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
