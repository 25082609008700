import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '../../../ui/button/IconButton';
import PersonAddIcon from '../../../icons/16x16/PersonPlusFill';

// TODO: duplicate
const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    width: theme.spacing(22),
    backgroundColor: '#282828',
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium
  },
  arrow: {
    '&:before': {
      backgroundColor: '#282828'
    }
  }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  button: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
}));

const UpdateFollowersButton = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <StyledTooltip
      title='Add or remove subscribers'
      placement='top'
      arrow
      enterDelay={500}
    >
      <IconButton
        ref={ref}
        background={false}
        color='secondary'
        className={classes.button}
        icon={<PersonAddIcon />}
        {...props}
      />
    </StyledTooltip>
  );
});

export default UpdateFollowersButton;
