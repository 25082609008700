import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import UnarchiveIcon from '../../icons/20x20/Unarchive';
import useRestoreProjectMutation from '../hooks/useRestoreProjectMuation';

const RestoreProjectItem = React.forwardRef((props, ref) => {
  const { projectId, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);

  const [restoreProject, { loading }] = useRestoreProjectMutation({
    variables: { projectId },
    optimisticResponse: {
      __typename: 'Mutation',
      unarchiveProject: {
        __typename: 'Project',
        id: projectId,
        archived: false
      }
    }
  });

  const handleClick = () => {
    closeMenu();
    restoreProject();
  };

  return (
    <MenuItem
      ref={ref}
      disabled={loading}
      primary={loading ? 'Restoring...' : 'Restore this project'}
      icon={ <UnarchiveIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

RestoreProjectItem.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default RestoreProjectItem;
