import React from 'react';
import BellIcon from '../../../../icons/18x18/Bell';
import SubscribeUnsubscribeButton from './SubscribeUnsubscribeButton';

export default function SubscribeMeButton(props) {
  const tooltip = 'Get notifications about activity on this event.';
  return (
    <SubscribeUnsubscribeButton
      label='Subscribe'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
