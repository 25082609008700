import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    fontWeight: 500
  }
}));

function UserOptionAvatar({ user, className, ...rest }) {
  const classes = useStyles();
  return (
    <UserAvatar
      className={clsx(classes.avatar, className)}
      user={user}
      {...rest}
    />
  );
}

UserOptionAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
};

export default UserOptionAvatar;
