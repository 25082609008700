import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 20 / 15,
    fontSize: '0.9375rem'
  }
}));

function ActivityTitle({ children }) {
  const classes = useStyles();
  return (
    <Typography variant='subtitle1' className={classes.title}>
      {children}
    </Typography>
  );
}

ActivityTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default ActivityTitle;
