import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  allDayRow: {
    position: 'relative',
    minHeight: theme.spacing(5),
    display: 'flex',

    // Vertical bar
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.spacing(6.5),
      width: 1,
      backgroundColor: theme.palette.divider
    }
  },

  left: {
    flex: '0 0 auto',
    width: theme.spacing(6.5)
  },

  allDayLabel: {
    lineHeight: 1.5
  },

  right: {
    flexGrow: 1,
    width: 0,
    position: 'relative'
  },

  presentationRow: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    pointerEvents: 'none'
  },

  presentationCell: {
    flex: '1 1 0%',
    backgroundColor: 'transparent',
    borderTop: `1px solid ${theme.palette.divider}`,
    position: 'relative',

    // TODO: A border-right hack
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: -5,
      right: 0,
      width: 1,
      backgroundColor: theme.palette.divider
    }
  }
}));

function AllDayRow({ numCols, children }) {
  const classes = useStyles();

  return (
    <div className={classes.allDayRow}>
      <div className={classes.left}>
        <Typography
          component='div'
          variant='caption'
          color='textSecondary'
          className={classes.allDayLabel}
        >
          all-day
        </Typography>
      </div>
      <div className={classes.right}>
        <div className={classes.presentationRow}>
          {Array.from(
            new Array(numCols),
            (el, index) => (
              <div
                key={index}
                className={classes.presentationCell}
              />
            )
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

AllDayRow.propTypes = {
  numCols: PropTypes.number,
  children: PropTypes.node
};

AllDayRow.defaultProps = {
  numCols: 7
};

export default AllDayRow;
