import React from 'react';
import UserProfileMenuItem from './UserProfileMenuItem';
import SignInToOtherWorkspaceMenuItem from './SignInToOtherWorkspaceMenuItem';
import CreateNewWorkspaceMenuItem from './CreateNewWorkspaceMenuItem';
import DeleteCurrentWorkspaceMenuItem from './DeleteCurrentWorkspaceMenuItem';
import RemoveMeFromCurrentWorkspaceMenuItem from './RemoveMeFromCurrentWorkspaceMenuItem';
import LogoutMenuItem from './LogoutMenuItem';
import Menu from '../../ui/menu/Menu';
import MenuItemDivider from '../../ui/menu/MenuItemDivider';

export default function AccountMenu({ account, ...rest }) {
  const { workspaceMemberships, ...user } = account;

  const renderRoleDependentItem = () => {
    if (workspaceMemberships.length === 0) return null;
    const membership = workspaceMemberships[0];
    const { id, role, workspace } = membership;
    if (role === 'OWNER') {
      return <DeleteCurrentWorkspaceMenuItem workspace={workspace} />;
    } else {
      return (
        <RemoveMeFromCurrentWorkspaceMenuItem
          membershipId={id}
          workspaceName={workspace.name}
        />
      );
    }
  };

  return (
    <Menu {...rest}>
      <UserProfileMenuItem user={user} />
      <MenuItemDivider />
      <SignInToOtherWorkspaceMenuItem />
      <CreateNewWorkspaceMenuItem />
      {renderRoleDependentItem()}
      <MenuItemDivider />
      <LogoutMenuItem />
    </Menu>
  );
}
