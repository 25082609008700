import React from 'react';
import PropTypes from 'prop-types';
import FilesInProjectQuery from './FilesInProjectQuery';
import Table from '../ui/file/table/Table';
import TableHead from '../ui/file/table/TableHead';
import TableBodySkeleton from '../ui/file/table/TableBodySkeleton';
import TableBody from '../ui/file/table/TableBody';
import TableBodyEmpty from '../ui/file/table/TableBodyEmpty';
import NoMatchesRow from '../ui/file/table/NoMatchesRow';
import { isDirectory } from '../ui/file/mime/mime';
import { SEARCH_SCOPES } from '../ui/file/SearchCriteria';
import { COLUMNS } from './file_table';
import NoFilesRow from './NoFilesRow';

const colCount = COLUMNS.length;

function nameWithExt({ name, ext }) {
  return ext ? `${name}${ext}` : name;
}

function fileUrl(rootUrl, { id, type }) {
  if (isDirectory(type)) {
    return `${rootUrl}/directories/${id}`;
  } else {
    return `${rootUrl}/uploads/${id}`;
  }
}

function FileTable({
  rootUrl,
  projectId,
  directory,
  criteria,
  onClearFilters
}) {
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('ASC');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const isSearching = Boolean(criteria.matching);

  return (
    <Table>
      <TableHead
        columns={COLUMNS}
        onRequestSort={handleRequestSort}
        orderBy={orderBy}
        order={order}
      />

      <FilesInProjectQuery
        fetchPolicy='network-only'
        projectId={projectId}
        directoryId={directory.id}
        orderBy={{[orderBy]: order}}
        filter={criteria}
        onLoading={() => (
          <TableBodySkeleton colCount={colCount} />
        )}
      >
        {(files) => {
          const convertedFiles = files.map((file) => ({
            ...file,
            name: nameWithExt(file),
            url: fileUrl(rootUrl, file)
          }));

          return convertedFiles.length ? (
            <TableBody
              columns={COLUMNS}
              rows={convertedFiles}
            />
          ) : (
            <TableBodyEmpty colCount={colCount}>
              {isSearching ? (
                <NoMatchesRow
                  colSpan={colCount}
                  onClearFilters={onClearFilters}
                />
              ) : (
                <NoFilesRow
                  colSpan={colCount}
                  projectId={projectId}
                  currentFolder={directory}
                />
              )}
            </TableBodyEmpty>
          );
        }}
      </FilesInProjectQuery>
    </Table>
  );
}

FileTable.propTypes = {
  // The url to the root directory of a project
  rootUrl: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  directory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  criteria: PropTypes.shape({
    matching: PropTypes.string.isRequired,
    searchScope: PropTypes.oneOf(SEARCH_SCOPES).isRequired
  }),
  onClearFilters: PropTypes.func
};

export default FileTable;
