import { isBefore, isAfter, startOfDay, endOfDay } from 'date-fns';
import { parseDateInput } from '../../new-ui/datetime-picker/parseDateInput';

// Returns `true` if the [startsAt, endsAt] range of the specified event
// overlaps with the given date.
// Returns `false`, otherwise.
export default function eventOverlapsWithDate(event, date) {
  const start = parseDateInput(event.startsAt);
  const end = parseDateInput(event.endsAt);
  const lowerBound = startOfDay(date);
  const upperBound = endOfDay(date);
  return !isAfter(start, upperBound) && !isBefore(end, lowerBound);
}
