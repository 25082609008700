import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import RouterButton from '../ui/button/RouterButton';
import NotFound from './NotFound';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    maxWidth: '29rem',
    padding: theme.spacing(3),
    borderRadius: '0.75rem',
    boxShadow: theme.customShadow.card,
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  }
}));

const DOCUMENT_TITLE =
  "Sorry, we can't seem to find the page you're looking for";

export default function PageNotFound() {
  const classes = useStyles();
  const previousDocumentTitle = React.useMemo(() => {
    return document.title;
  }, []);

  React.useEffect(() => {
    document.title = DOCUMENT_TITLE;
    return () => {
      document.title = previousDocumentTitle;
    };
  }, [previousDocumentTitle]);

  return (
    <Container maxWidth='sm' className={classes.root}>
      <Paper elevation={0} className={classes.card}>
        <NotFound />
        <Divider />
        <RouterButton
          variant='contained'
          color='primary'
          fullWidth
          to='/'
        >
          Go home
        </RouterButton>
      </Paper>
    </Container>
  );
}
