import React from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import SectionSearchInput from '../../ui/page/SectionSearchInput';

function ProjectSearchCriteria({ criteria, onChange }) {
  const notifyOnChange = (newCriteria) => {
    onChange(newCriteria);
  };

  const handleMatchingChange = debounce((event) => {
    const newCriteria = {
      ...criteria,
      matching: event.target.value
    }
    notifyOnChange(newCriteria);
  }, 300);

  return (
    <SectionSearchInput
      placeholder='Search'
      inputProps={{ 'aria-label': 'search by name or description' }}
      defaultValue={criteria.matching}
      onChange={(e) => {
        e.persist();
        handleMatchingChange(e);
      }}
    />
  );
}

ProjectSearchCriteria.propTypes = {
  criteria: PropTypes.shape({
    matching: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func
};

export default ProjectSearchCriteria;
