import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

const TODO_FIELDS_QUERY = gql`
  query GetTodoFields($projectId: ID!) {
    project(projectId: $projectId) {
      id
      todoSheetColumns {
        name
        show
        width
      }
    }
  }
`;

export default function useLazyTodoFieldsQuery(options) {
  const { projectId, ...rest } = options;
  return useLazyQuery(TODO_FIELDS_QUERY, {
    variables: { projectId },
    ...rest
  });
}

export { TODO_FIELDS_QUERY };
