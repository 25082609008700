import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../snackbar/useCustomSnackbar';

const DELETE_PROJECT_MESSAGE_MUTATION = gql`
  mutation DeleteProjectMessage($messageId: ID!) {
    deleteProjectMessage(messageId: $messageId) {
      id
    }
  }
`;

export default function useDeleteMessageMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(DELETE_PROJECT_MESSAGE_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
