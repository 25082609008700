import React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from '../../ui/todo/sheet/SectionHeaderDeleteButton';
import DialogContext from '../../ui/dialog/DialogContext';
import DeleteTodoList from '../dialogs/DeleteTodoList';

function DeleteSectionButton({ sectionId }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: <DeleteTodoList todoListId={sectionId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return <DeleteButton onClick={handleClick} />;
}

DeleteSectionButton.propTypes = {
  sectionId: PropTypes.string.isRequired
};

export default DeleteSectionButton;
