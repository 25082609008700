import {
  getDay,
  addDays,
  subDays,
  isSameMonth,
  isAfter,
  eachDayOfInterval
} from 'date-fns';

// Given the start day of a month `startOfMonth`, returns a grid of size
// 6x7 (a 2D array of size 6, and each element of it is 1D array of size 7)
// of all days in the given month (potentially padded by extra days of
// previous/next month).
// Days within each row go from 'Sunday' to 'Saturday' if
// `options.startsWithSunday` is set to `true`, otherwise they go from
// 'Monday' to 'Sunday' (default).
export default function getDaysGridForMonth(startOfMonth, options) {
  const {
    // Whether the first column of the grid is Monday (default) or
    // Sunday (if `startWithSunday` is set to `true`).
    startsWithSunday = false,

    // The number of rows of the grid. Available options are 'fit'
    // (minimum number of rows to fit all days of the given month), or
    // any positive numbers.
    numRows = 6
  } = (options || {});

  // The day of week, 0 represents Sunday
  let dayOfWeek = getDay(startOfMonth);

  // The day of week, 0 represents Monday
  if (!startsWithSunday) {
    dayOfWeek = (dayOfWeek + 6) % 7;
  }

  let start = subDays(startOfMonth, dayOfWeek);
  let grids = [];
  let i = 0;

  // Helper function for checking whether the given day `d` belongs to
  // the next month or not.
  const isNextMonth = (d) => (
    !isSameMonth(d, startOfMonth) &&
    isAfter(d, startOfMonth)
  );

  while(true) {
    if (numRows === 'fit') {
      if (isNextMonth(start)) { break; }
    } else {
      if (i === numRows) { break; }
    }

    const end = addDays(start, 6);
    grids.push(eachDayOfInterval({ start, end }));
    start = addDays(end, 1);
    i++;
  }

  return grids;
}
