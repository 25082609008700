import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '../../../icons/16x16/ExpandMoreThin';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 1),
    borderRadius: 0,
    border: '1px solid transparent',
    justifyContent: 'space-between',

    '&:hover $endIcon': {
      display: 'flex'
    },

    '&:focus': {
      borderColor: theme.palette.primary.main,
      '& $endIcon': {
        display: 'flex'
      }
    },

    '&:active': {
      borderColor: theme.palette.primary.main
    },

    '&.Mui-focusVisible': {
      outline: 0,
      borderColor: theme.palette.primary.main
    }
  },
  menuOpen: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    '& $endIcon': {
      display: 'flex'
    }
  },
  endIcon: {
    display: 'none',
    marginRight: 0
  }
}));

// IMPORTANT NOTES: We're using `div` rather than native button element
// in order to fix(?) the following issues:
//
// - We want to place focus on this button whenever it's clicked
//   (So that focus within row works correctly).
//
// - Don't trigger `onBlur` and immediately `onFocus` focused button gets
//   clicked.
//
// - See [1] for more details.
//
// [1] - https://zellwk.com/blog/inconsistent-button-behavior/
function StyledMenuButton({ menuOpen, className, ...rest }) {
  const classes = useStyles();

  return (
    <Button
      component='div'
      aria-haspopup='true'
      classes={{
        root: clsx(classes.button, {
          [classes.menuOpen]: menuOpen
        }, className),
        endIcon: classes.endIcon
      }}
      endIcon={<ExpandMoreIcon color='secondary' />}
      {...rest}
    />
  );
}


StyledMenuButton.propTypes = {
  menuOpen: PropTypes.bool
};

StyledMenuButton.defaultProps = {
  menuOpen: false
};

export default StyledMenuButton;
