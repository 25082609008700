import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    flexGrow: 1,
    maxWidth: '28.125rem',
    boxShadow: theme.customShadow.card,
    borderRadius: '0.625rem'
  }
}));

function FullScreenCard({children, className, ...otherProps}) {
  const classes = useStyles();
  return (
    <Container maxWidth='sm' className={classes.root}>
      <Paper className={clsx(classes.paper, className)} {...otherProps}>
        {children}
      </Paper>
    </Container>
  );
}

FullScreenCard.propTypes = {
  children: PropTypes.node
};

export default FullScreenCard;
