import React from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import useCustomBlockStyleFn from
'../text-editor/hooks/useCustomBlockStyleFn';

// TODO: What is the conversion failed?
function fromJsonTextToEditorState(jsonText) {
  if (!jsonText) {
    return EditorState.createEmpty();
  } else {
    const contentState = convertFromRaw(JSON.parse(jsonText));
    return EditorState.createWithContent(contentState);
  }
}

function MessageDetailsContent({ jsonText }) {
  const editorState = fromJsonTextToEditorState(jsonText);
  const customBlockStyleFn = useCustomBlockStyleFn();

  return (
    <Editor
      readOnly
      blockStyleFn={customBlockStyleFn}
      editorState={editorState}
    />
  );
}

export default MessageDetailsContent;
