import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/MultiStepTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogForm from '../../ui/dialog/DialogForm';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import NameInput from './NameInput';
import useRenameFolderMutation from '../hooks/useRenameFolderMutation';

function RenameFolderDialog({ id, name }) {
  const { closeDialog } = React.useContext(DialogContext);
  const [newName, setNewName] = React.useState(name);

  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  const isSubmitDisabled = () => {
    const trimmedNewName = newName.trim();

    if (trimmedNewName === name) {
      return true;
    }

    if (trimmedNewName.length < 3) {
      return true;
    }

    return false;
  };

  const [renameFolder, { loading }] = useRenameFolderMutation({
    variables: { id, name: newName },
    optimisticResponse: {
      __typename: 'Mutation',
      renameFolder: {
        __typename: 'File',
        id: id,
        name: newName
      }
    },
    update: (client, { data: { renameFolder }}) => {
      // Since we use optimisticResponse we don't want to wait until
      // the mutation completes to close the dialog.
      closeDialog();
    }
  });

  React.useEffect(() => {
    setNewName(name);
  }, [name]);

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Rename this folder
      </DialogTitle>

      <DialogContent>
        <DialogForm>
          <NameInput
            id='__rename_folder_name_input'
            value={newName}
            onChange={handleChange}
          />
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          disabled={isSubmitDisabled() || loading}
          loading={loading}
          onClick={renameFolder}
        >
          Save changes
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

RenameFolderDialog.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default RenameFolderDialog;
