import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandSectionButton from './ExpandSectionButton';
import SectionTitle from './SectionTitle';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex'
  },
  button: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
}));

function SectionHeader({ expanded, title, onToggleExpanded }) {
  const classes = useStyles();

  return (
    <div className={classes.sectionHeader}>
      <div
        role='button'
        className={classes.button}
        onClick={onToggleExpanded}
      >
        <ExpandSectionButton expanded={expanded} />
        <SectionTitle>{title}</SectionTitle>
      </div>
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onToggleExpanded: PropTypes.func
};

SectionHeader.defaultProps = {
  expanded: false
};

export default SectionHeader;
