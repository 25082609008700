import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const UPDATE_MESSAGE_FOLLOWERS_MUTATION = gql`
  mutation UpdateMessageFollowers($messageId: ID!, $userIds: [ID]) {
    updateMessageFollowers(messageId: $messageId, userIds: $userIds) {
      id
    }
  }
`;

export default function useUpdateFollowersMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_MESSAGE_FOLLOWERS_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
