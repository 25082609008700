import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import AttachmentLabel from './AttachmentLabel';
import ListItem from '../../file/list/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 16
  },
  list: {
    paddingLeft: 36
  }
}))

export default function AttachmentSectionSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AttachmentLabel clickable={false} />
      <div className={classes.list}>
        <ListItem
          avatar={<Skeleton variant='rect' width={40} height={40} />}
          primary={<Skeleton />}
          secondary={<Skeleton width='40%' />}
        />
      </div>
    </div>
  );
}
