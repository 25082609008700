import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ClickableListItem from './ClickableListItem';
import ListItemAvatar from './ListItemAvatar';
import ListItemText from './ListItemText';

const PADDING = 8;
const ACTION_SIZE = 36;
const ACTION_GAP = 8;

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: PADDING
  },
  actionBase: {
    width: ACTION_SIZE,
    height: ACTION_SIZE,
    position: 'absolute',
    top: '50%',
    right: PADDING
  }
}));

function ListItem({
  avatar,
  primary,
  secondary,
  actions,
  onClick,
  disabledHover,
  ...rest
}) {
  const classes = useStyles();
  const sizeWithGap = ACTION_SIZE + ACTION_GAP;
  const textMarginRight = actions.length * sizeWithGap;

  const label = (
    <React.Fragment>
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
        style={{marginRight: textMarginRight}}
      />
    </React.Fragment>
  );

  const filteredActions = actions.filter((a) => Boolean(a));

  return (
    <Paper
      className={classes.card}
      elevation={0}
      {...rest}
    >
      {onClick ? (
        <ClickableListItem
          disabledHover={disabledHover}
          className={classes.cardContent}
          onClick={onClick}
        >
          {label}
        </ClickableListItem>
      ) : (
        <div className={classes.cardContent}>
          {label}
        </div>
      )}

      {filteredActions.map((action, index) => React.cloneElement(action, {
        key: index, // It's OK to use index as key here
        className: clsx(action.props.className, classes.actionBase),
        style: {
          ...action.props.style,
          transform: `translate(-${sizeWithGap * index}px, -50%)`
        }
      }))}
    </Paper>
  );
}

ListItem.propTypes = {
  avatar: PropTypes.node.isRequired,
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node.isRequired,
  actions: PropTypes.array,
  onClick: PropTypes.func,
  disabledHover: PropTypes.bool
};

ListItem.defaultProps = {
  actions: [],
  disabledHover: false
};

export default ListItem;
