import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import RouterLink from '../ui/link/RouterLink';

export default function SignInFormFooter() {
  return (
    <>
      <Divider flexItem style={{height: 1}} />

      <Typography variant='body2' color='textSecondary'>
        Don't have an account yet?
        {' '}
        <RouterLink
          to='/sign-up'
          variant='subtitle2'
        >
          Create new account
        </RouterLink>
      </Typography>
    </>
  );
}
