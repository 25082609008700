import React from 'react';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogForm from '../../ui/dialog/DialogForm';
import DialogContext from '../../ui/dialog/DialogContext';
import TextField from '../../ui/input/TextField';
import Button from '../../ui/button/Button';
import useCreateTeamMutation from '../hooks/useCreateTeamMutation';
import { gqlErrorDetails } from '../../oops/helpers';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

function CreateTeam() {
  const { closeDialog } = React.useContext(DialogContext);

  const [formData, setFormData] = React.useState({
    name: '',
    description: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  //
  // GraphQL mutation.
  //

  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  const [createTeam, { loading, error }] = useCreateTeamMutation({
    variables: formData,
    onError(error) {
      const errorDetails = gqlErrorDetails(error);
      const isNameError = errorDetails.hasOwnProperty('name');
      if (!isNameError) {
        enqueueGraphQLErrorSnackbar(error);
      }
    },
    onCompleted(data) {
      closeDialog();
    }
  });

  const getNameErrorProps = () => {
    const errorDetails = gqlErrorDetails(error);
    const isNameError = errorDetails.hasOwnProperty('name');
    if (isNameError) return {
      error: true,
      helperText: `A valid team name ${errorDetails['name']}.`
    };
    return null;
  };

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Create new team
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Teams are subsets of people in your organization who collaborate
          on projects with each other. Each team has its own members and
          projects.
        </DialogContentText>
        <DialogForm marginTop>
          <TextField
            autoFocus
            autoComplete='off'
            id='__create_team_name_input'
            name='name'
            label='Name'
            type='text'
            placeholder='e.g. marketing'
            fullWidth
            value={formData.name}
            onChange={handleChange}
            {...getNameErrorProps()}
          />

          <TextField
            multiline
            fullWidth
            type='text'
            id='__create_team_description_input'
            name='description'
            label='Description (optional)'
            helperText="What's this team about?"
            value={formData.description}
            onChange={handleChange}
          />
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          disabled={!(formData.name.trim().length > 0) || loading}
          loading={loading}
          onClick={() => {
            createTeam();
          }}
        >
          Create
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

export default CreateTeam;
