import React from 'react';
import PropTypes from 'prop-types';
import ListIcon from '../../icons/16x16/List';
import PersonIcon from '../../icons/16x16/Person';
import CalendarIcon from '../../icons/16x16/Calendar';
import PriorityIcon from '../../icons/16x16/Priority';
import NotesIcon from '../../icons/16x16/Notes';
import DatePicker from '../../new-ui/DatePicker';
import PrimaryInput from './PrimaryInput';
import NotesInput from './NotesInput';
import FormField from './FormField';
import PrioritySelect from './PrioritySelect';
import AssigneePicker from './AssigneePicker';
import ListPicker from './ListPicker';

function TodoForm({ projectId, data, onChange }) {
  const handleTextInputChange = React.useCallback((event) => {
    const { name, value } = event.target;
    if (onChange) {
      onChange((prevData) => ({
        ...prevData,
        [name]: value
      }))
    }
  }, [onChange]);

  const fieldChangeHandler = React.useCallback((fName) => (fValue) => {
    if (onChange) {
      onChange((prevData) => ({
        ...prevData,
        [fName]: fValue
      }));
    }
  }, [onChange]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <PrimaryInput
        name='name'
        autoFocus
        placeholder='Write a task name'
        leftPadding={20 /* label width */ + 24 /* padding + margin */}
        value={data.name}
        onChange={handleTextInputChange}
      />

      <FormField label={<CalendarIcon />} >
        <DatePicker
          clearable={false}
          placeholder='Set due date'
          value={data.dueDate}
          onChange={fieldChangeHandler('dueDate')}
        />
      </FormField>

      <FormField label={<ListIcon />} >
        <ListPicker
          projectId={projectId}
          value={data.list}
          onChange={fieldChangeHandler('list')}
        />
      </FormField>

      <FormField label={<PersonIcon />} >
        <AssigneePicker
          projectId={projectId}
          value={data.assignee}
          onChange={fieldChangeHandler('assignee')}
        />
      </FormField>

      <FormField label={<PriorityIcon />} >
        <PrioritySelect
          value={data.priority}
          onChange={fieldChangeHandler('priority')}
        />
      </FormField>

      <FormField label={<NotesIcon />}>
        <NotesInput
          name='description'
          rows={4}
          fullWidth
          placeholder='Add more detail to this task...'
          value={data.description}
          onChange={handleTextInputChange}
        />
      </FormField>
    </form>
  );
}

TodoForm.propTypes = {
  projectId: PropTypes.string.isRequired,

  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    priority: PropTypes.oneOf(['NONE', 'LOW', 'MEDIUM', 'HIGH']).isRequired,
    dueDate: PropTypes.instanceOf(Date),

    assignee: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.string
    }),

    list: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired,

  // (prevData) => nextData
  onChange: PropTypes.func
};

export default TodoForm;
