import React from 'react';
import PropTypes from 'prop-types';
import FileDetails from '../ui/file/FileDetails';
import FileDetailsHeader from '../ui/file/FileDetailsHeader';
import FileDetailsCreator from '../ui/file/FileDetailsCreator';
import FileDetailsToolbar from '../ui/file/FileDetailsToolbar';
import DownloadFileIconButton from '../ui/file/DownloadFileIconButton';
import FileDetailsPreview from '../ui/file/FileDetailsPreview';
import FileDetailsMetadata from '../ui/file/FileDetailsMetadata';
import FullscreenToggleButton
from '../ui/file/FileDetailsFullscreenToggleButton';
import ZoomController from '../ui/file/FileDetailsZoomController';
import Preview from '../ui/file/preview/Preview';
import UploadMetadata from '../ui/file/UploadMetadata';
import FileNotes from '../ui/file/FileNotes';
import UploadMenu from './UploadMenu';
import ToolbarDivider from '../ui/file/FileDetailsToolbarDivider';
import FileDetailsFooter from '../ui/file/FileDetailsFooter';
import FileComments from './comments/FileComments';
import CommentComposer from './comments/CommentComposer';
import FollowersBar from './followers/FollowersBar';
import { DocumentType, convertMimeTypeToDocumentType }
from '../ui/file/mime/mime';

const DEFAULT_ZOOM = 100;

function needZoomController(previewMimeType) {
  const docType = convertMimeTypeToDocumentType(previewMimeType);

  switch(docType) {
    case DocumentType.IMAGE:
    case DocumentType.PDF:
      return true;

    default:
      return false;
  }
}

function UploadDetails({ projectId, creator, upload, parentUrl }) {
  const [fullscreen, setFullscreen] = React.useState(false);
  const [zoom, setZoom] = React.useState(DEFAULT_ZOOM);

  const handleFullscreenChange = (newValue) => {
    setFullscreen(newValue);
  };

  const handleExitFullscreen = () => {
    setFullscreen(false);
  };

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  React.useEffect(() => {
    if (!fullscreen) {
      setZoom(DEFAULT_ZOOM);
    }
  }, [fullscreen]);

  return (
    <FileDetails
      fullscreen={fullscreen}
      onExitFullscreen={handleExitFullscreen}
    >
      <FileDetailsHeader borderBottom={fullscreen}>
        <FileDetailsCreator
          creator={creator}
          updatedAt={upload.updatedAt}
        />

        {fullscreen && needZoomController(upload.previewType) && (
          <ZoomController
            value={zoom}
            onChange={handleZoomChange}
          />
        )}

        <FileDetailsToolbar>
          <FullscreenToggleButton
            fullscreen={fullscreen}
            onChange={handleFullscreenChange}
          />
          <DownloadFileIconButton downloadUrl={upload.downloadUrl} />

          <ToolbarDivider />

          <UploadMenu upload={{parentUrl, ...upload}} />
        </FileDetailsToolbar>
      </FileDetailsHeader>

      <FileDetailsPreview>
        <Preview
          src={upload.previewUrl}
          type={upload.previewType}
          downloadUrl={upload.downloadUrl}
          alt={upload.name}
          zoom={zoom}
          maxHeight={fullscreen ? 'none' : 320}
        />
      </FileDetailsPreview>

      {!fullscreen && (
        <FileDetailsMetadata>
          <UploadMetadata
            type={upload.type}
            name={upload.name}
            ext={upload.ext}
            size={upload.size}
          />
        </FileDetailsMetadata>
      )}

      {!fullscreen && upload.notes && (
        <FileNotes>{upload.notes}</FileNotes>
      )}

      <FileDetailsFooter hidden={fullscreen}>
        <FileComments
          projectId={projectId}
          fileId={upload.id}
        />
        <CommentComposer
          projectId={projectId}
          fileId={upload.id}
        />
        <FollowersBar
          projectId={projectId}
          fileId={upload.id}
        />
      </FileDetailsFooter>
    </FileDetails>
  );
}

UploadDetails.propTypes = {
  projectId: PropTypes.string.isRequired,
  creator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }).isRequired,

  upload: PropTypes.shape({
    id: PropTypes.string.isRequired,

    // The name (without extension) and the extension of an upload.
    name: PropTypes.string.isRequired,
    ext: PropTypes.string.isRequired,

    // The MIME type of an upload
    type: PropTypes.string.isRequired,

    // size in bytes of an upload
    size: PropTypes.number.isRequired,

    notes: PropTypes.string,

    url: PropTypes.string.isRequired,
    previewUrl: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string.isRequired,

    // timestamp
    updatedAt: PropTypes.string.isRequired
  }).isRequired,

  parentUrl: PropTypes.string.isRequired
};

export default UploadDetails;
