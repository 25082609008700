import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteOwner from './AutocompleteOwner';
import useReassignOwnerMutation from '../hooks/useReassignOwnerMutation';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import { PROJECT_MEMBERS_QUERY } from '../ProjectMembersQuery';

const useStyles = makeStyles((theme) => ({
  // To make it look alike a normal form label.
  header: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }
}));


function OwnerPickerWithData({ projectId, memberships }) {
  const classes = useStyles();

  const ownership = memberships.find((membership) => membership.owner);

  // TODO: Think of a better way to display success message.
  // Mutation.

  const { enqueueSuccessSnackbar } = useCustomSnackbar();

  const [reassignOwner] = useReassignOwnerMutation({
    onCompleted: (data) => {
      const newOwnershipId = data.reassignProjectOwner.id;

      const newOwnership = memberships.find(({ id }) => (
        id === newOwnershipId
      ));

      if (!newOwnership) { return; }

      const newOwner = newOwnership.user;
      const msg = `${newOwner.username} is now the Owner of the project`;
      enqueueSuccessSnackbar(msg);
    }
  });

  const handleOwnershipChange = (newOwnership) => {
    reassignOwner({
      variables: { membershipId: newOwnership.id },

      optimisticResponse: {
        __typename: 'Mutation',
        reassignProjectOwner: {
          __typename: 'ProjectMembership',
          id: newOwnership.id
        }
      },

      update: (client, { data: { reassignProjectOwner }}) => {
        const newOwnershipId = reassignProjectOwner.id;

        const prevData = client.readQuery({
          query: PROJECT_MEMBERS_QUERY,
          variables: { projectId }
        });

        const updatedMemberships = prevData.project.members.map((m) => ({
          ...m,
          owner: (m.id === newOwnershipId)
        }));

        client.writeQuery({
          query: PROJECT_MEMBERS_QUERY,
          variables: { projectId },
          data: {
            project: {
              ...prevData.project,
              members: updatedMemberships
            }
          }
        });
      }
    });
  };

  return (
    <div>
      <Typography variant='body1' className={classes.header}>
        Owned by
      </Typography>
      <AutocompleteOwner
        ownership={ownership}
        options={memberships}
        onOwnershipChange={handleOwnershipChange}
      />
    </div>
  );
}

const MembershipShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
});

OwnerPickerWithData.propTypes = {
  projectId: PropTypes.string.isRequired,
  memberships: PropTypes.arrayOf(MembershipShape).isRequired
};

export default OwnerPickerWithData;
