import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from './Avatar';
import { AVATAR_COLORS, AVATAR_DEFAULT_COLOR } from './colors';

function getAvatarLetters(nameString) {
  const words = nameString.trim().split(/\s+/);
  if (words.length === 1) {
    return words[0].substr(0, 2).toUpperCase();
  } else if (words.length > 1) {
    return (words[0].substr(0, 1) + words[1].substr(0, 1)).toUpperCase();
  } else {
    return null;
  }
}

const UserAvatar = React.forwardRef((props, ref) => {
  const { username, color, tooltip, ...otherProps } = props;
  const avatarLetters = getAvatarLetters(username);

  const avatar = avatarLetters ? (
    <Avatar color={color} {...otherProps}>
      {avatarLetters}
    </Avatar>
  ) : (
    <Avatar color={color} {...otherProps} />
  );

  return tooltip ? (
    <Tooltip title={username} placement='top' arrow>
      {avatar}
    </Tooltip>
  ) : avatar;
});

UserAvatar.propTypes = {
  username: PropTypes.string.isRequired,
  color: PropTypes.oneOf(AVATAR_COLORS),
  tooltip: PropTypes.bool
};

UserAvatar.defaultProps = {
  color: AVATAR_DEFAULT_COLOR,
  tooltip: false
};

export default UserAvatar;
