import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import ListItem from './ListItem';

function ListItemLink({ to, ...rest }) {
  const renderLink = React.useMemo(() => {
    return React.forwardRef(function Link(linkProps, ref) {
      return (
        <RouterLink
          role={undefined}
          to={to}
          ref={ref}
          {...linkProps}
        />
      )
    });
  }, [to]);

  return (
    <li>
      <ListItem component={renderLink} {...rest} />
    </li>
  );
}

ListItemLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default ListItemLink;
