import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../ui/button/Button';
import ArrowDropdownIcon from '../../icons/12x12/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: 'left',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25, 0.25, 0.25, 1)
  },
  label: {
    fontWeight: 600
  },
  endIconContainer: {
    flex: '0 0 auto',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function FolderDropdownButton({ label, ...rest }) {
  const classes = useStyles();

  return (
    <Button
      size='small'
      variant='contained'
      className={classes.button}
      {...rest}
    >
      <Typography
        variant='body2'
        noWrap
        className={classes.label}
      >
        {label}
      </Typography>
      <div className={classes.endIconContainer}>
        <ArrowDropdownIcon />
      </div>
    </Button>
  );
}

FolderDropdownButton.propTypes = {
  label: PropTypes.string.isRequired
};

export default FolderDropdownButton;
