import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import { FILTER_PRIMARY } from '../../ui/icons/Base';
import UserAvatar from '../../account/UserAvatar';
import DropdownIcon from './DropdownIcon';
import NoOwnerAvatar from './NoOwnerAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover $icon': {
      filter: FILTER_PRIMARY
    },
    '&:hover $list': {
      color: theme.palette.text.primary
    }
  },
  list: {
    flex: '1 1 auto',
    width: 0,
    padding: theme.spacing(1, 1.5),
    color: theme.palette.text.seconary
  },
  listItem: {
    padding: 0
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '1.0625rem'
  },
  icon: {}
}));

function OwnerDropdownButtonLabel({ owner }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component='div' className={classes.list}>
        <ListItem component='div' className={classes.listItem}>
          <ListItemAvatar>
            {owner ? (
              <UserAvatar user={owner} className={classes.avatar} />
            ) : (
              <NoOwnerAvatar iconClassName={classes.icon} />
            )}
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              color: owner ? 'textPrimary' : 'inherit',
              noWrap: true
            }}
            primary={owner ? owner.username : 'No owner'}
          />
        </ListItem>
      </List>
      <DropdownIcon className={classes.icon} />
    </div>
  );
}

OwnerDropdownButtonLabel.propTypes = {
  owner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
};

export default OwnerDropdownButtonLabel;
