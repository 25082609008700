import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  remindersFieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  }
}));

export default function RemindersFieldContainer(props) {
  const classes = useStyles();
  return <div className={classes.remindersFieldContainer} {...props} />;
}
