import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import AddTodoButtonRow from '../../ui/todo/sheet/AddTodoButtonRow';
import AddTodoFormRow from './AddTodoFormRow';
import useAppendTodoItemMutation from '../hooks/useAppendTodoItemMutation';
import { TODO_CRITERIA_SHAPE } from '../helpers';

function SectionFooter({ cols, sectionId, todoCriteria, rootUrl }) {
  const [formOpen, setFormOpen] = React.useState(false);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleFormClose = React.useCallback(() => {
    setFormOpen(false);
  }, []);

  const [appendTodoItem] = useAppendTodoItemMutation({
    todoCriteria,
    variables: { todoListId: sectionId }
  });

  const handleSubmit = (variables, optimisticResponse) => {
    appendTodoItem({
      variables: variables,
      optimisticResponse: {
        __typename: 'Mutation',
        appendTodoItem: {
          __typename: 'TodoItem',
          ...optimisticResponse
        }
      }
    });
  };

  // TODO: this is pretty uggly and repetitive. The reason why we do it
  // here is because we want to submit the todo (save it to backend) and
  // then redirect when the `ViewTodoDetailsButton` button is clicked.
  // See `ViewTodoDetailsButton.js` for more details.

  const history = useHistory();

  const [appendTodoItemAndRedirect] = useAppendTodoItemMutation({
    todoCriteria,
    variables: { todoListId: sectionId },
    onCompleted: (data) => {
      const newTodoId = data.appendTodoItem.id;
      history.push({
        pathname: `${rootUrl}/${newTodoId}`,
        todoCriteria
      });
    }
  });

  const handleSubmitAndRedirect = React.useCallback(
    (variables, optimisticResponse) => {
      appendTodoItemAndRedirect({
        variables: variables,
        optimisticResponse: {
          __typename: 'Mutation',
          appendTodoItem: {
            __typename: 'TodoItem',
            ...optimisticResponse
          }
        }
      });
    }, [appendTodoItemAndRedirect]);

  return (
    <tbody>
      <AddTodoFormRow
        open={formOpen}
        onClose={handleFormClose}
        sectionId={sectionId}
        todoCriteria={todoCriteria}
        cols={cols}
        onSubmit={handleSubmit}
        onSubmitAndRedirect={handleSubmitAndRedirect}
      />
      <AddTodoButtonRow
        colSpan={cols.length + 2}
        onClick={handleFormOpen}
      />
    </tbody>
  );
}

SectionFooter.propTypes = {
  // TODO: Prop-Drilling.
  // todolist id
  sectionId: PropTypes.string.isRequired,

  // TODO: Prop-Drilling.
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired,

  // sheet's columns
  cols: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    getCellProps: PropTypes.func.isRequired,
    renderCell: PropTypes.func.isRequired
  })).isRequired,

  // TodoSheet root url
  rootUrl: PropTypes.string.isRequired
};

export default SectionFooter;
