import React from 'react';
import PropTypes from 'prop-types';
import CreateEventFormDialog from '../form-dialog/CreateEventFormDialog';

function EventFormDialog({ projectId, date, time, onClose }) {
  return (
    <CreateEventFormDialog
      projectId={projectId}
      onClose={onClose}
      defaultStartDate={date}
      defaultStartTime={time.start}
      defaultEndTime={time.end}
      defaultAllDay={false}
    />
  );
}

const TimeShape = PropTypes.shape({
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired
});

EventFormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  time: PropTypes.shape({
    start: TimeShape.isRequired,
    end: TimeShape.isRequired
  }).isRequired,
  onClose: PropTypes.func
};

export default EventFormDialog;
