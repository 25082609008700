import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '../icons/16x16/ExpandMoreThin';
import ProjectMenu from './menu/ProjectCardMenu';
import ToolbarButton from './ProjectHeaderToolbarButton';

// TODO:
// 1. Highlight the triggered button when menu is open.
// 2. Remove redundant actions.
function ProjectHeaderMoreActionMenu({ projectId, pinned, archived }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ToolbarButton
        icon={<ExpandMoreIcon />}
        tooltip='More actions'
        onClick={handleClick}
      />

      <ProjectMenu
        projectId={projectId}
        pinned={pinned}
        archived={archived}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      />
    </div>
  );
}

ProjectHeaderMoreActionMenu.propTypes = {
  projectId: PropTypes.string.isRequired,
  pinned: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired
};

export default ProjectHeaderMoreActionMenu;
