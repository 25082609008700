import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontSize: '1.25rem'
  }
}))((props) => <AvatarGroup {...props} />);
