import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from './ListItemAvatar';
import ListItemText from './ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  notification: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5)
  }
}));

const ListItemSkeleton = React.forwardRef(
  function ListItemSkeleton(props, ref) {
    const classes = useStyles();
    return (
      <ListItem ref={ref} className={classes.root} {...props}>
        <div className={classes.notification}>
          <ListItemAvatar primary={ <Skeleton variant='circle' />} />
          <ListItemText
            primary={<Skeleton height={24} />}
            secondary={<Skeleton width='40%' height={16} />}
          />
        </div>
      </ListItem>
    );
  }
);

export default ListItemSkeleton;
