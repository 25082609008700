import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ZoomOutButton from './FileDetailsZoomOutButton';
import ZoomInButton from './FileDetailsZoomInButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const Zoom = Object.freeze({
  MIM: 10,
  MAX: 200,
  GAP: 10
});

const ZOOM_VALUES = (function () {
  let ret = [];
  let nextZoomValue = Zoom.MIM;

  while (nextZoomValue <= Zoom.MAX) {
    ret.push(nextZoomValue);
    nextZoomValue += Zoom.GAP
  }

  return ret;
})();


function FileDetailsZoomController({ value, onChange }) {
  const classes = useStyles();

  const onZoomOut = () => {
    if ((value > Zoom.MIM) && onChange) {
      onChange(value - Zoom.GAP);
    }
  };

  const onZoomIn = () => {
    if ((value < Zoom.MAX) && onChange) {
      onChange(value + Zoom.GAP);
    }
  };

  return (
    <div className={classes.root}>
      <ZoomOutButton
        onClick={onZoomOut}
        disabled={value <= Zoom.MIM}
      />
      <ZoomInButton
        onClick={onZoomIn}
        disabled={value >= Zoom.MAX}
      />
    </div>
  );
}

FileDetailsZoomController.propTypes = {
  value: PropTypes.oneOf(ZOOM_VALUES),
  onChange: PropTypes.func
};

export default FileDetailsZoomController;
