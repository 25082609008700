import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TimelineContext from './TimelineContext';

const useStyles = makeStyles((theme) => ({
  divider: {
    flex: '0 0 auto',
    zIndex: 1
  }
}));

function TimelineItemDivider({ className, style, ...rest }) {
  const { dividerWidth } = React.useContext(TimelineContext);
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.divider, className)}
      style={{ ...style, width: dividerWidth }}
      {...rest}
    />
  );
}

export default React.memo(TimelineItemDivider);
