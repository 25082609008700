import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import WorkspaceContext from '../../workspace/CurrentWorkspaceContext';
import { GET_MY_FAVORITE_PROJECTS_QUERY }
from '../favorites/MyFavoritesQuery';

const UNPIN_PROJECT_MUTATION = gql`
  mutation UnpinProject($projectId: ID!) {
    unpinProject(projectId: $projectId) {
      id
      pinned
    }
  }
`;

export default function useUnpinProjectMutation(options) {
  const { projectId, onCompleted: onFinished, ...otherOptions} = options;

  const { currentWorkspaceId } = React.useContext(WorkspaceContext);

  const {
    enqueueSuccessSnackbar,
    enqueueGraphQLErrorSnackbar
  } = useCustomSnackbar();

  return useMutation(UNPIN_PROJECT_MUTATION, {
    variables: { projectId },

    optimisticResponse: {
      __typename: 'Mutation',
      unpinProject: {
        __typename: 'Project',
        id: projectId,
        pinned: false
      }
    },

    update: (client, { data: { unpinProject }}) => {
      const oldData = client.readQuery({
        query: GET_MY_FAVORITE_PROJECTS_QUERY,
        variables: { workspaceId: currentWorkspaceId }
      });

      // `oldData` is undefined if the query not yet in the cache
      if (!oldData) { return; }

      const pid = unpinProject.id;
      const isUnpinned = !unpinProject.pinned;

      if (!isUnpinned) { return; }

      const oldFavorites = oldData.myFavoriteProjects;
      const newFavorites = oldFavorites.filter(({ id }) => (id !== pid));

      client.writeQuery({
        query: GET_MY_FAVORITE_PROJECTS_QUERY,
        variables: { workspaceId: currentWorkspaceId },
        data: {
          ...oldData,
          myFavoriteProjects: newFavorites
        }
      });
    },

    onCompleted(data) {
      onFinished && onFinished();
      const msg = 'The project was removed from your Favorites';
      enqueueSuccessSnackbar(msg);
    },

    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },

    ...otherOptions
  });
}
