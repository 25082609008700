import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    flex: '0 0 auto',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  borderBottom: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    marginBottom: 1
  }
}));

function FileDetailsHeader({ borderBottom, ...rest }) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.header, {
        [classes.borderBottom]: borderBottom
      })}
      {...rest}
    />
  );
}

FileDetailsHeader.propTypes = {
  borderBottom: PropTypes.bool
};

FileDetailsHeader.defaultProps = {
  borderBottom: false
};

export default FileDetailsHeader;
