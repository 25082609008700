import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = withStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1)
  }
}))(Dialog);

export default StyledDialog;
