import React from 'react';
import PropTypes from 'prop-types';
import ToolbarDeleteButton from './ui/header/ToolbarDeleteButton';
import useDeleteEvent from '../mutations/useDeleteEvent';

function DeleteEventButton({ event, beforeDeleteFn }) {
  const { project, ...eventWithoutProject } = event;
  const [deleteEvent] = useDeleteEvent(project.id);

  const handleDelete = (e) => {
    // Close dialog etc...
    if (beforeDeleteFn) {
      beforeDeleteFn();
    }

    deleteEvent(eventWithoutProject);
  };

  return <ToolbarDeleteButton onClick={handleDelete} />;
}

DeleteEventButton.propTypes = {
  // Which event to delete?
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,

    startsAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired,

    endsAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired,

    project: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,

  // Callback to be execuated just before the mutation takes action.
  beforeDeleteFn: PropTypes.func
};

export default DeleteEventButton;
