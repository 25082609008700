import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ActivityIcon from '../../../icons/16x16/Bubbles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  labelIcon: {
    marginRight: theme.spacing(3)
  },
  labelText: {
    color: theme.palette.text.secondary
  }
}));

function ActivityLabel() {
  const classes = useStyles();

  return (
    <div className={classes.label} >
      <ActivityIcon color='secondary' className={classes.labelIcon} />
      <Typography variant='subtitle2' className={classes.labelText}>
        Activities
      </Typography>
    </div>
  );
}

export default ActivityLabel;
