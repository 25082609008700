import React from 'react';
import PropTypes from 'prop-types';
import EventCardLink from './EventCardLink';
import EventDate from './EventDate';
import EventCardSubtitle from './EventCardSubtitle';
import { parseDateInput, DateInputType }
from '../../new-ui/datetime-picker/parseDateInput';

function EventDetails({ title, startDate, endDate, isAllDay, url }) {
  const start = parseDateInput(startDate);
  const end = parseDateInput(endDate);

  return (
    <EventCardLink
      avatar={ <EventDate date={start} /> }
      to={url}
      title={title}
      subtitle={(
        <EventCardSubtitle
          isAllDay={isAllDay}
          startDate={start}
          endDate={end}
        />
      )}
    />
  );
}

EventDetails.propTypes = {
  title: PropTypes.string.isRequired,
  startDate: DateInputType.isRequired,
  endDate: DateInputType.isRequired,
  isAllDay: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired
};

export default EventDetails;
