import React from 'react';
import MarginRow from './MarginRow';
import CellSkeleton from './CellSkeleton';

export default function SectionSkeleton() {
  return (
    <tbody>
      <MarginRow colSpan={3} />
      <tr>
        <CellSkeleton />
        <CellSkeleton />
        <CellSkeleton />
      </tr>
      <tr>
        <CellSkeleton />
        <CellSkeleton />
        <CellSkeleton />
      </tr>
      <tr>
        <CellSkeleton />
        <CellSkeleton />
        <CellSkeleton />
      </tr>
    </tbody>
  );
}
