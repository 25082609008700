import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { CHIP_EVENT, CHIP_TODO } from './fragments';
import { writeEntryToCache } from './schedulesCacheHelpers';

const PROJECT_SCHEDULE_ADDED_SUBSCRIPTION = gql`
  subscription OnProjectScheduleAdded($projectId: ID!) {
    newEventAdded(projectId: $projectId) {
      __typename

      ... on Event {
        ...ChipEvent
      }

      ... on TodoItem {
        ...ChipTodo
      }
    }
  }
  ${CHIP_EVENT}
  ${CHIP_TODO}
`;

function ScheduleAddedSubscription({ projectId }) {
  useSubscription(PROJECT_SCHEDULE_ADDED_SUBSCRIPTION, {
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) {
        return;
      }

      const entry = subscriptionData.data.newEventAdded;
      writeEntryToCache(client, { projectId, entry });
    }
  });

  return null;
}

ScheduleAddedSubscription.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ScheduleAddedSubscription;
