import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentField: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  fieldIcon: {
    width: theme.spacing(2.5),
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1.25),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldContent: {
    // A hack to help truncating long text
    width: 0,
    flex: '1 1 auto',

    minHeight: theme.spacing(4.5),
    padding: theme.spacing(0.5, 0)
  }
}));

function ContentField({ icon, children }) {
  const classes = useStyles();
  return (
    <div className={classes.contentField}>
      <div className={classes.fieldIcon}>
        {icon && React.cloneElement(icon, {
          color: 'secondary'
        })}
      </div>
      <div className={classes.fieldContent}>
        {children}
      </div>
    </div>
  );
}

ContentField.propTypes = {
  icon: PropTypes.node
};

export default ContentField;
