import React from 'react';
import Row from './Row';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    height: 64,
    border: `1px dashed ${theme.palette.primary.main} !important`,
    textAlign: 'center'
  }
}));

export default function DropzoneIndicatorRow({ colSpan }) {
  const classes = useStyles();

  return (
    <Row>
      <TableCell
        colSpan={colSpan}
        className={classes.cell}
      >
        <Typography variant='body1'>
          Drag and drop files you want to upload here, or choose
          {' '}
          <Box
            component='span'
            color='text.primary'
            fontWeight={700}
          >
            Add files
          </Box>
        </Typography>
      </TableCell>
    </Row>
  );
}
