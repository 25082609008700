import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AttachmentListItem from './AttachmentListItem';
import MultiPreview from '../file/preview/MultiPreview';

const useStyles = makeStyles((theme) => ({
  attachmentList: {
    padding: theme.spacing(1, 0),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

function AttachmentList({ attachments, onDelete }) {
  const classes = useStyles();
  return !attachments.length ? null : (
    <div className={classes.attachmentList}>
      <MultiPreview files={attachments}>
        {attachments.map((attachment, index) => (
          <AttachmentListItem
            key={attachment.id}
            index={index}
            attachment={attachment}
            onDelete={onDelete}
          />
        ))}
      </MultiPreview>
    </div>
  );
}

AttachmentList.propTypes = {
  attachments: PropTypes.array.isRequired,
  onDelete: PropTypes.func
};

export default AttachmentList;
