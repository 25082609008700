import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { tabValues, tabpanelA11yProps } from './Tabs';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  }
}));

function TabPanel({ children, value, activeTab, ...other }) {
  const classes = useStyles();
  return (
    <div
      role='tabpanel'
      hidden={value !== activeTab}
      className={classes.tabPanel}
      {...tabpanelA11yProps(value)}
      {...other}
    >
      {value === activeTab && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOf(tabValues).isRequired,
  activeTab: PropTypes.oneOf(tabValues).isRequired
};

export default TabPanel;
