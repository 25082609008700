import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TimelineItem from './TimelineItem';
import TimelineItemDivider from './TimelineItemDivider';
import TimelineItemContent from './TimelineItemContent';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 1, 0),
  },
  divider: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dot: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.text.primary,
    borderRadius: '50%',
    border: `4px solid ${theme.palette.background.paper}`
  },
  date: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: '1.125rem',
    ...theme.customMixins.wordWrap
  }
}));

function DateItem({ date, left }) {
  const classes = useStyles();
  return (
    <TimelineItem className={classes.root} left={left}>
      <TimelineItemDivider className={classes.divider}>
        <div className={classes.dot} />
      </TimelineItemDivider>
      <TimelineItemContent>
        <Typography variant='h6' className={classes.date}>
          {date}
        </Typography>
      </TimelineItemContent>
    </TimelineItem>
  );
}

DateItem.propTypes = {
  left: PropTypes.bool,
  date: PropTypes.string.isRequired
};

DateItem.defaultProps = {
  left: false
};

export default DateItem;
