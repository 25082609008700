import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../ui/notification/ListItem';
import ListItemAvatar from '../ui/notification/ListItemAvatar';
import ListItemText from '../ui/notification/ListItemText';
import UserAvatar from '../account/UserAvatar';
import { formatTime, useEventUrl, getActorName, getAlertMessage }
from './helpers';
import { generateEventCommentHtmlId }
from '../calendar/event-details/comments/helper';

function getNotificationText({ type, reminder, event, actor, updatedAt }) {
  const { title, project } = event;
  const timestamp = formatTime(updatedAt);
  const actorName = getActorName(actor);

  let primary = '';

  switch(type) {
    case 'CREATE':
      primary = title;
      break;
    case 'COMMENT':
      primary = `Re: ${title}`;
      break;
    case 'ALERT':
      primary = getAlertMessage(event, reminder)
      break;
    default:
      break;
  }

  return {
    primary,
    secondary: [actorName, project.name, timestamp]
  }
}

function useNotificationUrl({ type, event, comment }) {
  const eventUrl = useEventUrl(event);

  if (type === 'COMMENT') {
    const htmlId = generateEventCommentHtmlId(event.id, comment.id);
    return `${eventUrl}#${htmlId}`;
  } else {
    return eventUrl;
  }
}

// TODO:
function getSecondaryAvatar(type) {
  switch(type) {
    case 'CREATE':
      return 'SCHEDULE';
    case 'COMMENT':
      return 'MESSAGE';
    case 'ALERT':
      return 'ALERT';
    default:
      return null;
  }
}

const EventNotificationItem = React.forwardRef((props, ref) => {
  const { notification, onMarkAsRead, ...rest } = props;
  const { id, updatedAt, eventNotification } = notification;
  const notificationUrl = useNotificationUrl(eventNotification);

  const primaryAvatar =
    eventNotification.actor ?
    <UserAvatar user={eventNotification.actor} /> :
    null;

  const secondaryAvatar = getSecondaryAvatar(eventNotification.type);

  return (
    <ListItem
      ref={ref}
      id={id}
      url={notificationUrl}
      onMarkAsRead={onMarkAsRead}
      {...rest}
    >
      <ListItemAvatar
        primary={primaryAvatar}
        secondary={secondaryAvatar}
      />
      <ListItemText
        {...getNotificationText({
          ...eventNotification,
          updatedAt: updatedAt
        })}
      />
    </ListItem>
  );
});

EventNotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    eventNotification: PropTypes.object.isRequired,
  }).isRequired,
  onMarkAsRead: PropTypes.func.isRequired
};

export default EventNotificationItem;
