import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const DELETE_FOLDER_MUTATION = gql`
  mutation DeleteFolder($id: ID!) {
    deleteFolder(id: $id) {
      id
    }
  }
`;

export default function useDeleteFolderMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(DELETE_FOLDER_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
