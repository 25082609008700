import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import PersonIcon from '../../icons/20x20/Person';
import OptionDialog from '../options/OptionDialog';
import { projectOption } from '../options/OptionList';

const ReassignOwnerItem = React.forwardRef((props, ref) => {
  const { projectId, ...other } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();

    openDialog({
      dialogContent: (
        <OptionDialog
          projectId={projectId}
          defaultOption={projectOption.REASSIGN_OWNER}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Reassign project owner'
      icon={ <PersonIcon /> }
      onClick={handleClick}
      {...other}
    />
  );
});

ReassignOwnerItem.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ReassignOwnerItem;
