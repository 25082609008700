import gql from 'graphql-tag';
import { USER_PROFILE_FRAGMENT } from '../account/fragments';

export const TEAM_DETAILS_FRAGMENT = gql`
  fragment TeamDetails on Team {
    id
    name
    description
  }
`;

export const TEAM_STATS_FRAGMENT = gql`
  fragment TeamStats on Team {
    memberCount
    projectCount
  }
`;

export const TEAM_MEMBERSHIP_DETAILS_FRAGMENT = gql`
  fragment TeamMembershipDetails on TeamMembership {
    id
    guest
    user {
      ...UserProfile
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export const TEAM_MEMBERS_FRAGMENT = gql`
  fragment TeamMembers on Team {
    members {
      ...TeamMembershipDetails
    }
  }
  ${TEAM_MEMBERSHIP_DETAILS_FRAGMENT}
`;
