import React from 'react';
import PropTypes from 'prop-types';
import Error from '../../oops/Error';
import Header from './ui/header/Header';
import Toolbar from './ui/header/Toolbar';
import CloseButton from './ui/header/ToolbarCloseButton';
import Content from './ui/content/Content';
import EventNotFound from './EventNotFound';

function EventQueryError({ error, onClose }) {
  const isProduction = process.env.NODE_ENV === 'production';

  return isProduction ? (
    <EventNotFound onClose={onClose} />
  ) : (
    <>
      <Header>
        <Toolbar>
          <CloseButton onClick={onClose} />
        </Toolbar>
      </Header>
      <Content>
        <Error error={error} />
      </Content>
    </>
  );
}

EventQueryError.propTypes = {
  error: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default EventQueryError;
