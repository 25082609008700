import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import DialogContent from './DialogContent';
import DialogActionsContainer from './DialogActionsContainer';
import DeleteProjectConfirmText from './DeleteProjectConfirmText';
import DeleteProjectConfirmCheckbox from './DeleteProjectConfirmCheckbox';
import useDeleteProjectMutation from '../hooks/useDeleteProjectMutation';

function DeleteConfirm({
  id,
  name,
  onClose,
  actionsContainer
}) {
  const [yes, setYes] = React.useState(false);

  const handleYesChange = (event) => {
    setYes(event.target.checked);
  };

  // Mutation.
  const [deleteProject, { loading }] = useDeleteProjectMutation({
    variables: { projectId: id },
    onCompleted: (data) => {
      onClose();
    }
  });

  return (
    <DialogContent>
      <DeleteProjectConfirmText projectName={name} />

      <DeleteProjectConfirmCheckbox
        value={yes}
        onChange={handleYesChange}
      />

      <DialogActionsContainer container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='danger'
          disabled={!yes || loading}
          loading={loading}
          onClick={() => {
            deleteProject();
          }}
        >
          Yes, delete it
        </Button>
      </DialogActionsContainer>
    </DialogContent>
  )
}

DeleteConfirm.propTypes = {
  // The project's name and ID
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  // Close dialog
  onClose: PropTypes.func,

  // The DOM node to render dialog actions.
  actionsContainer: PropTypes.object
};

export default DeleteConfirm;
