import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AutocompleteUser from '../../autocomplete/AutocompleteUser';
import AutocompleteOwnerOption from './AutocompleteOwnerOption';
import Loading from './AutocompleteOwnerLoading';
import TextField from '../../ui/input/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  listbox: {
    padding: theme.spacing(1, 0)
  },
  option: {
    padding: theme.spacing(1, 1.5),
    [`&[aria-selected="true"]`]: {
      backgroundColor: '#E7F3FF'
    }
  }
}));

const filterOwnershipOptions = createFilterOptions({
  stringify: ({ user }) => `${user.username} ${user.email}`,
});

const renderOwnershipOption = ({ user }) => (
  <AutocompleteOwnerOption owner={user} />
);

const getOwnershipLabel = ({ user }) => user.username;

export default function StyledAutocompleteOwner(props) {
  const classes = useStyles();

  const renderInput = React.useCallback((params) => {
    return (
      <TextField
      fullWidth
      autoFocus={true}
      type='text'
      placeholder='Search by name or email'
      {...params}
      />
    );
  }, []);  

  return (
    <AutocompleteUser
    multiple={false}
    loadingText={ <Loading /> }
    renderInput={renderInput}
    getOptionLabel={getOwnershipLabel}      
    renderOption={renderOwnershipOption}
    filterOptions={filterOwnershipOptions}
    classes={classes}
    {...props}
    />
  );
}
