import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { makeStyles } from '@material-ui/core/styles';
import SearchInput from './SearchInput';
import DropdownMenu from './DropdownMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    // The width of this shit MUST be fixed for IntersectionObserver
    // to work propertly!
    flex: '0 0 auto',
    width: 400,
    display: 'flex',
    alignItems: 'center'
  }
}));

const scopeOptions = [
  {
    value: 'CURRENT_FOLDER',
    label: 'Current folder'
  },
  {
    value: 'CURRENT_PROJECT',
    label: 'Current project'
  }
];

const SEARCH_SCOPES = scopeOptions.map(({ value }) => value);
const DEFAULT_SEARCH_SCOPE = SEARCH_SCOPES[0];
const DEFAULT_SEARCH_CRITERIA = {
  matching: '',
  searchScope: DEFAULT_SEARCH_SCOPE
};

const getScopeOptionFromSearchScope = (searchScope) => {
  return scopeOptions.find((option) => option.value === searchScope);
};

function SearchCriteria({ criteria, onChange }) {
  const classes = useStyles();
  const isSearching = Boolean(criteria.matching);
  const inputRef = React.useRef(null);

  const handleMatchingChange = debounce((event) => {
    const newMatching = event.target.value;
    let newCriteria;

    // Limit the search scope to current folder when there is no matching
    // specified.
    if (!newMatching) {
      newCriteria = DEFAULT_SEARCH_CRITERIA;
    } else {
      newCriteria = {
        ...criteria,
        matching: newMatching
      };
    }

    notifyOnChange(newCriteria);
  }, 300);

  const handleScopeChange = (event, newScope) => {
    const newCriteria = {
      ...criteria,
      searchScope: newScope.value
    };
    notifyOnChange(newCriteria);
  };

  const notifyOnChange = (newCriteria) => {
    if(onChange) {
      onChange(newCriteria);
    }
  };

  // Clear the input field.
  React.useEffect(() => {
    if (!isSearching) {
      inputRef.current.value = '';
    }
  }, [isSearching]);

  return (
    <div className={classes.root}>
      <SearchInput
        ref={inputRef}
        defaultValue={criteria.matching}
        onChange={(event) => {
          event.persist();
          handleMatchingChange(event);
        }}
      />
      <DropdownMenu
        hidden={!isSearching}
        options={scopeOptions}
        value={getScopeOptionFromSearchScope(criteria.searchScope)}
        onChange={handleScopeChange}
      />
    </div>
  );
}

SearchCriteria.propTypes = {
  criteria: PropTypes.shape({
    matching: PropTypes.string,
    searchScope: PropTypes.oneOf(SEARCH_SCOPES).isRequired
  }).isRequired,
  onChange: PropTypes.func
};

SearchCriteria.defaultProps = {
  criteria: DEFAULT_SEARCH_CRITERIA
};

export default SearchCriteria;
export { SEARCH_SCOPES, DEFAULT_SEARCH_SCOPE, DEFAULT_SEARCH_CRITERIA };
