import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';
import { WS_ENDPOINT } from './endpoints';

// Create a WebSocket link to the Phoenix app's socket URL.
const absintheSocket = AbsintheSocket.create(new PhoenixSocket(
  WS_ENDPOINT,
  {
    reconnect: true,
    params: {
      get token() {
        const accessToken = localStorage.getItem('auth-token');
        return accessToken ? `Bearer ${accessToken}` : null;
      }
    }
  }
));

export default createAbsintheSocketLink(absintheSocket);
