import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_CODES } from '../file/list/UploadStatus';
import PresignedUrlLoading from '../file/list/UploadLoading';
import PresignedUrlQuery from './UploadPresignedUrlQuery';
import UploadTask from './UploadTask';

function UploadStatus({
  file,
  uploadId,
  statusCode,
  onStatusChange,
  projectId,
  ...rest
}) {
  return (
    <PresignedUrlQuery
      projectId={projectId}
      fileName={file.name}
      fileType={file.type}
      onLoading={() =>  <PresignedUrlLoading {...rest} />}
      onError={(error) =>  <div {...rest}>Error!</div>}
    >
      {({ key, uploadUrl }) => (
        <UploadTask
          path={key}
          presignedUrl={uploadUrl}
          file={file}
          uploadId={uploadId}
          statusCode={statusCode}
          onStatusChange={onStatusChange}
          {...rest}
        />
      )}
    </PresignedUrlQuery>
  );
}

UploadStatus.propTypes = {
  // The file to be uploaded.
  file: PropTypes.instanceOf(File).isRequired,

  uploadId: PropTypes.string.isRequired,
  statusCode: PropTypes.oneOf(STATUS_CODES).isRequired,
  onStatusChange: PropTypes.func.isRequired,

  // Used for presigned url query
  projectId: PropTypes.string.isRequired
};

export default UploadStatus;
