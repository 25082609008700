import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const UPDATE_UPLOAD_MUTATION = gql`
  mutation UpdateUpload($uploadId: ID!, $changes: UpdateUploadInput!) {
    updateUpload(uploadId: $uploadId, changes: $changes) {
      id
      name
      notes
      downloadUrl
    }
  }
`;

export default function useUpdateUploadMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UPDATE_UPLOAD_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
