import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Cell from './Cell';
import CellContent from './CellContent';

export default function CellSkeleton() {
  return (
    <Cell>
      <CellContent>
        <Skeleton width='60%' />
      </CellContent>
    </Cell>
  );
}
