import PropTypes from 'prop-types';
import { isDate, parseISO, isValid, format, isEqual } from 'date-fns';
import { COMPLETION_TYPES, matchesCompletionType } from './CompletionMenu';
import { matchesAssignee } from './AssigneeFilter';
import { DUE_DATE_RANGES, matchesDueDateRange } from './DueDateFilter';

// Helper function to convert from todo field name to a human readable
// string.
export function fromFieldNameToFieldLabel(fieldName) {
  switch(fieldName) {
    case 'task_name':
      return 'Task name';

    case 'assignee':
      return 'Assignee'

    case 'due_date':
      return 'Due date';

    case 'priority':
      return 'Priority';

    case 'description':
      return 'Description';

    default:
      return 'Unknown field name';
  }
}

// Helper function to move todo field `name` to new `index`.
export function reorderFields(fields, { name, index }) {
  const copies = fields.map((field) => Object.assign({}, field));

  // Invalid index => Nothing to do.
  if (index < 0 || index >= fields.length) return copies;

  const foundIndex = copies.findIndex((field) => field.name === name);
  if (foundIndex < 0) return copies;

  const [field] = copies.splice(foundIndex, 1);
  copies.splice(index, 0, field);
  return copies;
}

// Takes as arguments the previous state `prevState` and the current
// state `currState` of the same todoitem and returns an object
// containing the differences. For each different field, the
// value in `currState` will be returned.
export function diffTodoItemStates(prevState, currState) {
  // Two different todoitems. This should never happen.
  if (prevState.id !== currState.id) return null;

  let diff = {};

  // Check for priority field.
  if (currState.priority !== prevState.priority) {
    diff = {...diff, priority: currState.priority};
  }

  // Check for assignee.

  const prevAssignee = prevState.assignee;
  const currAssignee = currState.assignee;

  if (prevAssignee) {
    if (
      (currAssignee && currAssignee.id !== prevAssignee.id) ||
      !currAssignee
    ) {
      diff = {
        ...diff,
        assigneeId: currAssignee ? currAssignee.id : null
      };
    }
  } else {
    if (currAssignee) {
      diff = {...diff, assigneeId: currAssignee.id};
    }
  }

  // Check for name.
  if (currState.name !== prevState.name) {
    diff = {...diff, name: currState.name};
  }

  // Check for description
  if (currState.description !== prevState.description) {
    diff = {...diff, description: currState.description};
  }

  // Check for dueDate.

  const prevDueDate = isDate(prevState.dueDate) ?
                      prevState.dueDate :
                      parseISO(prevState.dueDate);

  const currDueDate = isDate(currState.dueDate) ?
                      currState.dueDate :
                      parseISO(currState.dueDate);

  if (currState.dueDate === null) {
    if (isValid(prevDueDate)) {
      diff = {...diff, dueDate: null};
    }
  } else if (isValid(currDueDate)) {
    if (!isValid(prevDueDate) || !isEqual(currDueDate, prevDueDate)) {
      diff = {...diff, dueDate: format(currDueDate, 'yyyy-MM-dd')};
    }
  }

  return diff;
}

// Does the given `todoItem` matches the given criteria.
export function matchesTodoCriteria(todoItem, criteria) {
  // See `TodoSheet.js`
  const { completionType, assignee, dueDateBetween } = criteria;

  return matchesCompletionType(todoItem, completionType) &&
         matchesAssignee(todoItem, assignee) &&
         matchesDueDateRange(todoItem, dueDateBetween);
}

// Helper function to format the date for backend.
export function formatDateForBackend(dateToFormat) {
  if (isDate(dateToFormat)) {
    return format(dateToFormat, 'yyyy-MM-dd');
  } else {
    return null;
  }
}

// The shape of todoCriteria
export const TODO_CRITERIA_SHAPE = PropTypes.exact({
  completionType: PropTypes.oneOf(COMPLETION_TYPES).isRequired,
  assignee: PropTypes.object,
  dueDateBetween: PropTypes.oneOf(
    DUE_DATE_RANGES.map(({ value }) => value)
  ).isRequired
});

//
// Fake new section IDs for optimistic responses.
//

var __newSectionId = 0;
const __newSectionIdPrefix = 'new_section_';
const __newSectionIdPattern = new RegExp(`^${__newSectionIdPrefix}`);

export function generateNewSectionId() {
  __newSectionId++;
  return __newSectionIdPrefix + __newSectionId;
}

export function matchesNewSectionId(sectionId) {
  return __newSectionIdPattern.test(sectionId);
}

//
// Fake new row IDs for optimistic responses.
//

var __newRowId = 0;
const __newRowIdPrefix = 'new_row_';
const __newRowIdPattern = new RegExp(`^${__newRowIdPrefix}`);

export function generateNewRowId() {
  __newRowId++;
  return __newRowIdPrefix + __newRowId;
}

export function matchesNewRowId(rowId) {
  return __newRowIdPattern.test(rowId);
}
