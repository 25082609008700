import React from 'react';
import PropTypes from 'prop-types';
import CollapsableBreadcrumbs from '../breadcrumbs/CollapsableBreadcrumbs';
import { isDirectory } from './mime/mime';

function getPathComponentName({ name, ext}) {
  // Directory
  if (!ext) {
    return name;
  }

  // Upload
  return `${name}${ext}`;
}

function Breadcrumbs({ rootUrl, path }) {
  const toUrl = ({ id, type }) => {
    if (isDirectory(type)) {
      return `${rootUrl}/directories/${id}`;
    } else {
      return `${rootUrl}/uploads/${id}`;
    }
  };

  const isLastComponent = (index) => {
    return (index + 1) === path.length;
  };

  const convertedPath = [
    {to: rootUrl, primary: 'root'},
    ...path.map((pathComponent, index) => ({
      primary: getPathComponentName(pathComponent),
      to: toUrl(pathComponent),
      interactive: !isLastComponent(index)
    }))
  ];

  return <CollapsableBreadcrumbs path={convertedPath} />;
}

Breadcrumbs.propTypes = {
  // The project files root directory
  rootUrl: PropTypes.string.isRequired,

  // The id and type are required for all path components except the
  // last one.
  path: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string
  })).isRequired
};

export default Breadcrumbs;
