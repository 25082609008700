import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import { MESSAGE_DETAILS, MESSAGE_AUTHOR } from './fragments';

const PROJECT_MESSAGES_QUERY = gql`
  query ListProjectMessages($projectId: ID!) {
    project(projectId: $projectId) {
      id
      messages {
        ...MessageDetails
        ...MessageAuthor
        commentCount
      }
    }
  }
  ${MESSAGE_DETAILS}
  ${MESSAGE_AUTHOR}
`;

function ProjectMessagesQuery({
  projectId,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    PROJECT_MESSAGES_QUERY,
    {
      variables: { projectId },
      ...otherQueryOptions
    }
  );

  if (loading) { return onLoading ? onLoading() : null; }
  if (error) { return onError ? onError(error) : <Error error={error} />; }
  return children(data.project.messages, otherQueryResults);
}

ProjectMessagesQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ProjectMessagesQuery;
