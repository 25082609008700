import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  video: {
    display: 'block',
    backgroundColor: theme.palette.common.black,
    width: '100%',
    height: '100%'
  }
}));

// See [1] for more details about error handling.
// [1] - https://developer.mozilla.org/en-US/docs/Web/Guide/
// Audio_and_video_delivery.
function Video({ sources, fallback, maxHeight, ...rest }) {
  const classes = useStyles();
  const [error, setError] = React.useState(false);

  const handleLastSourceError = (event) => {
    setError(true);
  };

  // Showing fallback content when no source could be decoded.
  if (error) { return fallback; }

  return (
    <video
      controls
      preload='auto'
      className={classes.video}
      style={{maxHeight: maxHeight}}
      {...rest}
    >
      {sources.map(({ src, type }, idx) => {
        const isLastSource = (idx + 1) === sources.length;

        return isLastSource ? (
          <source
            key={type}
            src={src}
            type={type}
            onError={handleLastSourceError}
          />
        ) : (
          <source
            key={type}
            src={src}
            type={type}
          />
        );
      })}

      {/* also fallback for browsers that don't support video tag */}
      {fallback}
    </video>
  );
}

Video.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({
    // The source and MIME type of the video
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })).isRequired,

  // Fallback node to render when either non of the sources could be decoded
  // in the current browser or the current browser does not support the
  // video tag.
  fallback: PropTypes.node,

  // The CSS maxHeight attribute applied to the video element.
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['none'])
  ])
};

Video.defaultProps = {
  fallback: (
    <div>
      Sorry, your browser doesn't support embedded videos.
    </div>
  ),

  maxHeight: 'none'
};

export default Video;
