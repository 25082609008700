import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Button from './Button';

const RouterButton = React.forwardRef(function RouterButton(props, ref) {
  const { to, ...otherProps } = props;
  return (
    <Button
      ref={ref}
      {...otherProps}
      component={RouterLink}
      to={to}
    />
  );
});

RouterButton.propTypes = {
  to: PropTypes.string.isRequired
};

export default RouterButton;
