import React from 'react';
import List from '@material-ui/core/List';
import MobileNavItem from './MobileNavItem';
import useNavItems from './useNavItems';

function MobileNav() {
  const navItems = useNavItems();
  return (
    <List component='nav' aria-label='workspace navigation'>
      {navItems.map((props) => (
        <MobileNavItem key={props.primary} {...props} />
      ))}
    </List>
  );
}

export default MobileNav;
