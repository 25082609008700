import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../ui/todo/ToolbarMenu';
import MenuItem from '../ui/todo/ToolbarMenuItem';
import ToolbarButton from '../ui/todo/ToolbarButton';
import CheckCircleIcon from '../icons/12x12/CheckCircle';

// Mapping from completionType to completionLabel
const COMPLETION_LABEL = {
  'INCOMPLETE': 'Incomplete tasks',
  'COMPLETED': 'Completed tasks',
  'ALL': 'All tasks'
};

const COMPLETION_TYPES = Object.keys(COMPLETION_LABEL);
const DEFAULT_COMPLETION_TYPE = 'INCOMPLETE';

function CompletionMenu({ value, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCompletionTypeChange = (newCompletionType) => {
    if (onChange) {
      onChange(newCompletionType);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <ToolbarButton
        color={open ? 'primaryDeemphasized' : 'secondary'}
        startIcon={<CheckCircleIcon color='secondary' />}
        onClick={handleOpen}
      >
        {COMPLETION_LABEL[value]}
      </ToolbarButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {COMPLETION_TYPES.map((completionType) => (
          <MenuItem
            key={completionType}
            selected={completionType === value }
            onClick={(event) => onCompletionTypeChange(completionType)}
          >
            {COMPLETION_LABEL[completionType]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

CompletionMenu.propTypes = {
  // The current selected menu item
  value: PropTypes.oneOf(COMPLETION_TYPES),

  // callback to be executed when new item is selected.
  onChange: PropTypes.func
};

CompletionMenu.defaultProps = {
  value: DEFAULT_COMPLETION_TYPE
};

// Does the given `todoItem` match the specified `completionType`?
export function matchesCompletionType(todoItem, completionType) {
  if (!todoItem) {
    return false;
  }

  if (completionType === 'ALL') {
    return true;
  } else if (completionType === 'INCOMPLETE') {
    return !todoItem.completed;
  } else if (completionType === 'COMPLETED') {
    return todoItem.completed;
  } else {
    return false;
  }
}

export default CompletionMenu;
export { DEFAULT_COMPLETION_TYPE, COMPLETION_TYPES };
