import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import { TODO_LIST_COMPLETION_COUNT } from './fragments';

const TODO_LIST_NAME_AND_COMPLETION_COUNT_QUERY = gql`
  query GetTodoListNameAndCompletionCount($todoListId: ID!) {
    todoList(todoListId: $todoListId) {
      id
      name
      ...TodoListCompletionCount
    }
  }
  ${TODO_LIST_COMPLETION_COUNT}
`;

function TodoListNameAndCompletionCountQuery({
  todoListId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, error, data, ...otherQueryResults } = useQuery(
    TODO_LIST_NAME_AND_COMPLETION_COUNT_QUERY,
    {
      variables: { todoListId },
      ...otherQueryProps
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.todoList, otherQueryResults);
}

TodoListNameAndCompletionCountQuery.propTypes = {
  todoListId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default TodoListNameAndCompletionCountQuery;
