import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ActivityItem from './ActivityItem';
import ActionIcon from './ActionIcon';
import ActivityContent from './ActivityContent';
import ActivityTitle from './ActivityTitle';

export default function ActivityItemSkeleton() {
  return (
    <ActivityItem>
      <ActionIcon
        icon={(
          <Skeleton variant='circle' width={28} height={28} />
        )}
      />
      <ActivityContent>
        <ActivityTitle>
          <Skeleton />
        </ActivityTitle>
        <Skeleton
          width='40%'
          height={12}
          style={{marginBottom: 16}}
        />
        <Skeleton variant='rect' height={64} />
      </ActivityContent>
    </ActivityItem>
  );
}
