import React from 'react';
import PropTypes from 'prop-types';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dot: {
    padding: theme.spacing(1.5)
  },
  dotWithIcon: {
    padding: 0,
    border: 0,
    backgroundColor: 'transparent'
  }
}));

function ActionIcon({ icon }) {
  const classes = useStyles();
  return (
    <TimelineSeparator>
      {icon ? (
        <TimelineDot className={classes.dotWithIcon}>
          {icon}
        </TimelineDot>
      ) : (
        <TimelineDot className={classes.dot} />
      )}
      <TimelineConnector />
    </TimelineSeparator>
  );
}

ActionIcon.propTypes = {
  icon: PropTypes.node
};

export default ActionIcon;
