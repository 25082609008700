import React from 'react';
import PropTypes from 'prop-types';
import TaskIcon from '../../ui/svg-icons/TaskAlt';
import { getEventTitle, isTodo } from '../helpers';
import combineAllDaySchedulesWithGridAttributes
from './utils/combineAllDaySchedulesWithGridAttributes';
import getScheduleKey from '../daily-schedule/getScheduleKey';
import isSameTypeAndId from '../daily-schedule/isSameTypeAndId';
import AllDayGrid from './AllDayGrid';
import AllDayItem from './AllDayItem';

function commonChipProps(schedule) {
  return {
    label: getEventTitle(schedule),
    labelStrikeThrough: !!schedule.completed,
    startIcon: isTodo(schedule) ? <TaskIcon /> : null,
    color: schedule.color,
    'data-schedule-id': schedule.id,
    'data-schedule-type': schedule.__typename,
  };
}

function convertData(schedules, daysRange) {
  const withGridAttributes = combineAllDaySchedulesWithGridAttributes(
    schedules,
    daysRange
  );

  const rowIndices = withGridAttributes.map(({ gridAttributes }) => (
    gridAttributes.rowIndex
  ));

  return {
    numRows: !rowIndices.length ? 1 : Math.max(...rowIndices) + 1,
    items: withGridAttributes.map(({ schedule, gridAttributes }) => ({
      schedule,
      chipProps: {
        ...gridAttributes,
        ...commonChipProps(schedule)
      }
    }))
  };
}

function AllDaySchedule({ projectId, days, schedules }) {
  const [dragItem, setDragItem] = React.useState(null);

  const numCols = days.length;
  const start = days[0];
  const end = days[numCols - 1];
  const { numRows, items } = convertData(schedules, { start, end });

  const handleDragStart = React.useCallback((schedule) => {
    const foundItem = items.find((item) => (
      isSameTypeAndId(item.schedule, schedule)
    ));

    if (foundItem) {
      setDragItem(foundItem);
    }
  }, [items]);

  const handleDragEnd = React.useCallback(() => {
    setDragItem(null);
  }, []);

  const isDragging = (schedule) => {
    return dragItem && isSameTypeAndId(schedule, dragItem.schedule);
  };

  return (
    <AllDayGrid
      projectId={projectId}
      days={days}
      numRows={numRows}
      numCols={numCols}
      dragItem={dragItem}
      dragZIndex={numRows + 1}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {items.map(({ schedule, chipProps }) => (
        <AllDayItem
          key={getScheduleKey(schedule)}
          projectId={projectId}
          schedule={schedule}
          chipProps={{
            ...chipProps,
            disabled: isDragging(schedule)
          }}
        />
      ))}
    </AllDayGrid>
  );
}

AllDaySchedule.propTypes = {
  projectId: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
  schedules: PropTypes.array.isRequired
};

export default AllDaySchedule;
