import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../button/IconButton';
import SearchIcon from '../../icons/16x16/MagnifyingGlass';

const useStyles = makeStyles((theme) => ({
  search: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F0F2F5',
    paddingLeft: 4,
    paddingRight: 12,
    marginRight: 12
  },
  inputRoot: {
    flex: 1
  }
}));

const SearchInput = React.forwardRef(
  function SearchInput(props, ref) {
    const classes = useStyles();

    return (
      <Paper elevation={0} className={classes.search}>
        <IconButton
          disabled
          icon={<SearchIcon />}
          size='small'
          color='secondary'
          background={false}
        />
        <InputBase
          inputRef={ref}
          type='search'
          placeholder='Search'
          inputProps={{ 'aria-label': 'Search' }}
          classes={{
            root: classes.inputRoot
          }}
          {...props}
        />
      </Paper>
    );
  }
);

export default SearchInput;
