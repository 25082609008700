import React from 'react';
import PropTypes from 'prop-types';
import MuiMenu from '@material-ui/core/Menu';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '../menu/MenuItem';
import IconButton from '../button/IconButton';
import MoreIcon from '../../icons/18x18/Ellipsis';

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 8,
    boxShadow: theme.customShadow.menu
  }
}))((props) => (
  <MuiMenu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  menu: {
    opacity: 0
  },
  menuOpen: {
    opacity: 1
  }
}));

function Menu({ className, onEdit, onDelete }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const open = Boolean(anchorEl);

  //
  // Client API.
  //

  const handleEdit = (event) => {
    handleClose();
    if (onEdit) { onEdit(event) ;}
  };

  const handleDelete = (event) => {
    handleClose();
    if (onDelete) { onDelete(event) ;}
  };

  return (
    <div
      className={clsx(classes.menu, {
        [classes.menuOpen]: open
      }, className)}
    >
      <IconButton
        background={open}
        size='small'
        color={open ? 'accent' : 'secondary'}
        icon={<MoreIcon />}
        onClick={handleOpen}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem primary='Edit comment' onClick={handleEdit} />
        <MenuItem primary='Delete comment' onClick={handleDelete} />
      </StyledMenu>
    </div>
  );
}

Menu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default Menu;
