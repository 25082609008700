import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import UploadQuery from './UploadQuery';
import Toolbar from '../ui/file/Toolbar';
import Breadcrumbs from '../ui/file/Breadcrumbs';
import UploadDetails from './UploadDetails';
import UploadLoading from './UploadLoading';

function getParentUrl(rootUrl, path) {
  if (path.length === 1) {
    return rootUrl;
  }

  const parent = path[path.length - 2];
  return `${rootUrl}/directories/${parent.id}`;
}

function Upload({ rootUrl, projectId }) {
  const { uploadId } = useParams();
  return (
    <UploadQuery
      fetchPolicy='network-only'
      projectId={projectId}
      id={uploadId}
      onLoading={() => <UploadLoading />}
    >
      {({ creator, path, ...upload }) => (
        <React.Fragment>
          <Toolbar>
            <Breadcrumbs rootUrl={rootUrl} path={path} />
          </Toolbar>
          <UploadDetails
            projectId={projectId}
            parentUrl={getParentUrl(rootUrl, path)}
            creator={creator}
            upload={upload}
          />
        </React.Fragment>
      )}
    </UploadQuery>
  );
}

Upload.propTypes = {
  // The root directory of the project
  rootUrl: PropTypes.string.isRequired,

  projectId: PropTypes.string.isRequired
};

export default Upload;
