import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/MultiStepTitle';
import { uploadOption } from './OptionList';

function getDialogTitleForOption(option) {
  switch(option) {
    case uploadOption.EDIT_UPLOAD:
      return 'File details';
    case uploadOption.DELETE_UPLOAD:
      return 'Delete file';
    default:
      return null;
  }
}

function OptionDialogTitle({ option, ...rest }) {
  const title = getDialogTitleForOption(option);
  return (
    <DialogTitle noWrap {...rest}>
      {title}
    </DialogTitle>
  );
}

OptionDialogTitle.propTypes = {
  option: PropTypes.oneOf(Object.values(uploadOption))
};

export default OptionDialogTitle;
