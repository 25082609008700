import React from 'react';
import PropTypes from 'prop-types';
import StyledReminderItem from './StyledReminderItem';
import ReminderMethodSelect from './ReminderMethodSelect';
import ReminderQuantityInput from './ReminderQuantityInput';
import ReminderUnitSelect from './ReminderUnitSelect';
import getReminderQuantityRange from './getReminderQuantityRange';
import {
  EVENT_REMINDER_METHODS,
  TIME_EVENT_REMINDER_UNIT_OPTIONS,
  TIME_EVENT_REMINDER_UNITS
} from './constants';

function updateRemindersFn(key, propertyName, propertyValue) {
  return (prevData) => ({
    ...prevData,
    timeReminders: prevData.timeReminders.map((reminder) => {
      if (reminder._id !== key) {
        return reminder;
      }

      return {
        ...reminder,
        [propertyName]: propertyValue
      };
    })
  });
}

function TimeReminderItem({ value, onChange }) {
  const { _id, method, offsetUnit, offsetQuantity } = value;
  const { min, max } = getReminderQuantityRange(offsetUnit);

  const handleMethodChange = React.useCallback((event) => {
    const newMethod = event.target.value;
    onChange(updateRemindersFn(_id, 'method', newMethod));
  }, [_id, onChange]);

  const handleUnitChange = React.useCallback((event) => {
    const newUnit = event.target.value;
    onChange(updateRemindersFn(_id, 'offsetUnit', newUnit));
  }, [_id, onChange]);

  const handleQuantityChange = React.useCallback((event) => {
    const newQuantity = event.target.value;
    onChange(updateRemindersFn(_id, 'offsetQuantity', newQuantity));
  }, [_id, onChange]);

  const handleDelete = React.useCallback((event) => {
    onChange((prevData) => ({
      ...prevData,
      timeReminders: prevData.timeReminders.filter((r) => r._id !== _id )
    }));
  }, [_id, onChange]);

  const isQuantityInvalid = isNaN(offsetQuantity) ||
                            offsetQuantity < min ||
                            offsetQuantity > max;

  return (
    <StyledReminderItem onDelete={handleDelete}>
      <ReminderMethodSelect
        value={method}
        onChange={handleMethodChange}
      />
      <ReminderQuantityInput
        min={min}
        max={max}
        error={isQuantityInvalid}
        value={offsetQuantity}
        onChange={handleQuantityChange}
      />
      <ReminderUnitSelect
        options={TIME_EVENT_REMINDER_UNIT_OPTIONS}
        value={offsetUnit}
        onChange={handleUnitChange}
      />
    </StyledReminderItem>
  );
}

TimeReminderItem.propTypes = {
  value: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    method: PropTypes.oneOf(EVENT_REMINDER_METHODS).isRequired,
    offsetUnit: PropTypes.oneOf(TIME_EVENT_REMINDER_UNITS).isRequired,
    offsetQuantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired
  }).isRequired,

  onChange: PropTypes.func.isRequired
};

export default TimeReminderItem;
