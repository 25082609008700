import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/button/ButtonLink';
import DialogContext from '../ui/dialog/DialogContext';
import OptionDialog from './options/OptionDialog';
import { projectOption } from './options/OptionList';

function ArchivedWarningTrashButton({ projectId, ...rest }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: (
        <OptionDialog
          projectId={projectId}
          defaultOption={projectOption.DELETE_PROJECT}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <Button onClick={handleClick} {...rest}>
      put it in the trash
    </Button>
  );
}

ArchivedWarningTrashButton.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ArchivedWarningTrashButton;
