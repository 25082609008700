import React from 'react';
import clsx from 'clsx';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import UncheckedIcon from '../../../icons/16x16/CheckCircle';
import CheckedIcon from '../../../icons/16x16/CheckCircleFill';
import { FILTER_PRIMARY } from '../../../icons/Base';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 6,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&:hover $icon': {
      filter: FILTER_PRIMARY
    },
    '&.Mui-focusVisible': {
      outline: 0,
      borderColor: theme.palette.primary.main
    },
    '&.Mui-focusVisible $icon': {
      filter: FILTER_PRIMARY
    }
  },
  icon: {}
}));

export default function Checkbox({ className, ...rest }) {
  const classes = useStyles();
  return (
    <MuiCheckbox
      disableRipple
      color='default'
      icon={(
        <UncheckedIcon
          color={rest.disabled ? 'disabled' : 'secondary'}
          className={classes.icon}
        />
      )}
      checkedIcon={(
        <CheckedIcon color='accent' className={classes.checkedIcon} />
      )}
      className={clsx(classes.root, className)}
      inputProps={{
        tabIndex: -1
      }}
      {...rest}
    />
  );
}
