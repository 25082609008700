import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import useMemberRemovedSubscription
from './hooks/useMemberRemovedSubscription';
import { TEAM_MEMBERS_FRAGMENT } from './fragments';

function TeamCardMemberRemovedSubscriber({ teamId }) {
  useMemberRemovedSubscription({
    teamId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) return;

      const { teamMembership: removedMember } =
        subscriptionData.data.teamMemberRemoved;

      const fragmentObj = {
        id: `Team:${teamId}`,
        fragment: gql`fragment TeamMembersWithCount on Team {
          ...TeamMembers
          memberCount},
          ${TEAM_MEMBERS_FRAGMENT}
        `,
        fragmentName: 'TeamMembersWithCount'
      };

      const oldData = client.readFragment(fragmentObj);
      const updatedMembers = oldData.members.filter(({ id }) => (
        id !== removedMember.id
      ));

      client.writeFragment({
        ...fragmentObj,
        data: {
          ...oldData,
          memberCount: updatedMembers.length,
          members: updatedMembers
        }
      });
    }
  });

  return null;
}

TeamCardMemberRemovedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamCardMemberRemovedSubscriber);
