import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  pathBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.shadow
  },
  path: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
    animationDuration: '1.2s',
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

function Spinner(props) {
  const classes = useStyles();
  const { size, thickness, className, style, ...otherProps } = props;

  const defaultSize = 40;
  const defaultThickness = 4;

  const commonProps = {
    size: size || defaultSize,
    thickness: thickness || defaultThickness
  };

  return (
    <div
      className={clsx(classes.root, className)}
      style={{
        ...style,
        height: size || defaultSize,
        width: size || defaultSize
      }}
      {...otherProps}
    >
      <CircularProgress
        variant='determinate'
        className={classes.pathBackground}
        {...commonProps}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        className={classes.path}
        classes={{
          circle: classes.circle,
        }}
        {...commonProps}
      />
    </div>
  );
}

export default Spinner;
