import React from 'react';
import { SizeMe } from 'react-sizeme';
import WithHeightLinearStep from './WithHeightLinearStep';

const LinearStep = React.forwardRef(function LinearStep(props, ref) {
  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <WithHeightLinearStep
          ref={ref}
          height={size.height}
          {...props}
        />
      )}
    </SizeMe>
  );
});

export default LinearStep;
