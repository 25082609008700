import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import Menu from '../../ui/menu/Menu';
import MenuItem from '../../ui/menu/MenuItem';
import TabCollapseButton from './TabCollapseButton';

function TabCollapseMenu({ menuItems }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Any active link in the menu?
  const location = useLocation();
  const selected = menuItems.some(({ to, exact, strict, isActive }) => {
    const match = matchPath(location.pathname, {
      path: to,
      exact,
      strict
    });

    if (!isActive) return !!match;
    return isActive(match, location);
  });

  return (
    <React.Fragment>
      <TabCollapseButton selected={selected} onClick={handleClick} />
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: 320
          }
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {menuItems.map(({ primary, ...otherItemProps }) => (
          <MenuItem
            key={primary}
            activeClassName='Mui-selected'
            component={ NavLink }
            onClick={handleClose}
            primary={primary}
            {...otherItemProps}
          />
        ))}
      </Menu>
    </React.Fragment>
  );
}

TabCollapseMenu.propTypes = {
  menuItems: PropTypes.array.isRequired
};

export default TabCollapseMenu;
