import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../ui/input/TextField';
import DialogForm from '../../ui/dialog/DialogForm';
import Button from '../../ui/button/Button';
import DialogContent from './DialogContent';
import DialogActionsContainer from './DialogActionsContainer';
import useUpdateProjectDetailsMutation
from '../hooks/useUpdateProjectDetailsMutation';

// TODO: This shit is unacceptably long, please refactor it
// into several components.
function EditProject({
  id,
  name,
  description,
  onClose,
  actionsContainer
}) {
  const [formData, setFormData] = React.useState({
    name: name,
    description: description || ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Sync when name changed.
  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      name: name
    }));
  }, [name]);

  // Sync when description changed.
  React.useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      description: description || ''
    }));
  }, [description]);

  // Graphql mutation.

  const [
    updateProjectDetails,
    { loading }
  ] = useUpdateProjectDetailsMutation({
    variables: {
      projectId: id,
      ...formData
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateProject: {
        __typename: 'Project',
        id: id,
        ...formData
      }
    },
    onCompleted(data) {
      onClose();
    }
  });

  // TODO: FIXME
  const isSubmitDisabled = () => {
    const trimmedDesc = formData.description.trim();
    const trimmedName = formData.name.trim();
    const isSameName = trimmedName === name;
    const isSameDesc = (!description && trimmedDesc.length === 0) ||
                       (trimmedDesc === description);

    if (isSameName && isSameDesc) {
      return true;
    }

    if (trimmedName.length < 3) {
      return true;
    }

    return false;
  };

  return (
    <DialogContent>
      <DialogForm>
        <TextField
          fullWidth
          autoFocus
          type='text'
          id='__edit_project_name_input'
          name='name'
          label='Name'
          placeholder='Project name'
          autoComplete='off'
          value={formData.name}
          onChange={handleChange}
        />

        <TextField
          multiline
          fullWidth
          type='text'
          id='__edit_project_description_input'
          name='description'
          label='Description'
          helperText="What's this project about?"
          value={formData.description}
          onChange={handleChange}
        />
      </DialogForm>

      <DialogActionsContainer container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          disabled={loading}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          disabled={isSubmitDisabled()}
          onClick={() => {
            updateProjectDetails();
          }}
        >
          Save changes
        </Button>
      </DialogActionsContainer>
    </DialogContent>
  )
}

EditProject.propTypes = {
  // Project's details
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,

  // Close dialog
  onClose: PropTypes.func.isRequired,

  // A DOM node: where to mount dialog actions?
  actionsContainer: PropTypes.object
};

export default EditProject;
