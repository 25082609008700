import React from 'react';
import PropTypes from 'prop-types';
import ListAutocomplete from './ListAutocomplete';
import useTodoListsLazyQuery
from '../../project/hooks/useTodoListsLazyQuery';

// TODO: Add 'creatable' property to the autocomplete to allow users
// to create new list.
function ListPicker({ projectId, value, onChange, clearable }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [
    fetchTodoLists,
    { called, loading }
  ] = useTodoListsLazyQuery({
    projectId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const lists = data.project.todoLists;
      setOptions(lists);
    }
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  // Fetch whenever open
  React.useEffect(() => {
    if (open) {
      fetchTodoLists();
    }
  }, [open, fetchTodoLists]);

  // TODO: do we really need to reset the options when this shit closes,
  // and also the user of `network-only` fetch policy above?
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <ListAutocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      loading={called && loading}
      value={value}
      onChange={handleChange}
      disableClearable={!clearable}
    />
  );
}

ListPicker.propTypes = {
  projectId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,
  clearable: PropTypes.bool
};

ListPicker.defaultProps = {
  clearable: true
};

export default ListPicker;
