import { useCallback } from 'react';
import { RichUtils, getDefaultKeyBinding } from 'draft-js';

// Custom key binding function so that nest lists are handled correctly
// on tab.
export default function useCustomKeyBindingFn(dispatchEditorState) {
  return useCallback((e) => {
    if (e.keyCode === 9 /* tab */) {
      e.persist();
      dispatchEditorState((prevState) => (
        RichUtils.onTab(e, prevState, 6 /* max-depth */)
      ));
      return null;
    }
    return getDefaultKeyBinding(e);
  }, [dispatchEditorState]);
}
