import React from 'react';
import PropTypes from 'prop-types';
import DialogContext from '../../../ui/dialog/DialogContext';
import EventFormDialog from '../../form-dialog/EventFormDialog';
import useUpdateEvent from '../../mutations/useUpdateEvent';

function FormDialog({ event }) {
  const { closeDialog } = React.useContext(DialogContext);
  const [saveChanges] = useUpdateEvent(event.id);

  const handleSubmit = (changes, optimisticResponse) => {
    closeDialog();
    saveChanges(changes, optimisticResponse);
  };

  return (
    <EventFormDialog
      projectId={event.project.id}
      rawEvent={event}
      onClose={closeDialog}
      onSubmit={handleSubmit}
    />
  );
}

FormDialog.propTypes = {
  event: PropTypes.object.isRequired
};

export default FormDialog;
