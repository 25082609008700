import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import ColorRadio from './ColorRadio';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  label: {
    margin: 4
  }
}));

function ColorPicker({ colors, ...otherProps }) {
  const classes = useStyles();
  return (
    <RadioGroup className={classes.root} {...otherProps}>
      {colors.map((color) => (
        <FormControlLabel
          key={color}
          value={color}
          control={ <ColorRadio /> }
          className={classes.label}
        />
      ))}
    </RadioGroup>
  );
}

ColorPicker.propTypes = {
  colors: PropTypes.array.isRequired
};

export default ColorPicker;
