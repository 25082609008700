import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import NotFound from '../oops/SectionNotFound';

const DIRECTORY_QUERY = gql`
  query GetDirectory($projectId: ID!, $id: ID!) {
    project(projectId: $projectId) {
      id
      directory(id: $id) {
        id
        name
        path {
          id
          name
          type
        }
      }
    }
  }
`;

const rootDirectory = {
  id: 'root',
  name: 'Current project',
  path: []
};

function DirectoryQuery({
  projectId,
  id,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const isRootDirectory = id === rootDirectory.id;

  const { loading, error, data, ...otherQueryResults } = useQuery(
    DIRECTORY_QUERY,
    {
      variables: { projectId, id },
      skip: isRootDirectory,
      ...otherQueryProps
    }
  );

  if (isRootDirectory) {
    return children(rootDirectory, otherQueryResults);
  }

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  if (!data || !data.project.directory) {
    return <NotFound />;
  }

  return children(data.project.directory, otherQueryResults);
}

DirectoryQuery.propTypes = {
  // In which project to fetch the directory?
  projectId: PropTypes.string.isRequired,

  // Which directory to fetch?
  id: PropTypes.string.isRequired,

  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default DirectoryQuery;
