import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BaseIcon from '../Base';
import Sprites from './sprites.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    backgroundImage: `url(${Sprites})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 1160px',
    backgroundPosition: '0px -400px'
  }
}));

export default function Error({ className, ...rest }) {
  const classes = useStyles();
  const finalClassName = clsx(classes.root, className);
  return <BaseIcon className={finalClassName} {...rest} />;
}
