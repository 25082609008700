import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1.5)
  }
}));

export default function ListItemAvatar({ className, ...rest }) {
  const classes = useStyles();
  return <div className={clsx(classes.avatar, className)} {...rest} />;
}
