import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const UNFOLLOW_EVENT_MUTATION = gql`
  mutation UnfollowEvent($eventId: ID!) {
    unfollowEvent(eventId: $eventId) {
      id
    }
  }
`;

export default function useUnsubscribeMeMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(UNFOLLOW_EVENT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
