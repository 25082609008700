import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';

const UPDATE_EVENT_COLOR_MUTATION = gql`
  mutation UpdateEventColor($eventId: ID!, $color: String!) {
    updateEvent(eventId: $eventId, changes: { color: $color }) {
      id
      color
    }
  }
`;


export default function useUpdateEventColor() {
  const [mutate, mutationResults] = useMutation(
    UPDATE_EVENT_COLOR_MUTATION, {
      loadingMessage: 'Saving...',
      successMessage: 'Event saved'
    }
  );

  const mutateFn = useCallback((event, newColor) => {
    mutate({
      variables: {
        eventId: event.id,
        color: newColor
      },
      optimisticResponse: {
        updateEvent: {
          ...event,
          color: newColor
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
