import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    marginBottom: 12,
    fontSize: '1.125rem'
  }
}))((props) => (
  <Typography variant='body1' color='textSecondary' {...props} />
));
