import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import ColorRadio from './ColorRadio';
import { CHIP_COLOR_OPTIONS } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingLeft: theme.spacing(0.5)
  },
  label: {
    margin: theme.spacing(0.5)
  }
}));

function ChipColorPicker(props) {
  const classes = useStyles();
  return (
    <RadioGroup className={classes.root} {...props}>
      {CHIP_COLOR_OPTIONS.map(({ name, hex }) => (
        <FormControlLabel
          key={name}
          value={hex}
          control={ <ColorRadio /> }
          className={classes.label}
        />
      ))}
    </RadioGroup>
  );
}

export default ChipColorPicker;
