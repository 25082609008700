import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const DELETE_EVENT_COMMENT_MUTATION = gql`
  mutation DeleteEventComment($eventId: ID!, $commentId: ID!) {
    deleteEventComment(eventId: $eventId, commentId: $commentId) {
      id
    }
  }
`;

export default function useDeleteCommentMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(DELETE_EVENT_COMMENT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
