import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withSize } from 'react-sizeme'
import { TODO_SHEET_THEME } from './theme';
import SheetContainerContext from './SheetContainerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  shadowHeader: {
    flex: '0 0 auto',
    position: 'absolute',
    top: 1,
    left: 0,
    width: '100%',
    height: TODO_SHEET_THEME.cellHeight,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.appBar - 1,
    pointerEvents: 'none'
  },
  sheetRoot: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: 1,
    paddingBottom: TODO_SHEET_THEME.gapBetweenSections,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
}));

// The container for the actual table.
// This container is aware of its height, so that we know how to draw the
// blue indicator line when column resize handle is moving
function SheetContainer({ size, children }) {
  const classes = useStyles();

  const ctx = React.useMemo(() => ({
    height: size.height
  }), [size.height]);

  return (
    <div className={classes.root}>
      <div className={classes.shadowHeader} />
      <div className={classes.sheetRoot}>
        <SheetContainerContext.Provider value={ctx}>
          {children}
        </SheetContainerContext.Provider>
      </div>
    </div>
  );
}

SheetContainer.propTypes = {
  children: PropTypes.node
};

export default withSize({
  monitorWidth: false,
  monitorHeight: true
})(SheetContainer);
