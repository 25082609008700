import React from 'react';
import ActionDelete from '../file/list/ActionDelete';

function AttachmentActionDelete(props) {
  return (
    <ActionDelete {...props} />
  );
}

export default AttachmentActionDelete;
