import React from 'react';
import PropTypes from 'prop-types';
import FavoriteCheckbox from './FavoriteCheckbox';
import usePinProjectMutation from './hooks/usePinProjectMutation';
import useUnpinProjectMutation from './hooks/useUnpinProjectMutation';

function ProjectHeaderFavoriteCheckbox({ projectId, color, checked }) {
  const [addToFavorite] = usePinProjectMutation({ projectId });
  const [removeFromFavorite] = useUnpinProjectMutation({ projectId });

  const handleCheckedChange = (newChecked) => {
    if (newChecked) {
      addToFavorite();
    } else {
      removeFromFavorite();
    }
  };

  return (
    <FavoriteCheckbox
      starColor={color}
      checked={checked}
      onChange={handleCheckedChange}
    />
  );
}

ProjectHeaderFavoriteCheckbox.propTypes = {
  projectId: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string
};

export default ProjectHeaderFavoriteCheckbox;
