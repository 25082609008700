import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  }
}));

export default function DialogTitleContainer(props) {
  const classes = useStyles();
  return (
    <DialogTitle
      disableTypography
      className={classes.root}
      {...props}
    />
  );
}
