import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../../ui/progress/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 8
    }
  }
}));

export default function AutocompleteLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Spinner size={16} />
       Loading...
    </div>
  );
}
