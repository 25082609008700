import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const SAVE_TODO_UPLOADS_MUTATION = gql`
  mutation SaveTodoUploads(
    $todoId: ID!,
    $uploads: [UploadInput]
  ) {
    saveTodoUploads(
      todoId: $todoId,
      uploads: $uploads
    )
  }
`;

export default function useSaveUploadsMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(SAVE_TODO_UPLOADS_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
