import FileNameCell from '../ui/file/table/FileNameCell';
import DateModifiedCell from '../ui/file/table/DateModifiedCell';
import FileSizeCell from '../ui/file/table/FileSizeCell';
import FileCreatorCell from '../ui/file/table/FileCreatorCell';

export const COLUMNS = [
  {
    id: 'name',
    numeric: false,
    disabledSort: false,
    label: 'Name',
    width: 512,
    Cell: FileNameCell,
    getCellProps: ({ type, name, url }) => ({
      type,
      name,
      url
    })
  },
  {
    id: 'updatedAt',
    disabledSort: false,
    numeric: false,
    label: 'Date Modified',
    width: 256,
    Cell: DateModifiedCell,
    getCellProps: ({ updatedAt }) => ({
      timestamp: updatedAt
    })
  },
  {
    id: 'size',
    disabledSort: false,
    numeric: true,
    label: 'Size',
    width: 128,
    Cell: FileSizeCell,
    getCellProps: ({ size }) => ({ size })
  },
  {
    id: 'creator',
    disabledSort: false,
    numeric: false,
    label: 'Creator',
    width: 256,
    Cell: FileCreatorCell,
    getCellProps: ({ creator }) => ({ creator })
  }
];

