import React from 'react';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  paper: {
    borderRadius: 8,
    boxShadow: theme.customShadow.menu,

    // 1 extra + 16px padding on list is just a hack to position
    // color picker nicely (see margin style of ChipMenuColorPickerItem)
    width: theme.spacing(28 + 1),
  },
  list: {
    paddingBottom: theme.spacing(2)
  }
}))((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    anchorReference='anchorPosition'
    {...props}
  />
));
