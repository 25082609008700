import React from 'react';
import PropTypes from 'prop-types';
import { format, isToday, isYesterday } from 'date-fns';
import Timeline from '../../ui/timeline/Timeline';
import DateItem from '../../ui/timeline/DateItem';
import ActivityItem from '../../ui/timeline/ActivityItem';
import UserAvatar from '../../account/UserAvatar';

function ActivitiesTimeline({ activities }) {

  const groupActivitiesByDate = (activities) => {
    return activities.reduce((acc, act) => {
      const insertedAt = new Date(act.insertedAt);
      let date;

      if (isToday(insertedAt)) date = 'Today';
      else if (isYesterday(insertedAt)) date = 'Yesterday';
      else date = format(insertedAt, 'EEEE, MMMM d, yyyy');

      if (!acc.hasOwnProperty(date)) acc[date] = [];
      acc[date].push(act);
      return acc;
    }, {});
  };

  const groupedActivities = groupActivitiesByDate(activities);

  return (
    <Timeline breakpoint='sm' dividerWidth={72}>
      {Object.keys(groupedActivities).map((date, index) => {
        const left = (index % 2) === 0;
        const dailyActivities = groupedActivities[date];
        return (
          <React.Fragment key={date}>
            <DateItem date={date} left={left} />
            {dailyActivities.map(({id, actor, what, excerpt, insertedAt}) => (
              <ActivityItem
                key={id}
                left={left}
                divider={format(new Date(insertedAt), 'h:mma')}
                avatar={ <UserAvatar user={actor} /> }
                primary={`${actor.username} ${what}`}
                secondary={ excerpt && `${excerpt}.`}
              />
            ))}
          </React.Fragment>
        );
      })}
    </Timeline>
  );
}

ActivitiesTimeline.propTypes = {
  activities: PropTypes.array.isRequired
};

export default ActivitiesTimeline;
