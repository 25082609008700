import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Page } from 'react-pdf/dist/esm/entry.webpack';
import SpinnerIcon from './pdf_page_loading_icon.gif';
import { usePdfDocumentContainer } from './PdfDocumentContainer';

const useStyles = makeStyles((theme) => ({
  region: {
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    margin: '0 auto'
  },
  page: {
    height: '100%',
    '& .react-pdf__Page__canvas': {
      height: '100% !important'
    },
    '& .react-pdf__Page__textContent': {
      height: '100% !important'
    }
  },
  spinner: {
    background: `url(${SpinnerIcon}) center no-repeat`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}));

// In order for the Intersection Observer to work properly, we have to
// make sure that page region (placeholder) and the actual page MUST have
// the exact same dimension (same width and same height). Since we cannot
// afford to preload each and every single page in the document to
// determine its natural dimension, we have to use a hack to make sure
// the every page must match our pre-defined demension: the width is
// the container's width, and the height is the width muptiplied by
// `PAGE_ASPECT_RATIO`. This number is roughly the aspect ratio of a
// typical A4 document.
const PAGE_ASPECT_RATIO = 1.4;

function PdfPage({ pageIndex, visible, zoom }) {
  const classes = useStyles();
  const regionRef = React.useRef(null);
  const { observer, width } = usePdfDocumentContainer();

  const scale = zoom / 100;
  const scaledWidth = Math.floor(width * scale);
  const scaledHeight = Math.floor(scaledWidth * PAGE_ASPECT_RATIO);

  React.useEffect(() => {
    const region = regionRef.current;
    const isOk = observer && region;

    if (isOk) {
      observer.observe(region);
    }

    return () => {
      if (isOk) {
        observer.unobserve(region);
      }
    };
  }, [observer]);

  return (
    <div
      ref={regionRef}
      role='region'
      data-page-index={pageIndex}
      className={classes.region}
      style={{
        width: scaledWidth,
        height: scaledHeight
      }}
    >
      {visible && (
        <Page
          renderAnnotationLayer={false}
          className={classes.page}
          width={width}
          scale={scale}
          pageNumber={pageIndex + 1}
          loading={ <div className={classes.spinner} /> }
        />
      )}
    </div>
  );
}

PdfPage.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  zoom: PropTypes.number.isRequired
};

PdfPage.defaultProps = {
  zoom: 1.0
};

export default PdfPage;
