import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '../ui/svg-icons/DeleteOutline';
import Menu from './ui/ChipContextMenu';
import ChipMenuItem from './ui/ChipMenuItem';
import ChipMenuDivider from './ui/ChipMenuDivider';
import ColorPicker from './ui/ChipMenuColorPicker';
import { CHIP_DEFAULT_COLOR, isValidChipColor } from './ui/constants';
import useUpdateColor from './mutations/useUpdateEventColor';
import useDeleteEvent from './mutations/useDeleteEvent';

function EventContextMenu({ projectId, event, ...rest }) {
  const { color } = event;

  const closeMenu = () => {
    if (rest.onClose()) {
      rest.onClose();
    }
  };

  //
  // GraphQL mutations.
  //

  const [changeColor] = useUpdateColor();
  const [deleteEvent] = useDeleteEvent(projectId);

  const handleDelete = (e) => {
    closeMenu();
    deleteEvent(event);
  };

  const handleColorChange = (newColor) => {
    closeMenu();
    changeColor(event, newColor);
  };

  return (
    <Menu {...rest}>
      <ChipMenuItem
        primary='Delete'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
      />
      <ChipMenuDivider />
      <ColorPicker
        value={isValidChipColor(color) ? color : CHIP_DEFAULT_COLOR}
        onChange={handleColorChange}
      />
    </Menu>
  );
}

EventContextMenu.propTypes = {
  projectId: PropTypes.string.isRequired,

  event: PropTypes.shape({
    __typename: PropTypes.oneOf(['Event']).isRequired,

    id: PropTypes.string.isRequired,

    startsAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired,

    endsAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired,

    color: PropTypes.string
  }).isRequired
};

export default EventContextMenu;
