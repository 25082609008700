import { setContext } from 'apollo-link-context';
import { createLink } from 'apollo-absinthe-upload-link';
import customFetch from './customFetch';
import { HTTP_ENDPOINT } from './endpoints';

// Create an HTTP link that anable files uploading.
const httpLink = createLink({
  uri: HTTP_ENDPOINT,
  fetch: customFetch
});

// If an authentication token exists in the local storage, put
// the token in the 'Authorization' request header.
// Returns an object to set the context of the GraphQL request.
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('auth-token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export default authLink.concat(httpLink);
