import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import LinearStepper from '../../ui/stepper/LinearStepper';
import LinearStep from '../../ui/stepper/LinearStep';
import DialogTitleContainer from '../../ui/dialog/MultiStepTitleContainer';
import DialogTitle from '../../ui/dialog/MultiStepTitle';
import DialogContent from '../../ui/dialog/MultiStepContentContainer';
import DialogContext from '../../ui/dialog/DialogContext';
import MakeFolder from './MakeFolder';
import FolderPicker from './FolderPicker';

const CSS_TRANSITION_DURATION = '.2s';
const TRANSITION_DURATION = parseFloat(CSS_TRANSITION_DURATION) * 1000;

function MakeFolderDialog({ projectId, parent }) {
  const { closeDialog } = React.useContext(DialogContext);
  const [parentFolder, setParentFolder] = React.useState(parent);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showFolderPicker, setShowFolderPicker] = React.useState(false);
  const [actionsContainer, setActionsContainer] = React.useState(null);

  const handleGoBack = () => {
    setActiveStep(0);
  };

  const handleSelectParentClick = (event) => {
    setShowFolderPicker(true);
    setActiveStep(1);
  };

  const handleParentChange = (event, newParent) => {
    setParentFolder(newParent);
    handleGoBack();
  };

  const setActionsContainerRef = (elem) => {
    setActionsContainer(elem);
  };

  return (
    <React.Fragment>
      <DialogTitleContainer>
        <LinearStepper
          transitionDuration={CSS_TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <DialogTitle onClose={closeDialog}>
              Create new folder
            </DialogTitle>
          </LinearStep>

          <LinearStep>
            <DialogTitle onClose={closeDialog} onGoBack={handleGoBack}>
              Where to put this folder?
            </DialogTitle>
          </LinearStep>
        </LinearStepper>
      </DialogTitleContainer>

      <Divider />

      <DialogContent>
        <LinearStepper
          transitionDuration={CSS_TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <MakeFolder
              focusInputAfter={TRANSITION_DURATION}
              projectId={projectId}
              parent={parentFolder}
              onSelectParent={handleSelectParentClick}
              onClose={closeDialog}
              actionsContainer={actionsContainer}
            />
          </LinearStep>

          <LinearStep>
            {showFolderPicker && (
              <FolderPicker
                projectId={projectId}
                value={parentFolder}
                onChange={handleParentChange}
              />
            )}
          </LinearStep>
        </LinearStepper>
      </DialogContent>

      {/* placeholder for dialog actions */}
      <div>
        <LinearStepper
          transitionDuration={CSS_TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep ref={setActionsContainerRef} />
          <LinearStep />
        </LinearStepper>
      </div>
    </React.Fragment>
  );
}

MakeFolderDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  parent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })
};

export default MakeFolderDialog;
