import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import Error from '../oops/Error';
import NotFound from '../oops/SectionNotFound';
import { getDateSlug } from './helpers';
import { parseDateInput } from '../new-ui/datetime-picker/parseDateInput';

const PROJECT_EVENT_QUERY = gql`
  query GetProjectEvent($eventId: ID!) {
    event(id: $eventId) {
      id
      startsAt
      project { id }
    }
  }
`;

function EventDetailsPage({ calendarUrl }) {
  const { projectId, eventId } = useParams();
  const { hash } = useLocation();

  const { loading, data, error } = useQuery(PROJECT_EVENT_QUERY, {
    variables: { eventId }
  });

  if (loading) {
    return null;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!data || !data.event) {
    return <NotFound />;
  }

  const { id, startsAt, project } = data.event;

  if (project.id !== projectId) {
    return <NotFound />;
  }

  const start = parseDateInput(startsAt);
  const dateSlug = getDateSlug(start);

  let eventUrl = `${calendarUrl}/day/${dateSlug}/event/${id}`;

  if (hash) {
    eventUrl += hash;
  }

  return <Redirect to={eventUrl} />;
}

EventDetailsPage.propTypes = {
  calendarUrl: PropTypes.string.isRequired
};

export default EventDetailsPage;
