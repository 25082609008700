import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Preview from '../../ui/file/preview/Preview';

const useStyles = makeStyles((theme) => ({
  preview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  }
}));

export default function EditUploadPreview(props) {
  const classes = useStyles();
  return (
    <div className={classes.preview}>
      <Preview
        maxHeight={128}
        {...props}
      />
    </div>
  );
}
