import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto'
  }
}));

export default function TimelineItemContent({ className, ...rest }) {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)} {...rest} />;
}
