import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import StyledAllDayGrid from './StyledAllDayGrid';
import convertMousePositionToGridCoordinates
from './convertMousePositionToGridCoordinates';

// https://www.w3schools.com/jsref/event_button.asp
// TODO: Internet Explorer 8 and earlier has different return value: 1
function _isLeftMousePressed(mouseEvent) {
  return mouseEvent && mouseEvent.button === 0;
}

function AllDayGrid({
  numRows,
  numCols,
  onDragStart,
  onDragUpdate,
  onDragEnd,
  onClick,
  children
}) {
  const ref = React.useRef(null);

  const [isDragging, setIsDragging] = React.useState(false);
  const [dragStart, setDragStart] = React.useState(null);

  const gridCoordinates = React.useCallback((e) => {
    const rect = ref.current.getBoundingClientRect();
    const options = { rect, numRows, numCols };
    return convertMousePositionToGridCoordinates(e, options);
  }, [numRows, numCols]);

  // If user presses the mouse for more than two milisecond, we interpret
  // it as a dragging event, otherwise it is a click event.
  const debounceMouseDown = debounce((e) => {
    if (!_isLeftMousePressed(e)) {
      return;
    }

    const result = gridCoordinates(e);

    setIsDragging(true);
    setDragStart(result);

    if (onDragStart) {
      onDragStart(e, result);
    }
  }, 300);

  // User is dragging around.
  const handleMouseMove = (e) => {
    if (!isDragging) { return; }

    const dragEnd = gridCoordinates(e);

    if (onDragUpdate) {
      onDragUpdate(dragStart, dragEnd);
    }
  };

  // User stops dragging
  const handleMouseUp = (e) => {
    const dragEnd = gridCoordinates(e);

    if (!isDragging) {
      // If user releases the mouse before two miliseconds, we have to
      // cancel the registered mouse down event.
      debounceMouseDown.cancel();

      // Tell client that user has just clicked on some time-interval
      if (onClick && _isLeftMousePressed(e) && e.target === ref.current) {
        onClick(dragEnd);
      }

      return;
    }

    setIsDragging(false);
    setDragStart(null);

    if (onDragEnd) {
      onDragEnd(dragStart, dragEnd);
    }
  };

  return (
    <StyledAllDayGrid
      ref={ref}
      numRows={numRows}
      isDragging={isDragging}
      onMouseDown={(e) => {
        e.persist();
        debounceMouseDown(e);
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {children}
    </StyledAllDayGrid>
  );
}

AllDayGrid.propTypes = {
  numRows: PropTypes.number.isRequired,
  numCols: PropTypes.number,
  onDragStart: PropTypes.func,
  onDragUpdate: PropTypes.func,
  onDragEnd: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node
};

AllDayGrid.defaultProps = {
  numCols: 7
};

export default AllDayGrid;
