import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  commentComposer: {
    flexGrow: 1,
    width: '100%',
    display: 'flex'
  }
}));

export default function Composer(props) {
  const classes = useStyles();
  return <div className={classes.commentComposer} {...props} />;
}
