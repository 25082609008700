import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { TEAM_DETAILS_FRAGMENT } from './fragments';
import Error from '../oops/Error';

const TEAM_DETAILS_QUERY = gql`
  query GetTeamDetails($teamId: ID!) {
    team(teamId: $teamId) {
      ...TeamDetails
    }
  }
  ${TEAM_DETAILS_FRAGMENT}
`;

function TeamDetailsQuery(props) {
  const { teamId, onLoading, onError, children, ...other } = props;
  const { loading, error, data } = useQuery(TEAM_DETAILS_QUERY, {
    variables: { teamId },
    ...other
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.team);
}

TeamDetailsQuery.propTypes = {
  teamId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default TeamDetailsQuery;
export { TEAM_DETAILS_QUERY };
