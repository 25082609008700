import getTime from '../../new-ui/datetime-picker/getTime';
import { getEventTitle } from '../helpers';
import sortTimeIntervals from './ui/sortTimeIntervals';

export default function combineSchedulesWithChipProps(rawSchedules) {
  const count = rawSchedules.length;

  const intervals =  rawSchedules.map((schedule) => {
    const start = getTime(schedule.startsAt);
    const end = getTime(schedule.endsAt);

    return {
      schedule,
      chipProps: {
        'data-schedule-id': schedule.id,
        'data-schedule-type': schedule.__typename,
        title: getEventTitle(schedule),
        color: schedule.color,
        focusZIndex: count,
        start,
        end
      },
      start,
      end
    };
  });

  return sortTimeIntervals(intervals).map((x) => ({
    schedule: x.schedule,
    chipProps: {
      ...x.chipProps,
      zIndex: x.zIndex,
      width: Math.round(x.width * 100)
    }
  }));
}
