import React from 'react';
import TimelineItem from '@material-ui/lab/TimelineItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  missingOppositeContent: {
    '&:before': {
      display: 'none'
    }
  },
}));

export default function ActivityItem(props) {
  const classes = useStyles();
  return <TimelineItem classes={classes} {...props} />;
}
