import React from 'react';
import PropTypes from 'prop-types';
import { SizeMe } from 'react-sizeme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  }
}));

const PdfDocumentContainerContext = React.createContext(null);

function getPageIndex({ target }) {
  return parseInt(target.getAttribute('data-page-index'));
}

function PdfDocumentContainer({
  verticalMargin,
  onIntersect,
  maxHeight,
  children
}) {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const [observer, setObserver] = React.useState(null);

  // Initialize Intersection Observer.
  React.useEffect(() => {
    const observerOptions = {
      root: rootRef.current,
      rootMargin: `${verticalMargin}px 0px`,
      threshold: 0.0
    };

    const observerCallback = (entries, io) => {
      const intersects = entries.reduce((acc, entry) => {
        const pageIndex = getPageIndex(entry);
        return {
          ...acc,
          [pageIndex]: entry.isIntersecting
        };
      }, {});

      if (onIntersect) {
        onIntersect(intersects);
      }
    };

    const io = new IntersectionObserver(observerCallback, observerOptions);
    setObserver(io);

    return () => io.disconnect();
  }, [verticalMargin, onIntersect]);

  return (
    <div
      ref={rootRef}
      className={classes.root}
      style={{maxHeight: maxHeight}}
    >
      <SizeMe>
        {({ size }) => (
          <PdfDocumentContainerContext.Provider
            value={{
              observer: observer,
              width: size.width
            }}
          >
            {children}
          </PdfDocumentContainerContext.Provider>
        )}
      </SizeMe>
    </div>
  );
}

PdfDocumentContainer.propTypes = {
  verticalMargin: PropTypes.number,
  onIntersect: PropTypes.func,

  // The maximum height of the container. If `maxHeight` is set to
  // `none`, it is required that the parent container MUST have a
  // fixed height for InterSectionObserver to work properly.
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['none'])
  ]),

  children: PropTypes.node
};

PdfDocumentContainer.defaultProps = {
  verticalMargin: 600,
  maxHeight: 'none'
};

export default PdfDocumentContainer;

export function usePdfDocumentContainer() {
  return React.useContext(PdfDocumentContainerContext);
}
