import React from 'react';
import PropTypes from 'prop-types';
import UnfollowMessageButton from '../../ui/message/UnfollowMessageButton';
import useUnfollowMessageMutation from './useUnfollowMessageMutation';

function RemoveCurrentUser({ messageId }) {
  const [removeCurrentUser] = useUnfollowMessageMutation({
    variables: { messageId }
  });

  return <UnfollowMessageButton onClick={removeCurrentUser} />;
}

RemoveCurrentUser.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default RemoveCurrentUser;
