import React from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import EditorContainer from './EditorContainer';
import useCustomBlockStyleFn from './hooks/useCustomBlockStyleFn';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import useHandleKeyCommandFn from './hooks/useHandleKeyCommandFn';
import useCustomKeyBindingFn from './hooks/useCustomKeyBindingFn';
import EditorToolbar from './EditorToolbar';

export default function TextEditor() {
  const editorRef = React.useRef(null);

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty()
  );

  const focusEditor = () => {
    editorRef.current.focus();
  };

  const customBlockStyleFn = useCustomBlockStyleFn();

  const toggleBlockType = (blockType) => {
    setEditorState((prevState) => RichUtils.toggleBlockType(
      prevState,
      blockType
    ));
  };

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState((prevState) => RichUtils.toggleInlineStyle(
      prevState,
      inlineStyle
    ));
  };

  const handleKeyCommand = useHandleKeyCommandFn(setEditorState);
  const customKeyBindingFn = useCustomKeyBindingFn(setEditorState);

  return (
    <EditorContainer>
      <EditorToolbar>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
      </EditorToolbar>
      <div onClick={focusEditor} style={{padding: 24}}>
        <Editor
          spellCheck={true}
          ref={editorRef}
          blockStyleFn={customBlockStyleFn}
          keyBindingFn={customKeyBindingFn}
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          onChange={setEditorState}
        />
      </div>
    </EditorContainer>
  );
}
