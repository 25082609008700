// https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollX
export default function getWindowPageOffset() {
  const x = (window.pageXOffset !== undefined)
          ? window.pageXOffset
          : (document.documentElement || document.body.parentNode ||
             document.body).scrollLeft;

  const y = (window.pageYOffset !== undefined)
          ? window.pageYOffset
          : (document.documentElement || document.body.parentNode ||
             document.body).scrollTop;
  return {x, y};
}
