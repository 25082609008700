import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../new-ui/select/StyledSelect';
import MenuItem from '../../new-ui/select/MenuItem';

function ReminderUnitSelect({ options, ...rest }) {
  return (
    <Select typography='body2' background='always' {...rest}>
      {options.map(({ value, label }) => (
        <MenuItem typography='body2' key={label} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}

// See `./reminderUnitOptions.js`
ReminderUnitSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired
};

export default ReminderUnitSelect;
