import React from 'react';
import PropTypes from 'prop-types';
import ActionDownload from '../file/list/ActionDownload';

function AttachmentActionDownload({ downloadUrl, ...rest }) {
  return (
    <ActionDownload
      aria-label='Download file'
      component='a'
      href={downloadUrl}
      {...rest}
    />
  );
}

AttachmentActionDownload.propTypes = {
  downloadUrl: PropTypes.string.isRequired
};

export default AttachmentActionDownload;
