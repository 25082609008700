import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from './Breadcrumbs';
import Breadcrumb from './Breadcrumb';

// TODO: This value is really important and we've just hard-coded
// here. Find a better way to measure it.
// The ol's height.
const BREADCRUMB_HEIGHT = 24;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
    overflow: 'hidden',
    height: BREADCRUMB_HEIGHT
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    pointerEvents: 'none'
  }
}));

function CollapsableBreadcrumbs({ path }) {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const [observer, setObserver] = React.useState(null);
  const [lastBreadcrumb, setLastBreadcrumb] = React.useState(null);
  const [collapsed, setCollapsed] = React.useState(false);

  const setLastBreadcrumbRef = (elem) => {
    setLastBreadcrumb(elem);
  };

  const isLastBreadcrumb = (index) => (index + 1) === path.length;

  React.useEffect(() => {
    const options = {
      root: rootRef.current,
      margin: '0px',
      threshold: 1.0
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCollapsed(false);
        } else {
          setCollapsed(true);
        }
      });
    };

    const io = new IntersectionObserver(callback, options);
    setObserver(io);
    return () => io.disconnect();
  }, []);

  React.useEffect(() => {
    if (observer && lastBreadcrumb) {
      observer.observe(lastBreadcrumb);
      return () => observer.unobserve(lastBreadcrumb);
    }
  }, [observer, lastBreadcrumb]);

  return (
    <div className={classes.wrapper}>
      <div ref={rootRef}>
        <Breadcrumbs collapsed={collapsed}>
          {path.map(({ primary, to, interactive }) => (
            <Breadcrumb
              key={primary}
              primary={primary}
              to={to}
              interactive={interactive}
            />
          ))}
        </Breadcrumbs>
        <Breadcrumbs
          aria-hidden={true}
          className={classes.hidden}
        >
          {path.map(({ primary, to, interactive }, index) => (
            <Breadcrumb
              key={primary}
              primary={primary}
              to={to}
              interactive={interactive}
              ref={isLastBreadcrumb(index) ? setLastBreadcrumbRef : null}
            />
          ))}
        </Breadcrumbs>
      </div>
    </div>
  );
}

CollapsableBreadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({
    // If `interactive` is set to `true`, it means that this is a link
    // that user can click on to navigate to another page. In this
    // case `to` is required
    interactive: PropTypes.bool,

    to: PropTypes.string,
    primary: PropTypes.string.isRequired
  })).isRequired
};

export default CollapsableBreadcrumbs;
