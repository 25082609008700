import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../ui/input/TextField';
import Password from '../ui/input/Pasword';

function SignInFormInput({ value, onChange }) {
  const { email, password } = value;
  return (
    <>
      <TextField
        autoFocus
        type='email'
        name='email'
        id='email'
        placeholder='Your email'
        required
        fullWidth
        value={email}
        onChange={onChange}
      />

      <Password
        name='password'
        id='password'
        placeholder='Your password'
        required
        fullWidth
        value={password}
        onChange={onChange}
      />
    </>
  );
}

SignInFormInput.propTypes = {
  value: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func
};

export default SignInFormInput;
