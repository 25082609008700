import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import useTeamsURL from './useTeamsURL';
import { TEAMS_QUERY } from '../TeamsQuery';

const DELETE_TEAM_MUTATION = gql`
  mutation DeleteTeam($teamId: ID!){
    deleteTeam(teamId: $teamId) {
      id
      workspace {
        id
      }
    }
  }
`;

export default function useDeleteTeamMutation(options) {
  const { teamId, onCompleted: onFinished, ...rest } = options;
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  const teamsURL = useTeamsURL();
  const history = useHistory();
  const { pathname } = useLocation();

  const redirectToTeamsPage = () => {
    if (teamsURL !== pathname) {
      history.push(teamsURL);
    }
  };

  return useMutation(DELETE_TEAM_MUTATION, {
    variables: { teamId },
    update(cache, { data: { deleteTeam } }) {
      const deletedId = deleteTeam.id;
      const workspaceId = deleteTeam.workspace.id;
      const teamsQuery = { query: TEAMS_QUERY, variables: { workspaceId } };

      const oldData = cache.readQuery(teamsQuery);
      const updatedTeams = oldData.workspace.teams.filter(({ id }) => (
        id !== deletedId
      ));

      cache.writeQuery({
        ...teamsQuery,
        data: {
          workspace: {
            ...oldData.workspace,
            teams: updatedTeams
          }
        }
      });
    },
    onCompleted(data) {
      onFinished && onFinished(data);
      redirectToTeamsPage();
    },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...rest
  });
}
