import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from './DialogContent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  }
}));

export default function MultiStepContentContainer({className, ...rest}) {
  const classes = useStyles();
  return (
    <DialogContent
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
}
