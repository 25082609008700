import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editorToolbar: {
    //backgroundColor: 'yellow',
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2)
    },
    position: 'sticky',
    top: 64,
    zIndex: theme.zIndex.appBar,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.background.paper
  }
}));

export default function EditorToolbar(props) {
  const classes = useStyles();
  return <div className={classes.editorToolbar} {...props} />
}
