import React from 'react';
import PropTypes from 'prop-types';
import DialogLoading from '../../ui/dialog/DialogLoading';
import ProjectNameQuery from '../ProjectNameQuery';
import AddMembersForm from './AddMembersForm';

function AddMembers({ projectId }) {
  return (
    <ProjectNameQuery
      projectId={projectId}
      onLoading={() => <DialogLoading /> }
    >
      {(project) => <AddMembersForm project={project} />}
    </ProjectNameQuery>
  );
}

AddMembers.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default AddMembers;
