import React from 'react';
import Autocomplete from '../../new-ui/autocomplete/StyledAutocomplete';
import ToolbarInput from './ToolbarInput';
import {
  getOptionSelected,
  getOptionLabel,
  renderOption,
  filterOptions
} from '../../new-ui/UserAutocomplete';

const renderInput = (params) => {
  // TODO: delete weird className supplied by MUI.
  delete params.InputProps.className;
  return (
    <ToolbarInput
      autoFocus={true}
      type='text'
      placeholder='Search by name or email'
      {...params}
    />
  );
};

export default function AutocompleteAssignee(props) {
  return (
    <Autocomplete
      gutterTop={false}
      typography='body2'
      openOnFocus
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      {...props}
    />
  );
}
