import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  monthGrid: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

export default function MonthGrid(props) {
  const classes = useStyles();
  return <div role='grid' className={classes.monthGrid} {...props} />;
}
