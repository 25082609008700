import React from 'react';
import PropTypes from 'prop-types';
import CardGridItem from '../../ui/card/CardGridItem';
import ProjectCard from '../ProjectCard';

function ProjectGridItem({ project }) {
  return (
    <CardGridItem>
      <ProjectCard project={project} />
    </CardGridItem>
  );
}

ProjectGridItem.propTypes = {
  project: PropTypes.object.isRequired
};

export default React.memo(ProjectGridItem);
