import React from 'react';
import { useParams } from 'react-router-dom';
import SearchProjectInTeam from './SearchProjectInTeam';

function ActiveProjects() {
  const { teamId } = useParams();
  return <SearchProjectInTeam teamId={teamId} />;
}

export default ActiveProjects;
