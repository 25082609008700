import {isTodo, isEvent } from '../helpers';

function _isAllDay(schedule) {
  return isTodo(schedule)/* todos are all-day */ ||
         (isEvent(schedule) && schedule.allDay);
}

function _isTimeSpecific(schedule) {
  return !_isAllDay(schedule);
}

// Helper function to split a list of schedules into two separate parts:
// all-day and time-specific.
export default function splitSchedules(schedules) {
  const allDay = schedules.filter(_isAllDay);
  const timeSpecific = schedules.filter(_isTimeSpecific);
  return { allDay, timeSpecific };
}
