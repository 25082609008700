import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { truncateString } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    fontSize: '0.9375rem'
  }
}));

const MAX_TEXT_LENGTH = 16;

function BreadcrumbsLink({ primary, to, ...rest }) {
  const classes = useStyles();
  return (
    <Link
      component={ NavLink }
      variant='body1'
      to={to}
      className={classes.link}
      {...rest}
    >
      {truncateString(primary, MAX_TEXT_LENGTH)}
    </Link>
  );
}

BreadcrumbsLink.propTypes = {
  to: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired
};

export default BreadcrumbsLink;
