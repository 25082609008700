import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { TEAM_DETAILS_QUERY } from '../../team/TeamDetailsQuery';
import TeamDropdownButton from './TeamDropdownButton';

// TODO:
//
// 1. Find a way to notify the form when loading or error. We cannot
//    pass callback from form down to this shit because that would cause
//    React to warn "cannot update... while rendering..."
//
// 2. Find a better way to handle error.
//
// 3. Redesign the loading indicator.
function TeamDropdownButtonWithData({ teamId, ...rest }) {
  const { loading, error, data } = useQuery(TEAM_DETAILS_QUERY, {
    variables: { teamId }
  });

  if (loading) {
    return (
      <TeamDropdownButton
        label='Loading team...'
        loading={true}
      />
    );
  }

  if (error || !data) {
    return <TeamDropdownButton placeholder {...rest} />;
  }

  return (
    <TeamDropdownButton
      label={data.team.name}
      {...rest}
    />
  );
}

TeamDropdownButtonWithData.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default TeamDropdownButtonWithData;
