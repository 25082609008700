import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  secondary: {
    fontSize: '0.8125rem',
    lineHeight: 1.4
  },
  chunkDivider: {
    margin: theme.spacing(0, 0.5)
  }
}));

function Secondary({ text }) {
  const classes = useStyles();

  const renderSecondaryText = () => {
    if (!Array.isArray(text)) {
      return text;
    }

    const length = text.length;
    const isLastChunk = (index) => length && (index === length - 1);

    return text.map((chunk, index) => {
      if (isLastChunk(index)) {
        return chunk;
      } else {
        return (
          <React.Fragment key={index}>
            {chunk}
            <span className={classes.chunkDivider}>&bull;</span>
          </React.Fragment>
        );
      }
    });
  };

  return (
    <Typography
      variant='caption'
      color='textSecondary'
      className={classes.secondary}
    >
      {renderSecondaryText()}
    </Typography>
  );
}

Secondary.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array
  ]).isRequired
};

export default Secondary;
