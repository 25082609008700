import React from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '../../ui/menu/MenuItem';
import CreateIcon from '../../ui/icons/Pencil';
import IconAvatar from '../../ui/avatar/IconAvatar';

const CreateNewWorkspaceMenuItem = React.forwardRef((props, ref) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/create-workspace');
  };

  return (
    <MenuItem
      ref={ref}
      primary='Create new workspace'
      avatar={ <IconAvatar icon={ <CreateIcon /> } /> }
      onClick={handleClick}
      {...props}
    />
  )
});

export default CreateNewWorkspaceMenuItem;
