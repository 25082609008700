import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useEffect } from 'react';

const SOME_TODO_ATTACHMENT_REMOVED_SUBSCRIPTION = gql`
  subscription OnSomeTodoAttachmentRemoved($todoId: ID!) {
    todoAttachmentRemoved(todoId: $todoId) {
      id
    }
  }
`;

function SomeAttachmentRemoved({ todoId, onSomeAttachmentRemoved }) {
  useEffect(() => {
    const unsubscribe = onSomeAttachmentRemoved({
      document: SOME_TODO_ATTACHMENT_REMOVED_SUBSCRIPTION,
      variables: { todoId },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData) {
          return previousResult;
        }

        const entry = subscriptionData.data.todoAttachmentRemoved;
        const prevAttachments = previousResult.todoItem.attachments;

        return {
          todoItem: {
            ...previousResult.todoItem,
            attachments: prevAttachments.filter((a) => a.id !== entry.id)
          }
        };
      }
    });

    return () => unsubscribe();
  }, [todoId, onSomeAttachmentRemoved]);

  return null;
}

SomeAttachmentRemoved.propTypes = {
  todoId: PropTypes.string.isRequired,
  onSomeAttachmentRemoved: PropTypes.func.isRequired
};

export default SomeAttachmentRemoved;
