import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import NoMatchesFound from './NoMatchesFound';
import ProjectGridItem from './ProjectGridItem';
import ProjectCreatedSubscriber from './ProjectCreatedSubscriber';
import ProjectDeletedSubscriber from './ProjectDeletedSubscriber';
import ProjectArchivedChangedSubscriber
from './ProjectArchivedChangedSubscriber';

function ProjectsGrid({ projects, ...otherQueryResults }) {
  const { variables, refetch, subscribeToMore } = otherQueryResults;
  const { teamId, filter } = variables;
  const { archived } = filter;
  const noMatchesFound = (projects.length === 0);

  return (
    <React.Fragment>
      {noMatchesFound ? (
        <NoMatchesFound criteria={filter} />
      ) : (
        <Grid container spacing={2}>
          {projects.map((project) => (
            <ProjectGridItem key={project.id} project={project} />
          ))}
        </Grid>
      )}

      <ProjectCreatedSubscriber
        teamId={teamId}
        skip={archived}
        onSubscriptionData={({ client, subscriptionData }) => {
          refetch();
        }}
      />
      <ProjectDeletedSubscriber
        teamId={teamId}
        subscribeToProjectDeleted={subscribeToMore}
        skip={noMatchesFound}
      />

      <ProjectArchivedChangedSubscriber
        teamId={teamId}
        subscribeToProjectArchivedChanged={subscribeToMore}
        refetchProjectSearchResults={refetch}
        archivedFilter={filter.archived}
      />
    </React.Fragment>
  );
}

ProjectsGrid.propTypes = {
  projects: PropTypes.array.isRequired
};

export default ProjectsGrid;
