import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import TimelineItem from './TimelineItem';
import TimelineItemDivider from './TimelineItemDivider';
import TimelineItemContent from './TimelineItemContent';
import TimelineContext from './TimelineContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 1, 0),
  },
  divider: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dot: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `4px solid ${theme.palette.background.paper}`
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  left: {
    justifyContent: 'flex-end'
  }
}));

function DateItemSkeleton({ left }) {
  const classes = useStyles();
  const { matches } = React.useContext(TimelineContext);
  return (
    <TimelineItem className={classes.root} left={left}>
      <TimelineItemDivider className={classes.divider}>
        <Skeleton variant='rect' className={classes.dot} />
      </TimelineItemDivider>
      <TimelineItemContent
        className={clsx(classes.content, {
          [classes.left]: left && matches
        })}
      >
        <Skeleton height={20} width='50%' />
      </TimelineItemContent>
    </TimelineItem>
  );
}

DateItemSkeleton.propTypes = {
  left: PropTypes.bool
};

DateItemSkeleton.defaultProps = {
  left: false
};

export default DateItemSkeleton;
