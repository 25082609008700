import React from 'react';
import PropTypes from 'prop-types';
import Composer from '../../ui/comments/Composer';
import ComposerAvatar from '../../ui/comments/ComposerAvatar';
import ComposerEditor from '../../ui/comments/ComposerEditor';
import useCreateCommentMutation from './useCreateCommentMutation';
import { FILE_COMMENTS_QUERY } from './CommentsQuery';
import { generateNewCommentId } from './helpers';

function UserCommentComposer({ projectId, fileId, user }) {
  const [createComment] = useCreateCommentMutation({
    variables: { fileId },

    update: (cache, { data: { createFileComment: newComment }}) => {
      const data = cache.readQuery({
        query: FILE_COMMENTS_QUERY,
        variables: { projectId, fileId }
      });

      let comments = [...data.project.upload.comments];

      // We have to check for existance here because the comment may have
      // already been added by subscription.
      const found = comments.some(({ id }) => ( id === newComment.id ));
      if (!found) {
        comments = [...comments, newComment];
      }

      cache.writeQuery({
        query: FILE_COMMENTS_QUERY,
        variables: { projectId, fileId },
        data: {
          project: {
            ...data.project,
            upload: {
              ...data.project.upload,
              comments: comments
            }
          }
        }
      });
    }
  });

  const handleSubmit = ({ text, files }) => {
    createComment({
      variables: { text, files },
      optimisticResponse: {
        createFileComment: {
          id: generateNewCommentId(),
          text: text,
          files: [],
          autoGenerated: false,
          severity: 'DEFAULT',
          author: user,
          updatedAt: new Date().toISOString(),
          __typename: 'Comment'
        }
      }
    });
  };

  return (
    <Composer>
      <ComposerAvatar user={user} />
      <ComposerEditor
        placeholder='Ask a question or leave your comment here...'
        onSubmit={handleSubmit}
        projectId={projectId}
      />
    </Composer>
  );
}

UserCommentComposer.propTypes = {
  user: PropTypes.object.isRequired,
  fileId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default UserCommentComposer;
