import React from 'react';
import JoinOrLeaveButton from './JoinOrLeaveButton';
import BellIcon from '../../icons/18x18/Bell';

export default function FollowMessageButton(props) {
  const tooltip = 'Get notifications about activity on this message.';
  return (
    <JoinOrLeaveButton
      label='Subscribe'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
