import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../ui/file/table/Table';
import TableHead from '../../ui/file/table/TableHead';
import TableBody from '../../ui/file/table/TableBody';
import TableBodyEmpty from '../../ui/file/table/TableBodyEmpty';
import DropzoneIndicatorRow from '../../ui/file/table/DropzoneIndicatorRow';
import {
  COLUMNS, getRowComparator, stableSortRows, RowShape
} from './upload_table';

// 1 extra column for checkbox.
const colCount = COLUMNS.length + 1;

function UploadTable({ numRows, rows, numSelected, onSelectAllClick }) {
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('ASC');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const sortedRows = stableSortRows(
    rows,
    getRowComparator(order, orderBy)
  );

  return (
    <Table width='100%'>
      <TableHead
        checkbox
        columns={COLUMNS}
        onRequestSort={handleRequestSort}
        orderBy={orderBy}
        order={order}
        rowCount={numRows}
        numSelected={numSelected}
        onSelectAllClick={onSelectAllClick}
      />
      {(numRows === 0) ? (
        <TableBodyEmpty colCount={colCount}>
          <DropzoneIndicatorRow colSpan={colCount} />
        </TableBodyEmpty>
      ) : (
        <TableBody
          checkbox
          columns={COLUMNS}
          rows={sortedRows}
        />
      )}
    </Table>
  );
}

UploadTable.propTypes = {
  rows: PropTypes.arrayOf(RowShape).isRequired,
  onSelectAllClick: PropTypes.func.isRequired
};

export default UploadTable;
