import React from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    height: 40,
    borderBottom: '1px solid #EBEDF0',
    padding: theme.spacing(0, 2)
  },
  checkbox: {
    width: 48,
    paddingLeft: 6,
    paddingRight: 6
  }
}));

export default function Cell({ checkbox, className, ...rest }) {
  const classes = useStyles();
  return (
    <TableCell
      className={clsx(classes.cell, {
        [classes.checkbox]: checkbox
      }, className)}
      {...rest}
    />
  );
}
