import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  shadowHeader: {
    flex: '0 0 auto',
    position: 'absolute',
    top: 1,
    left: 0,
    width: '100%',
    height: 40,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    pointerEvents: 'none'
  },
  tableRoot: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function TableContainer({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.shadowHeader} />
      <div className={classes.tableRoot}>
        {children}
      </div>
    </div>
  );
}

TableContainer.propTypes = {
  children: PropTypes.node
};

export default TableContainer;
