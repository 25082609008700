import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '../../../ui/todo/details/AttachmentSectionSkeleton';
import AttachmentsQuery from './AttachmentsQuery';
import AttachmentUploader from './AttachmentUploader';
import AttachmentList from './AttachmentList';

function AttachmentSection({ todoId, projectId }) {
  return (
    <AttachmentsQuery
      todoId={todoId}
      onLoading={() => <Skeleton />}
      fetchPolicy='network-only'
    >
      {(attachments, { refetch, subscribeToMore }) => (
        <section style={{marginBottom: 24}}>
          <AttachmentUploader
            todoId={todoId}
            projectId={projectId}
            showPlaceholder={attachments.length === 0}
          />
          <AttachmentList
            attachments={attachments}
            todoId={todoId}
            refetchAttachments={refetch}
            onSomeAttachmentRemoved={subscribeToMore}
          />
        </section>
      )}
    </AttachmentsQuery>
  );
}
AttachmentSection.propTypes = {
  todoId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default AttachmentSection;
