import { Alert as MuiAlert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

const Alert = withStyles((theme) => ({
  root: {
    //borderRadius: 0
  }
}))(MuiAlert);

export default Alert;
