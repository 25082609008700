import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '../../ui/menu/MenuItem';
import FolderDropdownButton from './FolderDropdownButton';
import Menu from './UploadFilesDestinationMenu';
import LoadingMenu from './UploadFilesDestinationLoadingMenu';
import { useListFoldersInProjectLazyQuery }
from '../ListFoldersInProjectQuery';

const useStyles = makeStyles((theme) => ({
  destination: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  destinationText: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.3,
    marginRight: 8
  },
  menuItem: {
    borderRadius: 4,
    padding: theme.spacing(1)
  }
}));

function appendRootFolderToQueryResult(queryResult) {
  const rootFolder = {id: 'root', name: 'Current project'};

  if (!queryResult) {
    return [rootFolder];
  }

  return [...queryResult.project.folders, rootFolder];
}

function UploadFilesDestination({ projectId, value, onChange }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Lazy load folders in the project.
  const [
    loadFolders,
    { called, loading, data }
  ] = useListFoldersInProjectLazyQuery({
    variables: { projectId },
    fetchPolicy: 'network-only'
  });

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    loadFolders();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isLoading = called && loading;
  const folders = appendRootFolderToQueryResult(data);

  return (
    <div className={classes.destination}>
      <Typography
        variant='h6'
        className={classes.destinationText}
      >
        Destination
      </Typography>

      <FolderDropdownButton
        label={value.name}
        onClick={handleButtonClick}
      />

      {isLoading ? (
        <LoadingMenu
          open={true}
          anchorEl={anchorEl}
        />
      ) : (
        <Menu
          open={Boolean(anchorEl) && called}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          {folders.map((folder) => (
            <MenuItem
              key={folder.id}
              className={classes.menuItem}
              primary={folder.name}
              selected={(folder.id === value.id)}
              onClick={(event) => {
                handleClose();
                if (onChange) {
                  onChange(event, folder);
                }
              }}
            />
          ))}
        </Menu>
      )}
    </div>
  );
}

UploadFilesDestination.propTypes = {
  projectId: PropTypes.string.isRequired,

  // Which folder?
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,

  // What to do when destination folder changes.
  // (event, newdestination) => {...}
  onChange: PropTypes.func,
}

export default UploadFilesDestination;
