import gql from 'graphql-tag';

const TODO_NOTIFICATION = gql`
  fragment TodoNotification on Notification {
    todoNotification {
      id
      type

      todo {
        id
        name
        todoList {
          id
          project {
            id
            name
          }
        }
      }

      actor {
        id
        username
        email
        avatar
      }

      comment {
        id
      }
    }
  }
`;

const MESSAGE_NOTIFICATION = gql`
  fragment MessageNotification on Notification {
    messageNotification {
      id
      type

      message {
        id
        subject
        project { id name }
      }

      actor {
        id
        username
        email
        avatar
      }

      comment {
        id
      }
    }
  }
`;

const EVENT_NOTIFICATION = gql`
  fragment EventNotification on Notification {
    eventNotification {
      id
      type

      event {
        id
        title
        allDay
        startsAt
        project { id name }
      }

      reminder {
        offsetUnit
        offsetQuantity
      }

      actor {
        id
        username
        email
        avatar
      }

      comment {
        id
      }
    }
  }
`;

const FILE_NOTIFICATION = gql`
  fragment FileNotification on Notification {
    fileNotification {
      id
      type

      file {
        id
        name
        ext
        project { id name }
      }

      actor {
        id
        username
        email
        avatar
      }

      comment {
        id
      }
    }
  }
`;

export const NOTIFICATION_DETAILS_FRAGMENT = gql`
  fragment NotificationDetails on Notification {
    id
    unread
    updatedAt
    ...TodoNotification
    ...MessageNotification
    ...EventNotification
    ...FileNotification
  }
  ${TODO_NOTIFICATION}
  ${MESSAGE_NOTIFICATION}
  ${EVENT_NOTIFICATION}
  ${FILE_NOTIFICATION}
`;
