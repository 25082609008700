import React from 'react';
import PropTypes from 'prop-types';
import StyledHeaderCell from '../../ui/todo/sheet/HeaderCell';
import useResizeColumnMutation from '../hooks/useResizeColumnMutation';
import { PROJECT_SHEET_COLUMNS } from '../fragments';

function HeaderCell({
  projectId,
  optimisticResponseUponColumnResize,
  ...rest
}) {
  const [resizeColumn] = useResizeColumnMutation();

  const handleColumnResize = (col, deltaX) => {
    const { name, width, minWidth, maxWidth } = col;
    let newWidth = width + deltaX;

    if (minWidth && newWidth < minWidth) {
      newWidth = minWidth;
    }

    if (maxWidth && newWidth > maxWidth) {
      newWidth = maxWidth;
    }

    if (newWidth === width) return;

    resizeColumn({
      variables: { projectId, col: name, width: newWidth },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTodoSheetColumns: optimisticResponseUponColumnResize(
          col,
          newWidth
        )
      },
      update(client, { data: { updateTodoSheetColumns }}) {
        client.writeFragment({
          id: `Project:${projectId}`,
          fragment: PROJECT_SHEET_COLUMNS,
          data: {
            todoSheetColumns: updateTodoSheetColumns,
            __typename: 'Project'
          }
        });
      }
    });
  };

  return <StyledHeaderCell onResize={handleColumnResize} {...rest} />;
}

HeaderCell.propTypes = {
  projectId: PropTypes.string.isRequired,
  optimisticResponseUponColumnResize: PropTypes.func.isRequired
};

export default HeaderCell;
