import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';
import { CHIP_EVENT } from '../fragments';

const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($projectId: ID!, $attrs: EventInput!) {
    createEvent(projectId: $projectId, attrs: $attrs) {
      ...ChipEvent
    }
  }
  ${CHIP_EVENT}
`;


export default function useCreateEvent(options) {
  return useMutation(CREATE_EVENT_MUTATION, {
    loadingMessage: 'Saving...',
    successMessage: 'Event saved',
    ...options
  });
}
