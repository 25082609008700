import {
  differenceInDays,
  eachDayOfInterval,
  startOfDay,
  endOfDay
} from 'date-fns';
import { groupEventsIntoDays } from '../../helpers';
import isSameTypeAndId from '../../daily-schedule/isSameTypeAndId';

function distance(leftDay, rightDay) {
  const left = startOfDay(leftDay);
  const right = startOfDay(rightDay);
  return differenceInDays(left, right);
}

export default function combineAllDaySchedulesWithGridAttributes(
  allDaySchedules,
  daysRange
) {
  // Client is responsible for making sure that all schedules in the
  // given `allDaySchedules` list overlap with this range.
  const startDate = startOfDay(daysRange.start);
  const endDate = endOfDay(daysRange.end);

  // Number of columns in the grid
  const numCols = distance(endDate, startDate) + 1;

  // TODO: I think we should not re-use this shit
  const columns = groupEventsIntoDays(allDaySchedules, eachDayOfInterval({
    start: startDate,
    end: endDate
  }));

  let results = [];

  columns.forEach(({ positionedEvents }) => {
    positionedEvents.forEach(({ event, position }) => {
      const found = results.some(({ schedule }) => (
        isSameTypeAndId(schedule, event)
      ));

      if (!found) {
        results.push({
          schedule: event,
          gridAttributes: {
            numCols,
            rowIndex: position,
            colIndex: distance(event.startsAt, startDate),
            colSpan: distance(event.endsAt, event.startsAt) + 1
          }
        });
      }
    });
  });

  return results;
}
