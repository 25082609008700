import React from 'react';
import PropTypes from 'prop-types';

// TODO: FIXME!
import LazyFollowerSearch from '../../ui/todo/details/LazyFollowerSearch';
import useLazySearchActiveProjectMember
from './useLazySearchActiveProjectMember';

function UpdateFollowersInput({ projectId, ...rest }) {
  const [criteria, setCriteria] = React.useState({ matching: '' });
  const [searchResults, setSearchResults] = React.useState([]);

  const handleMatchingChange = (newMatching) => {
    setCriteria({ matching: newMatching });
  };

  const [
    searchActiveProjectMember, { loading }
  ] = useLazySearchActiveProjectMember({
    variables: { projectId },
    onCompleted(data) {
      const members = data.project.members;
      const users = members.map(({ user }) => user);
      setSearchResults(users);
    }
  });

  React.useEffect(() => {
    if (criteria.matching) {
      searchActiveProjectMember({
        variables: {filter: criteria}
      });
    }
  }, [criteria, searchActiveProjectMember]);

  return (
    <LazyFollowerSearch
      fullWidth
      InputProps={{
        autoFocus: true,
        placeholder: 'Search by name or email',
        helperText: 'Click away or press ESC to save.'
      }}
      onMatchingChange={handleMatchingChange}
      loading={loading}
      searchResults={searchResults}
      {...rest}
    />
  );
}

UpdateFollowersInput.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default UpdateFollowersInput;
