import React from 'react';
import PropTypes from 'prop-types';
import GenericActivityItem from './ui/GenericActivityItem';

function CreateProjectItem({ activity  }) {
  const { type, actor, insertedAt } = activity;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='created this project'
      insertedAt={insertedAt}
    />
  );
}

CreateProjectItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['CREATE_PROJECT']).isRequired,
    actor: PropTypes.object.isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default CreateProjectItem;
