import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { PROJECT_MEMBERS_FRAGMENT } from './fragments';
import Error from '../oops/Error';

const PROJECT_MEMBERS_QUERY = gql`
  query GetProjectMembers($projectId: ID!) {
    project(projectId: $projectId) {
      id
      ...ProjectMembers
    }
  }
  ${PROJECT_MEMBERS_FRAGMENT}
`;

function ProjectMembersQuery({
  projectId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const {loading, error, data, ...otherQueryResults} = useQuery(
    PROJECT_MEMBERS_QUERY,
    {
      variables: { projectId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.project.members, otherQueryResults);
}

ProjectMembersQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ProjectMembersQuery;
export { PROJECT_MEMBERS_QUERY };
