import React from 'react';
import PropTypes from 'prop-types';
import StyledHeader from '../../ui/todo/details/Header';
import HeaderToolbar from '../../ui/todo/details/HeaderToolbar';
import ToolbarButton from '../../ui/todo/details/HeaderToolbarButton';
import CloseIcon from '../../icons/18x18/Close';
import MarkComplete from './MarkComplete';

function Header({ todoId, name, completed, onClose }) {
  return (
    <StyledHeader>
      <MarkComplete
        todoId={todoId}
        name={name}
        completed={completed}
      />
      <HeaderToolbar>
        <ToolbarButton
          background={false}
          aria-label='close'
          icon={ <CloseIcon /> }
          onClick={onClose}
        />
      </HeaderToolbar>
    </StyledHeader>
  );
}

Header.propTypes = {
  todoId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default Header;
