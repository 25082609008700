import { isSameMonth, isSameYear, format } from 'date-fns';
import startOfWeek from './startOfWeek';
import endOfWeek from './endOfWeek';

export default function weekViewLabel(date) {
  const start = startOfWeek(date);
  const end = endOfWeek(date);

  if (isSameMonth(start, end)) {
    return format(start, 'MMMM yyyy');
  }

  const endLabel = format(end, 'MMM yyyy');
  let startLabel;

  if (isSameYear(start, end)) {
    startLabel = format(start, 'MMM');
  } else {
    startLabel = format(start, 'MMM yyyy');
  }

  return `${startLabel} - ${endLabel}`;
}
