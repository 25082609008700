import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const CREATE_WORKSPACE_MUTATION = gql`
  mutation CreateWorkspace($name: String!, $description: String) {
    createWorkspace(name: $name, description: $description) {
      id
    }
  }
`;

export default function useCreateWorkspaceMutation(options) {
  const history = useHistory();

  return useMutation(CREATE_WORKSPACE_MUTATION, {
    onCompleted({ createWorkspace: { id }}) {
      history.push(`/workspaces/${id}`);
    },
    ...options
  });
}
