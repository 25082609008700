import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';

const UPDATE_TODO_COLOR_MUTATION = gql`
  mutation UpdateTodoColor($todoId: ID!, $color: String!) {
    updateTodoItem(todoItemId: $todoId, changes: { color: $color }) {
      id
      color
    }
  }
`;

export default function useUpdateTodoColor() {
  const [mutate, mutationResults] = useMutation(
    UPDATE_TODO_COLOR_MUTATION, {
      loadingMessage: 'Saving...',
      successMessage: 'Task saved'
    }
  );

  const mutateFn = useCallback((todoId, color) => {
    mutate({
      variables: { todoId, color },
      optimisticResponse: {
        updateTodoItem: {
          id: todoId,
          color,
          __typename: 'TodoItem'
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
