import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const TEAM_MEMBER_REMOVED_SUBSCRIPTION = gql`
  subscription OnTeamMemberRemoved($teamId: ID!) {
    teamMemberRemoved(teamId: $teamId) {
      teamMembership {
        id
      }
    }
  }
`;

export default function useMemberRemovedSubscription(options) {
  const { teamId, ...otherOptions } = options;
  return useSubscription(TEAM_MEMBER_REMOVED_SUBSCRIPTION, {
    variables: { teamId },
    ...otherOptions
  });
}

export { TEAM_MEMBER_REMOVED_SUBSCRIPTION };
