import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Row from './Row';
import Cell from './LargeCell';
import Button from '../../button/Button';
import ClearIcon from '../../../icons/16x16/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  }
}));

function NoMatchesRow({ colSpan, onClearFilters }) {
  const classes = useStyles();

  return (
    <Row>
      <Cell colSpan={colSpan}>
        <div className={classes.root}>
          <Typography
            variant='body1'
            color='textSecondary'
            className={classes.title}
          >
            No matches
          </Typography>

          <Typography
            variant='body1'
            color='textSecondary'
            className={classes.subtitle}
          >
            We can't find a match.
          </Typography>

          <Button
            startIcon={<ClearIcon />}
            onClick={onClearFilters}
          >
            Clear filters
          </Button>
        </div>
      </Cell>
    </Row>
  );
}

NoMatchesRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  onClearFilters: PropTypes.func
};

export default NoMatchesRow;
