import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { TODO_ITEM_DETAILS } from '../fragments';
import { matchesTodoCriteria } from '../helpers';

const SOME_TODO_ITEM_UPDATED_SUBSCRIPTION = gql`
  subscription OnSomeTodoItemUpdated($todoListId: ID!) {
    someTodoItemUpdated(todoListId: $todoListId) {
      ...TodoItemDetails
    }
  }
  ${TODO_ITEM_DETAILS}
`;

export default function useSomeTodoItemUpdatedSubscription(options) {
  const { todoListId, todoCriteria, ...otherOptions } = options;

  const fragmentObj = {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };

  return useSubscription(SOME_TODO_ITEM_UPDATED_SUBSCRIPTION, {
    variables: { todoListId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const updatedTodo = subscriptionData.data.someTodoItemUpdated;
      const todoList = client.readFragment(fragmentObj);
      const todoItems = todoList.todoItems;

      if (!matchesTodoCriteria(updatedTodo, todoCriteria)) {
        client.writeFragment({
          ...fragmentObj,
          data: {
            ...todoList,
            todoItems: todoItems.filter(({ id }) => id !== updatedTodo.id)
          }
        });
      } else {
        const found = todoItems.some(({ id }) => (id === updatedTodo.id));

        if (found) {
          return;
        }

        const newTodoItems = [...todoItems, updatedTodo];

        // Since we sort ascending by rank in backend.
        newTodoItems.sort((item1, item2) => {
          const rank1 = item1.rank;
          const rank2 = item2.rank;

          if (rank1 < rank2) {
            return -1;
          } else if (rank1 > rank2) {
            return 1;
          } else {
            return 0;
          }
        });

        client.writeFragment({
          ...fragmentObj,
          data: {
            ...todoList,
            todoItems: newTodoItems
          }
        });
      }
    },
    ...otherOptions
  });
}
