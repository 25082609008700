import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Content from '../../ui/todo/details/Content';
import TodoSection from './TodoSection';
import AttachmentSection from './attachment/AttachmentSection';
import ActivitySection from './ActivitySection';
import CreateComment from './comment/CreateComment';
import Footer from '../../ui/todo/details/Footer';
import ContainerContext from './ContainerContext';
import FollowersBar from './follower/FollowersBar';

function TodoDetailsWithData({ todo, onClose }) {
  const sentinelEndRef = React.useRef();

  const ctx = React.useMemo(() => ({
    scrollToBottom: () => {
      sentinelEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }), []);

  return (
    <ContainerContext.Provider value={ctx}>
      <Header
        todoId={todo.id}
        name={todo.name}
        completed={todo.completed}
        onClose={onClose}
      />
      <Content sentinelEndRef={sentinelEndRef}>
        <TodoSection todo={todo} />
        <AttachmentSection
          todoId={todo.id}
          projectId={todo.todoList.project.id}
        />
        <ActivitySection
          todoId={todo.id}
          projectId={todo.todoList.project.id}
        />
      </Content>
      <Footer>
        <CreateComment
          projectId={todo.todoList.project.id}
          todoId={todo.id}
        />
        <FollowersBar
          projectId={todo.todoList.project.id}
          todoId={todo.id}
        />
      </Footer>
    </ContainerContext.Provider>
  );
}

TodoDetailsWithData.propTypes = {
  todo: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default TodoDetailsWithData;
