import React from 'react';
import MenuItem from '../menu/MenuItem';
import EditIcon from '../../icons/20x20/Pencil';

export default React.forwardRef(function EditFileMenuItem(props, ref) {
  const { closeMenu, onClick, ...rest } = props;

  const handleClick = (event) => {
    closeMenu();
    if (onClick) { onClick(event); }
  };

  return (
    <MenuItem
      ref={ref}
      primary='Edit this file'
      secondary='Edit name and notes.'
      icon={ <EditIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});
