import { isSameDay } from 'date-fns';
import { parseDateInput } from '../../new-ui/datetime-picker/parseDateInput';

// Returns `true` if the `dueDate` of the given `todo` is the same
// day as the given `date`.
// Returns `false`, otherwise.
export default function todoOverlapsWithDate(todo, date) {
  const dueDate = parseDateInput(todo.dueDate);
  return isSameDay(dueDate, date);
}
