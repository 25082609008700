import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TeamDropdownButton from './TeamDropdownButton';
import TeamDropdownButtonWithData from './TeamDropdownButtonWithData';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

function TeamPickerButton({ teamId, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='body1' className={classes.title}>
        In
    </Typography>

      {teamId ? (
        <TeamDropdownButtonWithData
          teamId={teamId}
          onClick={onClick}
        />
      ) : (
        <TeamDropdownButton
          placeholder
          onClick={onClick}
        />
      )}
    </div>
  );
}

TeamPickerButton.propTypes = {
  teamId: PropTypes.string,
  onClick: PropTypes.func
};

export default TeamPickerButton;
