// Define style constants for `DataGrid`.
export const TODO_SHEET_THEME = {
  // The height of each table cell (1 extra pixel for the border)
  cellHeight: 37,

  // Border of table cell
  cellBorder: '1px solid #EBEDF0',

  // The gap between two sections
  gapBetweenSections: 24,

  borderColor: '#EBEDF0',

  // The width of drag handle cell
  dragHandleCellWidth: 48
};
