import React from 'react';
import PropTypes from 'prop-types';
import CurrentUserProfileQuery from '../../account/CurrentUserProfileQuery';
import UserCommentComposer from './UserCommentComposer';

function CommentComposer({ messageId, projectId }) {
  return (
    <CurrentUserProfileQuery>
      {(currentUser) => (
        <UserCommentComposer
          user={currentUser}
          messageId={messageId}
          projectId={projectId}
        />
      )}
    </CurrentUserProfileQuery>
  );
}

CommentComposer.propTypes = {
  messageId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default CommentComposer;
