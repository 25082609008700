import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from '../../input/textfield_background.png';

const useStyles = makeStyles((theme) => ({
  uploadList: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: `#fff url(${BackgroundImage}) repeat-x`,
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.shadow}`,
  }
}));

export default function UploadListContainer(props) {
  const classes = useStyles();
  return <div className={classes.uploadList} {...props} />;
}
