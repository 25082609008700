// Returns the index of the specified time (hours, minutes) within the
// given timeline (which is an array of such objects).
//
// TODO: consider to loose the contraints a bit and return the timeline
// point who is the most closest to the given time.
export default function findTimeIndex({ hours, minutes }, timeline) {
  return timeline.findIndex((time) => (
    time.hours === hours &&
    time.minutes === minutes
  ));
}
