import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from './SectionHeaderIconButton';
import ArrowRightIcon from '../../../icons/20x20/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    })
  },
  expandOpen: {
    transform: 'rotate(0deg)'
  }
}));

function ExpandSectionButton({ expanded, ...rest }) {
  const classes = useStyles();
  return (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded
      })}
      icon={ <ArrowRightIcon /> }
      {...rest}
    />
  );
}

ExpandSectionButton.propTypes = {
  expanded: PropTypes.bool
};

ExpandSectionButton.defaultProps = {
  expanded: false
};

export default React.memo(ExpandSectionButton);
