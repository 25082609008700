import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Cell from './Cell';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.background.paper
  },
  cell: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1)
  },
  button: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 7),
    borderRadius: 0,
    justifyContent: 'space-between',
    color: theme.palette.text.secondary
  }
}));

function AddTodoButtonRow({ colSpan, onClick }) {
  const classes = useStyles();

  return (
    <tr className={classes.row}>
      <Cell colSpan={colSpan} className={classes.cell}>
        <Button
          component='div'
          className={classes.button}
          onClick={onClick}
        >
          Add task...
        </Button>
      </Cell>
    </tr>
  );
}

AddTodoButtonRow.propTypes = {
  colSpan: PropTypes.number
};

AddTodoButtonRow.defaultProps = {
  colSpan: 2
};

export default React.memo(AddTodoButtonRow);
