import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SizeMe } from 'react-sizeme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    height: 'auto',
    maxWidth: 'none',
    flexShrink: 0,
    userSelect: 'none',
    transition: 'transform 200ms ease 0s'
  }
}));

// Returns a scale that would scale the image to fit the container size
// while maintaining the image aspect ratio.
function scaleImageToFitContainer(imgSize, containerSize) {
  const { width: imgWidth, height: imgHeight } = imgSize;
  const { width: conWidth, maxheight: conMaxHeight} = containerSize;

  if (!imgWidth || !imgHeight || !conWidth || !conMaxHeight) {
    return 0.0;
  }

  let scaledWidth = imgWidth;
  let scaledHeight = imgHeight;

  if (conMaxHeight === 'none') {
    if (scaledWidth > conWidth) { scaledWidth = conWidth; }
  } else {
    while (scaledWidth > conWidth || scaledHeight > conMaxHeight) {
      // keep resizing until it fits the container while maintaining
      // the original aspect ratio of the image.
      if (scaledWidth > conWidth) {
        scaledWidth = conWidth;
        scaledHeight = (imgHeight / imgWidth) * scaledWidth;
      } else {
        scaledHeight = conMaxHeight;
        scaledWidth = (imgWidth / imgHeight) * scaledHeight;
      }
    }
  }

  return scaledWidth / imgWidth;
}

function Image({
  scale,
  alt,
  src,
  naturalWidth,
  naturalHeight,
  onLoad,
  onError,
  maxHeight
}) {
  const classes = useStyles();
  const naturalDim = {width: naturalWidth, height: naturalHeight};

  return (
    <SizeMe>
      {({ size }) => {
        const fitScale = scaleImageToFitContainer(naturalDim, {
          width: size.width,
          maxheight: maxHeight
        });

        const imgScale = scale * fitScale;
        let containerHeight = imgScale * naturalDim.height;

        if (maxHeight !== 'none' && maxHeight < containerHeight) {
          containerHeight = maxHeight;
        }

        const imgStyle = {transform: `scale(${imgScale})`};
        const conStyle = {height: containerHeight};

        return (
          <div className={classes.root} style={conStyle}>
            <img
              alt={alt}
              src={src}
              className={classes.img}
              style={imgStyle}
              width={naturalWidth}
              height={naturalHeight}
              onLoad={onLoad}
              onError={onError}
            />
          </div>
        );
      }}
    </SizeMe>
  );
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,

  naturalWidth: PropTypes.number.isRequired,
  naturalHeight: PropTypes.number.isRequired,

  onLoad: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,

  scale: PropTypes.number,

  // If `maxHeight` is set to `none`, the height of the image container
  // will be the same as that of the image (after the image has been
  // scaled to fit the container width).
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['none'])
  ]).isRequired
};

Image.defaultProps = {
  maxHeight: 'none',
  scale: 1.0
};

export default Image;
