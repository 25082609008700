import React from 'react';
import PropTypes from 'prop-types';
import addTime from '../daily-schedule/ui/addTime';
import subTime from '../../new-ui/datetime-picker/subTime';
import compareTime from '../../new-ui/datetime-picker/compareTime';
import Grid from './ui/TimeSpecificGrid';
import TimeSpecificSnapshot from './ui/TimeSpecificSnapshot';
import UpdateTimeMutation from './UpdateTimeMutation';
import FormDialog from '../daily-schedule/FormDialog';

// Helpers.

function _init(dragItem) {
  if (!dragItem) {
    return null;
  }

  return {
    ...dragItem,
    originalStart: dragItem.chipProps.start,
    originalEnd: dragItem.chipProps.end,
    isNew: false,
    isDraggingStopped: false
  };
}

function _createDragItem({ colIndex, time }) {
  return {
    schedule: null,

    chipProps: {
      colIndex,
      start: time.start,
      end: time.end
    },

    originalStart: time.start,
    originalEnd: time.end,
    isNew: true,
    isDraggingStopped: false
  };
}

function _updateTime(dragItem, { origLocation, currLocation }) {
  const origTime = origLocation.time;
  const currTime = currLocation.time;

  let startTime = origTime.start;
  let endTime = origTime.end;

  if (compareTime(currTime.end, endTime) > 0) {
    endTime = currTime.end;
  } else {
    startTime = currTime.start;
  }

  return {
    ...dragItem,
    chipProps: {
      ...dragItem.chipProps,
      start: startTime,
      end: endTime
    }
  };
}

function _updateLocation(dragItem, { origLocation, currLocation }) {
  const origTime = origLocation.time;
  const currTime = currLocation.time;
  const delta = subTime(currTime.start, origTime.start);

  const { originalStart, originalEnd } = dragItem;

  return {
    ...dragItem,
    chipProps: {
      ...dragItem.chipProps,
      start: addTime(originalStart, delta),
      end: addTime(originalEnd, delta),
      colIndex: currLocation.colIndex
    }
  };
}

function _updateDragItem(dragItem, locations) {
  if (dragItem.isNew) {
    return _updateTime(dragItem, locations);
  } else {
    return _updateLocation(dragItem, locations);
  }
}

function _reducer(state, action) {
  switch(action.type) {
    case 'START_DRAGGING':
      const { event, location } = action.payload;

      // User drags to create new schedule.
      if (event === 'DRAG') {
        return _createDragItem(location);
      }

      // User clicks to create new schedule.
      if (event === 'CLICK') {
        return {
          ..._createDragItem({
            ...location,
            time: {
              start: location.time.start,
              end: addTime(location.time.start, {hours: 1, minutes: 0})
            }
          }),
          isDraggingStopped: true
        };
      }

      throw new Error(`Unknown event: ${event}`);

    case 'UPDATE_DRAGGING':
      if (!state) {
        return state;
      }

      return _updateDragItem(state, action.payload);

    case 'STOP_DRAGGING':
      if (!state) {
        return state;
      }

      return {...state, isDraggingStopped: true};

    case 'RESET_DRAG_ITEM':
      return _init(action.payload);

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}

// Exports

function TimeSpecificGrid({
  projectId,
  days,
  dragItem,
  dragZIndex,
  onDragStart,
  onDragEnd,
  children
}) {
  const [state, dispatch] = React.useReducer(_reducer, dragItem, _init);

  const handleDragStart = React.useCallback((e, location) => {
    const id = e.target.dataset.scheduleId;
    const type = e.target.dataset.scheduleType;

    if(id && type && onDragStart) {
      onDragStart({ id, __typename: type });
    } else {
      dispatch({
        type: 'START_DRAGGING',
        payload: { event: 'DRAG', location }
      });
    }
  }, [onDragStart]);

  const handleDragUpdate = React.useCallback((origLoc, currLoc) => {
    dispatch({
      type: 'UPDATE_DRAGGING',
      payload: { origLocation: origLoc, currLocation: currLoc }
    });
  }, []);

  const handleDragEnd = React.useCallback((origLoc, currLoc) => {
    if (onDragEnd) {
      onDragEnd();
    }

    dispatch({
      type: 'STOP_DRAGGING',
      payload: { origLocation: origLoc, currLocation: currLoc}
    });
  }, [onDragEnd]);

  // Clicks to create new schedule.
  const handleClick = React.useCallback((location) => {
    dispatch({
      type: 'START_DRAGGING',
      payload: { event: 'CLICK', location }
    });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: 'RESET_DRAG_ITEM',
      payload: dragItem
    });
  }, [dragItem]);

  const numCols = days.length;
  const chipWidth = 100 / numCols;

  const {
    chipProps = null,
    schedule = null,
    isNew = false,
    isDraggingStopped = false
  } = state || {};

  return (
    <Grid
      numCols={numCols}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
    >
      {children}

      {chipProps && (
        <TimeSpecificSnapshot
          {...chipProps}
          zIndex={dragZIndex}
          width={chipWidth}
        />
      )}

      <UpdateTimeMutation
        runMutation={isDraggingStopped && !isNew}
        schedule={schedule}
        date={chipProps ? days[chipProps.colIndex] : null}
        startTime={chipProps ? chipProps.start : null}
        endTime={chipProps ? chipProps.end : null}
      />

      {(chipProps && isNew && isDraggingStopped) && (
        <FormDialog
          projectId={projectId}
          date={days[chipProps.colIndex]}
          time={{start: chipProps.start, end: chipProps.end}}
          onClose={() => dispatch({
            type: 'RESET_DRAG_ITEM',
            payload: null
          })}
        />
      )}
    </Grid>
  );
}

TimeSpecificGrid.propTypes = {
  // Which project?
  projectId: PropTypes.string.isRequired,

  // Typically a list of days within a week
  days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,

  // TODO
  dragItem: PropTypes.object,

  // Typically number of schedules plus 1
  dragZIndex: PropTypes.number.isRequired,

  // ({ id, __typename }) => {...}
  onDragStart: PropTypes.func,

  // () => {...}
  onDragEnd: PropTypes.func,

  children: PropTypes.node
};

export default TimeSpecificGrid;
