import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '1.0625rem'
  },
  primary: {
    lineHeight: 1.25,
    fontWeight: theme.typography.fontWeightMedium
  },
  secondary: {
    lineHeight: 1.3
  }
}));

function AutocompleteOwnerOption({ owner }) {
  const classes = useStyles();
  return (
    <ListItem component='div' className={classes.listItem}>
      <ListItemAvatar>
        <UserAvatar user={owner} size='small' />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          color: 'textPrimary',
          noWrap: true,
          className: classes.primary
        }}
        secondaryTypographyProps={{
          noWrap: true,
          className: classes.secondary
        }}
        primary={owner.username}
        secondary={owner.email}
      />
    </ListItem>
  );
}

AutocompleteOwnerOption.propTypes = {
  owner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
};

export default AutocompleteOwnerOption;
