import React from 'react';
import Grid from '@material-ui/core/Grid';
import InvitationGridItemSkeleton from './InvitationGridItemSkeleton';

export default function InvitationsSearchLoading() {
  return (
    <Grid container spacing={2}>
      <InvitationGridItemSkeleton />
      <InvitationGridItemSkeleton />
      <InvitationGridItemSkeleton />
      <InvitationGridItemSkeleton />
    </Grid>
  );
}
