import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles, fade } from '@material-ui/core/styles';
import Overlay from './Overlay';
import Spinner from '../progress/Spinner';
import { FILTER_ACCENT, FILTER_ALWAYS_WHITE } from '../icons/Base';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'inherit',
    fontWeight: 600,
    textTransform: 'none',
    transition: 'none',
    minWidth: theme.spacing(8),
    borderRadius: theme.shape.borderRadius,

    // Default height = 40px
    fontSize: '1.0625rem',
    padding: theme.spacing(1, 2),
    lineHeight: 1.45,

    '&:hover $overlay': {
      opacity: 1.0
    },

    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },

    '&.Mui-disabled': {
      backgroundColor: '#E4E6EB',
      color: theme.palette.text.disabled
    }
  },

  label: {
    width: '100%',
    display: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit'
  },

  // For small button, height = 36px
  small: {
    fontSize: '0.9375rem',
    lineHeight: 1.75,
    padding: '5px 10px'
  },

  // For large button, height = 48px
  large: {
    padding: '11px 22px',
    lineHeight: 1.3,
    fontSize: '1.25rem'
  },

  contained: {
    '&$primary': {
      color: theme.palette.button.primary.contrastText,
      backgroundColor: theme.palette.button.primary.main
    },
    '&$secondary': {
      color: theme.palette.button.secondary.contrastText,
      backgroundColor: theme.palette.button.secondary.main
    },
    '&$success': {
      color: theme.palette.button.success.contrastText,
      backgroundColor: theme.palette.button.success.main
    },
    '&$danger': {
      color: theme.palette.button.danger.contrastText,
      backgroundColor: theme.palette.button.danger.main
    }
  },

  outlined: {
    // Keep the button's height consistent with the contained version.
    lineHeight: 1.3,
    '&$small': {
      lineHeight: 1.6
    },
    '&$large': {
      lineHeight: 1.2
    },

    '&$primary': {
      color: theme.palette.button.primary.main,
      border: `1px solid ${fade(theme.palette.button.primary.main, 0.5)}`,
      '&:hover': {
        backgroundColor: fade(theme.palette.button.primary.main, 0.02),
        border: `1px solid ${theme.palette.button.primary.main}`
      }
    },

    '&$secondary': {
      color: theme.palette.button.secondary.contrastText,
      border: `1px solid ${theme.palette.divider}`,
      '&:hover': {
        backgroundColor: 'rgba(5, 5, 5, 0.02)'
      }
    },

    '&$success': {
      color: theme.palette.button.success.main,
      border: `1px solid ${fade(theme.palette.button.success.main, 0.5)}`,
      '&:hover': {
        backgroundColor: fade(theme.palette.button.success.main, 0.02),
        border: `1px solid ${theme.palette.button.success.main}`
      }
    },

    '&$danger': {
      color: theme.palette.button.danger.main,
      border: `1px solid ${fade(theme.palette.button.danger.main, 0.5)}`,
      '&:hover': {
        backgroundColor: fade(theme.palette.button.danger.main, 0.02),
        border: `1px solid ${theme.palette.button.danger.main}`
      }
    }
  },

  text: {
    '&$primary': {
      color: theme.palette.button.primary.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.button.primary.main, 0.02)
      }
    },

    '&$secondary': {
      color: theme.palette.text.secondary
    },

    '&$success': {
      color: theme.palette.button.success.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.button.success.main, 0.02)
      }
    },

    '&$danger': {
      color: theme.palette.button.danger.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.button.danger.main, 0.02)
      }
    }
  },

  primary: {
    '& $startIcon': {
      filter: FILTER_ALWAYS_WHITE
    }
  },
  primaryDeemphasized: {
    backgroundColor: '#E7F3FF',
    color: theme.palette.primary.main,
    '& $startIcon': {
      filter: FILTER_ACCENT
    }
  },
  secondary: {},
  success: {},
  danger: {},

  fullWidth: {
    width: '100%'
  },

  overlay: {},

  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  startIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Button = React.forwardRef(function Button(props, ref) {
  const {
    children,
    size,
    color,
    variant,
    className,
    overlayClassName,
    fullWidth,
    loading,
    disabled,
    startIcon,
    ...rest
  } = props;

  const classes = useStyles();
  const spinnerSize = (size === 'small') ? 18 : (size === 'large' ? 24 : 20);
  const isDisabled = disabled || loading;

  return (
    <ButtonBase
      ref={ref}
      className={clsx(classes.root, classes[size], {
        [classes.fullWidth]: fullWidth,
        [classes[variant]]: !isDisabled,
        [classes[color]]: !isDisabled
      }, className)}
      disabled={isDisabled}
      {...rest}
    >
      <span className={classes.label}>
        {startIcon && React.cloneElement(startIcon, {
          className: clsx(classes.startIcon, startIcon.props.className)
        })}
        {children}
      </span>

      <Overlay className={clsx(classes.overlay, overlayClassName)} />

      {loading && (
        <Spinner
          size={spinnerSize}
          thickness={4.5}
          className={classes.spinner}
        />
      )}
    </ButtonBase>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf([
    'primary', 'primaryDeemphasized', 'secondary', 'success', 'danger'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool
};

Button.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  size: 'medium',
  fullWidth: false,
  loading: false
};

export default Button;
