import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function CellLoading() {
  const classes = useStyles();
  return (
    <div className={classes.progressContainer}>
      <CircularProgress size={20} thickness={6} />
    </div>
  );
}
