import React from 'react';
import PropTypes from 'prop-types';
import CurrentUserQuery from '../../../account/CurrentUserProfileQuery';
import SubscribeMe from './SubscribeMe';
import UnsubscribeMe from './UnsubscribeMe';

function MeButton({ eventId, subscribers }) {
  return (
    <CurrentUserQuery>
      {(me) => {
        const found = subscribers.some(({ id }) => id === me.id);
        return found ? (
          <UnsubscribeMe eventId={eventId} />
        ) : (
          <SubscribeMe eventId={eventId} />
        );
      }}
    </CurrentUserQuery>
  );
}

MeButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  subscribers: PropTypes.array.isRequired
};

export default MeButton;
