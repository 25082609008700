import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex'
  }
}));

function StyledListItem({ id, children }) {
  const classes = useStyles();
  const ref = React.useRef(null);
  const { hash } = useLocation();

  React.useEffect(() => {
    if (hash && id && hash === `#${id}`) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [id, hash]);

  return (
    <div ref={ref} className={classes.listItem} id={id}>
      {children}
    </div>
  );
}

StyledListItem.propTypes = {
  // HTML id of this item
  id: PropTypes.string,

  children: PropTypes.node
};

export default StyledListItem;
