import React from 'react';
import PropTypes from 'prop-types';
import useWorkspaceURL from '../workspace/hooks/useCurrentWorkspaceURL';
import ListItem from '../ui/notification/ListItem';
import ListItemAvatar from '../ui/notification/ListItemAvatar';
import ListItemText from '../ui/notification/ListItemText';
import UserAvatar from '../account/UserAvatar';
import { formatTime } from './helpers';
import { generateMessageCommentHtmlId } from '../message/comments/helpers';

function getNotificationText(msgNotification)  {
  const subject = msgNotification.message.subject;
  const projName = msgNotification.message.project.name;
  const timestamp = formatTime(msgNotification.updatedAt);
  const actorName = msgNotification.actor.username;

  let primary = '';

  switch(msgNotification.type) {
    case 'CREATE':
      primary = subject;
      break;
    case 'COMMENT':
      primary = `Re: ${subject}`;
      break;
    default:
      break;
  }

  return {
    primary,
    secondary: [actorName, projName, timestamp]
  };
}

function getNotificationUrl(msgNotification, workspaceUrl) {
  const projId = msgNotification.message.project.id;
  const msgId = msgNotification.message.id;
  const msgUrl = `projects/${projId}/messages/${msgId}`;
  const url = `${workspaceUrl}/${msgUrl}`;

  if (msgNotification.type === 'COMMENT') {
    const commentId = msgNotification.comment.id
    const htmlId = generateMessageCommentHtmlId(msgId, commentId);
    return `${url}#${htmlId}`;
  } else {
    return url;
  }
}

const MessageNotificationItem = React.forwardRef((props, ref) => {
  const { notification, onMarkAsRead, ...rest } = props;
  const workspaceUrl = useWorkspaceURL();
  const {id, updatedAt, messageNotification } = notification;

  return (
    <ListItem
      ref={ref}
      id={id}
      url={getNotificationUrl(messageNotification, workspaceUrl)}
      onMarkAsRead={onMarkAsRead}
      {...rest}
    >
      <ListItemAvatar
        primary={<UserAvatar user={messageNotification.actor} />}
        secondary='MESSAGE'
      />
      <ListItemText
        {...getNotificationText({
          ...messageNotification,
          updatedAt: updatedAt
        })}
      />
    </ListItem>
  );
})

MessageNotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onMarkAsRead: PropTypes.func.isRequired
};

export default MessageNotificationItem;
