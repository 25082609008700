import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  }
}));

export default function FolderPickerLoading() {
  const classes = useStyles();
  return (
    <div className={classes.list}>
      <div>
        <Skeleton width='100%' height={18} />
        <Skeleton width='40%' height={12} />
      </div>

      <div>
        <Skeleton width='100%' height={18} />
        <Skeleton width='40%' height={12} />
      </div>

      <div>
        <Skeleton width='100%' height={18} />
        <Skeleton width='40%' height={12} />
      </div>
    </div>
  );
}
