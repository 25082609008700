import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(1.5)  // 12px
  }
}));

export default function List({ className, ...rest }) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.list, className)}
      {...rest}
    />
  );
}
