import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../../ui/icons/CloseMui';
import Header from './Header';
import HeaderToolbar from './HeaderToolbar';
import ToolbarButton from './HeaderToolbarButton';
import MarkCompleteSkeleton from './MarkCompleteSkeleton';

function HeaderSkeleton({ onClose }) {
  return (
    <Header>
      <MarkCompleteSkeleton />
      <HeaderToolbar>
        <ToolbarButton
          background={false}
          aria-label='close'
          icon={ <CloseIcon /> }
          onClick={onClose}
        />
      </HeaderToolbar>
    </Header>
  );
}

HeaderSkeleton.propTypes = {
  onClose: PropTypes.func
};

export default HeaderSkeleton;
