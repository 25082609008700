import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DragIndicatorIcon from '../../../icons/16x16/DragIndicator';
import Cell from './Cell';
import { TODO_SHEET_THEME } from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: TODO_SHEET_THEME.dragHandleCellWidth,
    borderTop: TODO_SHEET_THEME.cellBorder,
    borderBottom: TODO_SHEET_THEME.cellBorder,
    borderLeft: 0,
    borderRight: 0
  },
  iconRoot: {
    width: '100%',
    height: '100%',
    fontSize: '1.25rem',
    color: theme.palette.text.secondary,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function DragHandleCell({ className, dragIconRootClassName, ...rest }) {
  const classes = useStyles();

  return (
    <Cell className={clsx(classes.root, className)} {...rest}>
      <div className={clsx(classes.iconRoot, dragIconRootClassName)}>
        <DragIndicatorIcon color='secondary' />
      </div>
    </Cell>
  );
}

export default React.memo(DragHandleCell);
