import React from 'react';
import PropTypes from 'prop-types';
import { startOfDay, endOfDay, isBefore, isAfter } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { parseDateInput } from '../new-ui/datetime-picker/parseDateInput';
import useCustomSnackbar from '../snackbar/useCustomSnackbar';
import { isTodo, isEvent } from './helpers';
import { CHIP_EVENT, CHIP_TODO } from './fragments';

const PROJECT_SCHEDULES_QUERY = gql`
  query ListProjectSchedules($projectId: ID!, $filter: ScheduleFilter) {
    project(projectId: $projectId) {
      id
      schedules(filter: $filter) {
        __typename

        ... on Event {
          ...ChipEvent
        }

        ... on TodoItem {
          ...ChipTodo
        }
      }
    }
  }
  ${CHIP_EVENT}
  ${CHIP_TODO}
`;

function convertRawSchedule(schedule) {
  // If the given schedule is a todo, we convert its due date to
  // LOCAL TIMEZONE, and also add two additional properties for
  // convenience later on.
  if (isTodo(schedule)) {
    const dueDate = parseDateInput(schedule.dueDate);

    return {
      ...schedule,
      dueDate,
      startsAt: startOfDay(dueDate),
      endsAt: endOfDay(dueDate)
    };
  }

  // Otherwise, if it is  an event, we convert its starting point and
  // ending point to LOCAL TIMEZONE.
  if(isEvent(schedule)) {
    return {
      ...schedule,
      startsAt: parseDateInput(schedule.startsAt),
      endsAt: parseDateInput(schedule.endsAt)
    };
  }

  // Something else we don't know how to handle yet.
  return null;
}

function buildSchedulesCriteria({ start, end }) {
  return {
    datetimeBetween: {
      startsAt: start,
      endsAt: end
    }
  };
}

function ProjectSchedulesQuery({
  projectId,
  start,
  end,
  onLoading,
  onError,
  children,
  ...otherQueryOptions
}) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  const { loading, data, ...otherQueryResults } = useQuery(
    PROJECT_SCHEDULES_QUERY,
    {
      variables: {
        projectId,
        filter: buildSchedulesCriteria({ start, end })
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        } else {
          enqueueGraphQLErrorSnackbar(error);
        }
      },
      ...otherQueryOptions
    }
  );

  // TODO: explain why do wee need the last filter
  const convertRawSchedules = React.useCallback((rawSchedules) => {
    return rawSchedules.map(convertRawSchedule)
                       .filter(Boolean)
                       .filter(({ startsAt, endsAt }) => (
                         isBefore(startsAt, end) &&
                         isAfter(endsAt, start)
                       ));
  }, [start, end]);

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  // TODO: what if no data (in case of error, for example)?
  const rawSchedules = data.project.schedules;
  const schedules = convertRawSchedules(rawSchedules);
  return children(schedules, otherQueryResults);
}

ProjectSchedulesQuery.propTypes = {
  // Which project
  projectId: PropTypes.string.isRequired,

  // Only schedules which overlap with this interval.
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,

  onLoading: PropTypes.func,
  onError: PropTypes.func,

  children: PropTypes.func.isRequired
};

export default ProjectSchedulesQuery;

export {
  PROJECT_SCHEDULES_QUERY,
  convertRawSchedule,
  buildSchedulesCriteria
};
