import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import StarIcon from '../../icons/20x20/Star';
import MenuContext from '../../ui/menu/MenuContext';
import usePinProjectMutation from '../hooks/usePinProjectMutation';

const AddToFavoritesItem = React.forwardRef((props, ref) => {
  const { projectId, ...other } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const [ pinProject ] = usePinProjectMutation({ projectId });

  const handleClick = () => {
    closeMenu();
    pinProject();
  };

  return (
    <MenuItem
      ref={ref}
      primary='Add to favorites'
      icon={ <StarIcon /> }
      onClick={handleClick}
      {...other}
    />
  );
});

AddToFavoritesItem.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default AddToFavoritesItem;
