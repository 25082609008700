import React from 'react';
import PropTypes from 'prop-types';
import DroppableSectionBody from '../../ui/todo/sheet/DroppableSectionBody';
import SectionRow from './SectionRow';
import { TODO_CRITERIA_SHAPE } from '../helpers';

function SectionBody({ sectionId, todoCriteria, cols, rows }) {

  return (
    <DroppableSectionBody sectionId={sectionId}>
      {rows.map((row, idx) => (
        <SectionRow
          key={row.id}
          index={idx}
          sectionId={sectionId}
          todoCriteria={todoCriteria}
          row={row}
          cols={cols}
        />
      ))}
    </DroppableSectionBody>
  );
}

SectionBody.propTypes = {
  sectionId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

export default React.memo(SectionBody);
