import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '../../../icons/20x20/Plus';
import Button from '../../button/Button';
import { TODO_SHEET_THEME } from './theme';

const useStyles = makeStyles((theme) => ({
  cell: {
    // 40px for button height
    height: 40 + 2 * TODO_SHEET_THEME.gapBetweenSections
  }
}));

function StyledFooter({ colSpan, onClick }) {
  const classes = useStyles();

  return (
    <tfoot>
      <tr>
        <td colSpan={colSpan} className={classes.cell}>
          <Button
            color='secondary'
            startIcon={ <AddIcon /> }
            onClick={onClick}
          >
            Add list
          </Button>
        </td>
      </tr>
    </tfoot>
  );
}

StyledFooter.propTypes = {
  colSpan: PropTypes.number,
  onClick: PropTypes.func
};

StyledFooter.defaultProps = {
  colSpan: 2
};

export default React.memo(StyledFooter);
