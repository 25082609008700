import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';
import CellContent from './CellContent';
import { isDirectory, getIcon24ForMimeType } from '../mime/mime';

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1)
  },
  link: {
    //color: theme.palette.text.primary,
    fontWeight: 500
  }
}));

function FileNameCell({ name, type, url }) {
  const classes = useStyles();
  const Icon = getIcon24ForMimeType(type);

  return (
    <Cell>
      <CellContent>
        <div className={classes.linkContainer}>
          <Icon className={classes.icon} />
          <Link
            noWrap
            variant='body2'
            className={classes.link}
            component={NavLink}
            to={url}
          >
            {isDirectory(type) ? `${name}/` : name}
          </Link>
        </div>
      </CellContent>
    </Cell>
  );
}

FileNameCell.propTypes = {
  // MIME type for uploads, the string `directory` for folders,...
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default FileNameCell;
