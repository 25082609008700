import React from 'react';
import PropTypes from 'prop-types';
import SectionSearchNotFound from '../../ui/page/SectionSearchNotFound';

function NoMatchesFound({ criteria }) {
  const { matching, archived } = criteria;
  let message;
  if (matching) message = `No results for: ${matching}.`;
  else if (archived) message = 'No archived projects found.';
  else message = 'No active projects found.';
  return <SectionSearchNotFound message={message} />;
}

NoMatchesFound.propTypes = {
  criteria: PropTypes.shape({
    matching: PropTypes.string,
    archived: PropTypes.bool
  }).isRequired
};

export default NoMatchesFound;
