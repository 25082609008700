import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  }
}));

function SectionHeader({ children, className, ...rest }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.sectionHeader, className)} {...rest}>
      {children}
    </div>
  );
}

SectionHeader.propTypes = {
  children: PropTypes.node
};

export default SectionHeader;
