import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'inherit',
    textTransform: 'none',
    transition: 'none',
    ...theme.typography.body2,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),

    '&:hover': {
      backgroundColor: '#F0F2F5'
    },
    '&:active': {
      backgroundColor: '#DADDE1'
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#F0F2F5'
    }
  }
}));

export default function AddNotificationButton(props) {
  const classes = useStyles();
  return (
    <ButtonBase className={classes.root} {...props}>
      Add notification
    </ButtonBase>
  );
}
