import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  section: {
    position: 'relative',  // A trick to position seaction search input.
    borderRadius: 8,
    padding: theme.spacing(2),
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)'
  }
}));

function Section({ children, className, ...rest }) {
  const classes = useStyles();
  return (
    <Container maxWidth='md' className={classes.root}>
      <Paper className={clsx(classes.section, className)} {...rest}>
        {children}
      </Paper>
    </Container>
  );
}

Section.propTypes = {
  children: PropTypes.node
};

export default Section;
