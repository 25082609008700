import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../button/IconButton';
import EllipsisIcon from '../../icons/20x20/Ellipsis';
import Menu from '../menu/StyledMenu';
import MenuItem from '../menu/MenuItem';
import PencilIcon from '../../icons/20x20/Pencil';
import TrashIcon from '../../icons/20x20/Trash';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

function MessageDetailsMenu({ onEdit, onDelete }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    handleClose(event);

    if (onEdit) {
      onEdit(event);
    }
  };

  const handleDelete = (event) => {
    handleClose(event);

    if (onDelete) {
      onDelete(event);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <IconButton
        background={open}
        size='small'
        color={open ? 'accent' : 'secondary'}
        icon={ <EllipsisIcon /> }
        onClick={handleOpen}
      />

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          icon={ <PencilIcon /> }
          primary='Edit this message'
          onClick={handleEdit}
        />
        <MenuItem
          icon={ <TrashIcon />}
          primary='Delete this message'
          onClick={handleDelete}
        />
      </Menu>
    </div>
  );
}

MessageDetailsMenu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default MessageDetailsMenu;
