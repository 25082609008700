import React from 'react';
import MuiList from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  messageList: {
    '& > *:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

export default function List(props) {
  const classes = useStyles();
  return (
    <MuiList
      disablePadding
      className={classes.messageList}
      {...props}
    />
  );
}
