import React from 'react';
import Section from '../../home/Section';
import SectionHeader from '../../home/SectionHeader';
import SectionBody from '../../home/SectionBody';
import MyFavoritesQuery from './MyFavoritesQuery';
import ProjectGrid from './ProjectGrid';
import ProjectGridSkeleton from './ProjectGridSkeleton';

// TODO: We have to make a network request every fucking single time
// this shit is expanded JUST because we don't yet know how to update
// the cache after a project got pinned! (see also usePinProjectMutation).
// hint:
// 1. Make sure that the project card and projec page request the same
// project shape.
// 2. Send the entire project (not just projectId) to whoever triggers the
// pin mutation.
function MyFavorites() {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Section>
      <SectionHeader
        expanded={expanded}
        onToggleExpanded={() => setExpanded(!expanded)}
        title='My Favorites'
      />

      {expanded && (
        <SectionBody>
          <MyFavoritesQuery
            onLoading={() => <ProjectGridSkeleton />}
            fetchPolicy='cache-and-network'
          >
            {(projects, { subscribeToMore }) => (
              <ProjectGrid
                projects={projects}
                subscribeToMore={subscribeToMore}
              />
            )}
          </MyFavoritesQuery>
        </SectionBody>
      )}
    </Section>
  );
}

export default MyFavorites;
