import React from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

// INDETERMINATE progress (often used while fetching presigned URL)
export default function UploadLoading({ className, ...rest }) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.progressContainer, className)}
      {...rest}
    >
      <CircularProgress size={20} thickness={6} />
    </div>
  );
}
