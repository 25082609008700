import React from 'react'
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../ui/dialog/DialogLoading';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContext from '../../ui/dialog/DialogContext';
import UserWorkspaceMembershipList from './UserWorkspaceMembershipList';
import CurrentUserWorkspaceMembershipsQuery
from '../CurrentUserWorkspaceMembershipsQuery';

const useStyles = makeStyles((theme) => ({
  content: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 1.5)
  },
  noWorkspaceText: {
    margin: 0,
    padding: theme.spacing(0, 1.5)
  }
}));

function SignInToOtherWorkspace() {
  const { closeDialog } = React.useContext(DialogContext);
  const classes = useStyles();
  return (
    <CurrentUserWorkspaceMembershipsQuery
      onLoading={() => <Loading /> }
      fetchPolicy='network-only'
    >
      {(workspaceMemberships) => (
        <React.Fragment>
          <DialogTitle onClose={closeDialog}>
            Sign in to other workspace
          </DialogTitle>
          <DialogContent className={classes.content}>
            {(workspaceMemberships.length === 0) ? (
              <DialogContentText className={classes.noWorkspaceText}>
                Your workspace list will be displayed here.
              </DialogContentText>
            ) : (
              <UserWorkspaceMembershipList workspaceMemberships={workspaceMemberships} />
            )}

          </DialogContent>
        </React.Fragment>
      )}
    </CurrentUserWorkspaceMembershipsQuery>
  );
}

export default SignInToOtherWorkspace;
