import React from 'react';
import PropTypes from 'prop-types';
import EventChipContainer from './EventChipContainer';
import Chip from './Chip';

function MonthGridEventsCellOverflowMenu({ overflowEvents, onClick }) {
  const count = overflowEvents.length;
  const top = count ? overflowEvents[count - 1] : null;

  return top ? (
    <EventChipContainer
      position={top.position}
      colIndex={top.colIndex}
      colSpan={1}
    >
      <Chip
        leftRadius
        rightRadius
        moreButton
        label={`${count} more...`}
        onClick={onClick}
      />
    </EventChipContainer>
  ) : null;
}


MonthGridEventsCellOverflowMenu.propTypes = {
  overflowEvents: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

export default MonthGridEventsCellOverflowMenu;
