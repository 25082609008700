import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import Menu from './UploadFilesDestinationMenu';

export default function UploadFilesDestinationLoadingMenu(props) {
  return (
    <Menu
      variant='menu'
      MenuListProps={{
        style: {
          width: 152
        }
      }}
      {...props}
    >
      <ListItem>
        <ListItemText primary={<Skeleton />} />
      </ListItem>
      <ListItem>
        <ListItemText primary={<Skeleton />} />
      </ListItem>
      <ListItem>
        <ListItemText primary={<Skeleton />} />
      </ListItem>
    </Menu>
  );
}
