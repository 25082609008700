import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledMenu from '../../ui/menu/StyledMenu';
import ListItem from '../../ui/list/ListItem';
import MenuItemDivider from '../../ui/menu/MenuItemDivider';

export default function LoadingMenu(props) {
  return (
    <StyledMenu
      autoFocus={false}
      {...props}
    >
      <ListItem
        primary={<Skeleton variant='text' />}
        avatar={<Skeleton variant='circle' width={64} height={64} />}
      />

      <MenuItemDivider />

      {[0, 1, 2].map((key) => (
        <ListItem
          key={key}
          primary={<Skeleton variant='text' />}
          avatar={<Skeleton variant='circle' width={36} height={36} />}
        />
      ))}
    </StyledMenu>
  );
}
