import React from 'react';
import MenuItem from '../../new-ui/select/MenuItem';

export default React.forwardRef(function ToolbarMenuItem(props, ref) {
  return (
    <MenuItem
      ref={ref}
      typography='body2'
      {...props}
    />
  );
});
