import React from 'react';
import PropTypes from 'prop-types';
import CreateProjectItem from './CreateProjectItem';
import CommentTodoItem from './CommentTodoItem';
import GrantAccessItem from './GrantAccessItem';
import RevokeAccessItem from './RevokeAccessItem';
import AddTodoItem from './AddTodoItem';
import CompleteTodoItem from './CompleteTodoItem';
import ReopenTodoItem from './ReopenTodoItem';
import ReassignOwnerItem from './ReassignOwnerItem';
import UploadFileItem from './UploadFileItem';
import AddMessageItem from './AddMessageItem';
import CommentMessageItem from './CommentMessageItem';
import AddEventItem from './AddEventItem';
import RescheduleEventItem from './RescheduleEventItem';
import CommentEventItem from './CommentEventItem';
import CommentFileItem from './CommentFileItem';

function ActivityItem({ activity }) {
  switch(activity.type) {
    case 'CREATE_PROJECT':
      return <CreateProjectItem activity={activity} />;
    case 'COMMENT_TODO':
      return <CommentTodoItem activity={activity} />;
    case 'GRANT_ACCESS':
      return <GrantAccessItem activity={activity} />;
    case 'REVOKE_ACCESS':
      return <RevokeAccessItem activity={activity} />;
    case 'ADD_TODO':
      return <AddTodoItem activity={activity} />;
    case 'COMPLETE_TODO':
      return <CompleteTodoItem activity={activity} />;
    case 'REOPEN_TODO':
      return <ReopenTodoItem activity={activity} />;
    case 'REASSIGN_OWNER':
      return <ReassignOwnerItem activity={activity} />;
    case 'UPLOAD_FILE':
      return <UploadFileItem activity={activity} />;
    case 'ADD_MESSAGE':
      return <AddMessageItem activity={activity} />;
    case 'COMMENT_MESSAGE':
      return <CommentMessageItem activity={activity} />;
    case 'ADD_EVENT':
      return <AddEventItem activity={activity} />;
    case 'RESCHEDULE_EVENT':
      return <RescheduleEventItem activity={activity} />;
    case 'COMMENT_EVENT':
      return <CommentEventItem activity={activity} />;
    case 'COMMENT_FILE':
      return <CommentFileItem activity={activity} />;
    default:
      return null;
  }
}

ActivityItem.propTypes = {
  activity: PropTypes.object.isRequired
};

export default ActivityItem;
