import { useCallback } from 'react';
import { RichUtils } from 'draft-js';
import DraftHandleValue from '../utils/DraftHandleValue';

export default function useHandleKeyCommandFn(dispatchEditorState) {
  return useCallback((commandString, editorState) => {
    const newEditorState = RichUtils.handleKeyCommand(
      editorState,
      commandString
    );

    if (newEditorState) {
      dispatchEditorState(newEditorState);
      return DraftHandleValue.HANDLED;
    }

    return DraftHandleValue.NOT_HANDLED;
  }, [dispatchEditorState]);
}
