import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  blockquote: {
    borderLeft: '4px solid #E4E6EB',
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('md')]: {
      fontSize: 'calc(0.75rem + 0.5vw)'
    },
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2)
  },
  codeblock: {
    ...theme.typography.body1,
    borderRadius: 4,
    backgroundColor: '#F5F6F7',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: '0.9375rem',
    padding: theme.spacing(2)
  },
  headerOne: {
    ...theme.typography.h6,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.2,
    fontWeight: 700,
    padding: 0,
    margin: theme.spacing(2, 0)
  },
  list: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('md')]: {
      fontSize: 'calc(0.75rem + 0.5vw)'
    }
  },
  unstyled: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('md')]: {
      fontSize: 'calc(0.75rem + 0.5vw)'
    }
  }
}));

export default function useCustomBlockStyleFn() {
  const classes = useStyles();

  return (contentBlock) => {
    const blockType = contentBlock.getType();

    switch(blockType) {
      case 'blockquote':
        return classes.blockquote;
      case 'code-block':
        return classes.codeblock;
      case 'header-one':
      case 'header-two':
      case 'header-three':
      case 'header-four':
      case 'header-five':
      case 'header-six':
        return classes.headerOne;
      case 'unordered-list-item':
      case 'ordered-list-item':
        return classes.list;
      case 'unstyled':
        return classes.unstyled;
      default:
        return null;
    }
  };
}
