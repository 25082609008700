import React from 'react';
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack';
import SnackMessage from './SnackMessage';

// A workaround to pass down the `variant` from `enqueueSnackbar` to
// custom snackbar item.
// See https://github.com/iamhosseindhv/notistack/issues/218
const customSnackbarOptionsFromMessage = (message) => {
  if (!message) return {};
  return JSON.parse(message);
};

function SnackbarProvider({children, ...rest}) {
  const content = (key, message) => (
    <SnackMessage
      id={key}
      options={customSnackbarOptionsFromMessage(message)}
    />
  );

  return (
    <NotiSnackbarProvider
      {...rest}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      content={content}
    >
      {children}
    </NotiSnackbarProvider>
  );
}

export default SnackbarProvider;
