import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import useDeactivateMembershipMutation
from '../hooks/useDeactivateMembershipMutation';

function DeactivateSelfMembershipConfirm(props) {
  const { membershipId, workspaceName, client } = props;
  const { closeDialog } = React.useContext(DialogContext);
  const history = useHistory();

  const [
    deactivateSelfMembership,
    { loading }
  ] = useDeactivateMembershipMutation({
    membershipId,
    onCompleted(data) {
      closeDialog();
      client.clearStore().then(() => {
        history.push('/');
      });
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Leave {workspaceName}?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          If you remove yourself from this workspace, you will no longer
          be able to sign in to it.
        </DialogContentText>

        <DialogContentText style={{marginBottom: 0}}>
          Are you sure you wish to to this?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {!loading && (
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={closeDialog}
          >
            Never mind
          </Button>
        )}

        <Button
          variant='contained'
          color='primary'
          size='small'
          loading={loading}
          onClick={deactivateSelfMembership}
        >
          Yes, remove me
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

DeactivateSelfMembershipConfirm.propTypes = {
  membershipId: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default withApollo(DeactivateSelfMembershipConfirm);
