import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import TimeSpecificScheduleChip from './ui/TimeSpecificScheduleChip';
import useScheduleUrl from './useScheduleUrl';
import useMatchCurrentScheduleUrl from './useMatchCurrentScheduleUrl';
import withContextMenu from './withContextMenu';

function TimeSpecificScheduleItem({ schedule, chipProps, ...rest }) {
  const isFocus = useMatchCurrentScheduleUrl(schedule);
  const chipPropsWithFocus = {...chipProps, focus: isFocus};

  const scheduleUrl = useScheduleUrl(schedule);
  const history = useHistory();

  const redirectToScheduleDetails = (event) => {
    history.push(scheduleUrl);
  };

  return (
    <TimeSpecificScheduleChip
      {...chipPropsWithFocus}
      {...rest}
      onClick={redirectToScheduleDetails}
    />
  );
}

TimeSpecificScheduleItem.propTypes = {
  // A todo or an event object.
  schedule: PropTypes.object.isRequired,

  // The corresponding chip props
  chipProps: PropTypes.object.isRequired
};

export default withContextMenu(TimeSpecificScheduleItem);
