import React from 'react';
import filesize from 'filesize';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getDescriptionForMimeType } from './mime/mime';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  title: {
    lineHeight: 1.2,
    fontWeight: 700
  },
  subtitle: {
    fontWeight: 500
  }
}));


function UploadMetadata({ type, name, ext, size}) {
  const classes = useStyles();
  const nameWithExt = `${name}${ext}`;
  const description = getDescriptionForMimeType(type);
  const humanReadableSize = filesize(size, {round: 1, base: 10});

  return (
    <div className={classes.root}>
      <Typography variant='h6' className={classes.title}>
        {nameWithExt}
      </Typography>
      <Typography
        variant='body2'
        color='textSecondary'
        className={classes.subtitle}
      >
        {description} - {humanReadableSize}
      </Typography>
    </div>
  );
}

export default UploadMetadata;
