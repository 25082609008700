import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CheckSvg(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0
  1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02
      0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"/>
    </SvgIcon>
  );
}
