import React from 'react';
import BellIcon from '../../../../icons/18x18/BellFill';
import SubscribeUnsubscribeButton from './SubscribeUnsubscribeButton';

export default function UbsubscribeMeButton(props) {
  const tooltip = 'Stop getting notifications about activity on this event.';
  return (
    <SubscribeUnsubscribeButton
      label='Unsubscribe'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
