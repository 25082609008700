import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Header from './ui/header/Header';
import Toolbar from './ui/header/Toolbar';
import CloseButton from './ui/header/ToolbarCloseButton';
import Content from './ui/content/Content';

function EventQueryLoading({ onCancel }) {
  return (
    <>
      <Header>
        <Toolbar>
          <CloseButton onClick={onCancel} />
        </Toolbar>
      </Header>
      <Content>
        <Skeleton />
        <Skeleton width='60%' />
      </Content>
    </>
  );
}

EventQueryLoading.propTypes = {
  onCancel: PropTypes.func
};

export default EventQueryLoading;
