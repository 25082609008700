import React from 'react';
import PropTypes from 'prop-types';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from '../button/Overlay';
import UserAvatar from '../../account/UserAvatar';
import ListItemSubject from './ListItemSubject';
import ListItemDetails from './ListItemDetails';
import ListItemCommentCount from './ListItemCommentCount';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover $overlay': {
      opacity: 1.0
    },
    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },
    '&.Mui-focusVisible $overlay': {
      opacity: 1.0
    }
  },
  comment: {
    marginRight: 0
  },
  commentCount: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 28,
    minWidth: 28,
    padding: 4,
    borderRadius: '50%',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  overlay: {}
}));

function ListItem({
  author,
  subject,
  details,
  commentCount,
  timestamp,
  button,
  ...rest
}) {
  const classes = useStyles();
  return (
    <MuiListItem
      alignItems='flex-start'
      button={button}
      className={classes.root}
      {...rest}
    >
      <ListItemAvatar>
        <UserAvatar user={author} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<ListItemSubject subject={subject} />}
        secondary={(
          <ListItemDetails
            authorName={author.username}
            timestamp={timestamp}
            details={details}
          />
        )}
      />
      <ListItemCommentCount count={commentCount} />
      {button && <Overlay className={classes.overlay} />}
    </MuiListItem>
  );
}

ListItem.propTypes = {
  // The author of the message
  author: PropTypes.object.isRequired,

  // The subject of the message
  subject: PropTypes.string.isRequired,

  // The text contents of the message
  details: PropTypes.string.isRequired,

  // How many comments are there?
  commentCount: PropTypes.number.isRequired,

  // When was this message created, e.g., 'Oct 13'
  timestamp: PropTypes.string.isRequired,

  // Is this item clickable?
  button: PropTypes.bool
};

ListItem.defaultProps = {
  button: true
};

export default ListItem;
