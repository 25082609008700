import ZipIcon128 from '../icons/128/Zip';
import Zip7Icon128 from '../icons/128/Zip7';
import BzipIcon128 from '../icons/128/Bz';
import Bzip2Icon128 from '../icons/128/Bz2';
import GzipIcon128 from '../icons/128/Gz';
import JarIcon128 from '../icons/128/Jar';
import RarIcon128 from '../icons/128/Rar';
import TarIcon128 from '../icons/128/Tar';
import FileArchiveIcon from '../../icons/FileArchive';

import ZipIcon40 from '../icons/40/Zip';

export const ArchiveMimeType = Object.freeze({
  'application/zip': {
    description: 'ZIP Archive',
    Icon128: ZipIcon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/x-7z-compressed': {
    description: '7-ZIP Archive',
    Icon128: Zip7Icon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/x-bzip': {
    description: 'BZip Archive',
    Icon128: BzipIcon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/x-bzip2': {
    description: 'BZip2 Archive',
    Icon128: Bzip2Icon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/gzip': {
    description: 'GZip Compressed Archive',
    Icon128: GzipIcon128,
    Icon40: ZipIcon40
  },
  'application/java-archive': {
    description: 'Java Archive (JAR)',
    Icon128: JarIcon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/x-java-archive': {
    description: 'Java Archive (JAR)',
    Icon128: JarIcon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/vnd.rar': {
    description: 'RAR Archive',
    Icon128: RarIcon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  },
  'application/x-tar': {
    description: 'Tape Archive (TAR)',
    Icon128: TarIcon128,
    Icon24: FileArchiveIcon,
    Icon40: ZipIcon40
  }
});
