import React from 'react';
import { Redirect } from 'react-router-dom';
import CurrentUser from '../components/account/CurrentUserQuery';
import RecentWorkspace from '../components/workspace/RecentWorkspaceQuery';

function Home() {
  return (
    <CurrentUser>
      {(currentUser) => {
        if (!currentUser) return <Redirect to='/sign-in' />;
        return (
          <RecentWorkspace>
            {(recentWorkspace) => {
              if (!recentWorkspace) {
                return <Redirect to='/create-workspace' />;
              }

              return <Redirect to={`/workspaces/${recentWorkspace.id}`} />;
            }}
          </RecentWorkspace>
        );
      }}
    </CurrentUser>
  );
}

export default Home;
