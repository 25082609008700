import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

function DeleteUploadConfirmText({ uploadName }) {
  const name = (
    <Box
      component='span'
      color='text.primary'
      fontWeight={500}
    >
      {uploadName}
    </Box>
  );

  return (
    <DialogContentText>
      Are you sure you want to delete the file {name}? This cannot be
      undone.
    </DialogContentText>
  );
}

DeleteUploadConfirmText.propTypes = {
  uploadName: PropTypes.string.isRequired
};

export default DeleteUploadConfirmText;
