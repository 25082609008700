import React from 'react';
import TextField from '../../../new-ui/Input';
import FormField from './FormField';

export default function NameField(props) {
  return (
    <FormField header>
      <TextField
        fullWidth
        underline
        horizontalPadding={false}
        typography='header'
        background='never'
        inputProps={{spellCheck: false, autoComplete: 'off'}}
        placeholder='Write a task name'
        {...props}
      />
    </FormField>
  );
}
