import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0)
  },
  message: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 500
  }
}));

function SectionSearchNotFound({ message }) {
  const classes = useStyles();
  const defaultMessage = 'No matches found.';
  return (
    <div className={classes.root}>
      <Typography
        variant='body1'
        color='textSecondary'
        className={classes.message}
      >
        { message || defaultMessage }
      </Typography>
    </div>
  );
}

SectionSearchNotFound.propTypes = {
  message: PropTypes.node
};

export default SectionSearchNotFound;
