import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateMessageQuery from './UpdateMessageQuery';
import EditMessageForm from './EditMessageForm';

function EditProjectMessage() {
  const { projectId, messageId } = useParams();
  return (
    <UpdateMessageQuery
      projectId={projectId}
      messageId={messageId}
    >
      {(message) => <EditMessageForm message={message} />}
    </UpdateMessageQuery>
  )
}
export default EditProjectMessage;
