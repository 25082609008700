import { isTodo } from '../helpers';
import { useRouteMatch } from 'react-router-dom';

// Takes a schedule object and returns its URL.
export default function useScheduleUrl(schedule) {
  const { url } = useRouteMatch();
  const scheduleType = isTodo(schedule) ? 'task' : 'event';
  const scheduleId = schedule.id;
  return `${url}/${scheduleType}/${scheduleId}`;
}
