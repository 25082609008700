import React from 'react';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  paper: {
    borderRadius: 4,
    width: theme.spacing(24),
    boxShadow: theme.shadows[4],
    marginTop: 0
  },
  list: {
    padding: theme.spacing(1, 0)
  }
}))((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
));
