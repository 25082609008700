import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import EmptyRow from './EmptyRow';

function TableBodyEmpty({ children, colCount }) {
  return (
    <TableBody>
      <EmptyRow colSpan={colCount} />
      {children}
    </TableBody>
  );
}

TableBodyEmpty.propTypes = {
  colCount: PropTypes.number.isRequired,
  children: PropTypes.node
};

export default TableBodyEmpty;
