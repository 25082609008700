import formatTimeOfDay from './formatTimeOfDay';

const NUM_HOURS_PER_DAY = 24;
const NUM_MINUTES_PER_HOUR = 60;

export default function listTimesOfDay(options) {
  const {
    step = 15, // 15 minutes between two time points by default
    start = 0  // starting point
  } = options || {};

  let times = [];
  let hours = start;
  let minutes = 0;

  while (hours < NUM_HOURS_PER_DAY) {
    times.push({ hours, minutes });
    minutes += step;
    if (minutes >= NUM_MINUTES_PER_HOUR) {
      hours += 1;
      minutes = minutes % NUM_MINUTES_PER_HOUR;
    }
  }

  return times.map(formatTimeOfDay);
}
