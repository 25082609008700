import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../button/IconButton';
import CloseIcon from '../../icons/20x20/Close';
import ArrowBackIcon from '../icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    lineHeight: 1.4,
    textAlign: 'center'
  },

  // applied when we have `onClose`.
  titleWidth: {
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`
  },

  // applied when we have `onGoBack`
  titlePaddingLeft: {
    paddingLeft: theme.spacing(5.25)
  },

  closeButton: {
    position: 'absolute',
    top: 12,
    right: theme.spacing(2),
    color: theme.palette.text.secondary
  },

  goBackButton: {
    position: 'absolute',
    top: 12,
    left: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

function CenteredDialogTitle({
  children,
  onClose,
  onGoBack,
  noWrap,
  ...otherProps
}) {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography {...otherProps}>
      {onGoBack && (
        <IconButton
          aria-label='Go back'
          color='secondary'
          size='small'
          icon={ <ArrowBackIcon /> }
          className={classes.goBackButton}
          onClick={onGoBack}
        />
      )}

      <Typography
        component='h2'
        variant='h6'
        className={clsx(classes.title, {
          [classes.titleWidth]: Boolean(onClose),
          [classes.titlePaddingLeft]: Boolean(onGoBack)
        })}
        noWrap={noWrap}
      >
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          aria-label='close'
          color='secondary'
          size='small'
          icon={ <CloseIcon /> }
          className={classes.closeButton}
          onClick={onClose}
        />
      ) : null}
    </DialogTitle>
  );
}

CenteredDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  onGoBack: PropTypes.func,
  noWrap: PropTypes.bool
};

CenteredDialogTitle.defaultProps = {
  noWrap: false
};

export default CenteredDialogTitle;
