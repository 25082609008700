import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '0 0 auto',
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2, 0),
    }
  }
}));

export default function ProjectHeaderContainer(props) {
  const classes = useStyles();
  return <div className={classes.container} {...props} />
}
