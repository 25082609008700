import React from 'react';
import PropTypes from 'prop-types';
import PickTeamDialogTitle from './PickTeamDialogTitle';
import TeamPicker from './TeamPicker';
import InfoDialogTitle from './InfoDialogTitle';
import InfoDialogContent from './InfoDialogContent';

const secondStepDialog = Object.freeze({
  'PICK_TEAM': 1, // Pick team dialog
  'READ_INFO': 2  // Read info dialog
});

//
// Dialog title
//

function SecondStepDialogTitle({ dialog, ...rest }) {
  switch(dialog) {
    case secondStepDialog.PICK_TEAM:
      return <PickTeamDialogTitle  {...rest} />;
    case secondStepDialog.READ_INFO:
      return <InfoDialogTitle {...rest} />;
    default:
      return null
  }
}

SecondStepDialogTitle.propTypes = {
  dialog: PropTypes.oneOf(Object.values(secondStepDialog))
};

//
// Dialog content.
//

function SecondStepDialogContent({
  dialog,
  teamId,
  onTeamIdChange,
  ...rest
}) {
  switch(dialog) {
    case secondStepDialog.PICK_TEAM:
      return (
        <TeamPicker
          value={teamId}
          onChange={onTeamIdChange}
        />
      );
    case secondStepDialog.READ_INFO:
      return <InfoDialogContent />;
    default:
      return null;
  }
}

SecondStepDialogContent.propTypes = {
  dialog: PropTypes.oneOf(Object.values(secondStepDialog)),
  teamId: PropTypes.string,
  onTeamIdChange: PropTypes.func
};

export {
  secondStepDialog,
  SecondStepDialogTitle,
  SecondStepDialogContent
};
