import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogForm from '../../ui/dialog/DialogForm';
import DialogContext from '../../ui/dialog/DialogContext';
import TextField from '../../ui/input/TextField';
import Button from '../../ui/button/Button';
import useUpdateTeamMutation from '../hooks/useUpdateTeamMutation';
import { gqlErrorDetails } from '../../oops/helpers';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

function EditTeamForm({ team }) {
  const { id, name, description } = team;
  const { closeDialog } = React.useContext(DialogContext);

  const [formData, setFormData] = React.useState({
    name: name,
    description: description || ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  //
  // GraphQL mutation.
  //

  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  const [updateTeam, { loading, error }] = useUpdateTeamMutation({
    variables: {
      teamId: id,
      changes: formData
    },
    onError(error) {
      const errorDetails = gqlErrorDetails(error);
      const isNameError = errorDetails.hasOwnProperty('name');
      if (!isNameError) {
        enqueueGraphQLErrorSnackbar(error);
      }
    },
    onCompleted(data) {
      closeDialog();
    }
  });

  const getNameErrorProps = () => {
    const errorDetails = gqlErrorDetails(error);
    const isNameError = errorDetails.hasOwnProperty('name');
    if (isNameError) return {
      error: true,
      helperText: `A valid team name ${errorDetails['name']}.`
    };
    return null;
  };

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Edit team {name}
      </DialogTitle>
      <DialogContent>
        <DialogForm>
          <TextField
            type='text'
            id='__edit_team_name_input'
            name='name'
            label='Name'
            placeholder='Team name'
            autoComplete='off'
            autoFocus
            value={formData.name}
            onChange={handleChange}
            {...getNameErrorProps()}
          />

          <TextField
            multiline
            type='text'
            id='__edit_team_description_input'
            name='description'
            label='Description'
            helperText="What's this team about?"
            value={formData.description}
            onChange={handleChange}
          />
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          onClick={() => {
            updateTeam();
          }}
        >
          Save changes
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

EditTeamForm.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired
};

export default EditTeamForm;
