import getWindowPageOffset
from '../../../new-ui/helpers/getWindowPageOffset';

// Convert a mouse postion (pageX, pageY) into the grid coordinates:
// (colIndex, rowIndex).
export default function convertMousePositionToGridCoordinates(
  mousePosition,
  options
) {
  const { rect, numRows, numCols } = options;
  const { top, left, width, height } = rect;
  const windowPageOffset = getWindowPageOffset();

  // Mouse position relative to grid (in pixels).
  let relX = mousePosition.pageX - (windowPageOffset.x + left);
  let relY = mousePosition.pageY - (windowPageOffset.y + top);

  if (relX < 0) {
    // mouse is on the left of the grid.
    relX = 0;
  } else if (relX >= width) {
    // mouse is on the right of the grid.
    relX = width - 1;
  }

  if (relY < 0) {
    // mouse is on the top of the grid
    relY = 0;
  } else if (relY >= height) {
    // mouse is at the bottom of the grid
    relY = height - 1;
  }

  //
  // Find column index
  //

  const colWidth = width / numCols;
  let colIndex = 0;
  let leftBound = 0;

  while (colIndex < numCols) {
    const rightBound = leftBound + colWidth;
    if (relX >= leftBound && relX < rightBound) { break; }
    colIndex++;
    leftBound = rightBound;
  }

  //
  // Find row index
  //

  const rowHeight = height / numRows;
  let rowIndex = 0;
  let topBound = 0;

  while (rowIndex < numRows) {
    const bottomBound = topBound + rowHeight;
    if (relY >= topBound && relY < bottomBound) { break; }
    rowIndex++;
    topBound = bottomBound;
  }

  return { rowIndex, colIndex };
}
