import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../ui/button/Button';
import OwnerDropdownButtonLabel from './OwnerDropdownButtonLabel';
import BackgroundImage from '../../ui/input/textfield_background.png';

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: 0,

    // These are here to Make the button looks alike a normal text field.
    background: `#fff url(${BackgroundImage}) repeat-x`,
    border: `1px solid ${theme.palette.divider}`,
    '&.Mui-focusVisible': {
      outline: 0,
      boxShadow: `0 0 0 2px ${theme.palette.primary.shadow}`,
      borderColor: theme.palette.primary.main,
    }
  },

  // A trick to hide this button when autocomplete is open and be able
  // to restore the focus back to it when autocomplete closes.
  invisible: {
    opacity: 0,
    cursor: 'default',
    pointerEvents: 'none'
  }
}));

const OwnerDropdownButton = React.forwardRef(
  function OwnerDropdownButton({ owner, invisible, ...rest }, ref) {
    const classes = useStyles();

    return (
      <Button
        ref={ref}
        component='div'
        variant='text'
        tabIndex={invisible ? -1 : 0}
        className={clsx(classes.button, {
          [classes.invisible]: invisible
        })}
        {...rest}
      >
        <OwnerDropdownButtonLabel owner={owner} />
      </Button>
    );
  }
);

OwnerDropdownButton.propTypes = {
  owner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }),

  // A trick to visually hide this button when autocomplete is open, and
  // also be able to restore the focus back to it when autocomplete
  // closes.
  invisible: PropTypes.bool
};

OwnerDropdownButton.defaultProps = {
  invisible: false
};

export default OwnerDropdownButton;
