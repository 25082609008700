import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';

const PROJECT_HEADER_PROJECT_QUERY = gql`
  query GetProjectDetailsForProjectPageHeader($projectId: ID!) {
    project(projectId: $projectId) {
      id
      name
      color
      pinned
      archived
      team {
        id
        name
      }
    }
  }
`;

function ProjectHeaderProjectQuery({
  projectId,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const {loading, error, data, ...otherQueryResults} = useQuery(
    PROJECT_HEADER_PROJECT_QUERY, {
      variables: { projectId },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return children(data.project, otherQueryResults);
}

ProjectHeaderProjectQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ProjectHeaderProjectQuery;
