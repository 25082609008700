import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';
import DirectoryFilesAddedSubscription
from './DirectoryFilesAddedSubscription';

const FILES_IN_PROJECT_QUERY = gql`
  query GetFilesInProject(
    $projectId: ID!,
    $directoryId: ID,
    $orderBy: FileOrder,
    $filter: FileFilter
  ) {
    project(projectId: $projectId) {
      id
      files(directoryId: $directoryId, orderBy: $orderBy, filter: $filter) {
        id
        name
        ext
        type
        size
        updatedAt
        creator {
          id
          username
          email
          avatar
        }
      }
    }
  }
`;

function FilesInProjectQuery({
  projectId,
  directoryId,
  orderBy,
  filter,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { matching, searchScope } = filter;

  const { loading, error, data, ...otherQueryResults } = useQuery(
    FILES_IN_PROJECT_QUERY,
    {
      variables: {
        projectId,
        directoryId: (searchScope === 'CURRENT_FOLDER') ?
                     directoryId : null,
        orderBy,
        filter: { matching }
      },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : <Error error={error} />;
  }

  return (
    <React.Fragment>
      {children(data.project.files, otherQueryResults)}
      <DirectoryFilesAddedSubscription
        projectId={projectId}
        directoryId={directoryId}
        refetchDirectoryFiles={otherQueryResults.refetch}
      />
    </React.Fragment>
  );
}

FilesInProjectQuery.propTypes = {
  projectId: PropTypes.string.isRequired,

  // If `directoryId` is `root`, only files those are not children of any
  // directory will be listed. If `directoryId` is some ID value, only
  // files whose parent is this directory will be listed.
  directoryId: PropTypes.string.isRequired,

  orderBy: PropTypes.shape({
    // Sort by name
    name: PropTypes.oneOf(['ASC', 'DESC']),

    // Sort by date modified
    updatedAt: PropTypes.oneOf(['ASC', 'DESC']),

    // Sort by size
    size: PropTypes.oneOf(['ASC', 'DESC']),

    // Sort by creator name
    creator: PropTypes.oneOf(['ASC', 'DESC'])
  }),

  filter: PropTypes.shape({
    // Only list files whose name or notes match the given term.
    matching: PropTypes.string,

    // Whether to search in the current folder or the entire project.
    searchScope: PropTypes.oneOf(['CURRENT_FOLDER', 'CURRENT_PROJECT'])
  }),

  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

FilesInProjectQuery.defaultProps = {
  // Root directory of project
  directoryId: 'root',

  // Order list of files by their names ascending by default
  orderBy: {name: 'ASC'},

  // By default, list all files in the current folder.
  filter: {
    matching: '',
    searchScope: 'CURRENT_FOLDER'
  }
};

export default FilesInProjectQuery;
