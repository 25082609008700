import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../ui/button/IconButton';
import CloseIcon from '../../ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.4
  },
  titleWidth: {
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: theme.spacing(2),
    color: theme.palette.text.secondary
  },
}))

function StyledDialogTitle({ title, onClose, children, ...rest }) {
  const classes = useStyles();
  return (
    <DialogTitle
      disableTypography
      className={classes.root}
      {...rest}
    >
      <Typography
        component='h2'
        variant='h6'
        className={clsx(classes.title, {
          [classes.titleWidth]: Boolean(onClose)
        })}
      >
        {title}
      </Typography>

      {/* tabs */}
      {children}

      {onClose ? (
        <IconButton
          aria-label='close'
          color='secondary'
          size='small'
          icon={ <CloseIcon /> }
          className={classes.closeButton}
          onClick={onClose}
        />
      ) : null}
    </DialogTitle>
  );
}

StyledDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node
};

export default StyledDialogTitle;
