import React from 'react';
import PropTypes from 'prop-types';
import SpeedDial from '../../ui/file/fabs/SpeedDial';
import SpeedDialAction from '../../ui/file/fabs/SpeedDialAction';
import PencilIcon from '../../icons/20x20/Pencil';
import UploadIcon from '../../icons/20x20/ArrowUpDoc';
import FolderIcon from '../../icons/20x20/FolderPlus';
import TrashIcon from '../../icons/20x20/Trash';
import DialogContext from '../../ui/dialog/DialogContext';
import UploadFilesDialog from './UploadFilesDialog';
import MakeFolderDialog from './MakeFolderDialog';
import RenameFolderDialog from './RenameFolderDialog';
import DeleteFolderConfirmDialog from './DeleteFolderConfirmDialog';

const actions = [
  {
    icon: <UploadIcon color='white' />,
    name: 'Upload files',
    Dialog: UploadFilesDialog,
    dialogProps: {
      fullScreen: true,
      disableEscapeKeyDown: true
    },
    getDialogProps: (folder) => ({ destinationFolder: folder }),
    rootDisabled: false
  },
  {
    icon: <FolderIcon color='white' />,
    name: 'Make a new folder',
    Dialog: MakeFolderDialog,
    dialogProps: {
      maxWidth: 'sm',
      fullWidth: true
    },
    getDialogProps: (folder) => ({ parent: folder }),
    rootDisabled: false
  },
  {
    icon: <PencilIcon color='white' />,
    name: 'Rename this folder',
    Dialog: RenameFolderDialog,
    dialogProps: {
      maxWidth: 'sm',
      fullWidth: true
    },
    getDialogProps: (folder) => ({...folder}),
    rootDisabled: true
  },
  {
    icon: <TrashIcon color='white' />,
    name: 'Delete this folder',
    Dialog: DeleteFolderConfirmDialog,
    dialogProps: {
      maxWidth: 'sm',
      fullWidth: true
    },
    getDialogProps: (folder) => ({...folder}),
    rootDisabled: true
  }
];

function DirectorySpeedDial({ parentUrl, projectId, directory }) {
  const isRootDirectory = directory.id === 'root';
  const { openDialog } = React.useContext(DialogContext);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SpeedDial
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      {actions.filter((action) => (
        isRootDirectory ? !action.rootDisabled : true
      )).map(({ name, icon, Dialog,  dialogProps, getDialogProps }) => (
        <SpeedDialAction
          key={name}
          icon={icon}
          tooltipTitle={name}
          onClick={(event) => {
            handleClose();
            openDialog({
              dialogContent: (
                <Dialog
                  parentUrl={parentUrl}
                  projectId={projectId}
                  {...getDialogProps(directory)}
                />
              ),
              dialogProps
            });
          }}
        />
      ))}
    </SpeedDial>
  );
}

DirectorySpeedDial.propTypes = {
  parentUrl: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  directory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default DirectorySpeedDial;
