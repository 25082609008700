import React from 'react';
import PropTypes from 'prop-types';
import DialogLoading from '../../ui/dialog/DialogLoading';
import TeamDetailsQuery from '../TeamDetailsQuery';
import DeleteTeamConfirm from './DeleteTeamConfirm';

function DeleteTeam({ teamId }) {
  return (
    <TeamDetailsQuery
      teamId={teamId}
      onLoading={() => <DialogLoading /> }
    >
      {(team) => <DeleteTeamConfirm team={team} />}
    </TeamDetailsQuery>
  );
}

DeleteTeam.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default DeleteTeam;
