import React from 'react';
import JoinLeaveButton from './JoinLeaveButton';
import BellIcon from '../../../icons/18x18/BellFill';

export default function UnfollowTodoButton(props) {
  const tooltip = 'Stop getting notifications about activity on this task.';
  return (
    <JoinLeaveButton
      label='Leave task'
      icon={<BellIcon />}
      tooltip={tooltip}
      {...props}
    />
  );
}
