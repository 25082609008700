import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../button/IconButton';
import PersonAddIcon from '../../icons/16x16/PersonPlusFill';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: 4
  }
}));

export default function CardAvatarGroupAction(props) {
  const classes = useStyles();

  return (
    <IconButton
      color='secondary'
      className={classes.root}
      icon={ <PersonAddIcon /> }
      {...props}
    />
  );
}
