import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1)
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  border: {
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'
  }
}));

function StyledSectionHeaderExpandRow({ colSpan, border, children }) {
  const classes = useStyles();

  return (
    <tr>
      <Cell
        colSpan={colSpan}
        className={clsx(classes.root, {[classes.border]: border})}
      >
        <div className={classes.content}>{children}</div>
      </Cell>
    </tr>
  );
}

StyledSectionHeaderExpandRow.propTypes = {
  colSpan: PropTypes.number,
  border: PropTypes.bool,
  children: PropTypes.node
};

StyledSectionHeaderExpandRow.defaultProps = {
  colSpan: 2,
  border: false
};

export default React.memo(StyledSectionHeaderExpandRow);
