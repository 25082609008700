import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  followersList: {
    flex: '1 1 auto',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function FollowersList(props) {
  const classes = useStyles();
  return <div className={classes.followersList} {...props} />;
}
