import React from 'react';
import PropTypes from 'prop-types';
import List from '../ui/message/List';
import ListItemLink from '../ui/message/ListItemLink';
import ListEmpty from '../ui/message/ListEmpty';
import { formatCreatedTime } from './helpers';

function MessageList({ rootUrl, messages }) {
  if (!messages.length) {
    return <ListEmpty />;
  }

  return (
    <List>
      {messages.map(({ id, author, subject, plainText, insertedAt, commentCount }) => (
        <ListItemLink
          key={id}
          to={`${rootUrl}/${id}`}
          author={author}
          subject={subject}
          details={plainText}
          commentCount={commentCount}
          timestamp={formatCreatedTime(insertedAt)}
        />
      ))}
    </List>
  );
}

MessageList.propTypes = {
  rootUrl: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired
};

export default MessageList;
