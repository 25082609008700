import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from './TextField';
import EditorToolbar from './EditorToolbar';
import AttachFileButton from './AttachFileButton';
import { StatusCode } from '../file/list/UploadStatus';
import AttachmentList from './AttachmentList';
import UploadListItem from './UploadListItem';

const useStyles = makeStyles((theme) => ({
  composerEditor: {
    flex: '1 1 auto'
  },
  composerInput: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#F0F2F5',
    borderRadius: theme.spacing(2.5)
  }
}));

const isEnter = (keyCode) => keyCode === 13;
const isESC = (keyCode) => keyCode === 27;

function prependAndRemoveDuplicateUploadItems(state, newItems) {
  const trulyNewItems = newItems.filter((newItem) => {
    const found = state.find((item) => (item.id === newItem.id));
    return found ? false : true;
  });
  return [...trulyNewItems, ...state];
}

function ComposerEditor({
  placeholder,
  onSubmit,
  text,
  files,
  onDiscard,
  projectId,
  ...rest
}) {
  const classes = useStyles();
  const inputRef = React.useRef(null);
  const isThisStillAliveRef = React.useRef(true);

  // Keep track of existing files
  const [existingFiles, setExistingFiles] = React.useState(files);

  // Keep track of upload items
  const [uploadItems, setUploadItems] = React.useState([]);

  // In order to position toolbar correctly
  const [bottom, setBottom] = React.useState(false);

  const handleSubmit = (event) => {
    if (haveOngoingUploads()) {
      console.log('Please wait for ongoing uploads to finish...');
      return;
    }

    const succeededUploads = uploadItems.filter((item) => (
      item.statusCode === StatusCode.SUCCEEDED
    )).map(({ file, notes, path }) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      notes: notes || null,
      key: path,
      projectId: projectId
    }));

    const previousUploads = existingFiles.map((f) => ({
      name: f.name,
      type: f.type,
      size: f.size,
      notes: f.notes || null,
      key: f.key,
      projectId: projectId
    }));

    const text = inputRef.current.value;
    const files = [...succeededUploads, ...previousUploads];

    if (onSubmit) {
      onSubmit({ text, files });
    }

    // Refresh the input
    inputRef.current.value = '';
    inputRef.current.style.height = 'auto';

    // Clear uploads
    setUploadItems([]);
  };

  const handleKeyDown = (event) => {
    const keyCode = event.which || event.keyCode;
    if (isEnter(keyCode)) {
      event.preventDefault();
      handleSubmit(event);
    } else if (isESC(keyCode) && onDiscard) {
      event.preventDefault();
      event.stopPropagation();
      onDiscard(event);
    }
  };

  //
  // Place toolbar.
  //

  const handleFocus = (event) => {
    setBottom(true);
  };

  const handleBlur = (event) => {
    if (!inputRef.current.value) {
      setBottom(false);
    }
  };

  //
  // Existing files.
  //

  const handleDeleteExistingFile = (fileId) => {
    setExistingFiles((prev) => prev.filter(({ id }) => id !== fileId ));
  };

  //
  // Upload.
  //

  const handleAttachFiles = React.useCallback((selectedFiles) => {
    const newItems = selectedFiles.map((file) => ({
      id: file.name,
      file,
      statusCode: StatusCode.UPLOADING
    }));

    setUploadItems((prev) => (
      prependAndRemoveDuplicateUploadItems(prev, newItems)
    ));
  }, []);

  const handleUploadStatusChange = React.useCallback((uploadId, data) => {
    if (isThisStillAliveRef.current) {
      const newStatusCode = data.statusCode;
      setUploadItems((prev) => prev.map((item) => {
        if (item.id !== uploadId) { return item; }
        let newItem = {...item, statusCode: newStatusCode};
        if (newStatusCode === StatusCode.SUCCEEDED) {
          newItem = {...newItem, path: data.path};
        }
        return newItem;
      }));
    }
  }, []);

  const handleUploadDelete = React.useCallback((uploadId) => {
    setUploadItems((prev) => prev.filter((item) => item.id !== uploadId));
  }, []);

  const haveOngoingUploads = () => uploadItems.some((item) => (
    item.statusCode === StatusCode.UPLOADING
  ));

  // Helper effect to know when this component unmounted.
  React.useEffect(() => {
    isThisStillAliveRef.current = true;
    return () => isThisStillAliveRef.current = false;
  }, []);


  return (
    <form
      className={classes.composerEditor}
      onSubmit={handleSubmit}
    >
      <div className={classes.composerInput}>
        <TextField
          defaultValue={text}
          inputRef={inputRef}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        <EditorToolbar bottom={bottom}>
          <AttachFileButton onAttach={handleAttachFiles} />
        </EditorToolbar>
      </div>

      <AttachmentList
        attachments={existingFiles}
        onDelete={handleDeleteExistingFile}
      />

      <div>
        {uploadItems.map((item) => (
          <UploadListItem
            key={item.id}
            item={item}
            onUploadStatusChange={handleUploadStatusChange}
            onUploadDelete={handleUploadDelete}
            projectId={projectId}
          />
        ))}
      </div>
    </form>
  );
}

ComposerEditor.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  files: PropTypes.array,
  onDiscard: PropTypes.func,

  // Used for presigned url query and other stuffs
  // TODO: Please give more details explanation about this weird prop
  projectId: PropTypes.string.isRequired
};

ComposerEditor.defaultProps = {
  placeholder: 'Ask a question or post an update...',
  text: '',
  files: []
};

export default ComposerEditor;
