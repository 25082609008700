import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const MEMBERSHIP_DEACTIVATED_SUBSCRIPTION = gql`
  subscription OnMembershipDeactivated($membershipId: ID!) {
    membershipDeactivated(membershipId: $membershipId) {
      membership {
        id
        active
      }
      deactivatedBy {
        id
      }
    }
  }
`;

export default function useMembershipDeactivatedSubscription(options) {
  const { membershipId, ...rest } = options;
  return useSubscription(MEMBERSHIP_DEACTIVATED_SUBSCRIPTION, {
    variables: { membershipId },
    ...rest
  });
}
