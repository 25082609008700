import React from 'react';
import Section from './Section';
import SectionSubscribers from './SectionSubscribers';

export default function SectionWithSubscribers(props) {
  return (
    <React.Fragment>
      <Section {...props} />

      <SectionSubscribers
        todoListId={props.id}
        todoCriteria={props.todoCriteria}
      />
    </React.Fragment>
  );
}
