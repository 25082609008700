import {
  addMonths,
  subMonths,
  eachMonthOfInterval,
  eachDayOfInterval,
  startOfDay,
  endOfDay
} from 'date-fns';
import {
  PROJECT_SCHEDULES_QUERY,
  convertRawSchedule,
  buildSchedulesCriteria
} from './ProjectSchedulesQuery';
import getMonthGridForDate from './getMonthGridForDate';
import startOfWeek from './weekly-schedule/utils/startOfWeek';
import endOfWeek from './weekly-schedule/utils/endOfWeek';
import eachWeekOfInterval from './weekly-schedule/utils/eachWeekOfInterval';

// Helpers.

function _sameTypeAndId(foo, bar) {
  return (foo.__typename === bar.__typename) &&
         (foo.id === bar.id);
}

function _buildMonthlySchedulesQuery(projectId, date) {
  const { lowerBound, upperBound } = getMonthGridForDate(date);

  const queryCriteria = buildSchedulesCriteria({
    start: lowerBound,
    end: upperBound
  });

  return {
    query: PROJECT_SCHEDULES_QUERY,
    variables: { projectId, filter: queryCriteria }
  };
}

function _buildDailySchedulesQuery(projectId, date) {
  const queryCriteria = buildSchedulesCriteria({
    start: startOfDay(date),
    end: endOfDay(date)
  });

  return {
    query: PROJECT_SCHEDULES_QUERY,
    variables: { projectId, filter: queryCriteria }
  };
}

function _buildWeeklySchedulesQuery(projectId, date) {
  const queryCriteria = buildSchedulesCriteria({
    start: startOfWeek(date),
    end: endOfWeek(date)
  });

  return {
    query: PROJECT_SCHEDULES_QUERY,
    variables: { projectId, filter: queryCriteria }
  };
}

function _writeEntryToCache(cache, { query, entry }) {
  const previousData = cache.readQuery(query);

  if (!previousData) {
    return;
  }

  const previousSchedules = previousData.project.schedules;
  const found = previousSchedules.some((e) => _sameTypeAndId(e, entry));

  if (found) {
    return;
  }

  cache.writeQuery({
    ...query,
    data: {
      project: {
        ...previousData.project,
        schedules: [...previousSchedules, entry]
      }
    }
  });
}

function _removeEntryFromCache(cache, { query, entry }) {
  const previousData = cache.readQuery(query);

  if (!previousData) {
    return;
  }

  const newSchedules = previousData.project.schedules.filter((e) => (
    !_sameTypeAndId(e, entry)
  ));

  cache.writeQuery({
    ...query,
    data: {
      project: {
        ...previousData.project,
        schedules: newSchedules
      }
    }
  });
}

// Exports

export function writeEntryToCache(cache, { projectId, entry }) {
  const result = convertRawSchedule(entry);

  if (!result) {
    return null;
  }

  const { startsAt, endsAt } = result;

  // Add this entry to all posible monthly queries

  const start = subMonths(startsAt, 1);
  const end = addMonths(endsAt, 1);

  eachMonthOfInterval({ start, end })
    .map((date) => _buildMonthlySchedulesQuery(projectId, date))
    .forEach((query) => _writeEntryToCache(cache, { query, entry }));

  // Add this entry to all posible daily queries

  eachDayOfInterval({ start: startsAt, end: endsAt })
    .map((date) => _buildDailySchedulesQuery(projectId, date))
    .forEach((query) => _writeEntryToCache(cache, { query, entry }));

  // Add this entry to all possible weekly queries
  eachWeekOfInterval({ start: startsAt, end: endsAt })
    .map((date) => _buildWeeklySchedulesQuery(projectId, date))
    .forEach((query) => _writeEntryToCache(cache, { query, entry }));
}

export function removeEntryFromCache(cache, { projectId, entry }) {
  const result = convertRawSchedule(entry);

  if (!result) {
    return null;
  }

  const { startsAt, endsAt } = result;

  const start = subMonths(startsAt, 1);
  const end = addMonths(endsAt, 1);

  // Remove this entry from all posible monthly queries
  eachMonthOfInterval({ start, end })
    .map((date) => _buildMonthlySchedulesQuery(projectId, date))
    .forEach((query) => _removeEntryFromCache(cache, { query, entry }));

  // Remove this entry from all posible daily queries
  eachDayOfInterval({ start: startsAt, end: endsAt })
    .map((date) => _buildDailySchedulesQuery(projectId, date))
    .forEach((query) => _removeEntryFromCache(cache, { query, entry }));

  // Remove this entry from all possible weekly queries
  eachWeekOfInterval({ start: startsAt, end: endsAt })
    .map((date) => _buildWeeklySchedulesQuery(projectId, date))
    .forEach((query) => _removeEntryFromCache(cache, { query, entry }));
}
