import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const RENAME_FOLDER_MUTATION = gql`
  mutation RenameFolder($id: ID!, $name: String!) {
    renameFolder(id: $id, name: $name) {
      id
      name
    }
  }
`;

export default function useRenameFolderMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(RENAME_FOLDER_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
