import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cell from './Cell';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0
  }
}));

export default function StyledUploadStatusCell(props)  {
  const classes = useStyles();
  return <Cell className={classes.cell} {...props} />;
}
