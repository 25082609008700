import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useProjectURL from '../project/hooks/useProjectURL';
import GenericActivityItem from './ui/GenericActivityItem';

function AddTodoItem({ activity }) {
  const { type, actor, details, todo, insertedAt } = activity;
  const todoName = details[1];

  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const todoUrl = `${projectUrl}/tasks/${todo.id}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='added a new task:'
      linkUrl={todoUrl}
      linkText={todoName}
      insertedAt={insertedAt}
    />
  );
}

AddTodoItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['ADD_TODO']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    todo: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default AddTodoItem;
