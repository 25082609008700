import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '../chip/Chip';
import TextField from './TextField';
import { isEmailValid } from '../../../utils/helpers';

const KEY_CODE = Object.freeze({
  SPACE: 32,
  COMMA: 188
});

function EmailsInput({ value, onChange, onCommaOrSpacePressed, ...rest }) {
  const handleKeyDown = (event) => {
    switch(event.keyCode) {
      case KEY_CODE.SPACE:
      case KEY_CODE.COMMA:
        event.stopPropagation();
        event.preventDefault();
        const newInputValue = event.target.value;
        onCommaOrSpacePressed(event, newInputValue);
        break;

      default:
        break;
    }
  };

  const getInputErrorProps = () => {
    const someEmailsInvalid = value.some((email) => !isEmailValid(email));
    if (someEmailsInvalid) {
      return {
        error: true,
        helperText: 'Please remove invalid email addresses.'
      };
    }
    return {};
  };

  const renderInput = (params) => {
    params.inputProps.onKeyDown = handleKeyDown;
    return (
      <TextField
        {...params}
        {...getInputErrorProps()}
        autoFocus
        placeholder='name@company.com, name@company.com'
        label='Email addresses'
      />
    );
  };

  const renderTags = (value, getTagProps) => (
    value.map((email, index) => (
      <Chip
        color={isEmailValid(email) ? 'primary' : 'secondary'}
        label={email}
        {...getTagProps({ index })}
      />
    ))
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      disableClearable
      renderInput={renderInput}
      renderTags={renderTags}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
}

EmailsInput.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onCommaOrSpacePressed: PropTypes.func.isRequired
};

export default EmailsInput;
