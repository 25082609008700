import React from 'react';
import PropTypes from 'prop-types';
import TodoSheet from './sheet/TodoSheet';
import TodoDetails from './details/TodoSheetTodoDetails';

function List({ projectId, rootUrl }) {
  return (
    <React.Fragment>
      <TodoSheet projectId={projectId} rootUrl={rootUrl} />
      <TodoDetails fromUrl={rootUrl} />
    </React.Fragment>
  );
}

List.propTypes = {
  projectId: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired
};

export default List;
