import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/button/Button';
import useReactiveMembershipMutation
from './hooks/useReactivateMembershipMutation';
import useCustomSnackbar from '../snackbar/useCustomSnackbar';

function ActivateMembershipButton({ membership, ...rest }) {
  const { id, role, user } = membership;
  const { enqueueSuccessSnackbar } = useCustomSnackbar();

  const handleCompleted = () => {
    let message;

    switch(role) {
      case 'OWNER':
        message = `${user.username} is now an Owner`;
        break;

      case 'ADMIN':
        message = `${user.username} is now an Admin`;
        break;

      case 'MEMBER':
        message = `${user.username} is now a Member`;
        break;

      case 'GUEST':
        message = `${user.username} is now a Guest`;
        break;

      default:
        break;
    }

    enqueueSuccessSnackbar(message);
  };

  const [activateMembership, { loading }] = useReactiveMembershipMutation({
    membershipId: id,
    onCompleted(data) {
      handleCompleted();
    }
  });

  return (
    <Button
      size='small'
      color='primaryDeemphasized'
      loading={loading}
      onClick={() => {
        activateMembership();
      }}
      {...rest}
    >
      Activate
    </Button>
  );
}

ActivateMembershipButton.propTypes = {
  membership: PropTypes.object.isRequired
};

export default ActivateMembershipButton;
