import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconSprites from './sprites.png';

const ICON_SIZE = 28;
const NUM_ICONS = 11;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    display: 'inline-block',
    cursor: 'inherit',
    userSelect: 'none',
    pointerEvents: 'none',
    width: ICON_SIZE,
    height: ICON_SIZE,
    backgroundImage: `url(${IconSprites})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${ICON_SIZE}px ${ICON_SIZE * NUM_ICONS}px`,
  }
}));

function Base({ className, ...rest }) {
  const classes = useStyles();
  return <i className={clsx(classes.root, className)} {...rest} />;
}

export default Base;
