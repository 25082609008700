import React from 'react';
import PropTypes from 'prop-types';
import ActivityTextDetails from './ActivityTextDetails';

function getMessage(names) {
  if (names.length === 1) {
    return `${names[0]} was granted access.`;
  } else if (names.length > 1) {
    return `${names.join(', ')} were granted access.`;
  } else {
    return null;
  }
}

function GrantAccessDetails({ names }) {
  const msg = getMessage(names);
  return <ActivityTextDetails text={msg} />;
}

GrantAccessDetails.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default GrantAccessDetails;
