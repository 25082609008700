import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center'
  }
}));

function CreateWorkspaceFormHeader({ username }) {
  const classes = useStyles();
  const displayName = username.trim().split(/\s+/)[0];
  return (
    <div className={classes.root}>
      <Typography variant='h4' component='h1' noWrap>
        Hi, {displayName}
      </Typography>

      <Typography variant='subtitle1' color='textSecondary'>
        Let's get started by creating a workspace.
      </Typography>
    </div>
  );
}

CreateWorkspaceFormHeader.propTypes = {
  username: PropTypes.string.isRequired
};

export default CreateWorkspaceFormHeader;
