import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridCell: {
    flex: '1 1 0%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function GridCell({ header, ...rest }) {
  const classes = useStyles();
  return (
    <span
      role={header ? 'columnheader' : 'gridcell'}
      className={classes.gridCell}
      {...rest}
    />
  );
}

GridCell.propTypes = {
  header: PropTypes.bool
};

GridCell.defaultProps = {
  header: false
};

export default GridCell;
