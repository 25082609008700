import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatarGroupContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 8
    }
  }
}));

export default function ProjectHeaderAvatarGroupContainer(props) {
  const classes = useStyles();
  return <div className={classes.avatarGroupContainer} {...props} />;
}
