import React from 'react';
import CurrentUserProfileQuery from '../CurrentUserProfileQuery';
import EditUserProfileForm from './EditUserProfileForm';
import Loading from '../../ui/dialog/DialogLoading';

function EditUserProfile() {
  return (
    <CurrentUserProfileQuery onLoading={() => <Loading /> }>
      {(user) => (
        <EditUserProfileForm user={user} />
      )}
    </CurrentUserProfileQuery>
  );
}

export default EditUserProfile;
