import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/card/CardAvatarGroupAction';
import DialogContext from '../ui/dialog/DialogContext';
import AddMembers from './dialogs/AddMembers';

function ProjectHeaderAddPeopleButton({ projectId }) {
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    openDialog({
      dialogContent: <AddMembers projectId={projectId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <Button onClick={handleClick} />
  );
}

ProjectHeaderAddPeopleButton.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectHeaderAddPeopleButton;
