import { format } from 'date-fns';
import { generateNewTodoId } from '../helpers';

// Helpers

function _formatDueDate(date) {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (err) {
    return null/* no due date */;
  }
}

// Exports

export function initFormData(options) {
  const { defaultDueDate } = options || {};

  return {
    name: '',
    description: '',
    priority: 'NONE',
    dueDate: defaultDueDate,
    assignee: null,
    list: null
  };
}

export function convertFormDataToTodoData(formData) {
  return {
    todoListId: formData.list ? formData.list.id : null,
    name: formData.name,
    description: formData.description,
    dueDate: _formatDueDate(formData.dueDate),
    priority: formData.priority,
    assigneeId: formData.assignee ? formData.assignee.id : null
  };
}

export function convertFormDataToOptimisticResponse(formData) {
  return {
    __typename: 'TodoItem',
    id: generateNewTodoId(),
    name: formData.name,
    description: formData.description,
    dueDate: _formatDueDate(formData.dueDate),
    color: null,
    completed: false
  };
}
