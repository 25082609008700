import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '../../ui/dialog/StyledDialog';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import Button from '../../ui/button/Button';

function WorkspaceAccessDeniedDialog({ open, onClose }) {
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
    >
      <DialogTitle style={{textAlign: 'center'}}>
        Access denied
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You no longer have access to this workspace. This happens either
          beacause the owner of this workspace has deleted it or has
          deactivated your membership.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          size='small'
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

WorkspaceAccessDeniedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

WorkspaceAccessDeniedDialog.defaultProps = {
  open: false
};

export default WorkspaceAccessDeniedDialog;
