import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import useMemberAddedSubscription from './hooks/useMemberAddedSubscription';
import { TEAM_MEMBERS_FRAGMENT } from './fragments';

function TeamCardMemberAddedSubscriber({ teamId }) {
  useMemberAddedSubscription({
    teamId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) return;

      const fragmentObj = {
        id: `Team:${teamId}`,
        fragment: gql`fragment TeamMembersWithCount on Team {
          ...TeamMembers
          memberCount},
          ${TEAM_MEMBERS_FRAGMENT}
        `,
        fragmentName: 'TeamMembersWithCount'
      };

      const oldData = client.readFragment(fragmentObj);
      const oldMembers = oldData.members;
      let newMembers = subscriptionData.data.teamMemberAdded;
      newMembers = newMembers.filter(({ id }) => (
        !oldMembers.some((m) => m.id === id)
      ));
      if (newMembers.length === 0) return;
      const updatedMembers = [...newMembers, ...oldMembers];

      client.writeFragment({
        ...fragmentObj,
        data: {
          ...oldData,
          memberCount: updatedMembers.length,
          members: updatedMembers
        }
      });
    }
  });

  return null;
}

TeamCardMemberAddedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default React.memo(TeamCardMemberAddedSubscriber);
