import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../snackbar/useCustomSnackbar';

const MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION = gql`
  mutation MarkAllNotificationsAsRead($workspaceId: ID!) {
    markAllRead(workspaceId: $workspaceId)
  }
`;

export default function useMarkAllReadMutation(options) {
  const { workspaceId } = useParams();
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION, {
    variables: { workspaceId },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
