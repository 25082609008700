const ROLE_LIST = ['OWNER', 'ADMIN', 'MEMBER', 'GUEST'];

const ROLE_RANK = Object.freeze({
  'OWNER': 3,
  'ADMIN': 2,
  'MEMBER': 1,
  'GUEST': 0
});

function roleCmp(role1, role2) {
  return ROLE_RANK[role1] - ROLE_RANK[role2];
};

function isOwner(role) {
  return role === 'OWNER';
}

function isAdmin(role) {
  return role === 'ADMIN';
}

function isMember(role) {
  return role === 'MEMBER';
}

function isGuest(role) {
  return role === 'GUEST';
}

export {
  roleCmp,
  isOwner,
  isAdmin,
  isMember,
  isGuest,
  ROLE_LIST
};
