import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ResizeHandle, { HANDLE_WIDTH } from './ResizeHandle';
import ColumnResizeHandle from './ColumnResizeHandle';
import Cell from './Cell';

const useStyles = makeStyles((theme) => ({
  cellInterior: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  content: {
    height: '100%',
    width: `calc(100% - ${Math.floor(HANDLE_WIDTH/2)}px)`,
    paddingLeft: 0
  },
  contentFullWidth: {
    width: '100%'
  },
  contentPaddingLeft: {
    paddingLeft: Math.ceil(HANDLE_WIDTH/2)
  },
  contentInterior: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    pointerEvents: 'auto',
    position: 'relative'
  },
  isDragging: {
    zIndex: theme.zIndex.tooltip
  }
}));

function HeaderCell({
  col,
  resizable,
  onResize,
  borderRight,
  contentFullWidth,
  contentPaddingLeft,
  ...rest
}) {
  const classes = useStyles();
  const [isDragging, setIsDragging] = React.useState(false);

  const getColWidthStyle = () => {
    if (!col) {
      return {};
    }

    return { width: col.width };
  };

  const getResizeBoulds = () => {
    if (!col) {
      return null;
    }

    const { width, minWidth, maxWidth } = col;
    let bounds = {};

    if (minWidth) {
      bounds = {...bounds, left: minWidth - width};
    }

    if (maxWidth) {
      bounds = {...bounds, right: maxWidth - width};
    }

    return bounds;
  };

  const renderContent = () => {
    if (!col) {
      return null;
    }

    if (col && !col.renderHeaderCell) {
      return col.name;
    }

    return col.renderHeaderCell(col);
  };

  return (
    <Cell
      isHeaderCell
      sticky
      disablePointerEvents
      className={clsx(isDragging && classes.isDragging)}
      style={getColWidthStyle()}
      {...rest}
    >
      <div className={classes.cellInterior}>
        <div
          className={clsx(
            classes.content,
            contentFullWidth && classes.contentFullWidth,
            contentPaddingLeft && classes.contentPaddingLeft
          )}
        >
          <div className={classes.contentInterior}>
            {renderContent()}
          </div>
        </div>

        {(borderRight || resizable) && <ResizeHandle />}

        {resizable && (
          <ColumnResizeHandle
            bounds={getResizeBoulds()}
            onStart={() => {
              setIsDragging(true);
            }}
            onStop={(deltaX) => {
              setIsDragging(false);
              onResize && onResize(col, deltaX);
            }}
          />
        )}
      </div>
    </Cell>
  );
}

HeaderCell.propTypes = {
  // The column props to render
  col: PropTypes.shape({
    name: PropTypes.string.isRequired,

    width: PropTypes.number.isRequired,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,

    renderHeaderCell: PropTypes.func
  }),

  // Should this column be resizable?
  resizable: PropTypes.bool,

  // What to do on column resize, expected a two-arguments function
  // where first arg is the column props, second is the deltaX
  // (newWidth = width + deltaX)
  onResize: PropTypes.func,

  // These shit are here to help position resize handle correctly.
  borderRight: PropTypes.bool,
  contentFullWidth: PropTypes.bool,
  contentPaddingLeft: PropTypes.bool
};

HeaderCell.defaultProps = {
  resizable: true,
  borderRight: true,
  contentFullWidth: false,
  contentPaddingLeft: true
};

export default HeaderCell;
