import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/button/Button';
import DialogForm from '../../ui/dialog/DialogForm';
import {AVATAR_COLORS, AVATAR_DEFAULT_COLOR} from '../../ui/avatar/colors';
import DialogContent from './DialogContent';
import DialogActionsContainer from './DialogActionsContainer';
import ColorPicker from '../ProjectColorPicker';
import useUpdateProjectColorMutation
from '../hooks/useUpdateProjectColorMutation';

// TODO: Move this shit out of here.
function fromColorStringToColor(colorString)  {
  return (AVATAR_COLORS.includes(colorString) && colorString) ||
         AVATAR_DEFAULT_COLOR;
}

function ChangeAvatar({ id, name, color, onClose, actionsContainer }) {
  const [avatarColor, setAvatarColor] =
    React.useState(fromColorStringToColor(color));

  const handleChange = (event) => {
    setAvatarColor(event.target.value);
  };

  // Sync whenever color changed.
  React.useEffect(() => {
    setAvatarColor(fromColorStringToColor(color));
  }, [color]);

  // Mutation.

  const [
    updateProjectColor,
    { loading }
  ] = useUpdateProjectColorMutation({
    variables: {
      projectId: id,
      color: avatarColor
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateProject: {
        __typename: 'Project',
        id: id,
        color: avatarColor
      }
    },
    onCompleted(data) {
      onClose();
    }
  });

  return (
    <DialogContent>
      <DialogForm>
        <ColorPicker
          inputName='color'
          projectName={name}
          value={avatarColor}
          onChange={handleChange}
        />
      </DialogForm>

      <DialogActionsContainer container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          disabled={loading}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          disabled={(avatarColor === color)}
          onClick={() => {
            updateProjectColor();
          }}
        >
          Save changes
        </Button>
      </DialogActionsContainer>
    </DialogContent>
  )
}

ChangeAvatar.propTypes = {
  // The project's ID, name and color.
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,

  // close dialog
  onClose: PropTypes.func,

  // DOM node to mount dialog actions
  actionsContainer: PropTypes.object
};

export default ChangeAvatar;
