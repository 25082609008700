import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import FileDetailsContainer from './FileDetailsContainer';

const useStyles = makeStyles((theme) => ({
  section: {
    borderRadius: 8,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  sectionFullscreen: {
    borderRadius: 0,
    boxShadow: 'none',
    height: '100vh'
  }
}));


function FileDetails({ fullscreen, onExitFullscreen, children }) {
  const classes = useStyles();

  return (
    <FileDetailsContainer
      fullscreen={fullscreen}
      onExitFullscreen={onExitFullscreen}
    >
      <Paper
        className={clsx(classes.section, {
          [classes.sectionFullscreen]: fullscreen
        })}
      >
        {children}
      </Paper>
    </FileDetailsContainer>
  );
}

FileDetails.propTypes = {
  fullscreen: PropTypes.bool,
  onExitFullscreen: PropTypes.func,
  children: PropTypes.node
};

FileDetails.defaultProps = {
  fullscreen: false
};

export default FileDetails;
