import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subject: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '1.25em'
  }
}));

function ListItemSubject({ subject, ...rest }) {
  const classes = useStyles();
  return (
    <Typography
      variant='subtitle1'
      className={classes.subject}
      {...rest}
    >
      {subject}
    </Typography>
  );
}

ListItemSubject.propTypes = {
  // The subject of the message
  subject: PropTypes.string.isRequired
};

export default ListItemSubject;
