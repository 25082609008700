import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RouterLink from '../ui/link/RouterLink';
import Skeleton from '@material-ui/lab/Skeleton';
import useTeamURL from '../team/hooks/useTeamURL';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 500,
  }
}));

function ProjectHeaderProjectTeam({ teamId, teamName }) {
  const classes = useStyles();
  const teamUrl = useTeamURL(teamId);

  return (
    <Typography
      noWrap
      variant='body2'
      color='textSecondary'
      className={classes.subtitle}
    >
      in&nbsp;<RouterLink to={teamUrl}>{teamName}</RouterLink>
    </Typography>
  );
}

function ProjectHeaderProjectTeamSkeleton() {
  return (
    <Typography noWrap variant='body2' color='textSecondary'>
      <Skeleton width='60%' height={12} />
    </Typography>
  );
}

ProjectHeaderProjectTeam.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired
};

export default ProjectHeaderProjectTeam;
export { ProjectHeaderProjectTeamSkeleton };
