import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';

const UPDATE_TODO_DUE_DATE_MUTATION = gql`
  mutation UpdateTodoDueDate($todoId: ID!, $dueDate: Date!) {
    updateTodoItem(todoItemId: $todoId, changes: { dueDate: $dueDate }) {
      id
      dueDate
    }
  }
`;

export default function useUpdateTodoDueDate() {
  const [mutate, mutationResults] = useMutation(
    UPDATE_TODO_DUE_DATE_MUTATION,
    {
      loadingMessage: 'Saving...',
      successMessage: 'Task saved'
    }
  );

  const mutateFn = useCallback((todo, dueDate) => {
    mutate({
      variables: {
        todoId: todo.id,
        dueDate
      },

      optimisticResponse: {
        updateTodoItem: {
          ...todo,
          dueDate
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
