import React from 'react';
import PropTypes from 'prop-types';
import Container from './ui/Container';
import Skeleton from './ui/ActivityItemSkeleton';
import StyledActivityFeed from './ui/ActivityFeed';
import ActivityItem from './ActivityItem';

function ActivityFeed({ loading, error, entries, onLoadMore }) {
  return (
    <Container onScrollToBottom={onLoadMore}>
      <StyledActivityFeed>
        {entries.map((entry) => (
          <ActivityItem
            key={entry.id}
            activity={entry}
          />
        ))}
        {loading && <Skeleton />}
      </StyledActivityFeed>
    </Container>
  );
}

ActivityFeed.propTypes = {
  loading: PropTypes.bool.isRequired,
  entries: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired
};

export default ActivityFeed;
