import PropTypes from 'prop-types';
import UploadFileNameCell from '../../ui/file/table/UploadFileNameCell';
import FileSizeCell from '../../ui/file/table/FileSizeCell';
import FileKindCell from '../../ui/file/table/FileKindCell';
import UploadFileNotesCell from '../../ui/file/table/UploadFileNotesCell';
import { getDescriptionForMimeType } from '../../ui/file/mime/mime';
import UploadStatusCell from './UploadStatusCell';

export const COLUMNS = [
  {
    id: 'status',
    disabledSort: true,
    numeric: false,
    label: 'Status',
    width: 96,
    Cell: UploadStatusCell,
    getCellProps: ({ projectId, file, statusCode, onStatusChange}) => ({
      projectId,
      file,
      statusCode,
      onStatusChange
    })
  },
  {
    id: 'name',
    disabledSort: false,
    numeric: false,
    label: 'Name',
    width: 384,
    Cell: UploadFileNameCell,
    getCellProps: ({ file }) => ({ name: file.name })
  },
  {
    id: 'kind',
    disabledSort: false,
    numeric: false,
    label: 'Kind',
    width: 192,
    Cell: FileKindCell,
    getCellProps: ({ file }) => ({ type: file.type })
  },
  {
    id: 'size',
    disabledSort: false,
    numeric: true,
    label: 'Size',
    width: 128,
    Cell: FileSizeCell,
    getCellProps: ({ file }) => ({ size: file.size })
  },
  {
    id: 'notes',
    disabledSort: true,
    numeric: false,
    label: 'Notes',
    width: 384,
    Cell: UploadFileNotesCell,
    getCellProps: ({ notes, onNotesChange }) => ({
      notes,
      onChange: onNotesChange
    })
  }
];

export const RowShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  file: PropTypes.instanceOf(File).isRequired,
  notes: PropTypes.string.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onToggleSelected: PropTypes.func.isRequired,
  statusCode: PropTypes.number.isRequired,
  onStatusChange: PropTypes.func.isRequired
});

function descendingComparator(x, y) {
  if (y < x) { return -1; }
  if (y > x) { return 1; }
  return 0;
}

// Descending compare two rows by file `kind`.
function descendingFileKindComparator({ file: a }, { file: b }) {
  const aKind = getDescriptionForMimeType(a.type);
  const bKind = getDescriptionForMimeType(b.type);
  return descendingComparator(aKind, bKind);
}

// Descending compare two rows by file `notes`.
function descendingFileNotesComparator({ notes: a}, { notes: b}) {
  return descendingComparator(a, b);
}

// Descending compare two rows by file attribute.
function descendingUploadComparator({ file: a }, { file: b }, attr) {
  return descendingComparator(a[attr], b[attr]);
}

// Each row in the table is an object of shape :
// { file: File, notes: String }
function descendingRowComparator(row1, row2, orderBy) {
  switch(orderBy) {
    case 'kind':
      return descendingFileKindComparator(row1, row2);
    case 'notes':
      return descendingFileNotesComparator(row1, row2);
    default:
      return descendingUploadComparator(row1, row2, orderBy);
  }
}

// Returns a function to compare two rows based on the given `order`
// (whether to compare ascending or descending).
export function getRowComparator(order, orderBy) {
  if (order === 'DESC') {
    return (a, b) => descendingRowComparator(a, b, orderBy);
  }

  return (a, b) => -descendingRowComparator(a, b, orderBy);
}

export function stableSortRows(rows, comparator) {
  const rowsWithIndices = rows.map((row, index) => [row, index]);

  rowsWithIndices.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return rowsWithIndices.map((el) => el[0]);
}
