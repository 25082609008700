import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));

function Actions({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

Actions.propTypes = {
  children: PropTypes.node
};

export default Actions;
