import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.background.paper,
    '& > :first-child': {
      borderLeft: `1px solid #EBEDF0`
    },
    '& > :last-child': {
      borderRight: `1px solid #EBEDF0`
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper
      },
      '& > *': {
        backgroundColor: fade(theme.palette.secondary.main, 0.08)
      }
    }
  }
}));

export default function Row(props) {
  const classes = useStyles();
  return <TableRow className={classes.row} {...props} />;
}
