import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const ProjectHeaderToolbarTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#282828',
    fontSize: '0.8125rem',
    fontWeight: theme.typography.fontWeightMedium
  },
  arrow: {
    '&:before': {
      backgroundColor: '#282828'
    }
  }
}))(Tooltip);

export default ProjectHeaderToolbarTooltip;
