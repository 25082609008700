import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    margin: '8px 0px'
  }
}))(Tooltip);

const showHoverIcon = {
  '& $icon': { display: 'none' },
  '& $hoverIcon': { display: 'inline-block' }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover $buttonRoot:not($disabled)': {
      ...showHoverIcon
    }
  },
  buttonRoot: {
    flex: '0 0 auto',
    borderRadius: '50%',
    height: 36,
    width: 36,
    '&:hover:not($disabled)': {
      ...showHoverIcon,
      backgroundColor: theme.palette.button.secondary.main
    },
    '&:active:not($disabled)': {
      backgroundColor: theme.palette.button.primaryDeemphasized.main
    }
  },
  buttonFocusVisible: {
    ...showHoverIcon,
    backgroundColor: theme.palette.button.secondary.main
  },
  icon: {},
  hoverIcon: { display: 'none' },
  disabled: {}
}));

function UploadStatusIconButton({ icon, hoverIcon, tooltip, ...rest }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StyledTooltip
        arrow
        placement='top'
        title={tooltip}
      >
        <ButtonBase
          classes={{
            root: classes.buttonRoot,
            focusVisible: classes.buttonFocusVisible,
            disabled: classes.disabled
          }}
          {...rest}
        >
          {React.cloneElement(icon, {
            className: hoverIcon && classes.icon
          })}
          {hoverIcon && React.cloneElement(hoverIcon, {
            className: classes.hoverIcon
          })}
        </ButtonBase>
      </StyledTooltip>
    </div>
  );
}

UploadStatusIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  hoverIcon: PropTypes.node,        // Icon to be displayed on hover
  tooltip: PropTypes.string
};

UploadStatusIconButton.defaultProps = {
  hoverIcon: null,
  tooltip: ''
};

export default UploadStatusIconButton;
