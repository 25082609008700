import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MembershipGridItem from './MembershipGridItem';
import { TEAM_MEMBER_REMOVED_SUBSCRIPTION }
from '../hooks/useMemberRemovedSubscription';
import { TEAM_MEMBER_ADDED_SUBSCRIPTION }
from '../hooks/useMemberAddedSubscription';
import NoMatchesFound from './NoMatchesFound';

function MembershipsGrid(props) {
  const { memberships, variables, subscribeToMemberAddedOrRemoved } = props;
  const { teamId, filter } = variables;
  const matching = filter.matching;
  const noMatchesFound = memberships.length === 0;

  // Subscribe to member removed.
  React.useEffect(() => {
    if (!noMatchesFound) {
      const unsubscribe =  subscribeToMemberAddedOrRemoved({
        document: TEAM_MEMBER_REMOVED_SUBSCRIPTION,
        variables: { teamId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;

          const { teamMembership: removedMember } =
            subscriptionData.data.teamMemberRemoved;

          const oldMembers = prev.team.members;
          const updatedMembers = oldMembers.filter(({ id }) => (
            id !== removedMember.id
          ));

          return {
            team: {
              ...prev.team,
              members: updatedMembers
            }
          };
        }
      });

      // TODO: without this cleanup, the `updateQuery` function will fire
      // multiple times with the exact same data.
      return () => unsubscribe();
    }
  }, [subscribeToMemberAddedOrRemoved, teamId, noMatchesFound]);

  // Subscribe to member added.
  React.useEffect(() => {
    if (!matching) {
      const unsubscribe = subscribeToMemberAddedOrRemoved({
        document: TEAM_MEMBER_ADDED_SUBSCRIPTION,
        variables: { teamId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          const oldMembers = prev.team.members;
          let newMembers = subscriptionData.data.teamMemberAdded;
          newMembers = newMembers.filter(({ id }) => (
            !oldMembers.some((m) => m.id === id)
          ));
          if (newMembers.length === 0) return prev;
          return {
            team: {
              ...prev.team,
              members: [...newMembers, ...oldMembers]
            }
          };
        }
      });

      // TODO: without this cleanup, the `updateQuery` function will fire
      // multiple times with the exact same data.
      return () => unsubscribe();
    }
  }, [subscribeToMemberAddedOrRemoved, teamId, matching]);

  return noMatchesFound ? (
    <NoMatchesFound criteria={filter} />
  ) : (
    <Grid container spacing={2}>
      {memberships.map((m) => (
        <MembershipGridItem key={m.id} membership={m} />
      ))}
    </Grid>
  );
}

MembershipsGrid.propTypes = {
  memberships: PropTypes.array.isRequired,
  variables: PropTypes.shape({
    teamId: PropTypes.string,
    filter: PropTypes.object
  }).isRequired,
  subscribeToMemberAddedOrRemoved: PropTypes.func.isRequired
};

export default MembershipsGrid;
