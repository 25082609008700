import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import NotesIcon from '../../../../icons/16x16/Notes';
import ContentField from './ContentField';

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    paddingTop: 4
  }
}));

function DescriptionField({ description }) {
  const classes = useStyles();
  return description ? (
    <ContentField icon={<NotesIcon />}>
      <div className={classes.descriptionContainer}>
        <Typography variant='body2'>
          {description}
        </Typography>
      </div>
    </ContentField>
  ) : null;
}

DescriptionField.propTypes = {
  description: PropTypes.string
};

export default DescriptionField;
