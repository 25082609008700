import React from 'react';
import PropTypes from 'prop-types';
import UserAutocomplete from './UserAutocomplete';

function UserAutocompleteAsync({ fetchUsers, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Fetch whenever open
  React.useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open, fetchUsers]);

  return (
    <UserAutocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      {...rest}
    />
  );
}

UserAutocompleteAsync.propTypes = {
  // Typically the result of useLazyQuery.
  fetchUsers: PropTypes.func.isRequired
};

export default UserAutocompleteAsync;
