import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import PersonAddIcon from '../../icons/20x20/PersonPlus';
import AddMembers from '../dialogs/AddMembers';

const AddMembersMenuItem = React.forwardRef((props, ref) => {
  const { teamId, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <AddMembers teamId={teamId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Add member'
      icon={ <PersonAddIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

AddMembersMenuItem.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default AddMembersMenuItem;
