import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import LinearStepper from '../../ui/stepper/LinearStepper';
import LinearStep from '../../ui/stepper/LinearStep';
import DialogContent from '../../ui/dialog/NoPaddingDialogContent';
import DialogContext from '../../ui/dialog/DialogContext';
import DialogTitleContainer from './DialogTitleContainer';
import OptionListTitle from './OptionListTitle';
import OptionList, { projectOption } from './OptionList';
import OptionDialogTitle from './OptionDialogTitle';
import OptionDialogContent from './OptionDialogContent';

// 4 sec transition duration.
const TRANSITION_DURATION = '.2s';

function OptionDialog({ project, defaultOption }) {
  const { closeDialog } = React.useContext(DialogContext);

  // Keep track of active step and option picked.

  const [activeStep, setActiveStep] = React.useState(defaultOption ? 1 : 0);
  const [option, setOption] = React.useState(defaultOption);

  React.useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  React.useEffect(() => {
    if (defaultOption) {
      setActiveStep(1);
    }
  }, [defaultOption]);

  // Ref to dialog actions container.

  const [actionsContainer, setActionsContainer] = React.useState(null);

  const setActionsContainerRef = (elem) => {
    setActionsContainer(elem);
  };

  // Make a transition when an option is clicked
  const handleOptionClick = (clickedOption) => {
    setOption(clickedOption);
    setActiveStep(1);
  };

  // Go back.
  // IMPORTANT NODE: we don't reset the option here.
  const handleGoback = () => {
    setActiveStep(0);
  };

  return (
    <React.Fragment>
      <DialogTitleContainer>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <OptionListTitle
              projectName={project.name}
              onClose={closeDialog}
            />
          </LinearStep>

          <LinearStep>
            <OptionDialogTitle
              option={option}
              onGoBack={handleGoback}
              onClose={closeDialog}
            />
          </LinearStep>
        </LinearStepper>
      </DialogTitleContainer>

      <Divider />

      <DialogContent>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep>
            <OptionList onOptionClick={handleOptionClick} />
          </LinearStep>

          <LinearStep>
            <OptionDialogContent
              project={project}
              onClose={closeDialog}
              option={option}
              actionsContainer={actionsContainer}
            />
          </LinearStep>
        </LinearStepper>
      </DialogContent>

      {/* placeholder for dialog actions */}
      <div>
        <LinearStepper
          transitionDuration={TRANSITION_DURATION}
          activeStep={activeStep}
        >
          <LinearStep />
          <LinearStep ref={setActionsContainerRef} />
        </LinearStepper>
      </div>
    </React.Fragment>
  );
}

OptionDialog.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    color: PropTypes.string
  }).isRequired,
  defaultOption: PropTypes.oneOf(Object.values(projectOption))
};

OptionDialog.defaultProps = {
  defaultOption: null
};

export default OptionDialog;
