import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '../../ui/dialog/MultiStepTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import Button from '../../ui/button/Button';

function CancelUploadsConfirmDialog({ open, onClose, onCancelUploads }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
    >
      <DialogTitle onClose={onClose}>
        Cancel all uploads?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your uploads are not complete. Would you like to cancel all
          ongoing uploads?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='primary'
          onClick={onClose}
        >
          Continue uploads
        </Button>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onCancelUploads}
        >
          Cancel uploads
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelUploadsConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelUploads: PropTypes.func.isRequired
};

export default CancelUploadsConfirmDialog;
