import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useCalendarUrl from '../useCalendarUrl';
import { getDateSlug } from '../helpers';
import Row from './ui/WeekDayRow';
import Cell from './ui/WeekDayCell';

function WeekDayRow({ daysOfWeek }) {
  const calendarUrl = useCalendarUrl();
  const history = useHistory();

  const handleDateClick = (date) => {
    const slug = getDateSlug(date);
    const view = 'day';
    const toUrl = `${calendarUrl}/${view}/${slug}`;
    history.push(toUrl);
  };

  return (
    <Row>
      {Array.from(
        daysOfWeek,
        (date, index) => (
          <Cell
            key={index}
            date={date}
            onDateClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDateClick(date);
            }}
          />
        )
      )}
    </Row>
  );
}

WeekDayRow.propTypes = {
  daysOfWeek: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ).isRequired
};

export default WeekDayRow;
