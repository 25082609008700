import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_PROFILE_FRAGMENT } from '../../account/fragments';

const SEARCH_NEW_MEMBERS_FOR_PROJECT_QUERY = gql`
  query SearchNewMembersForProject(
    $projectId: ID!, $filter: UserSearchCriteria) {
    newMembersForProject(projectId: $projectId, filter: $filter) {
      ...UserProfile
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export default function useLazySearchNewMembersForProjectQuery(options) {
  const { projectId, ...rest } = options;
  return useLazyQuery(SEARCH_NEW_MEMBERS_FOR_PROJECT_QUERY, {
    fetchPolicy: 'network-only',
    variables: { projectId },
    ...rest
  });
}
