import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import MobileNav from './MobileNav';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 256
  },
  toolbar: theme.mixins.toolbar
}));

function MobileDrawer({ open, onClose }) {
  const classes = useStyles();

  const handleKeyDown = (event) => {
    if (event.key === 'Tab' || event.key === 'Shift' ||
        event.key === 'Enter') {
      return;
    }
    onClose();
  };

  return (
    <Drawer
      ModalProps={{
        keepMounted: true
      }}
      classes={{
        paper: classes.paper
      }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.toolbar} />
      <Divider />
      <div
        role='presentation'
        onClick={onClose}
        onKeyDown={handleKeyDown}
      >
        <MobileNav />
      </div>
    </Drawer>
  );
}

MobileDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MobileDrawer;
