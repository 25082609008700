import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    padding: theme.spacing(4, 2, 2),
    margin: '0 auto',
    maxWidth: theme.spacing(136),
    width: '100%',

    [theme.breakpoints.up(768)]: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(7.5),
      width: '90%',
      borderRadius: '8px 8px 0px 0px',
      boxShadow: theme.shadows[1]
    },
    [theme.breakpoints.up(992)]: {
      padding: theme.spacing(7.5, 15)
    }
  }
}));

export default function Container(props) {
  const classes = useStyles();
  return <div className={classes.root} {...props} />;
}
