import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MessageDetailsMenu from '../ui/message/MessageDetailsMenu';
import DialogContext from '../ui/dialog/DialogContext';
import DeleteConfirm from './DeleteConfirmDialog';

function ProjectMessageDetailsMenu({ id, subject, messageBoardUrl }) {
  const { openDialog } = React.useContext(DialogContext);
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleEdit = (event) => {
    history.push(`${url}/edit`, { from: url });
  };

  const handleDelete = (event) => {
    openDialog({
      dialogContent: (
        <DeleteConfirm
          id={id}
          subject={subject}
          messageBoardUrl={messageBoardUrl}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MessageDetailsMenu
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
}

ProjectMessageDetailsMenu.propTypes = {
  // The ID of the message
  id: PropTypes.string.isRequired,

  // The subject of the message
  subject: PropTypes.string.isRequired,

  // The url of the Message Board
  messageBoardUrl: PropTypes.string.isRequired
};
export default ProjectMessageDetailsMenu;
