import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  }
}))

function ListItemText({ primary, secondary, className, ...rest }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography variant='subtitle2' noWrap color='textPrimary'>
        {primary}
      </Typography>
      <Typography variant='body2' noWrap color='textSecondary'>
        {secondary}
      </Typography>
    </div>
  );
}

ListItemText.propTypes = {
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node.isRequired
};

export default ListItemText;
