import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';

export function parseDateInput(dateInput) {
  if (dateInput instanceof Date) {
    return dateInput;
  }

  return parseISO(dateInput);
}

export const DateInputType = PropTypes.oneOfType([
  // Either an ISO8601 formatted string or an instance of Date.
  PropTypes.string,
  PropTypes.instanceOf(Date)
]);
