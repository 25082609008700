import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const UPDATE_PROJECT_DETAILS_MUTATION = gql`
  mutation UpdateProjectDetails(
    $projectId: ID!,
    $name: String!,
    $description: String!
  ) {
    updateProject(
      projectId: $projectId,
      changes: {name: $name, description: $description}
    ) {
      id
      name
      description
    }
  }
`;

export default function useUpdateProjectDetailsMutation(options) {
  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  return useMutation(UPDATE_PROJECT_DETAILS_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
