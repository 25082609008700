import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import UserWorkspaceMembershipListItem from './UserWorkspaceMembershipListItem';

function UserWorkspaceMembershipList({ workspaceMemberships }) {
  return (
    <List disablePadding>
      {(workspaceMemberships.map((membership) => (
        <UserWorkspaceMembershipListItem
          key={membership.id}
          workspaceMembership={membership}
        />
      )))}
    </List>
  );
}

UserWorkspaceMembershipList.propTypes = {
  workspaceMemberships: PropTypes.array.isRequired
};

export default UserWorkspaceMembershipList;
