import React from 'react';
import PropTypes from 'prop-types';
import AvatarGroup from '../ui/card/CardAvatarGroup';
import UserAvatar from '../account/UserAvatar';
import MemberAddedSubscriber from './TeamCardMemberAddedSubscriber';
import MemberRemovedSubscriber from './TeamCardMemberRemovedSubscriber';

function TeamCardAvatarGroup({ teamId, members, ...rest }) {
  return (
    <>
      <AvatarGroup max={8} {...rest}>
        {members.map(({ id, user }) => (
          <UserAvatar key={id} user={user} />
        ))}
      </AvatarGroup>
      <MemberAddedSubscriber teamId={teamId} />
      <MemberRemovedSubscriber teamId={teamId} />
    </>
  );
}

TeamCardAvatarGroup.propTypes = {
  teamId: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired
};

export default TeamCardAvatarGroup;
