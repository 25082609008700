import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import ElevationBackground from './elevation.png';
import DesktopNav from './DesktopNav';
import MobileDrawer from './MobileDrawer';
import SecondaryNav from './SecondaryNav';
import IconButton from '../ui/button/IconButton';
import MenuIcon from '../ui/icons/Menu';
import SearchIcon from '../ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  elevation: {
    height: 7,
    backgroundImage: `url(${ElevationBackground})`,
    backgroundSize: '1px 7px',
    backgroundRepeat: 'repeat-x',
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: -6
  }
}));

function AppBar() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <MuiAppBar
      position='fixed'
      elevation={0}
      className={classes.appbar}
    >
      <Toolbar className={classes.toolbar}>
        <div>
          <IconButton
            icon={ <MenuIcon /> }
            className={classes.menuButton}
            onClick={handleDrawerToggle}
          />

          <IconButton
            variant='secondary'
            icon={ <SearchIcon /> }
          />
        </div>

        <Hidden xsDown>
          <DesktopNav />
        </Hidden>

        <Hidden smUp implementation='css'>
          <MobileDrawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <SecondaryNav />
      </Toolbar>
      <div className={classes.elevation}></div>
    </MuiAppBar>
  );
}

export default React.memo(AppBar);
