import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../../ui/button/Button';
import DialogContent from '../../ui/dialog/MultiStepContent';
import DialogActions from '../../ui/dialog/MultiStepActionsContainer';
import DeleteUploadConfirmText from './DeleteUploadConfirmText';
import DeleteUploadConfirmCheckbox from './DeleteUploadConfirmCheckbox';
import useDeleteFileMutation from '../hooks/useDeleteFileMutation';

function DeleteConfirm({ onClose, actionsContainer, upload }) {
  const history = useHistory();
  const [yes, setYes] = React.useState(false);

  const handleYesChange = (event) => {
    setYes(event.target.checked);
  };

  const [deleteFile, { loading }] = useDeleteFileMutation({
    variables: { fileId: upload.id },
    onCompleted: (data) => {
      onClose();
      history.push(upload.parentUrl);
    }
  })

  return (
    <DialogContent>
      <DeleteUploadConfirmText
        uploadName={`${upload.name}${upload.ext}`}
      />

      <DeleteUploadConfirmCheckbox
        value={yes}
        onChange={handleYesChange}
      />

      <DialogActions container={actionsContainer}>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='danger'
          disabled={!yes || loading}
          loading={loading}
          onClick={deleteFile}
        >
          Yes, delete it
        </Button>
      </DialogActions>
    </DialogContent>
  );
}

DeleteConfirm.propTypes = {
  upload: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ext: PropTypes.string.isRequired,
    parentUrl: PropTypes.string.isRequired
  }).isRequired,

  // DOM node to mount dialog actions
  actionsContainer: PropTypes.object,

  // Close dialog
  onClose: PropTypes.func
};

export default DeleteConfirm;
