import React from 'react';
import PropTypes from 'prop-types';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import ZoomOutIcon from '../../../icons/18x18/MinusMagnifyingGlass';
import ZoomInIcon from '../../../icons/18x18/PlusMagnifyingGlass';
import ZoomButton from './ZoomButton';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center'
  }
}));

function DialogActions({ onZoomOut, onZoomIn, value, min, max }) {
  const classes = useStyles();
  return (
    <MuiDialogActions className={classes.dialogActions}>
      <ZoomButton
        icon={<ZoomOutIcon />}
        onClick={onZoomOut}
        disabled={value <= min}
      />

      <ZoomButton
        icon={<ZoomInIcon />}
        onClick={onZoomIn}
        disabled={value >= max}
      />
    </MuiDialogActions>
  );
}


DialogActions.propTypes = {
  onZoomOut: PropTypes.func.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default DialogActions;
