import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddNewMenuButton from './AddNewMenuButton';
import NotificationMenu from '../notification/NotificationMenu';
import AccountMenuButton from './AccountMenuButton';

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'stretch',
    display: 'flex',
    flex: '0 0 auto',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    },
  }
}));

export default function SecondaryNav() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AddNewMenuButton />
      <NotificationMenu />
      <AccountMenuButton />
    </div>
  );
}
