import React from 'react';
import PropTypes from 'prop-types';
import Column from './ui/TimeSpecificColumn';
import TimeSpecificScheduleItem
from '../daily-schedule/TimeSpecificScheduleItem';
import getScheduleKey from '../daily-schedule/getScheduleKey';

function TimeSpecificColumn({ projectId, items }) {
  return (
    <Column>
      {items.map(({ schedule, chipProps }) => (
        <TimeSpecificScheduleItem
          key={getScheduleKey(schedule)}
          projectId={projectId}
          schedule={schedule}
          chipProps={chipProps}
        />
      ))}
    </Column>
  );
}

TimeSpecificColumn.propTypes = {
  projectId: PropTypes.string.isRequired,

  items: PropTypes.arrayOf(PropTypes.shape({
    // A todo or an event object
    schedule: PropTypes.object.isRequired,

    // The corresponding chip props
    chipProps: PropTypes.object.isRequired
  })).isRequired
};

export default TimeSpecificColumn;
