import React from 'react';
import Menu from '../../ui/menu/StyledMenu';

export default function UploadFilesDestinationMenu(props) {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    />
  );
}
