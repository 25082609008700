import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import Switch from '../input/Switch';
import DragIndicatorIcon from '../../icons/16x16/DragIndicator';

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
    transition: theme.transitions.create(['box-shadow']),
    '&:hover': {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.4)'
    }
  },
  iconRoot: {
    marginRight: theme.spacing(1),
    fontSize: '1.25rem',
    color: theme.palette.text.secondary
  },
  label: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium
  },
  isDragging: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.4)'
  }
}));

function CustomizeFieldMenuItem(props) {
    const { index, name, label, show, onChange } = props;
    const classes = useStyles();

    const handleCheckedChange = (event) => {
      if (onChange) {
        onChange(name, event.target.checked);
      }
    };

    return (
      <Draggable draggableId={name} index={index}>
        {(provided, snapshot) => (
          <MenuItem
            className={clsx(classes.item, {
              [classes.isDragging]: snapshot.isDragging
            })}
            ContainerProps={{
              ref: provided.innerRef,
              ...provided.draggableProps,
            }}
            {...provided.dragHandleProps}
          >
            <ListItemIcon className={classes.iconRoot}>
              <DragIndicatorIcon color='secondary' />
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.label}}
              primary={label ? label : name}
            />
            <ListItemAction>
              <Switch
                edge='end'
                checked={show}
                onChange={handleCheckedChange}
              />
            </ListItemAction>
          </MenuItem>
        )}
      </Draggable>
    );
}

CustomizeFieldMenuItem.propTypes = {
  // index used by `react-beautiful-dnd`
  index: PropTypes.number.isRequired,

  // name of the field (must be unique)
  name: PropTypes.string.isRequired,

  // primary props for the `ListItemText`. If this is not provided,
  // the `name` prop will be used instead.
  label: PropTypes.string,

  // Show or hide this field
  show: PropTypes.bool.isRequired,

  // callback to execute when show is toggled (by clicking switch)
  onChange: PropTypes.func
};

export default CustomizeFieldMenuItem;
