import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';
import { TODO_ITEM_DETAILS } from '../fragments';
import { matchesTodoCriteria } from '../helpers';

const UPDATE_TODO_ITEM_MUTATION = gql`
  mutation UpdateTodoItem($todoItemId: ID!, $changes: UpdateTodoItemInput!) {
    updateTodoItem(todoItemId: $todoItemId, changes: $changes) {
      ...TodoItemDetails
    }
  }
  ${TODO_ITEM_DETAILS}
`;

export default function useUpdateTodoItemMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  const { todoListId, todoCriteria, ...otherOptions } = options;

  const fragmentObj = {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };

  return useMutation(UPDATE_TODO_ITEM_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    update: (client, { data: { updateTodoItem: updatedTodo }}) => {
      if (!matchesTodoCriteria(updatedTodo, todoCriteria)) {
        const todoList = client.readFragment(fragmentObj);
        const todoItems = todoList.todoItems;

        client.writeFragment({
          ...fragmentObj,
          data: {
            ...todoList,
            todoItems: todoItems.filter(({ id }) => id !== updatedTodo.id)
          }
        });
      }
    },
    ...otherOptions
  });
}
