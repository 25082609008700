import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CurrentWorkspaceContext from '../workspace/CurrentWorkspaceContext';
import Error from '../oops/Error';
import { MEMBERSHIP_DETAILS_FRAGMENT } from './fragments';

const MEMBERSHIPS_IN_CURRENT_WORKSPACE_QUERY = gql`
  query GetMembershipsInCurrentWorkspace($currentWorkspaceId: ID!) {
    workspace(workspaceId: $currentWorkspaceId) {
      id
      workspaceMemberships {
        ...MembershipDetails
      }
    }
  }
  ${MEMBERSHIP_DETAILS_FRAGMENT}
`;

function MembershipsInCurrentWorkspaceQuery(props) {
  const { onLoading, onError, children, ...rest } = props;
  const { currentWorkspaceId } = React.useContext(CurrentWorkspaceContext);
  const { loading, error, data } = useQuery(
    MEMBERSHIPS_IN_CURRENT_WORKSPACE_QUERY,
    {
      variables: { currentWorkspaceId },
      fetchPolicy: 'network-only',
      ...rest
    }
  );

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.workspace.workspaceMemberships);
}

MembershipsInCurrentWorkspaceQuery.propTypes = {
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default MembershipsInCurrentWorkspaceQuery;
