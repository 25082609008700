import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sortLabel: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    '& > svg': {
      margin: 0
    }
  }
}));

export default function SortLabel(props) {
  const classes = useStyles();
  return (
    <TableSortLabel
      className={classes.sortLabel}
      {...props}
    />
  );
}
