import React from 'react';
import MenuItem from '../menu/MenuItem';
import DeleteIcon from '../../icons/20x20/Trash';

export default React.forwardRef(function DeleteFileMenuItem(props, ref) {
  const { closeMenu, onClick, ...rest } = props;

  const handleClick = (event) => {
    closeMenu();
    if (onClick) { onClick(event); }
  };

  return (
    <MenuItem
      ref={ref}
      primary='Delete this file'
      secondary='Permanently delete this file.'
      icon={<DeleteIcon />}
      onClick={handleClick}
      {...rest}
    />
  );
});
