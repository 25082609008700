import gql from 'graphql-tag';
import { USER_PROFILE_FRAGMENT } from '../account/fragments';

export const MEMBERSHIP_DETAILS_FRAGMENT = gql`
  fragment MembershipDetails on WorkspaceMembership {
    id
    active
    role
  }
`;

export const MEMBERSHIP_USER_FRAGMENT = gql`
  fragment MembershipUser on WorkspaceMembership {
    user {
      ...UserProfile
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export const MEMBERSHIP_WORKSPACE_FRAGMENT = gql`
  fragment MembershipWorkspace on WorkspaceMembership {
    workspace {
      id
      name
    }
  }
`;
