import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const UploadFilesDropzoneRoot = React.forwardRef(
  function UploadFilesDropzoneRoot(props, ref) {
    const classes = useStyles();
    return (
      <div
        ref={ref}
        className={classes.dropzone}
        {...props}
      />
    );
  }
);

export default UploadFilesDropzoneRoot;
