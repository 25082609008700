import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import MuiListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from '../button/Overlay';
import MarkAsReadButton from './MarkAsReadButton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 0
  },
  link: {
    flexGrow: 1,
    display: 'block',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    color: theme.palette.text.primary,
    '&:hover $overlay': {
      opacity: 1
    },
    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },
    '&.Mui-focusVisible $overlay': {
      opacity: 1
    }
  },
  notification: {
    display: 'flex',
    alignItems: 'center'
  },
  overlay: {}
}));

const ListItem = React.forwardRef(function ListItem(props, ref) {
  const classes = useStyles();
  const history = useHistory();
  const { id, url, onMarkAsRead, closeMenu, children } = props

  const handleMarkAsReadClick = () => {
    if (onMarkAsRead) { onMarkAsRead(id); }
  };

  const handleClick = (event) => {
    event.preventDefault();
    handleMarkAsReadClick();

    if (closeMenu) { closeMenu(); }

    history.push(url);
  };

  return (
    <MuiListItem ref={ref} className={classes.root}>
      <Link
        className={classes.link}
        underline='none'
        href={url}
        onClick={handleClick}
      >
        <div className={classes.notification}>
          {children}
        </div>
        <Overlay className={classes.overlay} />
      </Link>
      {onMarkAsRead && (
        <MarkAsReadButton onClick={handleMarkAsReadClick} />
      )}
    </MuiListItem>
  );
});

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onMarkAsRead: PropTypes.func,
  closeMenu: PropTypes.func
};

export default ListItem;
