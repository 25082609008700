import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

const SEARCH_ACTIVE_PROJECT_MEMBER_QUERY = gql`
  query SearchActiveProjectMember(
    $projectId: ID!,
    $filter: ProjectMemberFilter
  ) {
    project(projectId: $projectId) {
      id
      members(filter: $filter) {
        id
        user {
          id
          username
          email
          avatar
        }
      }
    }
  }
`;

export default function useSearchProjectMember(options) {
  return useLazyQuery(SEARCH_ACTIVE_PROJECT_MEMBER_QUERY, {
    fetchPolicy: 'network-only',
    ...options
  });
}
