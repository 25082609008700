import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  notesContainer: {
    padding: theme.spacing(2)
  },
  notesTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(0.5)
  },
  notesBody: {
    ...theme.customMixins.wordWrap
  }
}));

function FileNotes({ children }) {
  const classes = useStyles();

  return (
    <div>
      <Divider />
      <div className={classes.notesContainer}>
        <Typography variant='body1' className={classes.notesTitle}>
          Notes:
        </Typography>
        <Typography variant='body1' className={classes.notesBody}>
          {children}
        </Typography>
      </div>
    </div>
  );
}

FileNotes.propTypes = {
  children: PropTypes.node.isRequired
};

export default FileNotes;
