import gql from 'graphql-tag';

export const PROJECT_DETAILS_FRAGMENT = gql`
  fragment ProjectDetails on Project {
    id
    name
    description
    startDate
    endDate
    color
    archived
  }
`;

export const PROJECT_PINNED_FRAGMENT = gql`
  fragment ProjectPinned on Project {
    pinned
  }
`;

export const PROJECT_STATS_FRAGMENT = gql`
  fragment ProjectStats on Project {
    memberCount
    todoCompletedRatio
  }
`;

export const PROJECT_MEMBERSHIP_DETAILS_FRAGMENT = gql`
  fragment ProjectMembershipDetails on ProjectMembership {
    id
    owner
    role
    user {
      id
      username
      email
      avatar
    }
  }
`;

export const PROJECT_MEMBERS_FRAGMENT = gql`
  fragment ProjectMembers on Project {
    members {
      ...ProjectMembershipDetails
    }
  }
  ${PROJECT_MEMBERSHIP_DETAILS_FRAGMENT}
`;
