import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  markAsReadButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: theme.spacing(4.5)
  },
  markAsReadButtonLabel: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: '50%',
    backgroundColor: '#FA383E'
  },
}));

export default function MarkAsReadButton(props) {
  const classes = useStyles();
  return (
    <ButtonBase className={classes.markAsReadButton} {...props}>
      <span className={classes.markAsReadButtonLabel} />
    </ButtonBase>
  );
}
