import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit'
  },
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    color: theme.palette.text.secondary
  },
  placeholder: {
    color: theme.palette.text.placeholder
  }
}));

function Placeholder({ text, color }) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.root, classes[color])}>
      {text}
    </span>
  );
}

Placeholder.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'placeholder'])
};

Placeholder.defaultProps = {
  color: 'placeholder'
};

export default Placeholder;
