import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import EditIcon from '../../icons/20x20/Pencil';
import EditTeam from '../dialogs/EditTeam';

const EditTeamMenuItem = React.forwardRef((props, ref) => {
  const { teamId, ...rest } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();
    openDialog({
      dialogContent: <EditTeam teamId={teamId} />,
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Edit team'
      icon={ <EditIcon /> }
      onClick={handleClick}
      {...rest}
    />
  );
});

EditTeamMenuItem.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default EditTeamMenuItem;
