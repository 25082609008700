import React from 'react';
import { isValid } from 'date-fns';
import NotFound from '../oops/SectionNotFound';
import { useDateParam } from './helpers';
import WeeklySchedule from './WeeklySchedule';

export default function WeeklyScheduleWrapper() {
  const date = useDateParam();

  if (isValid(date)) {
    return <WeeklySchedule date={date} />;
  }

  return <NotFound />;
}
