import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { PROJECT_DETAILS_FRAGMENT } from '../fragments';

const PROJECT_UPDATED_SUBSCRIPTION = gql`
  subscription OnProjectUpdated($projectId: ID!) {
    projectUpdated(projectId: $projectId) {
      ...ProjectDetails
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
`;

export default function useProjectUpdatedSubscription(options) {
  const { projectId, ...otherOptions } = options;
  const ret = useSubscription(PROJECT_UPDATED_SUBSCRIPTION, {
    variables: { projectId },
    ...otherOptions
  });
  if (ret.error) console.log('Error');
  return ret;
}
