import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const WORKSPACE_DELETED_SUBSCRIPTION = gql`
  subscription OnWorkspaceDeleted($workspaceId: ID!) {
    workspaceDeleted(workspaceId: $workspaceId) {
      id
    }
  }
`;

export default function useWorkspaceDeletedSubscription(options) {
  const { workspaceId, ...rest } = options;
  return useSubscription(WORKSPACE_DELETED_SUBSCRIPTION, {
    variables: { workspaceId },
    ...rest
  });
}
