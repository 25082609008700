import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '../icons/Error';
import InputAdornment from '@material-ui/core/InputAdornment';
import CssTextField from './CssTextField';

// Label styles.
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary
  },
  formControl: {
    transform: 'translate(0, 0) scale(1)'
  },
  marginDense: {
    fontSize: '0.875rem'
  }
}));

const TextField = React.forwardRef(function TextField(props, ref) {
  const labelClasses = useStyles();
  const {
    error,
    helperText,
    variant,
    InputLabelProps,
    InputProps,
    ...rest
  } = props;

  const getInputProps = () => {
    if (error) {
      return {
        ...InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <ErrorIcon color='negative' />
          </InputAdornment>
        )
      };
    }
    return InputProps;
  };

  return (
    <CssTextField
      ref={ref}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true, // Important to show `placeholder`
        focused: false,
        disabled: false,
        disableAnimation: true,
        classes: labelClasses
      }}
      InputProps={getInputProps()}
      error={error}
      helperText={helperText}
      {...rest}
    />
  );
});

export default TextField;
