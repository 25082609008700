import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChipRoot from './ChipRoot';

const useStyles = makeStyles((theme) => ({
  startTime: {
    lineHeight: 1.333333,
    marginRight: theme.spacing(0.5)
  },
  startIcon: {
    fontSize: '0.875rem',
    marginRight: theme.spacing(0.5)
  },
  label: {
    lineHeight: 1.333333,
    fontWeight: 600
  },
  lineThrough: {
    textDecoration: 'line-through'
  },
  superBold: {
    fontWeight: 700
  }
}));

function Chip({
  label,
  labelLineThrough,
  color,
  startTime,
  leftRadius,
  rightRadius,
  startIcon,
  disabled,
  moreButton,
  ...rest
}) {
  const classes = useStyles();

  return (
    <ChipRoot
      color={color}
      leftRadius={leftRadius}
      rightRadius={rightRadius}
      filled={!Boolean(startTime)}
      disabled={disabled}
      moreButton={moreButton}
      {...rest}
    >
      {startTime && (
        <Typography variant='caption' className={classes.startTime}>
          {startTime}
        </Typography>
      )}
      {startIcon && React.cloneElement(startIcon, {
        className: classes.startIcon
      })}
      <Typography
        noWrap
        variant='caption'
        className={clsx(classes.label, {
          [classes.lineThrough]: labelLineThrough,
          [classes.superBold]: moreButton
        })}
      >
        {label}
      </Typography>
    </ChipRoot>
  );
}

Chip.propTypes = {
  // The label of an event
  label: PropTypes.string.isRequired,
  labelLineThrough: PropTypes.bool,

  // The background color of the Chip (if no `startTime` was provided)
  // or the background color of Dot (if otherwise).
  color: PropTypes.string,

  // Start time (e.g., 9:30) of a time-specific event.
  // This should be set to null for all-day events
  startTime: PropTypes.string,


  // Chip border radius controls
  leftRadius: PropTypes.bool,
  rightRadius: PropTypes.bool,

  // Task icon for todo for example
  startIcon: PropTypes.node,

  disabled: PropTypes.bool,

  moreButton: PropTypes.bool
};

Chip.defaultProps = {
  leftRadius: true,
  rightRadius: true,
  labelLineThrough: false,
  disabled: false,
  moreButton: false
};

export default Chip;
