import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DesktopNavItem from './DesktopNavItem';
import useNavItems from './useNavItems';

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'stretch',
    display: 'flex'
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    flexGrow: 1,
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  }
}));

function DesktopNav() {
  const classes = useStyles();
  const navItems = useNavItems();
  return (
    <nav className={classes.root}>
      <ul className={classes.list}>
        {navItems.map((item) => (
          <DesktopNavItem key={item.primary} {...item} />
        ))}
      </ul>
    </nav>
  );
}

export default DesktopNav;
