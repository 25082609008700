import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../ui/progress/PageLoading';
import SectionError from '../oops/SectionError';
import { TEAM_DETAILS_FRAGMENT, TEAM_MEMBERS_FRAGMENT } from './fragments';

const TEAM_DETAILS_AND_MEMBERS_QUERY = gql`
  query GetTeamDetailsAndMembers($teamId: ID!) {
    team(teamId: $teamId) {
      ...TeamDetails
      ...TeamMembers
    }
  }
  ${TEAM_DETAILS_FRAGMENT}
  ${TEAM_MEMBERS_FRAGMENT}
`;

function TeamDetailsAndMembersQuery(props) {
  const { teamId, onLoading, onError, children, ...rest } = props;
  const { loading, error, data } = useQuery(
    TEAM_DETAILS_AND_MEMBERS_QUERY, {
      variables: { teamId },
      ...rest
  });

  if (loading) return onLoading ? onLoading() : <Loading />;
  if (error) return onError ? onError(error) : <SectionError error={error} />;
  return children(data.team);
}

TeamDetailsAndMembersQuery.propTypes = {
  teamId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func
};

export default TeamDetailsAndMembersQuery;
export { TEAM_DETAILS_AND_MEMBERS_QUERY };
