import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));

export default function Toolbar(props) {
  const classes = useStyles();
  return <div className={classes.toolbar} {...props} />;
}
