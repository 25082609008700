import GenericDocumentIcon128 from '../icons/128/Document';
import GenericDocumentIcon40 from '../icons/40/Generic';
import FileGenericIcon from '../../icons/FileGeneric';
import FolderIcon from '../../icons/FolderOutline';
import { ArchiveMimeType } from './archive';
import { DocumentMimeType } from './document';
import { ImageMimeType } from './image';
import { VideoMimeType } from './video';
import { AudioMimeType } from './audio';

const CommonMimeType = {
  ...ArchiveMimeType,
  ...DocumentMimeType,
  ...ImageMimeType,
  ...VideoMimeType,
  ...AudioMimeType
};

// Is the given `inputMimeType` of type image?
function isImage(inputMimeType) {
  const pattern = /image\/.*/;
  return pattern.test(inputMimeType);
}

// Is the given `inputMimeType` of type audio?
function isAudio(inputMimeType) {
  const pattern = /audio\/.*/;
  return pattern.test(inputMimeType);
}

// Is the given `inputMimeType` of type video?
function isVideo(inputMimeType) {
  const pattern = /video\/.*/;
  return pattern.test(inputMimeType);
}

// Is the given `inputMimeType` of type PDF?
function isPdf(inputMimeType) {
  return inputMimeType === 'application/pdf';
}

// Is the given `inputMimeType` of type `directory`
export function isDirectory(inputMimeType) {
  return inputMimeType === 'directory';
}

// Returns 128x128 icon for the given `inputMimeType`;
// TODO: We don't yet have 128x128 icon for folder.
export function getIcon128ForMimeType(inputMimeType) {
  if (
    CommonMimeType.hasOwnProperty(inputMimeType) &&
    CommonMimeType[inputMimeType].Icon128
  ) {
    return CommonMimeType[inputMimeType].Icon128;
  } else {
    return GenericDocumentIcon128;
  }
}

// Returns 40x40 icon for the given `inputMimeType`;
export function getIcon40ForMimeType(inputMimeType) {
  if (
    CommonMimeType.hasOwnProperty(inputMimeType) &&
    CommonMimeType[inputMimeType].Icon40
  ) {
    return CommonMimeType[inputMimeType].Icon40;
  } else {
    return GenericDocumentIcon40;
  }
}

// Returns 24x24 icon for the given `inputMimeType`;
export function getIcon24ForMimeType(inputMimeType) {
  if (
    CommonMimeType.hasOwnProperty(inputMimeType) &&
    CommonMimeType[inputMimeType].Icon24
  ) {
    return CommonMimeType[inputMimeType].Icon24;
  } else if (isDirectory(inputMimeType)){
    return FolderIcon;
  } else {
    return FileGenericIcon;
  }
}

// Helper function to get a human readable string from the given
// `inputMineType`. E.g, 'application/msword' => 'Word Document'.
export function getDescriptionForMimeType(inputMimeType) {
  if (CommonMimeType.hasOwnProperty(inputMimeType)) {
    return CommonMimeType[inputMimeType].description;
  } else if (isDirectory(inputMimeType)){
    return 'Folder';
  } else {
    return 'Document';
  }
}

export const DocumentType = Object.freeze({
  IMAGE: 0,
  VIDEO: 1,
  AUDIO: 2,
  PDF: 3,
  GENERIC: 4
});

export function convertMimeTypeToDocumentType(inputMimeType) {
  if (isImage(inputMimeType)) {
    return DocumentType.IMAGE;
  } else if (isVideo(inputMimeType)) {
    return DocumentType.VIDEO;
  } else if (isAudio(inputMimeType)) {
    return DocumentType.AUDIO;
  } else if (isPdf(inputMimeType)) {
    return DocumentType.PDF;
  } else {
    return DocumentType.GENERIC;
  }
}
