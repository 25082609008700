import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import StyledSectionRow from '../../ui/todo/sheet/SectionRow';
import useUpdateTodoItemMutation from '../hooks/useUpdateTodoItemMutation';
import {TODO_CRITERIA_SHAPE, diffTodoItemStates} from '../helpers';
import {areTwoSectionRowsEqual, areTwoColsArraysEqual} from './helpers';

function SectionRow({ sectionId, todoCriteria, row, cols, index }) {
  const { todoId: currentTodoId } = useParams();

  const [updateTodoItem] = useUpdateTodoItemMutation({
    // These shits are here to help update the cache after mutation
    todoCriteria,
    todoListId: sectionId,

    variables: {
      todoItemId: row.id
    },
  });

  const handleBlurWithin = React.useCallback((prevState, currState) => {
    const diff = diffTodoItemStates(prevState, currState);

    if (isEmpty(diff)) {
      return;
    }

    updateTodoItem({
      variables: {
        changes: diff
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTodoItem: {
          ...currState
        }
      }
    });
  }, [updateTodoItem]);

  return (
    <StyledSectionRow
      index={index}
      row={row}
      cols={cols}
      onBlurWithin={handleBlurWithin}
      sectionId={sectionId}
      todoCriteria={todoCriteria}
      highlight={currentTodoId === row.id}
    />
  );
}

SectionRow.propTypes = {
  // TODO: Prop-Drilling.
  // todolist id
  sectionId: PropTypes.string.isRequired,

  // TODO: Prop-Drilling.
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired,

  // The todo item
  row: PropTypes.object.isRequired,

  // sheet's columns
  cols: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    getCellProps: PropTypes.func.isRequired,
    renderCell: PropTypes.func.isRequired
  })).isRequired,

  index: PropTypes.number.isRequired
};

function areTwoSectionRowPropsEqual(prevProps, nextProps) {
  return prevProps.sectionId === nextProps.sectionId &&
         prevProps.todoCriteria === nextProps.todoCriteria &&
         prevProps.index === nextProps.index &&
         areTwoColsArraysEqual(prevProps.cols, nextProps.cols) &&
         areTwoSectionRowsEqual(prevProps.row, nextProps.row);
};

export default React.memo(SectionRow, areTwoSectionRowPropsEqual);
