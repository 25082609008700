import React from 'react';
import HeaderCellSkeleton from './HeaderCellSkeleton';

export default function HeaderSkeleton() {
  return (
    <thead>
      <tr>
        <HeaderCellSkeleton contentPaddingLeft={false} />
        <HeaderCellSkeleton />
        <HeaderCellSkeleton contentFullWidth borderRight={false} />
      </tr>
    </thead>
  );
}
