import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { USER_PROFILE_FRAGMENT } from './fragments';
import Error from '../oops/Error';

const CURRENT_USER_PROFILE_QUERY = gql`
  query GetCurrentUserProfile {
    me {
      ...UserProfile
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

function CurrentUserProfileQuery(props) {
  const { onLoading, onError, children, ...otherProps } = props;
  const { loading, error, data } = useQuery(CURRENT_USER_PROFILE_QUERY, {
    ...otherProps
  });
  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError() : <Error error={error} />;
  return children(data.me);
}

CurrentUserProfileQuery.propTypes = {
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default CurrentUserProfileQuery;
