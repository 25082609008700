import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

// The name `REOPEN_TODO_ITEM_MUTATION` has been used by the same function
// in `todo/hooks/useReopenTodoItemMutation.js`. Why don't we just re-use
// it? The reason is because that hook is so specific to TodoSheet. It
// needs `todoListId` and `todoCriteria` in order to update the TodoSheet
// correctly once a todo item is re-opened.
const MARK_TODO_AS_INCOMPLETE_MUTATION = gql`
  mutation MarkTodoAsIncomplete($todoId: ID!) {
    reopenTodoItem(todoItemId: $todoId) {
      id
      completed
    }
  }
`;

export default function useReopenTodoMutation(options) {
  const { todoId, ...otherOptions } = options;
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(MARK_TODO_AS_INCOMPLETE_MUTATION, {
    variables: { todoId },
    onError: (error) => {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...otherOptions
  });
}
