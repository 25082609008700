import PropTypes from 'prop-types';
import useProjectUpdatedSubscription
from './hooks/useProjectUpdatedSubscription';

function ProjectUpdatedSubscriber({ projectId }) {
  useProjectUpdatedSubscription({ projectId });
  return null;
}

ProjectUpdatedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectUpdatedSubscriber;
