import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Overlay from '../../ui/button/Overlay';
import { CHIP_HEIGHT, CHIP_GAP, CHIP_DEFAULT_COLOR } from './constants';

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    flex: '1 1 auto',
    textAlign: 'left',
    justifyContent: 'flex-start',
    width: '100%',
    height: CHIP_HEIGHT - CHIP_GAP,
    padding: theme.spacing(0, 0.5),
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    borderRadius: 0,
    '&:hover $overlay': {
      opacity: 1.0
    }
  },
  leftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  rightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  },
  dot: {
    flex: '0 0 auto',
    marginRight: theme.spacing(0.5),
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  overlay: {}
}));

function ChipRoot({
  color,
  filled,
  leftRadius,
  rightRadius,
  children,
  disabled,
  moreButton,
  ...rest
}) {
  const classes = useStyles();

  const colorStyle = {
    backgroundColor: color || CHIP_DEFAULT_COLOR,
    color: '#fff'
  };

  return (
    <ButtonBase
      component='div'
      className={clsx(classes.chipRoot, {
        [classes.leftRadius]: leftRadius,
        [classes.rightRadius]: rightRadius,
        [classes.disabled]: disabled
      })}
      style={(filled && !moreButton) ? colorStyle : null}
      disabled={disabled}
      {...rest}
    >
      {(!filled && !moreButton) && (
        <span className={classes.dot} style={colorStyle} />
      )}
      {children}
      <Overlay className={classes.overlay} />
    </ButtonBase>
  );
}

ChipRoot.propTypes = {
  color: PropTypes.string,
  filled: PropTypes.bool,
  leftRadius: PropTypes.bool,
  rightRadius: PropTypes.bool,
  disabled: PropTypes.bool,
  moreButton: PropTypes.bool,
  children: PropTypes.node
};

ChipRoot.defaultProps = {
  moreButton: false,
  filled: true,
  leftRadius: true,
  rightRadius: true,
  disabled: false
};

export default ChipRoot;
