import React from 'react';
import MuiCardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  content: {
    width: 0
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: '1.125rem'
  }
}));

export default function CardHeader({ classes: otherClasses, ...other }) {
  const classes = useStyles();
  return (
    <MuiCardHeader
      classes={{
        ...otherClasses,
        root: classes.root,
        content: classes.content
      }}
      titleTypographyProps={{
        variant: 'h6',
        component: 'h6',
        noWrap: true,
        className: classes.title
      }}
      subheaderTypographyProps={{
        color: 'textPrimary',
        variant: 'body2'
      }}
      {...other}
    />
  );
}
