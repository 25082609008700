import React from 'react';
import IconButton from '../button/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import EllipsisIcon from '../../icons/16x16/Ellipsis';

const useStyles = makeStyles((theme) => ({
  li: {
    minWidth: theme.spacing(3),
    width: theme.spacing(3),
    height: theme.spacing(2),
    borderRadius: 2,
    margin: theme.spacing(0, 0.5)
  }
}));

function CollapsedButton(props) {
  const classes = useStyles();

  return (
    <IconButton
      color='secondary'
      component='li'
      aria-label='Show path'
      square
      className={classes.li}
      icon={<EllipsisIcon />}
      {...props}
    />
  )
}

export default CollapsedButton;
