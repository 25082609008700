import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const CHANGE_PROJECT_ROLE_MUTATION = gql`
  mutation ChangeProjectRole($membershipId: ID!, $role: String) {
    changeProjectRole(membershipId: $membershipId, role: $role) {
      id
      role
    }
  }
`;

export default function useChangeRoleMutation(options) {
  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  return useMutation(CHANGE_PROJECT_ROLE_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
