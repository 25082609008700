import React from 'react';
import PropTypes from 'prop-types';
import {
  parseISO,
  addDays,
  subDays,
  startOfWeek,
  endOfWeek,
  format,
  compareAsc,
  addWeeks,
  isDate,
  isValid
} from 'date-fns';
import Menu from '../ui/todo/ToolbarMenu';
import MenuItem from '../ui/todo/ToolbarMenuItem';
import FilterDueDateButton from '../ui/todo/FilterDueDateButton';

// Helper function to format the given date so that our backend
// can understand.
function formatDate(date) {
  if (!date) {
    return date;
  }
  return format(date, 'yyyy-MM-dd');
}

// Helper function to determine if two date ranges are the same
// We don't have to use `isSameDay` from `date-fns` to do the comparisons
// since we know that `startDate` and `endDate` are just date-like strings.
function isSameDueDateRange(r1, r2) {
  return r1.startDate === r2.startDate && r1.endDate === r2.endDate;
}

// Due date types
const DueDateType = {
  'ALL': 0,
  'BEFORE_TODAY': 1,
  'TODAY': 2,
  'TOMORROW': 3,
  'THIS_WEEK': 4,
  'NEXT_WEEK': 5
};

// Default due date range: all due dates included
const DEFAULT_DUE_DATE_RANGE = {startDate: null, endDate: null};
const DEFAULT_DUE_DATE_LABEL = 'All due dates';

// Helper function to generate due date range from the given due date type
function fromDueDateTypeToDueDateRange(dueDateType) {
  let startDate = null;
  let endDate = null;
  let label;

  switch(dueDateType) {
    case DueDateType.BEFORE_TODAY:
      endDate = subDays(new Date(), 1);
      label = 'Before today';
      break;
    case DueDateType.TODAY:
      startDate = new Date();
      endDate = startDate;
      label = 'Today';
      break;
    case DueDateType.TOMORROW:
      startDate = addDays(new Date(), 1);
      endDate = startDate;
      label = 'Tomorrow';
      break;
    case DueDateType.THIS_WEEK:
      startDate = startOfWeek(new Date());
      endDate = endOfWeek(new Date());
      label = 'This week';
      break;
    case DueDateType.NEXT_WEEK:
      startDate = addWeeks(startOfWeek(new Date()), 1);
      endDate = endOfWeek(startDate);
      label = 'Next week';
      break;
    default:
      // No specific due date range provided.
      return {
        label: DEFAULT_DUE_DATE_LABEL,
        value: DEFAULT_DUE_DATE_RANGE
      };
  }

  return {
    label: label,
    value: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
  };
}

const DUE_DATE_RANGES = Object.values(DueDateType).map((dueDateType) => (
  fromDueDateTypeToDueDateRange(dueDateType)
));

// Helper function to convert from a due date range to a human readable
// label.
function fromDueDateRangeToDueDateLabel(dueDateRange) {
  const found = DUE_DATE_RANGES.find(({ value }) => (
    isSameDueDateRange(value, dueDateRange)
  ));

  return found.label;
}

function DueDateFilter({ value, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDueDateRangeChange = (newDueDateRange) => {
    handleClose();
    if (onChange) {
      onChange(newDueDateRange);
    }
  };

  const open = Boolean(anchorEl);
  const buttonLabel = fromDueDateRangeToDueDateLabel(value);

  const getButtonColor = () => {
    // Highlight the button when menu is open
    if (open) {
      return 'primaryDeemphasized';
    }

    // Highlight the non-default due date label
    if (buttonLabel !== DEFAULT_DUE_DATE_LABEL) {
      return 'primaryDeemphasized';
    }

    return 'secondary';
  };

  return (
    <div>
      <FilterDueDateButton
        style={!open && (buttonLabel !== DEFAULT_DUE_DATE_LABEL) ? {
          backgroundColor: 'transparent'
        } : {}}
        color={getButtonColor()}
        onClick={handleOpen}
      >
        {fromDueDateRangeToDueDateLabel(value)}
      </FilterDueDateButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {DUE_DATE_RANGES.map((range) => (
          <MenuItem
            key={range.label}
            selected={isSameDueDateRange(range.value, value)}
            onClick={(event) => onDueDateRangeChange(range.value)}
          >
            {range.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

DueDateFilter.propTypes = {
  value: PropTypes.oneOf(
    DUE_DATE_RANGES.map(({ value }) => value)
  ).isRequired,
  onChange: PropTypes.func
};

// Does the given `todoItem` match the specified `dueDateRange`?
export function matchesDueDateRange(todoItem, dueDateRange) {
  if (!todoItem) {
    return false;
  }

  // Always matches if no due date range was specified.
  if (!dueDateRange || (!dueDateRange.startDate && !dueDateRange.endDate)) {
    return true;
  }

  const dueDate = isDate(todoItem.dueDate) ?
                  parseISO(format(todoItem.dueDate, 'yyyy-MM-dd')) :
                  parseISO(todoItem.dueDate);

  // No sepcific dueDate was specified.
  if (!isValid(dueDate)) {
    return false;
  }

  const startDate = parseISO(dueDateRange.startDate);
  const endDate = parseISO(dueDateRange.endDate);

  if (isValid(startDate) && !isValid(endDate)) {
    return compareAsc(dueDate, startDate) >= 0;
  } else if (!isValid(startDate) && isValid(endDate)) {
    return compareAsc(dueDate, endDate) <= 0;
  } else {
    return compareAsc(dueDate, startDate) >= 0 &&
           compareAsc(dueDate, endDate) <= 0;
  }
}

export default DueDateFilter;
export { DEFAULT_DUE_DATE_RANGE, DUE_DATE_RANGES };
