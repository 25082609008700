import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MemberGridItem from './MemberGridItem';
import NoMatchesFound from './NoMatchesFound';
import MemberRemovedSubscriber from './MemberRemovedSubscriber';
import useMembershipManager from './useMembershipManager';
import MembershipChangedSubscriber from './MembershipChangedSubscriber';

// TODO: subscribe to member added events, don't forget to take
// into account the search criteria when updating the cache!
function MemberGrid({ members, variables, subscribeToMore, updateQuery }) {
  const { filter, projectId } = variables;
  const noMatchesFound = members.length === 0;
  const manager = useMembershipManager({ updateQuery });

  return noMatchesFound ? (
    <NoMatchesFound criteria={filter} />
  ) : (
    <Grid container spacing={2}>
      {members.map((member) => (
        <MemberGridItem
          key={member.id}
          member={member}
          onRoleChange={manager.handleRoleChange}
          onSetAsOwner={manager.handleSetAsOwner}
          onRemove={manager.handleRemove}
        />
      ))}

      <MembershipChangedSubscriber
        projectId={projectId}
        subscribeToMembershipChanged={subscribeToMore}
      />
      <MemberRemovedSubscriber
        projectId={projectId}
        subscribeToMemberRemoved={subscribeToMore}
      />
    </Grid>
  );
}

MemberGrid.propTypes = {
  members: PropTypes.array.isRequired,
  variables: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired
  }).isRequired
};

export default MemberGrid;
