import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSecondaryIcon, VARIANTS } from './secondaryIconVariant';

const useStyles = makeStyles((theme) => ({
  secondaryAvatar: {
    position: 'absolute',
    bottom: 6,
    right: 6,
    transform: 'translate(50%, 50%)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    border: `2px solid ${theme.palette.background.paper}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',

    // baseline color
    backgroundColor: '#E7F3FF',
    color: 'hsl(214, 89%, 52%)'
  }
}));

function SecondaryIcon({ variant }) {
  const classes = useStyles();
  const secondaryIcon = getSecondaryIcon(variant);

  if (!secondaryIcon) {
    return null;
  }

  const { SvgIcon, cssStyle } = secondaryIcon;

  return (
    <div className={classes.secondaryAvatar} style={cssStyle}>
      <SvgIcon fontSize='inherit' />
    </div>
  );
}

SecondaryIcon.propTypes = {
  variant: PropTypes.oneOf(VARIANTS)
};

export default SecondaryIcon;
