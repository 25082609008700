import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Error from '../oops/Error';

const PROJECT_NAME_QUERY = gql`
  query GetProjectName($projectId: ID!) {
    project(projectId: $projectId) {
      id
      name
    }
  }
`;

function ProjectNameQuery(props) {
  const { projectId, onLoading, onError, children, ...other } = props;
  const { loading, error, data, ...rest } = useQuery(PROJECT_NAME_QUERY, {
    variables: { projectId },
    ...other
  });

  if (loading) return onLoading ? onLoading() : null;
  if (error) return onError ? onError(error) : <Error error={error} />;
  return children(data.project, rest);
}

ProjectNameQuery.propTypes = {
  projectId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.func.isRequired
};

export default ProjectNameQuery;
