import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../ui/page/PageTitle';
import PageSubtitle from '../ui/page/PageSubtitle';
import PageHeader from '../ui/page/Header';
import Breadcrumbs from './TeamBreadcrumbs';
import TeamAvatarGroup from './TeamAvatarGroup';
import TeamNavigation from './TeamNavigation';
import useTeamUpdatedSubscription from './hooks/useTeamUpdatedSubscription';

function TeamHeader({ team }) {
  const { id, name, description, members } = team;

  // Subscribe to team updated event.
  useTeamUpdatedSubscription({ teamId: id });

  return (
    <>
      <Breadcrumbs teamName={name} />
      <PageHeader>
        <PageTitle>{name}</PageTitle>
        {description && (
          <PageSubtitle>
            {description}
          </PageSubtitle>
        )}
        <TeamAvatarGroup teamId={id} members={members} />
      </PageHeader>
      <TeamNavigation teamId={id} />
    </>
  );
}

TeamHeader.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    members: PropTypes.array.isRequired
  }).isRequired
};

export default React.memo(TeamHeader);
