import React from 'react';
import Button from '../../ui/button/Button';

export default function CancelButton(props) {
  return (
    <Button
      variant='contained'
      size='small'
      color='secondary'
      {...props}
    />
  );
}
