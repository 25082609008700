import React from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Chip from '../ui/chip/Chip';
import UserAvatar from '../account/UserAvatar';
import AutocompleteUserOption from './AutocompleteUserOption';

export function getAutocompleteUserProps() {
  return {
    renderOption: (user) => <AutocompleteUserOption user={user} />,
    renderTags: (value, getTagProps) => (
      value.map((user, index) => (
        <Chip
          color='primary'
          avatar={<UserAvatar user={user} /> }
          label={user.email}
          {...getTagProps({ index })}
        />
      ))
    ),
    getOptionSelected: (option, value) => option.id === value.id,
    getOptionLabel: (user) => user.username,
    filterOptions: createFilterOptions({
      stringify: (user) => `${user.username} ${user.email}`,
    })
  };
}
