import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  attachmentList: {
    paddingLeft: theme.spacing(4.5),
    '& > *': {
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

export default function AttachmentList(props) {
  const classes = useStyles();
  return <div className={classes.attachmentList} {...props} />
}
