import React from 'react';
import { getSecondaryIcon } from './secondaryIconVariant';

export default function renderSecondarySvgIcon(variant) {
  const result = getSecondaryIcon(variant);
  if (!result) {
    return null;
  }
  const { SvgIcon } = result;
  return <SvgIcon />;
}
