import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    maxWidth: '100%',
    borderRadius: 4,
    backgroundColor: 'transparent',
    '& .MuiSelect-select': {
      backgroundColor: 'transparent'
    }
  },

  inputInput: {
    height: theme.spacing(3),
    padding: theme.spacing(0.75, 1),
    //display: 'flex',
    //alignItems: 'center',

    // To truncate long text
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    // underline
    '&::after': {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      pointerEvents: 'none',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
    }
  },

  // Typography variances
  'input-body1': {
    ...theme.typography.body1,
    lineHeight: '24px'
  },
  'input-body2': {
    ...theme.typography.body2,
    lineHeight: '24px'
  },

  // Background variances
  'bg-always': {
    backgroundColor: '#F0F2F5',
    '&$focused': {
      // A bit darker than hover
      backgroundColor: '#DADDE1'
    }
  },
  'bg-hover': {
    '&:hover:not($focused)': {
      backgroundColor: '#F0F2F5'
    },
    '&$focused': {
      // Same as hover
      backgroundColor: '#F0F2F5'
    }
  },

  focused: {
    '& $inputInput': {
      // Draw underline on focused
      '&::after': {
        transform: 'scaleX(1)'
      }
    }
  }
}));

function Input({
  typography,
  background,
  ...rest
}) {
  const classes = useStyles();

  return (
    <InputBase
      classes={{
        root: clsx(
          classes.inputRoot,
          classes[`bg-${background}`]
        ),
        input: clsx(
          classes.inputInput,
          classes[`input-${typography}`]
        ),
        focused: classes.focused
      }}
      {...rest}
    />
  );
}

Input.propTypes = {
  // Input typography
  typography: PropTypes.oneOf(['body1', 'body2']),

  // When to show background
  background: PropTypes.oneOf(['hover', 'always']).isRequired,
};

Input.defaultProps = {
  typography: 'body1',
  background: 'hover'
};

export default Input;
