import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  emptyRow: {
    borderBottom: '1px solid #EBEDF0',
    padding: 0,
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    cursor: 'default'
  }
}));

function EmptyRow({ colSpan, height }) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        className={classes.emptyRow}
        style={{height: height}}
      />
    </TableRow>
  );
}

EmptyRow.propTypes = {
  colSpan: PropTypes.number,
  height: PropTypes.number
};

EmptyRow.defaultProps = {
  colSpan: 4,
  height: 24
};

export default EmptyRow;
