import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';
import { EVENT_DETAILS, EVENT_PARTICIPANTS, EVENT_REMINDERS}
from '../fragments';

const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($eventId: ID!, $changes: EventInput!) {
    updateEvent(eventId: $eventId, changes: $changes) {
      ...EventDetails
      ...EventParticipants
      ...EventReminders
    }
  }
  ${EVENT_DETAILS}
  ${EVENT_PARTICIPANTS}
  ${EVENT_REMINDERS}
`;

export default function useUpdateEvent(eventId) {
  const [mutate, mutationResults] = useMutation(UPDATE_EVENT_MUTATION, {
    loadingMessage: 'Saving...',
    successMessage: 'Event saved',
    variables: { eventId: eventId }
  });

  const mutateFn = useCallback((changes, optimisticResponse) => {
    mutate({
      variables: { changes },
      optimisticResponse: {
        updateEvent: {
          __typename: 'Event',
          id: eventId,
          ...optimisticResponse
        }
      }
    });
  }, [mutate, eventId]);

  return [mutateFn, mutationResults];
}
