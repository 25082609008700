import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import NotificationIcon from '../../icons/16x16/Bell';
import Container from './RemindersFieldContainer';
import FormField from './FormField';
import AddNotificationButton from './AddNotificationButton';
import DayReminderItem from './DayReminderItem';
import { dayEventReminder } from './defaultReminders';
import { MAX_NUM_REMINDERS } from './constants';

function DayReminderList({ dayReminders, onChange }) {
  const handleAddReminder = () => {
    const newReminder = {
      ...dayEventReminder,
      _id: uuidv4()
    };

    onChange((prevData) => ({
      ...prevData,
      dayReminders: [...prevData.dayReminders, newReminder]
    }));
  };

  return (
    <FormField label={<NotificationIcon />}>
      <Container>
        {dayReminders.map((reminder) => (
          <DayReminderItem
            key={reminder._id}
            value={reminder}
            onChange={onChange}
          />
        ))}
        {(dayReminders.length < MAX_NUM_REMINDERS) && (
          <AddNotificationButton onClick={handleAddReminder} />
        )}
      </Container>
    </FormField>
  );
}

DayReminderList.propTypes = {
  dayReminders: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DayReminderList;
