// Helper function to extract Graphql error details.
export function gqlErrorDetails(error) {
  if (!error || !error.message) return {};

  const hasGraphQLErrors = error.graphQLErrors &&
                           error.graphQLErrors.length;
  if (hasGraphQLErrors) {
    const { details } = error.graphQLErrors[0];
    if (details) return details;
  }

  return {};
}
