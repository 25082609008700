import React from 'react';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`members-tabpanel-${index}`}
      aria-labelledby={`members-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `members-tab-${index}`,
    'aria-controls': `members-tabpanel-${index}`,
  };
}

export default TabPanel;
export { a11yProps }
