import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { chipHeightStyle, getChipPosition } from './constants';

const useStyles = makeStyles((theme) => ({
  eventChipContainer: {
    position: 'absolute',
    ...chipHeightStyle,
    display: 'flex',
    alignItems: 'center',
    zIndex: 4,
  },
  rightPadding: {
    paddingRight: theme.spacing(1)
  },
  leftPadding: {
    paddingLeft: theme.spacing(1)
  },
  invisible: {
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none'
  }
}));

const EventChipContainer = React.forwardRef(
  function EventChipContainer({
    rightPadding,
    leftPadding,
    colIndex,
    position,
    colSpan,
    className,
    style,
    invisible,
    ...rest
  }, ref) {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={clsx(classes.eventChipContainer, {
          [classes.rightPadding]: rightPadding,
          [classes.leftPadding]: leftPadding,
          [classes.invisible]: invisible
        }, className)}
        style={{
          ...style,
          ...getChipPosition({ position, colIndex, colSpan })
        }}
        {...rest}
      />
    );
  }
);

EventChipContainer.propTypes = {
  // What is the position of this event withing the gridcell (a day)?
  position: PropTypes.number.isRequired,

  // What is the date of this event?
  colIndex: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired,

  // Within this row, how many days does this event span?
  colSpan: PropTypes.number.isRequired,

  rightPadding: PropTypes.bool,
  leftPadding: PropTypes.bool,
  invisible: PropTypes.bool
};

EventChipContainer.defaultProps = {
  rightPadding: true,
  leftPadding: true,
  invisible: false
};

export default EventChipContainer;
