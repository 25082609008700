import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { GET_CURRENT_USER_QUERY } from '../CurrentUserQuery';

const SIGNIN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        id
      }
    }
  }
`;

export default function useSignInMutation(options) {
  const { email, password, ...otherOptions } = options;
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || {from: {pathname: '/'}};

  return useMutation(SIGNIN_MUTATION, {
    variables: { email, password },
    update(cache, { data }) {
      cache.writeQuery({
        query: GET_CURRENT_USER_QUERY,
        data: { me: data.signin.user }
      });
    },
    onCompleted(data) {
      localStorage.setItem('auth-token', data.signin.token);
      history.replace(from);
    },
    ...otherOptions
  });
}
