import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    zIndex: 1010,

    // TODO: These values are hard-coded based on the height of the
    // AppBar.
    top: 64,
    [theme.breakpoints.down('xs')]: {
      top: 56
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

function Navigation({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='md' className={classes.container}>
        {children}
      </Container>
    </div>
  );
}

Navigation.propTypes = {
  children: PropTypes.node
};

export default Navigation;
