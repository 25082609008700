import React from 'react';
import PropTypes from 'prop-types';
import FollowMessageButton from '../../ui/message/FollowMessageButton';
import useFollowMessageMutation from './useFollowMessageMutation';

function AddCurrentUser({ messageId }) {
  const [addCurrentUser] = useFollowMessageMutation({
    variables: { messageId }
  });

  return <FollowMessageButton onClick={addCurrentUser} />;
}

AddCurrentUser.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default AddCurrentUser;
