import React from 'react';
import PropTypes from 'prop-types';
import TodoFormDialog from './TodoFormDialog';
import useCreateTodo from '../mutations/useCreateTodo';

function CreateTodoFormDialog({ projectId, defaultDueDate, onClose }) {
  const [createTodo] = useCreateTodo(projectId);

  const handleSubmit = (data, optimisticResponse) => {
    createTodo(data, optimisticResponse);
  };

  return (
    <TodoFormDialog
      projectId={projectId}
      defaultDueDate={defaultDueDate}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
}

CreateTodoFormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  defaultDueDate: PropTypes.instanceOf(Date),
  onClose: PropTypes.func
};

export default CreateTodoFormDialog;
