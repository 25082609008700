import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 3),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  }
}));

function FullScreenFormContainer({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

FullScreenFormContainer.propTypes = {
  children: PropTypes.node
};

export default FullScreenFormContainer;
