import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: '#EBEDF0'
  }
}));

export default function CreateProjectFormDivider() {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
}
