import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';

// TODO: This is just a hack to exploit the focus-trapping ability from
// material-ui Modal. We cannot use `Unstable_TrapFocus` from Mui because
// out children could contain modals (menu, dialog, ect...).
//
// Read [1] for a blug related to this shit!
//
// [1] - https://github.com/mui-org/material-ui/issues/19450
function TrapFocus({ trapped, children }) {
  return (
    <Modal
      role={undefined}
      open
      keepMounted
      disablePortal
      disableEscapeKeyDown
      hideBackdrop
      disableScrollLock
      disableEnforceFocus={!trapped}
      disableAutoFocus={!trapped}
      disableRestoreFocus={!trapped}
      style={{
        backgroundColor: 'transparent',
        position: 'static',
        zIndex: null
      }}
    >
      {children}
    </Modal>
  );
}

TrapFocus.propTypes = {
  trapped: PropTypes.bool,
  children: PropTypes.node
};

TrapFocus.defaultProps = {
  trapped: false
};

export default TrapFocus;
