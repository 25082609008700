import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SectionName from './SectionName';
import PrependRowButton from './PrependRowButton';
import EditSectionNameButton from './EditSectionNameButton';
import DeleteSectionButton from './DeleteSectionButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    '&:hover $actions': {
      opacity: 1,
      visibility: 'visible'
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    visibility: 'hidden'
  }
}));

// TODO:
// 1. Rename this shit to something else more meaningful.
// 2. Move all actions button to a separate component.
function SectionHeaderContent({
  sectionId,
  sectionName,
  onPrependRowButtonClick
}) {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);

  const handleEditModeChange = (newEditMode) => {
    setEditMode(newEditMode);
  };

  const handleEditNameButtonClick = () => {
    setEditMode(true);
  };

  const handlePrependRowButtonClick = () => {
    if (onPrependRowButtonClick) {
      onPrependRowButtonClick();
    }
  };

  return (
    <div className={classes.root}>
      <SectionName
        sectionId={sectionId}
        sectionName={sectionName}
        editMode={editMode}
        onEditModeChange={handleEditModeChange}
      />

      <div className={classes.actions}>
        <PrependRowButton onClick={handlePrependRowButtonClick} />
        <EditSectionNameButton onClick={handleEditNameButtonClick} />
        <DeleteSectionButton sectionId={sectionId} />
      </div>
    </div>
  );
}

SectionHeaderContent.propTypes = {
  sectionId: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  onPrependRowButtonClick: PropTypes.func
};

// Memoize to prevent this shit from re-rendering when expanded
// is toggled.
export default React.memo(SectionHeaderContent);
