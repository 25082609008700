import React from 'react';
import PropTypes from 'prop-types';
import Card from '../ui/card/Card';
import CardHeader from '../ui/card/CardHeader';
import CardTitleLink from '../ui/card/CardTitleLink';
import CardContent from '../ui/card/CardContent';
import CardContentTypography from '../ui/card/CardContentTypography';
import CardFooter from '../ui/card/CardFooter';
import TeamCardSubheader from './TeamCardSubheader';
import TeamCardAction from './TeamCardAction';
import TeamCardAvatarGroup from './TeamCardAvatarGroup';
import useTeamUpdatedSubscription from './hooks/useTeamUpdatedSubscription';
import useTeamURL from './hooks/useTeamURL';

function TeamCard({ team }) {
  const {
    id,
    name,
    description,
    memberCount,
    projectCount,
    members
  } = team;

  const url = useTeamURL(id);

  // Subscribe to team updated event.
  useTeamUpdatedSubscription({ teamId: id });

  return (
    <Card to={url}>
      <CardHeader
        action={ <TeamCardAction teamId={id} /> }
        title={ <CardTitleLink title={name} to={url} /> }
        subheader={
          <TeamCardSubheader
            projectCount={projectCount}
            memberCount={memberCount}
          />
        }
      />
      <CardContent>
        <CardContentTypography>
          {description}
        </CardContentTypography>
      </CardContent>
      <CardFooter>
        <TeamCardAvatarGroup teamId={id} members={members} />
      </CardFooter>
    </Card>
  );
}

TeamCard.propTypes = {
  team: PropTypes.object.isRequired
};

export default TeamCard;
