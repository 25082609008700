import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';

const TODO_ATTACHMENTS_ADDED_SUBSCRIPTION = gql`
  subscription OnTodoAttachmentsAdded($todoId: ID!) {
    todoAttachmentsAdded(todoId: $todoId)
  }
`;

function AttachmentsAddedSubscriber({ todoId, refetchAttachments }) {
  useSubscription(TODO_ATTACHMENTS_ADDED_SUBSCRIPTION, {
    variables: { todoId },
    onSubscriptionData: () => refetchAttachments()
  });
  return null;
}

AttachmentsAddedSubscriber.propTypes = {
  todoId: PropTypes.string.isRequired,
  refetchAttachments: PropTypes.func.isRequired
};

export default AttachmentsAddedSubscriber;
