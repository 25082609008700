import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Overlay from '../../ui/button/Overlay';
import UserAvatar from '../../account/UserAvatar';
import DropdownIcon from '../../ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'start',

    padding: theme.spacing(1.5),
    borderRadius: 8,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'
  },
  button: {
    '&:hover': {
      '& $overlay': { opacity: 1.0 },
      '& $dropdownIconContainer': { display: 'flex' }
    },

    '&.Mui-focusVisible': {
      '& $overlay': { opacity: 1.0 },
      '& $dropdownIconContainer': { display: 'flex' }
    },

    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    }
  },
  avatar: {
    marginRight: theme.spacing(1),
    flex: '0 0 auto'
  },
  text: {
    flex: '1 1 auto',
    width: 0
  },
  textPrimary: {
    lineHeight: 1.4,
    fontWeight: 500
  },
  dropdownIconContainer: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center'
  },
  highlight: {
    backgroundColor: '#E7F3FF'
  },
  overlay: {}
}));

const UserCard = React.forwardRef(function UserCard(props, ref) {
  const classes = useStyles();
  const { user, primary, secondary, highlight, disabled, ...rest } = props;

  return (
    <ButtonBase
      ref={ref}
      component='div'
      className={clsx(classes.root, {
        [classes.highlight]: highlight && !disabled,
        [classes.button]: !disabled
      })}
      disabled={disabled}
      {...rest}
    >
      <UserAvatar user={user} className={classes.avatar} />
      <div className={classes.text}>
        <Typography
          noWrap
          variant='subtitle1'
          className={classes.textPrimary}
        >
          {primary}
        </Typography>
        <Typography
          noWrap
          variant='body2'
          color='textSecondary'
        >
          {secondary}
        </Typography>
      </div>
      {!disabled && (
        <React.Fragment>
          <div className={classes.dropdownIconContainer}>
            <DropdownIcon color='secondary' />
          </div>
          <Overlay className={classes.overlay} />
        </React.Fragment>
      )}
    </ButtonBase>
  );
});

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node.isRequired
};

export default UserCard;
