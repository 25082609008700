import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from '../../new-ui/NumberInput';

function ReminderQuantityInput({ min, max, value, onChange, ...rest }) {
  return (
    <NumberInput
      background='always'
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
}

ReminderQuantityInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onChange: PropTypes.func,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default ReminderQuantityInput;
