import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '../../ui/svg-icons/History';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    fontSize: '4rem',
    color: theme.palette.text.secondary
  },
  text: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginTop: theme.spacing(1.5)
  }
}));

export default function Empty() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HistoryIcon className={classes.icon} />
      <Typography
        variant='body1'
        color='textSecondary'
        className={classes.text}
      >
        Your most recent projects will appear here.
      </Typography>
    </div>
  );
}
