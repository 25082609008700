import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const TEAM_PROJECT_DELETED_SUBSCRIPTION = gql`
  subscription OnTeamProjectDeleted($teamId: ID!) {
    teamProjectDeleted(teamId: $teamId) {
      id
    }
  }
`;

function ProjectDeletedSubscriber(props) {
  const { teamId, subscribeToProjectDeleted, skip } = props;

  React.useEffect(() => {
    if (!skip) {
      const unsubscribe = subscribeToProjectDeleted({
        document: TEAM_PROJECT_DELETED_SUBSCRIPTION,
        variables: { teamId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          const project = subscriptionData.data.teamProjectDeleted;
          return {
            team: {
              ...prev.team,
              projects: prev.team.projects.filter(({ id }) => (
                id !== project.id
              ))
            }
          };
        }
      });
      return () => unsubscribe();
    }
  }, [subscribeToProjectDeleted, teamId, skip]);

  return null;
}

ProjectDeletedSubscriber.propTypes = {
  teamId: PropTypes.string.isRequired,
  subscribeToProjectDeleted: PropTypes.func.isRequired,
  skip: PropTypes.bool
};

ProjectDeletedSubscriber.defaultProps = {
  skip: false
};

export default ProjectDeletedSubscriber;
