import React from 'react';
import PropTypes from 'prop-types';
import UpdateFollowersInput from './UpdateFollowersInput';
import useUpdateFollowersMutation from './useUpdateTodoFollowersMutation';

const isESC = (keyCode) => keyCode === 27;

function UpdateFollowers({ projectId, todoId, followers, open, onClose }) {
  const [value, setValue] = React.useState(followers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [updateFollowers] = useUpdateFollowersMutation({
    variables: { todoId }
  });

  const handleUpdateFollowers = (event) => {
    const userIds = value.map(({ id }) => id);
    updateFollowers({ variables: { userIds } });
    onClose(event);
  };

  const handleKeyDown = (event) => {
    const key = event.which || event.keyCode;

    if (isESC(key)) {
      event.preventDefault();
      handleUpdateFollowers(event);
    }
  };

  React.useEffect(() => {
    setValue(followers);
  }, [followers]);

  return open ? (
    <UpdateFollowersInput
      projectId={projectId}
      value={value}
      onChange={handleChange}
      onBlur={handleUpdateFollowers}
      onKeyDown={handleKeyDown}
    />
  ) : null;
}

UpdateFollowers.propTypes = {
  projectId: PropTypes.string.isRequired,
  todoId: PropTypes.string.isRequired,
  followers: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UpdateFollowers;
