import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { TODO_ITEM_DETAILS} from './fragments';
import {
  TODO_CRITERIA_SHAPE,
  matchesNewRowId,
  matchesTodoCriteria
} from './helpers';

const NEW_TODO_ITEM_APPENDED_SUBSCRIPTION = gql`
  subscription OnNewTodoItemAppended($todoListId: ID!) {
    newTodoItemAppended(todoListId: $todoListId) {
      ...TodoItemDetails
    }
  }
  ${TODO_ITEM_DETAILS}
`;

function NewTodoItemAppendedSubscriber({ todoListId, todoCriteria }) {
  const todoListFragment = {
    fragment: gql`
      fragment TodoListTodoItems on TodoList {
        todoItems {
          ...TodoItemDetails
        }
      }
      ${TODO_ITEM_DETAILS}
    `,
    fragmentName: 'TodoListTodoItems',
    id: `TodoList:${todoListId}`
  };

  useSubscription(NEW_TODO_ITEM_APPENDED_SUBSCRIPTION, {
    variables: { todoListId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) {
        return;
      }

      const newItem = subscriptionData.data.newTodoItemAppended;
      const todoList = client.readFragment(todoListFragment);
      let newTodoItems = [...todoList.todoItems];

      // TODO: explain why.
      // If we didn't remove the optimistic response result from
      // the mutation, we would end up with two todoitems in the
      // cache: one is the optimistic response result and
      // the other from the database (probably subscription result).
      if (
        newTodoItems.length &&
        matchesNewRowId(newTodoItems[newTodoItems.length - 1].id)
      ) {
        newTodoItems.pop();
      }

      // If this `newItem` is already in the cache (from mutation update)
      // => there is nothing todo here.
      const found = newTodoItems.some(({ id }) => id === newItem.id);
      if (found) {
        return;
      }

      // `newItem` not in the cache yet, but it does not satisfy
      // the current criteria => there nothing to do.
      if (!matchesTodoCriteria(newItem, todoCriteria)) {
        return;
      }

      // `newItem` not in the cache yet, but it does indeed satisfy
      // the current criteria => append it to the list.
      client.writeFragment({
        ...todoListFragment,
        data: {
          ...todoList,
          todoItems: [...newTodoItems, newItem]
        }
      });
    }
  });

  return null;
}

NewTodoItemAppendedSubscriber.propTypes = {
  todoListId: PropTypes.string.isRequired,
  todoCriteria: TODO_CRITERIA_SHAPE.isRequired
};

export default NewTodoItemAppendedSubscriber;
