import React from 'react';
import PropTypes from 'prop-types';
import FollowersBar from '../../ui/message/FollowersBar';
import Followers from '../../ui/message/Followers';
import FollowersList from '../../ui/message/FollowersList';
import AvatarGroup from '../../ui/message/FollowersAvatarGroup';
import UpdateButton from '../../ui/message/UpdateFollowersButton';
import FollowOrUnfollowButton from './FollowOrUnfollowButton';
import UpdateFollowers from './UpdateFollowers';

function FollowersBarWithData({ projectId, messageId, followers }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    <FollowersBar>
      <Followers>
        <FollowersList>
          {!open && (
            <React.Fragment>
              <AvatarGroup followers={followers} />
              <UpdateButton onClick={handleOpen} />
            </React.Fragment>
          )}
          <UpdateFollowers
            projectId={projectId}
            messageId={messageId}
            followers={followers}
            open={open}
            onClose={handleClose}
          />
        </FollowersList>
      </Followers>
      {!open && (
        <FollowOrUnfollowButton
          messageId={messageId}
          followers={followers}
        />
      )}
    </FollowersBar>
  );
}

FollowersBarWithData.propTypes = {
  projectId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  followers: PropTypes.array.isRequired
};

export default FollowersBarWithData;
