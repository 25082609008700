import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../../../ui/todo/details/UploadDropzone';
import ListContainer from '../../../ui/todo/details/UploadListContainer';
import List from '../../../ui/todo/details/UploadList';
import ListActions from '../../../ui/todo/details/UploadListActions';
import ActionSave from '../../../ui/todo/details/UploadListActionSave';
import ActionCancel from '../../../ui/todo/details/UploadListActionCancel';
import {StatusCode} from '../../../ui/file/list/UploadStatus';
import UploadListItem from './UploadListItem';
import useSaveUploadsMutation from './useSaveUploadsMutation';

function prependAndRemoveDuplicates(state, newItems) {
  const trulyNewItems = newItems.filter((newItem) => {
    const found = state.find((item) => (item.id === newItem.id));
    return found ? false : true;
  });
  return [...trulyNewItems, ...state];
}

function AttachmentUploader({ projectId, todoId, showPlaceholder }) {
  const [items, setItems] = React.useState([]);
  const isThisStillAliveRef = React.useRef(true);

  const handleDrop = React.useCallback((selectedFiles) => {
    const newItems = selectedFiles.map((file) => ({
      id: file.name,
      projectId,
      todoId,
      file,
      statusCode: StatusCode.UPLOADING
    }));

    setItems((prev) => prependAndRemoveDuplicates(prev, newItems));
  }, [projectId, todoId]);

  const handleUploadStatusChange = React.useCallback((uploadId, data) => {
    if (isThisStillAliveRef.current) {
      const newStatusCode = data.statusCode;
      setItems((prev) => prev.map((item) => {
        if (item.id !== uploadId) { return item; }
        let newItem = {...item, statusCode: newStatusCode};
        if (newStatusCode === StatusCode.SUCCEEDED) {
          newItem = {...newItem, path: data.path};
        }
        return newItem;
      }));
    }
  }, []);

  const handleUploadDelete = React.useCallback((uploadId) => {
    setItems((prev) => prev.filter((item) => item.id !== uploadId));
  }, []);

  // Helper effect to know when this component unmounted.
  React.useEffect(() => {
    isThisStillAliveRef.current = true;
    return () => isThisStillAliveRef.current = false;
  }, []);

  //
  // Save uploads to backend.
  //

  const [saveUploadsAndCloseDialog, {loading}] = useSaveUploadsMutation({
    onCompleted: (data) => {
      setItems([]);
    }
  });

  const saveSucceededUploads = () => {
    const succeededUploads = items.filter((item) => (
      item.statusCode === StatusCode.SUCCEEDED
    )).map(({ file, notes, path }) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      notes: notes || null,
      key: path
    }));

    saveUploadsAndCloseDialog({
      variables: {
        todoId,
        uploads: succeededUploads
      }
    });
  };

  // TODO: remove unsaved uploads from AWS S3!
  const discardSucceededUploadsAndCloseDialog = () => {
    setItems([]);
  };

  const isSucceededUploadsEmpty = items.filter((item) => (
    item.statusCode === StatusCode.SUCCEEDED
  )).length === 0;

  return (
    <div>
      <Dropzone
        onDrop={handleDrop}
        showPlaceholder={showPlaceholder && (items.length === 0)}
      />
      {(items.length > 0) && (
        <ListContainer>
          <List>
            {items.map((item) => (
              <UploadListItem
                key={item.id}
                item={item}
                onUploadStatusChange={handleUploadStatusChange}
                onUploadDelete={handleUploadDelete}
              />
            ))}
          </List>
          <ListActions>
            <ActionCancel
              disabled={loading}
              onClick={discardSucceededUploadsAndCloseDialog}
            />
            <ActionSave
              disabled={isSucceededUploadsEmpty}
              loading={loading}
              onClick={saveSucceededUploads}
            />
          </ListActions>
        </ListContainer>
      )}
    </div>
  );
}

AttachmentUploader.propTypes = {
  todoId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  showPlaceholder: PropTypes.bool
};

AttachmentUploader.defaultProps = {
  showPlaceholder: true
};

export default AttachmentUploader;
