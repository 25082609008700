import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

// IMORTANT NODES:
//
// - We have set `disablePortal` to `true` in order to keep the
//   focus within the row when this menu is open.
//
// - We need to set the tabindex for backdrop to -1 to make it able
//   to receive focus when it gets clicked on.
//   (so that focus will stay within row)
const CellMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 8,
    boxShadow: theme.customShadow.menu
  },
  list: {
    padding: theme.spacing(1, 0)
  }
}))(({ width, ...rest }) => (
  <Menu
    disablePortal
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    BackdropProps={{
      tabIndex: -1,
      invisible: true
    }}
    PaperProps={{
      style: {
        width: width
      }
    }}
    {...rest}
  />
));

CellMenu.propTypes = {
  width: PropTypes.number
};

CellMenu.defaultProps = {
  width: 192
};

export default CellMenu;
