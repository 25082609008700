import React from 'react';
import { parseISO, format, isToday, isYesterday } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  creator: {
    minWidth: 0,
    //flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  creatorAvatar: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1)
  },
  creatorInfo: {
    minWidth: 0,
    flexGrow: 1
  },
  username: {
    fontWeight: 600,
    lineHeight: 1.25
  },
  timestamp: {
    fontWeight: 600,
    lineHeight: 1.25
  }
}));

function formatUpdatedAt(timestampString) {
  const timestamp = parseISO(timestampString)
  if (isToday(timestamp)) {
    return format(timestamp, "'Today' 'at' HH:mm a");
  } else if (isYesterday(timestamp)) {
    return format(timestamp, "'Yesterday' 'at' HH:mm a");
  } else {
    return format(timestamp, "MMMM do, yyyy 'at' HH:mm a");
  }
}

function FileDetailsCreator({ creator, updatedAt }) {
  const classes = useStyles();

  return (
    <div className={classes.creator}>
      <UserAvatar
        user={creator}
        className={classes.creatorAvatar}
      />
      <div className={classes.creatorInfo}>
        <Typography
          noWrap
          variant='body1'
          color='textPrimary'
          className={classes.username}
        >
          {creator.username}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.timestamp}
        >
          {formatUpdatedAt(updatedAt)}
        </Typography>
      </div>
    </div>
  );
}

export default FileDetailsCreator;
