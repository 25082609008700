import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import useProjectURL from '../project/hooks/useProjectURL';
import { getDateSlug } from '../calendar/helpers';

export default function useEventUrl({ id, startsAt }) {
  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const date = parseISO(startsAt);
  const dateSlug = getDateSlug(date);
  return `${projectUrl}/calendar/week/${dateSlug}/event/${id}`;
}
