import React from 'react';
import NotesIcon from '../../../icons/16x16/Notes';
import TextField from '../../../new-ui/Input';
import FormField from './FormField';

export default function DescField(props) {
  return (
    <FormField icon={<NotesIcon color='secondary' />}>
      <TextField
        fullWidth
        multiline
        typography='body2'
        background='hover-and-focus'
        inputProps={{spellCheck: false, autoComplete: 'off'}}
        secondaryPlaceholder
        placeholder='Add more detail to this task...'
        {...props}
      />
    </FormField>
  );
}
