import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import CancelButton from './CancelButton';
import SubmitButton from './SubmitButton';
import EventFormData from './EventFormData';
import { ParticipantShape, ReminderShape } from './types';
import {
  initFormData,
  convertEventDataToFormData,
  convertFormDataToEventData,
  convertFormDataToOptimisticResponse,
  isFormDataInvalid
} from './eventFormHelpers';

function EventFormDialog({
  projectId,
  defaultAllDay,
  defaultStartDate,
  defaultStartTime,
  defaultEndDate,
  defaultEndTime,
  rawEvent,
  onSubmit,
  onClose
}) {
  const [formData, setFormData] = React.useState(
    rawEvent ?
    convertEventDataToFormData(rawEvent) :
    initFormData({
      startDate: defaultStartDate,
      startTime: defaultStartTime,
      endDate: defaultEndDate,
      endTime: defaultEndTime,
      allDay: defaultAllDay
    })
  );

  const handleSubmit = (event) => {
    if (onSubmit) {
      const data = convertFormDataToEventData(formData);
      const optimisticResponse =
        convertFormDataToOptimisticResponse(formData);
      onSubmit(data, optimisticResponse);
    }
  };

  return (
    <React.Fragment>
      <DialogContent>
        <EventFormData
          projectId={projectId}
          data={formData}
          onChange={setFormData}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose}>
          Cancel
        </CancelButton>
        <SubmitButton
          onClick={handleSubmit}
          disabled={isFormDataInvalid(formData)}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </React.Fragment>
  );
}

EventFormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,

  rawEvent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    allDay: PropTypes.bool.isRequired,

    startsAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired,

    endsAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]).isRequired,

    participants: PropTypes.arrayOf(ParticipantShape).isRequired,
    reminders: PropTypes.arrayOf(ReminderShape).isRequired
  }),

  defaultAllDay: PropTypes.bool,

  defaultStartDate: PropTypes.instanceOf(Date),
  defaultStartTime: PropTypes.object,

  defaultEndDate: PropTypes.instanceOf(Date),
  defaultEndTime: PropTypes.object,

  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

EventFormDialog.defaultProps = {
  defaultAllDay: true
};

export default EventFormDialog;
