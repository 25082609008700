import PropTypes from 'prop-types';
import { EVENT_REMINDER_METHODS, EVENT_REMINDER_UNITS } from './constants';

export const ParticipantShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.string
});

export const ReminderShape = PropTypes.shape({
  method: PropTypes.oneOf(EVENT_REMINDER_METHODS).isRequired,
  offsetUnit: PropTypes.oneOf(EVENT_REMINDER_UNITS).isRequired,
  offsetQuantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  offsetMinutes: PropTypes.number.isRequired
});
