import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { removeEntryFromCache } from './schedulesCacheHelpers';

const PROJECT_SCHEDULE_REMOVED_SUBSCRIPTION = gql`
  subscription OnProjectScheduleRemoved($projectId: ID!) {
    someEventRemoved(projectId: $projectId) {
      __typename

      ... on Event {
        id
        startsAt
        endsAt
      }

      ... on TodoItem {
        id
        dueDate
      }
    }
  }
`;

function ScheduleRemovedSubscription({ projectId }) {
  useSubscription(PROJECT_SCHEDULE_REMOVED_SUBSCRIPTION, {
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) {
        return;
      }

      const entry = subscriptionData.data.someEventRemoved;
      removeEntryFromCache(client, { projectId, entry });
    }
  });

  return null;
}

ScheduleRemovedSubscription.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ScheduleRemovedSubscription;
