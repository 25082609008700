import { isTodo, isEvent } from '../../helpers';

import eventOverlapsWithDate
from '../../daily-schedule/eventOverlapsWithDate';

import todoOverlapsWithDate
from '../../daily-schedule/todoOverlapsWithDate';


export default function isScheduleOverlapWithDate(schedule, date) {
  if (isEvent(schedule)) {
    return eventOverlapsWithDate(schedule, date);
  } else if (isTodo(schedule)) {
    return todoOverlapsWithDate(schedule, date);
  } else {
    throw new Error('Unknown schedule type');
  }
}
