import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerRow: {
    display: 'flex',
    alignItems: 'stretch',
    flex: '0 auto',
    height: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerCell: {
    textAlign: 'right',
    borderRight: `1px solid ${theme.palette.divider}`,
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  day: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.5,
    textTransform: 'uppercase',
    marginRight: theme.spacing(1)
  }
}));

function MonthGridHeader({ daysOfWeek }) {
  const classes = useStyles();

  return (
    <div role='row' className={classes.headerRow}>
      {daysOfWeek.map((day) => (
        <div
          key={day}
          role='columnheader'
          className={classes.headerCell}
        >
          <Typography variant='caption' className={classes.day}>
            {day}
          </Typography>
        </div>
      ))}
    </div>
  );
}

MonthGridHeader.propTypes = {
  daysOfWeek: PropTypes.arrayOf(PropTypes.oneOf([
    'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
  ])).isRequired
};

export default MonthGridHeader;
