import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

function MembersCountItem({ count, label }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='h4'>
        {count}
      </Typography>
      <Typography variant='body1' color='textSecondary'>
        {label}
      </Typography>
    </div>
  );
}

MembersCountItem.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string
};

export default MembersCountItem;
