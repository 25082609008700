import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const DELETE_TODO_LIST_MUTATION = gql`
  mutation DeleteTodoList($todoListId: ID!) {
    deleteTodoList(todoListId: $todoListId) {
      id
    }
  }
`;

export default function useDeleteTodoListMutation({
  todoListId,
  onCompleted: onFinished,
  ...otherMutationOptions
}) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();

  return useMutation(DELETE_TODO_LIST_MUTATION, {
    variables: { todoListId },
    onCompleted: (data) => {
      if (onFinished) {
        onFinished();
      }
    },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...otherMutationOptions
  });
}
