import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';

const UNFOLLOW_TODO_MUTATION = gql`
  mutation UnfollowTodo($todoId: ID!) {
    unfollowTodo(todoId: $todoId) {
      id
    }
  }
`;

export default function useFollowTodoMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(UNFOLLOW_TODO_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
