import React from 'react';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';
import {
  isESC,
  isEnter,
  isArrowUp,
  isArrowDown,
  isTab
} from './datetime-picker/keyboardEvent';
import formatDateInput from './datetime-picker/formatDateInput';
import addDays from './datetime-picker/addDays';
import subDays from './datetime-picker/subDays';
import MonthPopover from './datetime-picker/MonthPopover';
import Input from './AutosizeInput';

function DatePicker({
  value,
  onChange,
  format,
  clearable,
  onBlurAndClose,
  ...rest
}) {
  const [inputValue, setInputValue] = React.useState(
    formatDateInput(value, format)
  );
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleOpen = () => {
    if (!open) { setOpen(true); }
  };

  const handleClose = () => {
    setOpen(false);
    setInputValue(formatDateInput(value, format));
  };

  const handleInputClick = (e) => handleOpen();

  const dispatchDateChange = (newDate) => {
    if (onChange) {
      onChange(newDate);
    }
  };

  const handleSelectedDateChange = (date) => {
    setOpen(false);
    setInputValue(formatDateInput(date, format));
    dispatchDateChange(date);
  };

  const handleInputKeyDown = (e) => {
    if (isESC(e) || isEnter(e)) {
      e.preventDefault();
      e.stopPropagation();
      handleClose();
    } else if (isArrowUp(e)) {
      e.preventDefault();
      const d = subDays(value, 1);
      setInputValue(formatDateInput(d, format));
      dispatchDateChange(d);
    } else if (isArrowDown(e)) {
      e.preventDefault();
      const d = addDays(value, 1);
      setInputValue(formatDateInput(d, format));
      dispatchDateChange(d);
    } else if (isTab(e)) {
      handleClose();
    }
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);
    handleOpen();
    const d = new Date(newInputValue);

    if (newInputValue && isValid(d)) {
      dispatchDateChange(d);
    } else if (!newInputValue && clearable) {
      dispatchDateChange(null);
    }
  };

  const handleInputBlur = (event) => {
    if (!open && onBlurAndClose) {
      onBlurAndClose(event);
    }
  };

  // Sync the input value whenever value changes
  // TODO: explain why we have to guard against open here.
  React.useEffect(() => {
    if (!open) {
      setInputValue(formatDateInput(value, format));
    }
  }, [value, format, open]);

  return (
    <>
      <Input
        ref={anchorRef}
        placeholder='Pick a date...'
        inputProps={{spellCheck: false}}
        onClick={handleInputClick}
        onKeyDown={handleInputKeyDown}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
      />
      <MonthPopover
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        selectedDate={value}
        onChange={handleSelectedDateChange}
      />
    </>
  );
}


DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  format: PropTypes.string,

  // If set to `true` (default) and the input value is empty (because user
  // clears it), the value (selected date) will be set to null.
  clearable: PropTypes.bool,

  // The right moment to save changes to backend
  onBlurAndClose: PropTypes.func
};

DatePicker.defaultProps = {
  format: 'EEEE, MMMM d, yyyy',
  clearable: true
};

export default DatePicker;
