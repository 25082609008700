import gql from 'graphql-tag';

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfile on User {
    id
    username
    email
    avatar
  }
`;
