import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const EVENT_FOLLOWERS_CHANGED_SUBSCRIPTION = gql`
  subscription OnEventFollowersChanged($eventId: ID!) {
    eventFollowersChanged(eventId: $eventId) {
      id
      followers {
        id
        username
        email
        avatar
      }
    }
  }
`;

function SubscribersChangedSubscription({ eventId, onSubscribersChanged }) {
  React.useEffect(() => {
    const unsubscribe = onSubscribersChanged({
      document: EVENT_FOLLOWERS_CHANGED_SUBSCRIPTION,
      variables: { eventId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newFollowers =
          subscriptionData.data.eventFollowersChanged.followers;

        return {
          event: {
            ...prev.event,
            followers: newFollowers
          }
        };
      }
    });

    return () => unsubscribe();
  }, [eventId, onSubscribersChanged]);

  return null;
}


SubscribersChangedSubscription.propTypes = {
  eventId: PropTypes.string.isRequired,
  onSubscribersChanged: PropTypes.func.isRequired
};

export default SubscribersChangedSubscription;
