import React from 'react';
import PropTypes from 'prop-types';
import StyledSectionHeaderExpandRow from './StyledSectionHeaderExpandRow';
import ExpandSectionButton from './ExpandSectionButton';

function SectionHeaderExpandRow({
  colSpan,
  expanded,
  onExpandedChange,
  disableExpand,
  children
}) {
  const toggleExpanded = () => {
    if (onExpandedChange) {
      onExpandedChange(!expanded);
    }
  };

  return (
    <StyledSectionHeaderExpandRow colSpan={colSpan} border={!expanded}>
      <ExpandSectionButton
        disabled={disableExpand}
        expanded={expanded}
        onClick={toggleExpanded}
      />
      {children}
    </StyledSectionHeaderExpandRow>
  );
}

SectionHeaderExpandRow.propTypes = {
  colSpan: PropTypes.number,
  expanded: PropTypes.bool,
  onExpandedChange: PropTypes.func,
  disableExpand: PropTypes.bool
};

SectionHeaderExpandRow.defaultProps = {
  colSpan: 2,
  expanded: false,
  disableExpand: false
};

export default React.memo(SectionHeaderExpandRow);
