import { useCallback } from 'react';
import gql from 'graphql-tag';
import useMutation from '../../new-ui/graphql/useMutation';

const MARK_TODO_UNCOMPLETED_MUTATION = gql`
  mutation MarkTodoUncompleted($todoId: ID!) {
    reopenTodoItem(todoItemId: $todoId) {
      id
      completed
    }
  }
`;

export default function useMarkTodoUncompleted() {
  const [mutate, mutationResults] = useMutation(
    MARK_TODO_UNCOMPLETED_MUTATION,
    {
      loadingMessage: 'Re-opening task...',
      successMessage: 'Task re-opened'
    }
  );

  const mutateFn = useCallback((todoId) => {
    mutate({
      variables: { todoId },
      optimisticResponse: {
        reopenTodoItem: {
          __typename: 'TodoItem',
          id: todoId,
          completed: false
        }
      }
    });
  }, [mutate]);

  return [mutateFn, mutationResults];
}
