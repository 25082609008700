import gql from 'graphql-tag';

export const MESSAGE_AUTHOR = gql`
  fragment MessageAuthor on Message {
    author {
      id
      username
      email
      avatar
    }
  }
`;

export const MESSAGE_DETAILS = gql`
  fragment MessageDetails on Message {
    id
    subject
    plainText
    insertedAt
  }
`;

export const MESSAGE_RICH_TEXT = gql`
  fragment MessageRichText on Message {
    jsonText
  }
`;
