import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

// TODO:
// 1. This is (currently) exactly the same as `PresignedUrlQuery` in
//    `file/directory-options`.
// 2. The reason we duplicate it here is because we're going to limit
//    'who can add attachments to a task' to task owner only down the road.
//    Once this is fixed, this should be renamed to something like
//    `ATTACH_FILE_TO_TASK_PRESIGNED_URL_QUERY`, and probably takes an
//    additional argument `taskId` (beside the `projectId`).
const PRESIGNED_URL_QUERY = gql`
  query GetPresignedUrl(
    $projectId: ID!,
    $fileName: String!,
    $fileType: String!
  ) {
    projectPresignedUrl(
      projectId: $projectId,
      fileName: $fileName,
      fileType: $fileType
    ) {
      key
      uploadUrl
    }
  }
`;

function PresignedUrlQuery({
  projectId,
  fileName,
  fileType,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, data, error, ...otherQueryResults } = useQuery(
    PRESIGNED_URL_QUERY,
    {
      variables: { projectId, fileName, fileType },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : null;
  }

  return children(data.projectPresignedUrl, otherQueryResults);
}

PresignedUrlQuery.propTypes = {
  // The id of the destination project to upload the file
  projectId: PropTypes.string.isRequired,

  // The name and MIME type of the file to be uploaded
  fileName: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,

  onLoading: PropTypes.func,
  onError: PropTypes.func,

  children: PropTypes.func.isRequired
};

export default PresignedUrlQuery;
