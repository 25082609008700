import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format, isToday, isYesterday } from 'date-fns';
import Cell from './Cell';

function formatDateModified(timestamp) {
  const date = parseISO(timestamp);
  if (isToday(date)) {
    return format(date, "'Today' 'at' HH:mm a");
  } else if (isYesterday(date)) {
    return format(date, "'Yesterday' 'at' HH:mm a");
  } else {
    return format(date, "MMMM do, yyyy 'at' HH:mm a");
  }
}

function DateModifiedCell({ timestamp }) {
  return (
    <Cell>
      {formatDateModified(timestamp)}
    </Cell>
  );
}

DateModifiedCell.propTypes = {
  timestamp: PropTypes.string.isRequired
};

export default DateModifiedCell;
