import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import DataRow from './DataRow';

const useStyles = makeStyles((theme) => ({
  row: {
    '&:hover $dragIconRoot': {
      display: 'flex'
    }
  },
  dragging: {
    display: 'table',
    backgroundColor: '#E7F3FF',
    '& $dragIconRoot': {
      display: 'flex'
    }
  },
  dragIconRoot: {

  }
}));

function DraggableDataRow({ rowId, rowIndex, children, ...rest }) {
  const classes = useStyles();

  return (
    <Draggable draggableId={rowId} index={rowIndex}>
      {(provided, snapshot) => (
        <DataRow
          innerRef={provided.innerRef}
          className={clsx(classes.row, {
            [classes.dragging]: snapshot.isDragging
          })}
          dragHandleCellProps={{
            dragIconRootClassName: classes.dragIconRoot,
            ...provided.dragHandleProps
          }}
          {...provided.draggableProps}
          {...rest}
        >
          {children}
        </DataRow>
      )}
    </Draggable>
  );
}

DraggableDataRow.propTypes = {
  // task's ID.
  rowId: PropTypes.string.isRequired,

  // task's index within the section.
  rowIndex: PropTypes.number.isRequired,

  children: PropTypes.node
};

export default DraggableDataRow;
