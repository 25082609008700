import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function PendingInvitesCounterSkeleton() {
  return (
    <div>
      <Skeleton variant='rect' width={64} height={64} />
      <Skeleton variant='text' />
    </div>
  );
}
