import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../new-ui/select/StyledSelect';
import MenuItem from '../../new-ui/select/MenuItem';
import {
  EVENT_REMINDER_METHOD_OPTIONS,
  EVENT_REMINDER_METHODS
} from './constants';

function ReminderMethodSelect({ value, onChange }) {
  return (
    <Select
      typography='body2'
      background='always'
      value={value}
      onChange={onChange}
    >
      {EVENT_REMINDER_METHOD_OPTIONS.map(({ value, label }) => (
        <MenuItem typography='body2' key={label} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}

ReminderMethodSelect.propTypes = {
  value: PropTypes.oneOf(EVENT_REMINDER_METHODS).isRequired,
  onChange: PropTypes.func
};

export default ReminderMethodSelect;
