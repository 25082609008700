import React from 'react';
import TodoContextMenu from '../TodoContextMenu';
import EventContextMenu from '../EventContextMenu';
import { isTodo, isEvent } from '../helpers';

export default function withContextMenu(ChipComponent) {
  return ({ projectId, ...otherProps }) => {
    const schedule = otherProps.schedule;
    const [anchorPosition, setAnchorPosition] = React.useState(null);

    const handleContextMenu = (mouseEvt) => {
      mouseEvt.preventDefault();

      const mouseX = mouseEvt.clientX;
      const mouseY = mouseEvt.clientY;

      setAnchorPosition((prev) => {
        return (prev === null) ? {top: mouseY, left: mouseX} : null;
      });
    };

    const handleClose = () => {
      setAnchorPosition(null);
    };

    const renderContextMenu = () => {
      if (isTodo(schedule)) {
        return (
          <TodoContextMenu
            projectId={projectId}
            todo={schedule}
            open={anchorPosition !== null}
            onClose={handleClose}
            anchorPosition={anchorPosition}
          />
        );
      } else if (isEvent(schedule)) {
        return (
          <EventContextMenu
            projectId={projectId}
            event={schedule}
            open={anchorPosition !== null}
            onClose={handleClose}
            anchorPosition={anchorPosition}
          />
        );
      } else {
        return null;
      }
    };

    return (
      <React.Fragment>
        <ChipComponent
          {...otherProps}
          onContextMenu={handleContextMenu}
        />
        {renderContextMenu()}
      </React.Fragment>
    );
  };
}
