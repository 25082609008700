import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '../ui/card/Card';
import CardHeader from '../ui/card/CardHeader';
import CardContent from '../ui/card/CardContent';
import CardContentTypography from '../ui/card/CardContentTypography';
import CardFooter from '../ui/card/CardFooter';
import AvatarGroup from '../ui/card/CardAvatarGroup';

function TeamCardSkeleton() {
  return (
    <Card>
      <CardHeader
        title={ <Skeleton height={24} /> }
        subheader={ <Skeleton width='60%' height={16} /> }
      />
      <CardContent>
        <CardContentTypography>
          <Skeleton variant='rect' height={42} />
        </CardContentTypography>
      </CardContent>
      <CardFooter>
        <AvatarGroup >
          <Skeleton variant='circle' width={36} height={36} />
          <Skeleton variant='circle' width={36} height={36} />
          <Skeleton variant='circle' width={36} height={36} />
          <Skeleton variant='circle' width={36} height={36} />
        </AvatarGroup>
      </CardFooter>
    </Card>
  );
}

export default TeamCardSkeleton;
