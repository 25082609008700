import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const COMMENT_UPLOAD_PRESIGNED_URL_QUERY = gql`
  query GetCommentUploadPresignedUrl(
    $projectId: ID!,
    $fileName: String!,
    $fileType: String!
  ) {
    projectPresignedUrl(
      projectId: $projectId,
      fileName: $fileName,
      fileType: $fileType
    ) {
      key
      uploadUrl
    }
  }
`;

function UploadPresignedUrlQuery({
  projectId,
  fileName,
  fileType,
  onLoading,
  onError,
  children,
  ...otherQueryProps
}) {
  const { loading, data, error, ...otherQueryResults } = useQuery(
    COMMENT_UPLOAD_PRESIGNED_URL_QUERY,
    {
      variables: { projectId, fileName, fileType },
      ...otherQueryProps
    }
  );

  if (loading) {
    return onLoading ? onLoading() : null;
  }

  if (error) {
    return onError ? onError(error) : null;
  }

  return children(data.projectPresignedUrl, otherQueryResults);
}

UploadPresignedUrlQuery.propTypes = {
  // The destination project
  projectId: PropTypes.string.isRequired,

  // The name and MIME type of the file to be uploaded
  fileName: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,

  onLoading: PropTypes.func,
  onError: PropTypes.func,

  children: PropTypes.func.isRequired
};

export default UploadPresignedUrlQuery;
