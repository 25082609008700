import React from 'react';
import PropTypes from 'prop-types';
import { format, isSameDay, isSameYear } from 'date-fns';

function formatDate(date) {
  return isSameYear(date, new Date()) ?
         format(date, 'EEEE, MMMM d') :
         format(date, 'EEEE, MMMM d, yyyy');
}

function formatEndDate(startDate, endDate) {
  if (isSameDay(startDate, endDate)) {
    return 'All day';
  }

  return formatDate(endDate);
}

function formatTime(startDate, endDate) {
  const start = format(startDate, 'HH:mm');
  const end = format(endDate, 'HH:mm');
  return `${start} – ${end}`;
}

function EventCardSubtitle({ isAllDay, startDate, endDate }) {
  return (
    <React.Fragment>
      {formatDate(startDate)}
      <span style={{margin: '0px 8px', fontWeight: 700}}>&middot;</span>
      {isAllDay ?
       formatEndDate(startDate, endDate) :
       formatTime(startDate, endDate)
      }
    </React.Fragment>
  );
}

EventCardSubtitle.propTypes = {
  isAllDay: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
};

export default EventCardSubtitle;
