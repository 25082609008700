import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '&$fieldHeader': {
      marginBottom: theme.spacing(2)
    }
  },
  fieldIcon: {
    width: theme.spacing(2),
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1.25), // 36 - 16
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerIcon: {
    marginTop: theme.spacing(1.25)
  },
  fieldContent: {
    flex: '1 1 auto',
    display: 'flex'
  },
  contentHeader: {
    paddingLeft: theme.spacing(1)
  },
  fieldHeader: {}
}));

function FormField({ icon, header, children }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.formField, {
      [classes.fieldHeader]: header
    })}>
      <div className={classes.fieldIcon}>
        {icon}
      </div>
      <div className={clsx(classes.fieldContent, {
        [classes.contentHeader]: header
      })}>
        {children}
      </div>
    </div>
  );
}

FormField.propTypes = {
  icon: PropTypes.node,
  header: PropTypes.bool
};

FormField.defaultProps = {
  header: false
};

export default FormField;
