import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachmentList from './AttachmentList';

const useStyles = makeStyles((theme) => ({
  commentContent: {
    flexGrow: 1,
    backgroundColor: '#F0F2F5',
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(0.75, 1.5),
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary
  },
  authorName: {
    fontWeight: 600
  },
  commentText: {
    fontSize: '0.9375rem'
  },
  SUCCESS: {
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)'
  },
  WARNING: {
    backgroundColor: 'rgb(255, 244, 229)',
    color: 'rgb(102, 60, 0)'
  },
  INFO: {
    backgroundColor: 'rgb(232, 244, 253)',
    color: 'rgb(13, 60, 97)'
  },
  ERROR: {
    backgroundColor: 'rgb(253, 233, 236)',
    color: 'rgb(96, 16, 29)'
  }
}));

function Content({ authorName, text, files, variant }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.commentContent, classes[variant])}>
      <Typography variant='subtitle2' className={classes.authorName}>
        {authorName}
      </Typography>
      <Typography variant='body2' className={classes.commentText}>
        {text}
      </Typography>
      <AttachmentList attachments={files} />
    </div>
  );
}

Content.propTypes = {
  authorName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'DEFAULT',
    'SUCCESS',
    'WARNING',
    'INFO',
    'ERROR'
  ])
};

Content.defaultProps = {
  variant: 'DEFAULT'
};

export default Content;
