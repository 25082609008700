import React from 'react';
import PropTypes from 'prop-types';
import UncheckedIcon from '../../icons/20x20/RadioButtonUnchecked';
import CheckedIcon from '../../icons/20x20/RadioButtonChecked';
import ListItem from '../../ui/dialog/ListItem';
import ListItemText from '../../ui/dialog/ListItemText';
import ListItemEndIcon from '../../ui/dialog/ListItemEndIcon';

function FolderPickerItem({ primary, selected, ...rest }) {
  return (
    <ListItem selected={selected} {...rest}>
      <ListItemText primary={primary} />
      <ListItemEndIcon
        icon={selected ? (
          <CheckedIcon color='accent' />
        ) : (
          <UncheckedIcon color='secondary' />
        )}
      />
    </ListItem>
  );
}

FolderPickerItem.propTypes = {
  primary: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

export default FolderPickerItem;
