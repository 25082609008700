import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import AddMembersInput from './AddMembersInput';
import useAddMembersMutation from '../hooks/useAddMembersMutation';

function AddMembersForm({ team }) {
  const { id, name } = team;
  const { closeDialog } = React.useContext(DialogContext);
  const [value, setValue] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addMembersToteam, { loading }] = useAddMembersMutation({
    variables: {
      teamId: id,
      userIds: value.map(({ id }) => id)
    },
    onCompleted(data) {
      closeDialog();
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Add people to team {name}
      </DialogTitle>

      <DialogContent>
        <AddMembersInput
          fullWidth
          teamId={id}
          value={value}
          onChange={handleChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          color='primary'
          loading={loading}
          disabled={value.length === 0 }
          onClick={() => {
            addMembersToteam();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

AddMembersForm.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default AddMembersForm;
