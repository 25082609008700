import gql from 'graphql-tag';

export const TODO_ITEM_DETAILS = gql`
  fragment TodoItemDetails on TodoItem {
    id
    name
    description
    assignee {
      id
      username
      email
      avatar
    }
    dueDate
    priority
    completed
    rank
  }
`;

export const TODO_LIST_COMPLETION_COUNT = gql`
  fragment TodoListCompletionCount on TodoList {
    incompleteCount
    completedCount
  }
`;

export const PROJECT_SHEET_COLUMNS = gql`
  fragment ProjectSheetColumns on Project {
    todoSheetColumns {
      name
      width
      show
    }
  }
`;
