import React from 'react';
import PropTypes from 'prop-types';
import StyledFollowersBar from '../../../ui/todo/details/FollowersBar';
import Followers from '../../../ui/todo/details/Followers';
import StyledFollowersList from '../../../ui/todo/details/FollowersList';
import AvatarGroup from '../../../ui/todo/details/FollowersAvatarGroup';
import UpdateButton from '../../../ui/todo/details/UpdateFollowersButton';
import UpdateFollowers from './UpdateFollowers';
import JoinOrLeaveButton from './JoinOrLeaveButton';

function FollowersBar({ projectId, todoId, followers }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    <StyledFollowersBar>
      <Followers>
        <StyledFollowersList>
          {!open && (
            <React.Fragment>
              <AvatarGroup followers={followers} />
              <UpdateButton onClick={handleOpen} />
            </React.Fragment>
          )}
          <UpdateFollowers
            projectId={projectId}
            todoId={todoId}
            followers={followers}
            open={open}
            onClose={handleClose}
          />
        </StyledFollowersList>
      </Followers>
      {!open && (
        <JoinOrLeaveButton
          todoId={todoId}
          followers={followers}
        />
      )}
    </StyledFollowersBar>
  );
}

FollowersBar.propTypes = {
  projectId: PropTypes.string.isRequired,
  todoId: PropTypes.string.isRequired,
  followers: PropTypes.array.isRequired
};

export default FollowersBar;
