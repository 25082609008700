import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0, 1)
  }
}));

export default function FileDetailsToolbarDivider() {
  const classes = useStyles();
  return (
    <Divider
      orientation='vertical'
      flexItem
      className={classes.divider}
    />
  );
}
