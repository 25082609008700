import React from 'react';
import { useParams } from 'react-router-dom';
import TeamHeader from './TeamHeader';
import TeamSections from './TeamSections';
import TeamDetailsAndMembersQuery from './TeamDetailsAndMembersQuery';
import TeamSkeleton from './TeamSkeleton';

export default function Team() {
  const { teamId } = useParams();
  return (
    <TeamDetailsAndMembersQuery
      teamId={teamId}
      onLoading={() => <TeamSkeleton /> }
      fetchPolicy='network-only'
    >
      {(team) => (
        <div>
          <TeamHeader team={team} />
          <TeamSections />
        </div>
      )}
    </TeamDetailsAndMembersQuery>
  );
}
