import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2, 3, 0)
  }
}));

export default function EditUploadForm(props) {
  const classes = useStyles();

  return (
    <form
      className={classes.form}
      onSubmit={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
