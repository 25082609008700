import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../account/UserAvatar';
import UserCard from '../ui/card/UserCard';
import CardGridItem from '../ui/card/CardGridItem';
import MembershipAction from './MembershipAction';
import useMembershipDeactivatedSubscription
from './hooks/useMembershipDeactivatedSubscription';
import useMembershipReactivatedSubscription
from './hooks/useMembershipReactivatedSubscription';
import useMembershipChangedSubscription
from './hooks/useMembershipChangedSubscription';

function MembershipGridItem({ membership }) {
  const { id, active, role, user } = membership;

  // Subscribe to membership deactivated event.
  useMembershipDeactivatedSubscription({
    membershipId: id,
    skip: !active
  });

  // Subscribe to membership reactivated event.
  useMembershipReactivatedSubscription({
    membershipId: id,
    skip: active
  });

  // Subscribe to role changed event.
  useMembershipChangedSubscription({membershipId: id});

  return (
    <CardGridItem>
      <UserCard
        avatar={ <UserAvatar user={user} /> }
        title={user.username}
        subtitle={user.email}
        caption={active ? role : 'DEACTIVATED'}
        action={ <MembershipAction membership={membership} /> }
      />
    </CardGridItem>
  );
}

MembershipGridItem.propTypes = {
  membership: PropTypes.object.isRequired
};

export default React.memo(MembershipGridItem);
