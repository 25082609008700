import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconSprites from './icons.png';

const ICON_SIZE = 24;
const NUM_ICONS = 97;

// See [1] for more details about how to convert from a pure black color
// to a given target HEX color using css filter.
//
// [1] - https://codepen.io/sosuke/pen/Pjoqqp

const FILTER_PRIMARY = `invert(0%) sepia(96%) saturate(72%)
hue-rotate(26deg) brightness(98%) contrast(96%)`;

const FILTER_SECONDARY = `invert(46%) sepia(3%) saturate(496%)
hue-rotate(182deg) brightness(84%) contrast(88%)`;

const FILTER_DISABLED = `invert(89%) sepia(5%) saturate(215%)
hue-rotate(169deg) brightness(88%) contrast(86%)`;

const FILTER_ACCENT = `invert(39%) sepia(57%) saturate(200%) saturate(200%)
saturate(200%) saturate(200%) saturate(200%) saturate(147.75%)
hue-rotate(202deg) brightness(97%) contrast(96%);`;

const FILTER_LINK = `invert(30%) sepia(98%) saturate(200%) saturate(200%)
saturate(200%) saturate(166.5%) hue-rotate(192deg) brightness(91%)
contrast(101%)`;

const FILTER_NEGATIVE = `invert(25%) sepia(33%) saturate(200%)
saturate(200%) saturate(200%) saturate(200%) saturate(200%)
saturate(200%) saturate(110%) hue-rotate(345deg) brightness(132%)
contrast(96%)`;

const FILTER_POSITIVE = `invert(37%) sepia(61%) saturate(200%)
saturate(200%) saturate(200%) saturate(200%) saturate(115%)
hue-rotate(91deg) brightness(97%) contrast(105%)`;

const FILTER_WARNING = `invert(77%) sepia(29%) saturate(200%)
saturate(200%) saturate(200%) saturate(200%) saturate(200%)
saturate(128%) hue-rotate(359deg) brightness(102%) contrast(107%)`;

const FILTER_PLACEHOLDER = `invert(59%) sepia(11%) saturate(200%)
saturate(135%) hue-rotate(176deg) brightness(96%) contrast(94%)`;

const FILTER_ALWAYS_WHITE = 'invert(100%)';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    display: 'inline-block',
    cursor: 'inherit',
    userSelect: 'none',
    pointerEvents: 'none',
    width: ICON_SIZE,
    height: ICON_SIZE,
    backgroundImage: `url(${IconSprites})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${ICON_SIZE}px ${ICON_SIZE * NUM_ICONS}px`,
  },
  primary: {
    filter: FILTER_PRIMARY
  },
  secondary: {
    filter: FILTER_SECONDARY
  },
  accent: {
    filter: FILTER_ACCENT
  },
  disabled: {
    filter: FILTER_DISABLED
  },
  link: {
    filter: FILTER_LINK
  },
  warning: {
    filter: FILTER_WARNING
  },
  negative: {
    filter: FILTER_NEGATIVE
  },
  positive: {
    filter: FILTER_POSITIVE
  },
  placeholder: {
    filter: FILTER_PLACEHOLDER
  },
  white: {
    filter: FILTER_ALWAYS_WHITE
  }
}));

function Base({ color, className, ...rest }) {
  const classes = useStyles();
  const finalClassName = clsx(classes.root, classes[color], className);
  return <i className={finalClassName} {...rest} />;
}

Base.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'accent',
    'disabled',
    'link',
    'warning',
    'negative',
    'positive',
    'placeholder',
    'white'
  ]).isRequired
};

Base.defaultProps = {
  color: 'primary'
};

export default Base;
export {
  FILTER_PRIMARY,
  FILTER_SECONDARY,
  FILTER_ACCENT,
  FILTER_NEGATIVE,
  FILTER_ALWAYS_WHITE,
  FILTER_DISABLED
};
