import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  paper: {
    height: '100%',
    backgroundColor: theme.palette.background.default
  }
}))((props) => (
  <MuiDialog
    maxWidth='xl'
    fullWidth={true}
    {...props}
  />
));
