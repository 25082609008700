import React from 'react';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import UncheckedIcon from '../../icons/20x20/RadioButtonUnchecked';
import CheckedIcon from '../../icons/20x20/RadioButtonChecked';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export default function RadioButton({ className, ...rest }) {
  const classes = useStyles();
  return (
    <Radio
      disableRipple
      color='default'
      icon={(
        <UncheckedIcon color={rest.disabled ? 'disabled' : 'secondary'} />
      )}
      checkedIcon={ <CheckedIcon color='accent' /> }
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
}
