import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 3),
    boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.1)'
  }
}));

export default function Footer({ children }) {
  const classes = useStyles();
  return (
    <DialogActions className={classes.root}>
      {children}
    </DialogActions>
  );
}
