import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ProjectContainer from './ProjectContainer';
import ProjectHeaderWithData from './ProjectHeaderWithData';
import List from '../todo/List';
import Members from './members/Members';
import ProjectFiles from '../file/ProjectFiles';
import ProjectActivities from '../project-activity/ProjectActivities';
import ProjectMessages from '../message/ProjectMessages';
import Calendar from '../calendar/Calendar';

// TODO:
// 1. Skeletonize TodoSheet filters.
// 2. Handle permisssion denied error nicely.
function Project() {
  const { path, url } = useRouteMatch();
  const { projectId } = useParams();

  return (
    <ProjectContainer>
      <ProjectHeaderWithData projectId={projectId} />
      <Switch>
        <Route path={path} exact>
          <Redirect to={`${url}/tasks`} />
        </Route>

        <Route path={`${path}/tasks/:todoId?`} exact>
          <List projectId={projectId} rootUrl={`${url}/tasks`} />
        </Route>

        <Route path={`${path}/files`}>
          <ProjectFiles projectId={projectId} />
        </Route>

        <Route path={`${path}/members`} exact>
          <Members projectId={projectId} />
        </Route>

        <Route path={`${path}/messages`} >
          <ProjectMessages projectId={projectId} />
        </Route>

        <Route path={`${path}/activities`} exact>
          <ProjectActivities projectId={projectId} />
        </Route>

        <Route path={`${path}/calendar`} >
          <Calendar />
        </Route>
      </Switch>
    </ProjectContainer>
  );
}

export default Project;
