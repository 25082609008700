import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: theme.spacing(0, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3, 2)
    }
  }
}));

export default function MonthGridContainer(props) {
  const classes = useStyles();
  return <div className={classes.container} {...props} />;
}
