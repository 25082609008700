import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Overlay from '../../ui/button/Overlay';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    '&:hover:not(.Mui-selected) $overlay': {
      opacity: 1.0
    },
    '&:active:not(.Mui-selected) $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    },
    '&.Mui-focusVisible:not(.Mui-selected) $overlay': {
      opacity: 1.0
    }
  },
  primary: {
    fontSize: '0.875rem',
    lineHeight: '16px',
    fontWeight: 500
  },
  overlay: {}
}));

const ViewMenuItem = React.forwardRef(function ViewMenuItem(props, ref) {
  const { primary, ...rest } = props;
  const classes = useStyles();
  return (
    <MenuItem
      ref={ref}
      className={classes.root}
      {...rest}
    >
      <ListItemText
        primary={primary}
        classes={{ primary: classes.primary }}
      />
      <Overlay className={classes.overlay} />
    </MenuItem>
  );
});

ViewMenuItem.propTypes = {
  primary: PropTypes.string.isRequired
};

export default ViewMenuItem;
