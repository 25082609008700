import Mp3Icon128 from '../icons/128/Mp3';
import WavIcon128 from '../icons/128/Wav';
import OggAudioIcon128 from '../icons/128/OggAudio';
import FileAudioIcon from '../../icons/FileAudio';

import Mp3Icon40 from '../icons/40/Mp3';
import WavIcon40 from '../icons/40/Wav';
import OggIcon40 from '../icons/40/Ogg';

const waveformAudioAttributes = {
  description: 'Waveform Audio',
  Icon128: WavIcon128,
  Icon24: FileAudioIcon,
  Icon40: WavIcon40
};

export const AudioMimeType = Object.freeze({
  'audio/mpeg': {
    description: 'MP3 Audio',
    Icon128: Mp3Icon128,
    Icon24: FileAudioIcon,
    Icon40: Mp3Icon40
  },
  'audio/mp4': {
    description: 'Apple MPEG-4 Audio',
    Icon128: null,
    Icon24: FileAudioIcon,
    Icon40: null
  },
  'audio/ogg': {
    description: 'HTML5 Audio (Ogg)',
    Icon128: OggAudioIcon128,
    Icon24: FileAudioIcon,
    Icon40: OggIcon40
  },
  // Waveform Audio MIME types. See [1] for more details.
  // [1] - https://en.wikipedia.org/wiki/WAV
  'audio/wav': waveformAudioAttributes,
  'audio/x-wav': waveformAudioAttributes,
  'audio/vnd.wave': waveformAudioAttributes,
  'audio/wave': waveformAudioAttributes,
});
