import React from 'react';
import PropTypes from 'prop-types';
import { startOfDay, endOfDay } from 'date-fns';
import ProjectSchedulesQuery from '../ProjectSchedulesQuery';

function ProjectDailyScheduleQuery({ projectId, date, ...rest }) {
  const start = startOfDay(date);
  const end = endOfDay(date);

  return (
    <ProjectSchedulesQuery
      projectId={projectId}
      start={start}
      end={end}
      {...rest}
    />
  );
}

ProjectDailyScheduleQuery.propTypes = {
  // Which project?
  projectId: PropTypes.string.isRequired,

  // Which date?
  date: PropTypes.instanceOf(Date).isRequired
};

export default ProjectDailyScheduleQuery;
