import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '../../../icons/16x16/Person';
import { FILTER_PRIMARY } from '../../../icons/Base';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 1),
    borderRadius: 0,
    border: '1px solid transparent',
    justifyContent: 'flex-start',
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary,

    '&:hover $placeholderLabel': {
      display: 'flex'
    },

    '&:focus': {
      borderColor: theme.palette.primary.main,
      '& $placeholderLabel': {
        display: 'flex'
      }
    },

    '&:active': {
      borderColor: theme.palette.primary.main
    },

    '&.Mui-focusVisible': {
      outline: 0,
      borderColor: theme.palette.primary.main,
      '& $placeholderLabel': {
        display: 'flex'
      }
    }
  },
  placeholderLabel: {
    display: 'none'
  },
  startIcon: {
    marginLeft: 0,
    padding: 4,
    '&:hover': {
      '& $userAddIcon': {
        filter: FILTER_PRIMARY
      }
    }
  },
  userAddIcon: {}
}));

const StyledAddUserButton = React.forwardRef(
  function StyledAddUserButton(props, ref) {
    const { placeholderText, children, ...rest } = props;
    const classes = useStyles();

    return (
      <Button
        ref={ref}
        component='div'
        classes={{
          root: classes.root,
          startIcon: classes.startIcon,
          label: !children && placeholderText && classes.placeholderLabel
        }}
        startIcon={!children && placeholderText && (
          <PersonAddIcon
            color='secondary'
            className={classes.userAddIcon}
          />
        )}
        {...rest}
      >
        {children ? children : placeholderText}
      </Button>
    );
  }
);

StyledAddUserButton.propTypes = {
  placeholderText: PropTypes.string,
  children: PropTypes.node
};

export default StyledAddUserButton;
