import React from 'react';
import PropTypes from 'prop-types';
import TodoNotificationItem from './TodoNotificationItem';
import MessageNotificationItem from './MessageNotificationItem';
import EventNotificationItem from './EventNotificationItem';
import FileNotificationItem from './FileNotificationItem';

const ListItem = React.forwardRef((props, ref) => {
  const { notification, onMarkAsRead, ...rest } = props;

  if (notification.todoNotification) {
    return (
      <TodoNotificationItem
        ref={ref}
        onMarkAsRead={onMarkAsRead}
        notification={notification}
        {...rest}
      />
    );
  } else if (notification.messageNotification) {
    return (
      <MessageNotificationItem
        ref={ref}
        onMarkAsRead={onMarkAsRead}
        notification={notification}
        {...rest}
      />
    );
  } else if (notification.eventNotification) {
    return (
      <EventNotificationItem
        ref={ref}
        onMarkAsRead={onMarkAsRead}
        notification={notification}
        {...rest}
      />
    );
  } else if (notification.fileNotification) {
    return (
      <FileNotificationItem
        ref={ref}
        onMarkAsRead={onMarkAsRead}
        notification={notification}
        {...rest}
      />
    );
  } else {
    return null;
  }
});

ListItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onMarkAsRead: PropTypes.func.isRequired
};

export default ListItem;
