import { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const SOME_PROJECT_MEMBERSHIP_CHANGED_SUBSCRIPTION = gql`
  subscription OnSomeProjectMembershipChanged($projectId: ID!) {
    projectMemberChanged(projectId: $projectId) {
      id
      role
      owner
    }
  }
`;

function MembershipChangedSubscriber({
  projectId,
  subscribeToMembershipChanged
}) {
  useEffect(() => {
    const unsubscribe = subscribeToMembershipChanged({
      document: SOME_PROJECT_MEMBERSHIP_CHANGED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) { return prev; }

        const pm = subscriptionData.data.projectMemberChanged;

        return {
          project: {
            ...prev.project,
            members: prev.project.members.map((m) => {
              return (m.id !== pm.id) ? {
                ...m,
                owner: !pm.owner && m.owner
              } : {
                ...m,
                role: pm.role,
                owner: pm.owner
              };
            })
          }
        };
      }
    });

    return () => unsubscribe();
  }, [projectId, subscribeToMembershipChanged]);

  return null;
}

MembershipChangedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired,
  subscribeToMembershipChanged: PropTypes.func.isRequired
};

export default MembershipChangedSubscriber;
