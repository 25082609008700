import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ProjectHeaderProjectDetails from './ProjectHeaderProjectDetails';
import ProjectHeaderProjectAvatar from './ProjectHeaderProjectAvatar';

const useStyles = makeStyles((theme) => ({
  project: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto'
  }
}));

function ProjectHeaderProject({ project }) {
  const classes = useStyles();

  return (
    <div className={classes.project}>
      <ProjectHeaderProjectAvatar
        name={project.name}
        color={project.color}
      />

      <ProjectHeaderProjectDetails
        id={project.id}
        name={project.name}
        color={project.color}
        pinned={project.pinned}
        archived={project.archived}
        team={project.team}
      />
    </div>
  );
}

ProjectHeaderProject.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    pinned: PropTypes.bool.isRequired,
    archived: PropTypes.bool.isRequired,
    team: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default ProjectHeaderProject;
