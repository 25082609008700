import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { PROJECT_MEMBERSHIP_DETAILS_FRAGMENT } from './fragments';
import useProjectMemberRemovedSubscription
from './hooks/useProjectMemberRemovedSubscription';

function ProjectCardMemberRemovedSubscriber({ projectId }) {
  useProjectMemberRemovedSubscription({
    projectId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData) return;

      const { id: removedId } = subscriptionData.data.projectMemberRemoved;

      const fragmentObj = {
        id: `Project:${projectId}`,
        fragment: gql`fragment ProjectMembersWithCount on Project {
          memberCount
          members {
            ...ProjectMembershipDetails
          }
        },
          ${PROJECT_MEMBERSHIP_DETAILS_FRAGMENT}
        `,
        fragmentName: 'ProjectMembersWithCount'
      };

      const oldData = client.readFragment(fragmentObj);
      const updatedMembers = oldData.members.filter(({ id }) => (
        id !== removedId
      ));

      client.writeFragment({
        ...fragmentObj,
        data: {
          ...oldData,
          memberCount: updatedMembers.length,
          members: updatedMembers
        }
      });
    }
  });

  return null;
}

ProjectCardMemberRemovedSubscriber.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectCardMemberRemovedSubscriber;
