import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TimelineItem from './TimelineItem';
import TimelineItemDivider from './TimelineItemDivider';
import TimelineItemContent from './TimelineItemContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

const useStyles = makeStyles((theme) => ({
  dividerRoot: {
    display: 'flex',
    justifyContent: 'center'
  },
  dividerAvatar: {
    marginRight: 0,
    width: 56,
    height: 40,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divider: {
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function ActivityItem({ avatar, primary, secondary, divider, left }) {
  const classes = useStyles();
  const alignItems = secondary ? 'flex-start' : 'center';
  return (
    <TimelineItem left={left}>
      <TimelineItemDivider className={classes.dividerRoot}>
        <ListItem
          alignItems={alignItems}
          component='div'
          disableGutters
          style={{width: 'auto'}}
        >
          <ListItemAvatar className={classes.dividerAvatar}>
            <Typography
              variant='caption'
              color='textSecondary'
              className={classes.divider}
            >
              {divider}
            </Typography>
          </ListItemAvatar>
        </ListItem>
      </TimelineItemDivider>

      <TimelineItemContent>
        <ListItem alignItems={alignItems} component='div' disableGutters>
          <ListItemAvatar>
            {avatar}
          </ListItemAvatar>
          <ListItemText
            primary={primary}
            secondary={secondary}
            primaryTypographyProps={{
              style: {
                fontWeight: 500
              }
            }}
          />
        </ListItem>
      </TimelineItemContent>
    </TimelineItem>
  );
}

ActivityItem.propTypes = {
  left: PropTypes.bool,
  avatar: PropTypes.node.isRequired,
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node,
  divider: PropTypes.node.isRequired
};

ActivityItem.defaultProps = {
  left: false
};

export default ActivityItem;
