import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {isSameMonth, isSameDay, isFirstDayOfMonth, format} from 'date-fns';

const useStyles = makeStyles((theme) => ({
  daysRow: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex'
  },
  dayCell: {
    position: 'relative',
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'right',
    backgroundColor: 'transparent',
    zIndex: 2,
    flex: '1 1 0%',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  day: {
    position: 'absolute',
    top: 2,
    right: 2,

    display: 'inline-block',
    whiteSpace: 'nowrap',
    width: 'max-content',
    minWidth: 24,
    height: 24,
    lineHeight: 2,

    color: theme.palette.text.primary,
    fontWeight: 500,
    textAlign: 'center',

    // Clickable
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#F0F2F5'
    }
  },
  secondary: {
    color: '#BCC0C4'
  },
  current: {
    borderRadius: '50%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  firstDay: {
    padding: theme.spacing(0, 0.75)
  }
}));

function MonthGridDaysRow({startOfMonth, days, onCellClick, onDayClick}) {
  const classes = useStyles();
  return (
    <div className={classes.daysRow}>
      {days.map((day) => ({
        day,
        label: isFirstDayOfMonth(day) ?
               format(day, 'MMM d') :
               format(day, 'd'),
        className: clsx(classes.day, {
          [classes.current]: isSameDay(day, new Date()),
          [classes.secondary]: !isSameMonth(day, startOfMonth),
          [classes.firstDay]: isFirstDayOfMonth(day)
        })
      })).map(({ day, label, className }) => (
        <div
          key={label}
          className={classes.dayCell}
          onClick={(event) => {
            if (onCellClick) { onCellClick(event, day); }
          }}
        >
          <Typography
            variant='caption'
            className={className}
            onClick={(event) => {
              if (onDayClick) { onDayClick(event, day); }
            }}
          >
            {label}
          </Typography>
        </div>
      ))}
    </div>
  );
}

MonthGridDaysRow.propTypes = {
  startOfMonth: PropTypes.instanceOf(Date).isRequired,
  days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  onCellClick: PropTypes.func,
  onDayClick: PropTypes.func
};

export default MonthGridDaysRow;
