import React from 'react';
import PropTypes from 'prop-types';
import { getHours, getMinutes } from 'date-fns';
import formatTimeOfDay from '../new-ui/datetime-picker/formatTimeOfDay';
import { parseDateInput } from '../new-ui/datetime-picker/parseDateInput';
import TaskIcon from '../ui/svg-icons/TaskAlt';
import Chip from './ui/EventChip';
import { isTodo, isEvent, getEventTitle } from './helpers';
import { useDragDropContext } from './ui/MonthGridDragDropProvider';
import EventContextMenu from './EventContextMenu';
import TodoContextMenu from './TodoContextMenu';
import TodoDetails from '../todo/details/TodoDetails';
import EventDetails from './event-details/EventDetails';
import { useDialogContext } from './DialogProvider';

// Common properties of event/todo dialogs.
const dialogProps = {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: { height: '100%'}
  }
};

function getEventStartTime(e) {
  if (isTodo(e)) {
    return null;
  }

  if (isEvent(e) && e.allDay) {
    return  null;
  }

  const start = parseDateInput(e.startsAt);
  const result = formatTimeOfDay({
    hours: getHours(start),
    minutes: getMinutes(start)
  });

  return result.label;
}

// TODO: i don't think this is a good idea. Why not split EventChip
// into two separate components, namely EventChip and TodoChip?
function EventChip({ projectId, event, ...rest }) {
  const { onMouseDown } = useDragDropContext();

  const chipProps = {
    label: getEventTitle(event),
    startIcon: isTodo(event) ? <TaskIcon /> : null,
    lineThrough: isTodo(event) && event.completed,
    color: event.color,
    startTime: getEventStartTime(event),
    disabled: event.isDragging
  };

  const handleMouseDown = (mouseEvent) => {
    onMouseDown(mouseEvent, { event });
  };

  //
  // Context Menu
  //

  const [anchorPosition, setAnchorPosition] = React.useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();

    const mouseX = event.clientX;
    const mouseY = event.clientY;

    setAnchorPosition((prev) => {
      return (prev === null) ? {top: mouseY, left: mouseX} : null;
    });
  };

  const handleClose = () => {
    setAnchorPosition(null);
  };

  const renderContextMenu = () => {
    if (isTodo(event)) {
      return (
        <TodoContextMenu
          projectId={projectId}
          todo={event}
          open={anchorPosition !== null}
          onClose={handleClose}
          anchorPosition={anchorPosition}
        />
      );
    } else if (isEvent(event)) {
      return (
        <EventContextMenu
          projectId={projectId}
          event={event}
          open={anchorPosition !== null}
          onClose={handleClose}
          anchorPosition={anchorPosition}
        />
      );
    } else {
      return null;
    }
  };

  //
  // Dialogs
  //

  const { openDialog, closeDialog } = useDialogContext();

  const handleClick = (e) => {
    if (isTodo(event)) {
      openDialog({
        dialogContent: (
          <TodoDetails
            todoId={event.id}
            onClose={closeDialog}
          />
        ),
        dialogProps: dialogProps
      });
    } else if (isEvent(event)) {
      openDialog({
        dialogContent: (
          <EventDetails
            eventId={event.id}
            onClose={closeDialog}
          />
        ),
        dialogProps: dialogProps
      });
    }
  };

  return (
    <React.Fragment>
      <Chip
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
        onClick={handleClick}
        {...chipProps}
        {...rest}
      />
      {renderContextMenu()}
    </React.Fragment>
  );
}

EventChip.propTypes = {
  projectId: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired
};

export default EventChip;
