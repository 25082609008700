import React from 'react';
import PropTypes from 'prop-types';
import AllDayScheduleContainer from './ui/AllDayScheduleContainer';
import AllDayScheduleItem from './AllDayScheduleItem';
import getScheduleKey from './getScheduleKey';

function AllDaySchedule({ projectId, date, schedules }) {
  return (
    <AllDayScheduleContainer>
      {schedules.map((schedule) => (
        <AllDayScheduleItem
          key={getScheduleKey(schedule)}
          projectId={projectId}
          date={date}
          schedule={schedule}
        />
      ))}
    </AllDayScheduleContainer>
  );
}

AllDaySchedule.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  schedules: PropTypes.array.isRequired
};

export default AllDaySchedule;
