import { isTodo, isEvent } from '../helpers';

export default function getScheduleKey(schedule) {
  let prefix = '';

  if (isTodo(schedule)) {
    prefix = 'task';
  } else if (isEvent(schedule)) {
    prefix = 'event';
  } else {
    prefix = 'unknown';
  }

  return `${prefix}_${schedule.id}`;
}
