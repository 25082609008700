import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const REASSIGN_PROJECT_OWNER_MUTATION = gql`
  mutation ReassignProjectOwner($membershipId: ID!) {
    reassignProjectOwner(membershipId: $membershipId) {
      id
    }
  }
`;

export default function useReassignOwnerMutation(options) {
  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  return useMutation(REASSIGN_PROJECT_OWNER_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
