import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format, isToday, isYesterday } from 'date-fns';
import Typography from '@material-ui/core/Typography';

function formatTime(timestampString) {
  const timestamp = parseISO(timestampString)
  if (isToday(timestamp)) {
    return format(timestamp, "'Today' 'at' HH:mm a");
  } else if (isYesterday(timestamp)) {
    return format(timestamp, "'Yesterday' 'at' HH:mm a");
  } else {
    return format(timestamp, "MMMM do, yyyy 'at' HH:mm a");
  }
}

function Timestamp({ value, ...rest }) {
  return (
    <Typography
      variant='caption'
      color='textSecondary'
      {...rest}
    >
      {formatTime(value)}
    </Typography>
  );
}

Timestamp.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]).isRequired
};

export default Timestamp;
