import React from 'react';
import PropTypes from 'prop-types';
import ColorPickerItem from './ChipMenuColorPickerItem';
import { CHIP_DEFAULT_COLOR, CHIP_COLOR_OPTIONS } from './constants';

function ChipMenuColorPicker({ value, onChange }) {
  return CHIP_COLOR_OPTIONS.map(({ name, hex }) => (
    <ColorPickerItem
      key={name}
      color={hex}
      checked={(value === hex)}
      onSelect={onChange}
    />
  ));
}

ChipMenuColorPicker.propTypes = {
  value: PropTypes.oneOf(CHIP_COLOR_OPTIONS.map(({ hex }) => hex)),
  onChange: PropTypes.func
};

// A workaround for those events that do not have a color
ChipMenuColorPicker.defaultProps = {
  value: CHIP_DEFAULT_COLOR
};

export default ChipMenuColorPicker;
