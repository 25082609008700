import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import StyledListField from '../../ui/todo/details/ListField';
import useChangeListMutation from './hooks/useChangeListMutation';
import { TODO_DETAILS_QUERY } from './TodoDetailsQuery';

function _convertTodoCriteria(todoCriteria) {
  if (!todoCriteria) {
    return undefined /* intentionally undefined not null */;
  }

  return {
    completionType: todoCriteria.completionType,
    assigneeId: todoCriteria.assignee ? todoCriteria.assignee.id : undefined,
    dueDateBetween: todoCriteria.dueDateBetween
  };
}

function ListField({ todoId, list }) {
  const { todoCriteria } = useLocation();
  const reorderCriteria = _convertTodoCriteria(todoCriteria);

  const [reorderTodoItem] = useChangeListMutation({
    update: (client, { data: { reorderTodoItem } }) => {
      // Not this todo buddy!
      if (reorderTodoItem.todoItem.id !== todoId) {
        return;
      }

      const dstList = reorderTodoItem.destination.todoList;

      const previousResult = client.readQuery({
        query: TODO_DETAILS_QUERY,
        variables: { todoId }
      });

      client.writeQuery({
        query: TODO_DETAILS_QUERY,
        variables: { todoId },
        data: {
          todoItem: {
            ...previousResult.todoItem,
            todoList: {
              ...previousResult.todoItem.todoList,
              id: dstList.id,
              name: dstList.name
            }
          }
        }
      });
    }
  });

  const handleChange = (newList) => {
    if (list.id === newList.id) {
      return;
    }

    reorderTodoItem({
      variables: {
        todoItemId: todoId,
        todoListId: newList.id,
        index: 0 /* TODO: always move to front? */,
        filter: reorderCriteria
      },
      optimisticResponse: {
        __typename: 'Mutation',
        reorderTodoItem: {
          __typename: 'TodoReorderingResult',
          todoItem: {
            __typename: 'TodoItem',
            id: todoId
          },
          destination: {
            __typename: 'TodoLocation',
            index: 0,
            todoList: {
              __typename: 'TodoList',
              id: newList.id,
              name: newList.name
            }
          }
        }
      }
    });
  };

  return (
    <StyledListField
      projectId={list.project.id}
      value={list}
      onChange={handleChange}
    />
  );
}

ListField.propTypes = {
  todoId: PropTypes.string.isRequired,
  list: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default ListField;
