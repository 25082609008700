import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useProjectURL from '../project/hooks/useProjectURL';
import GenericActivityItem from './ui/GenericActivityItem';
import ActivityTextDetails from './ui/ActivityTextDetails';

function AddMessageItem({ activity }) {
  const { type, actor, details, message, insertedAt } = activity;
  const [msgSubject, msgText] = details;

  const { projectId } = useParams();
  const projectUrl = useProjectURL(projectId);
  const msgUrl = `${projectUrl}/messages/${message.id}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='added a new message called'
      linkUrl={msgUrl}
      linkText={msgSubject}
      insertedAt={insertedAt}
    >
      <ActivityTextDetails text={msgText} />
    </GenericActivityItem>
  );
}

AddMessageItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['ADD_MESSAGE']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    message: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default AddMessageItem;
