import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(0, 3),
    lineHeight: 1.2,
    fontWeight: 700
  }
}));

export default function InfoDialogContentTitle() {
  const classes = useStyles();
  return (
    <Typography variant='h6' className={classes.title}>
      Who would have access to a project in a given team?
    </Typography>
  );
}
