import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropdownIcon from '../../icons/12x12/ArrowDropDown';
import Overlay from '../../ui/button/Overlay';

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'inherit',
    textTransform: 'none',
    transition: 'none',
    textAlign: 'left',
    justifyContent: 'space-between',
    borderRadius: 4,
    padding: theme.spacing(0.5, 0.625, 0.5, 1.5),
    backgroundColor: theme.palette.button.secondary.main,

    '&:hover $overlay': {
      opacity: 1.0
    },
    '&:active $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    }
  },
  label: {
    fontWeight: 600
  },
  endIconContainer: {
    flex: '0 0 auto',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    '& $overlay': {
      opacity: 1.0,
      backgroundColor: 'rgba(0, 0, 0, 0.10)'
    }
  },
  overlay: {}
}));

function ViewMenuButton({ label, active, ...rest }) {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.button, {
        [classes.active]: active
      })}
      {...rest}
    >
      <Typography
        component='span'
        variant='subtitle2'
        noWrap
        className={classes.label}
      >
        {label}
      </Typography>
      <span className={classes.endIconContainer}>
        <ArrowDropdownIcon />
      </span>
      <Overlay className={classes.overlay} />
    </ButtonBase>
  );
}

ViewMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool
};

ViewMenuButton.defaultProps = {
  active: false
};

export default ViewMenuButton;
