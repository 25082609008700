import React from 'react';
import PropTypes from 'prop-types';
import { isSameDay } from 'date-fns';
import { useHistory } from 'react-router-dom';
import TaskIcon from '../../ui/svg-icons/TaskAlt';
import { getEventTitle, isTodo } from '../helpers';
import AllDayScheduleChip from './ui/AllDayScheduleChip';
import useScheduleUrl from './useScheduleUrl';
import useMatchCurrentScheduleUrl from './useMatchCurrentScheduleUrl';
import withContextMenu from './withContextMenu';

function _convertScheduleToChipProps(schedule) {
  return {
    label: getEventTitle(schedule),
    startIcon: isTodo(schedule) ? <TaskIcon /> : null,
    color: schedule.color,
    strikeThrough: !!schedule.completed
  };
}

function AllDayScheduleItem({ date, schedule, ...rest }) {
  const isFocus = useMatchCurrentScheduleUrl(schedule);

  const chipProps = React.useMemo(() => ({
    ..._convertScheduleToChipProps(schedule),
    leftContinuous: !isSameDay(schedule.startsAt, date),
    rightContinuous: !isSameDay(schedule.endsAt, date),
    focus: isFocus
  }), [schedule, date, isFocus]);

  const scheduleUrl = useScheduleUrl(schedule);
  const history = useHistory();

  const redirectToScheduleDetails = (event) => {
    history.push(scheduleUrl);
  };

  return (
    <AllDayScheduleChip
      {...chipProps}
      {...rest}
      onClick={redirectToScheduleDetails}
    />
  );
}

AllDayScheduleItem.propTypes = {
  // Which date?
  date: PropTypes.instanceOf(Date).isRequired,

  // A todo or an event object in the `date` above.
  schedule: PropTypes.object.isRequired
};

export default withContextMenu(AllDayScheduleItem);
