import React from 'react';
import PropTypes from 'prop-types';
import OptionListItem from './OptionListItem';
import PencilIcon from '../../icons/20x20/PencilFill';
import DeleteIcon from '../../icons/20x20/TrashFill';
import List from '../../ui/dialog/List';

// Available options for an upload.
const uploadOption = Object.freeze({
  'EDIT_UPLOAD': 1,
  'DELETE_UPLOAD': 2
});

const OPTIONS = [
  {
    startIcon: <PencilIcon />,
    primary: 'Edit this file',
    secondary: 'Edit the file name and notes.',
    value: uploadOption.EDIT_UPLOAD
  },
  {
    startIcon: <DeleteIcon />,
    primary: 'Delete this file',
    secondary: `Delete a file will permanently remove it from Taskbook.
This cannot be undone.`,
    value: uploadOption.DELETE_UPLOAD
  }
];

function OptionList({ onOptionClick }) {
  return (
    <List>
      {OPTIONS.map(({ value, startIcon, primary, secondary }) => (
        <OptionListItem
          key={value}
          startIcon={startIcon}
          primary={primary}
          secondary={secondary}
          onClick={() => {
            if (onOptionClick) {
              onOptionClick(value);
            }
          }}
        />
      ))}
    </List>
  );
}

OptionList.propTypes = {
  onOptionClick: PropTypes.func
};

export default OptionList;
export { uploadOption }
