import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../ui/button/IconButton';
import ClearIcon from '../../ui/svg-icons/Clear';
import UserAvatar from '../../account/UserAvatar';

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: 'default',
    padding: theme.spacing(0.75, 1),
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F0F2F5',
      '& $clearIconButton': {
        display: 'flex'
      }
    },
    '&.Mui-focusVisible': {
      outline: 0,
      backgroundColor: '#F0F2F5',
      '& $clearIconButton': {
        display: 'flex'
      }
    }
  },
  avatar: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    fontWeight: 500
  },
  clearIconButton: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary,
    display: 'none'
  }
}));

const UserListItem = React.forwardRef(({user, onDelete, ...rest}, ref) => {
  const classes = useStyles();
  const clearButtonRef = React.useRef(null);

  const handleBlur = (event) => {
    // TODO: A (dirty) trick to keep the clear button around when this
    // shit loses focus.
    if (event.relatedTarget === clearButtonRef.current) {
      clearButtonRef.current.focus();
    }
  };

  const handleClearButtonClick = (event) => {
    if (onDelete) {
      onDelete(user);
    }
  };

  return (
    <MenuItem
      ref={ref}
      className={classes.listItem}
      onBlur={handleBlur}
      {...rest}
    >
      <UserAvatar
        user={user}
        className={classes.avatar}
      />
      <ListItemText
        primaryTypographyProps={{
          variant: 'body2',
          noWrap: true
        }}
        primary={user.username}
      />
      <IconButton
        ref={clearButtonRef}
        className={classes.clearIconButton}
        background={false}
        icon={ <ClearIcon fontSize='small' color='inherit' /> }
        onClick={handleClearButtonClick}
      />
    </MenuItem>
  );
});

UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }).isRequired,
  onDelete: PropTypes.func
};

export default UserListItem;
