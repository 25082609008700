import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { COMMENT_FILES } from './fragments';

const SOME_EVENT_COMMENT_UPDATED_SUBSCRIPTION = gql`
  subscription OnSomeEventCommentUpdated($eventId: ID!) {
    eventCommentUpdated(eventId: $eventId) {
      id
      text
      updatedAt
      ...CommentFiles
    }
  }
  ${COMMENT_FILES}
`;

function SomeCommentUpdatedSubscriber({ eventId }) {
  useSubscription(SOME_EVENT_COMMENT_UPDATED_SUBSCRIPTION, {
    variables: { eventId }
  });
  return null;
}

SomeCommentUpdatedSubscriber.propTypes = {
  eventId: PropTypes.string.isRequired
};

export default SomeCommentUpdatedSubscriber;
