import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import EmptyRow from './EmptyRow';
import RowSkeleton from './RowSkeleton';

function TableBodySkeleton({ colCount }) {
  return (
    <TableBody>
      <EmptyRow colSpan={colCount} />
      <RowSkeleton colSpan={colCount} />
      <RowSkeleton colSpan={colCount} />
      <RowSkeleton colSpan={colCount} />
    </TableBody>
  );
}

TableBodySkeleton.propTypes = {
  colCount: PropTypes.number.isRequired
};

export default TableBodySkeleton;
