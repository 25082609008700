import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function ProjectCardSubheader({ memberCount, todoCompletedRatio }) {
  return (
    <Box>
      {todoCompletedRatio} tasks completed &bull; {memberCount} members
    </Box>
  );
}

ProjectCardSubheader.propTypes = {
  todoCompletedRatio: PropTypes.string.isRequired,
  memberCount: PropTypes.number.isRequired
};

export default React.memo(ProjectCardSubheader);
