import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  TYPOGRAPHY_PROPS,
  TYPOGRAPHY_STYLE,
  INPUT_BORDER_WIDTH,
  INPUT_PADDINGS
} from './SectionNameInput';

const buttonPaddingStyles = INPUT_PADDINGS.map((padding) => (
  `${padding + INPUT_BORDER_WIDTH}px`
)).join(' ');

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    padding: buttonPaddingStyles
  },
  label: {...TYPOGRAPHY_STYLE}
}));

function SectionNameButton({ label, ...rest }) {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.button}
      {...rest}
    >
      <Typography
        noWrap
        {...TYPOGRAPHY_PROPS}
        className={classes.label}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
}

SectionNameButton.propTypes = {
  label: PropTypes.string.isRequired
};

export default SectionNameButton;
