import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '../../ui/dialog/DialogTitle';
import DialogContent from '../../ui/dialog/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '../../ui/dialog/DialogActions';
import DialogContext from '../../ui/dialog/DialogContext';
import Button from '../../ui/button/Button';
import useDeleteTeamMutation from '../hooks/useDeleteTeamMutation';

function DeleteTeamConfirm({ team }) {
  const { id, name } = team;
  const { closeDialog } = React.useContext(DialogContext);

  const [deleteTeam, { loading }] = useDeleteTeamMutation({
    teamId: id,
    onCompleted(data) {
      closeDialog();
    }
  });

  return (
    <React.Fragment>
      <DialogTitle onClose={closeDialog}>
        Delete team {name}?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          This action cannot be undone. Once this team is deleted,
          all of its projects and everything are gone forever
          as if they did not exist at all in the first place.
        </DialogContentText>

        <DialogContentText style={{marginBottom: 0}}>
          Are you sure you wish to do this?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={closeDialog}
          disabled={loading}
        >
          Never mind
        </Button>

        <Button
          variant='contained'
          size='small'
          color='danger'
          loading={loading}
          onClick={() => {
            deleteTeam();
          }}
        >
          Yes, delete it
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

DeleteTeamConfirm.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default DeleteTeamConfirm;
