import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../button/ButtonLink';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  button: {
    fontSize: '0.75rem',
    fontWeight: 500,
    marginLeft: theme.spacing(0.5)
  }
}))

function DiscardChanges({ onDiscard }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='caption' color='textSecondary'>
        Press ESC to
      </Typography>
      <Button className={classes.button} onClick={onDiscard}>
        discard changes
      </Button>
    </div>
  );
}


DiscardChanges.propTypes = {
  onDiscard: PropTypes.func
};

export default DiscardChanges;
