import React from 'react';
import MuiTab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 6,
    padding: theme.spacing(1.5, 2),
    textTransform: 'none',
    minWidth: 72,
    fontSize: '0.9375rem',
    opacity: 1.0,
    color: theme.palette.text.secondary,
    fontWeight: 600,
    '&::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      pointerEvents: 'none',
      opacity: 0.0,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      transition: 'opacity 0.1s cubic-bezier(0, 0, 1, 1)',
      '-webkit-tap-highlight-color': 'transparent',
      cursor: 'pointer'
    },
    '&:hover:not(&$selected)': {
      '&::after': {
        opacity: 1.0
      }
    },
    '&:active:not(&$selected)': {
      '&::after': {
        opacity: 1.0,
        backgroundColor: 'rgba(0, 0, 0, 0.10)'
      }
    },
    '&:focus:not(&$selected)': {
      '&::after': {
        opacity: 1.0
      }
    },
    '&$selected': {
      color: theme.palette.primary.main
    }
  },
  selected: {},
}))((props) => <MuiTab {...props} disableRipple /> );
