import React from 'react';
import PropTypes from 'prop-types';
import useWorkspaceURL from '../workspace/hooks/useCurrentWorkspaceURL';
import ListItem from '../ui/notification/ListItem';
import ListItemAvatar from '../ui/notification/ListItemAvatar';
import ListItemText from '../ui/notification/ListItemText';
import UserAvatar from '../account/UserAvatar';
import { formatTime } from './helpers';
import { generateFileCommentHtmlId } from '../file/comments/helpers';

function getNotificationText(fileNotification)  {
  const name = fileNotification.file.name;
  const ext = fileNotification.file.ext;
  const fileName = name + ext;

  const actorName = fileNotification.actor.username;
  const projName = fileNotification.file.project.name;
  const timestamp = formatTime(fileNotification.updatedAt);

  let primary;

  switch(fileNotification.type) {
    case 'COMMENT':
      primary = `Re: ${fileName}`;
      break;
    default:
      break;
  }

  return {
    primary,
    secondary: [actorName, projName, timestamp]
  };
}

function getNotificationUrl(fileNotification, workspaceUrl) {
  const projId = fileNotification.file.project.id;
  const fileId = fileNotification.file.id;
  const fileUrl = `projects/${projId}/files/uploads/${fileId}`;
  const url = `${workspaceUrl}/${fileUrl}`;

  if (fileNotification.type === 'COMMENT') {
    const commentId = fileNotification.comment.id
    const htmlId = generateFileCommentHtmlId(fileId, commentId);
    return `${url}#${htmlId}`;
  } else {
    return url;
  }
}

const FileNotificationItem = React.forwardRef((props, ref) => {
  const { notification, onMarkAsRead, ...rest } = props;
  const workspaceUrl = useWorkspaceURL();
  const {id, updatedAt, fileNotification } = notification;

  return (
    <ListItem
      ref={ref}
      id={id}
      url={getNotificationUrl(fileNotification, workspaceUrl)}
      onMarkAsRead={onMarkAsRead}
      {...rest}
    >
      <ListItemAvatar
        primary={<UserAvatar user={fileNotification.actor} />}
        secondary='MESSAGE'
      />
      <ListItemText
        {...getNotificationText({
          ...fileNotification,
          updatedAt: updatedAt
        })}
      />
    </ListItem>
  );
});

FileNotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    fileNotification: PropTypes.object.isRequired,
  }).isRequired,
  onMarkAsRead: PropTypes.func.isRequired
};

export default FileNotificationItem;
