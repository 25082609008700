import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const REMOVE_MEMBER_FROM_PROJECT_MUTATION = gql`
  mutation RemoveMemberFromProject($membershipId: ID!) {
    removeUserFromProject(membershipId: $membershipId) {
      id
    }
  }
`;

export default function useRemoveMemberMutation(options) {
  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  return useMutation(REMOVE_MEMBER_FROM_PROJECT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
