import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  }
}));

const NOT_FOUND_REASONS = [
  "The item you're looking for has been deleted",
  "You don't have access to it",
  "You clicked a broken link"
];

export default function NotFound() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='h6' component='h1' className={classes.title}>
        Sorry, we can't seem to find the page you're looking for.
      </Typography>

      <div>
        <Typography variant='body1' gutterBottom >
          This could be because:
        </Typography>

        <ul>
          {NOT_FOUND_REASONS.map((reason) => (
            <li key={reason}>
              <Typography variant='body1'>
                {reason}
              </Typography>
            </li>
          ))}
        </ul>
      </div>

      <Typography variant='body1'>
        If you think you should have access to this page, ask the person
        who manages the workspace to add you to it.
      </Typography>
    </div>
  );
}
