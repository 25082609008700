import React from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import WorkspaceContext from '../../workspace/CurrentWorkspaceContext';
import useCustomSnackbar from '../../snackbar/useCustomSnackbar';

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject(
    $teamId: ID!,
    $name: String!,
    $description: String,
    $color: String
  ) {
    createProject(
      teamId: $teamId,
      name: $name,
      description: $description,
      color: $color
    ) {
      id
    }
  }
`;

// TODO:
// 1. Find a better way to handle error!
// 2. We hard-coded project page url here! FIX that!
export default function useCreateProjectMutation({
  onCompleted: onFinished,
  ...rest
}) {
  const { currentWorkspaceId } = React.useContext(WorkspaceContext);
  const history = useHistory();

  const redirectToProjectPage = (projectId) => {
    history.push(`/workspaces/${currentWorkspaceId}/projects/${projectId}`);
  };

  const { enqueueGraphQLErrorSnackbar }  = useCustomSnackbar();

  return useMutation(CREATE_PROJECT_MUTATION, {
    onCompleted: (data) => {
      if (onFinished) { onFinished(data); }
      const projectId = data.createProject.id;
      redirectToProjectPage(projectId);
    },
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...rest
  })
}
