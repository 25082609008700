import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import NavTabs from '../ui/tabs/NavTabs';
import TabLink from '../ui/tabs/TabLink';

function TeamTabs() {
  const { url } = useRouteMatch();
  return (
    <NavTabs>
      <TabLink to={`${url}/projects`} primary='Projects' />
      <TabLink to={`${url}/members`} primary='Members' exact />
      <TabLink to={`${url}/activities`} primary='Activities' exact />
    </NavTabs>
  );
}

export default TeamTabs;
