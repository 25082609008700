import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../ui/menu/MenuItem';
import MenuContext from '../../ui/menu/MenuContext';
import DialogContext from '../../ui/dialog/DialogContext';
import EditIcon from '../../icons/20x20/Pencil';
import OptionDialog from '../options/OptionDialog';
import { projectOption } from '../options/OptionList';

const EditProjectItem = React.forwardRef((props, ref) => {
  const { projectId, ...other } = props;
  const { closeMenu } = React.useContext(MenuContext);
  const { openDialog } = React.useContext(DialogContext);

  const handleClick = () => {
    closeMenu();

    openDialog({
      dialogContent: (
        <OptionDialog
          projectId={projectId}
          defaultOption={projectOption.EDIT_PROJECT}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
        fullWidth: true
      }
    });
  };

  return (
    <MenuItem
      ref={ref}
      primary='Edit project details'
      icon={ <EditIcon /> }
      onClick={handleClick}
      {...other}
    />
  );
});

EditProjectItem.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default EditProjectItem;
