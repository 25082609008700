import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useCustomSnackbar from '../../../snackbar/useCustomSnackbar';
import { COMMENT_AUTHOR, COMMENT_FILES } from './fragments';

const CREATE_EVENT_COMMENT_MUTATION = gql`
  mutation CreateEventComment(
    $eventId: ID!,
    $text: String!,
    $files: [UploadInput]
  ) {
    createEventComment(eventId: $eventId, text: $text, files: $files) {
      id
      text
      autoGenerated
      severity
      ...CommentAuthor
      ...CommentFiles
      updatedAt
    }
  }
  ${COMMENT_AUTHOR}
  ${COMMENT_FILES}
`;

export default function useCreateCommentMutation(options) {
  const { enqueueGraphQLErrorSnackbar } = useCustomSnackbar();
  return useMutation(CREATE_EVENT_COMMENT_MUTATION, {
    onError(error) {
      enqueueGraphQLErrorSnackbar(error);
    },
    ...options
  });
}
