import GifIcon128 from '../icons/128/Gif';
import JpegIcon128 from '../icons/128/Jpeg';
import PngIcon128 from '../icons/128/Png';
import SvgIcon128 from '../icons/128/Svg';
import WebpIcon128 from '../icons/128/Webp';
import BmpIcon128 from '../icons/128/Bmp';
import TiffIcon128 from '../icons/128/Tiff';
import IcoIcon128 from '../icons/128/Ico';
import FileImageIcon from '../../icons/FileImage';

import BmpIcon40 from '../icons/40/Bmp';
import PngIcon40 from '../icons/40/Png';
import GifIcon40 from '../icons/40/Gif';
import JpegIcon40 from '../icons/40/Jpeg';

export const ImageMimeType = Object.freeze({
  'image/gif': {
    description: 'GIF Image',
    Icon128: GifIcon128,
    Icon24: FileImageIcon,
    Icon40: GifIcon40
  },
  'image/jpeg': {
    description: 'JPEG Image',
    Icon128: JpegIcon128,
    Icon24: FileImageIcon,
    Icon40: JpegIcon40
  },
  'image/png': {
    description: 'PNG Image',
    Icon128: PngIcon128,
    Icon24: FileImageIcon,
    Icon40: PngIcon40
  },
  'image/svg+xml': {
    description: 'Scalable Vector Graphics (SVG)',
    Icon128: SvgIcon128,
    Icon24: FileImageIcon,
    Icon40: null
  },
  'image/webp': {
    description: "Google's WebP",
    Icon128: WebpIcon128,
    Icon24: FileImageIcon,
    Icon40: null
  },
  'image/bmp': {
    description: 'Windows BMP image',
    Icon128: BmpIcon128,
    Icon24: FileImageIcon,
    Icon40: BmpIcon40
  },
  'image/vnd.microsoft.icon': {
    description: 'Windows Icon Image',
    Icon128: IcoIcon128,
    Icon24: FileImageIcon,
    Icon40: null
  },
  'image/tiff': {
    description: 'TIFF Image',
    Icon128: TiffIcon128,
    Icon24: FileImageIcon,
    Icon40: null
  },
});
