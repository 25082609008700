const MAX_NUM_WEEKS = 4;

function _getMaxQuantity(unit) {
  switch(unit) {
    case 'WEEKS':
      return MAX_NUM_WEEKS;
    case 'DAYS':
      return MAX_NUM_WEEKS * 7;
    case 'HOURS':
      return MAX_NUM_WEEKS * 7 * 24;
    case 'MINUTES':
      return MAX_NUM_WEEKS * 7 * 24 * 60;
    default:
      throw new Error(`Unknown unit: ${unit}`);
  }
}

export default function getReminderQuantityRange(unit) {
  return {
    min: 0,
    max: _getMaxQuantity(unit)
  };
}
