import React from 'react';
import PropTypes from 'prop-types';
import MarkCompleteCheckbox from '../../ui/todo/details/MarkCompleteCheckbox';
import useCompleteTodoMutation from './hooks/useCompleteTodoMutation';
import useReopenTodoMutation from './hooks/useReopenTodoMutation';

function MarkComplete({ todoId, name, completed }) {
  const [completeTodoItem] = useCompleteTodoMutation({ todoId });
  const [reopenTodoItem] = useReopenTodoMutation({ todoId });

  const handleCheckedChange = (event) => {
    const newChecked = event.target.checked;

    if (newChecked) {
      completeTodoItem({
        optimisticResponse: {
          __typename: 'Mutation',
          completeTodoItem: {
            id: todoId,
            name: name,
            completed: true,
            __typename: 'TodoItem'
          }
        }
      });
    } else {
      reopenTodoItem({
        optimisticResponse: {
          __typename: 'Mutation',
          reopenTodoItem: {
            id: todoId,
            name: name,
            completed: false,
            __typename: 'TodoItem'
          }
        }
      });
    }
  };

  const ariaLabel = completed ? 'mark as incomplete' : 'mark as complete';

  return (
    <MarkCompleteCheckbox
      checked={completed}
      onChange={handleCheckedChange}
      inputProps={{ 'aria-label': ariaLabel }}
    />
  );
}

MarkComplete.propTypes = {
  todoId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired
};

export default MarkComplete;
