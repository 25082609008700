import React from 'react';
import {
  Route, Switch, Redirect, useRouteMatch, useParams
} from 'react-router-dom';
import NotFound from '../oops/SectionNotFound';
import ViewSwitch from './ViewSwitch';
import { getDateSlug, viewOptions } from './helpers';
import DialogProvider from './DialogProvider';
import EventDetailsPage from './EventDetailsPage';
import ScheduleAddedSubscription from './ScheduleAddedSubscription';
import ScheduleRemovedSubscription from './ScheduleRemovedSubscription';
import ScheduleUpdatedSubscription from './ScheduleUpdatedSubscription';

function Calendar() {
  const { projectId } = useParams();
  const { path, url } = useRouteMatch();
  const currentDateSlug = getDateSlug(new Date());
  const viewRegex = viewOptions.map(({ value }) => value).join('|');

  return (
    <DialogProvider>
      <Switch>
        <Route path={path} exact>
          <Redirect to={`${url}/month/${currentDateSlug}`} />
        </Route>

        <Route path={`${path}/:view(${viewRegex})/:year/:month/:day`}>
          <ViewSwitch rootUrl={url} />
        </Route>

        <Route path={`${path}/events/:eventId`} exact>
          <EventDetailsPage calendarUrl={url} />
        </Route>

        <Route>
          <NotFound />
        </Route>
      </Switch>
      <ScheduleAddedSubscription projectId={projectId} />
      <ScheduleRemovedSubscription projectId={projectId} />
      <ScheduleUpdatedSubscription projectId={projectId} />
    </DialogProvider>
  );
}

export default Calendar;
