import React from 'react';
import FullScreenCard from '../components/ui/card/FullScreenCard';
import SignInForm from '../components/account/SignInForm';

const DOCUMENT_TITLE = "Taskbook Sign In";

export default function SignIn() {

  React.useEffect(() => {
    document.title = DOCUMENT_TITLE;
  }, []);

  return (
    <FullScreenCard>
      <SignInForm />
    </FullScreenCard>
  );
}
