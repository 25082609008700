import React from 'react';
import PropTypes from 'prop-types';
import { generateEventCommentHtmlId }
from '../calendar/event-details/comments/helper';
import GenericActivityItem from './ui/GenericActivityItem';
import ActivityTextDetails from './ui/ActivityTextDetails';
import useEventUrl from './useEventUrl';

function CommentEventItem({ activity }) {
  const { type, actor, details, event, comment, insertedAt } = activity;
  const [eventTitle, commentText] = details;

  const eventUrl = useEventUrl(event);
  const commentId = generateEventCommentHtmlId(event.id, comment.id);
  const commentUrl = `${eventUrl}#${commentId}`;

  return (
    <GenericActivityItem
      type={type}
      who={actor}
      what='commented on'
      linkUrl={commentUrl}
      linkText={eventTitle}
      insertedAt={insertedAt}
    >
      <ActivityTextDetails text={commentText} />
    </GenericActivityItem>
  );
}

CommentEventItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf(['COMMENT_EVENT']).isRequired,
    actor: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      startsAt: PropTypes.string.isRequired
    }).isRequired,
    comment: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    insertedAt: PropTypes.string.isRequired
  }).isRequired
};

export default CommentEventItem;
