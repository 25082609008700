import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#F0F2F5',
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(0.75, 1.5),

    // Position label
    '& label + .MuiInput-formControl': {
      marginTop: theme.spacing(2.5)
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(12px, 6px) scale(1.0)'
    },

    // Input root
    '& .MuiInput-root': {
      padding: '1px 0px',
      fontSize: '0.9375rem',
      lineHeight: 1.43,

      // To us, disabled is the same as readOnly
      '&.Mui-disabled': {
        color: theme.palette.text.primary
      },

      // Change cursor to point when not focused
      '&:not(.Mui-focused) $input': {
        cursor: 'pointer'
      }
    },

    // Remove `underline` for variant `standard`
    '& .MuiInput-underline': {
      '&:before': {content: 'none'},
      '&:after': {content: 'none'}
    }
  },
  label: {
    // Make label looks like comment title (subtitl2)
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.57
  },
  input: {
    '&::placeholder': {fontSize: '0.9375rem'}
  }
}))(React.forwardRef(
  ({ classes, InputLabelProps, InputProps, ...rest }, ref) => (
    <MuiTextField
      ref={ref}
      multiline
      type='text'
      autoComplete='off'
      InputProps={{
        ...InputProps,
        classes: {input: classes.input}
      }}
      InputLabelProps={{
        ...InputLabelProps,
        className: classes.label,
        shrink: true,
        focused: false,
        disabled: false,
        disableAnimation: true
      }}
      inputProps={{ spellCheck: 'false' }}
      classes={{root: classes.root}}
      {...rest}
    />
  )
));
