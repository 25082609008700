import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../ui/button/IconButton';
import MoreIcon from '../../icons/20x20/Ellipsis';
import OtherActionsMenu from './OtherActionsMenu';

function OtherActions({ teamId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        icon={ <MoreIcon /> }
        size='small'
        square
        color={open ? 'accent' : 'secondary'}
        background={open}
        onClick={handleClick}
      />
      <OtherActionsMenu
        teamId={teamId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
}

OtherActions.propTypes = {
  teamId: PropTypes.string.isRequired
};

export default OtherActions;
