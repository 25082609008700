import React from 'react';
import PropTypes from 'prop-types';
import CreateTodoFormDialog from '../form-dialog/CreateTodoFormDialog';

function TodoFormDialog({ projectId, date, onClose }) {
  return (
    <CreateTodoFormDialog
      projectId={projectId}
      defaultDueDate={date}
      onClose={onClose}
    />
  );
}

TodoFormDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onClose: PropTypes.func
};

export default TodoFormDialog;
