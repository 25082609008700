import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../progress/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    minWidth: 256,
    borderRadius: 8,
    boxShadow: theme.customShadow.menu,
  },
  spinnerContainer: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function CustomizeFieldPopover({ loading, children, ...rest }) {
  const classes = useStyles();
  return (
    <Popover
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
      {...rest}
    >
      {loading ? (
        <div className={classes.spinnerContainer}>
          <Spinner size={24} />
        </div>
      ) : children}
    </Popover>
  )
}

export default CustomizeFieldPopover;
