import React from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import useHandleKeyCommandFn from
'../text-editor/hooks/useHandleKeyCommandFn';
import useCustomKeyBindingFn from
'../text-editor/hooks/useCustomKeyBindingFn';
import useCustomBlockStyleFn from
'../text-editor/hooks/useCustomBlockStyleFn';
import EditorToolbar from './MessageEditorToolbar';
import EditorContainer from './MessageEditorContainer';

function MessageTextEditor({ editorState, onChange }) {
  const editorRef = React.useRef(null);
  const customBlockStyleFn = useCustomBlockStyleFn();
  const handleKeyCommand = useHandleKeyCommandFn(onChange);
  const customKeyBindingFn = useCustomKeyBindingFn(onChange);

  const focusEditor = () => {
    editorRef.current.focus();
  };

  const toggleBlockType = (blockType) => {
    onChange((prevState) => RichUtils.toggleBlockType(
      prevState,
      blockType
    ));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onChange((prevState) => RichUtils.toggleInlineStyle(
      prevState,
      inlineStyle
    ));
  };

  return (
    <React.Fragment>
      <EditorToolbar
        editorState={editorState}
        onToggleInlineStyle={toggleInlineStyle}
        onToggleBlockType={toggleBlockType}
      />
      <EditorContainer onClick={focusEditor}>
        <Editor
          placeholder='Write away...'
          spellCheck={true}
          ref={editorRef}
          blockStyleFn={customBlockStyleFn}
          keyBindingFn={customKeyBindingFn}
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          onChange={onChange}
        />
      </EditorContainer>
    </React.Fragment>
  );
}

MessageTextEditor.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onChange: PropTypes.func.isRequired
};

export default MessageTextEditor;
