import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const RED_COLOR = '#ff0053';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${RED_COLOR}`,
    borderRadius: 6,
    width: theme.spacing(6),
    flex: '0 0 auto'
  },
  monthText: {
    backgroundColor: RED_COLOR,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: '#fff',
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: 1.333334
  },
  dateText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    lineHeight: 1.1666667,
    fontWeight: 300
  }
}));

function EventDate({ date }) {
  const classes = useStyles();
  const d = format(date, 'd');
  const m = format(date, 'MMM');

  return (
    <div className={classes.root}>
      <Typography
        component='div'
        variant='subtitle2'
        className={classes.monthText}
      >
        {m}
      </Typography>
      <Typography
        component='div'
        variant='h5'
        color='textPrimary'
        className={classes.dateText}
      >
        {d}
      </Typography>
    </div>
  )
}

EventDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
};

export default EventDate;
