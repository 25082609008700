import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  largeCell: {
    height: 192,
    borderBottom: '1px solid #EBEDF0',
    padding: theme.spacing(0, 2)
  }
}));

export default function LargeCell(props) {
  const classes = useStyles();
  return <TableCell className={classes.largeCell} {...props} />
}
