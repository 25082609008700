import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  }
}));

function Header({ children }) {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.header}>
      <div className={classes.grow} />
      {children}
    </DialogTitle>
  );
}

export default Header;
