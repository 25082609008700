import React from 'react';
import Input from '../../new-ui/Input';

export default function NotesInput(props) {
  return (
    <Input
      fullWidth
      multiline
      rows={4}
      background='always'
      inputProps={{spellCheck: false, autoComplete: 'off'}}
      {...props}
    />
  );
}
