import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '../../../icons/12x12/Clear';
import Autocomplete from '../../../autocomplete/AutocompleteUser';
import AutocompleteUserOption from './AutocompleteUserOption';
import Loading from './AutocompleteUserLoading';
import CellInput from './CellInput';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  listbox: {
    padding: 0
  },
  option: {
    padding: theme.spacing(1),
    [`&[aria-selected="true"]`]: {
      backgroundColor: '#E7F3FF'
    }
  },
  endAdornment: {
    top: 'calc(50% - 12px)',
    right: 4
  },
  clearIndicator: {
    marginRight: 0,
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

export default function AutocompleteUser(props) {
  const classes = useStyles();

  const renderInput = React.useCallback((params) => {
    // TODO: delete weird className supplied by MUI.
    delete params.InputProps.className;
    return (
      <CellInput
        autoFocus={true}
        fullWidth
        type='text'
        placeholder='Search by name or email'
        {...params}
      />
    );
  }, []);

  const renderOption = React.useCallback((user) => (
    <AutocompleteUserOption user={user} />
  ), []);

  return (
    <Autocomplete
      openOnFocus
      disablePortal
      multiple={false}
      disableClearable={false}
      closeIcon={ <ClearIcon color='secondary' /> }
      loadingText={ <Loading /> }
      renderInput={renderInput}
      renderOption={renderOption}
      classes={classes}
      {...props}
    />
  );
}
