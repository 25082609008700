import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/button/Button';
import FormContainer from '../ui/misc/FullScreenFormContainer';
import Form from '../ui/misc/FullScreenForm';
import CreateWorkspaceFormInput from './CreateWorkspaceFormInput';
import CreateWorkspaceFormHeader from './CreateWorkspaceFormHeader';
import CreateWorkspaceFormFooter from './CreateWorkspaceFormFooter';
import useCreateWorkspace from './hooks/useCreateWorkspaceMutation';
import Error from '../oops/Error';
import { gqlErrorDetails } from '../oops/helpers';

function CreateWorkspaceForm({ username }) {
  const [formData, setFormData] = React.useState({
    name: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const [createWorkspace, { loading, error }] = useCreateWorkspace({
    variables: formData
  });

  const errorDetails = gqlErrorDetails(error);

  return (
    <FormContainer>
      <Form onSubmit={(event) => {
        event.preventDefault();
        createWorkspace().catch((e) => {});
      }}>
        <CreateWorkspaceFormHeader username={username} />

        {error && !errorDetails['name'] && (
          <Error error={error} style={{
            alignSelf: 'stretch'
          }} />
        )}

        <CreateWorkspaceFormInput
          value={formData}
          onChange={handleChange}
          error={errorDetails}
        />

        <Button
          type='submit'
          variant='contained'
          color='success'
          size='large'
          fullWidth
          loading={loading}
        >
          Create workspace
        </Button>
      </Form>
      {!loading && <CreateWorkspaceFormFooter />}
    </FormContainer>
  );
}

CreateWorkspaceForm.propTypes = {
  username: PropTypes.string.isRequired
};

export default CreateWorkspaceForm;
