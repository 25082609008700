import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import FormField from './FormField';

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%',
    height: theme.spacing(4.5),
    marginLeft: theme.spacing(1)
  }
}));

export default function TodoDetailsSkeleton() {
  const classes = useStyles();
  const icon = <Skeleton width={20} height={20} variant='circle' />;
  return (
    <div>
      <FormField icon={icon}>
        <Skeleton className={classes.field} />
      </FormField>
      <FormField icon={icon}>
        <Skeleton className={classes.field} />
      </FormField>
      <FormField icon={icon}>
        <Skeleton className={classes.field} />
      </FormField>
    </div>
  );
}
