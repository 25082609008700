import React from 'react';
import PageHeader from '../ui/page/Header';
import PageTitleSkeleton from '../ui/page/PageTitleSkeleton';
import PageSubtitleSkeleton from '../ui/page/PageSubtitleSkeleton';
import AvatarGroupSkeleton from '../ui/page/PageAvatarGroupSkeleton';
import PageNavigationSkeleton from '../ui/page/NavigationSkeleton';
import SectionSkeleton from '../ui/page/SectionSkeleton';

export default function TeamSkeleton() {
  return (
    <div>
      <PageHeader>
        <PageTitleSkeleton />
        <PageSubtitleSkeleton />
        <AvatarGroupSkeleton />
      </PageHeader>
      <PageNavigationSkeleton />
      <SectionSkeleton />
    </div>
  );
}
